// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Mui$BsConsole = require("../../bindings/Mui.js");
var Col2$BsConsole = require("../../components/Col2.js");
var I18N$BsConsole = require("../../I18N.js");
var Row2$BsConsole = require("../../components/Row2.js");
var Util$BsConsole = require("../../util.js");
var Task2$BsConsole = require("../../Task2.js");
var Colors$BsConsole = require("../../Colors.js");
var BtSettings$BsConsole = require("../../BtSettings.js");
var TeamsUtils$BsConsole = require("../../teams/TeamsUtils.js");
var Icon = require("@material-ui/core/Icon");
var BtTypography$BsConsole = require("../../BtTypography.js");
var MetricsEvent$BsConsole = require("../../bs-metrics/MetricsEvent.js");
var Paper = require("@material-ui/core/Paper");
var Configuration$BsConsole = require("../../configuration.js");
var Button = require("@material-ui/core/Button");
var Select = require("@material-ui/core/Select");
var Close = require("@material-ui/icons/Close");
var Tooltip = require("@material-ui/core/Tooltip");
var MenuItem = require("@material-ui/core/MenuItem");
var TextField = require("@material-ui/core/TextField");
var InputLabel = require("@material-ui/core/InputLabel");
var ArrowUpward = require("@material-ui/icons/ArrowUpward");
var OutlinedInput = require("@material-ui/core/OutlinedInput");
var ArrowDownward = require("@material-ui/icons/ArrowDownward");

function toString(param) {
  switch (param) {
    case /* FileName */0 :
        return "file_name";
    case /* FunctionName */1 :
        return "function_name";
    case /* ObjectName */2 :
        return "object_name";
    case /* Attribute */3 :
        return "attribute";
    
  }
}

function toFriendlyString(param) {
  switch (param) {
    case /* FileName */0 :
        return I18N$BsConsole.get(undefined, "File name");
    case /* FunctionName */1 :
        return I18N$BsConsole.get(undefined, "Function name");
    case /* ObjectName */2 :
        return I18N$BsConsole.get(undefined, "Object name");
    case /* Attribute */3 :
        return I18N$BsConsole.get(undefined, "Attribute");
    
  }
}

function fromString(param) {
  switch (param) {
    case "attribute" :
        return /* Attribute */3;
    case "file_name" :
        return /* FileName */0;
    case "function_name" :
        return /* FunctionName */1;
    case "object_name" :
        return /* ObjectName */2;
    default:
      throw [
            Json_decode.DecodeError,
            "failed to decode field type"
          ];
  }
}

var FieldType = {
  toString: toString,
  toFriendlyString: toFriendlyString,
  fromString: fromString
};

function toString$1(param) {
  if (param) {
    return "skip";
  } else {
    return "match";
  }
}

function toFriendlyString$1(param) {
  if (param) {
    return I18N$BsConsole.get(undefined, "Skip this repository");
  } else {
    return I18N$BsConsole.get(undefined, "Use this repository");
  }
}

function fromString$1(param) {
  switch (param) {
    case "match" :
        return /* Match */0;
    case "skip" :
        return /* Skip */1;
    default:
      throw [
            Json_decode.DecodeError,
            "failed to decode field type"
          ];
  }
}

var Action = {
  toString: toString$1,
  toFriendlyString: toFriendlyString$1,
  fromString: fromString$1
};

function fromJson(json) {
  return {
          id: Json_decode.optional((function (param) {
                  return Json_decode.field("id", Json_decode.$$int, param);
                }), json),
          position: Json_decode.field("position", Json_decode.$$int, json),
          matchingRepositoryId: Json_decode.field("matching_repository_id", Json_decode.$$int, json),
          fieldType: fromString(Json_decode.field("field_type", Json_decode.string, json)),
          fieldName: Json_decode.field("field_name", Json_decode.string, json),
          action: fromString$1(Json_decode.field("action", Json_decode.string, json)),
          regex: Json_decode.field("regex", Json_decode.string, json),
          projectName: Json_decode.field("project_name", Json_decode.string, json),
          universeName: Json_decode.field("universe_name", Json_decode.string, json)
        };
}

function toJson(t) {
  var props_000 = /* tuple */[
    "position",
    t.position
  ];
  var props_001 = /* :: */[
    /* tuple */[
      "matching_repository_id",
      t.matchingRepositoryId
    ],
    /* :: */[
      /* tuple */[
        "field_type",
        toString(t.fieldType)
      ],
      /* :: */[
        /* tuple */[
          "field_name",
          t.fieldName
        ],
        /* :: */[
          /* tuple */[
            "regex",
            t.regex
          ],
          /* :: */[
            /* tuple */[
              "action",
              t.action ? "skip" : "match"
            ],
            /* :: */[
              /* tuple */[
                "project_name",
                t.projectName
              ],
              /* :: */[
                /* tuple */[
                  "universe_name",
                  t.universeName
                ],
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]
  ];
  var props = /* :: */[
    props_000,
    props_001
  ];
  var id = t.id;
  return Json_encode.object_(id !== undefined ? /* :: */[
                /* tuple */[
                  "id",
                  id
                ],
                props
              ] : props);
}

function fetchRules(config, universeName, projectName) {
  var endpoint = Configuration$BsConsole.getEndpointWithProjectOverrides(projectName, undefined, config, "sourcecode");
  var path = endpoint !== undefined ? endpoint : "http://localhost:6451/api/sourcecode";
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(path) + ("/" + (String(universeName) + ("/" + (String(projectName) + "/rules")))))]), undefined, /* Get */0, undefined, (function (param) {
                return /* Ok */Block.__(0, [Json_decode.field("rules", (function (param) {
                                  return Json_decode.list(fromJson, param);
                                }), param.json)]);
              }), undefined, undefined);
}

function saveRule(rule, universeName, projectName, config) {
  var endpoint = Configuration$BsConsole.getEndpointWithProjectOverrides(projectName, undefined, config, "sourcecode");
  var path = endpoint !== undefined ? endpoint : "http://localhost:6451/api/sourcecode";
  var id = rule.id;
  if (id !== undefined) {
    return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(path) + ("/" + (String(universeName) + ("/" + (String(projectName) + ("/rules/" + (String(id) + "")))))))]), undefined, /* Put */Block.__(1, [toJson(rule)]), undefined, (function (param) {
                  return /* Ok */Block.__(0, [Json_decode.field("rule", fromJson, param.json)]);
                }), undefined, undefined);
  } else {
    return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(path) + ("/" + (String(universeName) + ("/" + (String(projectName) + "/rules")))))]), undefined, /* Post */Block.__(0, [toJson(rule)]), undefined, (function (param) {
                  return /* Ok */Block.__(0, [fromJson(param.json)]);
                }), undefined, undefined);
  }
}

function deleteRule(id, universeName, projectName, config) {
  var endpoint = Configuration$BsConsole.getEndpointWithProjectOverrides(projectName, undefined, config, "sourcecode");
  var path = endpoint !== undefined ? endpoint : "http://localhost:6451/api/sourcecode";
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(path) + ("/" + (String(universeName) + ("/" + (String(projectName) + ("/rules/" + (String(id) + "")))))))]), undefined, /* Delete */1, undefined, (function (param) {
                return /* Ok */Block.__(0, [undefined]);
              }), undefined, undefined);
}

function getRepoNameFromId(id, repos) {
  return Belt_Option.map(Belt_List.getBy(repos, (function (repo) {
                    return Caml_obj.caml_equal(repo.id, id);
                  })), (function (repo) {
                return repo.name;
              }));
}

function RepoRule$Rule(Props) {
  var config = Props.config;
  var token = Props.token;
  var projectName = Props.projectName;
  var universeName = Props.universeName;
  var refetchRepoRulesCb = Props.refetchRepoRulesCb;
  var positionChangeCb = Props.positionChangeCb;
  var rule = Props.rule;
  var repositories = Props.repositories;
  var match = React.useState((function () {
          return rule;
        }));
  var setRule = match[1];
  var rule$1 = match[0];
  var moveUp = function (_evt) {
    MetricsEvent$BsConsole.send(/* ActionWithAttrs */Block.__(1, [
            /* SourceCode_RuleChangePosition */87,
            Json_encode.object_(/* :: */[
                  /* tuple */[
                    "project",
                    projectName
                  ],
                  /* [] */0
                ])
          ]));
    return Curry._2(positionChangeCb, rule$1.id, rule$1.position - 1 | 0);
  };
  var moveDown = function (_evt) {
    MetricsEvent$BsConsole.send(/* ActionWithAttrs */Block.__(1, [
            /* SourceCode_RuleChangePosition */87,
            Json_encode.object_(/* :: */[
                  /* tuple */[
                    "project",
                    projectName
                  ],
                  /* [] */0
                ])
          ]));
    return Curry._2(positionChangeCb, rule$1.id, rule$1.position + 1 | 0);
  };
  var match$1 = rule$1.fieldType;
  return React.createElement(Row2$BsConsole.make, {
              alignItems: /* flexEnd */924268066,
              flexWrap: /* wrap */-822134326,
              className: Css.style(/* :: */[
                    Css.marginBottom(Css.rem(1)),
                    /* [] */0
                  ]),
              children: null
            }, React.createElement("div", {
                  className: Css.style(/* :: */[
                        Css.fontSize(Css.em(1)),
                        /* :: */[
                          Css.lineHeight(Css.em(2)),
                          /* :: */[
                            Css.marginRight(Css.rem(1)),
                            /* :: */[
                              Css.color(Css.hex(Colors$BsConsole.blackA54)),
                              /* [] */0
                            ]
                          ]
                        ]
                      ])
                }, I18N$BsConsole.show(undefined, "IF")), React.createElement(Col2$BsConsole.make, {
                  children: null
                }, React.createElement(InputLabel.default, {
                      classes: {
                        root: Css.style(/* :: */[
                              Css.marginTop(Css.px(8)),
                              /* :: */[
                                Css.marginBottom(Css.px(3)),
                                /* [] */0
                              ]
                            ])
                      },
                      children: React.createElement(BtTypography$BsConsole.make, {
                            variant: /* Caption */11,
                            children: I18N$BsConsole.show(undefined, "Type")
                          })
                    }), React.createElement(Select.default, {
                      value: rule$1.fieldType,
                      disabled: true,
                      onChange: (function (e, param) {
                          var value = e.target.value;
                          return Curry._1(setRule, (function (r) {
                                        return {
                                                id: r.id,
                                                position: r.position,
                                                matchingRepositoryId: r.matchingRepositoryId,
                                                fieldType: value,
                                                fieldName: r.fieldName,
                                                action: r.action,
                                                regex: r.regex,
                                                projectName: r.projectName,
                                                universeName: r.universeName
                                              };
                                      }));
                        }),
                      input: React.createElement(OutlinedInput.default, { }),
                      renderValue: (function (v) {
                          return I18N$BsConsole.showSkip(toFriendlyString(v));
                        }),
                      className: Css.style(/* :: */[
                            Css.backgroundColor(/* transparent */582626130),
                            /* :: */[
                              Css.paddingLeft(Css.rem(0.75)),
                              /* :: */[
                                Css.height(Css.rem(2)),
                                /* :: */[
                                  Css.width(Css.ch(17)),
                                  /* :: */[
                                    Css.marginRight(Css.rem(1)),
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ]
                          ]),
                      children: null
                    }, React.createElement(MenuItem.default, {
                          value: /* Attribute */3,
                          children: I18N$BsConsole.showSkip(I18N$BsConsole.get(undefined, "Attribute"))
                        }), React.createElement(MenuItem.default, {
                          value: /* FileName */0,
                          children: I18N$BsConsole.showSkip(I18N$BsConsole.get(undefined, "File name"))
                        }), React.createElement(MenuItem.default, {
                          value: /* FunctionName */1,
                          children: I18N$BsConsole.showSkip(I18N$BsConsole.get(undefined, "Function name"))
                        }), React.createElement(MenuItem.default, {
                          value: /* ObjectName */2,
                          children: I18N$BsConsole.showSkip(I18N$BsConsole.get(undefined, "Object name"))
                        }))), match$1 >= 3 ? React.createElement(Col2$BsConsole.make, {
                    children: null
                  }, React.createElement(InputLabel.default, {
                        classes: {
                          root: Css.style(/* :: */[
                                Css.marginTop(Css.px(8)),
                                /* :: */[
                                  Css.marginBottom(Css.px(3)),
                                  /* [] */0
                                ]
                              ])
                        },
                        children: React.createElement(BtTypography$BsConsole.make, {
                              variant: /* Caption */11,
                              children: I18N$BsConsole.show(undefined, "Attribute name")
                            })
                      }), React.createElement(TextField.default, {
                        disabled: true,
                        placeholder: I18N$BsConsole.get(undefined, "Attribute name"),
                        value: rule$1.fieldName,
                        color: "primary",
                        variant: "outlined",
                        classes: {
                          root: Css.style(/* :: */[
                                Css.width(Css.ch(20)),
                                /* :: */[
                                  Css.marginRight(Css.rem(1)),
                                  /* :: */[
                                    Css.selector("input", /* :: */[
                                          Css.important(Css.paddingTop(Css.px(6))),
                                          /* :: */[
                                            Css.important(Css.paddingBottom(Css.px(7))),
                                            /* [] */0
                                          ]
                                        ]),
                                    /* :: */[
                                      Css.selector("div[class*='multiline']", /* :: */[
                                            Css.important(Css.paddingTop(Css.px(10))),
                                            /* [] */0
                                          ]),
                                      /* [] */0
                                    ]
                                  ]
                                ]
                              ])
                        },
                        onChange: (function ($$event) {
                            return Curry._1(setRule, (function (r) {
                                          return {
                                                  id: r.id,
                                                  position: r.position,
                                                  matchingRepositoryId: r.matchingRepositoryId,
                                                  fieldType: r.fieldType,
                                                  fieldName: BtSettings$BsConsole.getInputValue($$event),
                                                  action: r.action,
                                                  regex: r.regex,
                                                  projectName: r.projectName,
                                                  universeName: r.universeName
                                                };
                                        }));
                          })
                      })) : null, React.createElement(Col2$BsConsole.make, {
                  children: null
                }, React.createElement(InputLabel.default, {
                      classes: {
                        root: Css.style(/* :: */[
                              Css.marginTop(Css.px(8)),
                              /* :: */[
                                Css.marginBottom(Css.px(3)),
                                /* [] */0
                              ]
                            ])
                      },
                      children: React.createElement(BtTypography$BsConsole.make, {
                            variant: /* Caption */11,
                            children: I18N$BsConsole.show(undefined, "Regex")
                          })
                    }), React.createElement(TextField.default, {
                      disabled: true,
                      placeholder: I18N$BsConsole.get(undefined, "Regex"),
                      value: rule$1.regex,
                      color: "primary",
                      variant: "outlined",
                      classes: {
                        root: Css.style(/* :: */[
                              rule$1.fieldType === /* Attribute */3 ? Css.width(Css.ch(20)) : Css.width(Css.ch(42)),
                              /* :: */[
                                Css.marginRight(Css.rem(1)),
                                /* :: */[
                                  Css.selector("input", /* :: */[
                                        Css.important(Css.paddingTop(Css.px(6))),
                                        /* :: */[
                                          Css.important(Css.paddingBottom(Css.px(7))),
                                          /* [] */0
                                        ]
                                      ]),
                                  /* :: */[
                                    Css.selector("div[class*='multiline']", /* :: */[
                                          Css.important(Css.paddingTop(Css.px(10))),
                                          /* [] */0
                                        ]),
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ])
                      },
                      onChange: (function ($$event) {
                          var re = BtSettings$BsConsole.getInputValue($$event);
                          return Curry._1(setRule, (function (r) {
                                        return {
                                                id: r.id,
                                                position: r.position,
                                                matchingRepositoryId: r.matchingRepositoryId,
                                                fieldType: r.fieldType,
                                                fieldName: r.fieldName,
                                                action: r.action,
                                                regex: re,
                                                projectName: r.projectName,
                                                universeName: r.universeName
                                              };
                                      }));
                        })
                    })), React.createElement("div", {
                  className: Css.style(/* :: */[
                        Css.fontSize(Css.em(1)),
                        /* :: */[
                          Css.lineHeight(Css.em(2)),
                          /* :: */[
                            Css.marginRight(Css.rem(1)),
                            /* :: */[
                              Css.color(Css.hex(Colors$BsConsole.blackA54)),
                              /* [] */0
                            ]
                          ]
                        ]
                      ])
                }, I18N$BsConsole.show(undefined, "THEN")), React.createElement(Col2$BsConsole.make, {
                  children: null
                }, React.createElement(InputLabel.default, {
                      classes: {
                        root: Css.style(/* :: */[
                              Css.marginTop(Css.px(8)),
                              /* :: */[
                                Css.marginBottom(Css.px(3)),
                                /* [] */0
                              ]
                            ])
                      },
                      children: React.createElement(BtTypography$BsConsole.make, {
                            variant: /* Caption */11,
                            children: I18N$BsConsole.show(undefined, "Action")
                          })
                    }), React.createElement(Select.default, {
                      value: rule$1.action,
                      disabled: true,
                      onChange: (function (e, param) {
                          var value = e.target.value;
                          return Curry._1(setRule, (function (r) {
                                        return {
                                                id: r.id,
                                                position: r.position,
                                                matchingRepositoryId: r.matchingRepositoryId,
                                                fieldType: r.fieldType,
                                                fieldName: r.fieldName,
                                                action: value,
                                                regex: r.regex,
                                                projectName: r.projectName,
                                                universeName: r.universeName
                                              };
                                      }));
                        }),
                      input: React.createElement(OutlinedInput.default, { }),
                      renderValue: (function (v) {
                          return I18N$BsConsole.showSkip(toFriendlyString$1(v));
                        }),
                      className: Css.style(/* :: */[
                            Css.height(Css.rem(2)),
                            /* :: */[
                              Css.backgroundColor(/* transparent */582626130),
                              /* :: */[
                                Css.paddingLeft(Css.rem(0.75)),
                                /* :: */[
                                  Css.width(Css.ch(21)),
                                  /* :: */[
                                    Css.marginRight(Css.rem(1)),
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ]
                          ]),
                      children: null
                    }, React.createElement(MenuItem.default, {
                          value: /* Match */0,
                          children: I18N$BsConsole.showSkip(I18N$BsConsole.get(undefined, "Use this repository"))
                        }), React.createElement(MenuItem.default, {
                          value: /* Skip */1,
                          children: I18N$BsConsole.showSkip(I18N$BsConsole.get(undefined, "Skip this repository"))
                        }))), React.createElement(Col2$BsConsole.make, {
                  children: null
                }, React.createElement(InputLabel.default, {
                      classes: {
                        root: Css.style(/* :: */[
                              Css.marginTop(Css.px(8)),
                              /* :: */[
                                Css.marginBottom(Css.px(3)),
                                /* [] */0
                              ]
                            ])
                      },
                      children: React.createElement(BtTypography$BsConsole.make, {
                            variant: /* Caption */11,
                            children: I18N$BsConsole.show(undefined, "Repository")
                          })
                    }), React.createElement(Row2$BsConsole.make, {
                      alignItems: /* center */98248149,
                      children: null
                    }, React.createElement(Select.default, {
                          value: rule$1.matchingRepositoryId,
                          disabled: true,
                          onChange: (function (e, param) {
                              var value = e.target.value;
                              return Curry._1(setRule, (function (r) {
                                            return {
                                                    id: r.id,
                                                    position: r.position,
                                                    matchingRepositoryId: value,
                                                    fieldType: r.fieldType,
                                                    fieldName: r.fieldName,
                                                    action: r.action,
                                                    regex: r.regex,
                                                    projectName: r.projectName,
                                                    universeName: r.universeName
                                                  };
                                          }));
                            }),
                          input: React.createElement(OutlinedInput.default, { }),
                          renderValue: (function (id) {
                              return I18N$BsConsole.showSkip(Belt_Option.mapWithDefault(getRepoNameFromId(id, repositories), "", Util$BsConsole.identity));
                            }),
                          className: Css.style(/* :: */[
                                Css.width(Css.ch(20)),
                                /* :: */[
                                  Css.height(Css.rem(2)),
                                  /* :: */[
                                    Css.backgroundColor(/* transparent */582626130),
                                    /* :: */[
                                      Css.paddingLeft(Css.rem(0.75)),
                                      /* [] */0
                                    ]
                                  ]
                                ]
                              ]),
                          children: Belt_List.toArray(Belt_List.map(repositories, (function (repo) {
                                      var name = getRepoNameFromId(repo.id, repositories);
                                      if (name !== undefined) {
                                        return React.createElement(MenuItem.default, {
                                                    value: repo.id,
                                                    children: I18N$BsConsole.showSkip(name)
                                                  });
                                      } else {
                                        return null;
                                      }
                                    })))
                        }), TeamsUtils$BsConsole.isAdmin(config, projectName) ? React.createElement(React.Fragment, undefined, React.createElement(Mui$BsConsole.IconButton.make, {
                                ariaLabel: "Delete",
                                classes: {
                                  root: Css.style(/* :: */[
                                        Css.padding2(/* zero */-789508312, Css.rem(0.75)),
                                        /* [] */0
                                      ])
                                },
                                onClick: (function (param) {
                                    var id = rule$1.id;
                                    if (id !== undefined) {
                                      MetricsEvent$BsConsole.send(/* ActionWithAttrs */Block.__(1, [
                                              /* SourceCode_RuleDelete */88,
                                              Json_encode.object_(/* :: */[
                                                    /* tuple */[
                                                      "project",
                                                      projectName
                                                    ],
                                                    /* [] */0
                                                  ])
                                            ]));
                                      return Task2$BsConsole.handle(token, deleteRule(id, universeName, projectName, config), undefined, (function (_result) {
                                                    return Curry._1(refetchRepoRulesCb, undefined);
                                                  }));
                                    }
                                    
                                  }),
                                children: React.createElement(Icon.default, {
                                      children: I18N$BsConsole.showSkip("delete")
                                    })
                              }), React.createElement(Mui$BsConsole.IconButton.make, {
                                ariaLabel: "Move up",
                                classes: {
                                  root: Css.style(/* :: */[
                                        Css.padding2(/* zero */-789508312, Css.rem(0.75)),
                                        /* [] */0
                                      ])
                                },
                                onClick: moveUp,
                                children: React.createElement(ArrowUpward.default, { })
                              }), React.createElement(Mui$BsConsole.IconButton.make, {
                                ariaLabel: "Move down",
                                classes: {
                                  root: Css.style(/* :: */[
                                        Css.padding2(/* zero */-789508312, Css.rem(0.75)),
                                        /* [] */0
                                      ])
                                },
                                onClick: moveDown,
                                children: React.createElement(ArrowDownward.default, { })
                              })) : null)));
}

var Rule = {
  make: RepoRule$Rule
};

function RepoRule$Rules(Props) {
  var token = Props.token;
  var config = Props.config;
  var projectName = Props.projectName;
  var universeName = Props.universeName;
  var rules = Props.rules;
  var refetchRepoRulesCb = Props.refetchRepoRulesCb;
  var repositories = Props.repositories;
  var positionChangeCb = function (id, newPos) {
    var newPos$1 = newPos < 0 ? 0 : newPos;
    var newPos$2 = newPos$1 > (Belt_List.length(rules) - 1 | 0) ? Belt_List.length(rules) - 1 | 0 : newPos$1;
    var origRule = Belt_List.getBy(rules, (function (r) {
            return Caml_obj.caml_equal(r.id, id);
          }));
    if (origRule === undefined) {
      return ;
    }
    var newRule_id = origRule.id;
    var newRule_matchingRepositoryId = origRule.matchingRepositoryId;
    var newRule_fieldType = origRule.fieldType;
    var newRule_fieldName = origRule.fieldName;
    var newRule_action = origRule.action;
    var newRule_regex = origRule.regex;
    var newRule_projectName = origRule.projectName;
    var newRule_universeName = origRule.universeName;
    var newRule = {
      id: newRule_id,
      position: newPos$2,
      matchingRepositoryId: newRule_matchingRepositoryId,
      fieldType: newRule_fieldType,
      fieldName: newRule_fieldName,
      action: newRule_action,
      regex: newRule_regex,
      projectName: newRule_projectName,
      universeName: newRule_universeName
    };
    return Task2$BsConsole.handle(token, saveRule(newRule, universeName, projectName, config), undefined, (function (param) {
                  return Curry._1(refetchRepoRulesCb, undefined);
                }));
  };
  return React.createElement(Paper.default, {
              classes: {
                root: Css.style(/* :: */[
                      Css.padding(Css.rem(1.0)),
                      /* :: */[
                        Css.marginBottom(Css.rem(1.0)),
                        /* [] */0
                      ]
                    ])
              },
              children: React.createElement(Col2$BsConsole.make, {
                    children: Belt_List.toArray(Belt_List.map(Belt_List.sort(rules, (function (r, r$prime) {
                                    return r.position - r$prime.position | 0;
                                  })), (function (r) {
                                return Belt_Option.mapWithDefault(r.id, null, (function (id) {
                                              return React.createElement(RepoRule$Rule, {
                                                          config: config,
                                                          token: token,
                                                          projectName: projectName,
                                                          universeName: universeName,
                                                          refetchRepoRulesCb: refetchRepoRulesCb,
                                                          positionChangeCb: positionChangeCb,
                                                          rule: r,
                                                          repositories: repositories,
                                                          key: String(id) + String(r.position)
                                                        });
                                            }));
                              })))
                  })
            });
}

var Rules = {
  make: RepoRule$Rules
};

function RepoRule$NewRule(Props) {
  var config = Props.config;
  var token = Props.token;
  var projectName = Props.projectName;
  var repositories = Props.repositories;
  var universeName = Props.universeName;
  var ruleCreatedCb = Props.ruleCreatedCb;
  var close = Props.close;
  var match = React.useState((function () {
          
        }));
  var setFieldName = match[1];
  var fieldName = match[0];
  var match$1 = React.useState((function () {
          return /* Attribute */3;
        }));
  var setFieldType = match$1[1];
  var fieldType = match$1[0];
  var match$2 = React.useState((function () {
          return /* Match */0;
        }));
  var setActionType = match$2[1];
  var action = match$2[0];
  var match$3 = React.useState((function () {
          
        }));
  var setRegex = match$3[1];
  var regex = match$3[0];
  var match$4 = React.useState((function () {
          
        }));
  var setMatchingRepositoryId = match$4[1];
  var matchingRepositoryId = match$4[0];
  var match$5 = React.useState((function () {
          
        }));
  var setErrorMsg = match$5[1];
  var errorMsg = match$5[0];
  var ruleFormValid = function (param) {
    if (matchingRepositoryId !== undefined && fieldType !== undefined) {
      if (fieldType >= 3) {
        if (fieldName !== undefined && regex !== undefined && Util$BsConsole.nonEmptyStr(fieldName)) {
          return Util$BsConsole.nonEmptyStr(regex);
        } else {
          return false;
        }
      } else if (regex !== undefined) {
        return Util$BsConsole.nonEmptyStr(regex);
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  return React.createElement(Paper.default, {
              classes: {
                root: Css.style(/* :: */[
                      Css.padding(Css.rem(1.0)),
                      /* :: */[
                        Css.marginBottom(Css.rem(1.0)),
                        /* [] */0
                      ]
                    ])
              },
              children: React.createElement(Row2$BsConsole.make, {
                    alignItems: /* flexEnd */924268066,
                    flexWrap: /* wrap */-822134326,
                    className: Css.style(/* :: */[
                          Css.marginBottom(Css.rem(0.5)),
                          /* [] */0
                        ]),
                    children: null
                  }, React.createElement("div", {
                        className: Css.style(/* :: */[
                              Css.fontSize(Css.em(1)),
                              /* :: */[
                                Css.lineHeight(Css.em(2)),
                                /* :: */[
                                  Css.marginRight(Css.rem(1)),
                                  /* :: */[
                                    Css.color(Css.hex(Colors$BsConsole.blackA54)),
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ])
                      }, I18N$BsConsole.show(undefined, "IF")), React.createElement(Col2$BsConsole.make, {
                        children: null
                      }, React.createElement(InputLabel.default, {
                            classes: {
                              root: Css.style(/* :: */[
                                    Css.marginTop(Css.px(8)),
                                    /* :: */[
                                      Css.marginBottom(Css.px(3)),
                                      /* [] */0
                                    ]
                                  ])
                            },
                            children: React.createElement(BtTypography$BsConsole.make, {
                                  variant: /* Caption */11,
                                  children: I18N$BsConsole.show(undefined, "Type")
                                })
                          }), React.createElement(Select.default, {
                            value: fieldType,
                            onChange: (function (e, param) {
                                var value = e.target.value;
                                return Curry._1(setFieldType, (function (param) {
                                              return value;
                                            }));
                              }),
                            input: React.createElement(OutlinedInput.default, { }),
                            renderValue: (function (v) {
                                return I18N$BsConsole.showSkip(Belt_Option.mapWithDefault(v, "", toFriendlyString));
                              }),
                            className: Css.style(/* :: */[
                                  Css.backgroundColor(/* transparent */582626130),
                                  /* :: */[
                                    Css.paddingLeft(Css.rem(0.75)),
                                    /* :: */[
                                      Css.height(Css.rem(2)),
                                      /* :: */[
                                        Css.width(Css.ch(17)),
                                        /* :: */[
                                          Css.marginRight(Css.rem(1)),
                                          /* [] */0
                                        ]
                                      ]
                                    ]
                                  ]
                                ]),
                            variant: "outlined",
                            children: null
                          }, React.createElement(MenuItem.default, {
                                value: /* Attribute */3,
                                children: I18N$BsConsole.showSkip(I18N$BsConsole.get(undefined, "Attribute"))
                              }), React.createElement(MenuItem.default, {
                                value: /* FileName */0,
                                children: I18N$BsConsole.showSkip(I18N$BsConsole.get(undefined, "File name"))
                              }), React.createElement(MenuItem.default, {
                                value: /* FunctionName */1,
                                children: I18N$BsConsole.showSkip(I18N$BsConsole.get(undefined, "Function name"))
                              }), React.createElement(MenuItem.default, {
                                value: /* ObjectName */2,
                                children: I18N$BsConsole.showSkip(I18N$BsConsole.get(undefined, "Object name"))
                              }))), fieldType !== undefined && fieldType >= 3 ? React.createElement(Col2$BsConsole.make, {
                          children: null
                        }, React.createElement(InputLabel.default, {
                              classes: {
                                root: Css.style(/* :: */[
                                      Css.marginTop(Css.px(8)),
                                      /* :: */[
                                        Css.marginBottom(Css.px(3)),
                                        /* [] */0
                                      ]
                                    ])
                              },
                              children: React.createElement(BtTypography$BsConsole.make, {
                                    variant: /* Caption */11,
                                    children: I18N$BsConsole.show(undefined, "Attribute name")
                                  })
                            }), React.createElement(TextField.default, {
                              placeholder: I18N$BsConsole.get(undefined, "Attribute name"),
                              value: Belt_Option.mapWithDefault(fieldName, "", Util$BsConsole.identity),
                              color: "primary",
                              variant: "outlined",
                              classes: {
                                root: Css.style(/* :: */[
                                      Css.width(Css.ch(20)),
                                      /* :: */[
                                        Css.marginRight(Css.rem(1)),
                                        /* :: */[
                                          Css.selector("input", /* :: */[
                                                Css.important(Css.paddingTop(Css.px(6))),
                                                /* :: */[
                                                  Css.important(Css.paddingBottom(Css.px(7))),
                                                  /* [] */0
                                                ]
                                              ]),
                                          /* :: */[
                                            Css.selector("div[class*='multiline']", /* :: */[
                                                  Css.important(Css.paddingTop(Css.px(10))),
                                                  /* [] */0
                                                ]),
                                            /* [] */0
                                          ]
                                        ]
                                      ]
                                    ])
                              },
                              onChange: (function ($$event) {
                                  return Curry._1(setFieldName, BtSettings$BsConsole.getInputValue($$event));
                                })
                            })) : null, React.createElement(Col2$BsConsole.make, {
                        children: null
                      }, React.createElement(InputLabel.default, {
                            classes: {
                              root: Css.style(/* :: */[
                                    Css.marginTop(Css.px(8)),
                                    /* :: */[
                                      Css.marginBottom(Css.px(3)),
                                      /* [] */0
                                    ]
                                  ])
                            },
                            children: React.createElement(BtTypography$BsConsole.make, {
                                  variant: /* Caption */11,
                                  children: I18N$BsConsole.show(undefined, "Regex")
                                })
                          }), React.createElement(TextField.default, {
                            placeholder: I18N$BsConsole.get(undefined, "Regex"),
                            value: Belt_Option.mapWithDefault(regex, "", Util$BsConsole.identity),
                            color: "primary",
                            variant: "outlined",
                            classes: {
                              root: Css.style(/* :: */[
                                    Caml_obj.caml_equal(fieldType, /* Attribute */3) ? Css.width(Css.ch(20)) : Css.width(Css.ch(42)),
                                    /* :: */[
                                      Css.marginRight(Css.rem(1)),
                                      /* :: */[
                                        Css.selector("input", /* :: */[
                                              Css.important(Css.paddingTop(Css.px(6))),
                                              /* :: */[
                                                Css.important(Css.paddingBottom(Css.px(7))),
                                                /* [] */0
                                              ]
                                            ]),
                                        /* :: */[
                                          Css.selector("div[class*='multiline']", /* :: */[
                                                Css.important(Css.paddingTop(Css.px(10))),
                                                /* [] */0
                                              ]),
                                          /* [] */0
                                        ]
                                      ]
                                    ]
                                  ])
                            },
                            onChange: (function ($$event) {
                                return Curry._1(setRegex, BtSettings$BsConsole.getInputValue($$event));
                              })
                          })), React.createElement("div", {
                        className: Css.style(/* :: */[
                              Css.fontSize(Css.em(1)),
                              /* :: */[
                                Css.lineHeight(Css.em(2)),
                                /* :: */[
                                  Css.marginRight(Css.rem(1)),
                                  /* :: */[
                                    Css.color(Css.hex(Colors$BsConsole.blackA54)),
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ])
                      }, I18N$BsConsole.show(undefined, "THEN")), React.createElement(Col2$BsConsole.make, {
                        children: null
                      }, React.createElement(InputLabel.default, {
                            classes: {
                              root: Css.style(/* :: */[
                                    Css.marginTop(Css.px(8)),
                                    /* :: */[
                                      Css.marginBottom(Css.px(3)),
                                      /* [] */0
                                    ]
                                  ])
                            },
                            children: React.createElement(BtTypography$BsConsole.make, {
                                  variant: /* Caption */11,
                                  children: I18N$BsConsole.show(undefined, "Action")
                                })
                          }), React.createElement(Select.default, {
                            value: action,
                            onChange: (function (e, param) {
                                var value = e.target.value;
                                return Curry._1(setActionType, (function (param) {
                                              return value;
                                            }));
                              }),
                            input: React.createElement(OutlinedInput.default, { }),
                            renderValue: (function (v) {
                                return I18N$BsConsole.showSkip(toFriendlyString$1(v));
                              }),
                            className: Css.style(/* :: */[
                                  Css.height(Css.rem(2)),
                                  /* :: */[
                                    Css.backgroundColor(/* transparent */582626130),
                                    /* :: */[
                                      Css.paddingLeft(Css.rem(0.75)),
                                      /* :: */[
                                        Css.width(Css.ch(21)),
                                        /* :: */[
                                          Css.marginRight(Css.rem(1)),
                                          /* [] */0
                                        ]
                                      ]
                                    ]
                                  ]
                                ]),
                            children: null
                          }, React.createElement(MenuItem.default, {
                                value: /* Match */0,
                                children: I18N$BsConsole.showSkip(I18N$BsConsole.get(undefined, "Use this repository"))
                              }), React.createElement(MenuItem.default, {
                                value: /* Skip */1,
                                children: I18N$BsConsole.showSkip(I18N$BsConsole.get(undefined, "Skip this repository"))
                              }))), React.createElement(Col2$BsConsole.make, {
                        children: null
                      }, React.createElement(InputLabel.default, {
                            classes: {
                              root: Css.style(/* :: */[
                                    Css.marginTop(Css.px(8)),
                                    /* :: */[
                                      Css.marginBottom(Css.px(3)),
                                      /* [] */0
                                    ]
                                  ])
                            },
                            children: React.createElement(BtTypography$BsConsole.make, {
                                  variant: /* Caption */11,
                                  children: I18N$BsConsole.show(undefined, "Repository")
                                })
                          }), React.createElement(Row2$BsConsole.make, {
                            alignItems: /* center */98248149,
                            children: React.createElement(Select.default, {
                                  value: matchingRepositoryId,
                                  onChange: (function (e, param) {
                                      var value = e.target.value;
                                      return Curry._1(setMatchingRepositoryId, (function (param) {
                                                    return value;
                                                  }));
                                    }),
                                  input: React.createElement(OutlinedInput.default, { }),
                                  renderValue: (function (id) {
                                      return I18N$BsConsole.showSkip(Belt_Option.mapWithDefault(getRepoNameFromId(id, repositories), "", Util$BsConsole.identity));
                                    }),
                                  className: Css.style(/* :: */[
                                        Css.width(Css.ch(20)),
                                        /* :: */[
                                          Css.height(Css.rem(2)),
                                          /* :: */[
                                            Css.backgroundColor(/* transparent */582626130),
                                            /* :: */[
                                              Css.paddingLeft(Css.rem(0.75)),
                                              /* :: */[
                                                Css.marginRight(Css.rem(1)),
                                                /* [] */0
                                              ]
                                            ]
                                          ]
                                        ]
                                      ]),
                                  children: Belt_List.toArray(Belt_List.map(repositories, (function (repo) {
                                              var match = repo.cloneStatus;
                                              var match$1 = getRepoNameFromId(repo.id, repositories);
                                              if (match !== undefined && match === "complete" && match$1 !== undefined) {
                                                return React.createElement(MenuItem.default, {
                                                            value: repo.id,
                                                            children: I18N$BsConsole.showSkip(match$1)
                                                          });
                                              } else {
                                                return null;
                                              }
                                            })))
                                })
                          })), React.createElement(Button.default, {
                        variant: ruleFormValid(undefined) ? "contained" : "outlined",
                        color: "primary",
                        disabled: !ruleFormValid(undefined),
                        classes: {
                          root: Css.style(/* :: */[
                                Css.whiteSpace(/* nowrap */867913355),
                                /* :: */[
                                  Css.marginRight(Css.rem(0.25)),
                                  /* [] */0
                                ]
                              ])
                        },
                        onClick: (function (param) {
                            Curry._1(setErrorMsg, (function (param) {
                                    
                                  }));
                            if (fieldType !== undefined && regex !== undefined && matchingRepositoryId !== undefined) {
                              MetricsEvent$BsConsole.send(/* ActionWithAttrs */Block.__(1, [
                                      /* SourceCode_RuleCreate */86,
                                      Json_encode.object_(/* :: */[
                                            /* tuple */[
                                              "project",
                                              projectName
                                            ],
                                            /* [] */0
                                          ])
                                    ]));
                              var newRule_fieldName = Belt_Option.mapWithDefault(fieldName, "", Util$BsConsole.identity);
                              var newRule = {
                                id: undefined,
                                position: 0,
                                matchingRepositoryId: matchingRepositoryId,
                                fieldType: fieldType,
                                fieldName: newRule_fieldName,
                                action: action,
                                regex: regex,
                                projectName: projectName,
                                universeName: universeName
                              };
                              Task2$BsConsole.handle(token, saveRule(newRule, universeName, projectName, config), undefined, (function (_resp) {
                                      Curry._1(setFieldName, (function (param) {
                                              
                                            }));
                                      Curry._1(setFieldType, (function (param) {
                                              
                                            }));
                                      Curry._1(setRegex, (function (param) {
                                              
                                            }));
                                      Curry._1(setActionType, (function (param) {
                                              return /* Match */0;
                                            }));
                                      return Curry._1(ruleCreatedCb, undefined);
                                    }));
                            }
                            
                          }),
                        children: I18N$BsConsole.show(undefined, "Create Rule")
                      }), React.createElement(Tooltip.default, {
                        children: React.createElement(Button.default, {
                              classes: {
                                root: Css.style(/* :: */[
                                      Css.marginTop(Css.rem(0.5)),
                                      /* [] */0
                                    ])
                              },
                              onClick: (function (param) {
                                  return Curry._1(close, undefined);
                                }),
                              children: React.createElement(Close.default, { })
                            }),
                        title: I18N$BsConsole.get(undefined, "close")
                      }), errorMsg !== undefined ? React.createElement(BtTypography$BsConsole.make, {
                          variant: /* Heading4 */3,
                          className: Css.style(/* :: */[
                                Css.marginTop(Css.px(3)),
                                /* :: */[
                                  Css.fontSize(Css.px(18)),
                                  /* [] */0
                                ]
                              ]),
                          color: Colors$BsConsole.errorDark,
                          children: I18N$BsConsole.showSkip(errorMsg)
                        }) : null)
            });
}

var NewRule = {
  make: RepoRule$NewRule
};

exports.FieldType = FieldType;
exports.Action = Action;
exports.fromJson = fromJson;
exports.toJson = toJson;
exports.fetchRules = fetchRules;
exports.saveRule = saveRule;
exports.deleteRule = deleteRule;
exports.getRepoNameFromId = getRepoNameFromId;
exports.Rule = Rule;
exports.Rules = Rules;
exports.NewRule = NewRule;
/* Css Not a pure module */
