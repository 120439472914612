// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var Route$BsConsole = require("../route.js");
var Task2$BsConsole = require("../Task2.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var Uncaught$BsConsole = require("../Uncaught.js");
var Backtrace$BsConsole = require("../bindings/Backtrace.js");
var ApiDescribe$BsConsole = require("../apiDescribe.js");
var MetricsEvent$BsConsole = require("../bs-metrics/MetricsEvent.js");

var ParseError = Caml_exceptions.create("NaturalLanguageApi-BsConsole.ParseError");

function toJson(t) {
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "query",
                t.query
              ],
              /* :: */[
                /* tuple */[
                  "attributes",
                  Belt_Array.map(t.attributes, ApiDescribe$BsConsole.toJson)
                ],
                /* [] */0
              ]
            ]);
}

var SkyNetRequest = {
  toJson: toJson
};

function fromJson(json) {
  return {
          group: Json_decode.optional((function (param) {
                  return Json_decode.field("group", Json_decode.string, param);
                }), json),
          filter: Json_decode.optional((function (param) {
                  return Json_decode.field("filter", Json_decode.string, param);
                }), json),
          timeFrame: Json_decode.optional((function (param) {
                  return Json_decode.field("timeframe", Json_decode.string, param);
                }), json)
        };
}

var SkyNetResult = {
  fromJson: fromJson
};

function fromJson$1(json) {
  return {
          result: Json_decode.field("result", fromJson, json),
          status: Json_decode.field("status", Json_decode.string, json)
        };
}

var SkyNetResponse = {
  fromJson: fromJson$1
};

function makeTask(question, attributes, clairvoyantUrl) {
  return Task2$BsConsole.make(/* Custom */Block.__(1, [clairvoyantUrl + "/question"]), undefined, /* Post */Block.__(0, [toJson({
                      query: question,
                      attributes: attributes
                    })]), undefined, (function (param) {
                try {
                  var response = fromJson$1(param.json);
                  return /* Ok */Block.__(0, [response]);
                }
                catch (raw_err){
                  var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                  var error = Uncaught$BsConsole.castToJsExn(err);
                  Backtrace$BsConsole.Client.reportWithSourceModule("NaturalLanguageApi/makeTask", error);
                  return /* Error */Block.__(1, [[
                              ParseError,
                              error
                            ]]);
                }
              }), undefined, undefined);
}

function responseCb(onSuccess, onFailure, resp) {
  if (!resp.tag) {
    return Curry._1(onSuccess, resp[0]);
  }
  var error = resp[0];
  var tmp;
  if (error[0] === ParseError) {
    var message = error[1].message;
    tmp = message !== undefined ? "ParseError" + message : "ParseError: There was an error parsing data";
  } else {
    tmp = "Something went wrong. This error has been reported to Backtrace.";
  }
  return Curry._1(onFailure, tmp);
}

function askQuestion(question, token, clairvoyantUrl, attributes, onSuccess, onFailure) {
  var questionTask = makeTask(question, attributes, clairvoyantUrl);
  return Task2$BsConsole.handle(token, questionTask, undefined, (function (param) {
                return responseCb(onSuccess, onFailure, param);
              }));
}

function buildUrlFromAnswer(answer, projectName) {
  var base = "/p/" + projectName;
  var match = answer.timeFrame;
  var match$1 = answer.group;
  var match$2 = answer.filter;
  if (match !== undefined) {
    if (match$1 !== undefined) {
      if (match$2 !== undefined) {
        return base + ("/explore?time=" + (match + ("&groupBy=" + (match$1 + ("&filters=" + btoa(match$2))))));
      } else {
        return base + ("/explore?time=" + (match + ("&groupBy=" + match$1)));
      }
    } else if (match$2 !== undefined) {
      return base + ("/explore?time=" + (match + ("&filters=" + btoa(match$2))));
    } else {
      return base + ("/explore?time=" + match);
    }
  } else if (match$1 !== undefined) {
    if (match$2 !== undefined) {
      return base + ("/explore?groupBy=" + (match$1 + ("&filters=" + btoa(match$2))));
    } else {
      return base + ("/explore?groupBy=" + match$1);
    }
  } else if (match$2 !== undefined) {
    return base + ("/explore?filters=" + btoa(match$2));
  } else {
    return base + "/explore";
  }
}

function buildRouteFromAnswer(answer, projectName) {
  var url = buildUrlFromAnswer(answer, projectName);
  var fullPath = url.split("?");
  var __x = Belt_Option.getWithDefault(Belt_Array.get(fullPath, 0), "").split("/");
  var path = Belt_List.fromArray(Belt_Array.keep(__x, (function (s) {
              return s !== "";
            })));
  var search = Belt_Option.getWithDefault(Belt_Array.get(fullPath, 1), "");
  return Route$BsConsole.routeForPathAndSearch(undefined, path, search);
}

function getTransactionID(projectName) {
  return projectName + ("-" + Pervasives.string_of_float(Date.now() * Math.random()).substring(2, 12));
}

function writeTranactionIdToLocalStorage(tid) {
  localStorage.setItem("nlq-tid", tid);
  
}

function readTranactionIdFromLocalStorage(param) {
  return Caml_option.null_to_opt(localStorage.getItem("nlq-tid"));
}

function sendQuestionMetric(tid, url, resp, question) {
  return MetricsEvent$BsConsole.send(/* ActionWithAttrs */Block.__(1, [
                /* NLQ_QuestionAsked */23,
                Json_encode.object_(/* :: */[
                      /* tuple */[
                        "nlq-tid",
                        tid
                      ],
                      /* :: */[
                        /* tuple */[
                          "nlq-url",
                          url
                        ],
                        /* :: */[
                          /* tuple */[
                            "nlq-resp",
                            Belt_Option.getWithDefault(JSON.stringify(resp), "unknown")
                          ],
                          /* :: */[
                            /* tuple */[
                              "nlq-question",
                              question
                            ],
                            /* [] */0
                          ]
                        ]
                      ]
                    ])
              ]));
}

function sendBadRobotMetric(tid) {
  return MetricsEvent$BsConsole.send(/* ActionWithAttrs */Block.__(1, [
                /* NLQ_BadRobot */24,
                Json_encode.object_(/* :: */[
                      /* tuple */[
                        "nlq-tid",
                        tid
                      ],
                      /* [] */0
                    ])
              ]));
}

exports.ParseError = ParseError;
exports.SkyNetRequest = SkyNetRequest;
exports.SkyNetResult = SkyNetResult;
exports.SkyNetResponse = SkyNetResponse;
exports.makeTask = makeTask;
exports.responseCb = responseCb;
exports.askQuestion = askQuestion;
exports.buildUrlFromAnswer = buildUrlFromAnswer;
exports.buildRouteFromAnswer = buildRouteFromAnswer;
exports.getTransactionID = getTransactionID;
exports.writeTranactionIdToLocalStorage = writeTranactionIdToLocalStorage;
exports.readTranactionIdFromLocalStorage = readTranactionIdFromLocalStorage;
exports.sendQuestionMetric = sendQuestionMetric;
exports.sendBadRobotMetric = sendBadRobotMetric;
/* Route-BsConsole Not a pure module */
