// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var RR$BsConsole = require("../RR.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var I18N$BsConsole = require("../I18N.js");
var Colors$BsConsole = require("../Colors.js");
var CommentsUtil$BsConsole = require("./CommentsUtil.js");
var CommentContent$BsConsole = require("./CommentContent.js");

var component = RR$BsConsole.statelessComponent("CommentInput-BsConsole");

var mainWrapper = Css.style(/* :: */[
      Css.width(Css.pct(100)),
      /* :: */[
        Css.marginBottom(Css.rem(1)),
        /* [] */0
      ]
    ]);

var commentInputContainer = Css.merge(/* :: */[
      Css.style(/* :: */[
            Css.border(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey6)),
            /* :: */[
              Css.borderRadius(Css.px(4)),
              /* :: */[
                Css.width(Css.pct(100)),
                /* :: */[
                  Css.display(/* flex */-1010954439),
                  /* [] */0
                ]
              ]
            ]
          ]),
      /* :: */[
        "CommentInput_container",
        /* [] */0
      ]
    ]);

var commentContentWrapper = Css.style(/* :: */[
      Css.flexGrow(1),
      /* :: */[
        Css.minHeight(Css.px(80)),
        /* [] */0
      ]
    ]);

var helperText = Css.style(/* :: */[
      Css.paddingLeft(Css.px(15)),
      /* [] */0
    ]);

var postButton = Css.style(/* :: */[
      Css.alignSelf(Css.flexEnd),
      /* :: */[
        Css.marginRight(Css.px(8)),
        /* :: */[
          Css.marginBottom(Css.px(8)),
          /* [] */0
        ]
      ]
    ]);

var Styles = {
  mainWrapper: mainWrapper,
  commentInputContainer: commentInputContainer,
  commentContentWrapper: commentContentWrapper,
  helperText: helperText,
  postButton: postButton
};

function make(resultValue, invalidMentions, onChange, allUsers, activeUser, onSendComment, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (param) {
              var tmp;
              if (resultValue.tag) {
                tmp = ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                          undefined,
                          postButton,
                          undefined,
                          undefined,
                          undefined,
                          /* Raised */-387916264,
                          /* Primary */-791844958,
                          undefined,
                          undefined,
                          true,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          [I18N$BsConsole.show(undefined, "Post")]
                        ]));
              } else {
                var value = resultValue[0];
                tmp = ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                          undefined,
                          postButton,
                          (function (param) {
                              return Curry._2(onSendComment, value, undefined);
                            }),
                          undefined,
                          undefined,
                          /* Raised */-387916264,
                          /* Primary */-791844958,
                          undefined,
                          undefined,
                          invalidMentions.length !== 0,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          undefined,
                          [I18N$BsConsole.show(undefined, "Post")]
                        ]));
              }
              var match = invalidMentions.length !== 0;
              var tmp$1;
              if (match) {
                var invalidUserMentions = Belt_Array.reduceWithIndex(invalidMentions, "", (function (acc, curr, idx) {
                        return acc + ((
                                  idx === 0 ? "" : ", "
                                ) + curr);
                      }));
                tmp$1 = ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.FormHelperText.Jsx2.make, undefined, helperText, undefined, true, [I18N$BsConsole.show(undefined, "The following usernames are invalid: " + invalidUserMentions)]));
              } else {
                tmp$1 = resultValue.tag ? ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.FormHelperText.Jsx2.make, undefined, helperText, undefined, true, [I18N$BsConsole.showSkip(resultValue[0])])) : null;
              }
              return React.createElement("div", {
                          className: mainWrapper
                        }, React.createElement("div", {
                              className: commentInputContainer
                            }, React.createElement("div", {
                                  className: commentContentWrapper
                                }, ReasonReact.element(undefined, undefined, CommentContent$BsConsole.Jsx2.make(true, allUsers, activeUser, resultValue, Curry.__1(onChange), []))), tmp), tmp$1);
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

var initialValue = CommentsUtil$BsConsole.initialValue;

exports.initialValue = initialValue;
exports.component = component;
exports.Styles = Styles;
exports.make = make;
/* component Not a pure module */
