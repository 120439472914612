// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Printf = require("bs-platform/lib/js/printf.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var I18N$BsConsole = require("../I18N.js");
var Util$BsConsole = require("../util.js");
var Regex$BsConsole = require("../Regex.js");
var Task2$BsConsole = require("../Task2.js");
var ApiList$BsConsole = require("../apiList.js");
var BtTable$BsConsole = require("../BtTable.js");
var BtSettings$BsConsole = require("../BtSettings.js");
var BtTableRow$BsConsole = require("../BtTableRow.js");
var BtTableBody$BsConsole = require("../BtTableBody.js");
var BtTableCell$BsConsole = require("../BtTableCell.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var MetricsEvent$BsConsole = require("../bs-metrics/MetricsEvent.js");
var ResourceFork$BsConsole = require("../ResourceFork.js");
var Button = require("@material-ui/core/Button");
var Dialog = require("@material-ui/core/Dialog");
var Close = require("@material-ui/icons/Close");
var Tooltip = require("@material-ui/core/Tooltip");
var GetApp = require("@material-ui/icons/GetApp");
var CardContent = require("@material-ui/core/CardContent");
var Visibility = require("@material-ui/icons/Visibility");
var CircularProgress = require("@material-ui/core/CircularProgress");

function extentionToPlugin(name) {
  return Belt_Option.flatMap(Caml_option.undefined_to_opt(name.split(".").pop()), (function (ext) {
                var extention = ext.toLowerCase();
                switch (extention) {
                  case "mp4" :
                      return /* Embed */["video/webm"];
                  case "bmp" :
                  case "gif" :
                  case "jpg" :
                  case "png" :
                      break;
                  case "mp3" :
                  case "wav" :
                      return /* Embed */["audio/" + extention];
                  case "ini" :
                  case "json" :
                  case "log" :
                  case "runtime-xml" :
                  case "txt" :
                  case "xml" :
                      return /* Fetch */1;
                  default:
                    return ;
                }
                return /* Embed */["image/" + extention];
              }));
}

function isValidContentType(param) {
  if (param === "application/octet-stream") {
    return false;
  } else {
    return true;
  }
}

function findViewer(name, contentType) {
  if (isValidContentType(contentType)) {
    return /* Natural */0;
  } else {
    return extentionToPlugin(name);
  }
}

function BugAttachments$IFrame(Props) {
  var url = Props.url;
  var name = Props.name;
  var contentType = Props.contentType;
  var match = React.useState((function () {
          
        }));
  var setRef = match[1];
  var ref_ = match[0];
  React.useEffect((function () {
          var match = findViewer(name, contentType);
          if (ref_ !== undefined && match !== undefined) {
            var ele = Caml_option.valFromOption(ref_);
            if (typeof match === "number") {
              if (match !== 0) {
                ele.contentWindow.document.open();
                ele.contentWindow.document.write(Curry._1(Printf.sprintf(/* Format */[
                              /* String_literal */Block.__(11, [
                                  "\n                <pre id=\"text-pre\"/>\n                <script>\n                  (function(){\n                    try{\n                      fetch(\"",
                                  /* String */Block.__(2, [
                                      /* No_padding */0,
                                      /* String_literal */Block.__(11, [
                                          "\").then(resp=>resp.text()).then(text=>{\n                        document.getElementById(\"text-pre\").textContent=text;\n                      })\n                    } catch(err) {\n                      console.error(err.message)\n                    }\n                  })()\n                </script>\n          ",
                                          /* End_of_format */0
                                        ])
                                    ])
                                ]),
                              "\n                <pre id=\"text-pre\"/>\n                <script>\n                  (function(){\n                    try{\n                      fetch(\"%s\").then(resp=>resp.text()).then(text=>{\n                        document.getElementById(\"text-pre\").textContent=text;\n                      })\n                    } catch(err) {\n                      console.error(err.message)\n                    }\n                  })()\n                </script>\n          "
                            ]), url));
                ele.contentWindow.document.close();
              } else {
                ele.src = url;
              }
            } else {
              ele.contentWindow.document.open();
              ele.contentWindow.document.write(Curry._2(Printf.sprintf(/* Format */[
                            /* String_literal */Block.__(11, [
                                "\n            <embed\n              type=\"",
                                /* String */Block.__(2, [
                                    /* No_padding */0,
                                    /* String_literal */Block.__(11, [
                                        "\"\n              src=\"",
                                        /* String */Block.__(2, [
                                            /* No_padding */0,
                                            /* String_literal */Block.__(11, [
                                                "\"\n            >\n          ",
                                                /* End_of_format */0
                                              ])
                                          ])
                                      ])
                                  ])
                              ]),
                            "\n            <embed\n              type=\"%s\"\n              src=\"%s\"\n            >\n          "
                          ]), match[0], url));
              ele.contentWindow.document.close();
            }
          }
          
        }), [ref_]);
  return React.createElement("iframe", {
              ref: (function (el) {
                  return Curry._1(setRef, (function (param) {
                                if (el == null) {
                                  return ;
                                } else {
                                  return Caml_option.some(el);
                                }
                              }));
                }),
              className: Css.style(/* :: */[
                    Css.borderRadius(Css.px(4)),
                    /* :: */[
                      Css.unsafe("border", "unset"),
                      /* [] */0
                    ]
                  ]),
              height: String(window.innerHeight - 235 | 0),
              width: String(window.innerWidth - 160 | 0)
            });
}

var IFrame = {
  extentionToPlugin: extentionToPlugin,
  isValidContentType: isValidContentType,
  findViewer: findViewer,
  make: BugAttachments$IFrame
};

function BugAttachments(Props) {
  var token = Props.token;
  var attachments = Props.attachments;
  var projectName = Props.projectName;
  var errorId = Props.errorId;
  var match = React.useState((function () {
          
        }));
  var setInline = match[1];
  var inline = match[0];
  var resourceList = ResourceFork$BsConsole.use(token, projectName, errorId);
  if (typeof attachments === "number") {
    attachments === /* NotAsked */0;
  } else {
    if (attachments.tag) {
      return React.createElement("div", undefined, I18N$BsConsole.dynamic(Task2$BsConsole.renderError(undefined, attachments[0])));
    }
    var tmp;
    if (inline !== undefined) {
      var tmp$1 = {
        key: inline.name,
        href: ApiList$BsConsole.getPath(errorId, token, projectName, inline)
      };
      var tmp$2 = inline.inline ? undefined : "";
      if (tmp$2 !== undefined) {
        tmp$1.download = Caml_option.valFromOption(tmp$2);
      }
      var tmp$3 = inline.inline ? "_blank" : undefined;
      if (tmp$3 !== undefined) {
        tmp$1.target = Caml_option.valFromOption(tmp$3);
      }
      tmp = React.createElement("div", undefined, React.createElement("a", tmp$1, React.createElement(Button.default, {
                    onClick: (function (param) {
                        return MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* Debugger_DownloadAttachmentClick */112]));
                      }),
                    children: React.createElement("div", {
                          className: Css.style(/* :: */[
                                Css.display(/* flex */-1010954439),
                                /* :: */[
                                  Css.alignItems(/* center */98248149),
                                  /* [] */0
                                ]
                              ])
                        }, React.createElement(BtTypography$BsConsole.make, {
                              variant: /* Subtitle2 */7,
                              children: I18N$BsConsole.show(undefined, "Download")
                            }), React.createElement(GetApp.default, { }))
                  })));
    } else {
      tmp = null;
    }
    return React.createElement("div", {
                className: Css.style(/* :: */[
                      Css.width(Css.pct(100)),
                      /* :: */[
                        Css.height(Css.pct(100)),
                        /* [] */0
                      ]
                    ])
              }, React.createElement(Dialog.default, {
                    open: Belt_Option.isSome(inline),
                    onClose: (function (param) {
                        return Curry._1(setInline, (function (param) {
                                      
                                    }));
                      }),
                    maxWidth: "false",
                    fullWidth: true,
                    children: React.createElement(CardContent.default, {
                          children: null
                        }, React.createElement("div", {
                              className: Css.merge(/* :: */[
                                    "smaller-icon",
                                    /* :: */[
                                      Css.style(/* :: */[
                                            Css.width(Css.pct(100)),
                                            /* :: */[
                                              Css.display(/* flex */-1010954439),
                                              /* :: */[
                                                Css.justifyContent(/* spaceBetween */516682146),
                                                /* :: */[
                                                  Css.alignItems(/* center */98248149),
                                                  /* [] */0
                                                ]
                                              ]
                                            ]
                                          ]),
                                      /* [] */0
                                    ]
                                  ])
                            }, React.createElement(BtTypography$BsConsole.make, {
                                  variant: /* Body2 */9,
                                  children: I18N$BsConsole.showSkip(Belt_Option.getWithDefault(Belt_Option.map(inline, (function (i) {
                                                  return i.name;
                                                })), ""))
                                }), React.createElement("div", undefined, React.createElement(Mui$BsConsole.IconButton.make, {
                                      ariaLabel: "Close",
                                      onClick: (function (param) {
                                          return Curry._1(setInline, (function (param) {
                                                        
                                                      }));
                                        }),
                                      children: React.createElement(Close.default, { })
                                    }))), React.createElement(BtSettings$BsConsole.FancyDivider.make, { }), tmp, React.createElement("div", undefined, inline !== undefined ? React.createElement(BugAttachments$IFrame, {
                                    url: ApiList$BsConsole.getPath(errorId, token, projectName, inline),
                                    name: inline.name,
                                    contentType: inline.content_type
                                  }) : null), React.createElement("div", {
                              className: Css.style(/* :: */[
                                    Css.paddingLeft(Css.px(3)),
                                    /* [] */0
                                  ])
                            }, React.createElement(BtTypography$BsConsole.make, {
                                  variant: /* Body2 */9,
                                  children: I18N$BsConsole.show(undefined, "Not seeing what you expect? Let us know by using the Support button on the bottom right.")
                                })))
                  }), React.createElement(BtTable$BsConsole.make, {
                    children: React.createElement(BtTableBody$BsConsole.make, {
                          children: null
                        }, Belt_Array.map(attachments[0][0], (function (file) {
                                var tmp = {
                                  key: file.name,
                                  className: Css.style(/* :: */[
                                        Css.paddingRight(Css.px(3)),
                                        /* [] */0
                                      ]),
                                  href: ApiList$BsConsole.getPath(errorId, token, projectName, file)
                                };
                                var tmp$1 = file.inline ? undefined : "";
                                if (tmp$1 !== undefined) {
                                  tmp.download = Caml_option.valFromOption(tmp$1);
                                }
                                var tmp$2 = file.inline ? "_blank" : undefined;
                                if (tmp$2 !== undefined) {
                                  tmp.target = Caml_option.valFromOption(tmp$2);
                                }
                                return React.createElement(BtTableRow$BsConsole.make, {
                                            children: null
                                          }, React.createElement(Tooltip.default, {
                                                enterDelay: 1000,
                                                children: React.createElement(BtTableCell$BsConsole.Data.make, {
                                                      className: Css.style(/* :: */[
                                                            Css.fontFamily("monospace"),
                                                            /* [] */0
                                                          ]),
                                                      children: I18N$BsConsole.showSkip(file.name)
                                                    }),
                                                title: file.name
                                              }), React.createElement(BtTableCell$BsConsole.Data.make, {
                                                className: Css.style(/* :: */[
                                                      Css.fontFamily("monospace"),
                                                      /* [] */0
                                                    ]),
                                                children: I18N$BsConsole.showSkip(Util$BsConsole.formatBytes(Pervasives.string_of_float(file.size)))
                                              }), React.createElement(BtTableCell$BsConsole.Data.make, {
                                                children: null
                                              }, React.createElement("a", tmp, React.createElement(Button.default, {
                                                        size: "small",
                                                        onClick: (function (param) {
                                                            return MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* Debugger_DownloadAttachmentClick */112]));
                                                          }),
                                                        children: null
                                                      }, I18N$BsConsole.show(undefined, "Download"), React.createElement(GetApp.default, {
                                                            className: Css.style(/* :: */[
                                                                  Css.paddingLeft(Css.px(3)),
                                                                  /* [] */0
                                                                ])
                                                          }))), React.createElement(Button.default, {
                                                    size: "small",
                                                    disabled: !Regex$BsConsole.isOfPreviewableExtension(file.name),
                                                    onClick: (function (param) {
                                                        return Curry._1(setInline, (function (param) {
                                                                      MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* Debugger_PreviewAttachmentClick */111]));
                                                                      return file;
                                                                    }));
                                                      }),
                                                    children: null
                                                  }, I18N$BsConsole.show(undefined, "Preview"), React.createElement(Visibility.default, {
                                                        className: Css.style(/* :: */[
                                                              Css.paddingLeft(Css.px(3)),
                                                              /* [] */0
                                                            ])
                                                      }))));
                              })), Belt_Array.map(resourceList, (function (resource) {
                                return React.createElement(BtTableRow$BsConsole.make, {
                                            children: null
                                          }, React.createElement(Tooltip.default, {
                                                enterDelay: 1000,
                                                children: React.createElement(BtTableCell$BsConsole.Data.make, {
                                                      className: Css.style(/* :: */[
                                                            Css.fontFamily("monospace"),
                                                            /* [] */0
                                                          ]),
                                                      children: I18N$BsConsole.showSkip(errorId + (" (" + (resource.name + ")")))
                                                    }),
                                                title: errorId + (" (" + (resource.name + ")"))
                                              }), React.createElement(BtTableCell$BsConsole.Data.make, {
                                                className: Css.style(/* :: */[
                                                      Css.fontFamily("monospace"),
                                                      /* [] */0
                                                    ])
                                              }), React.createElement(BtTableCell$BsConsole.Data.make, {
                                                children: React.createElement("a", {
                                                      className: Css.style(/* :: */[
                                                            Css.paddingRight(Css.px(3)),
                                                            /* [] */0
                                                          ]),
                                                      download: "",
                                                      href: resource.url,
                                                      target: "_blank"
                                                    }, React.createElement(Button.default, {
                                                          size: "small",
                                                          onClick: (function (param) {
                                                              return MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* Debugger_DownloadAttachmentClick */112]));
                                                            }),
                                                          children: null
                                                        }, I18N$BsConsole.show(undefined, "Download"), React.createElement(GetApp.default, {
                                                              className: Css.style(/* :: */[
                                                                    Css.paddingLeft(Css.px(3)),
                                                                    /* [] */0
                                                                  ])
                                                            })))
                                              }));
                              })))
                  }));
  }
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.width(Css.pct(100)),
                    /* :: */[
                      Css.height(Css.pct(100)),
                      /* :: */[
                        Css.display(/* flex */-1010954439),
                        /* :: */[
                          Css.alignItems(/* center */98248149),
                          /* :: */[
                            Css.justifyContent(/* center */98248149),
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ])
            }, React.createElement(CircularProgress.default, { }));
}

var make = BugAttachments;

exports.IFrame = IFrame;
exports.make = make;
/* Css Not a pure module */
