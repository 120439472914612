// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var $$String = require("bs-platform/lib/js/string.js");
var ReactDom = require("react-dom");
var Validator = require("validator");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Belt_SortArray = require("bs-platform/lib/js/belt_SortArray.js");
var I18N$BsConsole = require("../I18N.js");
var Util$BsConsole = require("../util.js");
var Colors$BsConsole = require("../Colors.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var TextField = require("@material-ui/core/TextField");
var SpecialCharacterAutocomplete$BsConsole = require("../SpecialCharacterAutocomplete.js");

var multilineSpan = Css.style(/* :: */[
      Css.display(/* inlineBlock */-147785676),
      /* :: */[
        Css.whiteSpace(/* preWrap */660870029),
        /* :: */[
          Css.wordWrap(/* breakWord */1059921449),
          /* :: */[
            Css.width(Css.pct(100)),
            /* :: */[
              Css.lineHeight(Css.px(24)),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var editorClassName = Css.style(/* :: */[
      Css.width(Css.pct(100)),
      /* :: */[
        Css.padding(Css.px(0)),
        /* [] */0
      ]
    ]);

var editorClasses = {
  root: Css.style(/* :: */[
        Css.padding(Css.px(0)),
        /* :: */[
          Css.important(Css.fontSize(Css.px(14))),
          /* :: */[
            Css.selector("fieldset", /* :: */[
                  Css.border(Css.px(0), /* none */-922086728, /* transparent */582626130),
                  /* [] */0
                ]),
            /* [] */0
          ]
        ]
      ]),
  input: Css.style(/* :: */[
        Css.padding(Css.px(0)),
        /* :: */[
          Css.background(Css.hex(Colors$BsConsole.red)),
          /* [] */0
        ]
      ])
};

function mentionsView(isMentionedUser) {
  if (isMentionedUser) {
    return Css.style(/* :: */[
                Css.color(Css.hex(Colors$BsConsole.white)),
                /* :: */[
                  Css.backgroundColor(Css.hex(Colors$BsConsole.green)),
                  /* :: */[
                    Css.padding2(Css.px(2), Css.px(4)),
                    /* :: */[
                      Css.borderRadius(Css.px(4)),
                      /* [] */0
                    ]
                  ]
                ]
              ]);
  } else {
    return Css.style(/* :: */[
                Css.backgroundColor(Css.hex(Colors$BsConsole.blueLight)),
                /* :: */[
                  Css.padding2(Css.px(2), Css.px(4)),
                  /* :: */[
                    Css.borderRadius(Css.px(4)),
                    /* [] */0
                  ]
                ]
              ]);
  }
}

var Style = {
  multilineSpan: multilineSpan,
  editorClassName: editorClassName,
  editorClasses: editorClasses,
  mentionsView: mentionsView
};

function CommentContent(Props) {
  var editable = Props.editable;
  var allUsers = Props.allUsers;
  var activeUser = Props.activeUser;
  var resultValue = Props.resultValue;
  var handleCommentChange = Props.handleCommentChange;
  var match = React.useState((function () {
          
        }));
  var setAnchorEl = match[1];
  var anchorEl = match[0];
  var allUsersSorted = Belt_SortArray.stableSortBy(allUsers, (function (u1, u2) {
          return $$String.compare(u1.username.toLowerCase(), u2.username.toLowerCase());
        }));
  var parsingError = function (value) {
    return React.createElement("span", {
                className: Css.style(/* :: */[
                      Css.color(Css.hex(Colors$BsConsole.red)),
                      /* :: */[
                        Css.lineHeight(Css.px(24)),
                        /* [] */0
                      ]
                    ])
              }, I18N$BsConsole.showSkip(value));
  };
  if (!editable) {
    if (resultValue.tag) {
      return parsingError(resultValue[0]);
    } else {
      var value = resultValue[0];
      var wordArray = Belt_Array.keepMap(value.split(SpecialCharacterAutocomplete$BsConsole.wordBreakRegex), (function (c) {
              return c;
            }));
      var formatedSentence = Belt_Array.map(wordArray, (function (word) {
              if (!word.startsWith("@")) {
                if (Validator.isURL(word, {
                        require_protocol: true
                      })) {
                  return React.createElement("a", {
                              href: "#",
                              onClick: (function ($$event) {
                                  $$event.preventDefault();
                                  return Util$BsConsole.openUrl(word);
                                })
                            }, I18N$BsConsole.showSkip(word));
                } else {
                  return I18N$BsConsole.showSkip(word);
                }
              }
              var isMentionedUser = activeUser.username === word.substr(1);
              return React.createElement("bold", {
                          className: mentionsView(isMentionedUser)
                        }, I18N$BsConsole.showSkip(word));
            }));
      return React.createElement("span", {
                  className: multilineSpan
                }, formatedSentence);
    }
  }
  if (resultValue.tag) {
    return parsingError(resultValue[0]);
  }
  var value$1 = resultValue[0];
  return React.createElement(SpecialCharacterAutocomplete$BsConsole.make, {
              toString: (function (user$prime) {
                  return "@" + user$prime.username;
                }),
              considerations: allUsersSorted,
              onChange: (function (newValue) {
                  Curry._1(handleCommentChange, newValue);
                  if (anchorEl !== undefined) {
                    return Caml_option.valFromOption(anchorEl).focus();
                  }
                  
                }),
              defaultValue: value$1,
              inputAriaLabel: I18N$BsConsole.get(undefined, "Comment"),
              renderListOption: (function (user, idx) {
                  return React.createElement(BtTypography$BsConsole.make, {
                              variant: /* Body1 */8,
                              children: I18N$BsConsole.showSkip(user.username),
                              key: user.username + ("__" + String(idx))
                            });
                }),
              renderInput: (function (param) {
                  var onFocus = param.onFocus;
                  var onChange = param.onChange;
                  if (value$1 === "" && value$1 !== param.internalValue) {
                    Curry._1(param.resetInternalValues, undefined);
                  }
                  var onFocus$prime = Curry.__1(onFocus);
                  return React.createElement(TextField.default, {
                              multiline: true,
                              error: false,
                              placeholder: editable ? "Add comment" : "",
                              value: value$1,
                              variant: "outlined",
                              className: editorClassName,
                              inputRef: (function (input) {
                                  if (input == null) {
                                    return ;
                                  }
                                  var domElement = ReactDom.findDOMNode(input);
                                  return Curry._1(setAnchorEl, (function (param) {
                                                return Caml_option.some(domElement);
                                              }));
                                }),
                              classes: editorClasses,
                              onChange: (function ($$event) {
                                  var cursorPosition = $$event.target.selectionStart;
                                  return Curry._2(onChange, $$event, cursorPosition);
                                }),
                              onFocus: onFocus$prime
                            });
                }),
              triggeringCharacter: "@"
            });
}

var component = ReasonReact.statelessComponent("CommentContent");

function make(editable, allUsers, activeUser, resultValue, handleCommentChange, children) {
  return ReasonReactCompat.wrapReactForReasonReact(CommentContent, {
              editable: editable,
              allUsers: allUsers,
              activeUser: activeUser,
              resultValue: resultValue,
              handleCommentChange: handleCommentChange
            }, children);
}

var Jsx2 = {
  component: component,
  make: make
};

var make$1 = CommentContent;

exports.Style = Style;
exports.make = make$1;
exports.Jsx2 = Jsx2;
/* multilineSpan Not a pure module */
