// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Caml_int32 = require("bs-platform/lib/js/caml_int32.js");

function Make(F) {
  var initial_limit = F.limit;
  var initial = {
    offset: 0,
    limit: initial_limit
  };
  var next = function (t$prime) {
    return {
            offset: t$prime.offset + F.limit | 0,
            limit: t$prime.limit
          };
  };
  var prev = function (t$prime) {
    return {
            offset: Math.max(t$prime.offset - F.limit | 0, 0),
            limit: t$prime.limit
          };
  };
  var nth = function (n, t$prime) {
    return {
            offset: Caml_int32.imul(n, F.limit),
            limit: t$prime.limit
          };
  };
  var getOffset = function (t$prime) {
    return t$prime.offset;
  };
  var getLimit = function (t$prime) {
    return t$prime.limit;
  };
  var setPageAndLimit = function (n, l) {
    return {
            offset: Caml_int32.imul(n, l),
            limit: l
          };
  };
  return {
          initial: initial,
          next: next,
          prev: prev,
          nth: nth,
          getOffset: getOffset,
          getLimit: getLimit,
          setPageAndLimit: setPageAndLimit
        };
}

exports.Make = Make;
/* No side effect */
