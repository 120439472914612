// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var Crdb$BsConsole = require("../crdb.js");
var I18N$BsConsole = require("../I18N.js");
var Util$BsConsole = require("../util.js");
var Route$BsConsole = require("../route.js");
var Task2$BsConsole = require("../Task2.js");
var Colors$BsConsole = require("../Colors.js");
var BpgTask$BsConsole = require("../BpgTask.js");
var BtTable$BsConsole = require("../BtTable.js");
var BugStyles$BsConsole = require("./BugStyles.js");
var BtTableRow$BsConsole = require("../BtTableRow.js");
var BtTableBody$BsConsole = require("../BtTableBody.js");
var BtTableCell$BsConsole = require("../BtTableCell.js");
var BtTableHead$BsConsole = require("../BtTableHead.js");
var MetricsEvent$BsConsole = require("../bs-metrics/MetricsEvent.js");
var Tooltip = require("@material-ui/core/Tooltip");
var BtTableContainer$BsConsole = require("../BtTableContainer.js");
var BugReport = require("@material-ui/icons/BugReport");

var cellStyle = Css.style(/* :: */[
      Css.fontSize(Css.px(12)),
      /* :: */[
        Css.fontFamily("Inconsolata"),
        /* :: */[
          Css.overflow(/* hidden */-862584982),
          /* :: */[
            Css.textOverflow(/* ellipsis */166888785),
            /* [] */0
          ]
        ]
      ]
    ]);

var rowStyle = Css.style(/* :: */[
      Css.height(Css.px(28)),
      /* [] */0
    ]);

var titleStyle = Css.style(/* :: */[
      Css.fontFamily("Roboto"),
      /* :: */[
        Css.fontWeight(Css.medium),
        /* :: */[
          Css.textTransform(Css.uppercase),
          /* :: */[
            Css.color(Css.hex(Colors$BsConsole.grey0)),
            /* :: */[
              Css.fontSize(Css.px(12)),
              /* :: */[
                Css.boxSizing(/* borderBox */9307263),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var classifiersContainerStyle = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* [] */0
    ]);

function getProjectNameFromToken(projectToken, config) {
  var tokenRemote = Task2$BsConsole.use(undefined, BpgTask$BsConsole.ApiToken.fetchAll, undefined);
  if (typeof tokenRemote === "number") {
    return ;
  }
  if (tokenRemote.tag) {
    return ;
  }
  var matchedTokenArr = Belt_Array.keep(tokenRemote[0][0], (function (token) {
          return token.id === projectToken;
        }));
  var projectId;
  if (matchedTokenArr.length !== 1) {
    projectId = undefined;
  } else {
    var first = matchedTokenArr[0];
    projectId = first.project;
  }
  var project = projectId !== undefined ? Belt_List.getBy(config.projects, (function (project) {
            return project.pid === projectId;
          })) : undefined;
  if (project !== undefined) {
    return project.name;
  }
  
}

function getProjectNameOfDefect(projectToken, config) {
  var project = Belt_List.getBy(config.projects, (function (project) {
          return project.name === projectToken;
        }));
  if (project !== undefined) {
    return project.name;
  } else {
    return getProjectNameFromToken(projectToken, config);
  }
}

function getDefectDebugRoute(fingerprint, projectName) {
  return Route$BsConsole.pathForRoute(/* ProjectDebugger2 */Block.__(19, [
                projectName,
                {
                  aperture: Curry._2(Crdb$BsConsole.Aperture.setFilters, Curry._2(Crdb$BsConsole.Filters.add, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                                "fingerprint",
                                /* Equal */Block.__(0, [/* `String */[
                                      -976970511,
                                      fingerprint
                                    ]])
                              ]), Crdb$BsConsole.Filters.empty), Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, /* Relative */Block.__(0, [
                              /* Floating */0,
                              /* All */6
                            ]), Crdb$BsConsole.Aperture.$$default)),
                  state: undefined
                }
              ]));
}

function BugDefectView(Props) {
  var config = Props.config;
  var defects = Props.defects;
  Props.height;
  Props.fullHeight;
  var defectColumns = [
    {
      name: "Defect",
      title: "Defect"
    },
    {
      name: "errorMessage",
      title: "Error message"
    },
    {
      name: "classifiers",
      title: "Classifiers"
    },
    {
      name: "functionName",
      title: "Function"
    },
    {
      name: "fileName",
      title: "File"
    },
    {
      name: "lineNumber",
      title: "Line"
    },
    {
      name: "build",
      title: "Build"
    },
    {
      name: "fingerprint",
      title: "Fingerprint"
    }
  ];
  return React.createElement(BtTableContainer$BsConsole.make, {
              children: React.createElement(BtTable$BsConsole.make, {
                    children: null
                  }, React.createElement(BtTableHead$BsConsole.make, {
                        children: React.createElement(BtTableRow$BsConsole.make, {
                              children: Belt_Array.map(defectColumns, (function (col) {
                                      return React.createElement(BtTableCell$BsConsole.Header.make, {
                                                  children: I18N$BsConsole.show(undefined, col.title)
                                                });
                                    }))
                            })
                      }), React.createElement(BtTableBody$BsConsole.make, {
                        children: Belt_Array.map(Belt_List.toArray(defects), (function (row) {
                                var projectName = getProjectNameOfDefect(row.project, config);
                                var message = row.errorMessage;
                                var classifiers = row.classifiers;
                                var tmp;
                                if (classifiers !== undefined) {
                                  var __x = classifiers.split(" ");
                                  var classifiersMinusDefect = Belt_Array.keep(__x, (function (value) {
                                          return value !== "defect";
                                        }));
                                  tmp = React.createElement(Tooltip.default, {
                                        children: React.createElement("div", {
                                              className: classifiersContainerStyle
                                            }, Belt_Array.mapWithIndex(classifiersMinusDefect, (function (_idx, value) {
                                                    return React.createElement("div", {
                                                                className: Css.style(/* :: */[
                                                                      Css.padding2(Css.px(3), Css.px(6)),
                                                                      /* :: */[
                                                                        Css.backgroundColor(Css.hex(Colors$BsConsole.greenExtraLight)),
                                                                        /* :: */[
                                                                          Css.color(Css.hex(Colors$BsConsole.grey2)),
                                                                          /* :: */[
                                                                            BugStyles$BsConsole.monospaceFont,
                                                                            /* :: */[
                                                                              Css.fontSize(Css.px(12)),
                                                                              /* :: */[
                                                                                Css.borderRadius(Css.px(4)),
                                                                                /* :: */[
                                                                                  Css.overflow(Css.hidden),
                                                                                  /* :: */[
                                                                                    Css.textOverflow(Css.ellipsis),
                                                                                    /* :: */[
                                                                                      Css.whiteSpace(Css.nowrap),
                                                                                      /* :: */[
                                                                                        Css.margin(Css.px(3)),
                                                                                        /* [] */0
                                                                                      ]
                                                                                    ]
                                                                                  ]
                                                                                ]
                                                                              ]
                                                                            ]
                                                                          ]
                                                                        ]
                                                                      ]
                                                                    ])
                                                              }, I18N$BsConsole.showSkip(value));
                                                  }))),
                                        title: I18N$BsConsole.get(undefined, classifiersMinusDefect.join(", "))
                                      });
                                } else {
                                  tmp = null;
                                }
                                var v = row.functionName;
                                var v$1 = row.fileName;
                                var v$2 = row.lineNumber;
                                var v$3 = row.build;
                                return React.createElement(BtTableRow$BsConsole.make, {
                                            children: null
                                          }, React.createElement(BtTableCell$BsConsole.make, {
                                                children: projectName !== undefined ? React.createElement(Mui$BsConsole.IconButtonWithTooltipJsx3.make, {
                                                        ariaLabel: "Open defect",
                                                        classes: {
                                                          root: "smaller-icon"
                                                        },
                                                        onClick: (function (param) {
                                                            MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* Debugger_CorrelatedDefectClick */105]));
                                                            return Util$BsConsole.openUrl(getDefectDebugRoute(row.fingerprint, projectName));
                                                          }),
                                                        title_tooltip: I18N$BsConsole.get(undefined, "Open defect"),
                                                        children: React.createElement(BugReport.default, { })
                                                      }) : React.createElement(Mui$BsConsole.IconButtonWithTooltipJsx3.make, {
                                                        ariaLabel: "Unable to find defect",
                                                        disabled: true,
                                                        classes: {
                                                          root: "smaller-icon"
                                                        },
                                                        title_tooltip: I18N$BsConsole.get(undefined, "Unable to find defect"),
                                                        children: React.createElement(BugReport.default, { })
                                                      })
                                              }), React.createElement(BtTableCell$BsConsole.make, {
                                                children: message !== undefined ? React.createElement(Tooltip.default, {
                                                        children: React.createElement("div", {
                                                              className: cellStyle
                                                            }, I18N$BsConsole.showSkip(message)),
                                                        title: I18N$BsConsole.get(undefined, message)
                                                      }) : null
                                              }), React.createElement(BtTableCell$BsConsole.make, {
                                                children: tmp
                                              }), React.createElement(BtTableCell$BsConsole.make, {
                                                children: v !== undefined ? React.createElement(Tooltip.default, {
                                                        children: React.createElement("div", {
                                                              className: cellStyle
                                                            }, I18N$BsConsole.showSkip(v)),
                                                        title: I18N$BsConsole.get(undefined, v)
                                                      }) : null
                                              }), React.createElement(BtTableCell$BsConsole.make, {
                                                children: v$1 !== undefined ? React.createElement(Tooltip.default, {
                                                        children: React.createElement("div", {
                                                              className: cellStyle
                                                            }, I18N$BsConsole.showSkip(v$1)),
                                                        title: I18N$BsConsole.get(undefined, v$1)
                                                      }) : null
                                              }), React.createElement(BtTableCell$BsConsole.make, {
                                                children: v$2 !== undefined ? React.createElement(Tooltip.default, {
                                                        children: React.createElement("div", {
                                                              className: cellStyle
                                                            }, I18N$BsConsole.showSkip(v$2)),
                                                        title: I18N$BsConsole.get(undefined, v$2)
                                                      }) : null
                                              }), React.createElement(BtTableCell$BsConsole.make, {
                                                children: v$3 !== undefined ? React.createElement(Tooltip.default, {
                                                        children: React.createElement("div", {
                                                              className: cellStyle
                                                            }, I18N$BsConsole.showSkip(v$3)),
                                                        title: I18N$BsConsole.get(undefined, v$3)
                                                      }) : null
                                              }), React.createElement(BtTableCell$BsConsole.make, {
                                                children: React.createElement(Tooltip.default, {
                                                      children: React.createElement("div", {
                                                            className: cellStyle
                                                          }, I18N$BsConsole.showSkip(row.fingerprint)),
                                                      title: I18N$BsConsole.get(undefined, row.fingerprint)
                                                    })
                                              }));
                              }))
                      }))
            });
}

var make = BugDefectView;

exports.cellStyle = cellStyle;
exports.rowStyle = rowStyle;
exports.titleStyle = titleStyle;
exports.classifiersContainerStyle = classifiersContainerStyle;
exports.getProjectNameFromToken = getProjectNameFromToken;
exports.getProjectNameOfDefect = getProjectNameOfDefect;
exports.getDefectDebugRoute = getDefectDebugRoute;
exports.make = make;
/* cellStyle Not a pure module */
