// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Route$BsConsole = require("../route.js");
var Backtrace$BsConsole = require("../bindings/Backtrace.js");
var GetNextRoute$BsConsole = require("../GetNextRoute.js");

function currentRouteToType(route, maybeCurrentView) {
  if (typeof route === "number") {
    return /* Other */3;
  }
  switch (route.tag | 0) {
    case /* ProjectCompareReleases */9 :
        return /* Releases */0;
    case /* ProjectTriage */11 :
        if (maybeCurrentView !== undefined && maybeCurrentView) {
          return /* TriageDetails */1;
        } else {
          return /* Other */3;
        }
    case /* ProjectDebugger2 */19 :
        return /* Debugger */2;
    default:
      return /* Other */3;
  }
}

function currentRouteToString(route, maybeCurrentView) {
  if (typeof route !== "number") {
    switch (route.tag | 0) {
      case /* ProjectCompareReleases */9 :
          return "Releases";
      case /* ProjectTriage */11 :
          if (maybeCurrentView !== undefined && maybeCurrentView) {
            return "Error Group Details";
          }
          break;
      case /* ProjectDebugger2 */19 :
          return "Debug";
      default:
        
    }
  }
  var __x = Backtrace$BsConsole.Attributes.addString("src_module", "Level3Breadcrumb/routeToString", Backtrace$BsConsole.Attributes.addString("shouldSendAlert", "true", Backtrace$BsConsole.Attributes.make(undefined)));
  Backtrace$BsConsole.Client.send(__x, /* `string */[
        288368849,
        "Route " + (Route$BsConsole.toString(route) + " was not accounted for.")
      ]);
  return "";
}

function getDefaultParentRoute(route, projectName, aperture) {
  if (typeof route !== "number") {
    switch (route.tag | 0) {
      case /* ProjectCompareReleases */9 :
          return {
                  route: GetNextRoute$BsConsole.overview(projectName, aperture),
                  routeName: /* Overview */3
                };
      case /* ProjectTriage */11 :
      case /* ProjectDebugger2 */19 :
          break;
      default:
        
    }
  }
  return {
          route: GetNextRoute$BsConsole.triage(projectName, route, aperture, true),
          routeName: /* Triage */0
        };
}

exports.currentRouteToType = currentRouteToType;
exports.currentRouteToString = currentRouteToString;
exports.getDefaultParentRoute = getDefaultParentRoute;
/* Route-BsConsole Not a pure module */
