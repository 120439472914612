// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Crdb$BsConsole = require("./crdb.js");
var Route$BsConsole = require("./route.js");
var BtTableSessionData$BsConsole = require("./BtTableSessionData.js");

function clearFilters(filtersToClear, operation, aperture) {
  var filters = Belt_Array.keep(Curry._1(Crdb$BsConsole.Filters.toArray, Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture)), (function (filter) {
          return !Belt_Array.some(filtersToClear, (function (filterToClear) {
                        if (operation !== undefined && Crdb$BsConsole.FilterOperation.toString(Curry._1(Crdb$BsConsole.Filter.getOperation, filter)) !== operation) {
                          return false;
                        } else {
                          return filterToClear === Curry._1(Crdb$BsConsole.Filter.getAttribute, filter);
                        }
                      }));
        }));
  return Curry._2(Crdb$BsConsole.Aperture.setFilters, Curry._1(Crdb$BsConsole.Filters.fromArray, filters), aperture);
}

function getHavingsFromRoute(route) {
  if (typeof route === "number") {
    return Crdb$BsConsole.Havings.empty;
  }
  switch (route.tag | 0) {
    case /* ProjectTriage */11 :
        if (route[2] !== undefined) {
          return Crdb$BsConsole.Havings.empty;
        } else {
          return route[1].havings;
        }
    case /* ProjectQueryBuilder */13 :
        return route[1].havings;
    default:
      return Crdb$BsConsole.Havings.empty;
  }
}

function overview(projectName, aperture) {
  return /* ProjectOverview */Block.__(8, [
            projectName,
            {
              aperture: aperture
            }
          ]);
}

function releases(projectName, aperture) {
  return /* ProjectCompareReleases */Block.__(9, [
            projectName,
            undefined,
            {
              aperture: aperture,
              groups: undefined
            }
          ]);
}

function $$debugger(projectName, aperture) {
  return /* ProjectDebugger2 */Block.__(19, [
            projectName,
            {
              aperture: aperture,
              state: undefined
            }
          ]);
}

function clearFiltersOnExploreNavigation(aperture, projectName) {
  var exploreAggsessionQuery = BtTableSessionData$BsConsole.Query.getFromSession(projectName, /* ExploreAgg */1);
  var defaultDeletes = [
    "_tx",
    "_deleted"
  ];
  var filtersToClear;
  if (exploreAggsessionQuery !== undefined) {
    var factor = Curry._1(Crdb$BsConsole.Query.getFactor, exploreAggsessionQuery);
    filtersToClear = factor !== undefined ? Belt_Array.concat(defaultDeletes, [Curry._1(Crdb$BsConsole.Factor.toCrdbString, factor)]) : defaultDeletes;
  } else {
    filtersToClear = defaultDeletes;
  }
  return clearFilters(filtersToClear, "equal", aperture);
}

function explore(projectName, route, aperture, clearSystemGeneratedFilters) {
  var havings = getHavingsFromRoute(route);
  var init = Route$BsConsole.getQueryBuilderParams(undefined, undefined, projectName, undefined, undefined, undefined, undefined, undefined, undefined);
  return /* ProjectQueryBuilder */Block.__(13, [
            projectName,
            {
              aperture: clearSystemGeneratedFilters ? clearFiltersOnExploreNavigation(aperture, projectName) : aperture,
              factor: init.factor,
              havings: havings,
              fold: init.fold,
              sort: init.sort,
              normBy: init.normBy,
              highlightExporter: init.highlightExporter
            }
          ]);
}

function instances(projectName, aperture, clearTx) {
  return /* ProjectInstances */Block.__(15, [
            projectName,
            Route$BsConsole.getInstancesParams(projectName, Caml_option.some(clearTx ? clearFilters(["_tx"], undefined, aperture) : aperture), undefined)
          ]);
}

function instancesFor(attributeName, attributeValue, projectName, aperture, clearTx) {
  var addFilter = function (aperture, filters) {
    var filterMap = Belt_List.toArray(filters).reduce((function (filterMap, param) {
            return Curry._2(Crdb$BsConsole.Filters.add, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                            param[0],
                            param[1]
                          ]), filterMap);
          }), Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture));
    return Curry._2(Crdb$BsConsole.Aperture.setFilters, filterMap, aperture);
  };
  var init = Route$BsConsole.getInstancesParams(projectName, undefined, undefined);
  return /* ProjectInstances */Block.__(15, [
            projectName,
            {
              aperture: clearTx ? clearFilters(["_tx"], undefined, aperture) : addFilter(aperture, /* :: */[
                      /* tuple */[
                        attributeName,
                        /* Equal */Block.__(0, [/* `String */[
                              -976970511,
                              attributeValue
                            ]])
                      ],
                      /* [] */0
                    ]),
              select: init.select,
              sort: init.sort
            }
          ]);
}

function clearFiltersOnTriageNavigation(aperture) {
  return clearFilters([
              "fingerprint",
              "_deleted"
            ], undefined, aperture);
}

function triage(projectName, route, aperture, clearSystemGeneratedFilters) {
  var havings = getHavingsFromRoute(route);
  var init = Route$BsConsole.getInboxParams(projectName, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
  return /* ProjectTriage */Block.__(11, [
            projectName,
            {
              aperture: clearSystemGeneratedFilters ? clearFiltersOnTriageNavigation(aperture) : aperture,
              sort: init.sort,
              stats: init.stats,
              havings: havings,
              fold: init.fold,
              normBy: init.normBy,
              similarity: init.similarity
            },
            undefined
          ]);
}

function triageDetails(projectName, fingerprint, aperture) {
  return /* ProjectTriage */Block.__(11, [
            projectName,
            Route$BsConsole.getInboxParams(projectName, Caml_option.some(Curry._2(Crdb$BsConsole.Aperture.setFilters, Curry._2(Crdb$BsConsole.Filters.add, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                                  "fingerprint",
                                  /* Equal */Block.__(0, [/* `String */[
                                        -976970511,
                                        fingerprint
                                      ]])
                                ]), Crdb$BsConsole.Filters.empty), Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, Curry._1(Crdb$BsConsole.Aperture.getTimeAperture, aperture), Crdb$BsConsole.Aperture.$$default))), undefined, undefined, undefined, undefined, undefined, undefined, undefined),
            undefined
          ]);
}

exports.clearFilters = clearFilters;
exports.getHavingsFromRoute = getHavingsFromRoute;
exports.overview = overview;
exports.releases = releases;
exports.$$debugger = $$debugger;
exports.clearFiltersOnExploreNavigation = clearFiltersOnExploreNavigation;
exports.explore = explore;
exports.instances = instances;
exports.instancesFor = instancesFor;
exports.clearFiltersOnTriageNavigation = clearFiltersOnTriageNavigation;
exports.triage = triage;
exports.triageDetails = triageDetails;
/* Crdb-BsConsole Not a pure module */
