// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Crdb$BsConsole = require("../crdb.js");
var I18N$BsConsole = require("../I18N.js");
var Util$BsConsole = require("../util.js");
var Group$BsConsole = require("../bs-crdb-response/src/Group.js");
var Task2$BsConsole = require("../Task2.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var CoronerdGate$BsConsole = require("../CoronerdGate.js");
var MetricsEvent$BsConsole = require("../bs-metrics/MetricsEvent.js");
var Radio = require("@material-ui/core/Radio");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");
var Button = require("@material-ui/core/Button");
var Dialog = require("@material-ui/core/Dialog");
var BtThemeContext$BsConsole = require("../BtThemeContext.js");
var Deleting_Tasks$BsConsole = require("./Deleting_Tasks.js");
var TextField = require("@material-ui/core/TextField");
var Typography = require("@material-ui/core/Typography");
var QueryPaginationTask$BsConsole = require("../QueryPaginationTask.js");
var DialogActions = require("@material-ui/core/DialogActions");
var DialogContent = require("@material-ui/core/DialogContent");
var SymbolServers_StatsUsage$BsConsole = require("../project-settings/symbols/SymbolServers/SymbolServers_StatsUsage.js");
var DialogContentText = require("@material-ui/core/DialogContentText");

var minCoronerdVersion = "1.60.66";

function useFormattedObjectSize(token, query, projectName) {
  var match = React.useState((function () {
          
        }));
  var setResultSize = match[1];
  var match$1 = QueryPaginationTask$BsConsole.CrdbAggregate.use(token, projectName, query, "delete_objects_size", undefined, undefined);
  var paginationRemote = match$1[0];
  React.useEffect((function () {
          if (typeof paginationRemote !== "number" && !paginationRemote.tag) {
            var match = paginationRemote[0][0];
            var columns = match[0];
            var group = Belt_List.get(match[1], 0);
            if (group !== undefined) {
              var getter = function (param, param$1) {
                return Group$BsConsole.getAggregation(columns, group, param, param$1);
              };
              var match$1 = Group$BsConsole.unwrapSum(getter("object.size", /* Sum */7));
              if (match$1 !== undefined && match$1.tag === /* Bytes */5) {
                var bytes = match$1[0];
                Curry._1(setResultSize, (function (param) {
                        return SymbolServers_StatsUsage$BsConsole.bytesFormatted(Caml_format.caml_float_of_string(bytes));
                      }));
              }
              
            }
            
          }
          
        }), [paginationRemote]);
  return match[0];
}

function DeleteByQuery(Props) {
  var onCloseCb = Props.onCloseCb;
  var errorCount = Props.errorCount;
  var token = Props.token;
  var project = Props.project;
  var universe = Props.universe;
  var query = Props.query;
  var children = Props.children;
  var onCloseCb$1 = function (param) {
    if (onCloseCb !== undefined) {
      return Curry._1(onCloseCb, undefined);
    }
    
  };
  var match = React.useState((function () {
          return false;
        }));
  var setDialogOpen = match[1];
  var match$1 = React.useState((function () {
          return /* Physical */0;
        }));
  var setSelectedDeleteOption = match$1[1];
  var selectedDeleteOption = match$1[0];
  var match$2 = React.useState((function () {
          return "";
        }));
  var setConfirmInputValue = match$2[1];
  var confirmInputValue = match$2[0];
  var palette = BtThemeContext$BsConsole.useThemePalette(undefined);
  var confirmationValue = I18N$BsConsole.get(undefined, "DELETE");
  var getRadioButtons = function (param) {
    return React.createElement("div", {
                className: Css.style(/* :: */[
                      Css.marginBottom(Css.px(35)),
                      /* [] */0
                    ])
              }, React.createElement("div", {
                    className: Css.style(/* :: */[
                          Css.display(/* flex */-1010954439),
                          /* :: */[
                            Css.flexDirection(/* row */5693978),
                            /* :: */[
                              Css.alignItems(/* center */98248149),
                              /* :: */[
                                Css.cursor(/* pointer */-786317123),
                                /* :: */[
                                  Css.marginBottom(Css.px(20)),
                                  /* [] */0
                                ]
                              ]
                            ]
                          ]
                        ]),
                    onClick: (function (param) {
                        return Curry._1(setSelectedDeleteOption, (function (param) {
                                      return /* Physical */0;
                                    }));
                      })
                  }, React.createElement(Radio.default, {
                        checked: selectedDeleteOption === /* Physical */0,
                        color: "primary",
                        classes: {
                          root: Css.style(/* :: */[
                                Css.paddingLeft(Css.px(0)),
                                /* [] */0
                              ])
                        }
                      }), React.createElement("div", undefined, React.createElement(Typography.default, {
                            variant: "body1",
                            classes: {
                              root: Css.style(/* :: */[
                                    Css.fontSize(Css.rem(1)),
                                    /* :: */[
                                      Css.fontWeight(/* `num */[
                                            5496390,
                                            500
                                          ]),
                                      /* [] */0
                                    ]
                                  ])
                            },
                            children: I18N$BsConsole.show(undefined, "Delete all physical objects")
                          }), React.createElement(Typography.default, {
                            variant: "caption",
                            classes: {
                              root: Css.style(/* :: */[
                                    Css.fontSize(Css.rem(1)),
                                    /* [] */0
                                  ])
                            },
                            children: I18N$BsConsole.show(undefined, "Error attachments and dump objects will be removed, and the error will not be viewable in Debug. Metadata is retained and available via Triage and Explore.")
                          }))), React.createElement("div", undefined, React.createElement("div", {
                        className: Css.style(/* :: */[
                              Css.display(/* flex */-1010954439),
                              /* :: */[
                                Css.flexDirection(/* row */5693978),
                                /* :: */[
                                  Css.alignItems(/* center */98248149),
                                  /* :: */[
                                    Css.cursor(/* pointer */-786317123),
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ]),
                        onClick: (function (param) {
                            return Curry._1(setSelectedDeleteOption, (function (param) {
                                          return /* PhysicalAndMetadata */1;
                                        }));
                          })
                      }, React.createElement(Radio.default, {
                            checked: selectedDeleteOption === /* PhysicalAndMetadata */1,
                            color: "primary",
                            classes: {
                              root: Css.style(/* :: */[
                                    Css.paddingLeft(Css.px(0)),
                                    /* [] */0
                                  ])
                            }
                          }), React.createElement("div", undefined, React.createElement(Typography.default, {
                                variant: "body1",
                                classes: {
                                  root: Css.style(/* :: */[
                                        Css.fontSize(Css.rem(1)),
                                        /* :: */[
                                          Css.fontWeight(/* `num */[
                                                5496390,
                                                500
                                              ]),
                                          /* [] */0
                                        ]
                                      ])
                                },
                                children: I18N$BsConsole.show(undefined, "Delete all physical objects and metadata")
                              }), React.createElement(Typography.default, {
                                variant: "caption",
                                classes: {
                                  root: Css.style(/* :: */[
                                        Css.fontSize(Css.rem(1)),
                                        /* [] */0
                                      ])
                                },
                                children: I18N$BsConsole.show(undefined, "In addition to removing physical objects, all error data will be removed and no longer available in Triage, Explore, or Debug.")
                              })))));
  };
  var confirmAndDelete = function (param) {
    return CoronerdGate$BsConsole.snack(minCoronerdVersion, undefined, (function (param) {
                  return Curry._1(setDialogOpen, (function (param) {
                                return true;
                              }));
                }), undefined);
  };
  var formattedSize = useFormattedObjectSize(token, /* Aggregate */Block.__(0, [
          Curry._1(Crdb$BsConsole.Query.getAperture, query),
          /* Custom */["*"],
          Curry._2(Crdb$BsConsole.Fold.add, Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                    "object.size",
                    /* Sum */3
                  ]), Crdb$BsConsole.Fold.empty),
          undefined,
          undefined,
          undefined
        ]), project);
  var getFormattedAperture = function (param) {
    var match = Curry._1(Crdb$BsConsole.TimeAperture.toRange, Curry._1(Crdb$BsConsole.Aperture.getTimeAperture, Curry._1(Crdb$BsConsole.Query.getAperture, query)));
    return Util$BsConsole.formatDateRange(undefined, undefined, match[0], match[1], undefined);
  };
  var dialogContent = function (param) {
    return React.createElement(React.Fragment, undefined, React.createElement(Typography.default, {
                    variant: "title",
                    children: I18N$BsConsole.show(undefined, "Delete selected group")
                  }), React.createElement("div", undefined, React.createElement(Typography.default, {
                        variant: "body1",
                        classes: {
                          root: Css.style(/* :: */[
                                Css.marginBottom(Css.px(33)),
                                /* :: */[
                                  Css.marginTop(Css.px(16)),
                                  /* :: */[
                                    Css.fontSize(Css.rem(1)),
                                    /* [] */0
                                  ]
                                ]
                              ])
                        },
                        children: null
                      }, Curry._2(I18N$BsConsole.showf(undefined, /* Format */[
                                /* String_literal */Block.__(11, [
                                    "This action will delete ",
                                    /* String */Block.__(2, [
                                        /* No_padding */0,
                                        /* String_literal */Block.__(11, [
                                            " objects that were received between ",
                                            /* String */Block.__(2, [
                                                /* No_padding */0,
                                                /* Char_literal */Block.__(12, [
                                                    /* "." */46,
                                                    /* End_of_format */0
                                                  ])
                                              ])
                                          ])
                                      ])
                                  ]),
                                "This action will delete %s objects that were received between %s."
                              ]), String(errorCount), getFormattedAperture(undefined)), formattedSize !== undefined ? Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                  /* String_literal */Block.__(11, [
                                      "This will reclaim up to ",
                                      /* String */Block.__(2, [
                                          /* No_padding */0,
                                          /* String_literal */Block.__(11, [
                                              " of space.",
                                              /* End_of_format */0
                                            ])
                                        ])
                                    ]),
                                  "This will reclaim up to %s of space."
                                ]), formattedSize) : null)), getRadioButtons(undefined), React.createElement("div", undefined, React.createElement(Typography.default, {
                        variant: "body1",
                        classes: {
                          root: Css.style(/* :: */[
                                Css.fontSize(Css.rem(1)),
                                /* :: */[
                                  Css.fontWeight(/* `num */[
                                        5496390,
                                        500
                                      ]),
                                  /* :: */[
                                    Css.marginBottom(Css.px(5)),
                                    /* [] */0
                                  ]
                                ]
                              ])
                        },
                        children: null
                      }, I18N$BsConsole.show(undefined, "This action is not recoverable. Confirm by typing "), React.createElement("span", {
                            className: Css.style(/* :: */[
                                  Css.color(BtThemeContext$BsConsole.getHex(palette.error.main)),
                                  /* [] */0
                                ])
                          }, I18N$BsConsole.showSkip(confirmationValue)), I18N$BsConsole.show(undefined, " below")), React.createElement(TextField.default, {
                        placeholder: confirmationValue,
                        value: confirmInputValue,
                        variant: "outlined",
                        classes: {
                          root: Css.style(/* :: */[
                                Css.selector("input", /* :: */[
                                      Css.important(Css.padding(Css.px(10))),
                                      /* [] */0
                                    ]),
                                /* :: */[
                                  Css.width(Css.pct(100)),
                                  /* [] */0
                                ]
                              ])
                        },
                        onChange: (function ($$event) {
                            var value = $$event.target.value;
                            return Curry._1(setConfirmInputValue, (function (param) {
                                          return value;
                                        }));
                          })
                      })));
  };
  var displayError = function (err) {
    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.showSkip(I18N$BsConsole.get(undefined, "Delete request failed: ") + err));
  };
  return React.createElement(React.Fragment, undefined, Curry._1(children, confirmAndDelete), React.createElement(Dialog.default, {
                  open: match[0],
                  classes: {
                    paper: Css.style(/* :: */[
                          Css.padding(Css.px(24)),
                          /* [] */0
                        ])
                  },
                  onClose: (function (_e) {
                      
                    }),
                  children: null
                }, React.createElement(DialogContent.default, {
                      classes: {
                        root: Css.style(/* :: */[
                              Css.important(Css.padding(Css.px(0))),
                              /* [] */0
                            ])
                      },
                      children: React.createElement(DialogContentText.default, {
                            children: dialogContent(undefined)
                          })
                    }), React.createElement(DialogActions.default, {
                      className: Css.style(/* :: */[
                            Css.marginRight(Css.px(0)),
                            /* :: */[
                              Css.marginTop(Css.px(45)),
                              /* [] */0
                            ]
                          ]),
                      children: null
                    }, React.createElement(Button.default, {
                          color: "primary",
                          onClick: (function (_event) {
                              Curry._1(setDialogOpen, (function (param) {
                                      return false;
                                    }));
                              return onCloseCb$1(undefined);
                            }),
                          children: I18N$BsConsole.show(undefined, "Cancel")
                        }), React.createElement(Button.default, {
                          variant: "raised",
                          color: "primary",
                          className: Css.style(/* :: */[
                                Css.marginRight(Css.px(0)),
                                /* :: */[
                                  Css.fontSize(Css.rem(1)),
                                  /* :: */[
                                    Css.backgroundColor(BtThemeContext$BsConsole.getHex(palette.error.main)),
                                    /* :: */[
                                      Css.hover(/* :: */[
                                            Css.backgroundColor(BtThemeContext$BsConsole.getHex(palette.error.dark)),
                                            /* [] */0
                                          ]),
                                      /* [] */0
                                    ]
                                  ]
                                ]
                              ]),
                          disabled: confirmInputValue !== confirmationValue,
                          onClick: (function (_event) {
                              Curry._1(setDialogOpen, (function (param) {
                                      return false;
                                    }));
                              var arg = Deleting_Tasks$BsConsole.$$delete(token, project, universe, selectedDeleteOption, query, undefined);
                              Curry._2((function (param) {
                                        return (function (param$1, param$2) {
                                            return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                          });
                                      })(token), undefined, (function (resp) {
                                      if (resp.tag) {
                                        return displayError(Task2$BsConsole.renderError(undefined, resp[0]));
                                      }
                                      if (resp[0].response !== "ok") {
                                        return displayError(I18N$BsConsole.get(undefined, "Please try again later."));
                                      }
                                      var metricSource = selectedDeleteOption ? /* DeleteByQuery_PhysicalAndMetadata */7 : /* DeleteByQuery_Physical */6;
                                      MetricsEvent$BsConsole.send(/* Action */Block.__(0, [metricSource]));
                                      return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Delete object request confirmed. You have successfully submitted a delete object request. This may take time to process."));
                                    }));
                              return onCloseCb$1(undefined);
                            }),
                          children: I18N$BsConsole.show(undefined, "Delete forever")
                        }))));
}

function make(onCloseCb, token, errorCount, project, universe, query, children) {
  var tmp = {
    errorCount: errorCount,
    token: token,
    project: project,
    universe: universe,
    query: query,
    children: children
  };
  if (onCloseCb !== undefined) {
    tmp.onCloseCb = Caml_option.valFromOption(onCloseCb);
  }
  return ReasonReactCompat.wrapReactForReasonReact(DeleteByQuery, tmp, children);
}

var Re = {
  make: make
};

var make$1 = DeleteByQuery;

exports.minCoronerdVersion = minCoronerdVersion;
exports.useFormattedObjectSize = useFormattedObjectSize;
exports.make = make$1;
exports.Re = Re;
/* Css Not a pure module */
