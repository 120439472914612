// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Belt_MapString = require("bs-platform/lib/js/belt_MapString.js");
var Crdb$BsConsole = require("./crdb.js");
var ApiWf$BsConsole = require("./workflow/workflows-api/ApiWf.js");
var Group$BsConsole = require("./bs-crdb-response/src/Group.js");
var Parse$BsConsole = require("./bs-crdb-response/src/Parse.js");
var Task2$BsConsole = require("./Task2.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var Uncaught$BsConsole = require("./Uncaught.js");
var Backtrace$BsConsole = require("./bindings/Backtrace.js");
var QueryPaginationTask$BsConsole = require("./QueryPaginationTask.js");

function makeQuery(aperture, sort, havings, frozenTx, page, limit, param) {
  var addFoldForSort = function (currentFold) {
    var sortFold;
    if (sort !== undefined) {
      switch (sort.tag | 0) {
        case /* Head */2 :
            sortFold = Caml_option.some(Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                      sort[0],
                      /* Head */1
                    ]));
            break;
        case /* Tail */3 :
            sortFold = Caml_option.some(Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                      sort[0],
                      /* Tail */2
                    ]));
            break;
        case /* Range */4 :
            sortFold = Caml_option.some(Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                      sort[0],
                      /* Range */8
                    ]));
            break;
        case /* Unique */6 :
            sortFold = Caml_option.some(Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                      sort[0],
                      /* Unique */4
                    ]));
            break;
        case /* Min */7 :
            sortFold = Caml_option.some(Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                      sort[0],
                      /* Min */5
                    ]));
            break;
        case /* Max */8 :
            sortFold = Caml_option.some(Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                      sort[0],
                      /* Max */6
                    ]));
            break;
        case /* Mean */9 :
            sortFold = Caml_option.some(Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                      sort[0],
                      /* Mean */7
                    ]));
            break;
        case /* Sum */10 :
            sortFold = Caml_option.some(Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                      sort[0],
                      /* Sum */3
                    ]));
            break;
        default:
          sortFold = undefined;
      }
    } else {
      sortFold = undefined;
    }
    if (sortFold !== undefined) {
      return Curry._2(Crdb$BsConsole.Fold.add, Caml_option.valFromOption(sortFold), currentFold);
    } else {
      return currentFold;
    }
  };
  var addFoldForHavings = function (currentFold) {
    var hs = Belt_Option.map(havings, (function (havings) {
            return Curry._1(Crdb$BsConsole.Havings.toArray, havings);
          }));
    if (hs !== undefined && hs.length !== 0) {
      return Belt_Array.reduce(Belt_Option.getWithDefault(hs, []), currentFold, (function (acc, h) {
                    var match = Curry._1(Crdb$BsConsole.Having.getAttribute, h);
                    var match$1 = Curry._1(Crdb$BsConsole.Having.getPipe, h);
                    if (match === undefined) {
                      return acc;
                    }
                    switch (match$1) {
                      case "head" :
                          return Curry._2(Crdb$BsConsole.Fold.add, Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                                          match,
                                          /* Head */1
                                        ]), acc);
                      case "max" :
                          return Curry._2(Crdb$BsConsole.Fold.add, Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                                          match,
                                          /* Max */6
                                        ]), acc);
                      case "mean" :
                          return Curry._2(Crdb$BsConsole.Fold.add, Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                                          match,
                                          /* Mean */7
                                        ]), acc);
                      case "min" :
                          return Curry._2(Crdb$BsConsole.Fold.add, Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                                          match,
                                          /* Min */5
                                        ]), acc);
                      case "sum" :
                          return Curry._2(Crdb$BsConsole.Fold.add, Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                                          match,
                                          /* Sum */3
                                        ]), acc);
                      case "tail" :
                          return Curry._2(Crdb$BsConsole.Fold.add, Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                                          match,
                                          /* Tail */2
                                        ]), acc);
                      case "unique" :
                          return Curry._2(Crdb$BsConsole.Fold.add, Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                                          match,
                                          /* Unique */4
                                        ]), acc);
                      default:
                        return acc;
                    }
                  }));
    } else {
      return currentFold;
    }
  };
  var filters = Curry._2(Crdb$BsConsole.Filters.add, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
            "_tx",
            /* AtMost */Block.__(9, [/* `ObjectID */[
                  -589436806,
                  frozenTx
                ]])
          ]), Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture));
  var frozenAperture = Curry._2(Crdb$BsConsole.Aperture.setFilters, filters, aperture);
  var __x = Crdb$BsConsole.Fold.empty;
  var __x$1 = Curry._2(Crdb$BsConsole.Fold.add, Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
            "error.message",
            /* Distribution */Block.__(1, [10])
          ]), __x);
  var fold = addFoldForHavings(addFoldForSort(Curry._2(Crdb$BsConsole.Fold.add, Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                    "callstack",
                    /* Head */1
                  ]), __x$1)));
  var pagination = limit !== undefined ? Caml_option.some(Curry._2(Crdb$BsConsole.Paginate.setPageAndLimit, page, limit)) : Caml_option.some(Curry._2(Crdb$BsConsole.Paginate.nth, page, Crdb$BsConsole.Paginate.initial));
  return /* Aggregate */Block.__(0, [
            frozenAperture,
            /* Identity */0,
            fold,
            sort,
            pagination,
            havings
          ]);
}

function queryDescription(token, frozenTx, page, projectName, aperture, sort, havings, cbSuccess, cbFailure, limit, param) {
  var query = makeQuery(aperture, sort, havings, frozenTx, page, limit, undefined);
  var queryJson = Curry._1(Crdb$BsConsole.Query.toCrdb_, query);
  var onSuccess = Curry.__1(cbSuccess);
  var onFailure = Curry.__1(cbFailure);
  var decodeResp = function (query, response) {
    try {
      var factor = Belt_Option.getWithDefault(Curry._1(Crdb$BsConsole.Query.getFactor, query), /* Custom */["*"]);
      var columns = Parse$BsConsole.parseColumns(response);
      var func = QueryPaginationTask$BsConsole.AggregateUtils.getRows;
      var func$1 = Curry._1(func, factor);
      var rows = Curry._2(func$1, columns, response);
      var parseColumns = function (columns, group) {
        var getter = function (param, param$1) {
          return Group$BsConsole.getAggregation(columns, group, param, param$1);
        };
        var errorMessage = Group$BsConsole.unwrapDistribution(getter("error.message", /* Distribution */1));
        var callstack = Group$BsConsole.unwrapCallstack(getter("callstack", /* Head */3));
        return /* tuple */[
                errorMessage,
                callstack
              ];
      };
      var foundErrorMessages = {
        contents: false
      };
      var descriptionGroups = Belt_List.toArray(Belt_List.map(rows, (function (group) {
                  var match = parseColumns(columns, group);
                  var maybeCallstack = match[1];
                  var maybeErrorMessages = match[0];
                  var fingerprint = group.factor.value;
                  var decodeCallstack = function (json) {
                    return {
                            frame: Json_decode.field("frame", (function (param) {
                                    return Json_decode.array(Json_decode.string, param);
                                  }), json)
                          };
                  };
                  var maybeLocation;
                  if (maybeCallstack !== undefined) {
                    try {
                      var $$location = decodeCallstack(JSON.parse(maybeCallstack[1])).frame;
                      var match$1 = Belt_Array.get($$location, 0);
                      var match$2 = Belt_Array.get($$location, 1);
                      maybeLocation = match$1 !== undefined && match$2 !== undefined && match$1.includes("anonymous") ? match$1 + (" within " + match$2) : match$1;
                    }
                    catch (_err){
                      maybeLocation = undefined;
                    }
                  } else {
                    maybeLocation = undefined;
                  }
                  var maybeErrorMessage;
                  if (maybeErrorMessages !== undefined) {
                    var errorMessages = Belt_List.get(maybeErrorMessages.vals, 0);
                    if (errorMessages !== undefined) {
                      var format = errorMessages[0];
                      if (format !== undefined && !format.tag) {
                        var match$3 = format[0];
                        if (typeof match$3 === "number" || match$3[0] !== -976970511) {
                          maybeErrorMessage = undefined;
                        } else {
                          var errorMessage = match$3[1];
                          if (errorMessage !== "*") {
                            if (!foundErrorMessages.contents) {
                              foundErrorMessages.contents = true;
                            }
                            maybeErrorMessage = errorMessage;
                          } else {
                            maybeErrorMessage = undefined;
                          }
                        }
                      } else {
                        maybeErrorMessage = undefined;
                      }
                    } else {
                      maybeErrorMessage = undefined;
                    }
                  } else {
                    maybeErrorMessage = undefined;
                  }
                  var description = maybeErrorMessage !== undefined ? (
                      maybeLocation !== undefined ? ({
                            message: maybeErrorMessage,
                            location: "in " + maybeLocation
                          }) : ({
                            message: maybeErrorMessage,
                            location: undefined
                          })
                    ) : (
                      maybeLocation !== undefined ? ({
                            message: undefined,
                            location: "in " + maybeLocation
                          }) : ({
                            message: undefined,
                            location: undefined
                          })
                    );
                  return /* tuple */[
                          fingerprint,
                          description
                        ];
                })));
      var descriptionMap = Belt_MapString.fromArray(descriptionGroups);
      if (foundErrorMessages.contents) {
        return /* Ok */Block.__(0, [descriptionMap]);
      } else {
        return /* Error */Block.__(1, [[
                    Task2$BsConsole.ErrorMsg,
                    "No error messages found"
                  ]]);
      }
    }
    catch (raw_err){
      var err = Caml_js_exceptions.internalToOCamlException(raw_err);
      var error = Uncaught$BsConsole.castToJsExn(err);
      var __x = Backtrace$BsConsole.Attributes.addString("src_module", "FingerprintDescription/queryDescription/decodeResp", Backtrace$BsConsole.Attributes.make(undefined));
      Backtrace$BsConsole.Client.send(__x, /* `exn */[
            5049499,
            error
          ]);
      return /* Error */Block.__(1, [[
                  ApiWf$BsConsole.ParseError,
                  error
                ]]);
    }
  };
  var task = Task2$BsConsole.make(/* Api */Block.__(0, ["/query?project=" + projectName]), undefined, /* Post */Block.__(0, [queryJson]), undefined, (function (param) {
          var json = param.json;
          try {
            if (param.status >= 400) {
              var response = ApiWf$BsConsole.$$Error.fromJson(json);
              var __x = Backtrace$BsConsole.Attributes.addString("src_module", "FingerprintDescription/queryDescription/task", Backtrace$BsConsole.Attributes.make(undefined));
              Backtrace$BsConsole.Client.send(__x, /* `string */[
                    288368849,
                    response.error
                  ]);
              return /* Error */Block.__(1, [[
                          ApiWf$BsConsole.ServiceError,
                          response.error
                        ]]);
            }
            var response$1 = decodeResp(query, json);
            if (response$1.tag) {
              return /* Error */Block.__(1, [response$1[0]]);
            } else {
              return /* Ok */Block.__(0, [response$1[0]]);
            }
          }
          catch (raw_err){
            var err = Caml_js_exceptions.internalToOCamlException(raw_err);
            var error = Uncaught$BsConsole.castToJsExn(err);
            var __x$1 = Backtrace$BsConsole.Attributes.addString("src_module", "FingerprintDescription/queryDescription/task", Backtrace$BsConsole.Attributes.make(undefined));
            Backtrace$BsConsole.Client.send(__x$1, /* `exn */[
                  5049499,
                  error
                ]);
            return /* Error */Block.__(1, [[
                        ApiWf$BsConsole.ParseError,
                        error
                      ]]);
          }
        }), undefined, undefined);
  return Task2$BsConsole.handle(token, task, undefined, (function (param) {
                return ApiWf$BsConsole.responseCb(onSuccess, onFailure, param);
              }));
}

function reorderDescriptionColumn(order) {
  var orderList = Belt_List.filter(Belt_List.fromArray(order), (function (column) {
          return column !== "__description";
        }));
  if (!orderList) {
    return order;
  }
  var match = orderList[1];
  if (match) {
    return Belt_List.toArray(/* :: */[
                orderList[0],
                /* :: */[
                  match[0],
                  /* :: */[
                    "__description",
                    match[1]
                  ]
                ]
              ]);
  } else {
    return order;
  }
}

exports.makeQuery = makeQuery;
exports.queryDescription = queryDescription;
exports.reorderDescriptionColumn = reorderDescriptionColumn;
/* Crdb-BsConsole Not a pure module */
