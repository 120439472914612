// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var CommentsUtil$BsConsole = require("./CommentsUtil.js");
var MetricsEvent$BsConsole = require("../bs-metrics/MetricsEvent.js");

var metricsNamespace = "triage/activity_feed/";

function toEvent(action) {
  if (typeof action === "number") {
    if (action !== 0) {
      return /* GenericEvent */Block.__(2, [{
                  name: "triage/activity_feed/comment_deleted",
                  payload: undefined
                }]);
    } else {
      return /* GenericEvent */Block.__(2, [{
                  name: "triage/activity_feed/comment_edited",
                  payload: undefined
                }]);
    }
  } else {
    return /* GenericEvent */Block.__(2, [{
                name: "triage/activity_feed/comment_created",
                payload: Caml_option.some(Json_encode.object_(/* :: */[
                          /* tuple */[
                            "mentionsCount",
                            Curry._1(CommentsUtil$BsConsole.Comments.Mentions.getMentionsCount, action[0])
                          ],
                          /* [] */0
                        ]))
              }]);
  }
}

function send(t) {
  return MetricsEvent$BsConsole.send(toEvent(t));
}

exports.metricsNamespace = metricsNamespace;
exports.toEvent = toEvent;
exports.send = send;
/* CommentsUtil-BsConsole Not a pure module */
