// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Colors$BsConsole = require("../Colors.js");

function backdrop(isFilterOpen) {
  if (isFilterOpen) {
    return Css.style(/* :: */[
                Css.backgroundColor(Css.hex(Colors$BsConsole.grey2)),
                /* :: */[
                  Css.display(/* block */888960333),
                  /* :: */[
                    Css.position(/* fixed */10615156),
                    /* :: */[
                      Css.top(/* zero */-789508312),
                      /* :: */[
                        Css.left(/* zero */-789508312),
                        /* :: */[
                          Css.height(Css.vh(100)),
                          /* :: */[
                            Css.width(Css.vw(100)),
                            /* :: */[
                              Css.zIndex(9),
                              /* :: */[
                                Css.opacity(0.5),
                                /* [] */0
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]);
  } else {
    return Css.style(/* :: */[
                Css.display(/* none */-922086728),
                /* [] */0
              ]);
  }
}

function FilterBackdrop(Props) {
  var isFilterOpen = Props.isFilterOpen;
  var closeFilter = Props.closeFilter;
  return React.createElement("div", {
              className: backdrop(isFilterOpen),
              onClick: (function (param) {
                  return Curry._1(closeFilter, undefined);
                })
            });
}

var make = FilterBackdrop;

exports.backdrop = backdrop;
exports.make = make;
/* Css Not a pure module */
