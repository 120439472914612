// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var I18N$BsConsole = require("../I18N.js");
var Task2$BsConsole = require("../Task2.js");
var Colors$BsConsole = require("../Colors.js");
var BpgTask$BsConsole = require("../BpgTask.js");
var MetricsEvent$BsConsole = require("../bs-metrics/MetricsEvent.js");
var Configuration$BsConsole = require("../configuration.js");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");

function handleResults(results, actionOpt, refetch) {
  var action = actionOpt !== undefined ? actionOpt : "complete task";
  var message;
  if (!results.tag) {
    return Curry._1(refetch, undefined);
  }
  var _err = results[0];
  if (_err[0] === Task2$BsConsole.CoronerError) {
    message = _err[1].message;
  } else {
    if (_err[0] !== BpgTask$BsConsole.BpgError) {
      return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                          /* String_literal */Block.__(11, [
                              "Failed to ",
                              /* String */Block.__(2, [
                                  /* No_padding */0,
                                  /* String_literal */Block.__(11, [
                                      ": Unknown error has occured",
                                      /* End_of_format */0
                                    ])
                                ])
                            ]),
                          "Failed to %s: Unknown error has occured"
                        ]), action));
    }
    message = _err[2];
  }
  return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, Curry._2(I18N$BsConsole.showf(undefined, /* Format */[
                      /* String_literal */Block.__(11, [
                          "Failed to ",
                          /* String */Block.__(2, [
                              /* No_padding */0,
                              /* String_literal */Block.__(11, [
                                  ": ",
                                  /* String */Block.__(2, [
                                      /* No_padding */0,
                                      /* End_of_format */0
                                    ])
                                ])
                            ])
                        ]),
                      "Failed to %s: %s"
                    ]), action, message));
}

function handleRefetchableResults(results, actionOpt, cb) {
  var action = actionOpt !== undefined ? actionOpt : "complete task";
  if (!results.tag) {
    return Curry._1(cb, undefined);
  }
  var _err = results[0];
  if (_err[0] === Task2$BsConsole.CoronerError) {
    SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, Curry._2(I18N$BsConsole.showf(undefined, /* Format */[
                  /* String_literal */Block.__(11, [
                      "Failed to ",
                      /* String */Block.__(2, [
                          /* No_padding */0,
                          /* String_literal */Block.__(11, [
                              ": ",
                              /* String */Block.__(2, [
                                  /* No_padding */0,
                                  /* End_of_format */0
                                ])
                            ])
                        ])
                    ]),
                  "Failed to %s: %s"
                ]), action, _err[1].message));
    return Curry._1(cb, undefined);
  } else if (_err[0] === BpgTask$BsConsole.BpgError) {
    SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                  /* String_literal */Block.__(11, [
                      "Failed to fetch team members: ",
                      /* String */Block.__(2, [
                          /* No_padding */0,
                          /* End_of_format */0
                        ])
                    ]),
                  "Failed to fetch team members: %s"
                ]), _err[2]));
    return Curry._1(cb, undefined);
  } else {
    SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Failed to fetch teams: Unknown error has occured."));
    return Curry._1(cb, undefined);
  }
}

function handleCreateOrEditTeam(token, currentTeam, config, refetchTeams, teamName, teamDescription, closeCb) {
  if (currentTeam !== undefined) {
    var arg = BpgTask$BsConsole.Team.updateFieldsByKey({
          id: currentTeam.id,
          universe: config.universe.id
        }, [
          /* tuple */[
            "name",
            BpgTask$BsConsole.encodeStringOrNull(currentTeam.name)
          ],
          /* tuple */[
            "description",
            BpgTask$BsConsole.encodeStringOrNull(teamDescription)
          ]
        ]);
    Curry._2((function (param) {
              return (function (param$1, param$2) {
                  return Task2$BsConsole.handle(param, arg, param$1, param$2);
                });
            })(token), true, (function (results) {
            handleResults(results, "edit team", refetchTeams);
            MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* Teams_EditTeam */64]));
            return Curry._1(closeCb, undefined);
          }));
  } else {
    var arg$1 = BpgTask$BsConsole.Team.create({
          id: 0,
          universe: config.universe.id,
          name: teamName,
          description: teamDescription,
          __state: undefined,
          __create: undefined,
          __modify: undefined
        });
    Curry._2((function (param) {
              return (function (param$1, param$2) {
                  return Task2$BsConsole.handle(param, arg$1, param$1, param$2);
                });
            })(token), true, (function (results) {
            handleResults(results, "create team", refetchTeams);
            MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* Teams_CreateTeam */63]));
            return Curry._1(closeCb, undefined);
          }));
  }
  
}

var selectStyle = Css.style(/* :: */[
      Css.minWidth(Css.px(200)),
      /* :: */[
        Css.backgroundColor(Css.hex(Colors$BsConsole.grey65)),
        /* :: */[
          Css.paddingLeft(Css.px(10)),
          /* [] */0
        ]
      ]
    ]);

function isAdmin(config, projectName) {
  var match = config.user.role;
  var match$1 = Configuration$BsConsole.getProjectRole(projectName, config);
  if (match === "admin") {
    return true;
  }
  if (match$1 === "admin") {
    return true;
  } else {
    return false;
  }
}

function isGuest(config) {
  var match = config.user.role;
  if (match === "guest") {
    return true;
  } else {
    return false;
  }
}

function isAtLeastMemberRole(config, projectName) {
  if (config.user.superuser === 1) {
    return true;
  }
  var match = config.user.role;
  var match$1 = Configuration$BsConsole.getProjectRole(projectName, config);
  if (match !== undefined) {
    switch (match) {
      case "admin" :
      case "member" :
          return true;
      default:
        
    }
  }
  if (match$1 === undefined) {
    return false;
  }
  switch (match$1) {
    case "admin" :
    case "member" :
        return true;
    default:
      return false;
  }
}

function handleCreateTeam(token, config, teamName, teamDescription, cb) {
  var arg = BpgTask$BsConsole.Team.create({
        id: 0,
        universe: config.universe.id,
        name: teamName,
        description: teamDescription,
        __state: undefined,
        __create: undefined,
        __modify: undefined
      });
  return Curry._2((function (param) {
                  return (function (param$1, param$2) {
                      return Task2$BsConsole.handle(param, arg, param$1, param$2);
                    });
                })(token), true, (function (results) {
                var exit = 0;
                var message;
                if (results.tag) {
                  var _err = results[0];
                  if (_err[0] === Task2$BsConsole.CoronerError) {
                    message = _err[1].message;
                    exit = 1;
                  } else if (_err[0] === BpgTask$BsConsole.BpgError) {
                    message = _err[2];
                    exit = 1;
                  } else {
                    SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Failed to create team: Unknown error has occured"));
                  }
                } else {
                  Curry._1(cb, results);
                }
                if (exit === 1) {
                  SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                /* String_literal */Block.__(11, [
                                    "Failed to create team: ",
                                    /* String */Block.__(2, [
                                        /* No_padding */0,
                                        /* End_of_format */0
                                      ])
                                  ]),
                                "Failed to create team: %s"
                              ]), message));
                }
                return MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* Teams_CreateTeam */63]));
              }));
}

var coronerdVersion = "1.48.9";

exports.handleResults = handleResults;
exports.handleRefetchableResults = handleRefetchableResults;
exports.handleCreateOrEditTeam = handleCreateOrEditTeam;
exports.selectStyle = selectStyle;
exports.coronerdVersion = coronerdVersion;
exports.isAdmin = isAdmin;
exports.isGuest = isGuest;
exports.isAtLeastMemberRole = isAtLeastMemberRole;
exports.handleCreateTeam = handleCreateTeam;
/* selectStyle Not a pure module */
