// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var $$String = require("bs-platform/lib/js/string.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Belt_SortArray = require("bs-platform/lib/js/belt_SortArray.js");
var Col2$BsConsole = require("../components/Col2.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Util$BsConsole = require("../util.js");
var Colors$BsConsole = require("../Colors.js");
var BugStyles$BsConsole = require("./BugStyles.js");
var SymbolTable$BsConsole = require("../project-settings/symbols/SymbolTable.js");
var MetricsEvent$BsConsole = require("../bs-metrics/MetricsEvent.js");
var BugAttributeRow$BsConsole = require("./BugAttributeRow.js");
var TextField = require("@material-ui/core/TextField");
var Attribute_Helpers$BsConsole = require("../Attribute_Helpers.js");
var AttributesContext$BsConsole = require("../context/AttributesContext.js");
var FilterQueryBuilder$BsConsole = require("../FilterQueryBuilder.js");

var queryBuilderAttrs = Belt_List.map(Belt_List.concatMany([
          FilterQueryBuilder$BsConsole.Groups.Data.errorDetails,
          FilterQueryBuilder$BsConsole.Groups.Data.devicesAndPlatforms,
          FilterQueryBuilder$BsConsole.Groups.Data.memoryAndProcess,
          FilterQueryBuilder$BsConsole.Groups.Data.misc
        ]), (function (attr) {
        return /* tuple */[
                attr.attributeName,
                attr
              ];
      }));

var attrDict = Js_dict.fromList(queryBuilderAttrs);

function getGroupedAttributes(attributes, describedAttributes) {
  var groupedDict = { };
  Belt_Array.forEach(attributes, (function (param) {
          var v = param[1];
          var k = param[0];
          var isCustom = Attribute_Helpers$BsConsole.RelevantAttributes.isCustomAttribute(describedAttributes, k);
          if (Attribute_Helpers$BsConsole.RelevantAttributes.includeAttr(isCustom, describedAttributes.active, /* tuple */[
                  k,
                  v
                ])) {
            var category = isCustom ? /* Custom */5 : Belt_Option.getWithDefault(Belt_Option.map(Js_dict.get(attrDict, k), (function (attr) {
                          return attr.group;
                        })), /* Misc */4);
            var attr = /* tuple */[
              k,
              v
            ];
            var arr = Js_dict.get(groupedDict, FilterQueryBuilder$BsConsole.Groups.groupToFriendlyStr(category));
            var newArr = arr !== undefined ? Belt_Array.concat(arr, [attr]) : [attr];
            groupedDict[FilterQueryBuilder$BsConsole.Groups.groupToFriendlyStr(category)] = newArr;
            return ;
          }
          
        }));
  return Js_dict.entries(groupedDict);
}

var AttributeGroups = {
  queryBuilderAttrs: queryBuilderAttrs,
  attrDict: attrDict,
  getGroupedAttributes: getGroupedAttributes
};

function BugAttributes$GroupedAttributesView(Props) {
  var attributes = Props.attributes;
  var numCols = Props.numCols;
  var describedAttributes = Props.describedAttributes;
  var onAddFilter = Props.onAddFilter;
  var searchValue = Props.searchValue;
  var arg = function (param) {
    var value = Util$BsConsole.formattedStringify(param[1]);
    return param[0] + (" " + value);
  };
  var attributesSearch = function (param) {
    return (function (param$1) {
        return SymbolTable$BsConsole.fuzz(param, param$1, arg);
      });
  };
  var searchedAttrs = attributesSearch(attributes)(searchValue);
  var groupedAttributes = getGroupedAttributes(searchedAttrs, describedAttributes);
  var sortedGroupedAttrs = Belt_SortArray.stableSortBy(groupedAttributes, (function (param, param$1) {
          return $$String.compare(param[0].toLowerCase(), param$1[0].toLowerCase());
        }));
  var misc = Belt_Array.keep(sortedGroupedAttrs, (function (param) {
          return param[0] === "Misc";
        }));
  var others = Belt_Array.keep(sortedGroupedAttrs, (function (param) {
          return param[0] !== "Misc";
        }));
  var colRender = numCols > 1 ? [
      others,
      misc
    ] : [sortedGroupedAttrs];
  return Belt_Array.map(Belt_Array.range(0, colRender.length - 1 | 0), (function (colIdx) {
                return React.createElement(Col2$BsConsole.make, {
                            children: Belt_Array.map(Belt_Option.getWithDefault(Belt_Array.get(colRender, colIdx), []), (function (param) {
                                    return React.createElement("div", {
                                                key: String(colIdx),
                                                className: Css.style(/* :: */[
                                                      colIdx !== 0 ? Css.borderLeft(Css.px(1), Css.solid, Css.hex(BugStyles$BsConsole.Color.dividerTertiary)) : Css.marginBottom(Css.zero),
                                                      /* :: */[
                                                        Css.maxWidth(Css.px(500)),
                                                        /* :: */[
                                                          Css.display(Css.grid),
                                                          /* :: */[
                                                            Css.gridTemplateColumns(/* :: */[
                                                                  Css.auto,
                                                                  /* :: */[
                                                                    Css.auto,
                                                                    /* [] */0
                                                                  ]
                                                                ]),
                                                            /* :: */[
                                                              Css.gridColumnGap(Css.px(10)),
                                                              /* :: */[
                                                                Css.fontFamily("Roboto"),
                                                                /* :: */[
                                                                  Css.fontSize(Css.px(12)),
                                                                  /* :: */[
                                                                    Css.padding2(Css.px(11), Css.px(16)),
                                                                    /* [] */0
                                                                  ]
                                                                ]
                                                              ]
                                                            ]
                                                          ]
                                                        ]
                                                      ]
                                                    ])
                                              }, React.createElement("div", {
                                                    className: Css.style(/* :: */[
                                                          Css.fontFamily("Roboto"),
                                                          /* :: */[
                                                            Css.fontWeight(Css.medium),
                                                            /* :: */[
                                                              Css.color(Css.hex(Colors$BsConsole.grey0)),
                                                              /* :: */[
                                                                Css.fontSize(Css.px(12)),
                                                                /* :: */[
                                                                  Css.margin2(Css.px(4), Css.zero),
                                                                  /* :: */[
                                                                    Css.textTransform(Css.uppercase),
                                                                    /* [] */0
                                                                  ]
                                                                ]
                                                              ]
                                                            ]
                                                          ]
                                                        ])
                                                  }, I18N$BsConsole.showSkip(param[0])), React.createElement("div", undefined), Belt_Array.map(param[1], (function (param) {
                                                      var key = param[0];
                                                      var projectAttribute = Belt_List.getBy(describedAttributes.active, (function (pa) {
                                                              return pa.name === key;
                                                            }));
                                                      return React.createElement(BugAttributeRow$BsConsole.make, {
                                                                  onAddFilter: onAddFilter,
                                                                  attributeName: key,
                                                                  value: param[1],
                                                                  projectAttribute: projectAttribute,
                                                                  key: key
                                                                });
                                                    })));
                                  }))
                          });
              }));
}

var GroupedAttributesView = {
  make: BugAttributes$GroupedAttributesView
};

function BugAttributes(Props) {
  var onAddFilter = Props.onAddFilter;
  var w = Props.width;
  var attributes = Props.attributes;
  Props.row;
  var describedAttributes = React.useContext(AttributesContext$BsConsole.ctx);
  var match = React.useState((function () {
          return "";
        }));
  var setSearchValue = match[1];
  var searchValue = match[0];
  var attributes$1 = Belt_SortArray.stableSortBy(Js_dict.entries(attributes), (function (param, param$1) {
          return $$String.compare(param[0].toLowerCase(), param$1[0].toLowerCase());
        }));
  var numCols = w > 1000 ? 2 : 1;
  return React.createElement(Col2$BsConsole.make, {
              children: null
            }, React.createElement("div", {
                  className: Css.style(/* :: */[
                        Css.marginLeft(Css.px(16)),
                        /* [] */0
                      ])
                }, React.createElement(TextField.default, {
                      placeholder: I18N$BsConsole.get(undefined, "Search attributes"),
                      value: searchValue,
                      onChange: (function (e) {
                          var value = e.target.value;
                          MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* Debugger_Attributes_Search */108]));
                          return Curry._1(setSearchValue, (function (param) {
                                        return value;
                                      }));
                        })
                    })), React.createElement(Row2$BsConsole.make, {
                  children: React.createElement(BugAttributes$GroupedAttributesView, {
                        attributes: attributes$1,
                        numCols: numCols,
                        describedAttributes: describedAttributes,
                        onAddFilter: onAddFilter,
                        searchValue: searchValue
                      })
                }));
}

var make = BugAttributes;

exports.AttributeGroups = AttributeGroups;
exports.GroupedAttributesView = GroupedAttributesView;
exports.make = make;
/* queryBuilderAttrs Not a pure module */
