// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Fetch = require("bs-fetch/lib/js/src/Fetch.js");
var Semver = require("@gladimdim/bs-semver/lib/js/src/semver.bs.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var I18N$BsConsole = require("./I18N.js");
var Util$BsConsole = require("./util.js");
var TriageGroup$BsConsole = require("./TriageGroup.js");
var MetricsEvent$BsConsole = require("./bs-metrics/MetricsEvent.js");
var Caml_builtin_exceptions = require("bs-platform/lib/js/caml_builtin_exceptions.js");
var InboxReadOnly$BsConsole = require("./inbox/InboxReadOnly.js");
var SnackPurveyor$BsConsole = require("./SnackPurveyor.js");

var ver = window._BACKTRACE_CORONER_VERSION;

var coronerVersion = (ver == null) ? I18N$BsConsole.get(undefined, "Unknown") : ver;

var validVersion = Semver.valid(coronerVersion);

var settingApiVersion = validVersion !== undefined && !Semver.gt(validVersion, "1.35.22") ? /* QueryApi */0 : /* IssueApi */[1];

function successOfJson(param) {
  return /* FullSuccess */0;
}

function conflictOfJson(json) {
  return /* Conflict */Block.__(0, [{
              conflicts: Json_decode.field("conflicts", (function (param) {
                      return Json_decode.list(Json_decode.string, param);
                    }), json)
            }]);
}

function fingerprintResultOfJson(param) {
  return /* FullSuccess */0;
}

function ofJsonQueryApi(json) {
  return Json_decode.map((function (param) {
                switch (param) {
                  case "conflict" :
                      return conflictOfJson(json);
                  case "success" :
                      return /* FullSuccess */0;
                  default:
                    throw [
                          Caml_builtin_exceptions.assert_failure,
                          /* tuple */[
                            "TriageIssueRemote.re",
                            41,
                            16
                          ]
                        ];
                }
              }), (function (param) {
                return Json_decode.field("result", Json_decode.string, param);
              }), json);
}

function ofJson(v) {
  return Json_decode.oneOf(/* :: */[
              (function (json) {
                  return /* Success */Block.__(0, [{
                              id: Json_decode.field("id", Json_decode.$$int, json),
                              fingerprint: Json_decode.field("fingerprint", Json_decode.string, json)
                            }]);
                }),
              /* :: */[
                (function (json) {
                    return /* Failure */Block.__(1, [{
                                fingerprint: Json_decode.field("fingerprint", Json_decode.string, json),
                                error: Json_decode.field("error", Json_decode.string, json)
                              }]);
                  }),
                /* [] */0
              ]
            ], v);
}

var FingerprintResult = {
  ofJson: ofJson
};

function ofJsonIssueApi(json) {
  var results = Json_decode.field("results", (function (json) {
          return Json_decode.list(ofJson, json);
        }), json);
  if (Belt_List.length(Belt_List.keep(results, (function (r) {
                return r.tag ? false : true;
              }))) === Belt_List.length(results)) {
    return /* FullSuccess */0;
  } else {
    return /* Failure */Block.__(1, [I18N$BsConsole.get(undefined, "Failure")]);
  }
}

var Result = {
  successOfJson: successOfJson,
  conflictOfJson: conflictOfJson,
  fingerprintResultOfJson: fingerprintResultOfJson,
  ofJsonQueryApi: ofJsonQueryApi,
  FingerprintResult: FingerprintResult,
  ofJsonIssueApi: ofJsonIssueApi
};

function parseResponse(json) {
  if (settingApiVersion) {
    return Json_decode.optional(ofJsonIssueApi, json);
  } else {
    return Json_decode.map((function (param) {
                  return Json_decode.optional(ofJsonQueryApi, param);
                }), (function (param) {
                  return Json_decode.field("response", Util$BsConsole.identity, param);
                }), json);
  }
}

function toastReadOnly(param) {
  return new Promise((function (resolve, _r) {
                SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Skipped: You are currently in \"Read only\" mode. Please contact your system administrator for more information."));
                return resolve(undefined);
              }));
}

function toastN(results) {
  return new Promise((function (resolve, _r) {
                var nSkipped = Belt_List.length(Belt_List.keep(Belt_List.fromArray(results), (function (d) {
                            if (d !== undefined && !(typeof d === "number" || d.tag)) {
                              return true;
                            } else {
                              return false;
                            }
                          })));
                var nFailure = Belt_List.length(Belt_List.keep(Belt_List.fromArray(results), (function (d) {
                            return d === undefined;
                          })));
                var nSuccess = Belt_List.length(Belt_List.keep(Belt_List.fromArray(results), (function (d) {
                            return Caml_obj.caml_equal(d, /* FullSuccess */0);
                          })));
                var message = nSkipped !== 0 ? Curry._3(I18N$BsConsole.getf(undefined, /* Format */[
                            /* Int */Block.__(4, [
                                /* Int_d */0,
                                /* No_padding */0,
                                /* No_precision */0,
                                /* String_literal */Block.__(11, [
                                    " skipped (attempt to set on stale data). ",
                                    /* Int */Block.__(4, [
                                        /* Int_d */0,
                                        /* No_padding */0,
                                        /* No_precision */0,
                                        /* String_literal */Block.__(11, [
                                            " failures. ",
                                            /* Int */Block.__(4, [
                                                /* Int_d */0,
                                                /* No_padding */0,
                                                /* No_precision */0,
                                                /* String_literal */Block.__(11, [
                                                    " successes",
                                                    /* End_of_format */0
                                                  ])
                                              ])
                                          ])
                                      ])
                                  ])
                              ]),
                            "%d skipped (attempt to set on stale data). %d failures. %d successes"
                          ]), nSkipped, nFailure, nSuccess) : (
                    nFailure !== 0 ? Curry._2(I18N$BsConsole.getf(undefined, /* Format */[
                                /* Int */Block.__(4, [
                                    /* Int_d */0,
                                    /* No_padding */0,
                                    /* No_precision */0,
                                    /* String_literal */Block.__(11, [
                                        " failures. ",
                                        /* Int */Block.__(4, [
                                            /* Int_d */0,
                                            /* No_padding */0,
                                            /* No_precision */0,
                                            /* String_literal */Block.__(11, [
                                                " successes",
                                                /* End_of_format */0
                                              ])
                                          ])
                                      ])
                                  ]),
                                "%d failures. %d successes"
                              ]), nFailure, nSuccess) : (
                        nSuccess !== 0 ? Curry._1(I18N$BsConsole.getf(undefined, /* Format */[
                                    /* Int */Block.__(4, [
                                        /* Int_d */0,
                                        /* No_padding */0,
                                        /* No_precision */0,
                                        /* String_literal */Block.__(11, [
                                            " issues updated. Success",
                                            /* End_of_format */0
                                          ])
                                      ]),
                                    "%d issues updated. Success"
                                  ]), nSuccess) : I18N$BsConsole.get(undefined, "No changes performed.")
                      )
                  );
                SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.showSkip(message));
                return resolve(undefined);
              }));
}

function toast(fingerprint, getDetailsOpt, result) {
  var getDetails = getDetailsOpt !== undefined ? Caml_option.valFromOption(getDetailsOpt) : undefined;
  var successMessage = I18N$BsConsole.get(undefined, "Success. Issue updated.");
  var failureMessage = I18N$BsConsole.get(undefined, "Failure. Issue not updated.");
  var conflictMessage = I18N$BsConsole.get(undefined, "Skipped. Setting with stale data detected.");
  if (result !== undefined) {
    if (typeof result === "number") {
      if (getDetails !== undefined) {
        return SnackPurveyor$BsConsole.enqueue({
                    label: I18N$BsConsole.get(undefined, "Details"),
                    onClick: (function (param) {
                        return Curry._1(getDetails, fingerprint);
                      })
                  }, undefined, undefined, undefined, I18N$BsConsole.showSkip(successMessage));
      } else {
        return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.showSkip(successMessage));
      }
    } else if (result.tag) {
      return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.showSkip(failureMessage));
    } else if (getDetails !== undefined) {
      return SnackPurveyor$BsConsole.enqueue({
                  label: I18N$BsConsole.get(undefined, "Details"),
                  onClick: (function (param) {
                      return Curry._1(getDetails, fingerprint);
                    })
                }, undefined, undefined, undefined, I18N$BsConsole.showSkip(conflictMessage));
    } else {
      return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.showSkip(conflictMessage));
    }
  } else {
    return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.showSkip(failureMessage));
  }
}

var $$Response = {
  parseResponse: parseResponse,
  toastReadOnly: toastReadOnly,
  toastN: toastN,
  toast: toast
};

function runRequests(requests, cb) {
  return Promise.all(requests).then((function (responses) {
                  return Promise.all(Belt_Array.map(responses, (function (response) {
                                    return response.json();
                                  })));
                })).then((function (jsons) {
                var results = Belt_Array.map(jsons, parseResponse);
                Curry._1(cb, results);
                return Promise.resolve(results);
              }));
}

function runRequest(request) {
  if (settingApiVersion) {
    return request.then((function (response) {
                    return response.json();
                  })).then((function (json) {
                  return Promise.resolve(parseResponse(json));
                }));
  } else {
    return request.then((function (response) {
                    return response.json();
                  })).then((function (json) {
                  return Promise.resolve(parseResponse(json));
                }));
  }
}

function makeHeaders(token) {
  return [
          /* tuple */[
            "content-type",
            "application/json"
          ],
          /* tuple */[
            "X-Coroner-Token",
            token
          ]
        ];
}

function bodyForRequest(settingActions, fingerprint, project, seq) {
  if (!settingApiVersion) {
    return JSON.stringify(Json_encode.object_(/* :: */[
                    /* tuple */[
                      "filter",
                      Json_encode.list((function (v) {
                              return v;
                            }), /* :: */[
                            Json_encode.object_(/* :: */[
                                  /* tuple */[
                                    "fingerprint",
                                    Json_encode.list((function (v) {
                                            return v;
                                          }), /* :: */[
                                          Json_encode.list((function (v) {
                                                  return v;
                                                }), /* :: */[
                                                "equal",
                                                /* :: */[
                                                  fingerprint,
                                                  /* [] */0
                                                ]
                                              ]),
                                          /* [] */0
                                        ])
                                  ],
                                  /* [] */0
                                ]),
                            /* [] */0
                          ])
                    ],
                    /* :: */[
                      /* tuple */[
                        "set",
                        Json_encode.object_(Belt_List.map(settingActions, (function (i) {
                                    return Curry._1(TriageGroup$BsConsole.Actions.Setting.toJsonTuple, i);
                                  })))
                      ],
                      /* :: */[
                        /* tuple */[
                          "table",
                          "issues"
                        ],
                        /* :: */[
                          /* tuple */[
                            "seq",
                            Json_encode.nullable((function (prim) {
                                    return prim;
                                  }), seq)
                          ],
                          /* [] */0
                        ]
                      ]
                    ]
                  ]));
  }
  var sets = Belt_List.map(settingActions, (function (i) {
          return Curry._1(TriageGroup$BsConsole.Actions.Setting.toIssuesSetJsonTuple, i);
        }));
  return JSON.stringify(Json_encode.object_(Belt_List.concat(sets, /* :: */[
                      /* tuple */[
                        "fingerprint",
                        Json_encode.list((function (prim) {
                                return prim;
                              }), /* :: */[
                              fingerprint,
                              /* [] */0
                            ])
                      ],
                      /* :: */[
                        /* tuple */[
                          "project",
                          project
                        ],
                        /* :: */[
                          /* tuple */[
                            "seq",
                            Json_encode.nullable((function (prim) {
                                    return prim;
                                  }), seq)
                          ],
                          /* [] */0
                        ]
                      ]
                    ])));
}

function makeInboxGroupRequest(project, token, fingerprint, seq, settingActions) {
  var url = settingApiVersion ? "/api/issue" : "/api/query?project=" + project;
  var headers = makeHeaders(token);
  var body = bodyForRequest(settingActions, fingerprint, project, seq);
  var init = Fetch.RequestInit.make(/* Post */2, Caml_option.some(headers), Caml_option.some(body), undefined, undefined, undefined, /* SameOrigin */1, undefined, undefined, undefined, undefined)(undefined);
  return fetch(url, init);
}

function makeInboxGroupRequestWithoutSeq(project, token, fingerprint, settingActions) {
  var url = settingApiVersion ? "/api/issue" : "/api/query?project=" + project;
  var headers = makeHeaders(token);
  var body;
  if (settingApiVersion) {
    var sets = Belt_List.map(settingActions, (function (i) {
            return Curry._1(TriageGroup$BsConsole.Actions.Setting.toIssuesSetJsonTuple, i);
          }));
    body = JSON.stringify(Json_encode.object_(Belt_List.concat(sets, /* :: */[
                  /* tuple */[
                    "fingerprint",
                    Json_encode.list((function (prim) {
                            return prim;
                          }), /* :: */[
                          fingerprint,
                          /* [] */0
                        ])
                  ],
                  /* :: */[
                    /* tuple */[
                      "project",
                      project
                    ],
                    /* [] */0
                  ]
                ])));
  } else {
    body = JSON.stringify(Json_encode.object_(/* :: */[
              /* tuple */[
                "filter",
                Json_encode.list((function (v) {
                        return v;
                      }), /* :: */[
                      Json_encode.object_(/* :: */[
                            /* tuple */[
                              "fingerprint",
                              Json_encode.list((function (v) {
                                      return v;
                                    }), /* :: */[
                                    Json_encode.list((function (v) {
                                            return v;
                                          }), /* :: */[
                                          "equal",
                                          /* :: */[
                                            fingerprint,
                                            /* [] */0
                                          ]
                                        ]),
                                    /* [] */0
                                  ])
                            ],
                            /* [] */0
                          ]),
                      /* [] */0
                    ])
              ],
              /* :: */[
                /* tuple */[
                  "set",
                  Json_encode.object_(Belt_List.map(settingActions, (function (i) {
                              return Curry._1(TriageGroup$BsConsole.Actions.Setting.toJsonTuple, i);
                            })))
                ],
                /* :: */[
                  /* tuple */[
                    "table",
                    "issues"
                  ],
                  /* [] */0
                ]
              ]
            ]));
  }
  var init = Fetch.RequestInit.make(/* Post */2, Caml_option.some(headers), Caml_option.some(body), undefined, undefined, undefined, /* SameOrigin */1, undefined, undefined, undefined, undefined)(undefined);
  return fetch(url, init);
}

var $$Request = {
  runRequests: runRequests,
  runRequest: runRequest,
  makeHeaders: makeHeaders,
  bodyForRequest: bodyForRequest,
  makeInboxGroupRequest: makeInboxGroupRequest,
  makeInboxGroupRequestWithoutSeq: makeInboxGroupRequestWithoutSeq
};

function setIssueWithoutSeq(preflight, postFlight, token, project, triageGroup, actions, param) {
  var settingActions = Curry._1(TriageGroup$BsConsole.Actions.Setting.mergeSettingActions, TriageGroup$BsConsole.Actions.toSettingActions(actions, triageGroup));
  if (InboxReadOnly$BsConsole.isEnabled) {
    return toastReadOnly(undefined);
  } else {
    return (
              Curry._1(preflight, /* :: */[
                    /* tuple */[
                      triageGroup.fingerprint,
                      settingActions
                    ],
                    /* [] */0
                  ]) ? runRequest(makeInboxGroupRequestWithoutSeq(project, token, triageGroup.fingerprint, settingActions)) : new Promise((function (resolve, _r) {
                        return resolve(undefined);
                      }))
            ).then((function (result) {
                  Curry._2(postFlight, triageGroup.fingerprint, result);
                  Belt_List.forEach(actions, (function (action) {
                          var tmp;
                          switch (action.tag | 0) {
                            case /* SetTicketsAssignment */0 :
                                tmp = /* Action */Block.__(0, [/* Triage_SetLinkedAssignees */57]);
                                break;
                            case /* SetState */1 :
                                tmp = /* Action */Block.__(0, [/* Triage_SetState */52]);
                                break;
                            case /* SetInvariant */2 :
                                tmp = /* Action */Block.__(0, [/* Triage_SetInvariant */53]);
                                break;
                            case /* SetBtAssignee */3 :
                                tmp = /* Action */Block.__(0, [/* Triage_SetBtAssignee */54]);
                                break;
                            case /* SetUnlinkedTicket */4 :
                                tmp = /* Action */Block.__(0, [/* Triage_SetUnlinkedTicket */55]);
                                break;
                            case /* AddUnlinkedTicket */5 :
                                tmp = /* Action */Block.__(0, [/* Triage_AddUnlinkedTicket */56]);
                                break;
                            case /* RemoveTickets */6 :
                                tmp = /* Action */Block.__(0, [/* Triage_RemoveTicket */58]);
                                break;
                            case /* RemoveTags */7 :
                                tmp = /* Action */Block.__(0, [/* Triage_RemoveTags */50]);
                                break;
                            case /* AddTags */8 :
                            case /* AddRemoveTags */9 :
                                tmp = /* Action */Block.__(0, [/* Triage_AddTags */49]);
                                break;
                            
                          }
                          return MetricsEvent$BsConsole.send(tmp);
                        }));
                  return Promise.resolve(/* tuple */[
                              triageGroup.fingerprint,
                              result
                            ]);
                }));
  }
}

function setIssues(preflight, postFlight, postFlightAll, token, project, triageGroups, actions, param) {
  return Promise.all(Belt_List.toArray(Belt_List.map(triageGroups, (function (triageGroup) {
                          return setIssueWithoutSeq(preflight, postFlight, token, project, triageGroup, actions, undefined);
                        })))).then((function (data) {
                Curry._1(postFlightAll, data);
                return Promise.resolve(data);
              }));
}

var setIssue = setIssueWithoutSeq;

exports.coronerVersion = coronerVersion;
exports.settingApiVersion = settingApiVersion;
exports.Result = Result;
exports.$$Response = $$Response;
exports.$$Request = $$Request;
exports.setIssueWithoutSeq = setIssueWithoutSeq;
exports.setIssue = setIssue;
exports.setIssues = setIssues;
/* ver Not a pure module */
