// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';


var green = "3DDC91";

var focusGreenBackground = "38655C";

var yellow = "FFCD48";

exports.green = green;
exports.focusGreenBackground = focusGreenBackground;
exports.yellow = yellow;
/* No side effect */
