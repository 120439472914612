// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var DateFns = require("bs-date-fns/lib/js/src/DateFns.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var CommentsUtil$BsConsole = require("./activity-feed/CommentsUtil.js");

function fromJson(json) {
  return Json_decode.field("version", Json_decode.string, json);
}

var Status = {
  fromJson: fromJson
};

function fromJson$1(json) {
  return Json_decode.field("err", Json_decode.string, json);
}

var $$Error = {
  fromJson: fromJson$1
};

function fromJson$2(json) {
  return {
          id: Json_decode.field("id", Json_decode.$$int, json),
          userId: Json_decode.field("userId", Json_decode.$$int, json),
          contents: CommentsUtil$BsConsole.decode(Json_decode.field("contents", Json_decode.string, json)),
          timestamp: DateFns.parseString(undefined, Json_decode.field("timestamp", Json_decode.string, json)),
          edited: Belt_Option.isSome(Json_decode.optional((function (param) {
                      return Json_decode.field("updatedAt", Json_decode.$$float, param);
                    }), json))
        };
}

var $$Comment = {
  fromJson: fromJson$2
};

exports.Status = Status;
exports.$$Error = $$Error;
exports.$$Comment = $$Comment;
/* DateFns Not a pure module */
