	var Mexp=function(parsed){
		this.value=parsed;

	};

	Mexp.math={
		isDegree:true, //mode of calculator
		acos:function(x){
			return (Mexp.math.isDegree?180/Math.PI*Math.acos(x):Math.acos(x));
		},
		add:function(a,b){
			return a+b;
		},
		asin:function(x){
			return (Mexp.math.isDegree?180/Math.PI*Math.asin(x):Math.asin(x));
		},
		atan:function(x){
			return (Mexp.math.isDegree?180/Math.PI*Math.atan(x):Math.atan(x));
		},
		acosh:function(x){
			return Math.log(x+Math.sqrt(x*x-1));
		},
		asinh:function(x){
			return Math.log(x+Math.sqrt(x*x+1));
		},
		atanh:function(x){
			return Math.log((1+x)/(1-x));
		},
		C:function(n,r){
			var pro=1,other=n-r,choice=r;
			if(choice<other){
			choice=other;
			other=r;
			}
			for(var i=choice+1;i<=n;i++)
				pro*=i;
			return pro/Mexp.math.fact(other);
		},
		changeSign:function(x){
			return -x;
		},
		cos:function(x){
			if(Mexp.math.isDegree)x=Mexp.math.toRadian(x);
			return Math.cos(x);
		},
		cosh:function(x){
			return (Math.pow(Math.E,x)+Math.pow(Math.E,-1*x))/2;
		},
		div:function(a,b){
		return a/b;
		},
		fact:function(n) {
		if(n%1!==0)return "NAN";
			var pro=1;
			for(var i=2;i<=n;i++)
				pro*=i;
			return pro;
		},
		inverse:function(x){
			return 1/x;
		},
		log:function(i){
			return Math.log(i)/Math.log(10);
		},
		mod:function(a,b){
		return a%b;
		},
		mul:function(a,b){
		return a*b;
		},
		P:function(n,r){var pro=1;
			 for(var i=Math.floor(n)-Math.floor(r)+1;i<=Math.floor(n);i++)
					pro*=i;
					return pro;

		},
		Pi:function(low,high,ex){
			var pro=1;
			for(var i=low;i<=high;i++){
				pro*=Number(ex.postfixEval({n:i}));
			}
			return pro;
		},
		pow10x:function(e){
			var x=1;
			while(e--){x*=10;}
			return x;
		},
		sigma:function(low,high,ex){
			var sum=0;
			for(var i=low;i<=high;i++){
				sum+=Number(ex.postfixEval({n:i}));
			}
			return sum;
		},
		sin:function(x){
			if(Mexp.math.isDegree)x=Mexp.math.toRadian(x);
			return Math.sin(x);
		},
		sinh:function(x){
			return (Math.pow(Math.E,x)-Math.pow(Math.E,-1*x))/2;
		},
		sub:function(a,b){
		return a-b;
		},
		tan:function(x){
			if(Mexp.math.isDegree)x=Mexp.math.toRadian(x);
			return Math.tan(x);
		},
		tanh:function(x){
			return Mexp.sinha(x)/Mexp.cosha(x);
		},
		toRadian:function(x){
			return x*Math.PI/180;
		}
	};
	Mexp.exception=function(message){
		this.message=message;
	};
    module.exports=Mexp;