// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Js_exn = require("bs-platform/lib/js/js_exn.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_int64 = require("bs-platform/lib/js/caml_int64.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Crdb$BsConsole = require("./crdb.js");
var I18N$BsConsole = require("./I18N.js");
var Util$BsConsole = require("./util.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var ObjectID$BsConsole = require("./ObjectID.js");
var WfRouter$BsConsole = require("./workflow/WfRouter.js");
var Backtrace$BsConsole = require("./bindings/Backtrace.js");
var UrlEncode$BsConsole = require("./UrlEncode.js");
var WfLinkUtil$BsConsole = require("./workflow/WfLinkUtil.js");
var Dedupe_Flag$BsConsole = require("./project-settings/dedupe/Dedupe_Flag.js");
var BtTableSessionData$BsConsole = require("./BtTableSessionData.js");
var DebuggerStateSerializer$BsConsole = require("./DebuggerStateSerializer.js");

function getParam(search, key, decoder) {
  var __x = Util$BsConsole.Qs.parse(search);
  return Json_decode.optional((function (param) {
                return Json_decode.field(key, decoder, param);
              }), __x);
}

function getAlpha(search) {
  return Belt_Option.map(getParam(search, "alpha", Json_decode.string), Pervasives.bool_of_string);
}

function getBeta(search) {
  return Belt_Option.map(getParam(search, "beta", Json_decode.string), Pervasives.bool_of_string);
}

function getI18N(search) {
  return Belt_Option.map(getParam(search, "i18n", Json_decode.string), Pervasives.bool_of_string);
}

function getWfBeta(search) {
  return Belt_Option.map(getParam(search, "wf-beta", Json_decode.string), Pervasives.bool_of_string);
}

function getNLQAlpha(search) {
  return Belt_Option.map(getParam(search, "nlq-alpha", Json_decode.string), Pervasives.bool_of_string);
}

function getToken(search) {
  return getParam(search, "token", Json_decode.string);
}

var Search = {
  getParam: getParam,
  getAlpha: getAlpha,
  getBeta: getBeta,
  getI18N: getI18N,
  getWfBeta: getWfBeta,
  getNLQAlpha: getNLQAlpha,
  getToken: getToken
};

var defaultStats = Crdb$BsConsole.Stats.empty;

var defaultFold = Curry._2(Crdb$BsConsole.Fold.add, Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
          "callstack",
          /* Head */1
        ]), Curry._2(Crdb$BsConsole.Fold.add, Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
              "timestamp",
              /* Bin */Block.__(0, [
                  32,
                  undefined
                ])
            ]), Crdb$BsConsole.Fold.empty));

var defaultSelects = Curry._2(Crdb$BsConsole.Select.add, "callstack", Curry._2(Crdb$BsConsole.Select.add, "timestamp", Curry._2(Crdb$BsConsole.Select.add, "fingerprint", Crdb$BsConsole.Select.empty)));

function withDefaultOnException($$default, decoder, json) {
  try {
    try {
      return Curry._1(decoder, json);
    }
    catch (raw_msg){
      var msg = Caml_js_exceptions.internalToOCamlException(raw_msg);
      if (msg[0] === Json_decode.DecodeError) {
        console.log(msg[1]);
        return $$default;
      }
      throw msg;
    }
  }
  catch (raw_e){
    var e = Caml_js_exceptions.internalToOCamlException(raw_e);
    if (e[0] === Js_exn.$$Error) {
      var __x = Backtrace$BsConsole.Attributes.addString("src_module", "route/withDefaultOnException", Backtrace$BsConsole.Attributes.make(undefined));
      Backtrace$BsConsole.Client.send(__x, /* `exn */[
            5049499,
            e[1]
          ]);
      return $$default;
    }
    throw e;
  }
}

function optDefault($$default, maybe) {
  if (maybe !== undefined) {
    return Caml_option.valFromOption(maybe);
  } else {
    return $$default;
  }
}

function decodeSearch(search) {
  var json = Util$BsConsole.Qs.parse(search);
  try {
    return {
            objectId: Json_decode.optional((function (param) {
                    return Json_decode.field("objectId", Json_decode.string, param);
                  }), json)
          };
  }
  catch (raw_msg){
    var msg = Caml_js_exceptions.internalToOCamlException(raw_msg);
    if (msg[0] === Json_decode.DecodeError) {
      console.log(msg[1]);
      return {
              objectId: undefined
            };
    }
    throw msg;
  }
}

var ProjectSettingsDedupeConfigParams = {
  decodeSearch: decodeSearch
};

function decodeSearch$1(search) {
  var json = Util$BsConsole.Qs.parse(search);
  return {
          pluginId: Json_decode.optional((function (param) {
                  return Json_decode.field("pluginId", Json_decode.string, param);
                }), json),
          connectionId: Belt_Option.map(Json_decode.optional((function (param) {
                      return Json_decode.field("connectionId", Json_decode.string, param);
                    }), json), Caml_format.caml_int_of_string),
          connectionCreated: Belt_Option.map(Json_decode.optional((function (param) {
                      return Json_decode.field("success", Json_decode.string, param);
                    }), json), Pervasives.bool_of_string),
          integrationId: Belt_Option.map(Json_decode.optional((function (param) {
                      return Json_decode.field("integrationId", Json_decode.string, param);
                    }), json), Caml_format.caml_int_of_string),
          referrer: Belt_Option.map(Json_decode.optional((function (param) {
                      return Json_decode.field("referrer", Json_decode.string, param);
                    }), json), (function (referrer) {
                  return WfRouter$BsConsole.Referrer.toType(referrer);
                })),
          error: Json_decode.optional((function (param) {
                  return Json_decode.field("error", Json_decode.string, param);
                }), json)
        };
}

function getOptionalParams(search) {
  try {
    return decodeSearch$1(search);
  }
  catch (exn){
    return ;
  }
}

var ProjectSettingsIntegrationsParams = {
  decodeSearch: decodeSearch$1,
  getOptionalParams: getOptionalParams
};

function decodeSearch$2(search) {
  var json = Util$BsConsole.Qs.parse(search);
  try {
    return {
            doc: Json_decode.field("doc", (function (param) {
                    return Json_decode.optional(Json_decode.string, param);
                  }), json)
          };
  }
  catch (raw_msg){
    var msg = Caml_js_exceptions.internalToOCamlException(raw_msg);
    if (msg[0] === Json_decode.DecodeError) {
      console.log(msg[1]);
      return {
              doc: undefined
            };
    }
    throw msg;
  }
}

var IntegratedDocsParams = {
  decodeSearch: decodeSearch$2
};

function decodeSearch$3(search) {
  var json = Util$BsConsole.Qs.parse(search);
  return {
          attribute: Json_decode.optional((function (param) {
                  return Json_decode.field("attribute", Json_decode.string, param);
                }), json)
        };
}

var ProjectSettingsAttributesParams_empty = {
  attribute: undefined
};

var ProjectSettingsAttributesParams = {
  empty: ProjectSettingsAttributesParams_empty,
  decodeSearch: decodeSearch$3
};

function decodeSearch$4(search) {
  var json = Util$BsConsole.Qs.parse(search);
  try {
    return {
            tab: Json_decode.optional((function (param) {
                    return Json_decode.field("type", Json_decode.string, param);
                  }), json),
            id: Json_decode.optional((function (param) {
                    return Json_decode.field("id", Json_decode.string, param);
                  }), json)
          };
  }
  catch (raw_msg){
    var msg = Caml_js_exceptions.internalToOCamlException(raw_msg);
    if (msg[0] === Json_decode.DecodeError) {
      console.log(msg[1]);
      return {
              tab: undefined,
              id: undefined
            };
    }
    throw msg;
  }
}

var ProjectSettingsAlertsParams = {
  decodeSearch: decodeSearch$4
};

function decodeSearch$5(search) {
  var json = Util$BsConsole.Qs.parse(search);
  var qs;
  try {
    qs = {
      error: Json_decode.field("error", Json_decode.string, json)
    };
  }
  catch (raw_msg){
    var msg = Caml_js_exceptions.internalToOCamlException(raw_msg);
    if (msg[0] === Json_decode.DecodeError) {
      console.log(msg[1]);
      return I18N$BsConsole.get(undefined, "An unknown error has occurred.");
    }
    throw msg;
  }
  return qs.error;
}

var GenericErrorParams = {
  decodeSearch: decodeSearch$5
};

function decodeSearch$6(search) {
  var json = Util$BsConsole.Qs.parse(search);
  try {
    return {
            referrer: Json_decode.optional((function (param) {
                    return Json_decode.field("referrer", Json_decode.string, param);
                  }), json),
            token: Json_decode.optional((function (param) {
                    return Json_decode.field("token", Json_decode.string, param);
                  }), json),
            redirect: Json_decode.optional((function (param) {
                    return Json_decode.field("redirect", Json_decode.string, param);
                  }), json),
            method: Json_decode.optional((function (param) {
                    return Json_decode.field("method", Json_decode.string, param);
                  }), json)
          };
  }
  catch (raw_msg){
    var msg = Caml_js_exceptions.internalToOCamlException(raw_msg);
    if (msg[0] === Json_decode.DecodeError) {
      console.log(msg[1]);
      return {
              referrer: undefined,
              token: undefined,
              redirect: undefined,
              method: undefined
            };
    }
    throw msg;
  }
}

var LoginParams = {
  decodeSearch: decodeSearch$6
};

function decodeSearch$7(search) {
  var json = Util$BsConsole.Qs.parse(search);
  try {
    return {
            token: Json_decode.field("token", Json_decode.string, json),
            username: Json_decode.field("username", Json_decode.string, json),
            set: Json_decode.map(Pervasives.bool_of_string, (function (param) {
                    return withDefaultOnException("true", (function (param) {
                                  return Json_decode.field("set", Json_decode.string, param);
                                }), param);
                  }), json)
          };
  }
  catch (raw_msg){
    var msg = Caml_js_exceptions.internalToOCamlException(raw_msg);
    if (msg[0] === Json_decode.DecodeError) {
      console.log(msg[1]);
      return {
              token: "",
              username: "",
              set: false
            };
    }
    throw msg;
  }
}

var ActivateParams = {
  decodeSearch: decodeSearch$7
};

function decodeSearch$8(search) {
  var json = Util$BsConsole.Qs.parse(search);
  try {
    return {
            token: Json_decode.field("token", Json_decode.string, json),
            username: Json_decode.field("username", Json_decode.string, json),
            universe_id: Json_decode.field("universe_id", Json_decode.string, json)
          };
  }
  catch (raw_msg){
    var msg = Caml_js_exceptions.internalToOCamlException(raw_msg);
    if (msg[0] === Json_decode.DecodeError) {
      console.log(msg[1]);
      return {
              token: "",
              username: "",
              universe_id: ""
            };
    }
    throw msg;
  }
}

var ResetParams = {
  decodeSearch: decodeSearch$8
};

function decodeSearch$9(search) {
  var json = Util$BsConsole.Qs.parse(search);
  try {
    return {
            username: Json_decode.field("username", Json_decode.string, json),
            password: Json_decode.field("password", Json_decode.string, json)
          };
  }
  catch (raw__msg){
    var _msg = Caml_js_exceptions.internalToOCamlException(raw__msg);
    if (_msg[0] === Json_decode.DecodeError) {
      return {
              username: "",
              password: ""
            };
    }
    throw _msg;
  }
}

var CreateAccountParams = {
  decodeSearch: decodeSearch$9
};

function decodeSearch$10(search) {
  var json = Util$BsConsole.Qs.parse(search);
  return {
          project: Json_decode.field("project", Json_decode.string, json),
          email: Json_decode.field("email", Json_decode.string, json),
          token: Json_decode.field("token", Json_decode.string, json),
          referrer: Json_decode.optional((function (param) {
                  return Json_decode.field("referrer", Json_decode.string, param);
                }), json)
        };
}

var UDashEntryParams = {
  decodeSearch: decodeSearch$10
};

function attributeUniq(l) {
  if (!l) {
    return /* [] */0;
  }
  var hd = l[0];
  return /* :: */[
          hd,
          attributeUniq(Belt_List.keep(l[1], (function (v) {
                      return Caml_obj.caml_notequal(hd, v);
                    })))
        ];
}

function getUniqueFoldAttributes(fold) {
  return attributeUniq(Belt_List.fromArray(Belt_Array.map(Belt_Array.map(Curry._1(Crdb$BsConsole.Stats.valuesToArray, fold), Crdb$BsConsole.StatsAggregation.toTuple), (function (param) {
                        return param[0];
                      }))));
}

function decodeSearch$11(_projectName, search) {
  var json = Util$BsConsole.Qs.parse(search);
  var __x = Json_decode.optional((function (param) {
          return Json_decode.field("aperture", Util$BsConsole.identity, param);
        }), json);
  var aperture = Belt_Option.map(__x, (function (json) {
          return Curry._1(Crdb$BsConsole.Aperture.fromURLEncodedJson, JSON.parse(json));
        }));
  if (aperture !== undefined) {
    return {
            aperture: Caml_option.valFromOption(aperture)
          };
  }
  var stuff = Js_dict.entries(json);
  var query = UrlEncode$BsConsole.Query.decodeOpt(Belt_List.fromArray(stuff));
  return {
          aperture: Curry._2(Crdb$BsConsole.Aperture.setFilters, Belt_Option.getWithDefault(query.filters, Crdb$BsConsole.Filters.empty), Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, Belt_Option.getWithDefault(query.time, Crdb$BsConsole.TimeAperture.$$default), Crdb$BsConsole.Aperture.$$default))
        };
}

var ProjectOverviewParams = {
  decodeSearch: decodeSearch$11
};

function decodeSearch$12(_projectName, search) {
  var json = Util$BsConsole.Qs.parse(search);
  var __x = Json_decode.optional((function (param) {
          return Json_decode.field("aperture", Util$BsConsole.identity, param);
        }), json);
  var aperture = Belt_Option.map(__x, (function (json) {
          return Curry._1(Crdb$BsConsole.Aperture.fromURLEncodedJson, JSON.parse(json));
        }));
  var groups = Belt_Option.flatMap(Json_decode.optional((function (param) {
              return Json_decode.field("groups", Json_decode.string, param);
            }), json), (function (jsonStr) {
          try {
            var __x = JSON.parse(jsonStr);
            return Json_decode.array(Json_decode.string, __x);
          }
          catch (exn){
            return ;
          }
        }));
  if (aperture !== undefined) {
    return {
            aperture: Caml_option.valFromOption(aperture),
            groups: groups
          };
  }
  var stuff = Js_dict.entries(json);
  var query = UrlEncode$BsConsole.Query.decodeOpt(Belt_List.fromArray(stuff));
  return {
          aperture: Curry._2(Crdb$BsConsole.Aperture.setFilters, Belt_Option.getWithDefault(query.filters, Crdb$BsConsole.Filters.empty), Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, Belt_Option.getWithDefault(query.time, Crdb$BsConsole.TimeAperture.$$default), Crdb$BsConsole.Aperture.$$default)),
          groups: groups
        };
}

var ProjectCompareReleasesParams = {
  decodeSearch: decodeSearch$12
};

function decodeSearch$13(_projectName, search) {
  var json = Util$BsConsole.Qs.parse(search);
  var __x = Json_decode.optional((function (param) {
          return Json_decode.field("aperture", Util$BsConsole.identity, param);
        }), json);
  var aperture = Belt_Option.map(__x, (function (json) {
          return Curry._1(Crdb$BsConsole.Aperture.fromURLEncodedJson, JSON.parse(json));
        }));
  if (aperture !== undefined) {
    return {
            aperture: Caml_option.valFromOption(aperture),
            sort: withDefaultOnException(Crdb$BsConsole.Sort.defaultAggregate, (function (param) {
                    return Json_decode.field("sort", (function (json) {
                                  return optDefault(Crdb$BsConsole.Sort.defaultAggregate, Curry._1(Crdb$BsConsole.Sort.fromJson, JSON.parse(json)));
                                }), param);
                  }), json),
            stats: getUniqueFoldAttributes(withDefaultOnException(Crdb$BsConsole.Stats.empty, (function (param) {
                        return Json_decode.field("fold", (function (json) {
                                      return Curry._1(Crdb$BsConsole.Stats.fromJson, JSON.parse(json));
                                    }), param);
                      }), json)),
            havings: withDefaultOnException(Crdb$BsConsole.Havings.empty, (function (param) {
                    return Json_decode.field("havings", (function (json) {
                                  return Curry._1(Crdb$BsConsole.Havings.fromJson, JSON.parse(json));
                                }), param);
                  }), json),
            fold: withDefaultOnException(Crdb$BsConsole.Fold.empty, (function (param) {
                    return Json_decode.field("fold", (function (json) {
                                  return Curry._1(Crdb$BsConsole.Fold.fromJson, JSON.parse(json));
                                }), param);
                  }), json),
            normBy: Json_decode.optional((function (param) {
                    return Json_decode.field("normBy", Json_decode.string, param);
                  }), json),
            similarity: Json_decode.optional((function (param) {
                    return Json_decode.field("similarity", Json_decode.bool, param);
                  }), json)
          };
  }
  var stuff = Js_dict.entries(json);
  var baseQuery = UrlEncode$BsConsole.Query.decode(/* [] */0);
  var query = UrlEncode$BsConsole.Query.decodeOpt(Belt_List.fromArray(stuff));
  return {
          aperture: Curry._2(Crdb$BsConsole.Aperture.setFilters, Belt_Option.getWithDefault(query.filters, Crdb$BsConsole.Filters.empty), Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, Belt_Option.getWithDefault(query.time, Crdb$BsConsole.TimeAperture.$$default), Crdb$BsConsole.Aperture.$$default)),
          sort: Belt_Option.getWithDefault(query.sort, Crdb$BsConsole.Sort.defaultAggregate),
          stats: Belt_Option.getWithDefault(query.stats, baseQuery.stats),
          havings: Belt_Option.getWithDefault(query.havings, Crdb$BsConsole.Havings.empty),
          fold: Belt_Option.getWithDefault(query.aggregations, Crdb$BsConsole.Fold.empty),
          normBy: query.normBy,
          similarity: query.similarity
        };
}

var ProjectInboxParams = {
  decodeSearch: decodeSearch$13
};

function getInboxParams(projectName, aperture, havings, stats, fold, sort, normBy, similarity, param) {
  var query = BtTableSessionData$BsConsole.Query.get(projectName, /* Triage */0);
  return {
          aperture: Belt_Option.getWithDefault(aperture, Curry._2(Crdb$BsConsole.Aperture.setFilters, query.filters, Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, query.time, Crdb$BsConsole.Aperture.$$default))),
          sort: Belt_Option.getWithDefault(sort, query.sort),
          stats: Belt_Option.getWithDefault(stats, query.stats),
          havings: Belt_Option.getWithDefault(havings, query.havings),
          fold: Belt_Option.getWithDefault(fold, query.aggregations),
          normBy: normBy,
          similarity: similarity
        };
}

function decodeSearch$14(_projectName, search) {
  var json = Util$BsConsole.Qs.parse(search);
  var __x = Json_decode.optional((function (param) {
          return Json_decode.field("aperture", Util$BsConsole.identity, param);
        }), json);
  var aperture = Belt_Option.map(__x, (function (json) {
          return Curry._1(Crdb$BsConsole.Aperture.fromURLEncodedJson, JSON.parse(json));
        }));
  if (aperture !== undefined) {
    return {
            aperture: Caml_option.valFromOption(aperture),
            factor: withDefaultOnException(/* Identity */0, (function (param) {
                    return Json_decode.field("factor", (function (json) {
                                  return Curry._1(Crdb$BsConsole.Factor.fromJson, JSON.parse(json));
                                }), param);
                  }), json),
            havings: withDefaultOnException(Crdb$BsConsole.Havings.empty, (function (param) {
                    return Json_decode.field("havings", (function (json) {
                                  return Curry._1(Crdb$BsConsole.Havings.fromJson, JSON.parse(json));
                                }), param);
                  }), json),
            fold: withDefaultOnException(Crdb$BsConsole.Fold.empty, (function (param) {
                    return Json_decode.field("fold", (function (json) {
                                  return Curry._1(Crdb$BsConsole.Fold.fromJson, JSON.parse(json));
                                }), param);
                  }), json),
            sort: withDefaultOnException(Crdb$BsConsole.Sort.defaultAggregate, (function (param) {
                    return Json_decode.field("sort", (function (json) {
                                  return optDefault(Crdb$BsConsole.Sort.defaultAggregate, Curry._1(Crdb$BsConsole.Sort.fromJson, JSON.parse(json)));
                                }), param);
                  }), json),
            normBy: Json_decode.optional((function (param) {
                    return Json_decode.field("normBy", Json_decode.string, param);
                  }), json),
            highlightExporter: Json_decode.optional((function (param) {
                    return Json_decode.field("highlightExporter", Json_decode.bool, param);
                  }), json)
          };
  }
  var stuff = Js_dict.entries(json);
  var baseQuery = UrlEncode$BsConsole.Query.decode(/* [] */0);
  var query = UrlEncode$BsConsole.Query.decodeOpt(Belt_List.fromArray(stuff));
  return {
          aperture: Curry._2(Crdb$BsConsole.Aperture.setFilters, Belt_Option.getWithDefault(query.filters, Crdb$BsConsole.Filters.empty), Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, Belt_Option.getWithDefault(query.time, Crdb$BsConsole.TimeAperture.$$default), Crdb$BsConsole.Aperture.$$default)),
          factor: Belt_Option.getWithDefault(query.groupBy, baseQuery.groupBy),
          havings: Belt_Option.getWithDefault(query.havings, Crdb$BsConsole.Havings.empty),
          fold: Belt_Option.getWithDefault(query.aggregations, Crdb$BsConsole.Fold.empty),
          sort: Belt_Option.getWithDefault(query.sort, Crdb$BsConsole.Sort.defaultAggregate),
          normBy: query.normBy,
          highlightExporter: query.highlightExporter
        };
}

var ProjectQueryBuilderParams = {
  decodeSearch: decodeSearch$14
};

function _decodeSearch(param, search) {
  var json = Util$BsConsole.Qs.parse(search);
  var querySon = Json_decode.field("query", (function (prim) {
          return JSON.parse(prim);
        }), json);
  var filter = Belt_Option.getWithDefault(Belt_Array.get(Json_decode.field("filter", (function (j) {
                  return Json_decode.array(Util$BsConsole.identity, j);
                }), querySon), 0), null);
  var timestamp = Json_decode.field("timestamp", (function (param) {
          return Json_decode.array((function (param) {
                        return Json_decode.tuple2(Json_decode.string, Json_decode.$$float, param);
                      }), param);
        }), filter);
  var fromTime = Belt_Array.getBy(timestamp, (function (param) {
          return param[0] === "at-least";
        }));
  var toTime = Belt_Array.getBy(timestamp, (function (param) {
          return param[0] === "at-most";
        }));
  var filters = Belt_Array.keep(Js_dict.entries(filter), (function (param) {
          return param[0] !== "timestamp";
        }));
  var aperture = fromTime !== undefined && toTime !== undefined ? Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, /* Custom */Block.__(1, [/* tuple */[
              new Date(fromTime[1] * 1000),
              new Date(toTime[1] * 1000)
            ]]), Crdb$BsConsole.Aperture.$$default) : Crdb$BsConsole.Aperture.$$default;
  var aperture$1 = Belt_Array.reduce(filters, aperture, (function (acc, param) {
          var n = param[0];
          return Belt_Array.reduce(Json_decode.array((function (param) {
                            return Json_decode.tuple2(Json_decode.string, Util$BsConsole.identity, param);
                          }), param[1]), acc, (function (acc$prime, fop) {
                        var __x = Curry._2(Crdb$BsConsole.Filters.add, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                                  n,
                                  Crdb$BsConsole.FilterOperation.fromTuple(fop)
                                ]), Curry._1(Crdb$BsConsole.Aperture.getFilters, acc$prime));
                        return Curry._2(Crdb$BsConsole.Aperture.setFilters, __x, acc$prime);
                      }));
        }));
  var stuff = Js_dict.entries(querySon);
  var match = Belt_Array.getBy(stuff, (function (param) {
          return param[0] === "fold";
        }));
  var fold;
  if (match !== undefined) {
    var folds = Js_dict.entries(match[1]);
    fold = Belt_Array.reduce(folds, Crdb$BsConsole.Fold.empty, (function (acc, param) {
            var n = param[0];
            return Belt_Array.reduce(Json_decode.array(Util$BsConsole.identity, param[1]), acc, (function (acc$prime, opp) {
                          return Curry._2(Crdb$BsConsole.Fold.add, Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                                          n,
                                          Crdb$BsConsole.Operation.fromJson(opp)
                                        ]), acc$prime);
                        }));
          }));
  } else {
    fold = Crdb$BsConsole.Fold.empty;
  }
  return {
          aperture: aperture$1,
          fold: fold
        };
}

function decodeSearch$15(name, search) {
  try {
    return _decodeSearch(name, search);
  }
  catch (exn){
    return {
            aperture: Crdb$BsConsole.Aperture.$$default,
            fold: Crdb$BsConsole.Fold.empty
          };
  }
}

var ProjectQueryBuilderLegacyParams = {
  _decodeSearch: _decodeSearch,
  decodeSearch: decodeSearch$15
};

function getQueryBuilderParams(aperture, havings, projectName, factor, fold, sort, normBy, highlightExporter, param) {
  var query = BtTableSessionData$BsConsole.Query.get(projectName, /* ExploreAgg */1);
  return {
          aperture: Belt_Option.getWithDefault(aperture, Curry._2(Crdb$BsConsole.Aperture.setFilters, query.filters, Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, query.time, Crdb$BsConsole.Aperture.$$default))),
          factor: Belt_Option.getWithDefault(factor, query.groupBy),
          havings: Belt_Option.getWithDefault(havings, query.havings),
          fold: Belt_Option.getWithDefault(fold, query.aggregations),
          sort: Belt_Option.getWithDefault(sort, query.sort),
          normBy: normBy,
          highlightExporter: highlightExporter
        };
}

var mustSelectFields = [
  "callstack",
  "fingerprint",
  "timestamp"
];

var defaultSelect = Belt_Array.reduce(mustSelectFields, Crdb$BsConsole.Select.empty, (function (select, field) {
        return Curry._2(Crdb$BsConsole.Select.add, field, select);
      }));

function decodeSearch$16(_projectName, search) {
  var json = Util$BsConsole.Qs.parse(search);
  var __x = Json_decode.optional((function (param) {
          return Json_decode.field("aperture", Util$BsConsole.identity, param);
        }), json);
  var aperture = Belt_Option.map(__x, (function (json) {
          return Curry._1(Crdb$BsConsole.Aperture.fromURLEncodedJson, JSON.parse(json));
        }));
  if (aperture !== undefined) {
    var select = {
      contents: withDefaultOnException(Crdb$BsConsole.Select.empty, (function (param) {
              return Json_decode.field("select", (function (json) {
                            return Curry._1(Crdb$BsConsole.Select.fromJson, JSON.parse(json));
                          }), param);
            }), json)
    };
    return {
            aperture: Caml_option.valFromOption(aperture),
            select: (Belt_Array.forEach(mustSelectFields, (function (msf) {
                      if (!Belt_List.has(select.contents, msf, (function (prim, prim$1) {
                                return prim === prim$1;
                              }))) {
                        select.contents = /* :: */[
                          msf,
                          select.contents
                        ];
                        return ;
                      }
                      
                    })), select.contents),
            sort: withDefaultOnException(Crdb$BsConsole.Sort.defaultSelect, (function (param) {
                    return Json_decode.field("sort", (function (json) {
                                  return optDefault(Crdb$BsConsole.Sort.defaultSelect, Curry._1(Crdb$BsConsole.Sort.fromJson, JSON.parse(json)));
                                }), param);
                  }), json)
          };
  }
  var stuff = Js_dict.entries(json);
  var baseQuery = UrlEncode$BsConsole.Query.decode(/* [] */0);
  var query = UrlEncode$BsConsole.Query.decodeOpt(Belt_List.fromArray(stuff));
  return {
          aperture: Curry._2(Crdb$BsConsole.Aperture.setFilters, Belt_Option.getWithDefault(query.filters, Crdb$BsConsole.Filters.empty), Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, Belt_Option.getWithDefault(query.time, Crdb$BsConsole.TimeAperture.$$default), Crdb$BsConsole.Aperture.$$default)),
          select: Belt_Option.getWithDefault(query.select, baseQuery.select),
          sort: Belt_Option.getWithDefault(query.sort, Crdb$BsConsole.Sort.defaultSelect)
        };
}

var ProjectInstancesParams = {
  mustSelectFields: mustSelectFields,
  defaultSelect: defaultSelect,
  decodeSearch: decodeSearch$16
};

function getInstancesParams(projectName, aperture, param) {
  var query = BtTableSessionData$BsConsole.Query.get(projectName, /* ExploreList */2);
  return {
          aperture: Belt_Option.getWithDefault(aperture, Curry._2(Crdb$BsConsole.Aperture.setFilters, query.filters, Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, query.time, Crdb$BsConsole.Aperture.$$default))),
          select: query.select,
          sort: query.sort
        };
}

function decodeSearch$17(search) {
  var json = Util$BsConsole.Qs.parse(search);
  var __x = Json_decode.optional((function (param) {
          return Json_decode.field("aperture", Util$BsConsole.identity, param);
        }), json);
  var aperture = Belt_Option.map(__x, (function (json) {
          return Curry._1(Crdb$BsConsole.Aperture.fromURLEncodedJson, JSON.parse(json));
        }));
  if (aperture !== undefined) {
    return {
            aperture: Caml_option.valFromOption(aperture),
            sort: Json_decode.withDefault(Crdb$BsConsole.Sort.defaultSelect, (function (param) {
                    return Json_decode.field("sort", (function (json) {
                                  return optDefault(Crdb$BsConsole.Sort.defaultSelect, Curry._1(Crdb$BsConsole.Sort.fromJson, JSON.parse(json)));
                                }), param);
                  }), json),
            s: Json_decode.optional((function (param) {
                    return Json_decode.field("s", (function (json) {
                                  var __x = DebuggerStateSerializer$BsConsole.DebuggerState.fromJson(JSON.parse(json));
                                  return Belt_Option.getWithDefault(__x, {
                                              currentID: undefined,
                                              states: undefined
                                            });
                                }), param);
                  }), json)
          };
  }
  var stuff = Js_dict.entries(json);
  var query = UrlEncode$BsConsole.Query.decodeOpt(Belt_List.fromArray(stuff));
  return {
          aperture: Curry._2(Crdb$BsConsole.Aperture.setFilters, Belt_Option.getWithDefault(query.filters, Crdb$BsConsole.Filters.empty), Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, Belt_Option.getWithDefault(query.time, Crdb$BsConsole.TimeAperture.$$default), Crdb$BsConsole.Aperture.$$default)),
          sort: Belt_Option.getWithDefault(query.sort, Crdb$BsConsole.Sort.defaultSelect),
          s: Json_decode.optional((function (param) {
                  return Json_decode.field("s", (function (json) {
                                var __x = DebuggerStateSerializer$BsConsole.DebuggerState.fromJson(JSON.parse(json));
                                return Belt_Option.getWithDefault(__x, {
                                            currentID: undefined,
                                            states: undefined
                                          });
                              }), param);
                }), json)
        };
}

var ProjectDebuggerParams = {
  decodeSearch: decodeSearch$17
};

function decodeSearch$18(search) {
  var json = Util$BsConsole.Qs.parse(search);
  var stateStr = Json_decode.optional((function (param) {
          return Json_decode.field("debug", Json_decode.string, param);
        }), json);
  var stuff = Js_dict.entries(json);
  var query = UrlEncode$BsConsole.Query.decodeOpt(Belt_List.fromArray(stuff));
  var state = Belt_Option.flatMap(stateStr, (function (state) {
          var exp = UrlEncode$BsConsole.Exp.fromString(state);
          if (exp.tag) {
            return ;
          }
          var match = exp[0];
          if (!match) {
            return ;
          }
          var errorId = match[0];
          if (errorId.tag !== /* Str */1) {
            return ;
          }
          var match$1 = match[1];
          if (!match$1) {
            return ;
          }
          var thread = match$1[0];
          if (thread.tag !== /* Int */2) {
            return ;
          }
          var match$2 = match$1[1];
          var thread$1 = thread[0];
          var errorId$1 = errorId[0];
          if (!match$2) {
            return {
                    errorId: errorId$1,
                    threadIndex: Caml_int64.to_int32(thread$1),
                    frameIndex: undefined,
                    variableIndex: undefined
                  };
          }
          var frame = match$2[0];
          if (frame.tag !== /* Int */2) {
            return ;
          }
          var match$3 = match$2[1];
          var frame$1 = frame[0];
          if (!match$3) {
            return {
                    errorId: errorId$1,
                    threadIndex: Caml_int64.to_int32(thread$1),
                    frameIndex: Caml_int64.to_int32(frame$1),
                    variableIndex: undefined
                  };
          }
          var variable = match$3[0];
          if (variable.tag === /* Int */2 && !match$3[1]) {
            return {
                    errorId: errorId$1,
                    threadIndex: Caml_int64.to_int32(thread$1),
                    frameIndex: Caml_int64.to_int32(frame$1),
                    variableIndex: Caml_int64.to_int32(variable[0])
                  };
          }
          
        }));
  var aperture = Curry._2(Crdb$BsConsole.Aperture.setFilters, Belt_Option.getWithDefault(query.filters, Crdb$BsConsole.Filters.empty), Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, Belt_Option.getWithDefault(query.time, Crdb$BsConsole.TimeAperture.$$default), Crdb$BsConsole.Aperture.$$default));
  return {
          aperture: aperture,
          state: state
        };
}

var ProjectDebugger2Params = {
  decodeSearch: decodeSearch$18
};

function getDebuggerParams(s, aperture, param, param$1) {
  var query = UrlEncode$BsConsole.Query.decode(/* [] */0);
  return {
          aperture: Belt_Option.getWithDefault(aperture, Curry._2(Crdb$BsConsole.Aperture.setFilters, query.filters, Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, query.time, Crdb$BsConsole.Aperture.$$default))),
          sort: Crdb$BsConsole.Sort.defaultSelect,
          s: s
        };
}

function decodeSearch$19(_projectName, search) {
  var json = Util$BsConsole.Qs.parse(search);
  var __x = Json_decode.optional((function (param) {
          return Json_decode.field("aperture", Util$BsConsole.identity, param);
        }), json);
  var aperture = Belt_Option.map(__x, (function (json) {
          return Curry._1(Crdb$BsConsole.Aperture.fromURLEncodedJson, JSON.parse(json));
        }));
  if (aperture !== undefined) {
    return {
            aperture: Caml_option.valFromOption(aperture),
            weighted: Json_decode.map(Pervasives.bool_of_string, (function (param) {
                    return withDefaultOnException("true", (function (param) {
                                  return Json_decode.field("weighted", Json_decode.string, param);
                                }), param);
                  }), json),
            reversed: Json_decode.map(Pervasives.bool_of_string, (function (param) {
                    return withDefaultOnException("false", (function (param) {
                                  return Json_decode.field("reversed", Json_decode.string, param);
                                }), param);
                  }), json)
          };
  }
  var stuff = Js_dict.entries(json);
  var query = UrlEncode$BsConsole.Query.decodeOpt(Belt_List.fromArray(stuff));
  return {
          aperture: Curry._2(Crdb$BsConsole.Aperture.setFilters, Belt_Option.getWithDefault(query.filters, Crdb$BsConsole.Filters.empty), Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, Belt_Option.getWithDefault(query.time, Crdb$BsConsole.TimeAperture.$$default), Crdb$BsConsole.Aperture.$$default)),
          weighted: Json_decode.map(Pervasives.bool_of_string, (function (param) {
                  return withDefaultOnException("true", (function (param) {
                                return Json_decode.field("weighted", Json_decode.string, param);
                              }), param);
                }), json),
          reversed: Json_decode.map(Pervasives.bool_of_string, (function (param) {
                  return withDefaultOnException("false", (function (param) {
                                return Json_decode.field("reversed", Json_decode.string, param);
                              }), param);
                }), json)
        };
}

var ProjectFlamegraphParams = {
  decodeSearch: decodeSearch$19
};

function fromJson(json) {
  return {
          redirect: Json_decode.field("redirect", Json_decode.string, json)
        };
}

var ShareParams = {
  fromJson: fromJson
};

function decodeSearch$20(search) {
  var json = Util$BsConsole.Qs.parse(search);
  return {
          error: Json_decode.optional((function (param) {
                  return Json_decode.field("error", Json_decode.string, param);
                }), json)
        };
}

var SettingsWfConnectionManagement = {
  decodeSearch: decodeSearch$20
};

function fromJson$1(json) {
  return {
          nonce: Json_decode.field("nonce", Json_decode.string, json)
        };
}

var Workflow = {
  fromJson: fromJson$1
};

function getFlamegraphParams(weightedOpt, reversedOpt, param, aperture, param$1) {
  var weighted = weightedOpt !== undefined ? weightedOpt : true;
  var reversed = reversedOpt !== undefined ? reversedOpt : false;
  var query = UrlEncode$BsConsole.Query.decode(/* [] */0);
  return {
          aperture: Belt_Option.getWithDefault(aperture, Curry._2(Crdb$BsConsole.Aperture.setFilters, query.filters, Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, query.time, Crdb$BsConsole.Aperture.$$default))),
          weighted: weighted,
          reversed: reversed
        };
}

function toString(r) {
  if (typeof r === "number") {
    switch (r) {
      case /* Signup */0 :
          return "Signup";
      case /* Recover */1 :
          return "Recover";
      case /* CreateFirstProject */2 :
          return "CreateFirstProject";
      case /* Shell */3 :
          return "Shell";
      case /* Settings */4 :
          return "Settings";
      case /* SettingsMe */5 :
          return "SettingsMe";
      case /* SettingsUsers */6 :
          return "SettingsUsers";
      case /* SettingsTeams */7 :
          return "SettingsTeams";
      case /* SettingsProjects */8 :
          return "SettingsProjects";
      case /* SettingsProjectsNew */9 :
          return "SettingsProjectsNew";
      case /* SettingsProjectsFork */10 :
          return "SettingsProjectsFork";
      case /* SettingsInvites */11 :
          return "SettingsInvites";
      case /* SettingsInvitesNew */12 :
          return "SettingsInvitesNew";
      case /* SettingsNetworking */13 :
          return "SettingsNetworking";
      case /* SettingsRetentionPolicy */14 :
          return "SettingsRetentionPolicy";
      case /* SettingsNetworkingSSL */15 :
          return "SettingsNetworkingSSL";
      case /* SettingsNetworkingListeners */16 :
          return "SettingsNetworkingListeners";
      case /* SettingsWhitelist */17 :
          return "SettingsWhitelist";
      case /* SettingsBilling */18 :
          return "SettingsBilling";
      case /* SettingsBillingPlans */19 :
          return "SettingsBillingPlans";
      case /* SettingsSubscriptions */20 :
          return "SettingsSubscriptions";
      case /* SettingsSamlConfig */21 :
          return "SettingsSamlConfig";
      case /* WfPlayground */22 :
          return "WfPlayground";
      case /* UiPlayground */23 :
          return "UiPlayground";
      
    }
  } else {
    switch (r.tag | 0) {
      case /* Reset */0 :
          return "Reset";
      case /* CreateAccount */1 :
          return "CreateAccount";
      case /* UDashEntry */2 :
          return "U-Login";
      case /* Login */3 :
          return "Login";
      case /* Share */4 :
          return "Share";
      case /* Activate */5 :
          return "Activate";
      case /* SettingsWfConnectionManagement */6 :
          return "SettingsWfConnectionManagement";
      case /* Project */7 :
          return "Project";
      case /* ProjectOverview */8 :
          return "ProjectOverview";
      case /* ProjectCompareReleases */9 :
          return "ProjectCompareReleases";
      case /* ProjectUpload */10 :
          return "ProjectUpload";
      case /* ProjectTriage */11 :
          return "ProjectTriage";
      case /* ProjectInboxLegacy1 */12 :
          return "ProjectInboxLegacy1";
      case /* ProjectQueryBuilder */13 :
          return "ProjectQueryBuilder";
      case /* ProjectQueryBuilderLegacy1 */14 :
          return "ProjectQueryBuilderLegacy1";
      case /* ProjectInstances */15 :
          return "ProjectInstances";
      case /* ProjectInstancesLegacy1 */16 :
          return "ProjectInstancesLegacy1";
      case /* ProjectDebugger */17 :
          return "ProjectDebugger";
      case /* ProjectDebuggerLegacy1 */18 :
          return "ProjectDebuggerLegacy1";
      case /* ProjectDebugger2 */19 :
          return "ProjectDebugger2";
      case /* ProjectFlamegraph */20 :
          return "ProjectFlamegraph";
      case /* ProjectSimilarity */21 :
          return "ProjectSimilarity";
      case /* ProjectSettings */22 :
          return "ProjectSettings";
      case /* ProjectSettingsPlayground */23 :
          return "ProjectSettingsPlayground";
      case /* ProjectSettingsMetricsExporter */24 :
          return "ProjectSettingsMetricsExporter";
      case /* ProjectSettingsScrubbers */25 :
          return "ProjectSettingsScrubbers";
      case /* ProjectSettingsServerSideActions */26 :
          return "ProjectSettingsServerSideActions";
      case /* ProjectSettingsAlerts */27 :
          return "ProjectSettingsAlerts";
      case /* ProjectSettingsIntegratedDocs */28 :
          return "ProjectSettingsIntegratedDocs";
      case /* ProjectSettingsSubmission */29 :
          return "ProjectSettingsSubmission";
      case /* ProjectSettingsStorage */30 :
          return "ProjectSettingsStorage";
      case /* ProjectSettingsSubmissionTokens */31 :
          return "ProjectSettingsSubmissionTokens";
      case /* ProjectSettingStorageSampling */32 :
          return "ProjectSettingStorageSampling";
      case /* ProjectSettingsSubmissionDataSources */33 :
          return "ProjectSettingsSubmissionDataSources";
      case /* ProjectSettingsAttributes */34 :
          return "ProjectSettingsAttributes";
      case /* ProjectSettingsIntegrations */35 :
          return "ProjectSettingsIntegrations";
      case /* ProjectSettingsSymbolsUploadedArchives */36 :
          return "ProjectSettingsSymbolsUploadedArchives";
      case /* ProjectSettingsSymbolsUpload */37 :
          return "ProjectSettingsSymbolsUpload";
      case /* ProjectSettingsSymbolServers */38 :
          return "ProjectSettingsSymbolServers";
      case /* ProjectSettingsSymbols */39 :
          return "ProjectSettingsSymbols";
      case /* ProjectSettingsSymbolAccessTokens */40 :
          return "ProjectSettingsSymbolAccessTokens";
      case /* ProjectSettingsMissingSymbols */41 :
          return "ProjectSettingsMissingSymbols";
      case /* ProjectSettingsSymbolSearch */42 :
          return "ProjectSettingsSymbolSearch";
      case /* ProjectSettingsScheduledReports */43 :
          return "ProjectSettingsScheduledReports";
      case /* ProjectSettingsAccessControl */44 :
          return "ProjectSettingsAccessControl";
      case /* ProjectSettingsReprocessing */45 :
          return "ProjectSettingsReprocessing";
      case /* ProjectSettingsRetentionPolicy */46 :
          return "ProjectSettingsRetentionPolicy";
      case /* ProjectSettingsSourceCode */47 :
          return "ProjectSettingsSourceCode";
      case /* ProjectSettingsApiTokens */48 :
          return "ProjectSettingsApiTokens";
      case /* ProjectSettingsDedupeConfig */49 :
          return "ProjectSettingsDedupeConfig";
      case /* ProjectSettingsStabilityMonitoring */50 :
          return "ProjectSettingsStabilityMonitoring";
      case /* RedirectLegacyDebugger */51 :
          return "RedirectLegacyDebugger";
      case /* RedirectLegacyDetailView */52 :
          return "RedirectLegacyDetailView";
      case /* RedirectLegacyQueryBuilder */53 :
          return "RedirectLegacyQueryBuilder";
      case /* Workflow */54 :
          return "Workflow";
      case /* SamlError */55 :
          return "SamlError";
      case /* NotFound */56 :
          return "NotFound";
      case /* Error */57 :
          return "Error";
      case /* GenerateDemoProject */58 :
          return "GenerateDemoProject";
      
    }
  }
}

function pathForRoute(route) {
  if (typeof route === "number") {
    switch (route) {
      case /* Signup */0 :
          return "/signup";
      case /* Recover */1 :
          return "/recover";
      case /* CreateFirstProject */2 :
          return "/create-first-project";
      case /* Shell */3 :
          return "/";
      case /* Settings */4 :
          return "/settings";
      case /* SettingsMe */5 :
          return "/settings/me";
      case /* SettingsUsers */6 :
          return "/settings/users";
      case /* SettingsTeams */7 :
          return "/settings/teams";
      case /* SettingsProjects */8 :
          return "/settings/projects";
      case /* SettingsProjectsNew */9 :
          return "/settings/projects/new";
      case /* SettingsProjectsFork */10 :
          return "/settings/projects/fork";
      case /* SettingsInvites */11 :
          return "/settings/invites";
      case /* SettingsInvitesNew */12 :
          return "/settings/invites/new";
      case /* SettingsNetworking */13 :
          return "/settings/networking";
      case /* SettingsRetentionPolicy */14 :
          return "/settings/retention-policy";
      case /* SettingsNetworkingSSL */15 :
          return "/settings/networking/ssl";
      case /* SettingsNetworkingListeners */16 :
          return "/settings/networking/listeners";
      case /* SettingsWhitelist */17 :
          return "/settings/allowlist";
      case /* SettingsBilling */18 :
          return "/settings/billing";
      case /* SettingsBillingPlans */19 :
          return "/settings/billing/plans";
      case /* SettingsSubscriptions */20 :
          return "/settings/subscriptions";
      case /* SettingsSamlConfig */21 :
          return "/settings/saml";
      case /* WfPlayground */22 :
          return "/wf-playground";
      case /* UiPlayground */23 :
          return "/ui-playground";
      
    }
  } else {
    switch (route.tag | 0) {
      case /* Reset */0 :
          var qs = route[0];
          return "/reset?token=" + (qs.token + ("&username=" + (qs.username + ("&universe_id=" + qs.universe_id))));
      case /* CreateAccount */1 :
          var qs$1 = route[0];
          return "/create?username=" + (qs$1.username + ("&password=" + qs$1.password));
      case /* UDashEntry */2 :
          var match = route[0];
          return "/u-login?project=" + (match.project + ("&token=" + (match.token + ("&email=" + (match.email + Belt_Option.getWithDefault(Belt_Option.map(match.referrer, (function (r) {
                                      return "&referrer=" + r;
                                    })), ""))))));
      case /* Login */3 :
          var str = route[0].referrer;
          if (str !== undefined) {
            if (str.includes("login?referrer=")) {
              return "/" + str;
            } else {
              return "/login?referrer=" + encodeURI(btoa(str));
            }
          } else {
            return "/login";
          }
      case /* Share */4 :
          return "/share?redirect=" + route[0].redirect;
      case /* Activate */5 :
          var qs$2 = route[0];
          return "/activate?token=" + (qs$2.token + ("&username=" + (qs$2.username + ("&set=" + Pervasives.string_of_bool(qs$2.set)))));
      case /* SettingsWfConnectionManagement */6 :
          var err = route[0].error;
          var errorQs = err !== undefined ? "?error=" + err : "";
          return "/settings/workflow-connections" + errorQs;
      case /* Project */7 :
          return "/p/" + route[0];
      case /* ProjectOverview */8 :
          var qs$3 = route[1];
          var init = UrlEncode$BsConsole.Query.decode(/* [] */0);
          var urlEncodedQuery_time = Curry._1(Crdb$BsConsole.Aperture.getTimeAperture, qs$3.aperture);
          var urlEncodedQuery_filters = Curry._1(Crdb$BsConsole.Aperture.getFilters, qs$3.aperture);
          var urlEncodedQuery_havings = init.havings;
          var urlEncodedQuery_select = init.select;
          var urlEncodedQuery_groupBy = init.groupBy;
          var urlEncodedQuery_sort = init.sort;
          var urlEncodedQuery_aggregations = init.aggregations;
          var urlEncodedQuery_stats = init.stats;
          var urlEncodedQuery_normBy = init.normBy;
          var urlEncodedQuery_highlightExporter = init.highlightExporter;
          var urlEncodedQuery_similarity = init.similarity;
          var urlEncodedQuery = {
            time: urlEncodedQuery_time,
            filters: urlEncodedQuery_filters,
            havings: urlEncodedQuery_havings,
            select: urlEncodedQuery_select,
            groupBy: urlEncodedQuery_groupBy,
            sort: urlEncodedQuery_sort,
            aggregations: urlEncodedQuery_aggregations,
            stats: urlEncodedQuery_stats,
            normBy: urlEncodedQuery_normBy,
            highlightExporter: urlEncodedQuery_highlightExporter,
            similarity: urlEncodedQuery_similarity
          };
          return "/p/" + (route[0] + ("/overview" + UrlEncode$BsConsole.Query.toQueryString(/* :: */[
                          "time",
                          /* :: */[
                            "filters",
                            /* [] */0
                          ]
                        ], urlEncodedQuery)));
      case /* ProjectCompareReleases */9 :
          var qs$4 = route[2];
          var releaseAttribute = route[1];
          var init$1 = UrlEncode$BsConsole.Query.decode(/* [] */0);
          var urlEncodedQuery$1 = UrlEncode$BsConsole.Query.toQueryString(/* :: */[
                "time",
                /* :: */[
                  "filters",
                  /* [] */0
                ]
              ], {
                time: Curry._1(Crdb$BsConsole.Aperture.getTimeAperture, qs$4.aperture),
                filters: Curry._1(Crdb$BsConsole.Aperture.getFilters, qs$4.aperture),
                havings: init$1.havings,
                select: init$1.select,
                groupBy: init$1.groupBy,
                sort: init$1.sort,
                aggregations: init$1.aggregations,
                stats: init$1.stats,
                normBy: init$1.normBy,
                highlightExporter: init$1.highlightExporter,
                similarity: init$1.similarity
              });
          var groups = qs$4.groups;
          var groupQSParams;
          if (groups !== undefined) {
            var urlEncodedGroups = Belt_Array.map(groups, (function (group) {
                    return encodeURIComponent(group);
                  }));
            groupQSParams = "groups=" + JSON.stringify(urlEncodedGroups);
          } else {
            groupQSParams = "";
          }
          var queryString;
          var exit = 0;
          if (urlEncodedQuery$1 === "" && groupQSParams === "") {
            queryString = "";
          } else {
            exit = 1;
          }
          if (exit === 1) {
            queryString = groupQSParams === "" ? urlEncodedQuery$1 : (
                urlEncodedQuery$1 === "" ? "?" + groupQSParams : urlEncodedQuery$1 + ("&" + groupQSParams)
              );
          }
          return "/p/" + (route[0] + ("/compare" + ((
                        releaseAttribute !== undefined ? "/" + releaseAttribute : ""
                      ) + queryString)));
      case /* ProjectUpload */10 :
          return "/p/" + (route[0] + "/upload");
      case /* ProjectTriage */11 :
          var qs$5 = route[1];
          var init$2 = UrlEncode$BsConsole.Query.decode(/* [] */0);
          var urlEncodedQuery_time$1 = Curry._1(Crdb$BsConsole.Aperture.getTimeAperture, qs$5.aperture);
          var urlEncodedQuery_filters$1 = Curry._1(Crdb$BsConsole.Aperture.getFilters, qs$5.aperture);
          var urlEncodedQuery_havings$1 = qs$5.havings;
          var urlEncodedQuery_select$1 = init$2.select;
          var urlEncodedQuery_groupBy$1 = init$2.groupBy;
          var urlEncodedQuery_sort$1 = qs$5.sort;
          var urlEncodedQuery_aggregations$1 = qs$5.fold;
          var urlEncodedQuery_stats$1 = qs$5.stats;
          var urlEncodedQuery_normBy$1 = qs$5.normBy;
          var urlEncodedQuery_highlightExporter$1 = init$2.highlightExporter;
          var urlEncodedQuery_similarity$1 = qs$5.similarity;
          var urlEncodedQuery$2 = {
            time: urlEncodedQuery_time$1,
            filters: urlEncodedQuery_filters$1,
            havings: urlEncodedQuery_havings$1,
            select: urlEncodedQuery_select$1,
            groupBy: urlEncodedQuery_groupBy$1,
            sort: urlEncodedQuery_sort$1,
            aggregations: urlEncodedQuery_aggregations$1,
            stats: urlEncodedQuery_stats$1,
            normBy: urlEncodedQuery_normBy$1,
            highlightExporter: urlEncodedQuery_highlightExporter$1,
            similarity: urlEncodedQuery_similarity$1
          };
          return "/p/" + (route[0] + ("/triage" + UrlEncode$BsConsole.Query.toQueryString(/* :: */[
                          "time",
                          /* :: */[
                            "filters",
                            /* :: */[
                              "havings",
                              /* :: */[
                                "sort",
                                /* :: */[
                                  "stats",
                                  /* :: */[
                                    "aggregations",
                                    /* :: */[
                                      "normBy",
                                      /* :: */[
                                        "similarity",
                                        /* [] */0
                                      ]
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ], urlEncodedQuery$2)));
      case /* ProjectInboxLegacy1 */12 :
          var qs$6 = route[1];
          var init$3 = UrlEncode$BsConsole.Query.decode(/* [] */0);
          var urlEncodedQuery_time$2 = Curry._1(Crdb$BsConsole.Aperture.getTimeAperture, qs$6.aperture);
          var urlEncodedQuery_filters$2 = Curry._1(Crdb$BsConsole.Aperture.getFilters, qs$6.aperture);
          var urlEncodedQuery_havings$2 = qs$6.havings;
          var urlEncodedQuery_select$2 = init$3.select;
          var urlEncodedQuery_groupBy$2 = init$3.groupBy;
          var urlEncodedQuery_sort$2 = qs$6.sort;
          var urlEncodedQuery_aggregations$2 = qs$6.fold;
          var urlEncodedQuery_stats$2 = qs$6.stats;
          var urlEncodedQuery_normBy$2 = qs$6.normBy;
          var urlEncodedQuery_highlightExporter$2 = init$3.highlightExporter;
          var urlEncodedQuery_similarity$2 = init$3.similarity;
          var urlEncodedQuery$3 = {
            time: urlEncodedQuery_time$2,
            filters: urlEncodedQuery_filters$2,
            havings: urlEncodedQuery_havings$2,
            select: urlEncodedQuery_select$2,
            groupBy: urlEncodedQuery_groupBy$2,
            sort: urlEncodedQuery_sort$2,
            aggregations: urlEncodedQuery_aggregations$2,
            stats: urlEncodedQuery_stats$2,
            normBy: urlEncodedQuery_normBy$2,
            highlightExporter: urlEncodedQuery_highlightExporter$2,
            similarity: urlEncodedQuery_similarity$2
          };
          return "/p/" + (route[0] + ("/triage" + UrlEncode$BsConsole.Query.toQueryString(/* :: */[
                          "time",
                          /* :: */[
                            "filters",
                            /* :: */[
                              "havings",
                              /* :: */[
                                "sort",
                                /* :: */[
                                  "stats",
                                  /* :: */[
                                    "aggregations",
                                    /* :: */[
                                      "normBy",
                                      /* [] */0
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ], urlEncodedQuery$3)));
      case /* ProjectQueryBuilder */13 :
          var qs$7 = route[1];
          var init$4 = UrlEncode$BsConsole.Query.decode(/* [] */0);
          var urlEncodedQuery_time$3 = Curry._1(Crdb$BsConsole.Aperture.getTimeAperture, qs$7.aperture);
          var urlEncodedQuery_filters$3 = Curry._1(Crdb$BsConsole.Aperture.getFilters, qs$7.aperture);
          var urlEncodedQuery_havings$3 = qs$7.havings;
          var urlEncodedQuery_select$3 = init$4.select;
          var urlEncodedQuery_groupBy$3 = qs$7.factor;
          var urlEncodedQuery_sort$3 = qs$7.sort;
          var urlEncodedQuery_aggregations$3 = qs$7.fold;
          var urlEncodedQuery_stats$3 = init$4.stats;
          var urlEncodedQuery_normBy$3 = qs$7.normBy;
          var urlEncodedQuery_highlightExporter$3 = init$4.highlightExporter;
          var urlEncodedQuery_similarity$3 = init$4.similarity;
          var urlEncodedQuery$4 = {
            time: urlEncodedQuery_time$3,
            filters: urlEncodedQuery_filters$3,
            havings: urlEncodedQuery_havings$3,
            select: urlEncodedQuery_select$3,
            groupBy: urlEncodedQuery_groupBy$3,
            sort: urlEncodedQuery_sort$3,
            aggregations: urlEncodedQuery_aggregations$3,
            stats: urlEncodedQuery_stats$3,
            normBy: urlEncodedQuery_normBy$3,
            highlightExporter: urlEncodedQuery_highlightExporter$3,
            similarity: urlEncodedQuery_similarity$3
          };
          var explore = "/explore";
          var urlTemp = "/p/" + (route[0] + (explore + UrlEncode$BsConsole.Query.toQueryString(/* :: */[
                    "time",
                    /* :: */[
                      "filters",
                      /* :: */[
                        "havings",
                        /* :: */[
                          "sort",
                          /* :: */[
                            "aggregations",
                            /* :: */[
                              "groupBy",
                              /* :: */[
                                "normBy",
                                /* [] */0
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ], urlEncodedQuery$4)));
          var urlTemp2 = urlTemp + (
            urlTemp.endsWith(explore) ? "?" : "&"
          );
          var match$1 = qs$7.highlightExporter;
          return urlTemp2 + (
                  match$1 !== undefined && match$1 ? "highlightExporter=true" : ""
                );
      case /* ProjectQueryBuilderLegacy1 */14 :
          var qs$8 = route[1];
          var init$5 = UrlEncode$BsConsole.Query.decode(/* [] */0);
          var urlEncodedQuery_time$4 = Curry._1(Crdb$BsConsole.Aperture.getTimeAperture, qs$8.aperture);
          var urlEncodedQuery_filters$4 = Curry._1(Crdb$BsConsole.Aperture.getFilters, qs$8.aperture);
          var urlEncodedQuery_havings$4 = qs$8.havings;
          var urlEncodedQuery_select$4 = init$5.select;
          var urlEncodedQuery_groupBy$4 = qs$8.factor;
          var urlEncodedQuery_sort$4 = qs$8.sort;
          var urlEncodedQuery_aggregations$4 = qs$8.fold;
          var urlEncodedQuery_stats$4 = init$5.stats;
          var urlEncodedQuery_normBy$4 = init$5.normBy;
          var urlEncodedQuery_highlightExporter$4 = init$5.highlightExporter;
          var urlEncodedQuery_similarity$4 = init$5.similarity;
          var urlEncodedQuery$5 = {
            time: urlEncodedQuery_time$4,
            filters: urlEncodedQuery_filters$4,
            havings: urlEncodedQuery_havings$4,
            select: urlEncodedQuery_select$4,
            groupBy: urlEncodedQuery_groupBy$4,
            sort: urlEncodedQuery_sort$4,
            aggregations: urlEncodedQuery_aggregations$4,
            stats: urlEncodedQuery_stats$4,
            normBy: urlEncodedQuery_normBy$4,
            highlightExporter: urlEncodedQuery_highlightExporter$4,
            similarity: urlEncodedQuery_similarity$4
          };
          return "/p/" + (route[0] + ("/query-builder" + UrlEncode$BsConsole.Query.toQueryString(/* :: */[
                          "time",
                          /* :: */[
                            "filters",
                            /* :: */[
                              "havings",
                              /* :: */[
                                "sort",
                                /* :: */[
                                  "aggregations",
                                  /* :: */[
                                    "groupBy",
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ], urlEncodedQuery$5)));
      case /* ProjectInstances */15 :
          var qs$9 = route[1];
          var init$6 = UrlEncode$BsConsole.Query.decode(/* [] */0);
          var urlEncodedQuery_time$5 = Curry._1(Crdb$BsConsole.Aperture.getTimeAperture, qs$9.aperture);
          var urlEncodedQuery_filters$5 = Curry._1(Crdb$BsConsole.Aperture.getFilters, qs$9.aperture);
          var urlEncodedQuery_havings$5 = init$6.havings;
          var urlEncodedQuery_select$5 = qs$9.select;
          var urlEncodedQuery_groupBy$5 = init$6.groupBy;
          var urlEncodedQuery_sort$5 = qs$9.sort;
          var urlEncodedQuery_aggregations$5 = init$6.aggregations;
          var urlEncodedQuery_stats$5 = init$6.stats;
          var urlEncodedQuery_normBy$5 = init$6.normBy;
          var urlEncodedQuery_highlightExporter$5 = init$6.highlightExporter;
          var urlEncodedQuery_similarity$5 = init$6.similarity;
          var urlEncodedQuery$6 = {
            time: urlEncodedQuery_time$5,
            filters: urlEncodedQuery_filters$5,
            havings: urlEncodedQuery_havings$5,
            select: urlEncodedQuery_select$5,
            groupBy: urlEncodedQuery_groupBy$5,
            sort: urlEncodedQuery_sort$5,
            aggregations: urlEncodedQuery_aggregations$5,
            stats: urlEncodedQuery_stats$5,
            normBy: urlEncodedQuery_normBy$5,
            highlightExporter: urlEncodedQuery_highlightExporter$5,
            similarity: urlEncodedQuery_similarity$5
          };
          return "/p/" + (route[0] + ("/list" + UrlEncode$BsConsole.Query.toQueryString(/* :: */[
                          "time",
                          /* :: */[
                            "filters",
                            /* :: */[
                              "havings",
                              /* :: */[
                                "sort",
                                /* :: */[
                                  "select",
                                  /* [] */0
                                ]
                              ]
                            ]
                          ]
                        ], urlEncodedQuery$6)));
      case /* ProjectInstancesLegacy1 */16 :
          var qs$10 = route[1];
          var init$7 = UrlEncode$BsConsole.Query.decode(/* [] */0);
          var urlEncodedQuery_time$6 = Curry._1(Crdb$BsConsole.Aperture.getTimeAperture, qs$10.aperture);
          var urlEncodedQuery_filters$6 = Curry._1(Crdb$BsConsole.Aperture.getFilters, qs$10.aperture);
          var urlEncodedQuery_havings$6 = init$7.havings;
          var urlEncodedQuery_select$6 = qs$10.select;
          var urlEncodedQuery_groupBy$6 = init$7.groupBy;
          var urlEncodedQuery_sort$6 = qs$10.sort;
          var urlEncodedQuery_aggregations$6 = init$7.aggregations;
          var urlEncodedQuery_stats$6 = init$7.stats;
          var urlEncodedQuery_normBy$6 = init$7.normBy;
          var urlEncodedQuery_highlightExporter$6 = init$7.highlightExporter;
          var urlEncodedQuery_similarity$6 = init$7.similarity;
          var urlEncodedQuery$7 = {
            time: urlEncodedQuery_time$6,
            filters: urlEncodedQuery_filters$6,
            havings: urlEncodedQuery_havings$6,
            select: urlEncodedQuery_select$6,
            groupBy: urlEncodedQuery_groupBy$6,
            sort: urlEncodedQuery_sort$6,
            aggregations: urlEncodedQuery_aggregations$6,
            stats: urlEncodedQuery_stats$6,
            normBy: urlEncodedQuery_normBy$6,
            highlightExporter: urlEncodedQuery_highlightExporter$6,
            similarity: urlEncodedQuery_similarity$6
          };
          return "/p/" + (route[0] + ("/instances" + UrlEncode$BsConsole.Query.toQueryString(/* :: */[
                          "time",
                          /* :: */[
                            "filters",
                            /* :: */[
                              "havings",
                              /* :: */[
                                "sort",
                                /* :: */[
                                  "select",
                                  /* [] */0
                                ]
                              ]
                            ]
                          ]
                        ], urlEncodedQuery$7)));
      case /* ProjectDebugger */17 :
          var qs$11 = route[1];
          var init$8 = UrlEncode$BsConsole.Query.decode(/* [] */0);
          var urlEncodedQuery_time$7 = Curry._1(Crdb$BsConsole.Aperture.getTimeAperture, qs$11.aperture);
          var urlEncodedQuery_filters$7 = Curry._1(Crdb$BsConsole.Aperture.getFilters, qs$11.aperture);
          var urlEncodedQuery_havings$7 = init$8.havings;
          var urlEncodedQuery_select$7 = init$8.select;
          var urlEncodedQuery_groupBy$7 = init$8.groupBy;
          var urlEncodedQuery_sort$7 = qs$11.sort;
          var urlEncodedQuery_aggregations$7 = init$8.aggregations;
          var urlEncodedQuery_stats$7 = init$8.stats;
          var urlEncodedQuery_normBy$7 = init$8.normBy;
          var urlEncodedQuery_highlightExporter$7 = init$8.highlightExporter;
          var urlEncodedQuery_similarity$7 = init$8.similarity;
          var urlEncodedQuery$8 = {
            time: urlEncodedQuery_time$7,
            filters: urlEncodedQuery_filters$7,
            havings: urlEncodedQuery_havings$7,
            select: urlEncodedQuery_select$7,
            groupBy: urlEncodedQuery_groupBy$7,
            sort: urlEncodedQuery_sort$7,
            aggregations: urlEncodedQuery_aggregations$7,
            stats: urlEncodedQuery_stats$7,
            normBy: urlEncodedQuery_normBy$7,
            highlightExporter: urlEncodedQuery_highlightExporter$7,
            similarity: urlEncodedQuery_similarity$7
          };
          var filterQS = UrlEncode$BsConsole.Query.toQueryString(/* :: */[
                "time",
                /* :: */[
                  "filters",
                  /* :: */[
                    "havings",
                    /* [] */0
                  ]
                ]
              ], urlEncodedQuery$8);
          var s = qs$11.s;
          return "/p/" + (route[0] + ("/debug" + (filterQS + (
                        s !== undefined ? (
                            filterQS === "" ? "?" : "&"
                          ) + ("s=" + JSON.stringify(DebuggerStateSerializer$BsConsole.DebuggerState.toJson(s))) : ""
                      ))));
      case /* ProjectDebuggerLegacy1 */18 :
          var qs$12 = route[1];
          var init$9 = UrlEncode$BsConsole.Query.decode(/* [] */0);
          var urlEncodedQuery_time$8 = Curry._1(Crdb$BsConsole.Aperture.getTimeAperture, qs$12.aperture);
          var urlEncodedQuery_filters$8 = Curry._1(Crdb$BsConsole.Aperture.getFilters, qs$12.aperture);
          var urlEncodedQuery_havings$8 = init$9.havings;
          var urlEncodedQuery_select$8 = init$9.select;
          var urlEncodedQuery_groupBy$8 = init$9.groupBy;
          var urlEncodedQuery_sort$8 = qs$12.sort;
          var urlEncodedQuery_aggregations$8 = init$9.aggregations;
          var urlEncodedQuery_stats$8 = init$9.stats;
          var urlEncodedQuery_normBy$8 = init$9.normBy;
          var urlEncodedQuery_highlightExporter$8 = init$9.highlightExporter;
          var urlEncodedQuery_similarity$8 = init$9.similarity;
          var urlEncodedQuery$9 = {
            time: urlEncodedQuery_time$8,
            filters: urlEncodedQuery_filters$8,
            havings: urlEncodedQuery_havings$8,
            select: urlEncodedQuery_select$8,
            groupBy: urlEncodedQuery_groupBy$8,
            sort: urlEncodedQuery_sort$8,
            aggregations: urlEncodedQuery_aggregations$8,
            stats: urlEncodedQuery_stats$8,
            normBy: urlEncodedQuery_normBy$8,
            highlightExporter: urlEncodedQuery_highlightExporter$8,
            similarity: urlEncodedQuery_similarity$8
          };
          return "/p/" + (route[0] + ("/debugger" + UrlEncode$BsConsole.Query.toQueryString(/* :: */[
                          "time",
                          /* :: */[
                            "filters",
                            /* :: */[
                              "havings",
                              /* [] */0
                            ]
                          ]
                        ], urlEncodedQuery$9)));
      case /* ProjectDebugger2 */19 :
          var qs$13 = route[1];
          var init$10 = UrlEncode$BsConsole.Query.decode(/* [] */0);
          var urlEncodedQuery_time$9 = Curry._1(Crdb$BsConsole.Aperture.getTimeAperture, qs$13.aperture);
          var urlEncodedQuery_filters$9 = Curry._1(Crdb$BsConsole.Aperture.getFilters, qs$13.aperture);
          var urlEncodedQuery_havings$9 = init$10.havings;
          var urlEncodedQuery_select$9 = init$10.select;
          var urlEncodedQuery_groupBy$9 = init$10.groupBy;
          var urlEncodedQuery_sort$9 = init$10.sort;
          var urlEncodedQuery_aggregations$9 = init$10.aggregations;
          var urlEncodedQuery_stats$9 = init$10.stats;
          var urlEncodedQuery_normBy$9 = init$10.normBy;
          var urlEncodedQuery_highlightExporter$9 = init$10.highlightExporter;
          var urlEncodedQuery_similarity$9 = init$10.similarity;
          var urlEncodedQuery$10 = {
            time: urlEncodedQuery_time$9,
            filters: urlEncodedQuery_filters$9,
            havings: urlEncodedQuery_havings$9,
            select: urlEncodedQuery_select$9,
            groupBy: urlEncodedQuery_groupBy$9,
            sort: urlEncodedQuery_sort$9,
            aggregations: urlEncodedQuery_aggregations$9,
            stats: urlEncodedQuery_stats$9,
            normBy: urlEncodedQuery_normBy$9,
            highlightExporter: urlEncodedQuery_highlightExporter$9,
            similarity: urlEncodedQuery_similarity$9
          };
          var queryString$1 = UrlEncode$BsConsole.Query.toQueryString(/* :: */[
                "time",
                /* :: */[
                  "filters",
                  /* :: */[
                    "havings",
                    /* [] */0
                  ]
                ]
              ], urlEncodedQuery$10);
          var match$2 = qs$13.state;
          var tmp;
          if (match$2 !== undefined) {
            var variableIndex = match$2.variableIndex;
            var frameIndex = match$2.frameIndex;
            var stateStr = UrlEncode$BsConsole.Exp.toString(/* List */Block.__(0, [Belt_List.concatMany([
                          /* :: */[
                            /* Str */Block.__(1, [match$2.errorId]),
                            /* :: */[
                              /* Int */Block.__(2, [Caml_int64.of_int32(match$2.threadIndex)]),
                              /* [] */0
                            ]
                          ],
                          frameIndex !== undefined ? /* :: */[
                              /* Int */Block.__(2, [Caml_int64.of_int32(frameIndex)]),
                              /* [] */0
                            ] : /* [] */0,
                          variableIndex !== undefined ? /* :: */[
                              /* Int */Block.__(2, [Caml_int64.of_int32(variableIndex)]),
                              /* [] */0
                            ] : /* [] */0
                        ])]));
            tmp = (
              queryString$1.length === 0 ? "?" : "&"
            ) + ("debug=" + stateStr);
          } else {
            tmp = "";
          }
          return "/p/" + (route[0] + ("/debug" + (queryString$1 + tmp)));
      case /* ProjectFlamegraph */20 :
          var qs$14 = route[1];
          var init$11 = UrlEncode$BsConsole.Query.decode(/* [] */0);
          var urlEncodedQuery_time$10 = Curry._1(Crdb$BsConsole.Aperture.getTimeAperture, qs$14.aperture);
          var urlEncodedQuery_filters$10 = Curry._1(Crdb$BsConsole.Aperture.getFilters, qs$14.aperture);
          var urlEncodedQuery_havings$10 = init$11.havings;
          var urlEncodedQuery_select$10 = init$11.select;
          var urlEncodedQuery_groupBy$10 = init$11.groupBy;
          var urlEncodedQuery_sort$10 = init$11.sort;
          var urlEncodedQuery_aggregations$10 = init$11.aggregations;
          var urlEncodedQuery_stats$10 = init$11.stats;
          var urlEncodedQuery_normBy$10 = init$11.normBy;
          var urlEncodedQuery_highlightExporter$10 = init$11.highlightExporter;
          var urlEncodedQuery_similarity$10 = init$11.similarity;
          var urlEncodedQuery$11 = {
            time: urlEncodedQuery_time$10,
            filters: urlEncodedQuery_filters$10,
            havings: urlEncodedQuery_havings$10,
            select: urlEncodedQuery_select$10,
            groupBy: urlEncodedQuery_groupBy$10,
            sort: urlEncodedQuery_sort$10,
            aggregations: urlEncodedQuery_aggregations$10,
            stats: urlEncodedQuery_stats$10,
            normBy: urlEncodedQuery_normBy$10,
            highlightExporter: urlEncodedQuery_highlightExporter$10,
            similarity: urlEncodedQuery_similarity$10
          };
          var filterQS$1 = UrlEncode$BsConsole.Query.toQueryString(/* :: */[
                "time",
                /* :: */[
                  "filters",
                  /* :: */[
                    "havings",
                    /* [] */0
                  ]
                ]
              ], urlEncodedQuery$11);
          return "/p/" + (route[0] + ((
                      filterQS$1 === "" ? "/flamegraph?" : "/flamegraph"
                    ) + (filterQS$1 + ((
                          filterQS$1 === "" ? "weighted=" : "&weighted="
                        ) + (Pervasives.string_of_bool(qs$14.weighted) + ("&reversed=" + Pervasives.string_of_bool(qs$14.reversed)))))));
      case /* ProjectSimilarity */21 :
          var qs$15 = route[1];
          var init$12 = UrlEncode$BsConsole.Query.decode(/* [] */0);
          var urlEncodedQuery_time$11 = Curry._1(Crdb$BsConsole.Aperture.getTimeAperture, qs$15.aperture);
          var urlEncodedQuery_filters$11 = Curry._1(Crdb$BsConsole.Aperture.getFilters, qs$15.aperture);
          var urlEncodedQuery_havings$11 = init$12.havings;
          var urlEncodedQuery_select$11 = init$12.select;
          var urlEncodedQuery_groupBy$11 = init$12.groupBy;
          var urlEncodedQuery_sort$11 = init$12.sort;
          var urlEncodedQuery_aggregations$11 = init$12.aggregations;
          var urlEncodedQuery_stats$11 = init$12.stats;
          var urlEncodedQuery_normBy$11 = init$12.normBy;
          var urlEncodedQuery_highlightExporter$11 = init$12.highlightExporter;
          var urlEncodedQuery_similarity$11 = init$12.similarity;
          var urlEncodedQuery$12 = {
            time: urlEncodedQuery_time$11,
            filters: urlEncodedQuery_filters$11,
            havings: urlEncodedQuery_havings$11,
            select: urlEncodedQuery_select$11,
            groupBy: urlEncodedQuery_groupBy$11,
            sort: urlEncodedQuery_sort$11,
            aggregations: urlEncodedQuery_aggregations$11,
            stats: urlEncodedQuery_stats$11,
            normBy: urlEncodedQuery_normBy$11,
            highlightExporter: urlEncodedQuery_highlightExporter$11,
            similarity: urlEncodedQuery_similarity$11
          };
          var filterQS$2 = UrlEncode$BsConsole.Query.toQueryString(/* :: */[
                "time",
                /* :: */[
                  "filters",
                  /* :: */[
                    "havings",
                    /* [] */0
                  ]
                ]
              ], urlEncodedQuery$12);
          return "/p/" + (route[0] + ((
                      filterQS$2 === "" ? "/similarity?" : "/similarity"
                    ) + filterQS$2));
      case /* ProjectSettings */22 :
          return "/p/" + (route[0] + "/settings");
      case /* ProjectSettingsPlayground */23 :
          return "/p/" + (String(route[0]) + "/settings/ui-playground");
      case /* ProjectSettingsMetricsExporter */24 :
          return "/p/" + (String(route[0]) + "/settings/metrics-exporter");
      case /* ProjectSettingsScrubbers */25 :
          return "/p/" + (String(route[0]) + "/settings/scrubbers");
      case /* ProjectSettingsServerSideActions */26 :
          return "/p/" + (String(route[0]) + "/settings/server-side-actions");
      case /* ProjectSettingsAlerts */27 :
          var qs$16 = route[1];
          var match$3 = qs$16.tab;
          var match$4 = qs$16.id;
          var idQueryString = match$3 !== undefined ? (
              match$4 !== undefined ? "/?type=" + (match$3 + ("&id=" + match$4)) : "/?type=" + match$3
            ) : (
              match$4 !== undefined ? "/?id=" + match$4 : ""
            );
          return "/p/" + (route[0] + ("/settings/alerts" + idQueryString));
      case /* ProjectSettingsIntegratedDocs */28 :
          var doc = route[1].doc;
          return "/p/" + (route[0] + ("/settings/integration-guides" + (
                      doc !== undefined ? "?doc=" + doc : ""
                    )));
      case /* ProjectSettingsSubmission */29 :
          return "/p/" + (route[0] + "/settings/submission");
      case /* ProjectSettingsStorage */30 :
          return "/p/" + (route[0] + "/settings/storage");
      case /* ProjectSettingsSubmissionTokens */31 :
          return "/p/" + (route[0] + "/settings/submission/tokens");
      case /* ProjectSettingStorageSampling */32 :
          return "/p/" + (route[0] + "/settings/sampling");
      case /* ProjectSettingsSubmissionDataSources */33 :
          return "/p/" + (route[0] + "/settings/submission/data-sources");
      case /* ProjectSettingsAttributes */34 :
          var attribute = route[1].attribute;
          return "/p/" + (route[0] + ("/settings/attributes" + (
                      attribute !== undefined ? "?attribute=" + attribute : ""
                    )));
      case /* ProjectSettingsIntegrations */35 :
          var qs$17 = route[2];
          var wfRoute = route[1];
          var tmp$1;
          if (qs$17 !== undefined) {
            var match$5 = qs$17.pluginId;
            var match$6 = qs$17.connectionId;
            var match$7 = qs$17.integrationId;
            var match$8 = qs$17.connectionCreated;
            var match$9 = qs$17.referrer;
            var match$10 = qs$17.error;
            if (match$10 !== undefined) {
              tmp$1 = "/" + (Belt_List.toArray(WfRouter$BsConsole.routeToPath(wfRoute)).join("/") + ("?error=" + match$10));
            } else if (match$5 !== undefined) {
              if (match$6 !== undefined) {
                var exit$1 = 0;
                if (match$8 !== undefined && match$8 === true) {
                  tmp$1 = "?pluginId=" + (match$5 + ("&connectionId=" + (String(match$6) + "&success=true")));
                } else {
                  exit$1 = 1;
                }
                if (exit$1 === 1) {
                  tmp$1 = "?pluginId=" + (match$5 + ("&connectionId=" + String(match$6)));
                }
                
              } else {
                tmp$1 = match$7 !== undefined ? (
                    match$9 !== undefined ? "?pluginId=" + (match$5 + ("&integrationId=" + (String(match$7) + ("&referrer=" + WfRouter$BsConsole.Referrer.toString(match$9))))) : "?pluginId=" + (match$5 + ("&integrationId=" + String(match$7)))
                  ) : "";
              }
            } else {
              tmp$1 = "";
            }
          } else {
            tmp$1 = "/" + Belt_List.toArray(WfRouter$BsConsole.routeToPath(wfRoute)).join("/");
          }
          return "/p/" + (route[0] + ("/settings/integrations" + tmp$1));
      case /* ProjectSettingsSymbolsUploadedArchives */36 :
          return "/p/" + (route[0] + "/settings/symbol/uploaded-archives");
      case /* ProjectSettingsSymbolsUpload */37 :
          return "/p/" + (route[0] + "/settings/symbol/upload");
      case /* ProjectSettingsSymbolServers */38 :
          return "/p/" + (route[0] + "/settings/symbol/servers");
      case /* ProjectSettingsSymbols */39 :
          return "/p/" + (route[0] + "/settings/symbol");
      case /* ProjectSettingsSymbolAccessTokens */40 :
          return "/p/" + (route[0] + "/settings/symbol/access-tokens");
      case /* ProjectSettingsMissingSymbols */41 :
          return "/p/" + (route[0] + "/settings/symbol/missing-symbols");
      case /* ProjectSettingsSymbolSearch */42 :
          return "/p/" + (route[0] + "/settings/symbol/symbol-search");
      case /* ProjectSettingsScheduledReports */43 :
          return "/p/" + (route[0] + "/settings/scheduled-reports");
      case /* ProjectSettingsAccessControl */44 :
          return "/p/" + (route[0] + "/settings/access-control");
      case /* ProjectSettingsReprocessing */45 :
          return "/p/" + (route[0] + "/settings/reprocessing");
      case /* ProjectSettingsRetentionPolicy */46 :
          return "/p/" + (route[0] + "/settings/storage/retention");
      case /* ProjectSettingsSourceCode */47 :
          return "/p/" + (route[0] + "/settings/sourcecode");
      case /* ProjectSettingsApiTokens */48 :
          return "/p/" + (route[0] + "/settings/api-tokens");
      case /* ProjectSettingsDedupeConfig */49 :
          var objectId = route[1];
          var name = route[0];
          if (objectId !== undefined) {
            return "/p/" + (name + ("/settings/deduplication?objectId=" + objectId));
          } else {
            return "/p/" + (name + "/settings/deduplication");
          }
      case /* ProjectSettingsStabilityMonitoring */50 :
          return "/p/" + (String(route[0]) + "/settings/stability-monitoring");
      case /* RedirectLegacyDebugger */51 :
          var fingerprint = route[1];
          var filters = Curry._2(Crdb$BsConsole.Filters.add, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                    "_tx",
                    /* Equal */Block.__(0, [/* `ObjectID */[
                          -589436806,
                          route[2]
                        ]])
                  ]), Crdb$BsConsole.Filters.empty);
          var __x = fingerprint !== undefined ? Curry._2(Crdb$BsConsole.Filters.add, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                      "fingerprint",
                      /* Equal */Block.__(0, [/* `String */[
                            -976970511,
                            fingerprint
                          ]])
                    ]), filters) : filters;
          return "/p/" + (route[0] + ("/debug?" + Util$BsConsole.Qs.stringify({
                          aperture: Curry._1(Crdb$BsConsole.Aperture.toURLEncodedJson, Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, Curry._1(Crdb$BsConsole.TimeAperture.create, /* Relative */Block.__(0, [
                                          /* Floating */0,
                                          /* All */6
                                        ])), Curry._2(Crdb$BsConsole.Aperture.setFilters, __x, Crdb$BsConsole.Aperture.$$default))),
                          sort: Curry._1(Crdb$BsConsole.Sort.toJson, Crdb$BsConsole.Sort.defaultSelect)
                        })));
      case /* RedirectLegacyDetailView */52 :
          var __x$1 = Curry._2(Crdb$BsConsole.Filters.add, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                    "fingerprint",
                    /* Equal */Block.__(0, [/* `String */[
                          -976970511,
                          route[1]
                        ]])
                  ]), Crdb$BsConsole.Filters.empty);
          return "/p/" + (route[0] + ("/triage?" + Util$BsConsole.Qs.stringify({
                          aperture: Curry._1(Crdb$BsConsole.Aperture.toURLEncodedJson, Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, Curry._1(Crdb$BsConsole.TimeAperture.create, /* Relative */Block.__(0, [
                                          /* Floating */0,
                                          /* All */6
                                        ])), Curry._2(Crdb$BsConsole.Aperture.setFilters, __x$1, Crdb$BsConsole.Aperture.$$default))),
                          sort: Curry._1(Crdb$BsConsole.Sort.toJson, Crdb$BsConsole.Sort.defaultAggregate),
                          fold: Curry._1(Crdb$BsConsole.Stats.toJson, Crdb$BsConsole.Stats.empty)
                        })));
      case /* RedirectLegacyQueryBuilder */53 :
          return "/";
      case /* Workflow */54 :
          return "workflows/callback?nonce=" + (String(route[0].nonce) + "");
      case /* SamlError */55 :
          return "/error?context=" + (String(route[0]) + ("&message=" + (String(route[1]) + "")));
      case /* NotFound */56 :
          return route[0];
      case /* Error */57 :
          return "error-page?error=" + (String(route[0]) + "");
      case /* GenerateDemoProject */58 :
          return "/generate-demo-project/" + route[0];
      
    }
  }
}

function decodeProjectName(name) {
  return decodeURI(name);
}

function getProjectCaseInsensitive(project, projects) {
  var lowercaseProject = project.toLowerCase();
  if (projects !== undefined) {
    return Belt_Option.getWithDefault(Belt_List.getBy(Belt_List.map(projects, (function (project$prime) {
                          return project$prime.name;
                        })), (function (projectName) {
                      return projectName.toLowerCase() === lowercaseProject;
                    })), project);
  } else {
    return project;
  }
}

function routeForPathAndSearch(projects, path, search) {
  var exit = 0;
  var name;
  if (path) {
    switch (path[0]) {
      case "" :
          exit = path[1] ? 1 : 3;
          break;
      case "activate" :
          if (!path[1]) {
            return /* Activate */Block.__(5, [decodeSearch$7(search)]);
          }
          exit = 1;
          break;
      case "config" :
          var match = path[1];
          if (match) {
            var match$1 = match[1];
            if (match$1 && match$1[0] === "projects") {
              var match$2 = match$1[1];
              if (match$2) {
                var match$3 = match$2[1];
                if (match$3) {
                  var projectName = match$2[0];
                  switch (match$3[0]) {
                    case "access-control" :
                        if (!match$3[1]) {
                          return /* ProjectSettingsAccessControl */Block.__(44, [getProjectCaseInsensitive(projectName, projects)]);
                        }
                        exit = 1;
                        break;
                    case "scheduled-reports" :
                        if (!match$3[1]) {
                          return /* ProjectSettingsScheduledReports */Block.__(43, [getProjectCaseInsensitive(projectName, projects)]);
                        }
                        exit = 1;
                        break;
                    default:
                      exit = 1;
                  }
                } else {
                  exit = 1;
                }
              } else {
                exit = 1;
              }
            } else {
              exit = 1;
            }
          } else {
            exit = 1;
          }
          break;
      case "create" :
          if (!path[1]) {
            return /* CreateAccount */Block.__(1, [decodeSearch$9(search)]);
          }
          exit = 1;
          break;
      case "create-first-project" :
          if (!path[1]) {
            return /* CreateFirstProject */2;
          }
          exit = 1;
          break;
      case "dashboard" :
          var match$4 = path[1];
          if (match$4) {
            var match$5 = match$4[1];
            if (match$5 && match$5[0] === "project") {
              var match$6 = match$5[1];
              if (match$6) {
                var match$7 = match$6[1];
                var project = match$6[0];
                if (match$7) {
                  switch (match$7[0]) {
                    case "debugger" :
                        var match$8 = match$7[1];
                        if (match$8 && !match$8[1]) {
                          var objectID = ObjectID$BsConsole.fromDecimalString(match$8[0]);
                          return /* RedirectLegacyDebugger */Block.__(51, [
                                    getProjectCaseInsensitive(decodeURI(project), projects),
                                    undefined,
                                    objectID
                                  ]);
                        }
                        exit = 1;
                        break;
                    case "group" :
                        var match$9 = match$7[1];
                        if (match$9) {
                          var match$10 = match$9[1];
                          var fingerprint = match$9[0];
                          if (!match$10) {
                            return Util$BsConsole.log2Pipe("some fingerprint", /* RedirectLegacyDetailView */Block.__(52, [
                                          getProjectCaseInsensitive(decodeURI(project), projects),
                                          fingerprint
                                        ]));
                          }
                          if (match$10[0] === "trace") {
                            var match$11 = match$10[1];
                            if (match$11 && !match$11[1]) {
                              var objectID$1 = ObjectID$BsConsole.fromDecimalString(match$11[0]);
                              return /* RedirectLegacyDebugger */Block.__(51, [
                                        getProjectCaseInsensitive(decodeURI(project), projects),
                                        fingerprint,
                                        objectID$1
                                      ]);
                            }
                            exit = 1;
                          } else {
                            exit = 1;
                          }
                        } else {
                          exit = 1;
                        }
                        break;
                    case "query-builder" :
                        if (!match$7[1]) {
                          return /* RedirectLegacyQueryBuilder */Block.__(53, [
                                    getProjectCaseInsensitive(decodeURI(project), projects),
                                    decodeSearch$15(project, search)
                                  ]);
                        }
                        exit = 1;
                        break;
                    default:
                      exit = 1;
                  }
                } else {
                  var project$prime = getProjectCaseInsensitive(project, projects);
                  return /* ProjectTriage */Block.__(11, [
                            project$prime,
                            getInboxParams(project$prime, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
                            undefined
                          ]);
                }
              } else {
                exit = 1;
              }
            } else {
              exit = 1;
            }
          } else {
            exit = 1;
          }
          break;
      case "error" :
          if (path[1]) {
            exit = 1;
          } else {
            var json = Util$BsConsole.Qs.parse(search);
            var context = Json_decode.field("context", Json_decode.string, json);
            var message = Json_decode.field("message", Json_decode.string, json);
            return /* SamlError */Block.__(55, [
                      atob(context),
                      atob(message)
                    ]);
          }
          break;
      case "generate-demo-project" :
          var match$12 = path[1];
          if (match$12) {
            if (!match$12[1]) {
              return /* GenerateDemoProject */Block.__(58, [match$12[0]]);
            }
            exit = 1;
          } else {
            exit = 1;
          }
          break;
      case "login" :
          if (!path[1]) {
            return /* Login */Block.__(3, [decodeSearch$6(search)]);
          }
          exit = 1;
          break;
      case "p" :
          var match$13 = path[1];
          if (match$13) {
            var name$1 = match$13[0];
            name = name$1;
            exit = 2;
          } else {
            exit = 1;
          }
          break;
      case "recover" :
          if (!path[1]) {
            return /* Recover */1;
          }
          exit = 1;
          break;
      case "reset" :
          if (!path[1]) {
            return /* Reset */Block.__(0, [decodeSearch$8(search)]);
          }
          exit = 1;
          break;
      case "settings" :
          var match$14 = path[1];
          if (!match$14) {
            return /* Settings */4;
          }
          switch (match$14[0]) {
            case "allowlist" :
                if (!match$14[1]) {
                  return /* SettingsWhitelist */17;
                }
                exit = 1;
                break;
            case "billing" :
                var match$15 = match$14[1];
                if (!match$15) {
                  return /* SettingsBilling */18;
                }
                if (match$15[0] === "plans") {
                  if (!match$15[1]) {
                    return /* SettingsBillingPlans */19;
                  }
                  exit = 1;
                } else {
                  exit = 1;
                }
                break;
            case "invites" :
                var match$16 = match$14[1];
                if (!match$16) {
                  return /* SettingsInvites */11;
                }
                if (match$16[0] === "new") {
                  if (!match$16[1]) {
                    return /* SettingsInvitesNew */12;
                  }
                  exit = 1;
                } else {
                  exit = 1;
                }
                break;
            case "me" :
                if (!match$14[1]) {
                  return /* SettingsMe */5;
                }
                exit = 1;
                break;
            case "networking" :
                var match$17 = match$14[1];
                if (!match$17) {
                  return /* SettingsNetworking */13;
                }
                switch (match$17[0]) {
                  case "listeners" :
                      if (!match$17[1]) {
                        return /* SettingsNetworkingListeners */16;
                      }
                      exit = 1;
                      break;
                  case "ssl" :
                      if (!match$17[1]) {
                        return /* SettingsNetworkingSSL */15;
                      }
                      exit = 1;
                      break;
                  default:
                    exit = 1;
                }
                break;
            case "projects" :
                var match$18 = match$14[1];
                if (!match$18) {
                  return /* SettingsProjects */8;
                }
                switch (match$18[0]) {
                  case "fork" :
                      if (!match$18[1]) {
                        return /* SettingsProjectsFork */10;
                      }
                      exit = 1;
                      break;
                  case "new" :
                      if (!match$18[1]) {
                        return /* SettingsProjectsNew */9;
                      }
                      exit = 1;
                      break;
                  default:
                    exit = 1;
                }
                break;
            case "retention-policy" :
                if (!match$14[1]) {
                  return /* SettingsRetentionPolicy */14;
                }
                exit = 1;
                break;
            case "saml" :
                if (!match$14[1]) {
                  return /* SettingsSamlConfig */21;
                }
                exit = 1;
                break;
            case "subscriptions" :
                if (!match$14[1]) {
                  return /* SettingsSubscriptions */20;
                }
                exit = 1;
                break;
            case "teams" :
                if (!match$14[1]) {
                  return /* SettingsTeams */7;
                }
                exit = 1;
                break;
            case "users" :
                if (!match$14[1]) {
                  return /* SettingsUsers */6;
                }
                exit = 1;
                break;
            case "workflow-connections" :
                if (!match$14[1]) {
                  return /* SettingsWfConnectionManagement */Block.__(6, [decodeSearch$20(search)]);
                }
                exit = 1;
                break;
            default:
              exit = 1;
          }
          break;
      case "share" :
          if (!path[1]) {
            return /* Share */Block.__(4, [fromJson(Util$BsConsole.Qs.parse(search))]);
          }
          exit = 1;
          break;
      case "signup" :
          if (!path[1]) {
            return /* Signup */0;
          }
          exit = 1;
          break;
      case "u-login" :
          if (!path[1]) {
            return /* UDashEntry */Block.__(2, [decodeSearch$10(search)]);
          }
          exit = 1;
          break;
      case "ui-playground" :
          if (!path[1]) {
            return /* UiPlayground */23;
          }
          exit = 1;
          break;
      case "wf-playground" :
          if (!path[1]) {
            return /* WfPlayground */22;
          }
          exit = 1;
          break;
      case "workflows" :
          var match$19 = path[1];
          if (match$19) {
            switch (match$19[0]) {
              case "callback" :
                  if (!match$19[1]) {
                    return /* Workflow */Block.__(54, [fromJson$1(Util$BsConsole.Qs.parse(search))]);
                  }
                  exit = 1;
                  break;
              case "error-page" :
                  if (!match$19[1]) {
                    return /* Error */Block.__(57, [decodeSearch$5(search)]);
                  }
                  exit = 1;
                  break;
              default:
                exit = 1;
            }
          } else {
            exit = 1;
          }
          break;
      default:
        exit = 1;
    }
  } else {
    exit = 3;
  }
  switch (exit) {
    case 1 :
        return /* NotFound */Block.__(56, [Belt_List.toArray(path).join("/") + ("?" + search)]);
    case 2 :
        var name$2 = getProjectCaseInsensitive(decodeURI(name), projects);
        var exit$1 = 0;
        if (path && path[0] === "p") {
          var match$20 = path[1];
          if (match$20) {
            var match$21 = match$20[1];
            if (!match$21) {
              return /* Project */Block.__(7, [name$2]);
            }
            var exit$2 = 0;
            switch (match$21[0]) {
              case "compare" :
                  var match$22 = match$21[1];
                  if (!match$22) {
                    return /* ProjectCompareReleases */Block.__(9, [
                              name$2,
                              undefined,
                              decodeSearch$12(name$2, search)
                            ]);
                  }
                  if (!match$22[1]) {
                    return /* ProjectCompareReleases */Block.__(9, [
                              name$2,
                              decodeURI(match$22[0]),
                              decodeSearch$12(name$2, search)
                            ]);
                  }
                  exit$1 = 4;
                  break;
              case "debug" :
              case "debug2" :
                  exit$2 = 5;
                  break;
              case "debugger" :
                  if (!match$21[1]) {
                    return /* ProjectDebuggerLegacy1 */Block.__(18, [
                              name$2,
                              decodeSearch$17(search)
                            ]);
                  }
                  exit$1 = 4;
                  break;
              case "explore" :
                  if (!match$21[1]) {
                    return /* ProjectQueryBuilder */Block.__(13, [
                              name$2,
                              decodeSearch$14(name$2, search)
                            ]);
                  }
                  exit$1 = 4;
                  break;
              case "flamegraph" :
                  if (!match$21[1]) {
                    return /* ProjectFlamegraph */Block.__(20, [
                              name$2,
                              decodeSearch$19(name$2, search)
                            ]);
                  }
                  exit$1 = 4;
                  break;
              case "inbox" :
                  if (!match$21[1]) {
                    return /* ProjectInboxLegacy1 */Block.__(12, [
                              name$2,
                              decodeSearch$13(name$2, search)
                            ]);
                  }
                  exit$1 = 4;
                  break;
              case "instances" :
                  if (!match$21[1]) {
                    return /* ProjectInstancesLegacy1 */Block.__(16, [
                              name$2,
                              decodeSearch$16(name$2, search)
                            ]);
                  }
                  exit$1 = 4;
                  break;
              case "list" :
                  if (!match$21[1]) {
                    return /* ProjectInstances */Block.__(15, [
                              name$2,
                              decodeSearch$16(name$2, search)
                            ]);
                  }
                  exit$1 = 4;
                  break;
              case "overview" :
                  if (!match$21[1]) {
                    return /* ProjectOverview */Block.__(8, [
                              name$2,
                              decodeSearch$11(name$2, search)
                            ]);
                  }
                  exit$1 = 4;
                  break;
              case "query-builder" :
                  if (!match$21[1]) {
                    return /* ProjectQueryBuilderLegacy1 */Block.__(14, [
                              name$2,
                              decodeSearch$14(name$2, search)
                            ]);
                  }
                  exit$1 = 4;
                  break;
              case "settings" :
                  var match$23 = match$21[1];
                  if (!match$23) {
                    return /* ProjectSettings */Block.__(22, [name$2]);
                  }
                  switch (match$23[0]) {
                    case "access-control" :
                        if (!match$23[1]) {
                          return /* ProjectSettingsAccessControl */Block.__(44, [name$2]);
                        }
                        exit$1 = 4;
                        break;
                    case "alerts" :
                        if (!match$23[1]) {
                          return /* ProjectSettingsAlerts */Block.__(27, [
                                    name$2,
                                    decodeSearch$4(search)
                                  ]);
                        }
                        exit$1 = 4;
                        break;
                    case "api-tokens" :
                        if (!match$23[1]) {
                          return /* ProjectSettingsApiTokens */Block.__(48, [name$2]);
                        }
                        exit$1 = 4;
                        break;
                    case "attributes" :
                        if (!match$23[1]) {
                          return /* ProjectSettingsAttributes */Block.__(34, [
                                    name$2,
                                    decodeSearch$3(search)
                                  ]);
                        }
                        exit$1 = 4;
                        break;
                    case "deduplication" :
                        if (match$23[1]) {
                          exit$1 = 4;
                        } else {
                          if (Dedupe_Flag$BsConsole.dedupe_config_flag !== true) {
                            return /* NotFound */Block.__(56, [Belt_List.toArray(path).join("/") + ("?" + search)]);
                          }
                          var qs = decodeSearch(search);
                          return /* ProjectSettingsDedupeConfig */Block.__(49, [
                                    name$2,
                                    qs.objectId
                                  ]);
                        }
                        break;
                    case "integration-guides" :
                        if (!match$23[1]) {
                          return /* ProjectSettingsIntegratedDocs */Block.__(28, [
                                    name$2,
                                    decodeSearch$2(search)
                                  ]);
                        }
                        exit$1 = 4;
                        break;
                    case "integrations" :
                        return /* ProjectSettingsIntegrations */Block.__(35, [
                                  name$2,
                                  WfRouter$BsConsole.pathToRoute(match$23[1], search),
                                  getOptionalParams(search)
                                ]);
                    case "metrics-exporter" :
                        if (!match$23[1]) {
                          return /* ProjectSettingsMetricsExporter */Block.__(24, [name$2]);
                        }
                        exit$1 = 4;
                        break;
                    case "reprocessing" :
                        if (!match$23[1]) {
                          return /* ProjectSettingsReprocessing */Block.__(45, [name$2]);
                        }
                        exit$1 = 4;
                        break;
                    case "retention" :
                        if (!match$23[1]) {
                          return /* ProjectSettingsRetentionPolicy */Block.__(46, [name$2]);
                        }
                        exit$1 = 4;
                        break;
                    case "sampling" :
                        if (!match$23[1]) {
                          return /* ProjectSettingStorageSampling */Block.__(32, [name$2]);
                        }
                        exit$1 = 4;
                        break;
                    case "scheduled-reports" :
                        if (!match$23[1]) {
                          return /* ProjectSettingsScheduledReports */Block.__(43, [name$2]);
                        }
                        exit$1 = 4;
                        break;
                    case "scrubbers" :
                        if (!match$23[1]) {
                          return /* ProjectSettingsScrubbers */Block.__(25, [name$2]);
                        }
                        exit$1 = 4;
                        break;
                    case "server-side-actions" :
                        if (!match$23[1]) {
                          return /* ProjectSettingsServerSideActions */Block.__(26, [name$2]);
                        }
                        exit$1 = 4;
                        break;
                    case "sourcecode" :
                        if (!match$23[1]) {
                          return /* ProjectSettingsSourceCode */Block.__(47, [name$2]);
                        }
                        exit$1 = 4;
                        break;
                    case "stability-monitoring" :
                        if (!match$23[1]) {
                          return /* ProjectSettingsStabilityMonitoring */Block.__(50, [name$2]);
                        }
                        exit$1 = 4;
                        break;
                    case "storage" :
                        var match$24 = match$23[1];
                        if (!match$24) {
                          return /* ProjectSettingsStorage */Block.__(30, [name$2]);
                        }
                        switch (match$24[0]) {
                          case "retention" :
                              if (!match$24[1]) {
                                return /* ProjectSettingsRetentionPolicy */Block.__(46, [name$2]);
                              }
                              exit$1 = 4;
                              break;
                          case "sampling" :
                              if (!match$24[1]) {
                                return /* ProjectSettingStorageSampling */Block.__(32, [name$2]);
                              }
                              exit$1 = 4;
                              break;
                          default:
                            exit$1 = 4;
                        }
                        break;
                    case "submission" :
                        var match$25 = match$23[1];
                        if (!match$25) {
                          return /* ProjectSettingsSubmission */Block.__(29, [name$2]);
                        }
                        switch (match$25[0]) {
                          case "data-sources" :
                              if (!match$25[1]) {
                                return /* ProjectSettingsSubmissionDataSources */Block.__(33, [name$2]);
                              }
                              exit$1 = 4;
                              break;
                          case "tokens" :
                              if (!match$25[1]) {
                                return /* ProjectSettingsSubmissionTokens */Block.__(31, [name$2]);
                              }
                              exit$1 = 4;
                              break;
                          default:
                            exit$1 = 4;
                        }
                        break;
                    case "symbol" :
                        var match$26 = match$23[1];
                        if (!match$26) {
                          return /* ProjectSettingsSymbols */Block.__(39, [name$2]);
                        }
                        switch (match$26[0]) {
                          case "access-tokens" :
                              if (!match$26[1]) {
                                return /* ProjectSettingsSymbolAccessTokens */Block.__(40, [name$2]);
                              }
                              exit$1 = 4;
                              break;
                          case "missing-symbols" :
                              if (!match$26[1]) {
                                return /* ProjectSettingsMissingSymbols */Block.__(41, [name$2]);
                              }
                              exit$1 = 4;
                              break;
                          case "servers" :
                              if (!match$26[1]) {
                                return /* ProjectSettingsSymbolServers */Block.__(38, [name$2]);
                              }
                              exit$1 = 4;
                              break;
                          case "symbol-search" :
                              if (!match$26[1]) {
                                return /* ProjectSettingsSymbolSearch */Block.__(42, [name$2]);
                              }
                              exit$1 = 4;
                              break;
                          case "upload" :
                              if (!match$26[1]) {
                                return /* ProjectSettingsSymbolsUpload */Block.__(37, [name$2]);
                              }
                              exit$1 = 4;
                              break;
                          case "uploaded-archives" :
                              if (!match$26[1]) {
                                return /* ProjectSettingsSymbolsUploadedArchives */Block.__(36, [name$2]);
                              }
                              exit$1 = 4;
                              break;
                          default:
                            exit$1 = 4;
                        }
                        break;
                    case "ui-playground" :
                        if (!match$23[1]) {
                          return /* ProjectSettingsPlayground */Block.__(23, [name$2]);
                        }
                        exit$1 = 4;
                        break;
                    default:
                      exit$1 = 4;
                  }
                  break;
              case "similarity" :
                  if (!match$21[1]) {
                    return /* ProjectSimilarity */Block.__(21, [
                              name$2,
                              decodeSearch$14(name$2, search)
                            ]);
                  }
                  exit$1 = 4;
                  break;
              case "triage" :
                  if (!match$21[1]) {
                    return /* ProjectTriage */Block.__(11, [
                              decodeURI(name$2),
                              decodeSearch$13(name$2, search),
                              WfLinkUtil$BsConsole.getTxFromQueryString(search)
                            ]);
                  }
                  exit$1 = 4;
                  break;
              case "upload" :
                  if (!match$21[1]) {
                    return /* ProjectUpload */Block.__(10, [name$2]);
                  }
                  exit$1 = 4;
                  break;
              default:
                exit$1 = 4;
            }
            if (exit$2 === 5) {
              if (!match$21[1]) {
                return /* ProjectDebugger2 */Block.__(19, [
                          name$2,
                          decodeSearch$18(search)
                        ]);
              }
              exit$1 = 4;
            }
            
          } else {
            exit$1 = 4;
          }
        } else {
          exit$1 = 4;
        }
        if (exit$1 === 4) {
          return /* NotFound */Block.__(56, [Belt_List.toArray(path).join("/") + ("?" + search)]);
        }
        break;
    case 3 :
        var json$1 = Util$BsConsole.Qs.parse(search);
        var redirect = Json_decode.optional((function (param) {
                return Json_decode.field("redirect", Json_decode.string, param);
              }), json$1);
        if (redirect !== undefined) {
          return /* Share */Block.__(4, [{
                      redirect: redirect
                    }]);
        } else {
          return /* Shell */3;
        }
    
  }
}

function withDefaults(projectName, aperture, havings, factor, fold, sort, normBy, highlightExporter, param) {
  var __x = Crdb$BsConsole.Aperture.$$default;
  return /* ProjectQueryBuilder */Block.__(13, [
            projectName,
            {
              aperture: Belt_Option.getWithDefault(aperture, Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, Curry._1(Crdb$BsConsole.TimeAperture.create, /* Relative */Block.__(0, [
                              /* Floating */0,
                              /* Week */2
                            ])), __x)),
              factor: Belt_Option.getWithDefault(factor, /* Identity */0),
              havings: Belt_Option.getWithDefault(havings, Crdb$BsConsole.Havings.empty),
              fold: Belt_Option.getWithDefault(fold, Crdb$BsConsole.Fold.empty),
              sort: Belt_Option.getWithDefault(sort, Crdb$BsConsole.Sort.defaultAggregate),
              normBy: Belt_Option.getWithDefault(normBy, undefined),
              highlightExporter: Belt_Option.getWithDefault(highlightExporter, undefined)
            }
          ]);
}

var ProjectQueryBuilder = {
  withDefaults: withDefaults
};

function getProjectName(t) {
  var path = pathForRoute(t);
  var pathList = Belt_List.fromArray(Belt_Array.keep(path.split("/"), (function (s) {
              return s !== "";
            })));
  if (!pathList) {
    return ;
  }
  if (pathList[0] !== "p") {
    return ;
  }
  var match = pathList[1];
  if (match) {
    return match[0];
  }
  
}

exports.Search = Search;
exports.defaultStats = defaultStats;
exports.defaultFold = defaultFold;
exports.defaultSelects = defaultSelects;
exports.withDefaultOnException = withDefaultOnException;
exports.optDefault = optDefault;
exports.ProjectSettingsDedupeConfigParams = ProjectSettingsDedupeConfigParams;
exports.ProjectSettingsIntegrationsParams = ProjectSettingsIntegrationsParams;
exports.IntegratedDocsParams = IntegratedDocsParams;
exports.ProjectSettingsAttributesParams = ProjectSettingsAttributesParams;
exports.ProjectSettingsAlertsParams = ProjectSettingsAlertsParams;
exports.GenericErrorParams = GenericErrorParams;
exports.LoginParams = LoginParams;
exports.ActivateParams = ActivateParams;
exports.ResetParams = ResetParams;
exports.CreateAccountParams = CreateAccountParams;
exports.UDashEntryParams = UDashEntryParams;
exports.attributeUniq = attributeUniq;
exports.getUniqueFoldAttributes = getUniqueFoldAttributes;
exports.ProjectOverviewParams = ProjectOverviewParams;
exports.ProjectCompareReleasesParams = ProjectCompareReleasesParams;
exports.ProjectInboxParams = ProjectInboxParams;
exports.getInboxParams = getInboxParams;
exports.ProjectQueryBuilderParams = ProjectQueryBuilderParams;
exports.ProjectQueryBuilderLegacyParams = ProjectQueryBuilderLegacyParams;
exports.getQueryBuilderParams = getQueryBuilderParams;
exports.ProjectInstancesParams = ProjectInstancesParams;
exports.getInstancesParams = getInstancesParams;
exports.ProjectDebuggerParams = ProjectDebuggerParams;
exports.ProjectDebugger2Params = ProjectDebugger2Params;
exports.getDebuggerParams = getDebuggerParams;
exports.ProjectFlamegraphParams = ProjectFlamegraphParams;
exports.ShareParams = ShareParams;
exports.SettingsWfConnectionManagement = SettingsWfConnectionManagement;
exports.Workflow = Workflow;
exports.getFlamegraphParams = getFlamegraphParams;
exports.toString = toString;
exports.pathForRoute = pathForRoute;
exports.decodeProjectName = decodeProjectName;
exports.getProjectCaseInsensitive = getProjectCaseInsensitive;
exports.routeForPathAndSearch = routeForPathAndSearch;
exports.ProjectQueryBuilder = ProjectQueryBuilder;
exports.getProjectName = getProjectName;
/* defaultFold Not a pure module */
