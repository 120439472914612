// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Fetch = require("bs-fetch/lib/js/src/Fetch.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var Task2$BsConsole = require("./Task2.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var CommentsUtil$BsConsole = require("./activity-feed/CommentsUtil.js");
var APIActivityFeed$BsConsole = require("./APIActivityFeed.js");

var ParseError = Caml_exceptions.create("FetchActivityFeed-BsConsole.ParseError");

var UnknownSvcError = Caml_exceptions.create("FetchActivityFeed-BsConsole.UnknownSvcError");

var ServiceError = Caml_exceptions.create("FetchActivityFeed-BsConsole.ServiceError");

function responseCb(onSuccess, onFailure, resp) {
  if (!resp.tag) {
    return Curry._1(onSuccess, resp[0]);
  }
  var error = resp[0];
  var tmp;
  if (error[0] === Task2$BsConsole.CoronerError || error[0] === Task2$BsConsole.Httpd) {
    tmp = error[1].message;
  } else if (error[0] === Task2$BsConsole.JsonDecodeError) {
    var message = error[1];
    tmp = message !== undefined ? message : "JsonDecodeError";
  } else if (error[0] === ServiceError) {
    tmp = error[1];
  } else if (error[0] === UnknownSvcError) {
    tmp = String(error[1]) + ": Unknown service error";
  } else if (error[0] === ParseError) {
    var message$1 = error[1].message;
    tmp = message$1 !== undefined ? "ParseError" + message$1 : "ParseError: There was an error parsing data";
  } else {
    console.log(error);
    tmp = "Error: Something went wrong. This error has been reported to Backtrace.";
  }
  return Curry._1(onFailure, tmp);
}

function getServiceStatusTask(serviceEndpoint) {
  return Task2$BsConsole.make(/* Custom */Block.__(1, [serviceEndpoint + "/status"]), undefined, /* Get */0, [/* tuple */[
                "X-Coroner-Location",
                location.origin
              ]], (function (param) {
                try {
                  var version = APIActivityFeed$BsConsole.Status.fromJson(param.json);
                  return /* Ok */Block.__(0, [version]);
                }
                catch (exn){
                  return /* Error */Block.__(1, [[
                              UnknownSvcError,
                              param.status
                            ]]);
                }
              }), undefined, undefined);
}

function getCommentsTask(serviceEndpoint, projectId, fingerprint) {
  return Task2$BsConsole.make(/* Custom */Block.__(1, [serviceEndpoint + ("/" + (String(projectId) + ("/" + fingerprint)))]), undefined, /* Get */0, [/* tuple */[
                "X-Coroner-Location",
                location.origin
              ]], (function (param) {
                var status = param.status;
                var json = param.json;
                try {
                  if (status >= 400) {
                    var errorMessage = APIActivityFeed$BsConsole.$$Error.fromJson(json);
                    return /* Error */Block.__(1, [[
                                ServiceError,
                                errorMessage
                              ]]);
                  }
                  var result = Belt_List.toArray(Json_decode.list(APIActivityFeed$BsConsole.$$Comment.fromJson, json));
                  return /* Ok */Block.__(0, [result]);
                }
                catch (raw_err){
                  var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                  console.log(err);
                  return /* Error */Block.__(1, [[
                              UnknownSvcError,
                              status
                            ]]);
                }
              }), undefined, undefined);
}

function postCommentTask(serviceEndpoint, projectId, fingerprint, contents) {
  var body = Json_encode.object_(/* :: */[
        /* tuple */[
          "contents",
          CommentsUtil$BsConsole.Comments.stringify(contents)
        ],
        /* [] */0
      ]);
  return Task2$BsConsole.make(/* Custom */Block.__(1, [serviceEndpoint + ("/" + (String(projectId) + ("/" + fingerprint)))]), undefined, /* Post */Block.__(0, [body]), [/* tuple */[
                "X-Coroner-Location",
                location.origin
              ]], (function (param) {
                var status = param.status;
                try {
                  if (status >= 400) {
                    var errorMessage = APIActivityFeed$BsConsole.$$Error.fromJson(param.json);
                    return /* Error */Block.__(1, [[
                                ServiceError,
                                errorMessage
                              ]]);
                  }
                  var result = APIActivityFeed$BsConsole.$$Comment.fromJson;
                  return /* Ok */Block.__(0, [result]);
                }
                catch (exn){
                  return /* Error */Block.__(1, [[
                              UnknownSvcError,
                              status
                            ]]);
                }
              }), undefined, undefined);
}

function modifyCommentTask(serviceEndpoint, projectId, fingerprint, commentId, contents) {
  var body = Json_encode.object_(/* :: */[
        /* tuple */[
          "contents",
          CommentsUtil$BsConsole.Comments.stringify(contents)
        ],
        /* [] */0
      ]);
  return Task2$BsConsole.make(/* Custom */Block.__(1, [serviceEndpoint + ("/" + (String(projectId) + ("/" + (fingerprint + ("/" + String(commentId))))))]), undefined, /* Put */Block.__(1, [body]), [/* tuple */[
                "X-Coroner-Location",
                location.origin
              ]], (function (param) {
                var status = param.status;
                var json = param.json;
                try {
                  if (status >= 400) {
                    var errorMessage = APIActivityFeed$BsConsole.$$Error.fromJson(json);
                    return /* Error */Block.__(1, [[
                                ServiceError,
                                errorMessage
                              ]]);
                  }
                  var result = APIActivityFeed$BsConsole.$$Comment.fromJson(json);
                  return /* Ok */Block.__(0, [result]);
                }
                catch (exn){
                  return /* Error */Block.__(1, [[
                              UnknownSvcError,
                              status
                            ]]);
                }
              }), undefined, undefined);
}

function deleteCommentTask(serviceEndpoint, token, projectId, fingerprint, commentId, onSuccess, onFailure) {
  var url = serviceEndpoint + ("/" + (String(projectId) + ("/" + (fingerprint + ("/" + String(commentId))))));
  var headers = [
    /* tuple */[
      "X-Coroner-Token",
      token
    ],
    /* tuple */[
      "X-Coroner-Location",
      location.origin
    ]
  ];
  var request = Fetch.RequestInit.make(/* Delete */4, Caml_option.some(headers), undefined, undefined, undefined, undefined, /* SameOrigin */1, undefined, undefined, undefined, undefined)(undefined);
  var __x = fetch(url, request);
  var __x$1 = __x.then((function (resp) {
          var status = resp.status;
          if (status === 200) {
            return Promise.resolve(Json_encode.object_(/* :: */[
                            /* tuple */[
                              "response",
                              "success"
                            ],
                            /* [] */0
                          ]));
          } else if (status >= 400) {
            return resp.json();
          } else {
            return Promise.resolve(Json_encode.object_(/* :: */[
                            /* tuple */[
                              "err",
                              "Uncaught promise exception"
                            ],
                            /* [] */0
                          ]));
          }
        }));
  var __x$2 = __x$1.then((function (json) {
          var successMessage = Json_decode.optional((function (param) {
                  return Json_decode.field("response", Json_decode.string, param);
                }), json);
          var errorMessage = Json_decode.optional((function (param) {
                  return Json_decode.field("err", Json_decode.string, param);
                }), json);
          if (successMessage !== undefined) {
            if (successMessage === "success") {
              Curry._1(onSuccess, undefined);
            } else if (errorMessage !== undefined) {
              Curry._1(onFailure, errorMessage);
            } else {
              Curry._1(onFailure, "Failed to parse response");
            }
          } else if (errorMessage !== undefined) {
            Curry._1(onFailure, errorMessage);
          } else {
            Curry._1(onFailure, "Failed to parse response");
          }
          return Promise.resolve(undefined);
        }));
  return __x$2.catch((function (error) {
                var message = error.message;
                var msg = (message == null) ? undefined : Caml_option.some(message);
                if (message == null) {
                  Curry._1(onFailure, "Uncaught promise failure");
                } else {
                  Curry._1(onFailure, msg);
                }
                return Promise.resolve(undefined);
              }));
}

exports.ParseError = ParseError;
exports.UnknownSvcError = UnknownSvcError;
exports.ServiceError = ServiceError;
exports.responseCb = responseCb;
exports.getServiceStatusTask = getServiceStatusTask;
exports.getCommentsTask = getCommentsTask;
exports.postCommentTask = postCommentTask;
exports.modifyCommentTask = modifyCommentTask;
exports.deleteCommentTask = deleteCommentTask;
/* Task2-BsConsole Not a pure module */
