// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var React = require("react");
var I18N$BsConsole = require("../I18N.js");
var Colors$BsConsole = require("../Colors.js");
var BtTypography$BsConsole = require("../BtTypography.js");

function BreadcrumbDivider(Props) {
  var text = Css.style(/* :: */[
        Css.fontSize(Css.px(14)),
        /* :: */[
          Css.fontWeight(/* `num */[
                5496390,
                500
              ]),
          /* :: */[
            Css.lineHeight(Css.px(24)),
            /* [] */0
          ]
        ]
      ]);
  var divider = Css.merge(/* :: */[
        text,
        /* :: */[
          Css.style(/* :: */[
                Css.color(Css.hex(Colors$BsConsole.grey45)),
                /* :: */[
                  Css.margin2(Css.px(0), Css.rem(0.25)),
                  /* [] */0
                ]
              ]),
          /* [] */0
        ]
      ]);
  return React.createElement(BtTypography$BsConsole.make, {
              variant: /* Body1 */8,
              className: divider,
              children: I18N$BsConsole.showSkip("/")
            });
}

var make = BreadcrumbDivider;

exports.make = make;
/* Css Not a pure module */
