// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_int64 = require("bs-platform/lib/js/caml_int64.js");
var Crdb$BsConsole = require("./crdb.js");
var Group$BsConsole = require("./bs-crdb-response/src/Group.js");
var BacktraceHooks$BsConsole = require("./BacktraceHooks.js");
var QueryPaginationTask$BsConsole = require("./QueryPaginationTask.js");

function makeStabilityQuery(aperture, metricsAttributes, normBy, factor) {
  var timeAperture = Curry._1(Crdb$BsConsole.Aperture.getTimeAperture, aperture);
  var metricsAttributeNames = Belt_List.toArray(Belt_List.map(metricsAttributes, (function (attr) {
              return attr.name;
            })));
  var startingAperture = Curry._2(Crdb$BsConsole.Aperture.setFilters, Curry._1(Crdb$BsConsole.Filters.fromArray, Belt_Array.keep(Curry._1(Crdb$BsConsole.Filters.toArray, Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture)), (function (filter) {
                  return metricsAttributeNames.includes(Curry._1(Crdb$BsConsole.Filter.getAttribute, filter));
                }))), Crdb$BsConsole.Aperture.$$default);
  var aperture$prime;
  if (normBy !== undefined) {
    var __x = Curry._2(Crdb$BsConsole.Filters.add, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
              "_metric_group",
              /* Equal */Block.__(0, [/* `String */[
                    -976970511,
                    normBy
                  ]])
            ]), Curry._1(Crdb$BsConsole.Aperture.getFilters, startingAperture));
    aperture$prime = Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, timeAperture, Curry._2(Crdb$BsConsole.Aperture.setFilters, __x, Crdb$BsConsole.Aperture.$$default));
  } else {
    aperture$prime = Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, timeAperture, Crdb$BsConsole.Aperture.$$default);
  }
  return /* Aggregate */Block.__(0, [
            aperture$prime,
            factor,
            Curry._2(Crdb$BsConsole.Fold.add, Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                      "_value",
                      /* Sum */3
                    ]), Crdb$BsConsole.Fold.empty),
            undefined,
            undefined,
            undefined
          ]);
}

function use(aperture, normBy, token, projectName, metricsAttributes, factor) {
  var apertureForQuery = BacktraceHooks$BsConsole.FrozenTimeAperture.use(aperture);
  var metricsAttributeNames = Belt_List.toArray(Belt_List.map(metricsAttributes, (function (attr) {
              return attr.name;
            })));
  var factor$1;
  if (factor) {
    var attribute = factor[0];
    factor$1 = metricsAttributeNames.includes(attribute) ? /* Custom */[attribute] : /* Custom */["*"];
  } else {
    factor$1 = /* Custom */["*"];
  }
  var query = makeStabilityQuery(apertureForQuery, metricsAttributes, normBy, factor$1);
  var match = QueryPaginationTask$BsConsole.MetricsAggregate.use(token, projectName, query, "_metric_group-score", undefined, undefined);
  if (normBy === undefined) {
    return ;
  }
  var remote = match[0];
  if (typeof remote === "number") {
    return ;
  }
  if (remote.tag) {
    return ;
  }
  var match$1 = remote[0][0];
  var rows = match$1[1];
  var columns = match$1[0];
  if (factor$1 && metricsAttributeNames.includes(factor$1[0])) {
    var scores = Belt_List.toArray(Belt_List.keepMap(Belt_List.map(rows, (function (group) {
                    var groupName = group.factor.value;
                    var getter = function (param, param$1) {
                      return Group$BsConsole.getAggregation(columns, group, param, param$1);
                    };
                    var match = Group$BsConsole.unwrapSum(getter("_value", /* Sum */7));
                    if (match === undefined) {
                      return ;
                    }
                    if (match.tag) {
                      return ;
                    }
                    var match$1 = match[0];
                    if (typeof match$1 === "number" || match$1[0] !== 365180284) {
                      return ;
                    } else {
                      return /* tuple */[
                              groupName,
                              Caml_int64.of_float(match$1[1])
                            ];
                    }
                  })), (function (s) {
                return s;
              })));
    return /* Grouped */Block.__(1, [scores]);
  }
  var group = Belt_List.get(rows, 0);
  if (group === undefined) {
    return ;
  }
  var getter = function (param, param$1) {
    return Group$BsConsole.getAggregation(columns, group, param, param$1);
  };
  var match$2 = Group$BsConsole.unwrapSum(getter("_value", /* Sum */7));
  if (match$2 === undefined) {
    return ;
  }
  if (match$2.tag) {
    return ;
  }
  var match$3 = match$2[0];
  if (typeof match$3 === "number" || match$3[0] !== 365180284) {
    return ;
  } else {
    return /* TotalScore */Block.__(0, [Caml_int64.of_float(match$3[1])]);
  }
}

exports.makeStabilityQuery = makeStabilityQuery;
exports.use = use;
/* Crdb-BsConsole Not a pure module */
