// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var I18N$BsConsole = require("./I18N.js");
var Row2$BsConsole = require("./components/Row2.js");
var TxTask$BsConsole = require("./TxTask.js");
var BtPaper$BsConsole = require("./BtPaper.js");
var BtTheme$BsConsole = require("./BtTheme.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var MuiIcons$BsConsole = require("./MuiIcons.js");
var Services$BsConsole = require("./Services.js");
var UnitySdkCTA$BsConsole = require("./UnitySdkCTA.js");
var ViewLoading$BsConsole = require("./ViewLoading.js");
var BtTypography$BsConsole = require("./BtTypography.js");
var DescribeTask$BsConsole = require("./DescribeTask.js");
var Paper = require("@material-ui/core/Paper");
var Button = require("@material-ui/core/Button");
var Styles = require("@material-ui/core/styles");
var BacktraceHooks$BsConsole = require("./BacktraceHooks.js");
var IdentityVariant$BsConsole = require("./IdentityVariant.js");
var DrilldownContext$BsConsole = require("./DrilldownContext.js");
var DemoProjectPrompt$BsConsole = require("./DemoProjectPrompt.js");
var DashboardWidgetGrid$BsConsole = require("./DashboardWidgetGrid.js");
var DistributionValueSelect$BsConsole = require("./DistributionValueSelect.js");

var paper = Css.merge(/* :: */[
      Css.style(/* :: */[
            Css.display(/* flex */-1010954439),
            /* :: */[
              Css.flexDirection(/* column */-963948842),
              /* [] */0
            ]
          ]),
      /* :: */[
        BtPaper$BsConsole.mainViewMargins,
        /* [] */0
      ]
    ]);

var cta = Css.style(/* :: */[
      Css.margin4(Css.px(15), Css.px(15), Css.px(0), Css.px(15)),
      /* [] */0
    ]);

var Style = {
  paper: paper,
  cta: cta
};

function getVersionAttribute(projectFrontendSettings, attributes) {
  var projectSettingsVersionAttribute = Belt_Option.flatMap(projectFrontendSettings, (function (settings) {
          return settings.versionAttribute;
        }));
  var versionAttributes = Belt_List.keep(attributes, (function (attribute) {
          var match = attribute.format;
          var match$1 = attribute.name;
          switch (match) {
            case "none" :
                if (match$1.toLowerCase().includes("version")) {
                  return attribute.statistics.used;
                } else {
                  return false;
                }
            case "semver" :
                return true;
            default:
              return false;
          }
        }));
  var applicationVersion = Belt_List.getBy(versionAttributes, (function (attr) {
          return attr.name === "application.version";
        }));
  var version = Belt_List.getBy(versionAttributes, (function (attr) {
          return attr.name === "version";
        }));
  if (projectSettingsVersionAttribute !== undefined) {
    return projectSettingsVersionAttribute;
  } else if (version !== undefined) {
    return version.name;
  } else if (applicationVersion !== undefined) {
    return applicationVersion.name;
  } else {
    return ;
  }
}

function Overview$View(Props) {
  var aperture = Props.aperture;
  var token = Props.token;
  var projectName = Props.projectName;
  var config = Props.config;
  var serviceEndpoint = Props.serviceEndpoint;
  var handleChangeUrl = Props.handleChangeUrl;
  var handleAddFilters = Props.handleAddFilters;
  var attributes = Props.attributes;
  var projectFrontendSettings = Props.projectFrontendSettings;
  var updateProjectFrontendSettingsRemote = Props.updateProjectFrontendSettingsRemote;
  var bubbleUpError = Props.bubbleUpError;
  var match = React.useState((function () {
          return getVersionAttribute(projectFrontendSettings, attributes);
        }));
  var setVersionAttribute = match[1];
  var versionAttribute = match[0];
  React.useEffect((function () {
          var nextVersionAttribute = getVersionAttribute(projectFrontendSettings, attributes);
          if (versionAttribute !== undefined && nextVersionAttribute !== undefined && versionAttribute !== nextVersionAttribute) {
            Curry._1(setVersionAttribute, (function (param) {
                    return nextVersionAttribute;
                  }));
          }
          
        }), [projectFrontendSettings]);
  return React.createElement(DashboardWidgetGrid$BsConsole.make, {
              aperture: aperture,
              token: token,
              projectName: projectName,
              config: config,
              serviceEndpoint: serviceEndpoint,
              handleChangeUrl: handleChangeUrl,
              handleAddFilters: handleAddFilters,
              attributes: attributes,
              updateProjectFrontendSettingsRemote: updateProjectFrontendSettingsRemote,
              dashboard: "overview",
              versionAttribute: versionAttribute,
              onVersionAttributeChange: (function (attribute) {
                  return Curry._1(setVersionAttribute, (function (param) {
                                return attribute;
                              }));
                }),
              bubbleUpError: bubbleUpError
            });
}

var View = {
  make: Overview$View
};

function getViewState(serviceEndpoint, describeRemote, projectFrontendSettings) {
  if (serviceEndpoint === undefined) {
    return /* MissingServiceEndpoint */1;
  }
  if (typeof describeRemote === "number") {
    return /* Loading */0;
  }
  if (!describeRemote.tag) {
    if (typeof projectFrontendSettings !== "number") {
      if (projectFrontendSettings.tag) {
        return /* Failure */Block.__(0, [projectFrontendSettings[0]]);
      } else {
        return /* Success */Block.__(1, [
                  serviceEndpoint,
                  describeRemote[0],
                  projectFrontendSettings[0]
                ]);
      }
    }
    projectFrontendSettings === /* NotAsked */0;
  }
  if (typeof projectFrontendSettings === "number") {
    return /* Loading */0;
  } else {
    return /* Failure */Block.__(0, [describeRemote[0]]);
  }
}

function Overview(Props) {
  var aperture = Props.aperture;
  var projectName = Props.projectName;
  var token = Props.token;
  var config = Props.config;
  Props.route;
  var handleChangeUrl = Props.handleChangeUrl;
  var handleAddFilters = Props.handleAddFilters;
  var serviceEndpoint = Services$BsConsole.getEndpoint(undefined)(config.services, "overview");
  var match = React.useState((function () {
          return false;
        }));
  var setShowUnitySDKCTA = match[1];
  var showUnitySDKCTA = match[0];
  React.useEffect((function () {
          Curry._1(setShowUnitySDKCTA, (function (param) {
                  return false;
                }));
          
        }), [projectName]);
  var checkForBacktraceUnitySDKErrors = function (error) {
    if (error === undefined) {
      return ;
    }
    switch (error) {
      case "No metric group found" :
      case "No unique_aggregation object found" :
          break;
      default:
        return ;
    }
    if (showUnitySDKCTA === false && IdentityVariant$BsConsole.current === /* Unity */2) {
      return Curry._1(setShowUnitySDKCTA, (function (param) {
                    return true;
                  }));
    }
    
  };
  var match$1 = DescribeTask$BsConsole.use(token, projectName, undefined, undefined);
  var match$2 = TxTask$BsConsole.use(token, projectName);
  var txRemote = match$2[0];
  var match$3 = BacktraceHooks$BsConsole.getProjectFrontendSettings(token, config, projectName);
  var handleSetAperture = function (aperture$prime) {
    return Curry._1(handleChangeUrl, /* ProjectOverview */Block.__(8, [
                  projectName,
                  {
                    aperture: aperture$prime
                  }
                ]));
  };
  var tmp;
  tmp = typeof txRemote === "number" || txRemote.tag || txRemote[0] !== 1 ? null : React.createElement(DemoProjectPrompt$BsConsole.make, {
          config: config,
          token: token,
          bannerVariant: /* Overview */3,
          handleChangeUrl: handleChangeUrl,
          projectName: projectName,
          styles: Css.style(/* :: */[
                Css.transform(Css.translateY(Css.px(20))),
                /* :: */[
                  Css.marginTop(Css.px(5)),
                  /* :: */[
                    Css.marginRight(Css.px(16)),
                    /* :: */[
                      Css.marginLeft(Css.px(16)),
                      /* [] */0
                    ]
                  ]
                ]
              ])
        });
  var msg = getViewState(serviceEndpoint, match$1[0], match$3[0]);
  var tmp$1;
  if (typeof msg === "number") {
    tmp$1 = msg === /* Loading */0 ? React.createElement(ViewLoading$BsConsole.Jsx3.make, {
            label: "Fetching overview data"
          }) : React.createElement(Row2$BsConsole.make, {
            justifyContent: /* center */98248149,
            className: Css.style(/* :: */[
                  Css.margin(Css.rem(2)),
                  /* [] */0
                ]),
            children: React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Body1 */8,
                  children: I18N$BsConsole.show(undefined, "Overview service is currently not available on this instance. Please contact Backtrace support for further assistance.")
                })
          });
  } else if (msg.tag) {
    var attributes = msg[1];
    tmp$1 = React.createElement(React.Fragment, undefined, showUnitySDKCTA ? React.createElement(UnitySdkCTA$BsConsole.make, {
                className: cta
              }) : null, React.createElement(Row2$BsConsole.make, {
              alignItems: /* center */98248149,
              justifyContent: /* spaceBetween */516682146,
              children: null
            }, React.createElement(Row2$BsConsole.make, {
                  className: Css.style(/* :: */[
                        Css.marginLeft(Css.rem(1)),
                        /* :: */[
                          Css.marginTop(Css.rem(1)),
                          /* [] */0
                        ]
                      ]),
                  children: null
                }, DistributionValueSelect$BsConsole.hasAttribute(attributes, "error.type") ? React.createElement(DistributionValueSelect$BsConsole.make, {
                        attribute: "error.type",
                        label: "Error type",
                        aperture: aperture,
                        token: token,
                        projectName: projectName,
                        handleSetAperture: handleSetAperture,
                        className: Css.style(/* :: */[
                              Css.marginRight(Css.rem(1.0)),
                              /* [] */0
                            ]),
                        icon: React.createElement(MuiIcons$BsConsole.OctagonalError.make, {
                              color: "inherit",
                              fontSize: "inherit"
                            }),
                        view: "overview"
                      }) : null, DistributionValueSelect$BsConsole.hasAttribute(attributes, "uname.sysname") ? React.createElement(DistributionValueSelect$BsConsole.make, {
                        attribute: "uname.sysname",
                        label: "Platform",
                        aperture: aperture,
                        token: token,
                        projectName: projectName,
                        handleSetAperture: handleSetAperture,
                        className: Css.style(/* :: */[
                              Css.marginRight(Css.rem(1.0)),
                              /* [] */0
                            ]),
                        icon: React.createElement(MuiIcons$BsConsole.Platform.make, {
                              color: "inherit",
                              fontSize: "inherit"
                            }),
                        view: "overview"
                      }) : null), React.createElement(Button.default, {
                  variant: "contained",
                  color: "primary",
                  className: Css.merge(/* :: */[
                        Css.style(/* :: */[
                              Css.marginTop(Css.px(20)),
                              /* :: */[
                                Css.marginRight(Css.px(16)),
                                /* [] */0
                              ]
                            ]),
                        /* :: */[
                          "walkthrough--mode_tab--releases",
                          /* [] */0
                        ]
                      ]),
                  onClick: (function (param) {
                      return Curry._1(handleChangeUrl, /* ProjectCompareReleases */Block.__(9, [
                                    projectName,
                                    undefined,
                                    {
                                      aperture: aperture,
                                      groups: undefined
                                    }
                                  ]));
                    }),
                  children: I18N$BsConsole.show(undefined, "View project releases")
                })), React.createElement(Overview$View, {
              aperture: aperture,
              token: token,
              projectName: projectName,
              config: config,
              serviceEndpoint: msg[0],
              handleChangeUrl: handleChangeUrl,
              handleAddFilters: handleAddFilters,
              attributes: attributes,
              projectFrontendSettings: msg[2],
              updateProjectFrontendSettingsRemote: match$3[1],
              bubbleUpError: checkForBacktraceUnitySDKErrors
            }));
  } else {
    tmp$1 = I18N$BsConsole.dynamic(msg[0]);
  }
  return React.createElement(Styles.MuiThemeProvider, {
              theme: BtTheme$BsConsole.theme,
              children: React.createElement(DrilldownContext$BsConsole.Provider.make, {
                    handleChangeUrl: handleChangeUrl,
                    handleAddFilters: handleAddFilters,
                    children: React.createElement(Paper.default, {
                          classes: {
                            root: paper
                          },
                          children: null
                        }, tmp, tmp$1)
                  })
            });
}

function make(aperture, projectName, token, config, route, handleChangeUrl, handleAddFilters, _children) {
  return ReasonReactCompat.wrapReactForReasonReact(Overview, {
              aperture: aperture,
              projectName: projectName,
              token: token,
              config: config,
              route: route,
              handleChangeUrl: handleChangeUrl,
              handleAddFilters: handleAddFilters
            }, _children);
}

var Re = {
  make: make
};

var make$1 = Overview;

exports.Style = Style;
exports.getVersionAttribute = getVersionAttribute;
exports.View = View;
exports.getViewState = getViewState;
exports.make = make$1;
exports.Re = Re;
/* paper Not a pure module */
