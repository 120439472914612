// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var Uncaught$BsConsole = require("../Uncaught.js");
var Backtrace$BsConsole = require("./Backtrace.js");

var Value = { };

function isSlateFormat(json) {
  var obj = Js_json.decodeObject(json);
  if (obj !== undefined) {
    return Js_dict.get(Caml_option.valFromOption(obj), "document") !== undefined;
  } else {
    return false;
  }
}

function reduceToString(value) {
  return Belt_Array.reduce(value, "", (function (acc, curr) {
                return acc + curr;
              }));
}

function decode(json) {
  return ({
            object_: Json_decode.field("object", Json_decode.string, json),
            text: Json_decode.field("text", Json_decode.string, json)
          }).text;
}

var Leaf = {
  decode: decode
};

function decode$1(json) {
  var textNode_object_ = Json_decode.field("object", Json_decode.string, json);
  var textNode_leaves = Json_decode.field("leaves", (function (param) {
          return Json_decode.array(decode, param);
        }), json);
  return reduceToString(textNode_leaves);
}

var TextNode = {
  Leaf: Leaf,
  decode: decode$1
};

function decode$2(json) {
  var inlineNode_object_ = Json_decode.field("object", Json_decode.string, json);
  var inlineNode_type_ = Json_decode.field("type", Json_decode.string, json);
  var inlineNode_nodes = Json_decode.field("nodes", (function (param) {
          return Json_decode.array(decode$1, param);
        }), json);
  return reduceToString(inlineNode_nodes);
}

var InlineNode = {
  decode: decode$2
};

function decode$3(json) {
  var match = Json_decode.field("object", Json_decode.string, json);
  switch (match) {
    case "inline" :
        return decode$2(json);
    case "text" :
        return decode$1(json);
    default:
      return "";
  }
}

var ChildNode = {
  decode: decode$3
};

function addNewLines(value) {
  return Belt_Array.concat(value, ["\n"]);
}

function decode$4(json) {
  var paragraphNode_object_ = Json_decode.field("object", Json_decode.string, json);
  var paragraphNode_type_ = Json_decode.field("type", Json_decode.string, json);
  var paragraphNode_nodes = Json_decode.field("nodes", (function (param) {
          return Json_decode.array(decode$3, param);
        }), json);
  return reduceToString(Belt_Array.concat(paragraphNode_nodes, ["\n"]));
}

var ParagraphNode = {
  addNewLines: addNewLines,
  decode: decode$4
};

function decode$5(json) {
  var rootNode_object_ = Json_decode.field("object", Json_decode.string, json);
  var rootNode_nodes = Json_decode.field("nodes", (function (param) {
          return Json_decode.array(decode$4, param);
        }), json);
  return reduceToString(rootNode_nodes);
}

var RootNode = {
  decode: decode$5
};

function decode$6(json) {
  return ({
            document: Json_decode.field("document", decode$5, json)
          }).document;
}

var $$Document = {
  decode: decode$6
};

function toStringResult(json) {
  try {
    var value = decode$6(json);
    return /* Ok */Block.__(0, [value]);
  }
  catch (raw_err){
    var err = Caml_js_exceptions.internalToOCamlException(raw_err);
    var error = Uncaught$BsConsole.castToJsExn(err);
    var __x = Backtrace$BsConsole.Attributes.addString("src_module", "Slate/toStringResult", Backtrace$BsConsole.Attributes.addString("shouldSendAlert", "true", Backtrace$BsConsole.Attributes.make(undefined)));
    Backtrace$BsConsole.Client.send(__x, /* `exn */[
          5049499,
          error
        ]);
    return /* Error */Block.__(1, ["Error: Failed to parse comment. This error has been reported to Backtrace."]);
  }
}

exports.Value = Value;
exports.isSlateFormat = isSlateFormat;
exports.reduceToString = reduceToString;
exports.TextNode = TextNode;
exports.InlineNode = InlineNode;
exports.ChildNode = ChildNode;
exports.ParagraphNode = ParagraphNode;
exports.RootNode = RootNode;
exports.$$Document = $$Document;
exports.toStringResult = toStringResult;
/* Uncaught-BsConsole Not a pure module */
