// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Ui$BsConsole = require("../ui.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var Crdb$BsConsole = require("../crdb.js");
var Gate$BsConsole = require("../Gate.js");
var I18N$BsConsole = require("../I18N.js");
var Link$BsConsole = require("../Link.js");
var Route$BsConsole = require("../route.js");
var Colors$BsConsole = require("../Colors.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var MetricsEvent$BsConsole = require("../bs-metrics/MetricsEvent.js");
var DeleteByQuery$BsConsole = require("../deleting/DeleteByQuery.js");
var BtThemeContext$BsConsole = require("../BtThemeContext.js");
var ReprocessQuery$BsConsole = require("../reprocessing/ReprocessQuery.js");
var WindowVariables$BsConsole = require("../WindowVariables.js");

var component = ReasonReact.statelessComponent("QbMorePopover-BsConsole");

function make(group, aperture, config, havings, handleChangeUrl, token, name, universe, sort, handleAddFilters, normBy, children) {
  var filterMap = Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture);
  var filterApertureOnGroup = function (aperture) {
    var match = group.factor.attribute.toUpperCase();
    if (match === "NONE") {
      return aperture;
    }
    var filterMap = Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture);
    return Curry._2(Crdb$BsConsole.Aperture.setFilters, Curry._2(Crdb$BsConsole.Filters.add, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                        group.factor.attribute,
                        Crdb$BsConsole.FilterOperation.fromTuple(/* tuple */[
                              "equal",
                              group.factor.value
                            ])
                      ]), filterMap), aperture);
  };
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Ui$BsConsole.Popover.make((function (refHandler, onFocus, onClick) {
                                return React.createElement("div", {
                                            ref: refHandler,
                                            onFocus: onFocus,
                                            onClick: onClick
                                          }, children);
                              }), undefined, undefined, undefined, undefined, undefined, undefined, (function (onClose) {
                                var tmp;
                                if (group.factor.attribute !== "fingerprint") {
                                  var match = group.factor.attribute;
                                  var tmp$1 = match === "NONE" ? I18N$BsConsole.show(undefined, "Group by fingerprint, and reset aggregations") : Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                              /* String_literal */Block.__(11, [
                                                  "Filter on ",
                                                  /* String */Block.__(2, [
                                                      /* No_padding */0,
                                                      /* String_literal */Block.__(11, [
                                                          ", group by fingerprint, and reset aggregations.",
                                                          /* End_of_format */0
                                                        ])
                                                    ])
                                                ]),
                                              "Filter on %s, group by fingerprint, and reset aggregations."
                                            ]), group.factor.attribute);
                                  tmp = ReasonReact.element(undefined, undefined, Link$BsConsole.make(/* ProjectQueryBuilder */Block.__(13, [
                                              name,
                                              Route$BsConsole.getQueryBuilderParams(Caml_option.some(Curry._2(Crdb$BsConsole.Aperture.setFilters, Crdb$BsConsole.addDeletedZeroFilter(filterMap, WindowVariables$BsConsole.maybeAutoAddDeletedEqualZero), aperture)), Caml_option.some(havings), name, undefined, Caml_option.some(Curry._2(Crdb$BsConsole.Fold.add, Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                                                                "classifiers",
                                                                /* Head */1
                                                              ]), Curry._2(Crdb$BsConsole.Fold.add, Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                                                                    "callstack",
                                                                    /* Head */1
                                                                  ]), Curry._2(Crdb$BsConsole.Fold.add, Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                                                                        "guid",
                                                                        /* Unique */4
                                                                      ]), Crdb$BsConsole.Fold.empty)))), sort, normBy, undefined, undefined)
                                            ]), (function (e) {
                                              MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* ExploreGroup_ViewUniqueBugs */30]));
                                              e.stopPropagation();
                                              
                                            }), handleChangeUrl, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [
                                                                ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "View unique bugs")])),
                                                                ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Caption */-191243578, undefined, undefined, undefined, undefined, [tmp$1]))
                                                              ]))]))]));
                                } else {
                                  tmp = null;
                                }
                                var tmp$2;
                                if (group.factor.attribute === "fingerprint") {
                                  var init = Route$BsConsole.getInboxParams(name, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined);
                                  tmp$2 = ReasonReact.element(undefined, undefined, Link$BsConsole.make(/* ProjectTriage */Block.__(11, [
                                              name,
                                              {
                                                aperture: filterApertureOnGroup(aperture),
                                                sort: init.sort,
                                                stats: init.stats,
                                                havings: init.havings,
                                                fold: init.fold,
                                                normBy: init.normBy,
                                                similarity: init.similarity
                                              },
                                              undefined
                                            ]), (function (e) {
                                              MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* ExploreGroup_Triage */31]));
                                              e.stopPropagation();
                                              
                                            }), handleChangeUrl, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [
                                                                ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Triage issue")])),
                                                                ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Caption */-191243578, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Filter on fingerprint and switch to triage view.")]))
                                                              ]))]))]));
                                } else {
                                  tmp$2 = null;
                                }
                                var init$1 = Route$BsConsole.getInstancesParams(name, undefined, undefined);
                                var match$1 = group.factor.attribute;
                                var tmp$3 = match$1 === "NONE" ? I18N$BsConsole.show(undefined, "View as instances table.") : Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                            /* String_literal */Block.__(11, [
                                                "Filter on ",
                                                /* String */Block.__(2, [
                                                    /* No_padding */0,
                                                    /* String_literal */Block.__(11, [
                                                        "  and view as instances table.",
                                                        /* End_of_format */0
                                                      ])
                                                  ])
                                              ]),
                                            "Filter on %s  and view as instances table."
                                          ]), group.factor.attribute);
                                var match$2 = group.factor.attribute;
                                var tmp$4 = match$2 === "NONE" ? null : ReasonReact.element(undefined, undefined, Link$BsConsole.make(Curry._1(handleAddFilters, /* :: */[
                                                /* tuple */[
                                                  group.factor.attribute,
                                                  Crdb$BsConsole.FilterOperation.fromTuple(/* tuple */[
                                                        "equal",
                                                        group.factor.value
                                                      ])
                                                ],
                                                /* [] */0
                                              ]), (function (e) {
                                              MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* ExploreGroup_FilterOnGroup */33]));
                                              e.stopPropagation();
                                              
                                            }), handleChangeUrl, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [
                                                                ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Filter on group")])),
                                                                ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Caption */-191243578, undefined, undefined, undefined, undefined, [Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                                                                    /* String_literal */Block.__(11, [
                                                                                        "Filter on ",
                                                                                        /* String */Block.__(2, [
                                                                                            /* No_padding */0,
                                                                                            /* Char_literal */Block.__(12, [
                                                                                                /* "." */46,
                                                                                                /* End_of_format */0
                                                                                              ])
                                                                                          ])
                                                                                      ]),
                                                                                    "Filter on %s."
                                                                                  ]), group.factor.attribute)]))
                                                              ]))]))]));
                                var match$3 = group.factor.attribute;
                                var filters = match$3 === "NONE" ? /* [] */0 : /* :: */[
                                    /* tuple */[
                                      group.factor.attribute,
                                      Crdb$BsConsole.FilterOperation.fromTuple(/* tuple */[
                                            "equal",
                                            group.factor.value
                                          ])
                                    ],
                                    /* [] */0
                                  ];
                                var filterMap$1 = Belt_List.toArray(filters).reduce((function (filterMap, param) {
                                        return Curry._2(Crdb$BsConsole.Filters.add, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                                                        param[0],
                                                        param[1]
                                                      ]), filterMap);
                                      }), Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture));
                                var match$4 = group.factor.attribute;
                                var filters$1 = match$4 === "NONE" ? /* [] */0 : /* :: */[
                                    /* tuple */[
                                      group.factor.attribute,
                                      Crdb$BsConsole.FilterOperation.fromTuple(/* tuple */[
                                            "equal",
                                            group.factor.value
                                          ])
                                    ],
                                    /* [] */0
                                  ];
                                var filterMap$2 = Belt_List.toArray(filters$1).reduce((function (filterMap, param) {
                                        return Curry._2(Crdb$BsConsole.Filters.add, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                                                        param[0],
                                                        param[1]
                                                      ]), filterMap);
                                      }), Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture));
                                var init$2 = Route$BsConsole.getFlamegraphParams(undefined, undefined, name, undefined, undefined);
                                return [
                                        tmp,
                                        tmp$2,
                                        ReasonReact.element(undefined, undefined, Link$BsConsole.make(/* ProjectInstances */Block.__(15, [
                                                    name,
                                                    {
                                                      aperture: filterApertureOnGroup(aperture),
                                                      select: init$1.select,
                                                      sort: init$1.sort
                                                    }
                                                  ]), (function (e) {
                                                    MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* ExploreGroup_List */32]));
                                                    e.stopPropagation();
                                                    
                                                  }), handleChangeUrl, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [
                                                                      ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "View instances")])),
                                                                      ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Caption */-191243578, undefined, undefined, undefined, undefined, [tmp$3]))
                                                                    ]))]))])),
                                        tmp$4,
                                        ReasonReact.element(undefined, undefined, Link$BsConsole.make(/* ProjectDebugger2 */Block.__(19, [
                                                    name,
                                                    {
                                                      aperture: Curry._2(Crdb$BsConsole.Aperture.setFilters, filterMap$1, aperture),
                                                      state: undefined
                                                    }
                                                  ]), (function (e) {
                                                    MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* ExploreGroup_Debugger */34]));
                                                    e.stopPropagation();
                                                    
                                                  }), handleChangeUrl, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [
                                                                      ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "View latest trace")])),
                                                                      ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Caption */-191243578, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Open latest trace in debugger.")]))
                                                                    ]))]))])),
                                        ReasonReact.element(undefined, undefined, Link$BsConsole.make(/* ProjectFlamegraph */Block.__(20, [
                                                    name,
                                                    {
                                                      aperture: Curry._2(Crdb$BsConsole.Aperture.setFilters, filterMap$2, aperture),
                                                      weighted: init$2.weighted,
                                                      reversed: init$2.reversed
                                                    }
                                                  ]), (function (e) {
                                                    MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* ExploreGroup_FlameGraph */35]));
                                                    e.stopPropagation();
                                                    
                                                  }), handleChangeUrl, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [
                                                                      ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "View group as a flamegraph")])),
                                                                      ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Caption */-191243578, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "View group's callstack distribution as a flamegraph.")]))
                                                                    ]))]))])),
                                        ReasonReact.element(undefined, undefined, ReprocessQuery$BsConsole.Re.make(onClose, true, token, name, universe, /* Select */Block.__(1, [
                                                    filterApertureOnGroup(aperture),
                                                    /* :: */[
                                                      "*",
                                                      /* [] */0
                                                    ],
                                                    undefined,
                                                    undefined
                                                  ]), undefined, /* Reprocess_WithQuery_FromExplore */5, (function (param) {
                                                    var confirmAndReprocess = param[0];
                                                    return ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, undefined, (function ($$event) {
                                                                      $$event.stopPropagation();
                                                                      return Curry._1(confirmAndReprocess, undefined);
                                                                    }), undefined, undefined, undefined, [React.createElement("div", undefined, ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Reprocess group")])), ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Caption */-191243578, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Reprocess all errors in this group")])))]));
                                                  }))),
                                        ReasonReact.element(undefined, undefined, DeleteByQuery$BsConsole.Re.make(onClose, token, group.count, name, universe, /* Select */Block.__(1, [
                                                    filterApertureOnGroup(aperture),
                                                    /* :: */[
                                                      "*",
                                                      /* [] */0
                                                    ],
                                                    undefined,
                                                    undefined
                                                  ]), (function (confirmAndDelete) {
                                                    var getDisabledDeleteGroupMessage = function (querySize, user) {
                                                      var queryTooLarge = querySize > 100000;
                                                      var match = Gate$BsConsole.isNotAdmin(user);
                                                      if (match) {
                                                        return "Deleting is only permitted by administrators.";
                                                      } else if (queryTooLarge) {
                                                        return "This group has over 100,000 errors. Please apply additional filter rules to proceed.";
                                                      } else {
                                                        return ;
                                                      }
                                                    };
                                                    var errorMessageOption = getDisabledDeleteGroupMessage(group.count, config.user);
                                                    var errorMessageStr = Belt_Option.getWithDefault(errorMessageOption, "");
                                                    var isDisabled = Belt_Option.isSome(errorMessageOption);
                                                    return ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, {
                                                                    tooltip: Css.style(/* :: */[
                                                                          Css.fontSize(Css.px(13)),
                                                                          /* :: */[
                                                                            Css.backgroundColor(Css.hex(Colors$BsConsole.grey95)),
                                                                            /* [] */0
                                                                          ]
                                                                        ])
                                                                  }, I18N$BsConsole.show(undefined, errorMessageStr), undefined, /* Right */-57574468, undefined, undefined, undefined, [React.createElement("div", {
                                                                          className: Css.style(/* :: */[
                                                                                Css.marginTop(Css.px(5)),
                                                                                /* :: */[
                                                                                  Css.width(Css.pct(100)),
                                                                                  /* :: */[
                                                                                    Css.height(Css.pct(100)),
                                                                                    /* :: */[
                                                                                      Css.borderTopStyle(/* solid */12956715),
                                                                                      /* :: */[
                                                                                        Css.borderTopColor(Css.hex(Colors$BsConsole.grey7)),
                                                                                        /* [] */0
                                                                                      ]
                                                                                    ]
                                                                                  ]
                                                                                ]
                                                                              ])
                                                                        }, ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.MenuItem.Jsx2.make, undefined, (function ($$event) {
                                                                                    $$event.stopPropagation();
                                                                                    return Curry._1(confirmAndDelete, undefined);
                                                                                  }), undefined, isDisabled, undefined, [React.createElement("div", undefined, ReasonReact.element(undefined, undefined, Curry._1(BtThemeContext$BsConsole.Consumer.Jsx2.make, (function (themeVariant) {
                                                                                                  var errorColor;
                                                                                                  if (themeVariant !== undefined) {
                                                                                                    var palette = BtThemeContext$BsConsole.getPalette(themeVariant);
                                                                                                    errorColor = BtThemeContext$BsConsole.getHex(palette.error.main);
                                                                                                  } else {
                                                                                                    errorColor = /* `hex */[
                                                                                                      5194459,
                                                                                                      Colors$BsConsole.red
                                                                                                    ];
                                                                                                  }
                                                                                                  return ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, isDisabled ? "" : Css.style(/* :: */[
                                                                                                                        Css.color(errorColor),
                                                                                                                        /* [] */0
                                                                                                                      ]), /* Subheading */148169314, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Delete Group")]));
                                                                                                }))), ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Caption */-191243578, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Delete all errors in this group")])))])))]));
                                                  })))
                                      ];
                              }), undefined, []));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

var make$1 = ReasonReactCompat.wrapReasonReactForReact(component, (function (reactProps) {
        return make(reactProps.group, reactProps.aperture, reactProps.config, reactProps.havings, reactProps.handleChangeUrl, reactProps.token, reactProps.name, reactProps.universe, reactProps.sort, reactProps.handleAddFilters, reactProps.normBy, reactProps.children);
      }));

var Jsx3 = {
  make: make$1
};

var RR;

exports.RR = RR;
exports.component = component;
exports.make = make;
exports.Jsx3 = Jsx3;
/* component Not a pure module */
