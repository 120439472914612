// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var React = require("react");
var I18N$BsConsole = require("../I18N.js");
var Colors$BsConsole = require("../Colors.js");

function FilterBarIcon(Props) {
  var icon = Props.icon;
  var text = Props.text;
  var stylesOpt = Props.styles;
  var styles = stylesOpt !== undefined ? stylesOpt : "";
  var container = Css.merge(/* :: */[
        Css.style(/* :: */[
              Css.color(Css.hex(Colors$BsConsole.white)),
              /* :: */[
                Css.height(Css.px(34)),
                /* :: */[
                  Css.padding2(Css.px(0), Css.px(8)),
                  /* :: */[
                    Css.display(/* flex */-1010954439),
                    /* :: */[
                      Css.justifyContent(/* center */98248149),
                      /* :: */[
                        Css.alignItems(/* center */98248149),
                        /* [] */0
                      ]
                    ]
                  ]
                ]
              ]
            ]),
        /* :: */[
          styles,
          /* [] */0
        ]
      ]);
  return React.createElement("div", {
              className: container
            }, icon, I18N$BsConsole.show(undefined, text));
}

var make = FilterBarIcon;

exports.make = make;
/* Css Not a pure module */
