// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Crdb$BsConsole = require("./crdb.js");
var Issues$BsConsole = require("./bs-crdb-response/src/Issues.js");
var UrlEncode$BsConsole = require("./UrlEncode.js");
var FingerprintId$BsConsole = require("./FingerprintId.js");
var SessionStorage$BsConsole = require("./SessionStorage.js");
var BtTableColumnWidth$BsConsole = require("./BtTableColumnWidth.js");

var __x = Crdb$BsConsole.Fold.empty;

var __x$1 = Curry._2(Crdb$BsConsole.Fold.add, Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
          "classifiers",
          /* Head */1
        ]), __x);

var defaultTriageFold = Curry._2(Crdb$BsConsole.Fold.add, Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
          "guid",
          /* Unique */4
        ]), __x$1);

var __x$2 = Crdb$BsConsole.Fold.empty;

var defaultExploreAggFold = Curry._2(Crdb$BsConsole.Fold.add, Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
          "callstack",
          /* Head */1
        ]), __x$2);

var exploreAggsToRemove_000 = Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
      "timestamp",
      /* Bin */Block.__(0, [
          32,
          undefined
        ])
    ]);

var exploreAggsToRemove_001 = /* :: */[
  Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
        "timestamp",
        /* Range */8
      ]),
  /* [] */0
];

var exploreAggsToRemove = /* :: */[
  exploreAggsToRemove_000,
  exploreAggsToRemove_001
];

var defaultSelect = /* :: */[
  "timestamp",
  /* :: */[
    "fingerprint",
    /* :: */[
      "callstack",
      /* [] */0
    ]
  ]
];

var match = FingerprintId$BsConsole.shouldShowId(undefined);

var defaultTriageAggregations = Belt_List.concat(/* :: */[
      Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
            "timestamp",
            /* Range */8
          ]),
      /* :: */[
        Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
              "callstack",
              /* Head */1
            ]),
        /* :: */[
          Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                "fingerprint;first_seen",
                /* Head */1
              ]),
          /* :: */[
            Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                  Issues$BsConsole.State.columnName,
                  /* Head */1
                ]),
            /* :: */[
              Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                    Issues$BsConsole.Assignees.columnName,
                    /* Head */1
                  ]),
              /* :: */[
                Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                      Issues$BsConsole.Tickets.columnName,
                      /* Head */1
                    ]),
                /* :: */[
                  Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                        Issues$BsConsole.Tags.columnName,
                        /* Head */1
                      ]),
                  /* :: */[
                    Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                          Issues$BsConsole.Invariant.columnName,
                          /* Head */1
                        ]),
                    /* [] */0
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ], match ? /* :: */[
        Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
              Issues$BsConsole.Id.columnName,
              /* Head */1
            ]),
        /* [] */0
      ] : /* [] */0);

function removeFoldAggs(foldToRemove, fold) {
  var f = Belt_List.reduce(foldToRemove, fold, (function (acc, curr) {
          return Curry._2(Crdb$BsConsole.Fold.remove, curr, acc);
        }));
  var timestampAgg = Belt_Array.getBy(Curry._1(Crdb$BsConsole.Fold.toArray, f), (function (agg) {
          if (Curry._1(Crdb$BsConsole.Aggregation.getAttribute, agg) === "timestamp") {
            return Crdb$BsConsole.Operation.toString(Curry._1(Crdb$BsConsole.Aggregation.getOperation, agg)) === "bin";
          } else {
            return false;
          }
        }));
  if (timestampAgg !== undefined) {
    return Curry._2(Crdb$BsConsole.Fold.remove, Caml_option.valFromOption(timestampAgg), f);
  } else {
    return f;
  }
}

function pathNameToView(pathname) {
  var match = pathname.split("/");
  if (match.length !== 4) {
    return ;
  }
  var match$1 = match[0];
  if (match$1 !== "") {
    return ;
  }
  var match$2 = match[1];
  if (match$2 !== "p") {
    return ;
  }
  var match$3 = match[3];
  switch (match$3) {
    case "explore" :
        return /* ExploreAgg */1;
    case "list" :
        return /* ExploreList */2;
    case "triage" :
        return /* Triage */0;
    default:
      return ;
  }
}

function viewToString(param) {
  switch (param) {
    case /* Triage */0 :
        return "triage";
    case /* ExploreAgg */1 :
        return "explore-agg";
    case /* ExploreList */2 :
        return "explore-list";
    
  }
}

function viewFromOptionalString(optStr) {
  if (optStr === undefined) {
    return ;
  }
  switch (optStr) {
    case "explore-agg" :
        return /* ExploreAgg */1;
    case "explore-list" :
        return /* ExploreList */2;
    case "triage" :
        return /* Triage */0;
    default:
      return ;
  }
}

function toKey(view) {
  return viewToString(view) + "-query";
}

function getFromSession(projectName, view) {
  var key = viewToString(view) + "-query";
  return Belt_Option.flatMap(Belt_Option.map(SessionStorage$BsConsole.get(projectName, key), (function (crdbString) {
                    try {
                      return Curry._1(Crdb$BsConsole.Query.fromJson, JSON.parse(crdbString));
                    }
                    catch (_err){
                      return ;
                    }
                  })), (function (x) {
                return x;
              }));
}

function set(projectName, query, view) {
  var queryString = JSON.stringify(Curry._1(Crdb$BsConsole.Query.toJson, query));
  var key = viewToString(view) + "-query";
  return SessionStorage$BsConsole.set(projectName, key, queryString);
}

function get(projectName, view) {
  var sessionQuery = getFromSession(projectName, view);
  switch (view) {
    case /* Triage */0 :
        if (sessionQuery !== undefined) {
          var arg = Belt_Option.getWithDefault(Curry._1(Crdb$BsConsole.Query.getFold, sessionQuery), defaultTriageFold);
          var aggregations = removeFoldAggs(defaultTriageAggregations, arg);
          var sort = Belt_Option.getWithDefault(Curry._1(Crdb$BsConsole.Query.getSort, sessionQuery), Crdb$BsConsole.Sort.defaultAggregate);
          var init = UrlEncode$BsConsole.Query.decode(/* [] */0);
          return {
                  time: init.time,
                  filters: init.filters,
                  havings: init.havings,
                  select: init.select,
                  groupBy: init.groupBy,
                  sort: sort,
                  aggregations: aggregations,
                  stats: init.stats,
                  normBy: init.normBy,
                  highlightExporter: init.highlightExporter,
                  similarity: init.similarity
                };
        }
        var init$1 = UrlEncode$BsConsole.Query.decode(/* [] */0);
        return {
                time: init$1.time,
                filters: init$1.filters,
                havings: init$1.havings,
                select: init$1.select,
                groupBy: init$1.groupBy,
                sort: Crdb$BsConsole.Sort.defaultAggregate,
                aggregations: defaultTriageFold,
                stats: init$1.stats,
                normBy: init$1.normBy,
                highlightExporter: init$1.highlightExporter,
                similarity: init$1.similarity
              };
    case /* ExploreAgg */1 :
        if (sessionQuery !== undefined) {
          var arg$1 = Belt_Option.getWithDefault(Curry._1(Crdb$BsConsole.Query.getFold, sessionQuery), defaultExploreAggFold);
          var aggregations$1 = removeFoldAggs(exploreAggsToRemove, arg$1);
          var sort$1 = Belt_Option.getWithDefault(Curry._1(Crdb$BsConsole.Query.getSort, sessionQuery), Crdb$BsConsole.Sort.defaultAggregate);
          var groupBy = Belt_Option.getWithDefault(Curry._1(Crdb$BsConsole.Query.getFactor, sessionQuery), /* Identity */0);
          var init$2 = UrlEncode$BsConsole.Query.decode(/* [] */0);
          return {
                  time: init$2.time,
                  filters: init$2.filters,
                  havings: init$2.havings,
                  select: init$2.select,
                  groupBy: groupBy,
                  sort: sort$1,
                  aggregations: aggregations$1,
                  stats: init$2.stats,
                  normBy: init$2.normBy,
                  highlightExporter: init$2.highlightExporter,
                  similarity: init$2.similarity
                };
        }
        var init$3 = UrlEncode$BsConsole.Query.decode(/* [] */0);
        return {
                time: init$3.time,
                filters: init$3.filters,
                havings: init$3.havings,
                select: init$3.select,
                groupBy: init$3.groupBy,
                sort: Crdb$BsConsole.Sort.defaultAggregate,
                aggregations: defaultExploreAggFold,
                stats: init$3.stats,
                normBy: init$3.normBy,
                highlightExporter: init$3.highlightExporter,
                similarity: init$3.similarity
              };
    case /* ExploreList */2 :
        if (sessionQuery !== undefined) {
          var select = Belt_List.keep(Belt_Option.getWithDefault(Curry._1(Crdb$BsConsole.Query.getSelect, sessionQuery), defaultSelect), (function (col) {
                  if (col !== "_deleted") {
                    return col !== "_tx";
                  } else {
                    return false;
                  }
                }));
          var sort$2 = Belt_Option.getWithDefault(Curry._1(Crdb$BsConsole.Query.getSort, sessionQuery), Crdb$BsConsole.Sort.defaultSelect);
          var init$4 = UrlEncode$BsConsole.Query.decode(/* [] */0);
          return {
                  time: init$4.time,
                  filters: init$4.filters,
                  havings: init$4.havings,
                  select: select,
                  groupBy: init$4.groupBy,
                  sort: sort$2,
                  aggregations: init$4.aggregations,
                  stats: init$4.stats,
                  normBy: init$4.normBy,
                  highlightExporter: init$4.highlightExporter,
                  similarity: init$4.similarity
                };
        }
        var init$5 = UrlEncode$BsConsole.Query.decode(/* [] */0);
        return {
                time: init$5.time,
                filters: init$5.filters,
                havings: init$5.havings,
                select: defaultSelect,
                groupBy: init$5.groupBy,
                sort: Crdb$BsConsole.Sort.defaultSelect,
                aggregations: init$5.aggregations,
                stats: init$5.stats,
                normBy: init$5.normBy,
                highlightExporter: init$5.highlightExporter,
                similarity: init$5.similarity
              };
    
  }
}

var Query = {
  toKey: toKey,
  getFromSession: getFromSession,
  set: set,
  get: get
};

function toKey$1(view) {
  return viewToString(view) + "-columnOrder";
}

function get$1(projectName, view) {
  var key = viewToString(view) + "-columnOrder";
  return Belt_Option.flatMap(Belt_Option.map(SessionStorage$BsConsole.get(projectName, key), (function (columnOrderString) {
                    try {
                      var __x = JSON.parse(columnOrderString);
                      return Json_decode.array(Json_decode.string, __x);
                    }
                    catch (exn){
                      return ;
                    }
                  })), (function (x) {
                return x;
              }));
}

function set$1(projectName, columnOrder, view) {
  var columnOrderString = JSON.stringify(columnOrder);
  var key = viewToString(view) + "-columnOrder";
  return SessionStorage$BsConsole.set(projectName, key, columnOrderString);
}

function getWithoutView(projectName) {
  var view = pathNameToView(window.location.pathname);
  if (view !== undefined) {
    return get$1(projectName, view);
  }
  
}

var ColumnOrder = {
  toKey: toKey$1,
  get: get$1,
  set: set$1,
  getWithoutView: getWithoutView
};

function toKey$2(view) {
  return viewToString(view) + "-columnWidth";
}

function get$2(projectName, view) {
  var key = viewToString(view) + "-columnWidth";
  return Belt_Option.flatMap(Belt_Option.map(SessionStorage$BsConsole.get(projectName, key), (function (columnWidthsString) {
                    try {
                      var __x = JSON.parse(columnWidthsString);
                      return Json_decode.array(BtTableColumnWidth$BsConsole.ofJson, __x);
                    }
                    catch (exn){
                      return ;
                    }
                  })), (function (x) {
                return x;
              }));
}

function set$2(projectName, columnWidths, view) {
  var columnWidthsString = JSON.stringify(Json_encode.array(BtTableColumnWidth$BsConsole.toJson, columnWidths));
  var key = viewToString(view) + "-columnWidth";
  return SessionStorage$BsConsole.set(projectName, key, columnWidthsString);
}

function getWithoutView$1(projectName) {
  var view = pathNameToView(window.location.pathname);
  if (view !== undefined) {
    return get$2(projectName, view);
  }
  
}

var ColumnWidth = {
  toKey: toKey$2,
  get: get$2,
  set: set$2,
  getWithoutView: getWithoutView$1
};

exports.defaultTriageFold = defaultTriageFold;
exports.defaultExploreAggFold = defaultExploreAggFold;
exports.exploreAggsToRemove = exploreAggsToRemove;
exports.defaultSelect = defaultSelect;
exports.defaultTriageAggregations = defaultTriageAggregations;
exports.removeFoldAggs = removeFoldAggs;
exports.pathNameToView = pathNameToView;
exports.viewToString = viewToString;
exports.viewFromOptionalString = viewFromOptionalString;
exports.Query = Query;
exports.ColumnOrder = ColumnOrder;
exports.ColumnWidth = ColumnWidth;
/* __x Not a pure module */
