// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Mui$BsConsole = require("../../bindings/Mui.js");
var Col2$BsConsole = require("../../components/Col2.js");
var I18N$BsConsole = require("../../I18N.js");
var Row2$BsConsole = require("../../components/Row2.js");
var Util$BsConsole = require("../../util.js");
var Task2$BsConsole = require("../../Task2.js");
var Colors$BsConsole = require("../../Colors.js");
var BtSettings$BsConsole = require("../../BtSettings.js");
var TeamsUtils$BsConsole = require("../../teams/TeamsUtils.js");
var Icon = require("@material-ui/core/Icon");
var BtTypography$BsConsole = require("../../BtTypography.js");
var MetricsEvent$BsConsole = require("../../bs-metrics/MetricsEvent.js");
var Paper = require("@material-ui/core/Paper");
var Configuration$BsConsole = require("../../configuration.js");
var Button = require("@material-ui/core/Button");
var Select = require("@material-ui/core/Select");
var Close = require("@material-ui/icons/Close");
var Tooltip = require("@material-ui/core/Tooltip");
var MenuItem = require("@material-ui/core/MenuItem");
var TextField = require("@material-ui/core/TextField");
var InputLabel = require("@material-ui/core/InputLabel");
var ArrowUpward = require("@material-ui/icons/ArrowUpward");
var OutlinedInput = require("@material-ui/core/OutlinedInput");
var ArrowDownward = require("@material-ui/icons/ArrowDownward");

function toString(param) {
  switch (param) {
    case /* FileName */0 :
        return "file_name";
    case /* FunctionName */1 :
        return "function_name";
    case /* ObjectName */2 :
        return "object_name";
    case /* Attribute */3 :
        return "attribute";
    
  }
}

function toFriendlyString(param) {
  switch (param) {
    case /* FileName */0 :
        return I18N$BsConsole.get(undefined, "File name");
    case /* FunctionName */1 :
        return I18N$BsConsole.get(undefined, "Function name");
    case /* ObjectName */2 :
        return I18N$BsConsole.get(undefined, "Object name");
    case /* Attribute */3 :
        return I18N$BsConsole.get(undefined, "Attribute");
    
  }
}

function fromString(param) {
  switch (param) {
    case "attribute" :
        return /* Attribute */3;
    case "file_name" :
        return /* FileName */0;
    case "function_name" :
        return /* FunctionName */1;
    case "object_name" :
        return /* ObjectName */2;
    default:
      throw [
            Json_decode.DecodeError,
            I18N$BsConsole.get(undefined, "failed to decode field type")
          ];
  }
}

var FieldType = {
  toString: toString,
  toFriendlyString: toFriendlyString,
  fromString: fromString
};

function toString$1(param) {
  switch (param) {
    case /* Match */0 :
        return "match";
    case /* Skip */1 :
        return "skip";
    case /* MatchStop */2 :
        return "match_stop";
    case /* FullStop */3 :
        return "full_stop";
    
  }
}

function toFriendlyString$1(param) {
  switch (param) {
    case /* Match */0 :
        return I18N$BsConsole.get(undefined, "Search this server first");
    case /* Skip */1 :
        return I18N$BsConsole.get(undefined, "Don't search this server");
    case /* MatchStop */2 :
        return I18N$BsConsole.get(undefined, "Search only");
    case /* FullStop */3 :
        return I18N$BsConsole.get(undefined, "Don't search");
    
  }
}

function fromString$1(param) {
  switch (param) {
    case "full_stop" :
        return /* FullStop */3;
    case "match" :
        return /* Match */0;
    case "match_stop" :
        return /* MatchStop */2;
    case "skip" :
        return /* Skip */1;
    default:
      throw [
            Json_decode.DecodeError,
            I18N$BsConsole.get(undefined, "failed to decode field type")
          ];
  }
}

var Action = {
  toString: toString$1,
  toFriendlyString: toFriendlyString$1,
  fromString: fromString$1
};

function fromJson(json) {
  return {
          id: Json_decode.optional((function (param) {
                  return Json_decode.field("id", Json_decode.$$int, param);
                }), json),
          position: Json_decode.field("position", Json_decode.$$int, json),
          matchingPerforceId: Json_decode.field("matching_perforce_id", Json_decode.$$int, json),
          fieldType: fromString(Json_decode.field("field_type", Json_decode.string, json)),
          fieldName: Json_decode.field("field_name", Json_decode.string, json),
          action: fromString$1(Json_decode.field("action", Json_decode.string, json)),
          regex: Json_decode.field("regex", Json_decode.string, json),
          projectName: Json_decode.field("project_name", Json_decode.string, json),
          universeName: Json_decode.field("universe_name", Json_decode.string, json)
        };
}

function toJson(t) {
  var props_000 = /* tuple */[
    "position",
    t.position
  ];
  var props_001 = /* :: */[
    /* tuple */[
      "matching_perforce_id",
      t.matchingPerforceId
    ],
    /* :: */[
      /* tuple */[
        "field_type",
        toString(t.fieldType)
      ],
      /* :: */[
        /* tuple */[
          "field_name",
          t.fieldName
        ],
        /* :: */[
          /* tuple */[
            "regex",
            t.regex
          ],
          /* :: */[
            /* tuple */[
              "action",
              toString$1(t.action)
            ],
            /* :: */[
              /* tuple */[
                "project_name",
                t.projectName
              ],
              /* :: */[
                /* tuple */[
                  "universe_name",
                  t.universeName
                ],
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]
  ];
  var props = /* :: */[
    props_000,
    props_001
  ];
  var id = t.id;
  return Json_encode.object_(id !== undefined ? /* :: */[
                /* tuple */[
                  "id",
                  id
                ],
                props
              ] : props);
}

function fetchRules(config, universeName, projectName) {
  var path = Belt_Option.getWithDefault(Configuration$BsConsole.getEndpointWithProjectOverrides(projectName, undefined, config, "sourcecode"), "");
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(path) + ("/" + (String(universeName) + ("/" + (String(projectName) + "/perforce_rules")))))]), undefined, /* Get */0, undefined, (function (param) {
                return /* Ok */Block.__(0, [Json_decode.field("rules", (function (param) {
                                  return Json_decode.list(fromJson, param);
                                }), param.json)]);
              }), undefined, undefined);
}

function saveRule(rule, universeName, projectName, config) {
  var path = Belt_Option.getWithDefault(Configuration$BsConsole.getEndpointWithProjectOverrides(projectName, undefined, config, "sourcecode"), "");
  var id = rule.id;
  if (id !== undefined) {
    return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(path) + ("/" + (String(universeName) + ("/" + (String(projectName) + ("/perforce_rules/" + (String(id) + "")))))))]), undefined, /* Put */Block.__(1, [toJson(rule)]), undefined, (function (param) {
                  return /* Ok */Block.__(0, [Json_decode.field("rule", fromJson, param.json)]);
                }), undefined, undefined);
  } else {
    return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(path) + ("/" + (String(universeName) + ("/" + (String(projectName) + "/perforce_rules")))))]), undefined, /* Post */Block.__(0, [toJson(rule)]), undefined, (function (param) {
                  return /* Ok */Block.__(0, [fromJson(param.json)]);
                }), undefined, undefined);
  }
}

function deleteRule(id, universeName, projectName, config) {
  var path = Belt_Option.getWithDefault(Configuration$BsConsole.getEndpointWithProjectOverrides(projectName, undefined, config, "sourcecode"), "");
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(path) + ("/" + (String(universeName) + ("/" + (String(projectName) + ("/perforce_rules/" + (String(id) + "")))))))]), undefined, /* Delete */1, undefined, (function (param) {
                return /* Ok */Block.__(0, [undefined]);
              }), undefined, undefined);
}

function getRepoNameFromId(id, repos) {
  return Belt_Option.map(Belt_List.getBy(repos, (function (repo) {
                    return Caml_obj.caml_equal(repo.id, id);
                  })), (function (repo) {
                return repo.name;
              }));
}

var initialState = {
  fieldName: undefined,
  fieldType: /* Attribute */3,
  regex: undefined,
  matchingPerforceId: undefined,
  action: /* Match */0
};

function reducer(state, action) {
  if (typeof action === "number") {
    return initialState;
  }
  switch (action.tag | 0) {
    case /* SetFieldName */0 :
        return {
                fieldName: action[0],
                fieldType: state.fieldType,
                regex: state.regex,
                matchingPerforceId: state.matchingPerforceId,
                action: state.action
              };
    case /* SetFieldType */1 :
        return {
                fieldName: state.fieldName,
                fieldType: action[0],
                regex: state.regex,
                matchingPerforceId: state.matchingPerforceId,
                action: state.action
              };
    case /* SetRegex */2 :
        return {
                fieldName: state.fieldName,
                fieldType: state.fieldType,
                regex: action[0],
                matchingPerforceId: state.matchingPerforceId,
                action: state.action
              };
    case /* SetMatchingPerforceId */3 :
        return {
                fieldName: state.fieldName,
                fieldType: state.fieldType,
                regex: state.regex,
                matchingPerforceId: action[0],
                action: state.action
              };
    case /* SetAction */4 :
        return {
                fieldName: state.fieldName,
                fieldType: state.fieldType,
                regex: state.regex,
                matchingPerforceId: state.matchingPerforceId,
                action: action[0]
              };
    
  }
}

function PerforceRules$PerforceRule$NewRule(Props) {
  var config = Props.config;
  var token = Props.token;
  var projectName = Props.projectName;
  var servers = Props.servers;
  var universeName = Props.universeName;
  var ruleCreatedCb = Props.ruleCreatedCb;
  var close = Props.close;
  var match = React.useReducer(reducer, {
        fieldName: undefined,
        fieldType: /* Attribute */3,
        regex: undefined,
        matchingPerforceId: Belt_Option.flatMap(Belt_List.get(servers, 0), (function (s) {
                return s.id;
              })),
        action: /* Match */0
      });
  var dispatch = match[1];
  var match$1 = match[0];
  var action = match$1.action;
  var matchingPerforceId = match$1.matchingPerforceId;
  var regex = match$1.regex;
  var fieldType = match$1.fieldType;
  var fieldName = match$1.fieldName;
  var ruleFormValid = function (param) {
    var attrValid = fieldType !== undefined ? (
        fieldType >= 3 ? (
            fieldName !== undefined && regex !== undefined ? Util$BsConsole.nonEmptyStr(fieldName) && Util$BsConsole.nonEmptyStr(regex) : false
          ) : (
            regex !== undefined ? Util$BsConsole.nonEmptyStr(regex) : false
          )
      ) : false;
    var actionValid = action >= 3 ? true : matchingPerforceId !== undefined;
    if (attrValid) {
      return actionValid;
    } else {
      return false;
    }
  };
  return React.createElement(Paper.default, {
              classes: {
                root: Css.style(/* :: */[
                      Css.padding(Css.rem(1.0)),
                      /* :: */[
                        Css.marginBottom(Css.rem(1.0)),
                        /* [] */0
                      ]
                    ])
              },
              children: React.createElement(Row2$BsConsole.make, {
                    alignItems: /* flexEnd */924268066,
                    flexWrap: /* wrap */-822134326,
                    className: Css.style(/* :: */[
                          Css.marginBottom(Css.rem(0.5)),
                          /* [] */0
                        ]),
                    children: null
                  }, React.createElement("div", {
                        className: Css.style(/* :: */[
                              Css.fontSize(Css.em(1)),
                              /* :: */[
                                Css.lineHeight(Css.em(2)),
                                /* :: */[
                                  Css.marginRight(Css.rem(1)),
                                  /* :: */[
                                    Css.color(Css.hex(Colors$BsConsole.blackA54)),
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ])
                      }, I18N$BsConsole.show(undefined, "IF")), React.createElement(Col2$BsConsole.make, {
                        children: null
                      }, React.createElement(InputLabel.default, {
                            classes: {
                              root: Css.style(/* :: */[
                                    Css.marginTop(Css.px(8)),
                                    /* :: */[
                                      Css.marginBottom(Css.px(3)),
                                      /* [] */0
                                    ]
                                  ])
                            },
                            children: React.createElement(BtTypography$BsConsole.make, {
                                  variant: /* Caption */11,
                                  children: I18N$BsConsole.show(undefined, "Type")
                                })
                          }), React.createElement(Select.default, {
                            value: fieldType,
                            onChange: (function (e, param) {
                                var value = e.target.value;
                                return Curry._1(dispatch, /* SetFieldType */Block.__(1, [value]));
                              }),
                            input: React.createElement(OutlinedInput.default, { }),
                            renderValue: (function (v) {
                                return I18N$BsConsole.show(undefined, Belt_Option.mapWithDefault(v, "", toFriendlyString));
                              }),
                            className: Css.style(/* :: */[
                                  Css.backgroundColor(/* transparent */582626130),
                                  /* :: */[
                                    Css.paddingLeft(Css.rem(0.75)),
                                    /* :: */[
                                      Css.height(Css.rem(2)),
                                      /* :: */[
                                        Css.width(Css.ch(17)),
                                        /* :: */[
                                          Css.marginRight(Css.rem(1)),
                                          /* [] */0
                                        ]
                                      ]
                                    ]
                                  ]
                                ]),
                            variant: "outlined",
                            children: null
                          }, React.createElement(MenuItem.default, {
                                value: /* Attribute */3,
                                children: I18N$BsConsole.showSkip(I18N$BsConsole.get(undefined, "Attribute"))
                              }), React.createElement(MenuItem.default, {
                                value: /* FileName */0,
                                children: I18N$BsConsole.showSkip(I18N$BsConsole.get(undefined, "File name"))
                              }), React.createElement(MenuItem.default, {
                                value: /* FunctionName */1,
                                children: I18N$BsConsole.showSkip(I18N$BsConsole.get(undefined, "Function name"))
                              }), React.createElement(MenuItem.default, {
                                value: /* ObjectName */2,
                                children: I18N$BsConsole.showSkip(I18N$BsConsole.get(undefined, "Object name"))
                              }))), fieldType !== undefined && fieldType >= 3 ? React.createElement(Col2$BsConsole.make, {
                          children: null
                        }, React.createElement(InputLabel.default, {
                              classes: {
                                root: Css.style(/* :: */[
                                      Css.marginTop(Css.px(8)),
                                      /* :: */[
                                        Css.marginBottom(Css.px(3)),
                                        /* [] */0
                                      ]
                                    ])
                              },
                              children: React.createElement(BtTypography$BsConsole.make, {
                                    variant: /* Caption */11,
                                    children: I18N$BsConsole.show(undefined, "Attribute name")
                                  })
                            }), React.createElement(TextField.default, {
                              placeholder: I18N$BsConsole.get(undefined, "Attribute name"),
                              value: Belt_Option.mapWithDefault(fieldName, "", Util$BsConsole.identity),
                              color: "primary",
                              variant: "outlined",
                              classes: {
                                root: Css.style(/* :: */[
                                      Css.width(Css.ch(20)),
                                      /* :: */[
                                        Css.marginRight(Css.rem(1)),
                                        /* :: */[
                                          Css.selector("input", /* :: */[
                                                Css.important(Css.paddingTop(Css.px(6))),
                                                /* :: */[
                                                  Css.important(Css.paddingBottom(Css.px(7))),
                                                  /* [] */0
                                                ]
                                              ]),
                                          /* :: */[
                                            Css.selector("div[class*='multiline']", /* :: */[
                                                  Css.important(Css.paddingTop(Css.px(10))),
                                                  /* [] */0
                                                ]),
                                            /* [] */0
                                          ]
                                        ]
                                      ]
                                    ])
                              },
                              onChange: (function ($$event) {
                                  var v = $$event.target.value;
                                  return Curry._1(dispatch, /* SetFieldName */Block.__(0, [v]));
                                })
                            })) : null, React.createElement(Col2$BsConsole.make, {
                        children: null
                      }, React.createElement(InputLabel.default, {
                            classes: {
                              root: Css.style(/* :: */[
                                    Css.marginTop(Css.px(8)),
                                    /* :: */[
                                      Css.marginBottom(Css.px(3)),
                                      /* [] */0
                                    ]
                                  ])
                            },
                            children: React.createElement(BtTypography$BsConsole.make, {
                                  variant: /* Caption */11,
                                  children: I18N$BsConsole.show(undefined, "Regex")
                                })
                          }), React.createElement(TextField.default, {
                            placeholder: I18N$BsConsole.get(undefined, "Regex"),
                            value: Belt_Option.mapWithDefault(regex, "", Util$BsConsole.identity),
                            color: "primary",
                            variant: "outlined",
                            classes: {
                              root: Css.style(/* :: */[
                                    Caml_obj.caml_equal(fieldType, /* Attribute */3) ? Css.width(Css.ch(20)) : Css.width(Css.ch(42)),
                                    /* :: */[
                                      Css.marginRight(Css.rem(1)),
                                      /* :: */[
                                        Css.selector("input", /* :: */[
                                              Css.important(Css.paddingTop(Css.px(6))),
                                              /* :: */[
                                                Css.important(Css.paddingBottom(Css.px(7))),
                                                /* [] */0
                                              ]
                                            ]),
                                        /* :: */[
                                          Css.selector("div[class*='multiline']", /* :: */[
                                                Css.important(Css.paddingTop(Css.px(10))),
                                                /* [] */0
                                              ]),
                                          /* [] */0
                                        ]
                                      ]
                                    ]
                                  ])
                            },
                            onChange: (function ($$event) {
                                var value = BtSettings$BsConsole.getInputValue($$event);
                                return Curry._1(dispatch, /* SetRegex */Block.__(2, [value]));
                              })
                          })), React.createElement("div", {
                        className: Css.style(/* :: */[
                              Css.fontSize(Css.em(1)),
                              /* :: */[
                                Css.lineHeight(Css.em(2)),
                                /* :: */[
                                  Css.marginRight(Css.rem(1)),
                                  /* :: */[
                                    Css.color(Css.hex(Colors$BsConsole.blackA54)),
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ])
                      }, I18N$BsConsole.show(undefined, "THEN")), React.createElement(Col2$BsConsole.make, {
                        children: null
                      }, React.createElement(InputLabel.default, {
                            classes: {
                              root: Css.style(/* :: */[
                                    Css.marginTop(Css.px(8)),
                                    /* :: */[
                                      Css.marginBottom(Css.px(3)),
                                      /* [] */0
                                    ]
                                  ])
                            },
                            children: React.createElement(BtTypography$BsConsole.make, {
                                  variant: /* Caption */11,
                                  children: I18N$BsConsole.show(undefined, "Action")
                                })
                          }), React.createElement(Select.default, {
                            value: action,
                            onChange: (function (e, param) {
                                var value = e.target.value;
                                return Curry._1(dispatch, /* SetAction */Block.__(4, [value]));
                              }),
                            input: React.createElement(OutlinedInput.default, { }),
                            renderValue: (function (v) {
                                return I18N$BsConsole.showSkip(toFriendlyString$1(v));
                              }),
                            className: Css.style(/* :: */[
                                  Css.height(Css.rem(2)),
                                  /* :: */[
                                    Css.backgroundColor(/* transparent */582626130),
                                    /* :: */[
                                      Css.paddingLeft(Css.rem(0.75)),
                                      /* :: */[
                                        Css.width(Css.ch(21)),
                                        /* :: */[
                                          Css.marginRight(Css.rem(1)),
                                          /* [] */0
                                        ]
                                      ]
                                    ]
                                  ]
                                ]),
                            children: null
                          }, React.createElement(MenuItem.default, {
                                value: /* MatchStop */2,
                                children: I18N$BsConsole.showSkip(I18N$BsConsole.get(undefined, "Search only"))
                              }), React.createElement(MenuItem.default, {
                                value: /* FullStop */3,
                                children: I18N$BsConsole.showSkip(I18N$BsConsole.get(undefined, "Don't search"))
                              }))), action >= 3 ? null : React.createElement(Col2$BsConsole.make, {
                          children: null
                        }, React.createElement(InputLabel.default, {
                              classes: {
                                root: Css.style(/* :: */[
                                      Css.marginTop(Css.px(8)),
                                      /* :: */[
                                        Css.marginBottom(Css.px(3)),
                                        /* [] */0
                                      ]
                                    ])
                              },
                              children: React.createElement(BtTypography$BsConsole.make, {
                                    variant: /* Caption */11,
                                    children: I18N$BsConsole.show(undefined, "Server")
                                  })
                            }), React.createElement(Row2$BsConsole.make, {
                              alignItems: /* center */98248149,
                              children: React.createElement(Select.default, {
                                    value: matchingPerforceId,
                                    onChange: (function (e, param) {
                                        var value = e.target.value;
                                        return Curry._1(dispatch, /* SetMatchingPerforceId */Block.__(3, [value]));
                                      }),
                                    input: React.createElement(OutlinedInput.default, { }),
                                    renderValue: (function (id) {
                                        return I18N$BsConsole.showSkip(Belt_Option.mapWithDefault(getRepoNameFromId(id, servers), "", Util$BsConsole.identity));
                                      }),
                                    className: Css.style(/* :: */[
                                          Css.width(Css.ch(20)),
                                          /* :: */[
                                            Css.height(Css.rem(2)),
                                            /* :: */[
                                              Css.backgroundColor(/* transparent */582626130),
                                              /* :: */[
                                                Css.paddingLeft(Css.rem(0.75)),
                                                /* :: */[
                                                  Css.marginRight(Css.rem(1)),
                                                  /* [] */0
                                                ]
                                              ]
                                            ]
                                          ]
                                        ]),
                                    children: Belt_List.toArray(Belt_List.map(servers, (function (repo) {
                                                var name = getRepoNameFromId(repo.id, servers);
                                                if (name !== undefined) {
                                                  return React.createElement(MenuItem.default, {
                                                              value: repo.id,
                                                              children: I18N$BsConsole.showSkip(name)
                                                            });
                                                } else {
                                                  return null;
                                                }
                                              })))
                                  })
                            })), React.createElement(Button.default, {
                        variant: ruleFormValid(undefined) ? "contained" : "outlined",
                        color: "primary",
                        disabled: !ruleFormValid(undefined),
                        classes: {
                          root: Css.style(/* :: */[
                                Css.whiteSpace(/* nowrap */867913355),
                                /* :: */[
                                  Css.marginRight(Css.rem(0.25)),
                                  /* [] */0
                                ]
                              ])
                        },
                        onClick: (function (param) {
                            if (fieldType !== undefined && regex !== undefined && matchingPerforceId !== undefined) {
                              MetricsEvent$BsConsole.send(/* ActionWithAttrs */Block.__(1, [
                                      /* SourceCode_RuleCreate */86,
                                      Json_encode.object_(/* :: */[
                                            /* tuple */[
                                              "project",
                                              projectName
                                            ],
                                            /* [] */0
                                          ])
                                    ]));
                              var newRule_fieldName = Belt_Option.mapWithDefault(fieldName, "", Util$BsConsole.identity);
                              var newRule = {
                                id: undefined,
                                position: 0,
                                matchingPerforceId: matchingPerforceId,
                                fieldType: fieldType,
                                fieldName: newRule_fieldName,
                                action: action,
                                regex: regex,
                                projectName: projectName,
                                universeName: universeName
                              };
                              Task2$BsConsole.handle(token, saveRule(newRule, universeName, projectName, config), undefined, (function (_resp) {
                                      Curry._1(dispatch, /* Reset */0);
                                      return Curry._1(ruleCreatedCb, undefined);
                                    }));
                            }
                            
                          }),
                        children: I18N$BsConsole.show(undefined, "Create Rule")
                      }), React.createElement(Tooltip.default, {
                        children: React.createElement(Button.default, {
                              classes: {
                                root: Css.style(/* :: */[
                                      Css.marginTop(Css.rem(0.5)),
                                      /* [] */0
                                    ])
                              },
                              onClick: (function (param) {
                                  return Curry._1(close, undefined);
                                }),
                              children: React.createElement(Close.default, { })
                            }),
                        title: I18N$BsConsole.get(undefined, "close")
                      }))
            });
}

var NewRule = {
  make: PerforceRules$PerforceRule$NewRule
};

function PerforceRules$PerforceRule$Rule(Props) {
  var config = Props.config;
  var token = Props.token;
  var projectName = Props.projectName;
  var universeName = Props.universeName;
  var refetchRulesCb = Props.refetchRulesCb;
  var positionChangeCb = Props.positionChangeCb;
  var rule = Props.rule;
  var servers = Props.servers;
  var match = React.useState((function () {
          return rule;
        }));
  var setRule = match[1];
  var rule$1 = match[0];
  var moveUp = function (_evt) {
    MetricsEvent$BsConsole.send(/* ActionWithAttrs */Block.__(1, [
            /* SourceCode_RuleChangePosition */87,
            Json_encode.object_(/* :: */[
                  /* tuple */[
                    "project",
                    projectName
                  ],
                  /* [] */0
                ])
          ]));
    return Curry._2(positionChangeCb, rule$1.id, rule$1.position - 1 | 0);
  };
  var moveDown = function (_evt) {
    MetricsEvent$BsConsole.send(/* ActionWithAttrs */Block.__(1, [
            /* SourceCode_RuleChangePosition */87,
            Json_encode.object_(/* :: */[
                  /* tuple */[
                    "project",
                    projectName
                  ],
                  /* [] */0
                ])
          ]));
    return Curry._2(positionChangeCb, rule$1.id, rule$1.position + 1 | 0);
  };
  var adminControls = function (param) {
    if (TeamsUtils$BsConsole.isAdmin(config, projectName)) {
      return React.createElement(Col2$BsConsole.make, {
                  className: Css.style(/* :: */[
                        Css.height(Css.rem(2)),
                        /* :: */[
                          Css.justifyContent(/* center */98248149),
                          /* :: */[
                            Css.flex(/* `num */[
                                  5496390,
                                  1
                                ]),
                            /* [] */0
                          ]
                        ]
                      ]),
                  children: React.createElement(Row2$BsConsole.make, {
                        className: Css.style(/* :: */[
                              Css.alignSelf(/* flexEnd */924268066),
                              /* [] */0
                            ]),
                        children: null
                      }, React.createElement(Mui$BsConsole.IconButton.make, {
                            ariaLabel: "Delete",
                            classes: {
                              root: Css.style(/* :: */[
                                    Css.padding2(/* zero */-789508312, Css.rem(0.75)),
                                    /* [] */0
                                  ])
                            },
                            onClick: (function (param) {
                                var id = rule$1.id;
                                if (id !== undefined) {
                                  MetricsEvent$BsConsole.send(/* ActionWithAttrs */Block.__(1, [
                                          /* SourceCode_RuleDelete */88,
                                          Json_encode.object_(/* :: */[
                                                /* tuple */[
                                                  "project",
                                                  projectName
                                                ],
                                                /* [] */0
                                              ])
                                        ]));
                                  return Task2$BsConsole.handle(token, deleteRule(id, universeName, projectName, config), undefined, (function (_result) {
                                                return Curry._1(refetchRulesCb, undefined);
                                              }));
                                }
                                
                              }),
                            children: React.createElement(Icon.default, {
                                  children: I18N$BsConsole.showSkip("delete")
                                })
                          }), React.createElement(Mui$BsConsole.IconButton.make, {
                            ariaLabel: "Move up",
                            classes: {
                              root: Css.style(/* :: */[
                                    Css.padding2(/* zero */-789508312, Css.rem(0.75)),
                                    /* [] */0
                                  ])
                            },
                            onClick: moveUp,
                            children: React.createElement(ArrowUpward.default, { })
                          }), React.createElement(Mui$BsConsole.IconButton.make, {
                            ariaLabel: "Move down",
                            classes: {
                              root: Css.style(/* :: */[
                                    Css.padding2(/* zero */-789508312, Css.rem(0.75)),
                                    /* [] */0
                                  ])
                            },
                            onClick: moveDown,
                            children: React.createElement(ArrowDownward.default, { })
                          }))
                });
    } else {
      return null;
    }
  };
  var match$1 = rule$1.fieldType;
  var match$2 = rule$1.action;
  return React.createElement(Row2$BsConsole.make, {
              alignItems: /* flexEnd */924268066,
              flexWrap: /* wrap */-822134326,
              className: Css.style(/* :: */[
                    Css.marginBottom(Css.rem(1)),
                    /* :: */[
                      Css.width(Css.pct(100)),
                      /* [] */0
                    ]
                  ]),
              children: null
            }, React.createElement("div", {
                  className: Css.style(/* :: */[
                        Css.fontSize(Css.em(1)),
                        /* :: */[
                          Css.lineHeight(Css.em(2)),
                          /* :: */[
                            Css.marginRight(Css.rem(1)),
                            /* :: */[
                              Css.color(Css.hex(Colors$BsConsole.blackA54)),
                              /* [] */0
                            ]
                          ]
                        ]
                      ])
                }, I18N$BsConsole.show(undefined, "IF")), React.createElement(Col2$BsConsole.make, {
                  children: null
                }, React.createElement(InputLabel.default, {
                      classes: {
                        root: Css.style(/* :: */[
                              Css.marginTop(Css.px(8)),
                              /* :: */[
                                Css.marginBottom(Css.px(3)),
                                /* [] */0
                              ]
                            ])
                      },
                      children: React.createElement(BtTypography$BsConsole.make, {
                            variant: /* Caption */11,
                            children: I18N$BsConsole.show(undefined, "Type")
                          })
                    }), React.createElement(Select.default, {
                      value: rule$1.fieldType,
                      disabled: true,
                      onChange: (function (e, param) {
                          var value = e.target.value;
                          return Curry._1(setRule, (function (r) {
                                        return {
                                                id: r.id,
                                                position: r.position,
                                                matchingPerforceId: r.matchingPerforceId,
                                                fieldType: value,
                                                fieldName: r.fieldName,
                                                action: r.action,
                                                regex: r.regex,
                                                projectName: r.projectName,
                                                universeName: r.universeName
                                              };
                                      }));
                        }),
                      input: React.createElement(OutlinedInput.default, { }),
                      renderValue: (function (v) {
                          return I18N$BsConsole.showSkip(toFriendlyString(v));
                        }),
                      className: Css.style(/* :: */[
                            Css.backgroundColor(/* transparent */582626130),
                            /* :: */[
                              Css.paddingLeft(Css.rem(0.75)),
                              /* :: */[
                                Css.height(Css.rem(2)),
                                /* :: */[
                                  Css.width(Css.ch(17)),
                                  /* :: */[
                                    Css.marginRight(Css.rem(1)),
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ]
                          ]),
                      children: null
                    }, React.createElement(MenuItem.default, {
                          value: /* Attribute */3,
                          children: I18N$BsConsole.showSkip(I18N$BsConsole.get(undefined, "Attribute"))
                        }), React.createElement(MenuItem.default, {
                          value: /* FileName */0,
                          children: I18N$BsConsole.showSkip(I18N$BsConsole.get(undefined, "File name"))
                        }), React.createElement(MenuItem.default, {
                          value: /* FunctionName */1,
                          children: I18N$BsConsole.showSkip(I18N$BsConsole.get(undefined, "Function name"))
                        }), React.createElement(MenuItem.default, {
                          value: /* ObjectName */2,
                          children: I18N$BsConsole.showSkip(I18N$BsConsole.get(undefined, "Object name"))
                        }))), match$1 >= 3 ? React.createElement(Col2$BsConsole.make, {
                    children: null
                  }, React.createElement(InputLabel.default, {
                        classes: {
                          root: Css.style(/* :: */[
                                Css.marginTop(Css.px(8)),
                                /* :: */[
                                  Css.marginBottom(Css.px(3)),
                                  /* [] */0
                                ]
                              ])
                        },
                        children: React.createElement(BtTypography$BsConsole.make, {
                              variant: /* Caption */11,
                              children: I18N$BsConsole.show(undefined, "Attribute name")
                            })
                      }), React.createElement(TextField.default, {
                        disabled: true,
                        placeholder: I18N$BsConsole.get(undefined, "Attribute name"),
                        value: rule$1.fieldName,
                        color: "primary",
                        variant: "outlined",
                        classes: {
                          root: Css.style(/* :: */[
                                Css.width(Css.ch(20)),
                                /* :: */[
                                  Css.marginRight(Css.rem(1)),
                                  /* :: */[
                                    Css.selector("input", /* :: */[
                                          Css.important(Css.paddingTop(Css.px(6))),
                                          /* :: */[
                                            Css.important(Css.paddingBottom(Css.px(7))),
                                            /* [] */0
                                          ]
                                        ]),
                                    /* :: */[
                                      Css.selector("div[class*='multiline']", /* :: */[
                                            Css.important(Css.paddingTop(Css.px(10))),
                                            /* [] */0
                                          ]),
                                      /* [] */0
                                    ]
                                  ]
                                ]
                              ])
                        },
                        onChange: (function ($$event) {
                            return Curry._1(setRule, (function (r) {
                                          return {
                                                  id: r.id,
                                                  position: r.position,
                                                  matchingPerforceId: r.matchingPerforceId,
                                                  fieldType: r.fieldType,
                                                  fieldName: BtSettings$BsConsole.getInputValue($$event),
                                                  action: r.action,
                                                  regex: r.regex,
                                                  projectName: r.projectName,
                                                  universeName: r.universeName
                                                };
                                        }));
                          })
                      })) : null, React.createElement(Col2$BsConsole.make, {
                  children: null
                }, React.createElement(InputLabel.default, {
                      classes: {
                        root: Css.style(/* :: */[
                              Css.marginTop(Css.px(8)),
                              /* :: */[
                                Css.marginBottom(Css.px(3)),
                                /* [] */0
                              ]
                            ])
                      },
                      children: React.createElement(BtTypography$BsConsole.make, {
                            variant: /* Caption */11,
                            children: I18N$BsConsole.show(undefined, "Regex")
                          })
                    }), React.createElement(TextField.default, {
                      disabled: true,
                      placeholder: I18N$BsConsole.get(undefined, "Regex"),
                      value: rule$1.regex,
                      color: "primary",
                      variant: "outlined",
                      classes: {
                        root: Css.style(/* :: */[
                              rule$1.fieldType === /* Attribute */3 ? Css.width(Css.ch(20)) : Css.width(Css.ch(42)),
                              /* :: */[
                                Css.marginRight(Css.rem(1)),
                                /* :: */[
                                  Css.selector("input", /* :: */[
                                        Css.important(Css.paddingTop(Css.px(6))),
                                        /* :: */[
                                          Css.important(Css.paddingBottom(Css.px(7))),
                                          /* [] */0
                                        ]
                                      ]),
                                  /* :: */[
                                    Css.selector("div[class*='multiline']", /* :: */[
                                          Css.important(Css.paddingTop(Css.px(10))),
                                          /* [] */0
                                        ]),
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ])
                      },
                      onChange: (function ($$event) {
                          var re = BtSettings$BsConsole.getInputValue($$event);
                          return Curry._1(setRule, (function (r) {
                                        return {
                                                id: r.id,
                                                position: r.position,
                                                matchingPerforceId: r.matchingPerforceId,
                                                fieldType: r.fieldType,
                                                fieldName: r.fieldName,
                                                action: r.action,
                                                regex: re,
                                                projectName: r.projectName,
                                                universeName: r.universeName
                                              };
                                      }));
                        })
                    })), React.createElement("div", {
                  className: Css.style(/* :: */[
                        Css.fontSize(Css.em(1)),
                        /* :: */[
                          Css.lineHeight(Css.em(2)),
                          /* :: */[
                            Css.marginRight(Css.rem(1)),
                            /* :: */[
                              Css.color(Css.hex(Colors$BsConsole.blackA54)),
                              /* [] */0
                            ]
                          ]
                        ]
                      ])
                }, I18N$BsConsole.show(undefined, "THEN")), React.createElement(Col2$BsConsole.make, {
                  children: null
                }, React.createElement(InputLabel.default, {
                      classes: {
                        root: Css.style(/* :: */[
                              Css.marginTop(Css.px(8)),
                              /* :: */[
                                Css.marginBottom(Css.px(3)),
                                /* [] */0
                              ]
                            ])
                      },
                      children: React.createElement(BtTypography$BsConsole.make, {
                            variant: /* Caption */11,
                            children: I18N$BsConsole.show(undefined, "Action")
                          })
                    }), React.createElement(Select.default, {
                      value: rule$1.action,
                      disabled: true,
                      onChange: (function (e, param) {
                          var value = e.target.value;
                          return Curry._1(setRule, (function (r) {
                                        return {
                                                id: r.id,
                                                position: r.position,
                                                matchingPerforceId: r.matchingPerforceId,
                                                fieldType: r.fieldType,
                                                fieldName: r.fieldName,
                                                action: value,
                                                regex: r.regex,
                                                projectName: r.projectName,
                                                universeName: r.universeName
                                              };
                                      }));
                        }),
                      input: React.createElement(OutlinedInput.default, { }),
                      renderValue: (function (v) {
                          return I18N$BsConsole.showSkip(toFriendlyString$1(v));
                        }),
                      className: Css.style(/* :: */[
                            Css.height(Css.rem(2)),
                            /* :: */[
                              Css.backgroundColor(/* transparent */582626130),
                              /* :: */[
                                Css.paddingLeft(Css.rem(0.75)),
                                /* :: */[
                                  Css.width(Css.ch(21)),
                                  /* :: */[
                                    Css.marginRight(Css.rem(1)),
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ]
                          ]),
                      children: null
                    }, React.createElement(MenuItem.default, {
                          value: /* MatchStop */2,
                          children: I18N$BsConsole.showSkip(I18N$BsConsole.get(undefined, "Search only"))
                        }), React.createElement(MenuItem.default, {
                          value: /* FullStop */3,
                          children: I18N$BsConsole.showSkip(I18N$BsConsole.get(undefined, "Don't search"))
                        }))), match$2 >= 3 ? null : React.createElement(Col2$BsConsole.make, {
                    children: null
                  }, React.createElement(InputLabel.default, {
                        classes: {
                          root: Css.style(/* :: */[
                                Css.marginTop(Css.px(8)),
                                /* :: */[
                                  Css.marginBottom(Css.px(3)),
                                  /* [] */0
                                ]
                              ])
                        },
                        children: React.createElement(BtTypography$BsConsole.make, {
                              variant: /* Caption */11,
                              children: I18N$BsConsole.show(undefined, "Server")
                            })
                      }), React.createElement(Row2$BsConsole.make, {
                        alignItems: /* center */98248149,
                        children: React.createElement(Select.default, {
                              value: rule$1.matchingPerforceId,
                              disabled: true,
                              onChange: (function (e, param) {
                                  var value = e.target.value;
                                  return Curry._1(setRule, (function (r) {
                                                return {
                                                        id: r.id,
                                                        position: r.position,
                                                        matchingPerforceId: value,
                                                        fieldType: r.fieldType,
                                                        fieldName: r.fieldName,
                                                        action: r.action,
                                                        regex: r.regex,
                                                        projectName: r.projectName,
                                                        universeName: r.universeName
                                                      };
                                              }));
                                }),
                              input: React.createElement(OutlinedInput.default, { }),
                              renderValue: (function (id) {
                                  return I18N$BsConsole.showSkip(Belt_Option.mapWithDefault(getRepoNameFromId(id, servers), "", Util$BsConsole.identity));
                                }),
                              className: Css.style(/* :: */[
                                    Css.width(Css.ch(20)),
                                    /* :: */[
                                      Css.height(Css.rem(2)),
                                      /* :: */[
                                        Css.backgroundColor(/* transparent */582626130),
                                        /* :: */[
                                          Css.paddingLeft(Css.rem(0.75)),
                                          /* [] */0
                                        ]
                                      ]
                                    ]
                                  ]),
                              children: Belt_List.toArray(Belt_List.map(servers, (function (repo) {
                                          var name = getRepoNameFromId(repo.id, servers);
                                          if (name !== undefined) {
                                            return React.createElement(MenuItem.default, {
                                                        value: repo.id,
                                                        children: I18N$BsConsole.showSkip(name)
                                                      });
                                          } else {
                                            return null;
                                          }
                                        })))
                            })
                      })), adminControls(undefined));
}

var Rule = {
  make: PerforceRules$PerforceRule$Rule
};

function PerforceRules$PerforceRule$Rules(Props) {
  var token = Props.token;
  var config = Props.config;
  var projectName = Props.projectName;
  var universeName = Props.universeName;
  var rules = Props.rules;
  var refetchRulesCb = Props.refetchRulesCb;
  var servers = Props.servers;
  var positionChangeCb = function (id, newPos) {
    var newPos$1 = newPos < 0 ? 0 : newPos;
    var newPos$2 = newPos$1 > (Belt_List.length(rules) - 1 | 0) ? Belt_List.length(rules) - 1 | 0 : newPos$1;
    var origRule = Belt_List.getBy(rules, (function (r) {
            return Caml_obj.caml_equal(r.id, id);
          }));
    if (origRule === undefined) {
      return ;
    }
    var newRule_id = origRule.id;
    var newRule_matchingPerforceId = origRule.matchingPerforceId;
    var newRule_fieldType = origRule.fieldType;
    var newRule_fieldName = origRule.fieldName;
    var newRule_action = origRule.action;
    var newRule_regex = origRule.regex;
    var newRule_projectName = origRule.projectName;
    var newRule_universeName = origRule.universeName;
    var newRule = {
      id: newRule_id,
      position: newPos$2,
      matchingPerforceId: newRule_matchingPerforceId,
      fieldType: newRule_fieldType,
      fieldName: newRule_fieldName,
      action: newRule_action,
      regex: newRule_regex,
      projectName: newRule_projectName,
      universeName: newRule_universeName
    };
    return Task2$BsConsole.handle(token, saveRule(newRule, universeName, projectName, config), undefined, (function (param) {
                  return Curry._1(refetchRulesCb, undefined);
                }));
  };
  return React.createElement(Paper.default, {
              classes: {
                root: Css.style(/* :: */[
                      Css.padding(Css.rem(1.0)),
                      /* :: */[
                        Css.marginBottom(Css.rem(1.0)),
                        /* [] */0
                      ]
                    ])
              },
              children: React.createElement(Col2$BsConsole.make, {
                    children: Belt_List.toArray(Belt_List.map(Belt_List.sort(rules, (function (r, r$prime) {
                                    return r.position - r$prime.position | 0;
                                  })), (function (r) {
                                return Belt_Option.mapWithDefault(r.id, null, (function (id) {
                                              return React.createElement(PerforceRules$PerforceRule$Rule, {
                                                          config: config,
                                                          token: token,
                                                          projectName: projectName,
                                                          universeName: universeName,
                                                          refetchRulesCb: refetchRulesCb,
                                                          positionChangeCb: positionChangeCb,
                                                          rule: r,
                                                          servers: servers,
                                                          key: String(id) + String(r.position)
                                                        });
                                            }));
                              })))
                  })
            });
}

var Rules = {
  make: PerforceRules$PerforceRule$Rules
};

var PerforceRule = {
  initialState: initialState,
  reducer: reducer,
  NewRule: NewRule,
  Rule: Rule,
  Rules: Rules
};

function PerforceRules(Props) {
  var token = Props.token;
  var config = Props.config;
  var projectName = Props.projectName;
  var universeName = Props.universeName;
  var rules = Props.rules;
  var refetchRulesCb = Props.refetchRulesCb;
  var servers = Props.servers;
  var match = React.useState((function () {
          return false;
        }));
  var setShowRuleForm = match[1];
  var showRuleForm = match[0];
  var addRuleBtn = function (disabled) {
    return React.createElement(Button.default, {
                variant: "contained",
                color: "primary",
                disabled: disabled,
                classes: {
                  root: Css.style(/* :: */[
                        Css.marginBottom(Css.rem(1.5)),
                        /* :: */[
                          Css.display(showRuleForm ? /* none */-922086728 : /* unset */653193961),
                          /* [] */0
                        ]
                      ])
                },
                onClick: (function (param) {
                    return Curry._1(setShowRuleForm, (function (param) {
                                  return true;
                                }));
                  }),
                children: I18N$BsConsole.show(undefined, "Add a rule")
              });
  };
  return React.createElement(React.Fragment, undefined, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Heading4 */3,
                  className: Css.style(/* :: */[
                        Css.marginBottom(Css.rem(0.5)),
                        /* [] */0
                      ]),
                  color: Colors$BsConsole.grey1,
                  children: I18N$BsConsole.show(undefined, "Perforce rules")
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Body1 */8,
                  className: Css.style(/* :: */[
                        Css.marginBottom(Css.rem(1)),
                        /* [] */0
                      ]),
                  color: Colors$BsConsole.grey1,
                  children: I18N$BsConsole.show(undefined, "If you find cases where source code is pulled from the wrong server path, then you can create rules to point the system towards the correct server path. These rules are not required, but if they are present then they will execute at the beginning of the server path selection process. If none of the rules match, or if there are no rules, then the system will check every server path for matching source code.")
                }), React.createElement(BtSettings$BsConsole.FancyDivider.make, {
                  className: Css.style(/* :: */[
                        Css.margin2(Css.rem(1), /* zero */-789508312),
                        /* :: */[
                          Css.height(Css.px(4)),
                          /* [] */0
                        ]
                      ])
                }), React.createElement(Col2$BsConsole.make, {
                  children: null
                }, TeamsUtils$BsConsole.isAdmin(config, projectName) ? addRuleBtn(false) : React.createElement(Row2$BsConsole.make, {
                        alignItems: /* baseline */287825029,
                        children: null
                      }, addRuleBtn(true), React.createElement("span", {
                            className: Css.style(/* :: */[
                                  Css.marginLeft(Css.rem(0.5)),
                                  /* [] */0
                                ])
                          }, I18N$BsConsole.show(undefined, "Admin privileges for this project are required to perform this operation."))), React.createElement(Row2$BsConsole.make, {
                      justifyContent: /* flexStart */662439529,
                      flexWrap: /* wrap */-822134326,
                      children: null
                    }, showRuleForm ? React.createElement("div", {
                            className: Css.style(/* :: */[
                                  Css.marginBottom(Css.rem(1)),
                                  /* [] */0
                                ])
                          }, React.createElement(PerforceRules$PerforceRule$NewRule, {
                                config: config,
                                token: token,
                                projectName: projectName,
                                servers: servers,
                                universeName: universeName,
                                ruleCreatedCb: (function (param) {
                                    Curry._1(refetchRulesCb, undefined);
                                    return Curry._1(setShowRuleForm, (function (param) {
                                                  return false;
                                                }));
                                  }),
                                close: (function (param) {
                                    return Curry._1(setShowRuleForm, (function (param) {
                                                  return false;
                                                }));
                                  })
                              })) : null, Belt_List.length(rules) > 0 ? React.createElement(Col2$BsConsole.make, {
                            children: null
                          }, React.createElement(Row2$BsConsole.make, {
                                children: React.createElement(PerforceRules$PerforceRule$Rules, {
                                      token: token,
                                      config: config,
                                      projectName: projectName,
                                      universeName: universeName,
                                      rules: rules,
                                      refetchRulesCb: refetchRulesCb,
                                      servers: servers
                                    })
                              }), React.createElement(Paper.default, {
                                classes: {
                                  root: Css.style(/* :: */[
                                        Css.padding(Css.rem(1.0)),
                                        /* :: */[
                                          Css.marginBottom(Css.rem(1.0)),
                                          /* :: */[
                                            Css.width(Css.pct(100)),
                                            /* [] */0
                                          ]
                                        ]
                                      ])
                                },
                                children: React.createElement(Row2$BsConsole.make, {
                                      alignItems: /* baseline */287825029,
                                      children: null
                                    }, React.createElement("div", {
                                          className: Css.style(/* :: */[
                                                Css.fontSize(Css.em(1)),
                                                /* :: */[
                                                  Css.lineHeight(Css.em(2)),
                                                  /* :: */[
                                                    Css.marginRight(Css.rem(1)),
                                                    /* :: */[
                                                      Css.color(Css.hex(Colors$BsConsole.blackA54)),
                                                      /* [] */0
                                                    ]
                                                  ]
                                                ]
                                              ])
                                        }, I18N$BsConsole.show(undefined, "ELSE")), React.createElement("div", {
                                          className: Css.style(/* :: */[
                                                Css.fontSize(Css.em(1.1875)),
                                                /* :: */[
                                                  Css.lineHeight(Css.em(2)),
                                                  /* :: */[
                                                    Css.marginRight(Css.rem(1)),
                                                    /* :: */[
                                                      Css.color(Css.hex(Colors$BsConsole.blackA54)),
                                                      /* [] */0
                                                    ]
                                                  ]
                                                ]
                                              ])
                                        }, I18N$BsConsole.show(undefined, "Check every server for matching source code.")))
                              })) : React.createElement(React.Fragment, undefined, React.createElement(Paper.default, {
                                classes: {
                                  root: Css.style(/* :: */[
                                        Css.padding(Css.rem(1.0)),
                                        /* :: */[
                                          Css.marginBottom(Css.rem(1.0)),
                                          /* :: */[
                                            Css.width(Css.pct(100)),
                                            /* [] */0
                                          ]
                                        ]
                                      ])
                                },
                                children: React.createElement(Col2$BsConsole.make, {
                                      children: null
                                    }, React.createElement("div", {
                                          className: Css.style(/* :: */[
                                                Css.fontSize(Css.em(1.1875)),
                                                /* :: */[
                                                  Css.lineHeight(Css.em(2)),
                                                  /* :: */[
                                                    Css.marginRight(Css.rem(1)),
                                                    /* :: */[
                                                      Css.color(Css.hex(Colors$BsConsole.blackA54)),
                                                      /* [] */0
                                                    ]
                                                  ]
                                                ]
                                              ])
                                        }, I18N$BsConsole.show(undefined, "You don't have any rules set up yet.")), React.createElement("div", {
                                          className: Css.style(/* :: */[
                                                Css.fontSize(Css.em(1)),
                                                /* :: */[
                                                  Css.lineHeight(Css.em(2)),
                                                  /* :: */[
                                                    Css.marginRight(Css.rem(1)),
                                                    /* :: */[
                                                      Css.color(Css.hex(Colors$BsConsole.blackA54)),
                                                      /* [] */0
                                                    ]
                                                  ]
                                                ]
                                              ])
                                        }, I18N$BsConsole.show(undefined, "Backtrace will check all repositories for matching source code.")))
                              })))));
}

var make = PerforceRules;

exports.FieldType = FieldType;
exports.Action = Action;
exports.fromJson = fromJson;
exports.toJson = toJson;
exports.fetchRules = fetchRules;
exports.saveRule = saveRule;
exports.deleteRule = deleteRule;
exports.getRepoNameFromId = getRepoNameFromId;
exports.PerforceRule = PerforceRule;
exports.make = make;
/* Css Not a pure module */
