// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Gate$BsConsole = require("./Gate.js");

function addProjectPrefix(id, projectName) {
  var prefix = projectName.slice(0, 2).toUpperCase();
  return prefix + ("-" + id);
}

function shouldShowId(param) {
  var match = Gate$BsConsole.isAlpha(undefined);
  var match$1 = location.host;
  if (!match) {
    return false;
  }
  switch (match$1) {
    case "backtrace.sp.backtrace.io" :
    case "yolo.sp.backtrace.io" :
        return true;
    default:
      return false;
  }
}

exports.addProjectPrefix = addProjectPrefix;
exports.shouldShowId = shouldShowId;
/* Gate-BsConsole Not a pure module */
