// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var I18N$BsConsole = require("../I18N.js");
var Colors$BsConsole = require("../Colors.js");
var MetricsEvent$BsConsole = require("../bs-metrics/MetricsEvent.js");
var Switch = require("@material-ui/core/Switch");
var Tooltip = require("@material-ui/core/Tooltip");

function BugVariablesToggle(Props) {
  var isLocalVarsVisible = Props.isLocalVarsVisible;
  var toggleIsLocalVarsVisbile = Props.toggleIsLocalVarsVisbile;
  var handleOnChange = function (param, param$1) {
    MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* Debugger_ToggleVariables */114]));
    return Curry._1(toggleIsLocalVarsVisbile, undefined);
  };
  return React.createElement(Tooltip.default, {
              children: React.createElement("div", {
                    className: Css.style(/* :: */[
                          Css.display(/* flex */-1010954439),
                          /* :: */[
                            Css.alignItems(/* center */98248149),
                            /* :: */[
                              Css.flexShrink(0),
                              /* [] */0
                            ]
                          ]
                        ])
                  }, React.createElement("span", {
                        className: Css.style(/* :: */[
                              Css.textTransform(/* capitalize */-509867604),
                              /* :: */[
                                Css.color(Css.hex(Colors$BsConsole.green)),
                                /* :: */[
                                  Css.fontWeight(Css.normal),
                                  /* :: */[
                                    Css.fontSize(Css.px(12)),
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ])
                      }, I18N$BsConsole.show(undefined, "Show Variables")), React.createElement(Switch.default, {
                        classes: {
                          switchBase: Css.style(/* :: */[
                                Css.height(Css.px(32)),
                                /* [] */0
                              ])
                        },
                        onChange: handleOnChange,
                        disableRipple: true,
                        checked: isLocalVarsVisible
                      })),
              title: "Show variables"
            });
}

var make = BugVariablesToggle;

exports.make = make;
/* Css Not a pure module */
