// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Mui$BsConsole = require("../bindings/Mui.js");
var Crdb$BsConsole = require("../crdb.js");
var I18N$BsConsole = require("../I18N.js");
var Colors$BsConsole = require("../Colors.js");
var Close = require("@material-ui/icons/Close");

var filterActionButton = Css.style(/* :: */[
      Css.important(Css.color(Css.hex(Colors$BsConsole.white))),
      /* :: */[
        Css.height(Css.px(42)),
        /* [] */0
      ]
    ]);

function ClearFiltersButton(Props) {
  var aperture = Props.aperture;
  var filterArray = Props.filterArray;
  var isFilterOpen = Props.isFilterOpen;
  var onChange = Props.onChange;
  var filterContainerRef = Props.filterContainerRef;
  var autoFocus = Props.autoFocus;
  if (filterArray.length !== 0) {
    return React.createElement(Mui$BsConsole.IconButtonWithTooltipJsx3.make, {
                ariaLabel: "Clear all filters",
                classes: {
                  root: filterActionButton
                },
                onClick: (function (e) {
                    e.stopPropagation();
                    Curry._2(onChange, Curry._2(Crdb$BsConsole.Aperture.setFilters, Crdb$BsConsole.Filters.empty, aperture), Crdb$BsConsole.Havings.empty);
                    if (isFilterOpen && filterContainerRef !== undefined && autoFocus !== undefined) {
                      return Curry._1(autoFocus, filterContainerRef.current);
                    }
                    
                  }),
                onFocus: (function (e) {
                    return e.stopPropagation();
                  }),
                title_tooltip: I18N$BsConsole.get(undefined, "Clear all filters"),
                children: React.createElement(Close.default, { })
              });
  } else {
    return null;
  }
}

var make = ClearFiltersButton;

exports.filterActionButton = filterActionButton;
exports.make = make;
/* filterActionButton Not a pure module */
