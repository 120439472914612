// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_int32 = require("bs-platform/lib/js/caml_int32.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var Crdb$BsConsole = require("../crdb.js");
var I18N$BsConsole = require("../I18N.js");
var Colors$BsConsole = require("../Colors.js");
var Spread$BsConsole = require("../Spread.js");
var BugLink$BsConsole = require("./BugLink.js");
var BugTasks$BsConsole = require("./data/BugTasks.js");
var Icon = require("@material-ui/core/Icon");
var RouterContext$BsConsole = require("../context/RouterContext.js");
var ProjectNameContext$BsConsole = require("../context/ProjectNameContext.js");

var arrowRoot = Css.style(/* :: */[
      Css.unsafe("color", "inherit"),
      /* [] */0
    ]);

var arrowDisabled = Css.style(/* :: */[
      Css.opacity(0.55),
      /* [] */0
    ]);

var pagingContainer = Css.merge(/* :: */[
      "walkthrough--debugger--pagination-controls",
      /* :: */[
        Css.style(/* :: */[
              Css.display(/* flex */-1010954439),
              /* :: */[
                Css.alignItems(/* center */98248149),
                /* :: */[
                  Css.gridGap(Css.px(4)),
                  /* [] */0
                ]
              ]
            ]),
        /* [] */0
      ]
    ]);

var arrowButton = Css.style(/* :: */[
      Css.width(Css.rem(1.25)),
      /* :: */[
        Css.height(Css.rem(1.25)),
        /* :: */[
          Css.backgroundColor(Css.hex(Colors$BsConsole.Debugger.white1)),
          /* :: */[
            Css.border(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.Debugger.grey3)),
            /* :: */[
              Css.borderRadius(Css.px(5)),
              /* :: */[
                Css.padding(/* zero */-789508312),
                /* :: */[
                  Css.selector(":focus-visible", /* :: */[
                        Css.outline(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.green)),
                        /* [] */0
                      ]),
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var muiIcon = Css.style(/* :: */[
      Css.fontSize(Css.rem(0.875)),
      /* :: */[
        Css.color(Css.hex(Colors$BsConsole.Debugger.green1)),
        /* [] */0
      ]
    ]);

var Style = {
  arrowRoot: arrowRoot,
  arrowDisabled: arrowDisabled,
  pagingContainer: pagingContainer,
  arrowButton: arrowButton,
  muiIcon: muiIcon
};

function BugPagination(Props) {
  var hasErrorFilter = Props.hasErrorFilter;
  var errorsCount = Props.errorsCount;
  var errorIndex = Props.errorIndex;
  var setErrorIndex = Props.setErrorIndex;
  var aperture = Props.aperture;
  var projectName = React.useContext(ProjectNameContext$BsConsole.ctx);
  var match = React.useContext(RouterContext$BsConsole.ctx);
  var handleReplaceUrl = match.handleReplaceUrl;
  var errors = BugTasks$BsConsole.useErrors(aperture, errorIndex / 20 | 0);
  var tmp;
  if (hasErrorFilter) {
    if (typeof errors === "number" || errors.tag) {
      tmp = null;
    } else {
      var match$1 = errors[0][0][0];
      if (match$1.length !== 1) {
        tmp = null;
      } else {
        var error = match$1[0];
        var route_001 = {
          aperture: Curry._2(Crdb$BsConsole.Aperture.setFilters, Curry._2(Crdb$BsConsole.Filters.add, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
                        "fingerprint",
                        /* Equal */Block.__(0, [/* `String */[
                              -976970511,
                              error.fingerprint
                            ]])
                      ]), Crdb$BsConsole.Filters.empty), Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, /* Relative */Block.__(0, [
                      /* Floating */0,
                      /* All */6
                    ]), Crdb$BsConsole.Aperture.$$default)),
          state: undefined
        };
        var route = /* ProjectDebugger2 */Block.__(19, [
            projectName,
            route_001
          ]);
        tmp = React.createElement(BugLink$BsConsole.make, {
              route: route,
              handleChangeUrl: match.handleChangeUrl,
              className: Css.style(/* :: */[
                    Css.fontSize(Css.px(14)),
                    /* [] */0
                  ]),
              children: I18N$BsConsole.show(undefined, "View all errors in fingerprint")
            });
      }
    }
  } else if (errorsCount !== undefined) {
    if (errorsCount !== 0) {
      var idx = errorIndex + 1 | 0;
      tmp = React.createElement("div", {
            className: pagingContainer
          }, React.createElement(Spread$BsConsole.make, {
                props: {
                  "aria-label": I18N$BsConsole.get(undefined, "Go to previous error")
                },
                children: React.createElement(Mui$BsConsole.IconButton.make, {
                      disableRipple: true,
                      ariaLabel: "Go to previous error",
                      disabled: errorIndex === 0,
                      classes: {
                        root: arrowRoot,
                        disabled: arrowDisabled
                      },
                      className: arrowButton,
                      onClick: (function (param) {
                          return Curry._1(setErrorIndex, (function (errorIndex) {
                                        Curry._1(handleReplaceUrl, /* ProjectDebugger2 */Block.__(19, [
                                                projectName,
                                                {
                                                  aperture: aperture,
                                                  state: undefined
                                                }
                                              ]));
                                        return Caml_int32.mod_((errorIndex - 1 | 0) + errorsCount | 0, errorsCount);
                                      }));
                        }),
                      children: React.createElement(Icon.default, {
                            className: Css.merge(/* :: */[
                                  muiIcon,
                                  /* :: */[
                                    Css.style(/* :: */[
                                          Css.unsafe("transform", "scaleX(-1)"),
                                          /* [] */0
                                        ]),
                                    /* [] */0
                                  ]
                                ]),
                            children: I18N$BsConsole.show(undefined, "arrow_forward_ios")
                          })
                    })
              }), React.createElement("div", undefined, I18N$BsConsole.showSkip("" + (String(idx) + ("/" + (String(errorsCount) + ""))))), React.createElement(Spread$BsConsole.make, {
                props: {
                  "aria-label": I18N$BsConsole.get(undefined, "Go to next error")
                },
                children: React.createElement(Mui$BsConsole.IconButton.make, {
                      disableRipple: true,
                      ariaLabel: "Go to next error",
                      classes: {
                        root: arrowRoot,
                        disabled: arrowDisabled,
                        "focus-visible": Css.style(/* :: */[
                              Css.backgroundColor(Css.hex(Colors$BsConsole.blue)),
                              /* [] */0
                            ])
                      },
                      className: arrowButton,
                      onClick: (function (param) {
                          return Curry._1(setErrorIndex, (function (errorIndex) {
                                        Curry._1(handleReplaceUrl, /* ProjectDebugger2 */Block.__(19, [
                                                projectName,
                                                {
                                                  aperture: aperture,
                                                  state: undefined
                                                }
                                              ]));
                                        return Caml_int32.mod_((errorIndex + 1 | 0) + errorsCount | 0, errorsCount);
                                      }));
                        }),
                      children: React.createElement(Icon.default, {
                            className: Css.merge(/* :: */[
                                  muiIcon,
                                  /* :: */[
                                    errorIndex !== 0 ? Css.style(/* :: */[
                                            Css.color(Css.hex(Colors$BsConsole.Debugger.green1)),
                                            /* [] */0
                                          ]) : "",
                                    /* [] */0
                                  ]
                                ]),
                            children: I18N$BsConsole.show(undefined, "arrow_forward_ios")
                          })
                    })
              }));
    } else {
      tmp = React.createElement("div", {
            className: Css.style(/* :: */[
                  Css.paddingRight(Css.px(16)),
                  /* [] */0
                ])
          }, I18N$BsConsole.show(undefined, "No errors"));
    }
  } else {
    tmp = null;
  }
  return React.createElement(React.Fragment, undefined, tmp);
}

var make = BugPagination;

exports.Style = Style;
exports.make = make;
/* arrowRoot Not a pure module */
