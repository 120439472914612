// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Colors$BsConsole = require("../Colors.js");

var container = Css.style(/* :: */[
      Css.height(Css.px(24)),
      /* :: */[
        Css.marginTop(Css.rem(0.5)),
        /* :: */[
          Css.marginLeft(Css.rem(1)),
          /* [] */0
        ]
      ]
    ]);

var text = Css.style(/* :: */[
      Css.fontSize(Css.px(14)),
      /* :: */[
        Css.fontWeight(/* `num */[
              5496390,
              500
            ]),
        /* :: */[
          Css.lineHeight(Css.px(24)),
          /* [] */0
        ]
      ]
    ]);

var divider = Css.merge(/* :: */[
      text,
      /* :: */[
        Css.style(/* :: */[
              Css.color(Css.hex(Colors$BsConsole.grey4)),
              /* :: */[
                Css.margin2(Css.px(0), Css.px(1)),
                /* [] */0
              ]
            ]),
        /* [] */0
      ]
    ]);

var accentColor = Css.color(Css.hex(Colors$BsConsole.accent));

var iconButton = Css.style(/* :: */[
      accentColor,
      /* :: */[
        Css.fontSize(Css.px(18)),
        /* :: */[
          Css.height(Css.px(18)),
          /* :: */[
            Css.width(Css.px(18)),
            /* [] */0
          ]
        ]
      ]
    ]);

var crumb = Css.merge(/* :: */[
      text,
      /* :: */[
        Css.style(/* :: */[
              Css.padding2(Css.px(0), Css.px(1)),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var crumbWithLink = Css.merge(/* :: */[
      crumb,
      /* :: */[
        Css.style(/* :: */[
              accentColor,
              /* [] */0
            ]),
        /* :: */[
          "crumb-link",
          /* [] */0
        ]
      ]
    ]);

var link = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* row */5693978),
        /* :: */[
          Css.justifyContent(/* center */98248149),
          /* :: */[
            Css.alignItems(/* center */98248149),
            /* :: */[
              Css.borderRadius(Css.px(7)),
              /* :: */[
                Css.textDecoration(/* none */-922086728),
                /* :: */[
                  Css.color(Css.hex(Colors$BsConsole.accent)),
                  /* :: */[
                    Css.hover(/* :: */[
                          Css.selector(".crumb-link", /* :: */[
                                Css.textDecoration(/* underline */131142924),
                                /* [] */0
                              ]),
                          /* [] */0
                        ]),
                    /* [] */0
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

exports.container = container;
exports.text = text;
exports.divider = divider;
exports.accentColor = accentColor;
exports.iconButton = iconButton;
exports.crumb = crumb;
exports.crumbWithLink = crumbWithLink;
exports.link = link;
/* container Not a pure module */
