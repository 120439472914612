// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var Col2$BsConsole = require("../components/Col2.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Colors$BsConsole = require("../Colors.js");
var AppBadge$BsConsole = require("../AppBadge.js");
var BugUtils$BsConsole = require("./data/BugUtils.js");
var MuiIcons$BsConsole = require("../MuiIcons.js");
var MetricsEvent$BsConsole = require("../bs-metrics/MetricsEvent.js");
var Help = require("@material-ui/icons/Help");
var Warning = require("@material-ui/icons/Warning");

function BugCallstackFrameRow$HasDefectsIcon(Props) {
  return ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.FiberSmartRecord.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []));
}

var HasDefectsIcon = {
  make: BugCallstackFrameRow$HasDefectsIcon
};

function BugCallstackFrameRow$Default(Props) {
  var isSelected = Props.isSelected;
  var isFocused = Props.isFocused;
  var isFaulted = Props.isFaulted;
  var onClick = Props.onClick;
  var onDefectIconClick = Props.onDefectIconClick;
  var index = Props.index;
  var isAppFrame = Props.isAppFrame;
  var highlightAppFrames = Props.highlightAppFrames;
  var functionName = Props.functionName;
  var missingSymbolsOpt = Props.missingSymbols;
  var guessedFrameOpt = Props.guessedFrame;
  var inaccurateUnwindingOpt = Props.inaccurateUnwinding;
  var path = Props.path;
  var line = Props.line;
  var column = Props.column;
  var hasDefectsOpt = Props.hasDefects;
  var missingSymbols = missingSymbolsOpt !== undefined ? missingSymbolsOpt : false;
  var guessedFrame = guessedFrameOpt !== undefined ? guessedFrameOpt : false;
  var inaccurateUnwinding = inaccurateUnwindingOpt !== undefined ? inaccurateUnwindingOpt : false;
  var hasDefects = hasDefectsOpt !== undefined ? hasDefectsOpt : false;
  var match = React.useState((function () {
          return false;
        }));
  var setIsHovered = match[1];
  var isHovered = match[0];
  var shouldHighlightAppFrame = highlightAppFrames && isAppFrame;
  var place = line !== undefined ? (
      column !== undefined ? " " + (line + (":" + column)) : ":" + line
    ) : undefined;
  var pathStr = path !== undefined ? (
      place !== undefined ? path + place : path
    ) : undefined;
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.display(/* flex */-1010954439),
                    /* :: */[
                      Css.flexDirection(/* row */5693978),
                      /* :: */[
                        Css.flexGrow(0),
                        /* :: */[
                          Css.flexShrink(1),
                          /* :: */[
                            Css.alignItems(/* flexStart */662439529),
                            /* :: */[
                              Css.justifyContent(/* flexStart */662439529),
                              /* :: */[
                                Css.alignContent(/* flexStart */662439529),
                                /* :: */[
                                  Css.flexWrap(/* nowrap */867913355),
                                  /* :: */[
                                    Css.cursor(/* pointer */-786317123),
                                    /* :: */[
                                      Css.minHeight(Css.px(33)),
                                      /* :: */[
                                        Css.alignItems(Css.center),
                                        /* :: */[
                                          Css.width(Css.pct(100)),
                                          /* :: */[
                                            Css.background(isSelected ? (
                                                    isFocused ? Css.hex(Colors$BsConsole.tealExtraLight) : Css.hex(Colors$BsConsole.blackA10)
                                                  ) : (
                                                    isHovered ? Css.hex(Colors$BsConsole.blackA04) : Css.hex(Colors$BsConsole.white)
                                                  )),
                                            /* :: */[
                                              Css.color(isFaulted ? Css.hex(Colors$BsConsole.npLightRed) : (
                                                      inaccurateUnwinding ? Css.hex(Colors$BsConsole.grey4) : Css.hex(Colors$BsConsole.grey2)
                                                    )),
                                              /* [] */0
                                            ]
                                          ]
                                        ]
                                      ]
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]),
              onClick: onClick,
              onMouseEnter: (function (param) {
                  return Curry._1(setIsHovered, (function (param) {
                                return true;
                              }));
                }),
              onMouseLeave: (function (param) {
                  return Curry._1(setIsHovered, (function (param) {
                                return false;
                              }));
                })
            }, React.createElement(Row2$BsConsole.make, {
                  className: Css.style(/* :: */[
                        Css.width(Css.pct(100)),
                        /* [] */0
                      ]),
                  children: null
                }, React.createElement("div", {
                      className: Css.style(/* :: */[
                            Css.paddingRight(Css.px(8)),
                            /* :: */[
                              Css.paddingLeft(Css.px(20)),
                              /* :: */[
                                Css.minWidth(Css.px(26)),
                                /* :: */[
                                  Css.textAlign(/* right */-379319332),
                                  /* [] */0
                                ]
                              ]
                            ]
                          ])
                    }, missingSymbols ? React.createElement("span", {
                            "aria-label": "missing symbols",
                            className: Css.style(/* :: */[
                                  Css.fontSize(Css.px(12)),
                                  /* :: */[
                                    Css.height(Css.px(12)),
                                    /* :: */[
                                      Css.width(Css.px(12)),
                                      /* :: */[
                                        Css.color(Css.hex(Colors$BsConsole.orange)),
                                        /* [] */0
                                      ]
                                    ]
                                  ]
                                ])
                          }, React.createElement(Warning.default, {
                                color: "inherit",
                                fontSize: "inherit"
                              })) : (
                        guessedFrame ? React.createElement("span", {
                                "aria-label": BugUtils$BsConsole.missingCfiText,
                                className: Css.style(/* :: */[
                                      Css.fontSize(Css.px(12)),
                                      /* :: */[
                                        Css.height(Css.px(12)),
                                        /* :: */[
                                          Css.width(Css.px(12)),
                                          /* :: */[
                                            Css.color(Css.hex(Colors$BsConsole.teal)),
                                            /* [] */0
                                          ]
                                        ]
                                      ]
                                    ])
                              }, React.createElement(Help.default, {
                                    color: "inherit",
                                    fontSize: "inherit"
                                  })) : I18N$BsConsole.showSkip(String(index + 1 | 0))
                      )), React.createElement(Col2$BsConsole.make, {
                      className: Css.style(/* :: */[
                            Css.minWidth(Css.zero),
                            /* :: */[
                              Css.paddingRight(Css.px(8)),
                              /* [] */0
                            ]
                          ]),
                      children: null
                    }, React.createElement("div", {
                          className: Css.style(/* :: */[
                                Css.whiteSpace(Css.nowrap),
                                /* :: */[
                                  Css.textOverflow(Css.ellipsis),
                                  /* :: */[
                                    Css.overflow(Css.hidden),
                                    /* :: */[
                                      Css.width(Css.pct(100)),
                                      /* :: */[
                                        Css.color(highlightAppFrames ? (
                                                isAppFrame ? Css.hex(Colors$BsConsole.blackA87) : Css.hex(Colors$BsConsole.grey95A45)
                                              ) : Css.currentColor),
                                        /* [] */0
                                      ]
                                    ]
                                  ]
                                ]
                              ])
                        }, inaccurateUnwinding ? React.createElement(React.Fragment, undefined, React.createElement("span", {
                                    className: Css.style(/* :: */[
                                          Css.userSelect(Css.none),
                                          /* [] */0
                                        ])
                                  }, I18N$BsConsole.showSkip("<")), I18N$BsConsole.showSkip(functionName), React.createElement("span", {
                                    className: Css.style(/* :: */[
                                          Css.userSelect(Css.none),
                                          /* [] */0
                                        ])
                                  }, I18N$BsConsole.showSkip(">"))) : I18N$BsConsole.showSkip(functionName)), React.createElement("div", {
                          className: Css.style(/* :: */[
                                Css.fontSize(Css.px(10)),
                                /* :: */[
                                  Css.color(Css.hex(Colors$BsConsole.grey4)),
                                  /* :: */[
                                    Css.width(Css.pct(100)),
                                    /* :: */[
                                      Css.overflow(Css.hidden),
                                      /* :: */[
                                        Css.whiteSpace(Css.nowrap),
                                        /* :: */[
                                          Css.textOverflow(Css.ellipsis),
                                          /* :: */[
                                            Css.textAlign(/* left */-944764921),
                                            /* :: */[
                                              Css.direction(Css.rtl),
                                              /* [] */0
                                            ]
                                          ]
                                        ]
                                      ]
                                    ]
                                  ]
                                ]
                              ])
                        }, pathStr !== undefined ? React.createElement("span", {
                                dangerouslySetInnerHTML: {
                                  __html: "&#x200E;" + pathStr
                                }
                              }) : null)), isHovered && shouldHighlightAppFrame ? React.createElement(Col2$BsConsole.make, {
                        className: Css.style(/* :: */[
                              Css.minWidth(Css.px(35)),
                              /* :: */[
                                Css.marginLeft(Css.px(5)),
                                /* [] */0
                              ]
                            ]),
                        children: React.createElement(AppBadge$BsConsole.make, { })
                      }) : null, hasDefects ? React.createElement(Mui$BsConsole.IconButtonWithTooltipJsx3.make, {
                        ariaLabel: "View defects",
                        classes: {
                          root: Css.merge(/* :: */[
                                "smaller-icon",
                                /* :: */[
                                  Css.style(/* :: */[
                                        Css.marginTop(Css.px(2)),
                                        /* :: */[
                                          Css.marginRight(Css.px(15)),
                                          /* :: */[
                                            Css.color(Css.hex(Colors$BsConsole.accent)),
                                            /* [] */0
                                          ]
                                        ]
                                      ]),
                                  /* [] */0
                                ]
                              ])
                        },
                        onClick: (function ($$event) {
                            if (onDefectIconClick !== undefined) {
                              $$event.stopPropagation();
                              MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* Debugger_FrameRowDefectClick */104]));
                              return Curry._1(onDefectIconClick, undefined);
                            }
                            
                          }),
                        title_tooltip: I18N$BsConsole.get(undefined, "View defects"),
                        children: React.createElement(BugCallstackFrameRow$HasDefectsIcon, { })
                      }) : null));
}

var Default = {
  make: BugCallstackFrameRow$Default
};

var make = BugCallstackFrameRow$Default;

exports.HasDefectsIcon = HasDefectsIcon;
exports.Default = Default;
exports.make = make;
/* Css Not a pure module */
