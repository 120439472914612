// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var I18N$BsConsole = require("./I18N.js");
var Link$BsConsole = require("./Link.js");
var Row2$BsConsole = require("./components/Row2.js");
var Colors$BsConsole = require("./Colors.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var Services$BsConsole = require("./Services.js");
var SauceColors$BsConsole = require("./SauceColors.js");
var GetNextRoute$BsConsole = require("./GetNextRoute.js");

var divider = Css.style(/* :: */[
      Css.height(Css.px(30)),
      /* :: */[
        Css.width(Css.rem(0.03)),
        /* :: */[
          Css.backgroundColor(Css.hex(Colors$BsConsole.grey4)),
          /* [] */0
        ]
      ]
    ]);

var dividerLeft = Css.merge(/* :: */[
      divider,
      /* :: */[
        Css.style(/* :: */[
              Css.marginLeft(Css.px(8)),
              /* :: */[
                Css.marginRight(Css.px(8)),
                /* [] */0
              ]
            ]),
        /* [] */0
      ]
    ]);

var dividerRight = Css.merge(/* :: */[
      divider,
      /* :: */[
        Css.style(/* :: */[
              Css.marginLeft(Css.px(8)),
              /* :: */[
                Css.marginRight(Css.px(1)),
                /* [] */0
              ]
            ]),
        /* [] */0
      ]
    ]);

var navLink = Css.style(/* :: */[
      Css.minWidth(Css.px(80)),
      /* :: */[
        Css.height(Css.px(64)),
        /* :: */[
          Css.display(/* flex */-1010954439),
          /* :: */[
            Css.justifyContent(/* center */98248149),
            /* :: */[
              Css.alignItems(/* center */98248149),
              /* :: */[
                Css.hover(/* :: */[
                      Css.important(Css.color(Css.hex(SauceColors$BsConsole.green))),
                      /* [] */0
                    ]),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var navLinkTab = Css.style(/* :: */[
      Css.fontSize(Css.px(14)),
      /* :: */[
        Css.fontWeight(/* `num */[
              5496390,
              600
            ]),
        /* [] */0
      ]
    ]);

function getTabValueFromRoute(route) {
  if (typeof route === "number") {
    return /* Triage */2;
  }
  switch (route.tag | 0) {
    case /* ProjectOverview */8 :
        return /* Overview */0;
    case /* ProjectCompareReleases */9 :
        return /* Releases */1;
    case /* ProjectTriage */11 :
        return /* Triage */2;
    case /* ProjectDebugger2 */19 :
        return /* Debug */4;
    case /* ProjectQueryBuilder */13 :
    case /* ProjectInstances */15 :
    case /* ProjectFlamegraph */20 :
    case /* ProjectSimilarity */21 :
        return /* Explore */3;
    default:
      return /* Triage */2;
  }
}

function isActive(route, tabs) {
  var isActive$1 = Belt_Array.keep(tabs, (function (tab) {
          return getTabValueFromRoute(route) === tab;
        })).length !== 0;
  if (isActive$1) {
    return Css.style(/* :: */[
                Css.important(Css.color(Css.hex(SauceColors$BsConsole.green))),
                /* :: */[
                  Css.important(Css.borderBottom(Css.px(3), /* solid */12956715, Css.hex(SauceColors$BsConsole.green))),
                  /* [] */0
                ]
              ]);
  } else {
    return Css.style(/* :: */[
                Css.important(Css.color(Css.hex(Colors$BsConsole.grey400))),
                /* :: */[
                  Css.important(Css.borderBottom(Css.px(3), /* solid */12956715, /* transparent */582626130)),
                  /* [] */0
                ]
              ]);
  }
}

var Style = {
  divider: divider,
  dividerLeft: dividerLeft,
  dividerRight: dividerRight,
  navLink: navLink,
  navLinkTab: navLinkTab,
  getTabValueFromRoute: getTabValueFromRoute,
  isActive: isActive
};

function NavLinks(Props) {
  var shell = Props.shell;
  var route = Props.route;
  var projectName = Props.projectName;
  var aperture = Props.aperture;
  var handleChangeUrl = Props.handleChangeUrl;
  var tmp;
  if (typeof shell === "number" || shell.tag) {
    tmp = null;
  } else {
    var overviewEndpoint = Services$BsConsole.getEndpoint(undefined)(shell[0].services, "overview");
    tmp = Belt_Option.isSome(overviewEndpoint) ? React.createElement(Link$BsConsole.Jsx3.make, {
            route: GetNextRoute$BsConsole.overview(projectName, aperture),
            handleChangeUrl: handleChangeUrl,
            className: Css.merge(/* :: */[
                  navLink,
                  /* :: */[
                    isActive(route, [
                          /* Overview */0,
                          /* Releases */1
                        ]),
                    /* :: */[
                      "walkthrough--mode_tab--overview",
                      /* [] */0
                    ]
                  ]
                ]),
            children: React.createElement("span", {
                  className: navLinkTab
                }, I18N$BsConsole.show(undefined, "Overview"))
          }) : null;
  }
  return React.createElement(Row2$BsConsole.make, {
              alignItems: /* center */98248149,
              justifyContent: /* center */98248149,
              children: null
            }, React.createElement("span", {
                  className: dividerLeft
                }), tmp, React.createElement(Link$BsConsole.Jsx3.make, {
                  route: GetNextRoute$BsConsole.triage(projectName, route, aperture, true),
                  handleChangeUrl: handleChangeUrl,
                  className: Css.merge(/* :: */[
                        navLink,
                        /* :: */[
                          isActive(route, [/* Triage */2]),
                          /* :: */[
                            "walkthrough--mode_tab--triage",
                            /* [] */0
                          ]
                        ]
                      ]),
                  children: React.createElement("span", {
                        className: navLinkTab
                      }, I18N$BsConsole.show(undefined, "Triage"))
                }), React.createElement(Link$BsConsole.Jsx3.make, {
                  route: GetNextRoute$BsConsole.explore(projectName, route, aperture, true),
                  handleChangeUrl: handleChangeUrl,
                  className: Css.merge(/* :: */[
                        navLink,
                        /* :: */[
                          isActive(route, [/* Explore */3]),
                          /* :: */[
                            "walkthrough--mode_tab--explore",
                            /* [] */0
                          ]
                        ]
                      ]),
                  children: React.createElement("span", {
                        className: navLinkTab
                      }, I18N$BsConsole.show(undefined, "Explore"))
                }), React.createElement("span", {
                  className: dividerRight
                }));
}

function make(shell, route, projectName, aperture, handleChangeUrl, _children) {
  return ReasonReactCompat.wrapReactForReasonReact(NavLinks, {
              shell: shell,
              route: route,
              projectName: projectName,
              aperture: aperture,
              handleChangeUrl: handleChangeUrl
            }, _children);
}

var Jsx2 = {
  make: make
};

var make$1 = NavLinks;

exports.Style = Style;
exports.make = make$1;
exports.Jsx2 = Jsx2;
/* divider Not a pure module */
