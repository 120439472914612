// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var I18N$BsConsole = require("./I18N.js");
var Util$BsConsole = require("./util.js");
var Colors$BsConsole = require("./Colors.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var HelpOutline = require("@material-ui/icons/HelpOutline");

var iconButton = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.white)),
      /* [] */0
    ]);

var Styles = {
  iconButton: iconButton
};

function HelpAndSupportIcon(Props) {
  var classNameOpt = Props.className;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return React.createElement(Mui$BsConsole.IconButtonWithTooltipJsx3.make, {
              ariaLabel: "Help & support",
              className: Css.merge(/* :: */[
                    iconButton,
                    /* :: */[
                      className,
                      /* [] */0
                    ]
                  ]),
              onClick: (function (_event) {
                  return Util$BsConsole.openUrl("https://support.saucelabs.com/s/submit-a-request?language=en_US");
                }),
              placement_tooltip: "bottom",
              title_tooltip: I18N$BsConsole.get(undefined, "Help & support"),
              children: React.createElement(HelpOutline.default, { })
            });
}

function make(className, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  return ReasonReactCompat.wrapReactForReasonReact(HelpAndSupportIcon, tmp, children);
}

var Jsx2 = {
  make: make
};

var make$1 = HelpAndSupportIcon;

exports.Styles = Styles;
exports.make = make$1;
exports.Jsx2 = Jsx2;
/* iconButton Not a pure module */
