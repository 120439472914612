// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var ReactWindow = require("react-window");
var Mui$BsConsole = require("../bindings/Mui.js");
var Col2$BsConsole = require("../components/Col2.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Util$BsConsole = require("../util.js");
var Colors$BsConsole = require("../Colors.js");
var Morgue$BsConsole = require("../bs-crdb-response/src/Morgue.js");
var BugStyles$BsConsole = require("./BugStyles.js");
var BugTabCount$BsConsole = require("./BugTabCount.js");
var MetricsEvent$BsConsole = require("../bs-metrics/MetricsEvent.js");
var Select = require("@material-ui/core/Select");
var Switch = require("@material-ui/core/Switch");
var Divider = require("@material-ui/core/Divider");
var Tooltip = require("@material-ui/core/Tooltip");
var Collapse = require("@material-ui/core/Collapse");
var MenuItem = require("@material-ui/core/MenuItem");
var BugVariablesToggle$BsConsole = require("./BugVariablesToggle.js");
var Assignment = require("@material-ui/icons/Assignment");
var CallstackCopyButton$BsConsole = require("../CallstackCopyButton.js");
var BugCallstackFrameRow$BsConsole = require("./BugCallstackFrameRow.js");
var BugInteractiveTooltip$BsConsole = require("./BugInteractiveTooltip.js");
var KeyboardArrowDown = require("@material-ui/icons/KeyboardArrowDown");

function BugBttCallstack$SignalInfo(Props) {
  var signal = Props.signal;
  var match = React.useState((function () {
          return false;
        }));
  var setShowSignalDetails = match[1];
  return React.createElement(Col2$BsConsole.make, {
              className: Css.style(/* :: */[
                    Css.width(Css.pct(100)),
                    /* [] */0
                  ]),
              onClick: (function (e) {
                  return e.stopPropagation();
                }),
              children: null
            }, React.createElement(Divider.default, {
                  className: Css.style(/* :: */[
                        Css.width(Css.pct(100)),
                        /* :: */[
                          Css.margin3(Css.px(5), Css.zero, Css.px(4)),
                          /* [] */0
                        ]
                      ])
                }), React.createElement(Row2$BsConsole.make, {
                  alignItems: /* center */98248149,
                  justifyContent: /* spaceBetween */516682146,
                  className: Css.style(/* :: */[
                        Css.width(Css.pct(100)),
                        /* :: */[
                          Css.cursor(/* pointer */-786317123),
                          /* [] */0
                        ]
                      ]),
                  onClick: (function (param) {
                      return Curry._1(setShowSignalDetails, (function (prim) {
                                    return !prim;
                                  }));
                    }),
                  children: null
                }, React.createElement("div", {
                      className: Css.style(/* :: */[
                            Css.fontWeight(Css.medium),
                            /* :: */[
                              Css.fontSize(Css.px(12)),
                              /* [] */0
                            ]
                          ])
                    }, I18N$BsConsole.show(undefined, "Signal details")), React.createElement("div", {
                      className: Css.style(/* :: */[
                            Css.width(Css.px(16)),
                            /* :: */[
                              Css.height(Css.px(16)),
                              /* :: */[
                                Css.fontSize(Css.px(16)),
                                /* [] */0
                              ]
                            ]
                          ])
                    }, React.createElement(KeyboardArrowDown.default, {
                          fontSize: "inherit"
                        }))), React.createElement(Collapse.default, {
                  in: match[0],
                  children: Belt_Array.map(Js_dict.entries(Json_decode.dict(Util$BsConsole.identity, signal)), (function (param) {
                          return React.createElement(BugInteractiveTooltip$BsConsole.Row.make, {
                                      title: param[0],
                                      content: "" + (String(param[1]) + "")
                                    });
                        }))
                }));
}

var SignalInfo = {
  make: BugBttCallstack$SignalInfo
};

var menuItemRoot = Css.style(/* :: */[
      Css.hover(/* :: */[
            Css.important(Css.backgroundColor(Css.hex(Colors$BsConsole.accent))),
            /* [] */0
          ]),
      /* :: */[
        Css.focus(/* :: */[
              Css.important(Css.backgroundColor(Css.hex(Colors$BsConsole.accent))),
              /* [] */0
            ]),
        /* :: */[
          Css.fontFamily("Roboto"),
          /* :: */[
            Css.fontWeight(Css.medium),
            /* :: */[
              Css.textTransform(Css.uppercase),
              /* :: */[
                Css.color(Css.hex(Colors$BsConsole.grey0)),
                /* :: */[
                  Css.fontSize(Css.px(12)),
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var select = Css.style(/* :: */[
      Css.fontFamily("Roboto"),
      /* :: */[
        Css.fontWeight(Css.medium),
        /* :: */[
          Css.textTransform(Css.uppercase),
          /* :: */[
            Css.color(Css.hex(Colors$BsConsole.grey0)),
            /* :: */[
              Css.fontSize(Css.px(12)),
              /* :: */[
                Css.paddingTop(Css.px(5)),
                /* :: */[
                  Css.paddingBottom(Css.px(5)),
                  /* :: */[
                    Css.paddingLeft(Css.px(8)),
                    /* :: */[
                      Css.boxSizing(/* borderBox */9307263),
                      /* [] */0
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var Style = {
  menuItemRoot: menuItemRoot,
  select: select
};

function BugBttCallstack(Props) {
  var callstack = Props.callstack;
  var kernelStack = Props.kernelStack;
  var w = Props.width;
  var h = Props.height;
  var selectedFrame = Props.selectedFrame;
  var setSelectedFrame = Props.setSelectedFrame;
  var paneFocused = Props.paneFocused;
  var onClick = Props.onClick;
  var getDefectsByFrame = Props.getDefectsByFrame;
  var isLocalVarsVisible = Props.isLocalVarsVisible;
  var toggleIsLocalVarsVisbile = Props.toggleIsLocalVarsVisbile;
  var processFrames = Props.processFrames;
  var toggleProcessFrames = Props.toggleProcessFrames;
  var match = React.useState((function () {
          return /* Callstack */0;
        }));
  var setActiveTab = match[1];
  var activeTab = match[0];
  var frames = Belt_Array.map(callstack, (function (frame) {
          return frame.basename;
        }));
  var morgueProcessedFrames = Morgue$BsConsole.Callstack.prep(frames, {
        suffix: 2,
        dynamic: true
      });
  var isWorthProcessingFrames = Caml_obj.caml_notequal(morgueProcessedFrames, frames);
  var callstackFramesHasVariables = Belt_Array.some(callstack, (function (frame) {
          return Belt_Option.isSome(frame.variables);
        }));
  return React.createElement(Col2$BsConsole.make, {
              className: Css.style(/* :: */[
                    Css.width(Css.pct(100)),
                    /* :: */[
                      Css.height(Css.px(h)),
                      /* [] */0
                    ]
                  ]),
              onClick: onClick,
              children: null
            }, React.createElement(Row2$BsConsole.make, {
                  alignItems: /* center */98248149,
                  justifyContent: /* spaceBetween */516682146,
                  className: Css.style(/* :: */[
                        Css.width(Css.pct(100)),
                        /* :: */[
                          Css.borderBottom(Css.px(1), Css.solid, Css.hex(BugStyles$BsConsole.Color.dividerTertiary)),
                          /* :: */[
                            Css.height(Css.px(32)),
                            /* [] */0
                          ]
                        ]
                      ]),
                  children: null
                }, React.createElement(Select.default, {
                      value: activeTab,
                      onChange: (function ($$event, param) {
                          return Curry._1(setActiveTab, $$event.target.value);
                        }),
                      renderValue: (function (value) {
                          return React.createElement(Row2$BsConsole.make, {
                                      alignItems: /* center */98248149,
                                      justifyContent: /* spaceBetween */516682146,
                                      children: value ? React.createElement(React.Fragment, undefined, I18N$BsConsole.show(undefined, "Kernel stack"), React.createElement(BugTabCount$BsConsole.make, {
                                                  label: String(kernelStack.length)
                                                })) : React.createElement(React.Fragment, undefined, I18N$BsConsole.show(undefined, "Callstack"), React.createElement(BugTabCount$BsConsole.make, {
                                                  label: String(callstack.length)
                                                }))
                                    });
                        }),
                      disableUnderline: true,
                      classes: {
                        select: select
                      },
                      children: null
                    }, React.createElement(MenuItem.default, {
                          classes: {
                            root: menuItemRoot
                          },
                          value: /* Callstack */0,
                          children: React.createElement(Row2$BsConsole.make, {
                                alignItems: /* center */98248149,
                                justifyContent: /* spaceBetween */516682146,
                                className: Css.style(/* :: */[
                                      Css.width(Css.pct(100)),
                                      /* :: */[
                                        Css.boxSizing(/* borderBox */9307263),
                                        /* [] */0
                                      ]
                                    ]),
                                children: null
                              }, I18N$BsConsole.show(undefined, "Callstack"), React.createElement(BugTabCount$BsConsole.make, {
                                    label: String(callstack.length)
                                  }))
                        }), React.createElement(MenuItem.default, {
                          disabled: kernelStack.length === 0,
                          classes: {
                            root: menuItemRoot
                          },
                          value: /* KernelStack */1,
                          children: React.createElement(Row2$BsConsole.make, {
                                alignItems: /* center */98248149,
                                justifyContent: /* spaceBetween */516682146,
                                className: Css.style(/* :: */[
                                      Css.width(Css.pct(100)),
                                      /* :: */[
                                        Css.whiteSpace(Css.nowrap),
                                        /* :: */[
                                          Css.boxSizing(/* borderBox */9307263),
                                          /* [] */0
                                        ]
                                      ]
                                    ]),
                                children: null
                              }, I18N$BsConsole.show(undefined, "Kernel stack"), React.createElement(BugTabCount$BsConsole.make, {
                                    label: String(kernelStack.length)
                                  }))
                        })), React.createElement(Row2$BsConsole.make, {
                      alignItems: /* center */98248149,
                      children: null
                    }, isWorthProcessingFrames ? React.createElement(Tooltip.default, {
                            children: React.createElement("div", undefined, React.createElement(Switch.default, {
                                      onChange: (function (param, param$1) {
                                          return Curry._1(toggleProcessFrames, undefined);
                                        }),
                                      disableRipple: true,
                                      checked: processFrames
                                    })),
                            title: I18N$BsConsole.get(undefined, "Condense function names")
                          }) : null, callstackFramesHasVariables && !isLocalVarsVisible ? React.createElement(BugVariablesToggle$BsConsole.make, {
                            isLocalVarsVisible: isLocalVarsVisible,
                            toggleIsLocalVarsVisbile: toggleIsLocalVarsVisbile
                          }) : null, React.createElement("div", {
                          className: "smaller-icon"
                        }, React.createElement(CallstackCopyButton$BsConsole.Jsx3.make, {
                              callstack: /* Btt */Block.__(3, [callstack]),
                              target: React.createElement("span", undefined, React.createElement(Mui$BsConsole.IconButton.make, {
                                        ariaLabel: "Copy callstack to clipboard",
                                        children: React.createElement(Assignment.default, { })
                                      }))
                            })))), React.createElement(Col2$BsConsole.make, {
                  className: Css.style(/* :: */[
                        Css.height(Css.px((h - 40 | 0) - 1 | 0)),
                        /* :: */[
                          Css.overflowY(Css.auto),
                          /* :: */[
                            Css.overflowX(Css.hidden),
                            /* :: */[
                              Css.width(Css.pct(100)),
                              /* :: */[
                                Css.marginTop(Css.px(1)),
                                /* :: */[
                                  Css.fontSize(Css.px(12)),
                                  /* :: */[
                                    Css.fontFamily("Inconsolata"),
                                    /* [] */0
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]),
                  children: activeTab ? React.createElement(React.Fragment, undefined, Belt_Array.mapWithIndex(kernelStack, (function (idx, frame) {
                                return React.createElement(Row2$BsConsole.make, {
                                            alignItems: /* center */98248149,
                                            className: Css.style(/* :: */[
                                                  Css.height(Css.px(20)),
                                                  /* [] */0
                                                ]),
                                            children: null,
                                            key: String(idx)
                                          }, React.createElement("div", {
                                                className: Css.style(/* :: */[
                                                      Css.paddingRight(Css.px(8)),
                                                      /* :: */[
                                                        Css.paddingLeft(Css.px(6)),
                                                        /* :: */[
                                                          Css.minWidth(Css.px(26)),
                                                          /* :: */[
                                                            Css.textAlign(/* right */-379319332),
                                                            /* [] */0
                                                          ]
                                                        ]
                                                      ]
                                                    ])
                                              }, I18N$BsConsole.showSkip(String(idx))), React.createElement("div", undefined, I18N$BsConsole.showSkip(frame.symbol)));
                              }))) : React.createElement(ReactWindow.FixedSizeList, {
                          height: (h - 40 | 0) - 1 | 0,
                          width: w,
                          itemCount: callstack.length,
                          itemSize: 33,
                          children: (function (params) {
                              var frame = Belt_Array.getExn(callstack, params.index);
                              var frameText = frame.symbol === "" ? frame.ip : frame.symbol;
                              var rez = processFrames ? Morgue$BsConsole.Callstack.prep([frameText], {
                                      suffix: 2,
                                      dynamic: true
                                    }) : [frameText];
                              var condensedFrameText = Belt_Array.getExn(rez, 0);
                              var defectsByFrame = Curry._1(getDefectsByFrame, frame);
                              var match = frame.directory;
                              var match$1 = frame.path;
                              var path = match !== undefined ? (
                                  match$1 !== undefined ? match + ("/" + match$1) : match
                                ) : (
                                  match$1 !== undefined ? match$1 : undefined
                                );
                              var debugId = frame.debug_identifier;
                              var signal = frame.signal;
                              var tmp;
                              if (defectsByFrame !== undefined) {
                                var defectCount = Belt_List.length(defectsByFrame);
                                tmp = React.createElement(BugInteractiveTooltip$BsConsole.Row.make, {
                                      title: I18N$BsConsole.get(undefined, "Defect count"),
                                      content: String(defectCount)
                                    });
                              } else {
                                tmp = null;
                              }
                              var tmp$1;
                              if (defectsByFrame !== undefined) {
                                var defectCount$1 = Belt_List.length(defectsByFrame);
                                tmp$1 = defectCount$1 > 0;
                              } else {
                                tmp$1 = false;
                              }
                              var tmp$2 = {
                                isSelected: Caml_obj.caml_equal(frame, selectedFrame),
                                isFocused: paneFocused,
                                isFaulted: Caml_obj.caml_equal(frame.faulted, true),
                                onClick: (function (param) {
                                    MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* Debugger_FrameRowClick */103]));
                                    return Curry._2(setSelectedFrame, frame, false);
                                  }),
                                onDefectIconClick: (function (param) {
                                    return Curry._2(setSelectedFrame, frame, true);
                                  }),
                                index: params.index,
                                isAppFrame: false,
                                highlightAppFrames: false,
                                functionName: condensedFrameText,
                                hasDefects: tmp$1
                              };
                              if (frame.path !== undefined) {
                                tmp$2.path = Caml_option.valFromOption(frame.path);
                              }
                              if (frame.line !== undefined) {
                                tmp$2.line = Caml_option.valFromOption(frame.line);
                              }
                              return React.createElement(BugInteractiveTooltip$BsConsole.make, {
                                          contents: React.createElement("div", undefined, React.createElement(BugInteractiveTooltip$BsConsole.Row.make, {
                                                    title: I18N$BsConsole.get(undefined, "Function"),
                                                    content: frameText
                                                  }), path !== undefined ? React.createElement(BugInteractiveTooltip$BsConsole.Row.make, {
                                                      title: I18N$BsConsole.get(undefined, "Path"),
                                                      content: path
                                                    }) : null, debugId !== undefined ? React.createElement(BugInteractiveTooltip$BsConsole.Row.make, {
                                                      title: I18N$BsConsole.get(undefined, "Debug ID"),
                                                      content: debugId
                                                    }) : null, signal !== undefined ? React.createElement(BugBttCallstack$SignalInfo, {
                                                      signal: Caml_option.valFromOption(signal)
                                                    }) : null, tmp),
                                          children: React.createElement("div", {
                                                className: Css.style(/* :: */[
                                                      Css.width(Css.pct(100)),
                                                      /* :: */[
                                                        Css.padding2(Css.px(4), Css.zero),
                                                        /* :: */[
                                                          Css.important(Css.height(Css.px(params.style.height + 8 | 0))),
                                                          /* [] */0
                                                        ]
                                                      ]
                                                    ]),
                                                style: params.style
                                              }, React.createElement(BugCallstackFrameRow$BsConsole.make, tmp$2))
                                        });
                            })
                        })
                }));
}

var make = React.memo(BugBttCallstack);

exports.SignalInfo = SignalInfo;
exports.Style = Style;
exports.make = make;
/* menuItemRoot Not a pure module */
