// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Json = require("@glennsl/bs-json/lib/js/src/Json.bs.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var Task2$BsConsole = require("./Task2.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var Uncaught$BsConsole = require("./Uncaught.js");
var Backtrace$BsConsole = require("./bindings/Backtrace.js");

function setSignature(signature) {
  return Curry._1((function setSignature(signature) {
        setTimeout(() => {
          try {
            // retain any Appcues SDK settings that may have been set prior
            if (window.AppcuesSettings) {
              window.AppcuesSettings.userIdSignature = signature;
            } else {
              window.AppcuesSettings = { userIdSignature: signature };
            }
          } catch (err) {
          }
        }, 100)
      }), signature);
}

function initialize(signature) {
  window.AppcuesSettings = {
    enableURLDetection: true
  };
  var scriptTag = document.createElement("script");
  scriptTag.setAttribute("type", "text/javascript");
  scriptTag.setAttribute("async", "true");
  scriptTag.setAttribute("src", "//fast.appcues.com/94547.js");
  Belt_Option.map(Belt_Array.get(Array.prototype.slice.call(document.getElementsByTagName("script")), 0), (function (topScript) {
          return Belt_Option.map(Caml_option.nullable_to_opt(topScript.parentNode), (function (head) {
                        return head.insertBefore(scriptTag, topScript);
                      }));
        }));
  return setSignature(signature);
}

function identify(name, universe, project) {
  var project$1 = Belt_Option.getWithDefault(project, "");
  return Curry._3((function identify(name, universe, project) {
        setTimeout(() => {
          try {
            window.Appcues.identify(name, {
              universe: universe,
              project: project
            });
          } catch (err) {
          }
        }, 100)
      }), name, universe, project$1);
}

function track(name) {
  return Curry._1((function track(event) {
        setTimeout(() => {
          try {
            window.Appcues.track(event);
          } catch (err) {
          }
        }, 100)
      }), name);
}

function decode(json) {
  return {
          exp: Json_decode.optional((function (param) {
                  return Json_decode.field("exp", Json_decode.$$int, param);
                }), json)
        };
}

function encode(t) {
  var __x = t.exp;
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "exp",
                Json_encode.nullable((function (prim) {
                        return prim;
                      }), __x)
              ],
              /* [] */0
            ]);
}

var Payload = {
  decode: decode,
  encode: encode
};

function signatureHasExpired(signature) {
  try {
    var splitJWT = signature.split(".");
    if (splitJWT.length !== 3) {
      return true;
    }
    var payload = splitJWT[1];
    var decodedPayload = decode(JSON.parse(atob(payload)));
    var exp = decodedPayload.exp;
    if (exp === undefined) {
      return true;
    }
    var now = Date.now() / 1000;
    var floatExp = exp;
    return now >= floatExp;
  }
  catch (raw_err){
    var err = Caml_js_exceptions.internalToOCamlException(raw_err);
    var error = Uncaught$BsConsole.castToJsExn(err);
    var __x = Backtrace$BsConsole.Attributes.addString("src_module", "AppCues/signatureHasExpiredOrDoesntExist", Backtrace$BsConsole.Attributes.make(undefined));
    Backtrace$BsConsole.Client.send(__x, /* `exn */[
          5049499,
          error
        ]);
    return true;
  }
}

function decode$1(json) {
  return {
          statusCode: Json_decode.field("statusCode", Json_decode.$$int, json),
          signature: Json_decode.field("signature", Json_decode.string, json)
        };
}

var Success = {
  decode: decode$1
};

function decode$2(json) {
  return {
          statusCode: Json_decode.field("statusCode", Json_decode.$$int, json),
          message: Json_decode.field("message", Json_decode.string, json)
        };
}

function encode$1(f) {
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "statusCode",
                f.statusCode
              ],
              /* :: */[
                /* tuple */[
                  "message",
                  f.message
                ],
                /* [] */0
              ]
            ]);
}

var Failure = {
  decode: decode$2,
  encode: encode$1
};

function decode$3(json) {
  return Json_decode.oneOf(/* :: */[
              (function (json) {
                  return /* Success */Block.__(0, [decode$1(json)]);
                }),
              /* :: */[
                (function (json) {
                    return /* Failure */Block.__(1, [decode$2(json)]);
                  }),
                /* [] */0
              ]
            ], json);
}

var $$Response = {
  Success: Success,
  Failure: Failure,
  decode: decode$3
};

var ParseError = Caml_exceptions.create("AppCues-BsConsole.ParseError");

var AppCuesError = Caml_exceptions.create("AppCues-BsConsole.AppCuesError");

function makeTask(userId, url) {
  var endpoint = url + "/appcues/createAppcuesJWT";
  var payload = Json_encode.object_(/* :: */[
        /* tuple */[
          "userId",
          userId
        ],
        /* [] */0
      ]);
  return Task2$BsConsole.make(/* Custom */Block.__(1, [endpoint]), undefined, /* Post */Block.__(0, [payload]), undefined, (function (param) {
                try {
                  var success = decode$3(param.json);
                  if (success.tag) {
                    return /* Error */Block.__(1, [[
                                AppCuesError,
                                success[0]
                              ]]);
                  } else {
                    return /* Ok */Block.__(0, [success[0]]);
                  }
                }
                catch (raw_err){
                  var err = Caml_js_exceptions.internalToOCamlException(raw_err);
                  var error = Uncaught$BsConsole.castToJsExn(err);
                  var __x = Backtrace$BsConsole.Attributes.addString("src_module", "AppCues/makeTask/Exn", Backtrace$BsConsole.Attributes.make(undefined));
                  Backtrace$BsConsole.Client.send(__x, /* `exn */[
                        5049499,
                        error
                      ]);
                  return /* Error */Block.__(1, [[
                              ParseError,
                              error
                            ]]);
                }
              }), undefined, undefined);
}

function getAppcuesSignature(userId, url, token, onSuccess, onFailure) {
  var arg = makeTask(userId, url);
  return Curry._2((function (param) {
                  return (function (param$1, param$2) {
                      return Task2$BsConsole.handle(param, arg, param$1, param$2);
                    });
                })(token), undefined, (function (param) {
                if (!param.tag) {
                  return Curry._1(onSuccess, param[0]);
                }
                var error = param[0];
                if (error[0] === AppCuesError) {
                  return Curry._1(onFailure, Json.stringify(encode$1(error[1])));
                } else {
                  return Curry._1(onFailure, error[0] === Task2$BsConsole.ErrorMsg ? error[1] : "Something went wrong.");
                }
              }));
}

exports.setSignature = setSignature;
exports.initialize = initialize;
exports.identify = identify;
exports.track = track;
exports.Payload = Payload;
exports.signatureHasExpired = signatureHasExpired;
exports.$$Response = $$Response;
exports.ParseError = ParseError;
exports.AppCuesError = AppCuesError;
exports.makeTask = makeTask;
exports.getAppcuesSignature = getAppcuesSignature;
/* Task2-BsConsole Not a pure module */
