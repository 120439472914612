// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var $$String = require("bs-platform/lib/js/string.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Col2$BsConsole = require("../components/Col2.js");
var Task2$BsConsole = require("../Task2.js");
var BpgTask$BsConsole = require("../BpgTask.js");
var BtModal$BsConsole = require("../utilities/BtModal.js");
var TeamsUtils$BsConsole = require("./TeamsUtils.js");
var MetricsEvent$BsConsole = require("../bs-metrics/MetricsEvent.js");
var BtTypeaheadInput$BsConsole = require("../utilities/BtTypeaheadInput.js");

function TeamsAddTeamUsers(Props) {
  var token = Props.token;
  var _open = Props.open;
  var currentTeam = Props.currentTeam;
  var onCloseOpt = Props.onClose;
  var refetchTeamMembersOpt = Props.refetchTeamMembers;
  var cancelButtonTextOpt = Props.cancelButtonText;
  var allUsers = Props.allUsers;
  var onClose = onCloseOpt !== undefined ? onCloseOpt : (function (param) {
        
      });
  var refetchTeamMembers = refetchTeamMembersOpt !== undefined ? refetchTeamMembersOpt : (function (param) {
        
      });
  var cancelButtonText = cancelButtonTextOpt !== undefined ? cancelButtonTextOpt : "Cancel";
  var match = React.useState((function () {
          return [];
        }));
  var setSelectedUsers = match[1];
  var __x = Belt_List.fromArray(allUsers);
  var allUsers$1 = Belt_List.toArray(Belt_List.sort(__x, (function (u1, u2) {
              return $$String.compare(u1.username.toLowerCase(), u2.username.toLowerCase());
            })));
  var userToString = function (u) {
    return u.username;
  };
  return React.createElement(BtModal$BsConsole.Default.make, {
              open: _open,
              onClose: onClose,
              modalHeight: 320,
              title: "Add one or more members",
              subtitle: "",
              divider: true,
              children: React.createElement(Col2$BsConsole.make, {
                    justifyContent: /* flexEnd */924268066,
                    children: React.createElement(BtTypeaheadInput$BsConsole.Multi.make, {
                          selectedItems: match[0],
                          items: allUsers$1,
                          toString: userToString,
                          singleItemName: "user",
                          pluralItemName: "users",
                          onAction: (function (selectedItems) {
                              Belt_Array.forEach(selectedItems, (function (user) {
                                      var arg = BpgTask$BsConsole.TeamMember.create({
                                            team: currentTeam.id,
                                            user: user.uid,
                                            __state: undefined,
                                            __create: undefined,
                                            __modify: undefined
                                          });
                                      return Curry._2((function (param) {
                                                      return (function (param$1, param$2) {
                                                          return Task2$BsConsole.handle(param, arg, param$1, param$2);
                                                        });
                                                    })(token), true, (function (results) {
                                                    return TeamsUtils$BsConsole.handleResults(results, "add team member", refetchTeamMembers);
                                                  }));
                                    }));
                              MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* Teams_AddTeamMember */66]));
                              Curry._1(setSelectedUsers, (function (param) {
                                      return [];
                                    }));
                              return Curry._1(onClose, undefined);
                            }),
                          placeholder: "Search for a user",
                          noItemsMessage: "You don't have any users",
                          itemFilter: (function (param) {
                              return true;
                            }),
                          pixelWidth: 250,
                          handleAllItemsAfterChange: (function (updatedUsers) {
                              return Curry._1(setSelectedUsers, (function (param) {
                                            return updatedUsers;
                                          }));
                            }),
                          cancelButtonText: cancelButtonText,
                          onCancel: (function (param) {
                              Curry._1(setSelectedUsers, (function (param) {
                                      return [];
                                    }));
                              return Curry._1(onClose, undefined);
                            })
                        })
                  })
            });
}

var make = TeamsAddTeamUsers;

exports.make = make;
/* react Not a pure module */
