// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Json = require("@glennsl/bs-json/lib/js/src/Json.bs.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var I18N$BsConsole = require("../I18N.js");
var Link$BsConsole = require("../Link.js");
var Backtrace$BsConsole = require("../bindings/Backtrace.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var GetNextRoute$BsConsole = require("../GetNextRoute.js");
var BreadcrumbStyles$BsConsole = require("./BreadcrumbStyles.js");
var KeyboardArrowLeft = require("@material-ui/icons/KeyboardArrowLeft");

var _BACKTRACE_LV1_BREADCRUMB = "_Backtrace_lv1_breadcrumb";

function typeToRoute(projectName, aperture, route, breadcrumb) {
  var match = breadcrumb.routeName;
  if (match !== 0) {
    if (match >= 3) {
      return {
              route: GetNextRoute$BsConsole.overview(projectName, aperture),
              routeName: breadcrumb.routeName
            };
    } else {
      return {
              route: GetNextRoute$BsConsole.explore(projectName, route, aperture, true),
              routeName: breadcrumb.routeName
            };
    }
  } else {
    return {
            route: GetNextRoute$BsConsole.triage(projectName, route, aperture, true),
            routeName: breadcrumb.routeName
          };
  }
}

function routeToTitle(route) {
  if (route !== 0) {
    if (route >= 3) {
      return "Overview";
    } else {
      return "Explore";
    }
  } else {
    return "Triage";
  }
}

function routeToString(route) {
  switch (route) {
    case /* Triage */0 :
        return "Triage";
    case /* ExploreAgg */1 :
        return "ExploreAgg";
    case /* ExploreList */2 :
        return "ExploreList";
    case /* Overview */3 :
        return "Overview";
    
  }
}

function stringToRoute(route) {
  switch (route) {
    case "ExploreAgg" :
        return /* ExploreAgg */1;
    case "ExploreList" :
        return /* ExploreList */2;
    case "Overview" :
        return /* Overview */3;
    case "Triage" :
        return /* Triage */0;
    default:
      var __x = Backtrace$BsConsole.Attributes.addString("src_module", "Level1Breadcrumb/routeToString", Backtrace$BsConsole.Attributes.addString("shouldSendAlert", "true", Backtrace$BsConsole.Attributes.make(undefined)));
      Backtrace$BsConsole.Client.send(__x, /* `string */[
            288368849,
            "Route " + (route + " was not accounted for.")
          ]);
      return /* Triage */0;
  }
}

function decode(json) {
  return {
          routeName: stringToRoute(Json_decode.field("routeName", Json_decode.string, json))
        };
}

function encode(breadcrumb) {
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "routeName",
                routeToString(breadcrumb.routeName)
              ],
              /* [] */0
            ]);
}

function stringify(breadcrumb) {
  return Json.stringify(encode(breadcrumb));
}

function parse(breadcrumb) {
  var json = Json.parse(breadcrumb);
  if (json !== undefined) {
    return decode(Caml_option.valFromOption(json));
  }
  
}

function set(route) {
  var maybeBreadCrumb;
  if (typeof route === "number") {
    maybeBreadCrumb = undefined;
  } else {
    switch (route.tag | 0) {
      case /* ProjectOverview */8 :
          maybeBreadCrumb = {
            routeName: /* Overview */3
          };
          break;
      case /* ProjectTriage */11 :
          maybeBreadCrumb = {
            routeName: /* Triage */0
          };
          break;
      case /* ProjectQueryBuilder */13 :
          maybeBreadCrumb = {
            routeName: /* ExploreAgg */1
          };
          break;
      case /* ProjectInstances */15 :
          maybeBreadCrumb = {
            routeName: /* ExploreList */2
          };
          break;
      default:
        maybeBreadCrumb = undefined;
    }
  }
  if (maybeBreadCrumb !== undefined) {
    window.sessionStorage.setItem(_BACKTRACE_LV1_BREADCRUMB, Json.stringify(encode(maybeBreadCrumb)));
    return ;
  }
  
}

function get(param) {
  var jsonString = window.sessionStorage.getItem(_BACKTRACE_LV1_BREADCRUMB);
  if (!(jsonString == null)) {
    return parse(jsonString);
  }
  
}

function Level1Breadcrumb$Crumb(Props) {
  var parentRoute = Props.parentRoute;
  var handleChangeUrl = Props.handleChangeUrl;
  return React.createElement(Link$BsConsole.Jsx3.make, {
              route: parentRoute.route,
              handleChangeUrl: handleChangeUrl,
              className: BreadcrumbStyles$BsConsole.link,
              children: null
            }, React.createElement(KeyboardArrowLeft.default, {
                  className: BreadcrumbStyles$BsConsole.iconButton
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Body1 */8,
                  className: BreadcrumbStyles$BsConsole.crumbWithLink,
                  children: I18N$BsConsole.show(undefined, routeToTitle(parentRoute.routeName))
                }));
}

var Crumb = {
  make: Level1Breadcrumb$Crumb
};

exports._BACKTRACE_LV1_BREADCRUMB = _BACKTRACE_LV1_BREADCRUMB;
exports.typeToRoute = typeToRoute;
exports.routeToTitle = routeToTitle;
exports.routeToString = routeToString;
exports.stringToRoute = stringToRoute;
exports.decode = decode;
exports.encode = encode;
exports.stringify = stringify;
exports.parse = parse;
exports.set = set;
exports.get = get;
exports.Crumb = Crumb;
/* react Not a pure module */
