// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var I18N$BsConsole = require("../I18N.js");
var Colors$BsConsole = require("../Colors.js");
var Tooltip = require("@material-ui/core/Tooltip");

var style = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.justifyContent(/* center */98248149),
        /* :: */[
          Css.alignItems(/* center */98248149),
          /* :: */[
            Css.marginLeft(Css.px(8)),
            /* :: */[
              Css.width(Css.px(22)),
              /* :: */[
                Css.height(Css.px(22)),
                /* :: */[
                  Css.color(Css.hex(Colors$BsConsole.grey300)),
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

function TotalFilterChipsCount(Props) {
  var isFilterOpen = Props.isFilterOpen;
  var filtersCount = Props.filtersCount;
  var havingsCount = Props.havingsCount;
  var totalCount = havingsCount + filtersCount | 0;
  if (!isFilterOpen && totalCount > 0) {
    return React.createElement(Tooltip.default, {
                children: React.createElement("span", {
                      className: style
                    }, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                              /* Char_literal */Block.__(12, [
                                  /* "(" */40,
                                  /* Int */Block.__(4, [
                                      /* Int_d */0,
                                      /* No_padding */0,
                                      /* No_precision */0,
                                      /* Char_literal */Block.__(12, [
                                          /* ")" */41,
                                          /* End_of_format */0
                                        ])
                                    ])
                                ]),
                              "(%d)"
                            ]), totalCount)),
                title: Curry._1(I18N$BsConsole.getf(undefined, /* Format */[
                          /* String_literal */Block.__(11, [
                              "View all ",
                              /* Int */Block.__(4, [
                                  /* Int_d */0,
                                  /* No_padding */0,
                                  /* No_precision */0,
                                  /* String_literal */Block.__(11, [
                                      " filter chips",
                                      /* End_of_format */0
                                    ])
                                ])
                            ]),
                          "View all %d filter chips"
                        ]), totalCount)
              });
  } else {
    return null;
  }
}

var make = TotalFilterChipsCount;

exports.style = style;
exports.make = make;
/* style Not a pure module */
