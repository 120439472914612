// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Crdb$BsConsole = require("./crdb.js");
var I18N$BsConsole = require("./I18N.js");
var Row2$BsConsole = require("./components/Row2.js");
var Util$BsConsole = require("./util.js");
var Colors$BsConsole = require("./Colors.js");
var InboxUtils$BsConsole = require("./inbox/InboxUtils.js");
var FilterInput$BsConsole = require("./FilterInput.js");
var CoronerdGate$BsConsole = require("./CoronerdGate.js");
var Close = require("@material-ui/icons/Close");
var Tooltip = require("@material-ui/core/Tooltip");
var Attribute_Helpers$BsConsole = require("./Attribute_Helpers.js");

var ellipsis = Css.style(/* :: */[
      Css.whiteSpace(/* nowrap */867913355),
      /* :: */[
        Css.textOverflow(/* ellipsis */166888785),
        /* :: */[
          Css.overflow(/* hidden */-862584982),
          /* [] */0
        ]
      ]
    ]);

var bold = Css.style(/* :: */[
      Css.fontWeight(/* bold */-1055161979),
      /* :: */[
        Css.maxWidth(Css.px(75)),
        /* [] */0
      ]
    ]) + (" " + ellipsis);

var colon = Css.style(/* :: */[
      Css.opacity(0.75),
      /* [] */0
    ]);

var operator = Css.style(/* :: */[
      Css.whiteSpace(/* nowrap */867913355),
      /* :: */[
        Css.padding2(Css.zero, Css.em(0.25)),
        /* [] */0
      ]
    ]);

var container = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.grey0)),
      /* :: */[
        Css.fontSize(Css.px(12)),
        /* :: */[
          Css.borderRadius(Css.px(4)),
          /* :: */[
            Css.marginRight(Css.px(4)),
            /* :: */[
              Css.marginTop(Css.px(4)),
              /* :: */[
                Css.marginBottom(Css.px(4)),
                /* :: */[
                  Css.boxSizing(/* borderBox */9307263),
                  /* [] */0
                ]
              ]
            ]
          ]
        ]
      ]
    ]);

var filter = Css.style(/* :: */[
      Css.backgroundColor(Css.hex(Colors$BsConsole.grey7)),
      /* [] */0
    ]);

var having = Css.style(/* :: */[
      Css.backgroundColor(Css.hex(Colors$BsConsole.redLight)),
      /* [] */0
    ]);

var actionState = Css.style(/* :: */[
      Css.hover(/* :: */[
            Css.backgroundColor(Css.hex(Colors$BsConsole.teal)),
            /* :: */[
              Css.color(Css.hex(Colors$BsConsole.grey2)),
              /* [] */0
            ]
          ]),
      /* :: */[
        Css.focus(/* :: */[
              Css.backgroundColor(Css.hex(Colors$BsConsole.teal)),
              /* :: */[
                Css.color(Css.hex(Colors$BsConsole.grey2)),
                /* [] */0
              ]
            ]),
        /* [] */0
      ]
    ]);

var textContainer = Css.merge(/* :: */[
      Css.style(/* :: */[
            Css.padding(Css.px(4)),
            /* :: */[
              Css.display(/* flex */-1010954439),
              /* :: */[
                Css.flexWrap(/* nowrap */867913355),
                /* :: */[
                  Css.cursor(/* pointer */-786317123),
                  /* :: */[
                    Css.userSelect(/* none */-922086728),
                    /* :: */[
                      Css.borderBottomLeftRadius(Css.px(4)),
                      /* :: */[
                        Css.borderTopLeftRadius(Css.px(4)),
                        /* [] */0
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]),
      /* :: */[
        actionState,
        /* [] */0
      ]
    ]);

var closeContainer = Css.merge(/* :: */[
      Css.style(/* :: */[
            Css.display(/* flex */-1010954439),
            /* :: */[
              Css.alignItems(/* center */98248149),
              /* :: */[
                Css.padding(Css.px(4)),
                /* :: */[
                  Css.cursor(/* pointer */-786317123),
                  /* :: */[
                    Css.alignSelf(/* stretch */-162316795),
                    /* :: */[
                      Css.borderBottomRightRadius(Css.px(4)),
                      /* :: */[
                        Css.borderTopRightRadius(Css.px(4)),
                        /* [] */0
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]),
      /* :: */[
        actionState,
        /* [] */0
      ]
    ]);

var Style = {
  ellipsis: ellipsis,
  bold: bold,
  colon: colon,
  operator: operator,
  container: container,
  filter: filter,
  having: having,
  actionState: actionState,
  textContainer: textContainer,
  closeContainer: closeContainer
};

function InputChip$ChipBase(Props) {
  var onDeleteOpt = Props.onDelete;
  var classNameOpt = Props.className;
  var variant = Props.variant;
  var children = Props.children;
  var onDelete = onDeleteOpt !== undefined ? Caml_option.valFromOption(onDeleteOpt) : undefined;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return React.createElement(Row2$BsConsole.make, {
              alignItems: /* center */98248149,
              className: Css.merge(/* :: */[
                    container,
                    /* :: */[
                      variant ? filter : having,
                      /* :: */[
                        className,
                        /* [] */0
                      ]
                    ]
                  ]),
              children: null
            }, React.createElement(React.Fragment, undefined, children), onDelete !== undefined ? React.createElement(Tooltip.default, {
                    children: React.createElement("div", {
                          className: "extra-smaller-icon filter-delete " + closeContainer,
                          role: "button",
                          tabIndex: 0,
                          onKeyDown: (function ($$event) {
                              $$event.stopPropagation();
                              var keycode = $$event.keyCode;
                              var switcher = keycode - 8 | 0;
                              if (!(switcher > 5 || switcher < 0)) {
                                if (switcher > 4 || switcher < 1) {
                                  return Curry._1(onDelete, undefined);
                                } else {
                                  return ;
                                }
                              }
                              if (switcher !== 19) {
                                return ;
                              }
                              var target = $$event.target;
                              try {
                                return target.blur();
                              }
                              catch (exn){
                                return ;
                              }
                            }),
                          onFocus: (function ($$event) {
                              $$event.stopPropagation();
                              
                            }),
                          onClick: (function (e) {
                              e.stopPropagation();
                              return Curry._1(onDelete, undefined);
                            })
                        }, React.createElement(Close.default, { })),
                    title: I18N$BsConsole.get(undefined, "Remove filter")
                  }) : null);
}

var ChipBase = {
  make: InputChip$ChipBase
};

function getFilterInfo(filter) {
  var operator = Crdb$BsConsole.FilterOperation.toString(Curry._1(Crdb$BsConsole.Filter.getOperation, filter));
  var attribute = Curry._1(Crdb$BsConsole.Filter.getAttribute, filter);
  var value = Json_decode.string(Crdb$BsConsole.FilterOperation.valueToJson(Crdb$BsConsole.FilterOperation.getValue(Curry._1(Crdb$BsConsole.Filter.getOperation, filter))));
  var value$1 = attribute === "_tx" ? Util$BsConsole.decToHex(value) : value;
  return /* tuple */[
          attribute,
          operator,
          value$1
        ];
}

function getHavingInfo(having) {
  var operator = Crdb$BsConsole.HavingOperation.toString(Curry._1(Crdb$BsConsole.Having.getOperation, having));
  var attribute = Curry._1(Crdb$BsConsole.Having.getAttribute, having);
  var pipe = Curry._1(Crdb$BsConsole.Having.getPipe, having);
  var value = Json_decode.string(Crdb$BsConsole.HavingOperation.getValue(Curry._1(Crdb$BsConsole.Having.getOperation, having)));
  var value$1 = attribute === "_tx" ? Util$BsConsole.decToHex(value) : value;
  return /* tuple */[
          attribute,
          pipe,
          operator,
          value$1
        ];
}

var hidden = Css.style(/* :: */[
      Css.display(/* none */-922086728),
      /* [] */0
    ]);

var aggWarning = I18N$BsConsole.get(undefined, "This is a filter on the result of an aggregation. The top summary graph is currently unaffected by these filters but the results table is.");

function attributeName(token, operator, value, isEditable) {
  var editTxt = isEditable ? "\n\nClick to edit." : "";
  return Curry._4(I18N$BsConsole.showf(undefined, /* Format */[
                  /* String */Block.__(2, [
                      /* No_padding */0,
                      /* String_literal */Block.__(11, [
                          ", ",
                          /* String */Block.__(2, [
                              /* No_padding */0,
                              /* String_literal */Block.__(11, [
                                  ", ",
                                  /* String */Block.__(2, [
                                      /* No_padding */0,
                                      /* String */Block.__(2, [
                                          /* No_padding */0,
                                          /* End_of_format */0
                                        ])
                                    ])
                                ])
                            ])
                        ])
                    ]),
                  "%s, %s, %s%s"
                ]), InboxUtils$BsConsole.getFriendlyName(token), operator, value, editTxt);
}

function attributeCount(operator, value, isEditable) {
  var editTxt = isEditable ? "Click to edit.\n\n" : "";
  return Curry._4(I18N$BsConsole.showf(undefined, /* Format */[
                  /* String_literal */Block.__(11, [
                      "count ",
                      /* String */Block.__(2, [
                          /* No_padding */0,
                          /* Char_literal */Block.__(12, [
                              /* " " */32,
                              /* String */Block.__(2, [
                                  /* No_padding */0,
                                  /* String_literal */Block.__(11, [
                                      "\n\n",
                                      /* String */Block.__(2, [
                                          /* No_padding */0,
                                          /* String */Block.__(2, [
                                              /* No_padding */0,
                                              /* End_of_format */0
                                            ])
                                        ])
                                    ])
                                ])
                            ])
                        ])
                    ]),
                  "count %s %s\n\n%s%s"
                ]), operator, value, editTxt, aggWarning);
}

function attributePipe(name, pipe, operator, value, isEditable) {
  var editTxt = isEditable ? "Click to edit.\n\n" : "";
  return Curry._6(I18N$BsConsole.showf(undefined, /* Format */[
                  /* String */Block.__(2, [
                      /* No_padding */0,
                      /* String_literal */Block.__(11, [
                          " | ",
                          /* String */Block.__(2, [
                              /* No_padding */0,
                              /* Char_literal */Block.__(12, [
                                  /* " " */32,
                                  /* String */Block.__(2, [
                                      /* No_padding */0,
                                      /* Char_literal */Block.__(12, [
                                          /* " " */32,
                                          /* String */Block.__(2, [
                                              /* No_padding */0,
                                              /* String_literal */Block.__(11, [
                                                  "\n\n",
                                                  /* String */Block.__(2, [
                                                      /* No_padding */0,
                                                      /* String */Block.__(2, [
                                                          /* No_padding */0,
                                                          /* End_of_format */0
                                                        ])
                                                    ])
                                                ])
                                            ])
                                        ])
                                    ])
                                ])
                            ])
                        ])
                    ]),
                  "%s | %s %s %s\n\n%s%s"
                ]), name, pipe, operator, value, editTxt, aggWarning);
}

var $$Text = {
  aggWarning: aggWarning,
  attributeName: attributeName,
  attributeCount: attributeCount,
  attributePipe: attributePipe
};

function InputChip$Filter(Props) {
  var route = Props.route;
  var isHidden = Props.isHidden;
  var isFilterOpen = Props.isFilterOpen;
  var describeRemote = Props.describeRemote;
  var handleChangeUrl = Props.handleChangeUrl;
  var projectName = Props.projectName;
  var token = Props.token;
  var aperture = Props.aperture;
  var havings = Props.havings;
  var onDelete = Props.onDelete;
  var isEditable = Props.isEditable;
  var setIsEditing = Props.setIsEditing;
  var item = Props.item;
  var onChange = Props.onChange;
  var filterChipsContainerHeight = Props.filterChipsContainerHeight;
  var match = React.useState((function () {
          return /* Default */0;
        }));
  var setMode = match[1];
  var mode = match[0];
  React.useEffect((function () {
          if (isFilterOpen === false) {
            Curry._1(setMode, (function (param) {
                    return /* Default */0;
                  }));
          }
          
        }), [isFilterOpen]);
  React.useEffect((function () {
          if (mode) {
            Curry._1(setIsEditing, true);
          } else {
            Curry._1(setIsEditing, false);
          }
          
        }), [mode]);
  var match$1;
  if (item.tag) {
    var filter = item[0];
    var match$2 = getFilterInfo(filter);
    var attribute = match$2[0];
    var attributeStr = InboxUtils$BsConsole.getFriendlyName(Belt_Option.getWithDefault(attribute, ""));
    var apertureWithoutCurrent = Curry._2(Crdb$BsConsole.Aperture.setFilters, Curry._2(Crdb$BsConsole.Filters.remove, filter, Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture)), aperture);
    var onDelete$1 = function (param) {
      var __x = Curry._2(Crdb$BsConsole.Filters.remove, filter, Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture));
      Curry._2(onChange, Curry._2(Crdb$BsConsole.Aperture.setFilters, __x, aperture), havings);
      return Curry._1(onDelete, undefined);
    };
    match$1 = /* tuple */[
      attribute,
      match$2[1],
      match$2[2],
      attributeStr,
      undefined,
      apertureWithoutCurrent,
      havings,
      onDelete$1
    ];
  } else {
    var having = item[0];
    var match$3 = getHavingInfo(having);
    var attribute$1 = match$3[0];
    var onDelete$2 = function (param) {
      Curry._2(onChange, aperture, Curry._2(Crdb$BsConsole.Havings.remove, having, havings));
      return Curry._1(onDelete, undefined);
    };
    match$1 = /* tuple */[
      attribute$1,
      match$3[2],
      match$3[3],
      attribute$1,
      match$3[1],
      aperture,
      Crdb$BsConsole.Havings.empty,
      onDelete$2
    ];
  }
  var onDelete$3 = match$1[7];
  var pipe = match$1[4];
  var attributeStr$1 = match$1[3];
  var value = match$1[2];
  var operator$1 = match$1[1];
  var attribute$2 = match$1[0];
  if (mode) {
    var attribute$prime;
    if (typeof describeRemote === "number" || describeRemote.tag) {
      attribute$prime = undefined;
    } else {
      var attr = Belt_List.getBy(describeRemote[0], (function (attr) {
              return Caml_obj.caml_equal(attr.name, attribute$2);
            }));
      attribute$prime = attr !== undefined ? attr : (
          attribute$2 !== undefined ? undefined : Attribute_Helpers$BsConsole.rowCount
        );
    }
    var tmp;
    tmp = item.tag ? false : true;
    return React.createElement(FilterInput$BsConsole.make, {
                isEditing: mode === /* Edit */1,
                route: route,
                isHaving: tmp,
                describeRemote: describeRemote,
                onFilterChange: (function (newFilter) {
                    if (!item.tag) {
                      return ;
                    }
                    var filter = item[0];
                    Curry._1(setMode, (function (param) {
                            return /* Default */0;
                          }));
                    var a = Curry._2(Crdb$BsConsole.Filters.add, newFilter, Crdb$BsConsole.Filters.empty);
                    var b = Curry._2(Crdb$BsConsole.Filters.add, filter, Crdb$BsConsole.Filters.empty);
                    if (Curry._2(Crdb$BsConsole.Filters.eq, a, b)) {
                      return ;
                    }
                    var __x = Curry._2(Crdb$BsConsole.Filters.add, newFilter, Curry._2(Crdb$BsConsole.Filters.remove, filter, Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture)));
                    return Curry._2(onChange, Curry._2(Crdb$BsConsole.Aperture.setFilters, __x, aperture), havings);
                  }),
                onHavingChange: (function (newHaving) {
                    if (item.tag) {
                      return ;
                    }
                    var having = item[0];
                    Curry._1(setMode, (function (param) {
                            return /* Default */0;
                          }));
                    var nextHavings;
                    if (CoronerdGate$BsConsole.isEnoughCoronerdVersion("1.49.1")) {
                      var a = Curry._2(Crdb$BsConsole.Havings.add, newHaving, Crdb$BsConsole.Havings.empty);
                      var b = Curry._2(Crdb$BsConsole.Havings.add, having, Crdb$BsConsole.Havings.empty);
                      nextHavings = Curry._2(Crdb$BsConsole.Havings.eq, a, b) ? havings : Curry._2(Crdb$BsConsole.Havings.add, newHaving, Curry._2(Crdb$BsConsole.Havings.remove, having, havings));
                    } else {
                      nextHavings = Curry._2(Crdb$BsConsole.Havings.add, newHaving, Crdb$BsConsole.Havings.empty);
                    }
                    return Curry._2(onChange, aperture, nextHavings);
                  }),
                onDelete: (function (param) {
                    Curry._1(setMode, (function (param) {
                            return /* Default */0;
                          }));
                    return Curry._1(onDelete$3, undefined);
                  }),
                onBlur: (function (param) {
                    return Curry._1(setMode, (function (param) {
                                  return /* Default */0;
                                }));
                  }),
                projectName: projectName,
                token: token,
                handleChangeUrl: handleChangeUrl,
                containerFocused: mode === /* Edit */1,
                aperture: match$1[5],
                havings: match$1[6],
                attribute: attribute$prime,
                pipe: pipe,
                operator: operator$1,
                value: value,
                isNLQ: false,
                filterChipsContainerHeight: filterChipsContainerHeight,
                key: "chip-input-" + (Belt_Option.getWithDefault(attributeStr$1, "") + (operator$1 + value))
              });
  }
  var tmp$1;
  tmp$1 = item.tag ? /* Filter */1 : /* Having */0;
  var tmp$2;
  var exit = 0;
  if (attributeStr$1 !== undefined) {
    if (pipe !== undefined) {
      exit = 1;
    } else {
      tmp$2 = React.createElement("div", {
            className: bold
          }, I18N$BsConsole.showSkip(InboxUtils$BsConsole.getFriendlyName(attributeStr$1)));
    }
  } else if (pipe !== undefined) {
    exit = 1;
  } else {
    tmp$2 = null;
  }
  if (exit === 1) {
    tmp$2 = pipe === "count" ? React.createElement(React.Fragment, undefined, React.createElement("div", {
                className: bold
              }, I18N$BsConsole.show(undefined, "count"))) : (
        attributeStr$1 !== undefined ? React.createElement(React.Fragment, undefined, React.createElement("div", {
                    className: bold
                  }, I18N$BsConsole.showSkip(InboxUtils$BsConsole.getFriendlyName(attributeStr$1))), React.createElement("div", {
                    className: operator
                  }, Curry._1(I18N$BsConsole.showSkipf(/* Format */[
                            /* String_literal */Block.__(11, [
                                "| ",
                                /* String */Block.__(2, [
                                    /* No_padding */0,
                                    /* End_of_format */0
                                  ])
                              ]),
                            "| %s"
                          ]), pipe))) : React.createElement("div", {
                className: operator
              }, Curry._1(I18N$BsConsole.showSkipf(/* Format */[
                        /* String_literal */Block.__(11, [
                            "| ",
                            /* String */Block.__(2, [
                                /* No_padding */0,
                                /* End_of_format */0
                              ])
                          ]),
                        "| %s"
                      ]), pipe))
      );
  }
  var tmp$3;
  var exit$1 = 0;
  if (attributeStr$1 !== undefined) {
    if (pipe !== undefined) {
      exit$1 = 1;
    } else {
      tmp$3 = attributeName(attributeStr$1, operator$1, value, isEditable);
    }
  } else if (pipe !== undefined) {
    exit$1 = 1;
  } else {
    tmp$3 = null;
  }
  if (exit$1 === 1) {
    tmp$3 = pipe === "count" ? attributeCount(operator$1, value, isEditable) : (
        attributeStr$1 !== undefined && attributeStr$1 !== "" ? attributePipe(attributeStr$1, pipe, operator$1, value, isEditable) : attributePipe("count", pipe, operator$1, value, isEditable)
      );
  }
  return React.createElement(InputChip$ChipBase, {
              onDelete: onDelete$3,
              className: isHidden ? hidden : "",
              variant: tmp$1,
              children: React.createElement(Tooltip.default, {
                    children: React.createElement("div", {
                          className: textContainer,
                          role: "button",
                          onClick: (function (param) {
                              if (isEditable) {
                                return Curry._1(setMode, (function (param) {
                                              return /* Edit */1;
                                            }));
                              }
                              
                            })
                        }, tmp$2, React.createElement("div", {
                              className: operator
                            }, I18N$BsConsole.showSkip(Util$BsConsole.filterOpMap(operator$1))), React.createElement("div", {
                              className: bold
                            }, I18N$BsConsole.showSkip(value))),
                    title: React.createElement("span", {
                          className: Css.style(/* :: */[
                                Css.unsafe("wordBreak", "break-word"),
                                /* :: */[
                                  Css.whiteSpace(/* preLine */538440119),
                                  /* [] */0
                                ]
                              ])
                        }, tmp$3)
                  })
            });
}

var Filter = {
  getFilterInfo: getFilterInfo,
  getHavingInfo: getHavingInfo,
  hidden: hidden,
  $$Text: $$Text,
  make: InputChip$Filter
};

exports.Style = Style;
exports.ChipBase = ChipBase;
exports.Filter = Filter;
/* ellipsis Not a pure module */
