// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var List = require("bs-platform/lib/js/list.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Belt_Result = require("bs-platform/lib/js/belt_Result.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Mui$BsConsole = require("../../bindings/Mui.js");
var Caml_primitive = require("bs-platform/lib/js/caml_primitive.js");
var Col2$BsConsole = require("../../components/Col2.js");
var Href$BsConsole = require("../../Href.js");
var I18N$BsConsole = require("../../I18N.js");
var Row2$BsConsole = require("../../components/Row2.js");
var Util$BsConsole = require("../../util.js");
var Caml_exceptions = require("bs-platform/lib/js/caml_exceptions.js");
var Regex$BsConsole = require("../../Regex.js");
var Task2$BsConsole = require("../../Task2.js");
var Colors$BsConsole = require("../../Colors.js");
var BtTable$BsConsole = require("../../BtTable.js");
var BtSettings$BsConsole = require("../../BtSettings.js");
var BtTableRow$BsConsole = require("../../BtTableRow.js");
var CommitRule$BsConsole = require("./CommitRule.js");
var BtTableBody$BsConsole = require("../../BtTableBody.js");
var BtTableCell$BsConsole = require("../../BtTableCell.js");
var BtTableHead$BsConsole = require("../../BtTableHead.js");
var Icon = require("@material-ui/core/Icon");
var Menu = require("@material-ui/core/Menu");
var BtTypography$BsConsole = require("../../BtTypography.js");
var MetricsEvent$BsConsole = require("../../bs-metrics/MetricsEvent.js");
var Info = require("@material-ui/icons/Info");
var Configuration$BsConsole = require("../../configuration.js");
var Button = require("@material-ui/core/Button");
var Dialog = require("@material-ui/core/Dialog");
var Select = require("@material-ui/core/Select");
var Clear = require("@material-ui/icons/Clear");
var Divider = require("@material-ui/core/Divider");
var Tooltip = require("@material-ui/core/Tooltip");
var Checkbox = require("@material-ui/core/Checkbox");
var MenuItem = require("@material-ui/core/MenuItem");
var BtTableContainer$BsConsole = require("../../BtTableContainer.js");
var TextField = require("@material-ui/core/TextField");
var InputLabel = require("@material-ui/core/InputLabel");
var CardContent = require("@material-ui/core/CardContent");
var ArrowUpward = require("@material-ui/icons/ArrowUpward");
var ArrowDownward = require("@material-ui/icons/ArrowDownward");
var CircularProgress = require("@material-ui/core/CircularProgress");
var FormControlLabel = require("@material-ui/core/FormControlLabel");

function toString(param) {
  switch (param) {
    case /* NoAuth */0 :
        return "none";
    case /* UserPass */1 :
        return "user_pass";
    case /* SSH */2 :
        return "ssh_key";
    
  }
}

function fromString(param) {
  switch (param) {
    case "none" :
        return /* NoAuth */0;
    case "ssh_key" :
        return /* SSH */2;
    case "user_pass" :
        return /* UserPass */1;
    default:
      return /* NoAuth */0;
  }
}

function toFriendlyString(param) {
  switch (param) {
    case "none" :
        return I18N$BsConsole.get(undefined, "None");
    case "ssh_key" :
        return I18N$BsConsole.get(undefined, "SSH key");
    case "user_pass" :
        return I18N$BsConsole.get(undefined, "Username and password");
    default:
      return "None";
  }
}

var AuthType = {
  toString: toString,
  fromString: fromString,
  toFriendlyString: toFriendlyString
};

function revisionValid(revision) {
  return Belt_Option.map(revision, (function (revision) {
                return revision.trim().length > 0;
              })) === true;
}

var RepoNameNotUnique = Caml_exceptions.create("Repository-BsConsole.RepoNameNotUnique");

function fromJson(json) {
  return {
          id: Json_decode.optional((function (param) {
                  return Json_decode.field("id", Json_decode.$$int, param);
                }), json),
          name: Json_decode.field("name", Json_decode.string, json),
          projectName: Json_decode.field("project_name", Json_decode.string, json),
          universeName: Json_decode.field("universe_name", Json_decode.string, json),
          url: Json_decode.field("url", Json_decode.string, json),
          defaultCommit: Json_decode.field("default_commit", Json_decode.string, json),
          authType: fromString(Json_decode.field("auth_type", Json_decode.string, json)),
          userName: Json_decode.optional((function (param) {
                  return Json_decode.field("user_name", Json_decode.string, param);
                }), json),
          password: Json_decode.optional((function (param) {
                  return Json_decode.field("password", Json_decode.string, param);
                }), json),
          sshPublicKey: Json_decode.optional((function (param) {
                  return Json_decode.field("ssh_public_key", Json_decode.string, param);
                }), json),
          sshPrivateKey: Json_decode.optional((function (param) {
                  return Json_decode.field("ssh_private_key", Json_decode.string, param);
                }), json),
          position: Json_decode.optional((function (param) {
                  return Json_decode.field("position", Json_decode.$$int, param);
                }), json),
          cloneStatus: Json_decode.optional((function (param) {
                  return Json_decode.field("clone_status", Json_decode.string, param);
                }), json),
          cloneError: Json_decode.optional((function (param) {
                  return Json_decode.field("clone_error", Json_decode.string, param);
                }), json),
          autoclone: Belt_Option.mapWithDefault(Json_decode.optional((function (param) {
                      return Json_decode.field("autoclone_submodules", Json_decode.$$int, param);
                    }), json), false, (function (ac) {
                  return ac !== 0;
                }))
        };
}

function toJson(t) {
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "id",
                Json_encode.nullable((function (prim) {
                        return prim;
                      }), t.id)
              ],
              /* :: */[
                /* tuple */[
                  "name",
                  t.name
                ],
                /* :: */[
                  /* tuple */[
                    "project_name",
                    t.projectName
                  ],
                  /* :: */[
                    /* tuple */[
                      "universe_name",
                      t.universeName
                    ],
                    /* :: */[
                      /* tuple */[
                        "url",
                        t.url
                      ],
                      /* :: */[
                        /* tuple */[
                          "default_commit",
                          t.defaultCommit
                        ],
                        /* :: */[
                          /* tuple */[
                            "auth_type",
                            toString(t.authType)
                          ],
                          /* :: */[
                            /* tuple */[
                              "user_name",
                              Json_encode.nullable((function (prim) {
                                      return prim;
                                    }), t.userName)
                            ],
                            /* :: */[
                              /* tuple */[
                                "password",
                                Json_encode.nullable((function (prim) {
                                        return prim;
                                      }), t.password)
                              ],
                              /* :: */[
                                /* tuple */[
                                  "ssh_public_key",
                                  Json_encode.nullable((function (prim) {
                                          return prim;
                                        }), t.sshPublicKey)
                                ],
                                /* :: */[
                                  /* tuple */[
                                    "ssh_private_key",
                                    Json_encode.nullable((function (prim) {
                                            return prim;
                                          }), t.sshPrivateKey)
                                  ],
                                  /* :: */[
                                    /* tuple */[
                                      "position",
                                      Json_encode.nullable((function (prim) {
                                              return prim;
                                            }), t.position)
                                    ],
                                    /* :: */[
                                      /* tuple */[
                                        "autoclone_submodules",
                                        t.autoclone ? 1 : 0
                                      ],
                                      /* [] */0
                                    ]
                                  ]
                                ]
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]);
}

function getServerVersion(projectName, config) {
  var endpoint = Configuration$BsConsole.getEndpointWithProjectOverrides(projectName, undefined, config, "sourcecode");
  var path = endpoint !== undefined ? endpoint : "http://localhost:6451/api/sourcecode";
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(path) + "/version")]), undefined, /* Get */0, undefined, (function (param) {
                return /* Ok */Block.__(0, [Json_decode.field("version", Json_decode.string, param.json)]);
              }), undefined, undefined);
}

function fetchRepositories(projectName, universeName, config) {
  var endpoint = Configuration$BsConsole.getEndpointWithProjectOverrides(projectName, undefined, config, "sourcecode");
  var path = endpoint !== undefined ? endpoint : "http://localhost:6451/api/sourcecode";
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(path) + ("/" + (String(universeName) + ("/" + (String(projectName) + "/repositories")))))]), undefined, /* Get */0, undefined, (function (param) {
                return /* Ok */Block.__(0, [Json_decode.field("repositories", (function (param) {
                                  return Json_decode.list(fromJson, param);
                                }), param.json)]);
              }), undefined, undefined);
}

function saveRepository(repo, universeName, projectName, config) {
  var endpoint = Configuration$BsConsole.getEndpointWithProjectOverrides(projectName, undefined, config, "sourcecode");
  var path = endpoint !== undefined ? endpoint : "http://localhost:6451/api/sourcecode";
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(path) + ("/" + (String(universeName) + ("/" + (String(projectName) + "/repositories")))))]), undefined, /* Post */Block.__(0, [toJson(repo)]), undefined, (function (param) {
                var json = param.json;
                try {
                  return /* Ok */Block.__(0, [Json_decode.field("repository", fromJson, json)]);
                }
                catch (parseErr){
                  var errStr = Json_decode.field("error", Json_decode.string, json);
                  if (errStr === "UNIQUE constraint: repositories.name") {
                    return /* Error */Block.__(1, [RepoNameNotUnique]);
                  }
                  throw parseErr;
                }
              }), undefined, undefined);
}

function updateRepository(id, repo, commitRules, universeName, projectName, config, param) {
  var endpoint = Configuration$BsConsole.getEndpointWithProjectOverrides(projectName, undefined, config, "sourcecode");
  var path = endpoint !== undefined ? endpoint : "http://localhost:6451/api/sourcecode";
  var data = toJson(repo);
  var match = List.length(commitRules) > 0;
  var match$1 = Js_json.decodeObject(data);
  var data$prime;
  if (match && match$1 !== undefined) {
    var dataDict = Caml_option.valFromOption(match$1);
    var commitRules$prime = Belt_List.keepMap(Belt_List.map(Belt_List.sort(commitRules, (function (a, b) {
                    return Caml_primitive.caml_int_compare(a.position, b.position);
                  })), (function (cr) {
                return cr.revision;
              })), Util$BsConsole.identity);
    dataDict["commit_rules"] = Json_encode.list((function (prim) {
            return prim;
          }), commitRules$prime);
    data$prime = dataDict;
  } else {
    data$prime = data;
  }
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(path) + ("/" + (String(universeName) + ("/" + (String(projectName) + ("/repositories/" + (String(id) + "")))))))]), undefined, /* Put */Block.__(1, [data$prime]), undefined, (function (param) {
                return /* Ok */Block.__(0, [Json_decode.field("repository", fromJson, param.json)]);
              }), undefined, undefined);
}

var RepoTestError = Caml_exceptions.create("Repository-BsConsole.RepoTestError");

function testRepositoryFetching(id, universeName, projectName, config, param) {
  var endpoint = Configuration$BsConsole.getEndpointWithProjectOverrides(projectName, undefined, config, "sourcecode");
  var path = endpoint !== undefined ? endpoint : "http://localhost:6451/api/sourcecode";
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(path) + ("/" + (String(universeName) + ("/" + (String(projectName) + ("/repositories/" + (String(id) + "/fetch")))))))]), undefined, /* Get */0, undefined, (function (param) {
                if (param.ok) {
                  return /* Ok */Block.__(0, [undefined]);
                } else {
                  return /* Error */Block.__(1, [[
                              RepoTestError,
                              Json_decode.field("error_message", Json_decode.string, param.json)
                            ]]);
                }
              }), undefined, undefined);
}

function deleteRepo(id, universeName, projectName, config, param) {
  var endpoint = Configuration$BsConsole.getEndpointWithProjectOverrides(projectName, undefined, config, "sourcecode");
  var path = endpoint !== undefined ? endpoint : "http://localhost:6451/api/sourcecode";
  return Task2$BsConsole.make(/* Custom */Block.__(1, ["" + (String(path) + ("/" + (String(universeName) + ("/" + (String(projectName) + ("/repositories/" + (String(id) + "")))))))]), undefined, /* Delete */1, undefined, (function (param) {
                if (param.ok) {
                  return /* Ok */Block.__(0, [undefined]);
                } else {
                  return /* Error */Block.__(1, [Task2$BsConsole.UnknownError]);
                }
              }), undefined, undefined);
}

function Repository$Create(Props) {
  var config = Props.config;
  var projectName = Props.projectName;
  var token = Props.token;
  var refetchRepositories = Props.refetchRepositories;
  var close = Props.close;
  var supportsAutoSubmodules = Props.supportsAutoSubmodules;
  var match = React.useState((function () {
          return /* NoAuth */0;
        }));
  var setAuthType = match[1];
  var authType = match[0];
  var match$1 = React.useState((function () {
          
        }));
  var setPubKey = match$1[1];
  var pubKey = match$1[0];
  var match$2 = React.useState((function () {
          
        }));
  var setPrivKey = match$2[1];
  var privKey = match$2[0];
  var match$3 = React.useState((function () {
          
        }));
  var setUserName = match$3[1];
  var userName = match$3[0];
  var match$4 = React.useState((function () {
          
        }));
  var setPassword = match$4[1];
  var password = match$4[0];
  var match$5 = React.useState((function () {
          return false;
        }));
  var setAutoclone = match$5[1];
  var autoclone = match$5[0];
  var match$6 = React.useState((function () {
          
        }));
  var setRepoName = match$6[1];
  var repoName = match$6[0];
  var match$7 = React.useState((function () {
          
        }));
  var setUrl = match$7[1];
  var url = match$7[0];
  var match$8 = React.useState((function () {
          return "master";
        }));
  var setDefaultCommit = match$8[1];
  var defaultCommit = match$8[0];
  var match$9 = React.useState((function () {
          return /* [] */0;
        }));
  var setRevisions = match$9[1];
  var revisions = match$9[0];
  var match$10 = React.useState((function () {
          
        }));
  var setErrorMsg = match$10[1];
  var errorMsg = match$10[0];
  var universeName = config.universe.name;
  var repoFormValid = function (param) {
    var match = defaultCommit !== "";
    if (repoName === undefined) {
      return false;
    }
    if (url === undefined) {
      return false;
    }
    switch (authType) {
      case /* NoAuth */0 :
          if (match) {
            return true;
          } else {
            return false;
          }
      case /* UserPass */1 :
          if (userName !== undefined && password !== undefined && match) {
            return true;
          } else {
            return false;
          }
      case /* SSH */2 :
          if (pubKey !== undefined && privKey !== undefined && match) {
            return true;
          } else {
            return false;
          }
      
    }
  };
  var tmp;
  switch (authType) {
    case /* NoAuth */0 :
        tmp = null;
        break;
    case /* UserPass */1 :
        tmp = React.createElement(React.Fragment, undefined, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Caption */11,
                  className: Css.style(/* :: */[
                        Css.color(Css.hex(Colors$BsConsole.blackA54)),
                        /* :: */[
                          Css.lineHeight(Css.em(1.375)),
                          /* :: */[
                            Css.marginBottom(Css.rem(0.25)),
                            /* :: */[
                              Css.display(/* block */888960333),
                              /* [] */0
                            ]
                          ]
                        ]
                      ]),
                  children: I18N$BsConsole.show(undefined, "Username")
                }), React.createElement(BtSettings$BsConsole.FormInput.make, {
                  placeholder: "",
                  value: Belt_Option.mapWithDefault(userName, "", Util$BsConsole.identity),
                  onChange: (function ($$event) {
                      var userName = BtSettings$BsConsole.getInputValue($$event);
                      return Curry._1(setUserName, (function (param) {
                                    if (userName.length > 0) {
                                      return userName;
                                    }
                                    
                                  }));
                    }),
                  className: Css.style(/* :: */[
                        Css.width(Css.pct(95)),
                        /* [] */0
                      ])
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Caption */11,
                  className: Css.style(/* :: */[
                        Css.color(Css.hex(Colors$BsConsole.blackA54)),
                        /* :: */[
                          Css.lineHeight(Css.em(1.375)),
                          /* :: */[
                            Css.marginBottom(Css.rem(0.25)),
                            /* :: */[
                              Css.display(/* block */888960333),
                              /* [] */0
                            ]
                          ]
                        ]
                      ]),
                  children: I18N$BsConsole.show(undefined, "Password")
                }), React.createElement(BtSettings$BsConsole.FormInput.make, {
                  placeholder: "",
                  value: Belt_Option.mapWithDefault(password, "", Util$BsConsole.identity),
                  onChange: (function ($$event) {
                      var password = BtSettings$BsConsole.getInputValue($$event);
                      return Curry._1(setPassword, (function (param) {
                                    if (password.length > 0) {
                                      return password;
                                    }
                                    
                                  }));
                    }),
                  className: Css.style(/* :: */[
                        Css.width(Css.pct(95)),
                        /* [] */0
                      ])
                }));
        break;
    case /* SSH */2 :
        tmp = React.createElement(React.Fragment, undefined, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Caption */11,
                  className: Css.style(/* :: */[
                        Css.color(Css.hex(Colors$BsConsole.blackA54)),
                        /* :: */[
                          Css.lineHeight(Css.em(1.375)),
                          /* :: */[
                            Css.marginBottom(Css.rem(0.25)),
                            /* :: */[
                              Css.display(/* block */888960333),
                              /* [] */0
                            ]
                          ]
                        ]
                      ]),
                  children: I18N$BsConsole.show(undefined, "SSH public key")
                }), React.createElement(BtSettings$BsConsole.FormInput.make, {
                  placeholder: "",
                  value: Belt_Option.mapWithDefault(pubKey, "", Util$BsConsole.identity),
                  onChange: (function ($$event) {
                      var pubKey = BtSettings$BsConsole.getInputValue($$event);
                      return Curry._1(setPubKey, (function (param) {
                                    if (pubKey.length > 0) {
                                      return pubKey;
                                    }
                                    
                                  }));
                    }),
                  rows: 10,
                  multiline: true,
                  className: Css.style(/* :: */[
                        Css.width(Css.pct(95)),
                        /* [] */0
                      ])
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Caption */11,
                  className: Css.style(/* :: */[
                        Css.color(Css.hex(Colors$BsConsole.blackA54)),
                        /* :: */[
                          Css.lineHeight(Css.em(1.375)),
                          /* :: */[
                            Css.marginBottom(Css.rem(0.25)),
                            /* :: */[
                              Css.display(/* block */888960333),
                              /* [] */0
                            ]
                          ]
                        ]
                      ]),
                  children: I18N$BsConsole.show(undefined, "SSH private key")
                }), React.createElement(BtSettings$BsConsole.FormInput.make, {
                  placeholder: "",
                  value: Belt_Option.mapWithDefault(privKey, "", Util$BsConsole.identity),
                  onChange: (function ($$event) {
                      var privKey = BtSettings$BsConsole.getInputValue($$event);
                      return Curry._1(setPrivKey, (function (param) {
                                    if (privKey.length > 0) {
                                      return privKey;
                                    }
                                    
                                  }));
                    }),
                  rows: 10,
                  multiline: true,
                  className: Css.style(/* :: */[
                        Css.width(Css.pct(95)),
                        /* [] */0
                      ])
                }));
        break;
    
  }
  var supportLink = Href$BsConsole.Support.make(true, /* SettingsAttributes */21);
  return React.createElement(React.Fragment, undefined, React.createElement(Mui$BsConsole.IconButton.make, {
                  ariaLabel: "Close",
                  classes: {
                    root: Css.style(/* :: */[
                          Css.height(Css.px(48)),
                          /* :: */[
                            Css.position(/* absolute */-1013592457),
                            /* :: */[
                              Css.color(Css.hex(Colors$BsConsole.blackA54)),
                              /* :: */[
                                Css.top(/* zero */-789508312),
                                /* :: */[
                                  Css.right(/* zero */-789508312),
                                  /* [] */0
                                ]
                              ]
                            ]
                          ]
                        ])
                  },
                  color: "primary",
                  onClick: (function (_event) {
                      return Curry._1(close, undefined);
                    }),
                  children: React.createElement(Clear.default, { })
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Heading5 */4,
                  children: I18N$BsConsole.show(undefined, "Add repository")
                }), React.createElement(BtSettings$BsConsole.FancyDivider.make, {
                  className: Css.style(/* :: */[
                        Css.margin2(Css.rem(0.5), /* zero */-789508312),
                        /* :: */[
                          Css.height(Css.px(4)),
                          /* [] */0
                        ]
                      ])
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Caption */11,
                  className: Css.style(/* :: */[
                        Css.color(Css.hex(Colors$BsConsole.blackA54)),
                        /* :: */[
                          Css.lineHeight(Css.em(1.375)),
                          /* :: */[
                            Css.marginBottom(Css.rem(0.25)),
                            /* :: */[
                              Css.display(/* block */888960333),
                              /* [] */0
                            ]
                          ]
                        ]
                      ]),
                  children: I18N$BsConsole.show(undefined, "Name")
                }), React.createElement(BtSettings$BsConsole.FormInput.make, {
                  placeholder: "",
                  value: repoName !== undefined ? repoName : "",
                  onChange: (function ($$event) {
                      var repoName = $$event.target.value;
                      return Curry._1(setRepoName, (function (param) {
                                    if (repoName.length > 0) {
                                      return repoName;
                                    }
                                    
                                  }));
                    }),
                  className: Css.style(/* :: */[
                        Css.width(Css.pct(95)),
                        /* [] */0
                      ])
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Caption */11,
                  className: Css.style(/* :: */[
                        Css.color(Css.hex(Colors$BsConsole.blackA54)),
                        /* :: */[
                          Css.lineHeight(Css.em(1.375)),
                          /* :: */[
                            Css.marginBottom(Css.rem(0.25)),
                            /* :: */[
                              Css.display(/* block */888960333),
                              /* [] */0
                            ]
                          ]
                        ]
                      ]),
                  children: I18N$BsConsole.show(undefined, "URL")
                }), React.createElement(BtSettings$BsConsole.FormInput.make, {
                  placeholder: "",
                  value: Belt_Option.mapWithDefault(url, "", Util$BsConsole.identity),
                  onChange: (function ($$event) {
                      var url = BtSettings$BsConsole.getInputValue($$event);
                      if (Regex$BsConsole.isPossiblySSHURL(url)) {
                        Curry._1(setAuthType, (function (param) {
                                return /* SSH */2;
                              }));
                      }
                      return Curry._1(setUrl, (function (param) {
                                    if (url.length > 0) {
                                      return url;
                                    }
                                    
                                  }));
                    }),
                  className: Css.style(/* :: */[
                        Css.width(Css.pct(95)),
                        /* [] */0
                      ])
                }), React.createElement(React.Fragment, undefined, React.createElement(Divider.default, {
                      className: Css.style(/* :: */[
                            Css.marginBottom(Css.rem(0.25)),
                            /* [] */0
                          ])
                    }), React.createElement(BtTypography$BsConsole.make, {
                      variant: /* Caption */11,
                      className: Css.style(/* :: */[
                            Css.color(Css.hex(Colors$BsConsole.blackA54)),
                            /* :: */[
                              Css.lineHeight(Css.em(1.375)),
                              /* :: */[
                                Css.marginBottom(Css.rem(0.25)),
                                /* :: */[
                                  Css.display(/* block */888960333),
                                  /* [] */0
                                ]
                              ]
                            ]
                          ]),
                      children: I18N$BsConsole.show(undefined, "Authentication method")
                    }), React.createElement(Select.default, {
                      value: toString(authType),
                      onChange: (function (e, param) {
                          var value = fromString(e.target.value);
                          return Curry._1(setAuthType, (function (param) {
                                        return value;
                                      }));
                        }),
                      renderValue: (function (v) {
                          return React.createElement(BtTypography$BsConsole.make, {
                                      variant: /* Caption */11,
                                      children: I18N$BsConsole.showSkip(toFriendlyString(v))
                                    });
                        }),
                      disableUnderline: true,
                      className: Css.style(/* :: */[
                            Css.width(Css.pct(95)),
                            /* :: */[
                              Css.backgroundColor(Css.hex(Colors$BsConsole.blackA12)),
                              /* :: */[
                                Css.paddingLeft(Css.rem(0.75)),
                                /* :: */[
                                  Css.marginBottom(Css.rem(1)),
                                  /* [] */0
                                ]
                              ]
                            ]
                          ]),
                      children: null
                    }, React.createElement(MenuItem.default, {
                          value: "none",
                          children: React.createElement(BtTypography$BsConsole.make, {
                                variant: /* Caption */11,
                                children: I18N$BsConsole.show(undefined, "None")
                              })
                        }), React.createElement(MenuItem.default, {
                          value: "user_pass",
                          children: React.createElement(BtTypography$BsConsole.make, {
                                variant: /* Caption */11,
                                children: I18N$BsConsole.show(undefined, "Username and password")
                              })
                        }), React.createElement(MenuItem.default, {
                          value: "ssh_key",
                          children: React.createElement(BtTypography$BsConsole.make, {
                                variant: /* Caption */11,
                                children: I18N$BsConsole.show(undefined, "SSH key")
                              })
                        })), tmp), React.createElement(Divider.default, {
                  className: Css.style(/* :: */[
                        Css.marginBottom(Css.rem(0.25)),
                        /* [] */0
                      ])
                }), React.createElement(Row2$BsConsole.make, {
                  alignItems: /* flexEnd */924268066,
                  className: Css.style(/* :: */[
                        Css.marginBottom(Css.rem(0.5)),
                        /* :: */[
                          Css.width(Css.pct(100)),
                          /* [] */0
                        ]
                      ]),
                  children: React.createElement(Col2$BsConsole.make, {
                        justifyContent: /* spaceBetween */516682146,
                        className: Css.style(/* :: */[
                              Css.width(Css.pct(95)),
                              /* [] */0
                            ]),
                        children: null
                      }, React.createElement(BtTypography$BsConsole.make, {
                            variant: /* Caption */11,
                            className: Css.style(/* :: */[
                                  Css.color(Css.hex(Colors$BsConsole.blackA54)),
                                  /* :: */[
                                    Css.lineHeight(Css.em(1.375)),
                                    /* [] */0
                                  ]
                                ]),
                            children: React.createElement(Row2$BsConsole.make, {
                                  alignItems: /* center */98248149,
                                  className: Css.style(/* :: */[
                                        Css.marginBottom(Css.rem(0.5)),
                                        /* [] */0
                                      ]),
                                  children: null
                                }, React.createElement("span", {
                                      className: Css.style(/* :: */[
                                            Css.marginRight(Css.rem(0.5)),
                                            /* [] */0
                                          ])
                                    }, I18N$BsConsole.show(undefined, "Revisions")), React.createElement(Tooltip.default, {
                                      classes: {
                                        tooltip: Css.style(/* :: */[
                                              Css.backgroundColor(Css.hex(Colors$BsConsole.grey15)),
                                              /* [] */0
                                            ])
                                      },
                                      interactive: true,
                                      children: React.createElement(Info.default, {
                                            className: Css.style(/* :: */[
                                                  Css.fontSize(Css.rem(1)),
                                                  /* [] */0
                                                ])
                                          }),
                                      title: React.createElement(BtTypography$BsConsole.make, {
                                            variant: /* Body1 */8,
                                            className: Css.style(/* :: */[
                                                  Css.lineHeight(Css.em(1.375)),
                                                  /* [] */0
                                                ]),
                                            children: null
                                          }, React.createElement("div", {
                                                className: Css.style(/* :: */[
                                                      Css.marginBottom(Css.em(0.5)),
                                                      /* [] */0
                                                    ])
                                              }, I18N$BsConsole.show(undefined, "Specify a list of git revisions to try, in order, when searching for matching source code.")), React.createElement("div", {
                                                className: Css.style(/* :: */[
                                                      Css.marginBottom(Css.em(0.5)),
                                                      /* [] */0
                                                    ])
                                              }, I18N$BsConsole.show(undefined, "Acceptable revision types include branch names, tags, as well as short and long SHA-1 hashes.")), supportLink !== undefined ? React.createElement("div", {
                                                  className: Css.style(/* :: */[
                                                        Css.marginBottom(Css.em(0.5)),
                                                        /* [] */0
                                                      ])
                                                }, I18N$BsConsole.show(undefined, "You can specify an "), React.createElement("a", {
                                                      className: "link",
                                                      href: supportLink,
                                                      rel: "noreferrer",
                                                      target: "_blank"
                                                    }, I18N$BsConsole.show(undefined, "attribute")), I18N$BsConsole.show(undefined, " to be dynamically included in the revision string by using {attribute} template syntax.")) : null, React.createElement("div", undefined, I18N$BsConsole.show(undefined, "Some examples:")), React.createElement("div", {
                                                className: Css.style(/* :: */[
                                                      Css.paddingLeft(Css.em(0.5)),
                                                      /* [] */0
                                                    ])
                                              }, I18N$BsConsole.showSkip("release/{version}")), React.createElement("div", {
                                                className: Css.style(/* :: */[
                                                      Css.paddingLeft(Css.em(0.5)),
                                                      /* [] */0
                                                    ])
                                              }, I18N$BsConsole.showSkip("{commit}")), React.createElement("div", {
                                                className: Css.style(/* :: */[
                                                      Css.paddingLeft(Css.em(0.5)),
                                                      /* [] */0
                                                    ])
                                              }, I18N$BsConsole.showSkip("729e0d9a")), React.createElement("div", {
                                                className: Css.style(/* :: */[
                                                      Css.paddingLeft(Css.em(0.5)),
                                                      /* [] */0
                                                    ])
                                              }, I18N$BsConsole.showSkip("master")), React.createElement("div", {
                                                className: Css.style(/* :: */[
                                                      Css.paddingLeft(Css.em(0.5)),
                                                      /* :: */[
                                                        Css.marginBottom(Css.em(0.5)),
                                                        /* [] */0
                                                      ]
                                                    ])
                                              }, I18N$BsConsole.showSkip("main")))
                                    }))
                          }), Belt_List.toArray(Belt_List.mapWithIndex(Belt_List.sort(revisions, (function (a, b) {
                                      return Caml_primitive.caml_int_compare(a.position, b.position);
                                    })), (function (idx, rev) {
                                  return React.createElement(Row2$BsConsole.make, {
                                              alignItems: /* center */98248149,
                                              className: Css.style(/* :: */[
                                                    Css.width(Css.pct(100)),
                                                    /* :: */[
                                                      Css.marginBottom(Css.rem(0.5)),
                                                      /* [] */0
                                                    ]
                                                  ]),
                                              children: null
                                            }, React.createElement(BtSettings$BsConsole.FormInput.make, {
                                                  placeholder: "release/{version}",
                                                  value: Belt_Option.mapWithDefault(rev.revision, "", Util$BsConsole.identity),
                                                  onChange: (function ($$event) {
                                                      var newRev = BtSettings$BsConsole.getInputValue($$event);
                                                      return Curry._1(setRevisions, (function (param) {
                                                                    return Belt_List.mapWithIndex(revisions, (function (i, rev) {
                                                                                  if (i === idx) {
                                                                                    return {
                                                                                            revision: newRev,
                                                                                            position: rev.position
                                                                                          };
                                                                                  } else {
                                                                                    return rev;
                                                                                  }
                                                                                }));
                                                                  }));
                                                    }),
                                                  error: !revisionValid(rev.revision),
                                                  className: Css.style(/* :: */[
                                                        Css.width(Css.pct(93)),
                                                        /* :: */[
                                                          Css.marginBottom(/* zero */-789508312),
                                                          /* :: */[
                                                            Css.marginTop(/* zero */-789508312),
                                                            /* [] */0
                                                          ]
                                                        ]
                                                      ])
                                                }), React.createElement(Row2$BsConsole.make, {
                                                  alignItems: /* center */98248149,
                                                  className: Css.style(/* :: */[
                                                        Css.marginLeft(Css.rem(0.5)),
                                                        /* [] */0
                                                      ]),
                                                  children: null
                                                }, React.createElement(Mui$BsConsole.IconButton.make, {
                                                      ariaLabel: "Move up",
                                                      classes: {
                                                        root: Css.style(/* :: */[
                                                              Css.padding(Css.px(4)),
                                                              /* [] */0
                                                            ])
                                                      },
                                                      onClick: (function (param) {
                                                          var oldPos = rev.position;
                                                          var newPos = rev.position - 1 | 0;
                                                          if (newPos < 0) {
                                                            return ;
                                                          }
                                                          var updatedRevs = Belt_List.sort(Belt_List.map(revisions, (function (revision) {
                                                                      var match = newPos - revision.position | 0;
                                                                      if (match !== -1) {
                                                                        if (match !== 0) {
                                                                          return revision;
                                                                        } else {
                                                                          return {
                                                                                  revision: revision.revision,
                                                                                  position: oldPos
                                                                                };
                                                                        }
                                                                      } else {
                                                                        return {
                                                                                revision: revision.revision,
                                                                                position: newPos
                                                                              };
                                                                      }
                                                                    })), (function (a, b) {
                                                                  return Caml_primitive.caml_int_compare(a.position, b.position);
                                                                }));
                                                          return Curry._1(setRevisions, (function (param) {
                                                                        return updatedRevs;
                                                                      }));
                                                        }),
                                                      children: React.createElement(ArrowUpward.default, { })
                                                    }), React.createElement(Mui$BsConsole.IconButton.make, {
                                                      ariaLabel: "Move down",
                                                      classes: {
                                                        root: Css.style(/* :: */[
                                                              Css.padding(Css.px(4)),
                                                              /* :: */[
                                                                Css.marginLeft(Css.rem(0.5)),
                                                                /* [] */0
                                                              ]
                                                            ])
                                                      },
                                                      onClick: (function (param) {
                                                          var len = Belt_List.length(revisions);
                                                          var oldPos = rev.position;
                                                          var newPos = rev.position + 1 | 0;
                                                          if (newPos === len) {
                                                            return ;
                                                          }
                                                          var updatedRevs = Belt_List.sort(Belt_List.map(revisions, (function (revision) {
                                                                      var match = newPos - revision.position | 0;
                                                                      if (match !== 0) {
                                                                        if (match !== 1) {
                                                                          return revision;
                                                                        } else {
                                                                          return {
                                                                                  revision: revision.revision,
                                                                                  position: newPos
                                                                                };
                                                                        }
                                                                      } else {
                                                                        return {
                                                                                revision: revision.revision,
                                                                                position: oldPos
                                                                              };
                                                                      }
                                                                    })), (function (a, b) {
                                                                  return Caml_primitive.caml_int_compare(a.position, b.position);
                                                                }));
                                                          return Curry._1(setRevisions, (function (param) {
                                                                        return updatedRevs;
                                                                      }));
                                                        }),
                                                      children: React.createElement(ArrowDownward.default, { })
                                                    }), React.createElement(Mui$BsConsole.IconButton.make, {
                                                      ariaLabel: "Delete",
                                                      classes: {
                                                        root: Css.style(/* :: */[
                                                              Css.padding(Css.px(4)),
                                                              /* :: */[
                                                                Css.marginLeft(Css.rem(0.5)),
                                                                /* [] */0
                                                              ]
                                                            ])
                                                      },
                                                      onClick: (function (param) {
                                                          var pos = rev.position;
                                                          var updatedRevisions = Belt_List.map(Belt_List.filter(revisions, (function (revision) {
                                                                      return revision.position !== pos;
                                                                    })), (function (revision) {
                                                                  if (revision.position > pos) {
                                                                    return {
                                                                            revision: revision.revision,
                                                                            position: revision.position - 1 | 0
                                                                          };
                                                                  } else {
                                                                    return revision;
                                                                  }
                                                                }));
                                                          return Curry._1(setRevisions, (function (param) {
                                                                        return updatedRevisions;
                                                                      }));
                                                        }),
                                                      children: React.createElement(Icon.default, {
                                                            children: I18N$BsConsole.showSkip("delete")
                                                          })
                                                    })));
                                }))), React.createElement(BtSettings$BsConsole.FormInput.make, {
                            placeholder: "release/{version}",
                            value: defaultCommit,
                            onChange: (function ($$event) {
                                var newStr = BtSettings$BsConsole.getInputValue($$event);
                                return Curry._1(setDefaultCommit, (function (param) {
                                              return newStr;
                                            }));
                              }),
                            error: defaultCommit === "",
                            className: Css.style(/* :: */[
                                  Css.width(Css.pct(100)),
                                  /* :: */[
                                    Css.marginBottom(/* zero */-789508312),
                                    /* [] */0
                                  ]
                                ])
                          }))
                }), React.createElement(Button.default, {
                  variant: "outlined",
                  color: "primary",
                  classes: {
                    root: Css.style(/* :: */[
                          Css.marginBottom(Css.rem(0.5)),
                          /* :: */[
                            Css.marginTop(Css.rem(0.25)),
                            /* [] */0
                          ]
                        ])
                  },
                  onClick: (function (param) {
                      return Curry._1(setRevisions, (function (revisions) {
                                    return Belt_List.concat(revisions, /* :: */[
                                                {
                                                  revision: undefined,
                                                  position: Belt_List.length(revisions)
                                                },
                                                /* [] */0
                                              ]);
                                  }));
                    }),
                  children: I18N$BsConsole.show(undefined, "Add")
                }), supportsAutoSubmodules ? React.createElement("div", undefined, React.createElement(Divider.default, {
                        className: Css.style(/* :: */[
                              Css.marginBottom(Css.rem(0.5)),
                              /* [] */0
                            ])
                      }), React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Caption */11,
                        className: Css.style(/* :: */[
                              Css.color(Css.hex(Colors$BsConsole.blackA54)),
                              /* :: */[
                                Css.lineHeight(Css.em(1.375)),
                                /* [] */0
                              ]
                            ]),
                        children: React.createElement(Row2$BsConsole.make, {
                              alignItems: /* center */98248149,
                              className: Css.style(/* :: */[
                                    Css.marginBottom(Css.rem(0.25)),
                                    /* [] */0
                                  ]),
                              children: null
                            }, React.createElement("span", {
                                  className: Css.style(/* :: */[
                                        Css.marginRight(Css.rem(0.5)),
                                        /* [] */0
                                      ])
                                }, I18N$BsConsole.show(undefined, "Submodules")), React.createElement(Tooltip.default, {
                                  classes: {
                                    tooltip: Css.style(/* :: */[
                                          Css.backgroundColor(Css.hex(Colors$BsConsole.grey15)),
                                          /* [] */0
                                        ])
                                  },
                                  interactive: true,
                                  children: React.createElement(Info.default, {
                                        className: Css.style(/* :: */[
                                              Css.fontSize(Css.rem(1)),
                                              /* [] */0
                                            ])
                                      }),
                                  title: React.createElement(BtTypography$BsConsole.make, {
                                        variant: /* Body1 */8,
                                        className: Css.style(/* :: */[
                                              Css.lineHeight(Css.em(1.375)),
                                              /* [] */0
                                            ]),
                                        children: null
                                      }, I18N$BsConsole.show(undefined, "When this checkbox is set, the system will automatically clone any submodules that it discovers while performing a source code lookup. "), I18N$BsConsole.show(undefined, "These submodules will be listed alongside other repositories within this config section. "), I18N$BsConsole.show(undefined, "If the clone operation completes quickly, then its contents will will be considered for the current source code lookup. "), I18N$BsConsole.show(undefined, "If it takes longer to clone, then its contents will be available on subsequent lookups."))
                                }))
                      }), React.createElement(FormControlLabel.default, {
                        label: "Automatically clone submodules",
                        control: React.createElement(Checkbox.default, {
                              checked: autoclone,
                              color: "primary",
                              classes: {
                                root: Css.style(/* :: */[
                                      Css.color(Css.hex(Colors$BsConsole.grey4)),
                                      /* :: */[
                                        Css.padding2(Css.rem(0.25), /* zero */-789508312),
                                        /* :: */[
                                          Css.paddingRight(Css.rem(0.5)),
                                          /* :: */[
                                            Css.selector("svg", /* :: */[
                                                  Css.width(Css.rem(0.9)),
                                                  /* :: */[
                                                    Css.height(Css.rem(0.9)),
                                                    /* [] */0
                                                  ]
                                                ]),
                                            /* [] */0
                                          ]
                                        ]
                                      ]
                                    ])
                              },
                              onChange: (function (e) {
                                  var value = e.target.checked;
                                  return Curry._1(setAutoclone, (function (param) {
                                                return value;
                                              }));
                                }),
                              disableRipple: true
                            }),
                        classes: {
                          root: Css.style(/* :: */[
                                Css.margin(/* zero */-789508312),
                                /* :: */[
                                  Css.userSelect(Css.none),
                                  /* :: */[
                                    Css.marginRight(Css.rem(1.5)),
                                    /* :: */[
                                      Css.marginBottom(Css.rem(0.5)),
                                      /* [] */0
                                    ]
                                  ]
                                ]
                              ])
                        }
                      })) : null, React.createElement("div", undefined, React.createElement(Divider.default, {
                      className: Css.style(/* :: */[
                            Css.marginBottom(Css.rem(0.5)),
                            /* [] */0
                          ])
                    }), React.createElement(Button.default, {
                      variant: "contained",
                      color: "primary",
                      disabled: !repoFormValid(undefined),
                      onClick: (function (param) {
                          var repo;
                          if (repoName !== undefined && url !== undefined) {
                            switch (authType) {
                              case /* NoAuth */0 :
                                  repo = {
                                    id: undefined,
                                    name: repoName,
                                    projectName: projectName,
                                    universeName: universeName,
                                    url: url,
                                    defaultCommit: defaultCommit,
                                    authType: authType,
                                    userName: undefined,
                                    password: undefined,
                                    sshPublicKey: undefined,
                                    sshPrivateKey: undefined,
                                    position: undefined,
                                    cloneStatus: undefined,
                                    cloneError: undefined,
                                    autoclone: autoclone
                                  };
                                  break;
                              case /* UserPass */1 :
                                  repo = userName !== undefined && password !== undefined ? ({
                                        id: undefined,
                                        name: repoName,
                                        projectName: projectName,
                                        universeName: universeName,
                                        url: url,
                                        defaultCommit: defaultCommit,
                                        authType: authType,
                                        userName: userName,
                                        password: password,
                                        sshPublicKey: undefined,
                                        sshPrivateKey: undefined,
                                        position: undefined,
                                        cloneStatus: undefined,
                                        cloneError: undefined,
                                        autoclone: autoclone
                                      }) : undefined;
                                  break;
                              case /* SSH */2 :
                                  repo = pubKey !== undefined && privKey !== undefined ? ({
                                        id: undefined,
                                        name: repoName,
                                        projectName: projectName,
                                        universeName: universeName,
                                        url: url,
                                        defaultCommit: defaultCommit,
                                        authType: authType,
                                        userName: undefined,
                                        password: undefined,
                                        sshPublicKey: pubKey,
                                        sshPrivateKey: privKey,
                                        position: undefined,
                                        cloneStatus: undefined,
                                        cloneError: undefined,
                                        autoclone: autoclone
                                      }) : undefined;
                                  break;
                              
                            }
                          } else {
                            repo = undefined;
                          }
                          if (repo !== undefined) {
                            MetricsEvent$BsConsole.send(/* ActionWithAttrs */Block.__(1, [
                                    /* SourceCode_RepoCreate */81,
                                    Json_encode.object_(/* :: */[
                                          /* tuple */[
                                            "project",
                                            projectName
                                          ],
                                          /* [] */0
                                        ])
                                  ]));
                            Curry._1(setErrorMsg, (function (param) {
                                    
                                  }));
                            return Task2$BsConsole.handle(token, saveRepository(repo, universeName, projectName, config), undefined, (function (newRepository) {
                                          if (newRepository.tag) {
                                            if (newRepository[0] === RepoNameNotUnique) {
                                              return Curry._1(setErrorMsg, (function (param) {
                                                            return "Repository name exists, please choose another.";
                                                          }));
                                            } else {
                                              return Curry._1(setErrorMsg, (function (param) {
                                                            return "Encountered an error while creating repository.";
                                                          }));
                                            }
                                          }
                                          var id = newRepository[0].id;
                                          if (id !== undefined) {
                                            Belt_List.forEachWithIndex(Belt_List.sort(revisions, (function (a, b) {
                                                        return Caml_primitive.caml_int_compare(a.position, b.position);
                                                      })), (function (idx, revStr) {
                                                    var newCommitRule_attributeTemplate = Belt_Option.mapWithDefault(revStr.revision, "", Util$BsConsole.identity);
                                                    var newCommitRule = {
                                                      id: undefined,
                                                      position: idx,
                                                      repositoryId: id,
                                                      attributeTemplate: newCommitRule_attributeTemplate
                                                    };
                                                    return Task2$BsConsole.handle(token, CommitRule$BsConsole.saveCommitRule(newCommitRule, universeName, projectName, config), undefined, (function (_resp) {
                                                                  
                                                                }));
                                                  }));
                                            Curry._1(close, undefined);
                                            return Curry._1(refetchRepositories, undefined);
                                          }
                                          
                                        }));
                          }
                          
                        }),
                      children: I18N$BsConsole.show(undefined, "Create")
                    })), errorMsg !== undefined ? React.createElement(BtTypography$BsConsole.make, {
                    variant: /* Heading4 */3,
                    className: Css.style(/* :: */[
                          Css.marginTop(Css.px(3)),
                          /* :: */[
                            Css.fontSize(Css.px(18)),
                            /* [] */0
                          ]
                        ]),
                    color: Colors$BsConsole.errorDark,
                    children: I18N$BsConsole.showSkip(errorMsg)
                  }) : null);
}

var Create = {
  make: Repository$Create
};

function Repository$TestFetch(Props) {
  var config = Props.config;
  var id = Props.id;
  var projectName = Props.projectName;
  var token = Props.token;
  var close = Props.close;
  var match = React.useState((function () {
          
        }));
  var setResp = match[1];
  var resp = match[0];
  React.useEffect((function () {
          Task2$BsConsole.handle(token, testRepositoryFetching(id, config.universe.name, projectName, config, undefined), undefined, (function (resp) {
                  return Curry._1(setResp, (function (param) {
                                return resp;
                              }));
                }));
          
        }), []);
  var tmp;
  if (resp !== undefined) {
    if (resp.tag) {
      var err = resp[0];
      tmp = err[0] === RepoTestError ? React.createElement(React.Fragment, undefined, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Heading6 */5,
                  className: Css.style(/* :: */[
                        Css.marginBottom(Css.rem(1)),
                        /* [] */0
                      ]),
                  children: I18N$BsConsole.show(undefined, "Error: please check credentials and try again")
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Body1 */8,
                  children: I18N$BsConsole.showSkip(err[1])
                })) : React.createElement(BtTypography$BsConsole.make, {
              variant: /* Heading6 */5,
              children: React.createElement("div", undefined, I18N$BsConsole.show(undefined, "Error: please check credentials and try again"))
            });
    } else {
      tmp = React.createElement(BtTypography$BsConsole.make, {
            variant: /* Heading6 */5,
            children: I18N$BsConsole.show(undefined, "Successfully fetched repository")
          });
    }
  } else {
    tmp = React.createElement(React.Fragment, undefined, React.createElement(BtTypography$BsConsole.make, {
              variant: /* Heading6 */5,
              className: Css.style(/* :: */[
                    Css.marginBottom(Css.rem(1)),
                    /* [] */0
                  ]),
              children: I18N$BsConsole.show(undefined, "Fetching repository, this operation may take some time")
            }), React.createElement(Row2$BsConsole.make, {
              justifyContent: /* center */98248149,
              className: Css.style(/* :: */[
                    Css.width(Css.pct(100)),
                    /* [] */0
                  ]),
              children: React.createElement(CircularProgress.default, { })
            }));
  }
  return React.createElement(Col2$BsConsole.make, {
              children: null
            }, React.createElement(Mui$BsConsole.IconButton.make, {
                  ariaLabel: "Close",
                  classes: {
                    root: Css.style(/* :: */[
                          Css.height(Css.px(48)),
                          /* :: */[
                            Css.position(/* absolute */-1013592457),
                            /* :: */[
                              Css.color(Css.hex(Colors$BsConsole.blackA54)),
                              /* :: */[
                                Css.top(/* zero */-789508312),
                                /* :: */[
                                  Css.right(/* zero */-789508312),
                                  /* [] */0
                                ]
                              ]
                            ]
                          ]
                        ])
                  },
                  color: "primary",
                  onClick: (function (_event) {
                      return Curry._1(close, undefined);
                    }),
                  children: React.createElement(Clear.default, { })
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Heading5 */4,
                  children: I18N$BsConsole.show(undefined, "Testing repository")
                }), React.createElement(BtSettings$BsConsole.FancyDivider.make, {
                  className: Css.style(/* :: */[
                        Css.margin4(Css.rem(0.5), /* zero */-789508312, Css.rem(1.5), /* zero */-789508312),
                        /* :: */[
                          Css.height(Css.px(4)),
                          /* [] */0
                        ]
                      ])
                }), tmp);
}

var TestFetch = {
  make: Repository$TestFetch
};

function Repository$Edit(Props) {
  var config = Props.config;
  var projectName = Props.projectName;
  var token = Props.token;
  var refetchRepositories = Props.refetchRepositories;
  var refetchCommitRules = Props.refetchCommitRules;
  var close = Props.close;
  var repository = Props.repository;
  var supportsAutoSubmodules = Props.supportsAutoSubmodules;
  var match = React.useState((function () {
          return repository;
        }));
  var setRepo = match[1];
  var repo = match[0];
  var match$1 = React.useState((function () {
          return false;
        }));
  var setEditAuth = match$1[1];
  var editAuth = match$1[0];
  var match$2 = React.useState((function () {
          return /* [] */0;
        }));
  var setRevisions = match$2[1];
  var revisions = match$2[0];
  var match$3 = React.useState((function () {
          
        }));
  var setErrorMsg = match$3[1];
  var errorMsg = match$3[0];
  var universeName = config.universe.name;
  var validatedFormFields_000 = repo.name;
  var validatedFormFields_001 = /* :: */[
    repo.url,
    /* :: */[
      repo.defaultCommit,
      /* [] */0
    ]
  ];
  var validatedFormFields = /* :: */[
    validatedFormFields_000,
    validatedFormFields_001
  ];
  var match$4 = repo.authType;
  var validatedFormFields$prime;
  if (editAuth) {
    switch (match$4) {
      case /* NoAuth */0 :
          validatedFormFields$prime = validatedFormFields;
          break;
      case /* UserPass */1 :
          validatedFormFields$prime = /* :: */[
            Belt_Option.mapWithDefault(repo.userName, "", Util$BsConsole.identity),
            /* :: */[
              Belt_Option.mapWithDefault(repo.password, "", Util$BsConsole.identity),
              validatedFormFields
            ]
          ];
          break;
      case /* SSH */2 :
          validatedFormFields$prime = /* :: */[
            Belt_Option.mapWithDefault(repo.sshPublicKey, "", Util$BsConsole.identity),
            /* :: */[
              Belt_Option.mapWithDefault(repo.sshPrivateKey, "", Util$BsConsole.identity),
              validatedFormFields
            ]
          ];
          break;
      
    }
  } else {
    validatedFormFields$prime = validatedFormFields;
  }
  React.useEffect((function () {
          Task2$BsConsole.handle(token, CommitRule$BsConsole.fetchCommitRules(config, repo.id, universeName, projectName), undefined, (function (revisions) {
                  Belt_Result.map(revisions, (function (revisions) {
                          return Curry._1(setRevisions, (function (param) {
                                        return Belt_List.mapWithIndex(Belt_List.sort(revisions, (function (a, b) {
                                                          return Caml_primitive.caml_int_compare(a.position, b.position);
                                                        })), (function (i, r) {
                                                      return {
                                                              revision: r.attributeTemplate,
                                                              position: i
                                                            };
                                                    }));
                                      }));
                        }));
                  
                }));
          
        }), []);
  var tmp;
  if (editAuth) {
    var match$5 = repo.authType;
    var tmp$1;
    switch (match$5) {
      case /* NoAuth */0 :
          tmp$1 = null;
          break;
      case /* UserPass */1 :
          tmp$1 = React.createElement(React.Fragment, undefined, React.createElement(BtTypography$BsConsole.make, {
                    variant: /* Caption */11,
                    className: Css.style(/* :: */[
                          Css.color(Css.hex(Colors$BsConsole.blackA54)),
                          /* :: */[
                            Css.lineHeight(Css.em(1.375)),
                            /* :: */[
                              Css.marginBottom(Css.rem(0.25)),
                              /* :: */[
                                Css.display(/* block */888960333),
                                /* [] */0
                              ]
                            ]
                          ]
                        ]),
                    children: I18N$BsConsole.show(undefined, "Username")
                  }), React.createElement(BtSettings$BsConsole.FormInput.make, {
                    placeholder: I18N$BsConsole.get(undefined, "Update username"),
                    value: Belt_Option.mapWithDefault(repo.userName, "", Util$BsConsole.identity),
                    onChange: (function ($$event) {
                        var userName = BtSettings$BsConsole.getInputValue($$event);
                        return Curry._1(setRepo, (function (repo) {
                                      return {
                                              id: repo.id,
                                              name: repo.name,
                                              projectName: repo.projectName,
                                              universeName: repo.universeName,
                                              url: repo.url,
                                              defaultCommit: repo.defaultCommit,
                                              authType: repo.authType,
                                              userName: Util$BsConsole.nonEmptyStr(userName) ? userName.trim() : undefined,
                                              password: repo.password,
                                              sshPublicKey: repo.sshPublicKey,
                                              sshPrivateKey: repo.sshPrivateKey,
                                              position: repo.position,
                                              cloneStatus: repo.cloneStatus,
                                              cloneError: repo.cloneError,
                                              autoclone: repo.autoclone
                                            };
                                    }));
                      }),
                    className: Css.style(/* :: */[
                          Css.width(Css.pct(95)),
                          /* [] */0
                        ])
                  }), React.createElement(BtTypography$BsConsole.make, {
                    variant: /* Caption */11,
                    className: Css.style(/* :: */[
                          Css.color(Css.hex(Colors$BsConsole.blackA54)),
                          /* :: */[
                            Css.lineHeight(Css.em(1.375)),
                            /* :: */[
                              Css.marginBottom(Css.rem(0.25)),
                              /* :: */[
                                Css.display(/* block */888960333),
                                /* [] */0
                              ]
                            ]
                          ]
                        ]),
                    children: I18N$BsConsole.show(undefined, "Password")
                  }), React.createElement(BtSettings$BsConsole.FormInput.make, {
                    placeholder: I18N$BsConsole.get(undefined, "Update password"),
                    value: Belt_Option.mapWithDefault(repo.password, "", Util$BsConsole.identity),
                    onChange: (function ($$event) {
                        var password = BtSettings$BsConsole.getInputValue($$event);
                        return Curry._1(setRepo, (function (repo) {
                                      return {
                                              id: repo.id,
                                              name: repo.name,
                                              projectName: repo.projectName,
                                              universeName: repo.universeName,
                                              url: repo.url,
                                              defaultCommit: repo.defaultCommit,
                                              authType: repo.authType,
                                              userName: repo.userName,
                                              password: Util$BsConsole.nonEmptyStr(password) ? password.trim() : undefined,
                                              sshPublicKey: repo.sshPublicKey,
                                              sshPrivateKey: repo.sshPrivateKey,
                                              position: repo.position,
                                              cloneStatus: repo.cloneStatus,
                                              cloneError: repo.cloneError,
                                              autoclone: repo.autoclone
                                            };
                                    }));
                      }),
                    className: Css.style(/* :: */[
                          Css.width(Css.pct(95)),
                          /* [] */0
                        ])
                  }));
          break;
      case /* SSH */2 :
          tmp$1 = React.createElement(React.Fragment, undefined, React.createElement(BtTypography$BsConsole.make, {
                    variant: /* Caption */11,
                    className: Css.style(/* :: */[
                          Css.color(Css.hex(Colors$BsConsole.blackA54)),
                          /* :: */[
                            Css.lineHeight(Css.em(1.375)),
                            /* :: */[
                              Css.marginBottom(Css.rem(0.25)),
                              /* :: */[
                                Css.display(/* block */888960333),
                                /* [] */0
                              ]
                            ]
                          ]
                        ]),
                    children: I18N$BsConsole.show(undefined, "SSH public key")
                  }), React.createElement(BtSettings$BsConsole.FormInput.make, {
                    placeholder: I18N$BsConsole.get(undefined, "Update public key"),
                    value: Belt_Option.mapWithDefault(repo.sshPublicKey, "", Util$BsConsole.identity),
                    onChange: (function ($$event) {
                        var pubKey = BtSettings$BsConsole.getInputValue($$event);
                        return Curry._1(setRepo, (function (repo) {
                                      return {
                                              id: repo.id,
                                              name: repo.name,
                                              projectName: repo.projectName,
                                              universeName: repo.universeName,
                                              url: repo.url,
                                              defaultCommit: repo.defaultCommit,
                                              authType: repo.authType,
                                              userName: repo.userName,
                                              password: repo.password,
                                              sshPublicKey: Util$BsConsole.nonEmptyStr(pubKey) ? pubKey.trim() : undefined,
                                              sshPrivateKey: repo.sshPrivateKey,
                                              position: repo.position,
                                              cloneStatus: repo.cloneStatus,
                                              cloneError: repo.cloneError,
                                              autoclone: repo.autoclone
                                            };
                                    }));
                      }),
                    rows: 10,
                    multiline: true,
                    className: Css.style(/* :: */[
                          Css.width(Css.pct(95)),
                          /* [] */0
                        ])
                  }), React.createElement(BtTypography$BsConsole.make, {
                    variant: /* Caption */11,
                    className: Css.style(/* :: */[
                          Css.color(Css.hex(Colors$BsConsole.blackA54)),
                          /* :: */[
                            Css.lineHeight(Css.em(1.375)),
                            /* :: */[
                              Css.marginBottom(Css.rem(0.25)),
                              /* :: */[
                                Css.display(/* block */888960333),
                                /* [] */0
                              ]
                            ]
                          ]
                        ]),
                    children: I18N$BsConsole.show(undefined, "SSH private key")
                  }), React.createElement(BtSettings$BsConsole.FormInput.make, {
                    placeholder: I18N$BsConsole.get(undefined, "Update private key"),
                    value: Belt_Option.mapWithDefault(repo.sshPrivateKey, "", Util$BsConsole.identity),
                    onChange: (function ($$event) {
                        var privKey = BtSettings$BsConsole.getInputValue($$event);
                        return Curry._1(setRepo, (function (repo) {
                                      return {
                                              id: repo.id,
                                              name: repo.name,
                                              projectName: repo.projectName,
                                              universeName: repo.universeName,
                                              url: repo.url,
                                              defaultCommit: repo.defaultCommit,
                                              authType: repo.authType,
                                              userName: repo.userName,
                                              password: repo.password,
                                              sshPublicKey: repo.sshPublicKey,
                                              sshPrivateKey: Util$BsConsole.nonEmptyStr(privKey) ? privKey.trim() : undefined,
                                              position: repo.position,
                                              cloneStatus: repo.cloneStatus,
                                              cloneError: repo.cloneError,
                                              autoclone: repo.autoclone
                                            };
                                    }));
                      }),
                    rows: 10,
                    multiline: true,
                    className: Css.style(/* :: */[
                          Css.width(Css.pct(95)),
                          /* [] */0
                        ])
                  }));
          break;
      
    }
    tmp = React.createElement(React.Fragment, undefined, React.createElement(Divider.default, { }), React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.marginTop(Css.px(10)),
                    /* :: */[
                      Css.marginBottom(Css.px(3)),
                      /* :: */[
                        Css.fontSize(Css.px(12)),
                        /* :: */[
                          Css.color(Css.hex(Colors$BsConsole.blackA54)),
                          /* [] */0
                        ]
                      ]
                    ]
                  ])
            }, I18N$BsConsole.show(undefined, "Authentication method")), React.createElement(Select.default, {
              value: toString(repo.authType),
              onChange: (function (e, param) {
                  var value = fromString(e.target.value);
                  return Curry._1(setRepo, (function (repo) {
                                return {
                                        id: repo.id,
                                        name: repo.name,
                                        projectName: repo.projectName,
                                        universeName: repo.universeName,
                                        url: repo.url,
                                        defaultCommit: repo.defaultCommit,
                                        authType: value,
                                        userName: repo.userName,
                                        password: repo.password,
                                        sshPublicKey: repo.sshPublicKey,
                                        sshPrivateKey: repo.sshPrivateKey,
                                        position: repo.position,
                                        cloneStatus: repo.cloneStatus,
                                        cloneError: repo.cloneError,
                                        autoclone: repo.autoclone
                                      };
                              }));
                }),
              renderValue: (function (v) {
                  return React.createElement(BtTypography$BsConsole.make, {
                              variant: /* Caption */11,
                              children: I18N$BsConsole.showSkip(toFriendlyString(v))
                            });
                }),
              disableUnderline: true,
              className: Css.style(/* :: */[
                    Css.width(Css.pct(95)),
                    /* :: */[
                      Css.backgroundColor(Css.hex(Colors$BsConsole.blackA12)),
                      /* :: */[
                        Css.paddingLeft(Css.rem(0.75)),
                        /* :: */[
                          Css.marginBottom(Css.rem(1)),
                          /* [] */0
                        ]
                      ]
                    ]
                  ]),
              children: null
            }, React.createElement(MenuItem.default, {
                  value: "none",
                  children: React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Caption */11,
                        children: I18N$BsConsole.show(undefined, "None")
                      })
                }), React.createElement(MenuItem.default, {
                  value: "user_pass",
                  children: React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Caption */11,
                        children: I18N$BsConsole.show(undefined, "Username and password")
                      })
                }), React.createElement(MenuItem.default, {
                  value: "ssh_key",
                  children: React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Caption */11,
                        children: I18N$BsConsole.show(undefined, "SSH key")
                      })
                })), tmp$1);
  } else {
    tmp = null;
  }
  var supportLink = Href$BsConsole.Support.make(true, /* SettingsAttributes */21);
  return React.createElement(React.Fragment, undefined, React.createElement(Mui$BsConsole.IconButton.make, {
                  ariaLabel: "Close",
                  classes: {
                    root: Css.style(/* :: */[
                          Css.height(Css.px(48)),
                          /* :: */[
                            Css.position(/* absolute */-1013592457),
                            /* :: */[
                              Css.color(Css.hex(Colors$BsConsole.blackA54)),
                              /* :: */[
                                Css.top(/* zero */-789508312),
                                /* :: */[
                                  Css.right(/* zero */-789508312),
                                  /* [] */0
                                ]
                              ]
                            ]
                          ]
                        ])
                  },
                  color: "primary",
                  onClick: (function (_event) {
                      return Curry._1(close, undefined);
                    }),
                  children: React.createElement(Clear.default, { })
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Heading5 */4,
                  children: I18N$BsConsole.show(undefined, "Edit repository")
                }), React.createElement(BtSettings$BsConsole.FancyDivider.make, {
                  className: Css.style(/* :: */[
                        Css.margin2(Css.rem(0.5), /* zero */-789508312),
                        /* :: */[
                          Css.height(Css.px(4)),
                          /* [] */0
                        ]
                      ])
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Caption */11,
                  className: Css.style(/* :: */[
                        Css.color(Css.hex(Colors$BsConsole.blackA54)),
                        /* :: */[
                          Css.lineHeight(Css.em(1.375)),
                          /* :: */[
                            Css.marginBottom(Css.rem(0.25)),
                            /* :: */[
                              Css.display(/* block */888960333),
                              /* [] */0
                            ]
                          ]
                        ]
                      ]),
                  children: I18N$BsConsole.show(undefined, "Name")
                }), React.createElement(BtSettings$BsConsole.FormInput.make, {
                  placeholder: "",
                  value: repo.name,
                  onChange: (function ($$event) {
                      var repoName = $$event.target.value;
                      return Curry._1(setRepo, (function (repo) {
                                    return {
                                            id: repo.id,
                                            name: repoName,
                                            projectName: repo.projectName,
                                            universeName: repo.universeName,
                                            url: repo.url,
                                            defaultCommit: repo.defaultCommit,
                                            authType: repo.authType,
                                            userName: repo.userName,
                                            password: repo.password,
                                            sshPublicKey: repo.sshPublicKey,
                                            sshPrivateKey: repo.sshPrivateKey,
                                            position: repo.position,
                                            cloneStatus: repo.cloneStatus,
                                            cloneError: repo.cloneError,
                                            autoclone: repo.autoclone
                                          };
                                  }));
                    }),
                  className: Css.style(/* :: */[
                        Css.width(Css.pct(95)),
                        /* [] */0
                      ])
                }), React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Caption */11,
                  className: Css.style(/* :: */[
                        Css.color(Css.hex(Colors$BsConsole.blackA54)),
                        /* :: */[
                          Css.lineHeight(Css.em(1.375)),
                          /* :: */[
                            Css.marginBottom(Css.rem(0.25)),
                            /* :: */[
                              Css.display(/* block */888960333),
                              /* [] */0
                            ]
                          ]
                        ]
                      ]),
                  children: I18N$BsConsole.show(undefined, "URL")
                }), React.createElement(BtSettings$BsConsole.FormInput.make, {
                  placeholder: "",
                  value: repo.url,
                  onChange: (function ($$event) {
                      var url = BtSettings$BsConsole.getInputValue($$event);
                      return Curry._1(setRepo, (function (repo) {
                                    return {
                                            id: repo.id,
                                            name: repo.name,
                                            projectName: repo.projectName,
                                            universeName: repo.universeName,
                                            url: url,
                                            defaultCommit: repo.defaultCommit,
                                            authType: repo.authType,
                                            userName: repo.userName,
                                            password: repo.password,
                                            sshPublicKey: repo.sshPublicKey,
                                            sshPrivateKey: repo.sshPrivateKey,
                                            position: repo.position,
                                            cloneStatus: repo.cloneStatus,
                                            cloneError: repo.cloneError,
                                            autoclone: repo.autoclone
                                          };
                                  }));
                    }),
                  className: Css.style(/* :: */[
                        Css.width(Css.pct(95)),
                        /* [] */0
                      ])
                }), editAuth === false ? React.createElement(React.Fragment, undefined, React.createElement(Divider.default, {
                        className: Css.style(/* :: */[
                              Css.marginBottom(Css.rem(1)),
                              /* :: */[
                                Css.marginTop(Css.rem(0.5)),
                                /* [] */0
                              ]
                            ])
                      }), React.createElement(Button.default, {
                        variant: "contained",
                        classes: {
                          root: Css.style(/* :: */[
                                Css.marginBottom(Css.rem(1)),
                                /* [] */0
                              ])
                        },
                        onClick: (function (param) {
                            return Curry._1(setEditAuth, (function (param) {
                                          return true;
                                        }));
                          }),
                        children: I18N$BsConsole.show(undefined, "Edit credentials")
                      })) : null, tmp, React.createElement(Divider.default, {
                  className: Css.style(/* :: */[
                        Css.marginBottom(Css.rem(0.5)),
                        /* [] */0
                      ])
                }), React.createElement(Row2$BsConsole.make, {
                  alignItems: /* flexEnd */924268066,
                  className: Css.style(/* :: */[
                        Css.marginBottom(Css.rem(0.5)),
                        /* :: */[
                          Css.width(Css.pct(100)),
                          /* [] */0
                        ]
                      ]),
                  children: React.createElement(Col2$BsConsole.make, {
                        justifyContent: /* spaceBetween */516682146,
                        className: Css.style(/* :: */[
                              Css.width(Css.pct(95)),
                              /* [] */0
                            ]),
                        children: null
                      }, React.createElement(BtTypography$BsConsole.make, {
                            variant: /* Caption */11,
                            className: Css.style(/* :: */[
                                  Css.color(Css.hex(Colors$BsConsole.blackA54)),
                                  /* :: */[
                                    Css.lineHeight(Css.em(1.375)),
                                    /* [] */0
                                  ]
                                ]),
                            children: React.createElement(Row2$BsConsole.make, {
                                  alignItems: /* center */98248149,
                                  children: null
                                }, React.createElement("span", {
                                      className: Css.style(/* :: */[
                                            Css.marginRight(Css.rem(0.5)),
                                            /* [] */0
                                          ])
                                    }, I18N$BsConsole.show(undefined, "Revisions")), React.createElement(Tooltip.default, {
                                      classes: {
                                        tooltip: Css.style(/* :: */[
                                              Css.backgroundColor(Css.hex(Colors$BsConsole.grey15)),
                                              /* [] */0
                                            ])
                                      },
                                      interactive: true,
                                      children: React.createElement(Info.default, {
                                            className: Css.style(/* :: */[
                                                  Css.fontSize(Css.rem(1)),
                                                  /* [] */0
                                                ])
                                          }),
                                      title: React.createElement(BtTypography$BsConsole.make, {
                                            variant: /* Body1 */8,
                                            className: Css.style(/* :: */[
                                                  Css.lineHeight(Css.em(1.375)),
                                                  /* [] */0
                                                ]),
                                            children: null
                                          }, React.createElement("div", {
                                                className: Css.style(/* :: */[
                                                      Css.marginBottom(Css.em(0.5)),
                                                      /* [] */0
                                                    ])
                                              }, I18N$BsConsole.show(undefined, "Specify a list of git revisions to try, in order, when searching for matching source code.")), React.createElement("div", {
                                                className: Css.style(/* :: */[
                                                      Css.marginBottom(Css.em(0.5)),
                                                      /* [] */0
                                                    ])
                                              }, I18N$BsConsole.show(undefined, "Acceptable revision types include branch names, tags, as well as short and long SHA-1 hashes.")), supportLink !== undefined ? React.createElement("div", {
                                                  className: Css.style(/* :: */[
                                                        Css.marginBottom(Css.em(0.5)),
                                                        /* [] */0
                                                      ])
                                                }, I18N$BsConsole.show(undefined, "You can specify an "), React.createElement("a", {
                                                      className: "link",
                                                      href: supportLink,
                                                      rel: "noreferrer",
                                                      target: "_blank"
                                                    }, I18N$BsConsole.show(undefined, "attribute")), I18N$BsConsole.show(undefined, " to be dynamically included in the revision string by using {attribute} template syntax.")) : null, React.createElement("div", undefined, I18N$BsConsole.show(undefined, "Some examples:")), React.createElement("div", {
                                                className: Css.style(/* :: */[
                                                      Css.paddingLeft(Css.em(0.5)),
                                                      /* [] */0
                                                    ])
                                              }, I18N$BsConsole.showSkip("release/{version}")), React.createElement("div", {
                                                className: Css.style(/* :: */[
                                                      Css.paddingLeft(Css.em(0.5)),
                                                      /* [] */0
                                                    ])
                                              }, I18N$BsConsole.showSkip("{commit}")), React.createElement("div", {
                                                className: Css.style(/* :: */[
                                                      Css.paddingLeft(Css.em(0.5)),
                                                      /* [] */0
                                                    ])
                                              }, I18N$BsConsole.showSkip("729e0d9a")), React.createElement("div", {
                                                className: Css.style(/* :: */[
                                                      Css.paddingLeft(Css.em(0.5)),
                                                      /* [] */0
                                                    ])
                                              }, I18N$BsConsole.showSkip("master")), React.createElement("div", {
                                                className: Css.style(/* :: */[
                                                      Css.paddingLeft(Css.em(0.5)),
                                                      /* :: */[
                                                        Css.marginBottom(Css.em(0.5)),
                                                        /* [] */0
                                                      ]
                                                    ])
                                              }, I18N$BsConsole.showSkip("main")))
                                    }))
                          }), Belt_List.toArray(Belt_List.mapWithIndex(Belt_List.sort(revisions, (function (a, b) {
                                      return Caml_primitive.caml_int_compare(a.position, b.position);
                                    })), (function (idx, rev) {
                                  return React.createElement(Row2$BsConsole.make, {
                                              alignItems: /* center */98248149,
                                              className: Css.style(/* :: */[
                                                    Css.width(Css.pct(100)),
                                                    /* :: */[
                                                      Css.marginBottom(Css.rem(0.5)),
                                                      /* [] */0
                                                    ]
                                                  ]),
                                              children: null
                                            }, React.createElement(BtSettings$BsConsole.FormInput.make, {
                                                  placeholder: "release/{version}",
                                                  value: Belt_Option.mapWithDefault(rev.revision, "", Util$BsConsole.identity),
                                                  onChange: (function ($$event) {
                                                      var newRev = BtSettings$BsConsole.getInputValue($$event);
                                                      return Curry._1(setRevisions, (function (param) {
                                                                    return Belt_List.mapWithIndex(revisions, (function (i, rev) {
                                                                                  if (i === idx) {
                                                                                    return {
                                                                                            revision: newRev,
                                                                                            position: rev.position
                                                                                          };
                                                                                  } else {
                                                                                    return rev;
                                                                                  }
                                                                                }));
                                                                  }));
                                                    }),
                                                  error: !revisionValid(rev.revision),
                                                  className: Css.style(/* :: */[
                                                        Css.width(Css.pct(95)),
                                                        /* :: */[
                                                          Css.marginBottom(/* zero */-789508312),
                                                          /* :: */[
                                                            Css.marginTop(Css.em(0.5)),
                                                            /* [] */0
                                                          ]
                                                        ]
                                                      ])
                                                }), React.createElement(Row2$BsConsole.make, {
                                                  alignItems: /* center */98248149,
                                                  className: Css.style(/* :: */[
                                                        Css.marginLeft(Css.rem(0.5)),
                                                        /* :: */[
                                                          Css.width(Css.pct(4)),
                                                          /* [] */0
                                                        ]
                                                      ]),
                                                  children: null
                                                }, React.createElement(Mui$BsConsole.IconButton.make, {
                                                      ariaLabel: "Move up",
                                                      classes: {
                                                        root: Css.style(/* :: */[
                                                              Css.padding(Css.px(4)),
                                                              /* [] */0
                                                            ])
                                                      },
                                                      onClick: (function (param) {
                                                          var oldPos = rev.position;
                                                          var newPos = rev.position - 1 | 0;
                                                          if (newPos < 0) {
                                                            return ;
                                                          }
                                                          var updatedRevs = Belt_List.sort(Belt_List.map(revisions, (function (revision) {
                                                                      var match = newPos - revision.position | 0;
                                                                      if (match !== -1) {
                                                                        if (match !== 0) {
                                                                          return revision;
                                                                        } else {
                                                                          return {
                                                                                  revision: revision.revision,
                                                                                  position: oldPos
                                                                                };
                                                                        }
                                                                      } else {
                                                                        return {
                                                                                revision: revision.revision,
                                                                                position: newPos
                                                                              };
                                                                      }
                                                                    })), (function (a, b) {
                                                                  return Caml_primitive.caml_int_compare(a.position, b.position);
                                                                }));
                                                          return Curry._1(setRevisions, (function (param) {
                                                                        return updatedRevs;
                                                                      }));
                                                        }),
                                                      children: React.createElement(ArrowUpward.default, { })
                                                    }), React.createElement(Mui$BsConsole.IconButton.make, {
                                                      ariaLabel: "Move down",
                                                      classes: {
                                                        root: Css.style(/* :: */[
                                                              Css.padding(Css.px(4)),
                                                              /* :: */[
                                                                Css.marginLeft(Css.rem(0.5)),
                                                                /* [] */0
                                                              ]
                                                            ])
                                                      },
                                                      onClick: (function (param) {
                                                          var len = Belt_List.length(revisions);
                                                          var oldPos = rev.position;
                                                          var newPos = rev.position + 1 | 0;
                                                          if (newPos === len) {
                                                            return ;
                                                          }
                                                          var updatedRevs = Belt_List.sort(Belt_List.map(revisions, (function (revision) {
                                                                      var match = newPos - revision.position | 0;
                                                                      if (match !== 0) {
                                                                        if (match !== 1) {
                                                                          return revision;
                                                                        } else {
                                                                          return {
                                                                                  revision: revision.revision,
                                                                                  position: newPos
                                                                                };
                                                                        }
                                                                      } else {
                                                                        return {
                                                                                revision: revision.revision,
                                                                                position: oldPos
                                                                              };
                                                                      }
                                                                    })), (function (a, b) {
                                                                  return Caml_primitive.caml_int_compare(a.position, b.position);
                                                                }));
                                                          return Curry._1(setRevisions, (function (param) {
                                                                        return updatedRevs;
                                                                      }));
                                                        }),
                                                      children: React.createElement(ArrowDownward.default, { })
                                                    }), React.createElement(Mui$BsConsole.IconButton.make, {
                                                      ariaLabel: "Delete",
                                                      classes: {
                                                        root: Css.style(/* :: */[
                                                              Css.padding(Css.px(4)),
                                                              /* :: */[
                                                                Css.marginLeft(Css.rem(0.5)),
                                                                /* [] */0
                                                              ]
                                                            ])
                                                      },
                                                      onClick: (function (param) {
                                                          var pos = rev.position;
                                                          var updatedRevisions = Belt_List.map(Belt_List.filter(revisions, (function (revision) {
                                                                      return revision.position !== pos;
                                                                    })), (function (revision) {
                                                                  if (revision.position > pos) {
                                                                    return {
                                                                            revision: revision.revision,
                                                                            position: revision.position - 1 | 0
                                                                          };
                                                                  } else {
                                                                    return revision;
                                                                  }
                                                                }));
                                                          return Curry._1(setRevisions, (function (param) {
                                                                        return updatedRevisions;
                                                                      }));
                                                        }),
                                                      children: React.createElement(Icon.default, {
                                                            children: I18N$BsConsole.showSkip("delete")
                                                          })
                                                    })));
                                }))), React.createElement(BtSettings$BsConsole.FormInput.make, {
                            placeholder: "release/{version}",
                            value: repo.defaultCommit,
                            onChange: (function ($$event) {
                                var newStr = BtSettings$BsConsole.getInputValue($$event);
                                return Curry._1(setRepo, (function (repo) {
                                              return {
                                                      id: repo.id,
                                                      name: repo.name,
                                                      projectName: repo.projectName,
                                                      universeName: repo.universeName,
                                                      url: repo.url,
                                                      defaultCommit: newStr,
                                                      authType: repo.authType,
                                                      userName: repo.userName,
                                                      password: repo.password,
                                                      sshPublicKey: repo.sshPublicKey,
                                                      sshPrivateKey: repo.sshPrivateKey,
                                                      position: repo.position,
                                                      cloneStatus: repo.cloneStatus,
                                                      cloneError: repo.cloneError,
                                                      autoclone: repo.autoclone
                                                    };
                                            }));
                              }),
                            error: !Util$BsConsole.nonEmptyStr(repo.defaultCommit),
                            className: Css.style(/* :: */[
                                  Css.width(Css.pct(95)),
                                  /* :: */[
                                    Css.marginBottom(/* zero */-789508312),
                                    /* :: */[
                                      Css.marginTop(Css.em(0.5)),
                                      /* [] */0
                                    ]
                                  ]
                                ])
                          }))
                }), React.createElement(Button.default, {
                  variant: "outlined",
                  color: "primary",
                  classes: {
                    root: Css.style(/* :: */[
                          Css.marginBottom(Css.rem(0.5)),
                          /* :: */[
                            Css.marginTop(Css.rem(0.25)),
                            /* [] */0
                          ]
                        ])
                  },
                  onClick: (function (param) {
                      return Curry._1(setRevisions, (function (revisions) {
                                    return Belt_List.concat(revisions, /* :: */[
                                                {
                                                  revision: undefined,
                                                  position: Belt_List.length(revisions)
                                                },
                                                /* [] */0
                                              ]);
                                  }));
                    }),
                  children: I18N$BsConsole.show(undefined, "Add")
                }), supportsAutoSubmodules ? React.createElement("div", undefined, React.createElement(Divider.default, {
                        className: Css.style(/* :: */[
                              Css.marginBottom(Css.rem(0.5)),
                              /* [] */0
                            ])
                      }), React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Caption */11,
                        className: Css.style(/* :: */[
                              Css.color(Css.hex(Colors$BsConsole.blackA54)),
                              /* :: */[
                                Css.lineHeight(Css.em(1.375)),
                                /* [] */0
                              ]
                            ]),
                        children: React.createElement(Row2$BsConsole.make, {
                              alignItems: /* center */98248149,
                              className: Css.style(/* :: */[
                                    Css.marginBottom(Css.rem(0.25)),
                                    /* [] */0
                                  ]),
                              children: null
                            }, React.createElement("span", {
                                  className: Css.style(/* :: */[
                                        Css.marginRight(Css.rem(0.5)),
                                        /* [] */0
                                      ])
                                }, I18N$BsConsole.show(undefined, "Submodules")), React.createElement(Tooltip.default, {
                                  classes: {
                                    tooltip: Css.style(/* :: */[
                                          Css.backgroundColor(Css.hex(Colors$BsConsole.grey15)),
                                          /* [] */0
                                        ])
                                  },
                                  interactive: true,
                                  children: React.createElement(Info.default, {
                                        className: Css.style(/* :: */[
                                              Css.fontSize(Css.rem(1)),
                                              /* [] */0
                                            ])
                                      }),
                                  title: React.createElement(BtTypography$BsConsole.make, {
                                        variant: /* Body1 */8,
                                        className: Css.style(/* :: */[
                                              Css.lineHeight(Css.em(1.375)),
                                              /* [] */0
                                            ]),
                                        children: null
                                      }, I18N$BsConsole.show(undefined, "When this checkbox is set, the system will automatically clone any submodules that it discovers while performing a source code lookup. "), I18N$BsConsole.show(undefined, "These submodules will be listed alongside other repositories within this config section. "), I18N$BsConsole.show(undefined, "If the clone operation completes quickly, then its contents will will be considered for the current source code lookup. "), I18N$BsConsole.show(undefined, "If it takes longer to clone, then its contents will be available on subsequent lookups."))
                                }))
                      }), React.createElement(FormControlLabel.default, {
                        label: "Automatically clone submodules",
                        control: React.createElement(Checkbox.default, {
                              checked: repo.autoclone,
                              color: "primary",
                              classes: {
                                root: Css.style(/* :: */[
                                      Css.color(Css.hex(Colors$BsConsole.grey4)),
                                      /* :: */[
                                        Css.padding2(Css.rem(0.25), /* zero */-789508312),
                                        /* :: */[
                                          Css.paddingRight(Css.rem(0.5)),
                                          /* :: */[
                                            Css.selector("svg", /* :: */[
                                                  Css.width(Css.rem(0.9)),
                                                  /* :: */[
                                                    Css.height(Css.rem(0.9)),
                                                    /* [] */0
                                                  ]
                                                ]),
                                            /* [] */0
                                          ]
                                        ]
                                      ]
                                    ])
                              },
                              onChange: (function (e) {
                                  var value = e.target.checked;
                                  return Curry._1(setRepo, (function (repo) {
                                                return {
                                                        id: repo.id,
                                                        name: repo.name,
                                                        projectName: repo.projectName,
                                                        universeName: repo.universeName,
                                                        url: repo.url,
                                                        defaultCommit: repo.defaultCommit,
                                                        authType: repo.authType,
                                                        userName: repo.userName,
                                                        password: repo.password,
                                                        sshPublicKey: repo.sshPublicKey,
                                                        sshPrivateKey: repo.sshPrivateKey,
                                                        position: repo.position,
                                                        cloneStatus: repo.cloneStatus,
                                                        cloneError: repo.cloneError,
                                                        autoclone: value
                                                      };
                                              }));
                                }),
                              disableRipple: true
                            }),
                        classes: {
                          root: Css.style(/* :: */[
                                Css.margin(/* zero */-789508312),
                                /* :: */[
                                  Css.userSelect(Css.none),
                                  /* :: */[
                                    Css.marginRight(Css.rem(1.5)),
                                    /* :: */[
                                      Css.marginBottom(Css.rem(0.5)),
                                      /* [] */0
                                    ]
                                  ]
                                ]
                              ])
                        }
                      })) : null, React.createElement("div", undefined, React.createElement(Divider.default, {
                      className: Css.style(/* :: */[
                            Css.marginBottom(Css.rem(0.5)),
                            /* [] */0
                          ])
                    }), React.createElement(Button.default, {
                      variant: "contained",
                      color: "primary",
                      disabled: !Belt_List.every(validatedFormFields$prime, Util$BsConsole.nonEmptyStr),
                      onClick: (function (param) {
                          MetricsEvent$BsConsole.send(/* ActionWithAttrs */Block.__(1, [
                                  /* SourceCode_RepoEdit */82,
                                  Json_encode.object_(/* :: */[
                                        /* tuple */[
                                          "project",
                                          projectName
                                        ],
                                        /* [] */0
                                      ])
                                ]));
                          var match = repo.authType;
                          var repo$prime;
                          if (editAuth) {
                            switch (match) {
                              case /* NoAuth */0 :
                                  repo$prime = {
                                    id: repo.id,
                                    name: repo.name,
                                    projectName: repo.projectName,
                                    universeName: repo.universeName,
                                    url: repo.url,
                                    defaultCommit: repo.defaultCommit,
                                    authType: repo.authType,
                                    userName: undefined,
                                    password: undefined,
                                    sshPublicKey: undefined,
                                    sshPrivateKey: undefined,
                                    position: repo.position,
                                    cloneStatus: repo.cloneStatus,
                                    cloneError: repo.cloneError,
                                    autoclone: repo.autoclone
                                  };
                                  break;
                              case /* UserPass */1 :
                                  repo$prime = {
                                    id: repo.id,
                                    name: repo.name,
                                    projectName: repo.projectName,
                                    universeName: repo.universeName,
                                    url: repo.url,
                                    defaultCommit: repo.defaultCommit,
                                    authType: repo.authType,
                                    userName: repo.userName,
                                    password: repo.password,
                                    sshPublicKey: undefined,
                                    sshPrivateKey: undefined,
                                    position: repo.position,
                                    cloneStatus: repo.cloneStatus,
                                    cloneError: repo.cloneError,
                                    autoclone: repo.autoclone
                                  };
                                  break;
                              case /* SSH */2 :
                                  repo$prime = {
                                    id: repo.id,
                                    name: repo.name,
                                    projectName: repo.projectName,
                                    universeName: repo.universeName,
                                    url: repo.url,
                                    defaultCommit: repo.defaultCommit,
                                    authType: repo.authType,
                                    userName: undefined,
                                    password: undefined,
                                    sshPublicKey: repo.sshPublicKey,
                                    sshPrivateKey: repo.sshPrivateKey,
                                    position: repo.position,
                                    cloneStatus: repo.cloneStatus,
                                    cloneError: repo.cloneError,
                                    autoclone: repo.autoclone
                                  };
                                  break;
                              
                            }
                          } else {
                            repo$prime = {
                              id: repo.id,
                              name: repo.name,
                              projectName: repo.projectName,
                              universeName: repo.universeName,
                              url: repo.url,
                              defaultCommit: repo.defaultCommit,
                              authType: repository.authType,
                              userName: undefined,
                              password: undefined,
                              sshPublicKey: undefined,
                              sshPrivateKey: undefined,
                              position: repo.position,
                              cloneStatus: repo.cloneStatus,
                              cloneError: repo.cloneError,
                              autoclone: repo.autoclone
                            };
                          }
                          Curry._1(setErrorMsg, (function (param) {
                                  
                                }));
                          if (editAuth) {
                            MetricsEvent$BsConsole.send(/* ActionWithAttrs */Block.__(1, [
                                    /* SourceCode_RepoEditAuthInfo */83,
                                    Json_encode.object_(/* :: */[
                                          /* tuple */[
                                            "project",
                                            projectName
                                          ],
                                          /* [] */0
                                        ])
                                  ]));
                          }
                          var id = repo.id;
                          if (id !== undefined) {
                            return Task2$BsConsole.handle(token, updateRepository(id, repo$prime, revisions, universeName, projectName, config, undefined), undefined, (function (updatedRepository) {
                                          if (updatedRepository.tag) {
                                            return Curry._1(setErrorMsg, (function (param) {
                                                          return "Encountered an error while updating repository.";
                                                        }));
                                          }
                                          Curry._1(close, undefined);
                                          Curry._1(refetchRepositories, undefined);
                                          return Curry._1(refetchCommitRules, undefined);
                                        }));
                          }
                          
                        }),
                      children: I18N$BsConsole.show(undefined, "Update")
                    })), errorMsg !== undefined ? React.createElement(BtTypography$BsConsole.make, {
                    variant: /* Heading4 */3,
                    className: Css.style(/* :: */[
                          Css.marginTop(Css.px(3)),
                          /* :: */[
                            Css.fontSize(Css.px(18)),
                            /* [] */0
                          ]
                        ]),
                    color: Colors$BsConsole.errorDark,
                    children: I18N$BsConsole.showSkip(errorMsg)
                  }) : null);
}

var Edit = {
  make: Repository$Edit
};

function toJson$1(submodules) {
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "submodules",
                Json_encode.object_(Belt_List.map(submodules, (function (s) {
                            return /* tuple */[
                                    s.name,
                                    Json_encode.object_(/* :: */[
                                          /* tuple */[
                                            "url",
                                            s.url
                                          ],
                                          /* [] */0
                                        ])
                                  ];
                          })))
              ],
              /* [] */0
            ]);
}

function fetchSubmodules(projectName, universeName, config, repoId, commit) {
  var endpoint = Configuration$BsConsole.getEndpointWithProjectOverrides(projectName, undefined, config, "sourcecode");
  var path = endpoint !== undefined ? endpoint : "http://localhost:6451/api/sourcecode";
  var commitEncoded = encodeURIComponent(commit);
  var partial_arg = /* Custom */Block.__(1, ["" + (String(path) + ("/" + (String(universeName) + ("/" + (String(projectName) + ("/repositories/" + (String(repoId) + ("/commit/" + (String(commitEncoded) + "/submodules")))))))))]);
  var func = function (param, param$1, param$2, param$3, param$4, param$5) {
    return Task2$BsConsole.make(partial_arg, param, param$1, param$2, param$3, param$4, param$5);
  };
  var arg = function (param) {
    var obj = Json_decode.field("submodules", (function (param) {
            return Json_decode.dict((function (param) {
                          return Json_decode.dict(Json_decode.string, param);
                        }), param);
          }), param.json);
    return /* Ok */Block.__(0, [Belt_Array.keepMap(Js_dict.entries(obj), (function (param) {
                      var url = Js_dict.get(param[1], "url");
                      if (url !== undefined) {
                        return {
                                name: param[0],
                                url: url
                              };
                      }
                      
                    }))]);
  };
  return (function (param) {
      var func$1 = Curry._2(func, param, /* Get */0);
      return (function (param) {
          return Curry._2(func$1, param, arg);
        });
    });
}

function cloneSubmodules(projectName, universeName, config, repoId, commit, submodules) {
  var endpoint = Configuration$BsConsole.getEndpointWithProjectOverrides(projectName, undefined, config, "sourcecode");
  var path = endpoint !== undefined ? endpoint : "http://localhost:6451/api/sourcecode";
  var commitEncoded = encodeURIComponent(commit);
  var partial_arg = /* Custom */Block.__(1, ["" + (String(path) + ("/" + (String(universeName) + ("/" + (String(projectName) + ("/repositories/" + (String(repoId) + ("/commit/" + (String(commitEncoded) + "/submodules/clone")))))))))]);
  var func = function (param, param$1, param$2, param$3, param$4, param$5) {
    return Task2$BsConsole.make(partial_arg, param, param$1, param$2, param$3, param$4, param$5);
  };
  var arg = /* Post */Block.__(0, [toJson$1(submodules)]);
  var arg$1 = function (param) {
    var obj = Json_decode.field("submodules", (function (param) {
            return Json_decode.dict((function (param) {
                          return Json_decode.dict(Json_decode.string, param);
                        }), param);
          }), param.json);
    return /* Ok */Block.__(0, [Belt_Array.keepMap(Js_dict.entries(obj), (function (param) {
                      var url = Js_dict.get(param[1], "url");
                      if (url !== undefined) {
                        return {
                                name: param[0],
                                url: url
                              };
                      }
                      
                    }))]);
  };
  return (function (param) {
      var func$1 = Curry._2(func, param, arg);
      return (function (param) {
          return Curry._2(func$1, param, arg$1);
        });
    });
}

var SubmodulesTasks = {
  toJson: toJson$1,
  fetchSubmodules: fetchSubmodules,
  cloneSubmodules: cloneSubmodules
};

function Repository$CloneSubmodules(Props) {
  var config = Props.config;
  var id = Props.id;
  var defaultCommit = Props.defaultCommit;
  var projectName = Props.projectName;
  var universeName = Props.universeName;
  var token = Props.token;
  var close = Props.close;
  var match = React.useState((function () {
          
        }));
  var setSubmodules = match[1];
  var submodules = match[0];
  var match$1 = React.useState((function () {
          return defaultCommit;
        }));
  var setRevision = match$1[1];
  var revision = match$1[0];
  var match$2 = React.useState((function () {
          return 0;
        }));
  var setFetchKey = match$2[1];
  var match$3 = React.useState((function () {
          return false;
        }));
  var setShowRevisionForm = match$3[1];
  React.useEffect((function () {
          Task2$BsConsole.handle(token, Curry._3(fetchSubmodules(projectName, universeName, config, id, revision)(undefined), undefined, undefined, undefined), undefined, (function (submodules) {
                  if (submodules.tag) {
                    return ;
                  }
                  var submodules$1 = submodules[0];
                  return Curry._1(setSubmodules, (function (param) {
                                return Belt_Array.map(submodules$1, (function (subm) {
                                              return /* tuple */[
                                                      subm,
                                                      true
                                                    ];
                                            }));
                              }));
                }));
          
        }), [match$2[0]]);
  var tmp;
  var exit = 0;
  if (submodules !== undefined && submodules.length !== 0) {
    tmp = React.createElement(Col2$BsConsole.make, {
          children: React.createElement(BtTableContainer$BsConsole.make, {
                children: React.createElement(BtTable$BsConsole.make, {
                      dense: true,
                      className: Css.style(/* :: */[
                            Css.unsafe("table-layout", "unset"),
                            /* :: */[
                              Css.unsafe("width", "unset"),
                              /* [] */0
                            ]
                          ]),
                      children: null
                    }, React.createElement(BtTableHead$BsConsole.make, {
                          children: React.createElement(BtTableRow$BsConsole.make, {
                                children: null
                              }, React.createElement(BtTableCell$BsConsole.Header.make, {
                                    children: null
                                  }), React.createElement(BtTableCell$BsConsole.Header.make, {
                                    className: Css.style(/* :: */[
                                          Css.textAlign(/* left */-944764921),
                                          /* [] */0
                                        ]),
                                    children: I18N$BsConsole.show(undefined, "Name")
                                  }), React.createElement(BtTableCell$BsConsole.Header.make, {
                                    className: Css.style(/* :: */[
                                          Css.textAlign(/* left */-944764921),
                                          /* [] */0
                                        ]),
                                    children: I18N$BsConsole.show(undefined, "URL")
                                  }))
                        }), React.createElement(BtTableBody$BsConsole.make, {
                          children: Belt_Array.map(submodules, (function (submAssoc) {
                                  var subm = submAssoc[0];
                                  return React.createElement(BtTableRow$BsConsole.make, {
                                              children: null
                                            }, React.createElement(BtTableCell$BsConsole.make, {
                                                  className: Css.style(/* :: */[
                                                        Css.textAlign(/* right */-379319332),
                                                        /* [] */0
                                                      ]),
                                                  children: React.createElement(Checkbox.default, {
                                                        checked: submAssoc[1],
                                                        color: "primary",
                                                        classes: {
                                                          root: Css.style(/* :: */[
                                                                Css.padding(/* zero */-789508312),
                                                                /* :: */[
                                                                  Css.selector("svg", /* :: */[
                                                                        Css.width(Css.rem(0.75)),
                                                                        /* :: */[
                                                                          Css.height(Css.rem(0.75)),
                                                                          /* [] */0
                                                                        ]
                                                                      ]),
                                                                  /* [] */0
                                                                ]
                                                              ])
                                                        },
                                                        onChange: (function ($$event) {
                                                            return Curry._1(setSubmodules, (function (submodules) {
                                                                          if (submodules !== undefined) {
                                                                            return Belt_Array.map(submodules, (function (param) {
                                                                                          var s = param[0];
                                                                                          var checked = $$event.target.checked;
                                                                                          if (s.name === subm.name) {
                                                                                            return /* tuple */[
                                                                                                    s,
                                                                                                    checked
                                                                                                  ];
                                                                                          } else {
                                                                                            return /* tuple */[
                                                                                                    s,
                                                                                                    param[1]
                                                                                                  ];
                                                                                          }
                                                                                        }));
                                                                          }
                                                                          
                                                                        }));
                                                          })
                                                      })
                                                }), React.createElement(BtTableCell$BsConsole.make, {
                                                  className: Css.style(/* :: */[
                                                        Css.userSelect(/* text */-856044371),
                                                        /* [] */0
                                                      ]),
                                                  children: I18N$BsConsole.show(undefined, subm.name)
                                                }), React.createElement(BtTableCell$BsConsole.make, {
                                                  className: Css.style(/* :: */[
                                                        Css.unsafe("text-overflow", "unset"),
                                                        /* :: */[
                                                          Css.unsafe("overflow", "unset"),
                                                          /* :: */[
                                                            Css.userSelect(/* text */-856044371),
                                                            /* [] */0
                                                          ]
                                                        ]
                                                      ]),
                                                  children: I18N$BsConsole.showSkip(subm.url)
                                                }));
                                }))
                        }))
              })
        });
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = React.createElement("div", {
          className: Css.style(/* :: */[
                Css.marginTop(Css.rem(1)),
                /* [] */0
              ])
        }, I18N$BsConsole.show(undefined, "No submodules found"));
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Mui$BsConsole.IconButton.make, {
                  ariaLabel: "Close",
                  classes: {
                    root: Css.style(/* :: */[
                          Css.height(Css.px(48)),
                          /* :: */[
                            Css.position(/* absolute */-1013592457),
                            /* :: */[
                              Css.color(Css.hex(Colors$BsConsole.blackA54)),
                              /* :: */[
                                Css.top(/* zero */-789508312),
                                /* :: */[
                                  Css.right(/* zero */-789508312),
                                  /* [] */0
                                ]
                              ]
                            ]
                          ]
                        ])
                  },
                  color: "primary",
                  onClick: (function (_event) {
                      return Curry._1(close, undefined);
                    }),
                  children: React.createElement(Clear.default, { })
                }), React.createElement("div", {
                  className: Css.style(/* :: */[
                        Css.paddingRight(Css.rem(2)),
                        /* [] */0
                      ])
                }, React.createElement(BtTypography$BsConsole.make, {
                      variant: /* Heading5 */4,
                      className: Css.style(/* :: */[
                            Css.marginBottom(Css.rem(0.5)),
                            /* [] */0
                          ]),
                      children: I18N$BsConsole.show(undefined, "Select submodules to import")
                    }), React.createElement("div", {
                      className: Css.style(/* :: */[
                            Css.marginBottom(Css.rem(0.5)),
                            /* [] */0
                          ])
                    }, tmp), React.createElement("div", {
                      className: Css.style(/* :: */[
                            Css.marginBottom(Css.rem(0.5)),
                            /* [] */0
                          ])
                    }, match$3[0] ? React.createElement(Col2$BsConsole.make, {
                            children: null
                          }, React.createElement(InputLabel.default, {
                                classes: {
                                  root: Css.style(/* :: */[
                                        Css.marginTop(Css.px(8)),
                                        /* :: */[
                                          Css.marginBottom(Css.px(8)),
                                          /* [] */0
                                        ]
                                      ])
                                },
                                children: React.createElement(BtTypography$BsConsole.make, {
                                      variant: /* Body1 */8,
                                      children: I18N$BsConsole.show(undefined, "Read .submodules file from revision:")
                                    })
                              }), React.createElement(Row2$BsConsole.make, {
                                children: null
                              }, React.createElement(TextField.default, {
                                    placeholder: I18N$BsConsole.get(undefined, "Revision"),
                                    value: revision,
                                    color: "primary",
                                    variant: "outlined",
                                    classes: {
                                      root: Css.style(/* :: */[
                                            Css.width(Css.ch(20)),
                                            /* :: */[
                                              Css.marginRight(Css.rem(1)),
                                              /* :: */[
                                                Css.selector("input", /* :: */[
                                                      Css.important(Css.paddingTop(Css.px(6))),
                                                      /* :: */[
                                                        Css.important(Css.paddingBottom(Css.px(7))),
                                                        /* [] */0
                                                      ]
                                                    ]),
                                                /* :: */[
                                                  Css.selector("div[class*='multiline']", /* :: */[
                                                        Css.important(Css.paddingTop(Css.px(10))),
                                                        /* [] */0
                                                      ]),
                                                  /* [] */0
                                                ]
                                              ]
                                            ]
                                          ])
                                    },
                                    onChange: (function ($$event) {
                                        var value = BtSettings$BsConsole.getInputValue($$event);
                                        return Curry._1(setRevision, (function (param) {
                                                      return value;
                                                    }));
                                      })
                                  }), React.createElement(Button.default, {
                                    variant: "outlined",
                                    color: "primary",
                                    classes: {
                                      root: Css.style(/* :: */[
                                            Css.height(Css.rem(2)),
                                            /* [] */0
                                          ])
                                    },
                                    onClick: (function (_event) {
                                        return Curry._1(setFetchKey, (function (k) {
                                                      return k + 1 | 0;
                                                    }));
                                      }),
                                    children: I18N$BsConsole.show(undefined, "Fetch")
                                  }))) : React.createElement(Col2$BsConsole.make, {
                            children: null
                          }, React.createElement(InputLabel.default, {
                                classes: {
                                  root: Css.style(/* :: */[
                                        Css.marginTop(Css.px(8)),
                                        /* :: */[
                                          Css.marginBottom(Css.px(8)),
                                          /* [] */0
                                        ]
                                      ])
                                },
                                children: React.createElement(BtTypography$BsConsole.make, {
                                      variant: /* Body1 */8,
                                      children: I18N$BsConsole.show(undefined, "Not finding the submodules you expected?")
                                    })
                              }), React.createElement(Button.default, {
                                variant: "outlined",
                                color: "primary",
                                classes: {
                                  root: Css.style(/* :: */[
                                        Css.height(Css.rem(2)),
                                        /* [] */0
                                      ])
                                },
                                onClick: (function (_event) {
                                    return Curry._1(setShowRevisionForm, (function (param) {
                                                  return true;
                                                }));
                                  }),
                                children: I18N$BsConsole.show(undefined, "Change revision")
                              }))), React.createElement(Row2$BsConsole.make, {
                      justifyContent: /* flexEnd */924268066,
                      className: Css.style(/* :: */[
                            Css.marginTop(Css.rem(1)),
                            /* [] */0
                          ]),
                      children: null
                    }, React.createElement(Button.default, {
                          color: "primary",
                          classes: {
                            root: Css.style(/* :: */[
                                  Css.marginRight(Css.rem(0.5)),
                                  /* [] */0
                                ])
                          },
                          onClick: (function (_event) {
                              return Curry._1(close, undefined);
                            }),
                          children: I18N$BsConsole.show(undefined, "Cancel")
                        }), React.createElement(Button.default, {
                          variant: "raised",
                          color: "primary",
                          disabled: Belt_Option.isNone(submodules),
                          onClick: (function (_event) {
                              if (submodules !== undefined) {
                                MetricsEvent$BsConsole.send(/* ActionWithAttrs */Block.__(1, [
                                        /* SourceCode_CloneSubmodules */89,
                                        Json_encode.object_(/* :: */[
                                              /* tuple */[
                                                "project",
                                                projectName
                                              ],
                                              /* [] */0
                                            ])
                                      ]));
                                Task2$BsConsole.handle(token, Curry._3(cloneSubmodules(projectName, universeName, config, id, defaultCommit, Belt_List.fromArray(Belt_Array.keepMap(submodules, (function (param) {
                                                          if (param[1]) {
                                                            return param[0];
                                                          }
                                                          
                                                        }))))(undefined), undefined, undefined, undefined), undefined, (function (submodules) {
                                        if (submodules.tag) {
                                          console.log(submodules);
                                          return ;
                                        }
                                        var submodules$1 = submodules[0];
                                        return Curry._1(setSubmodules, (function (param) {
                                                      return Belt_Array.map(submodules$1, (function (subm) {
                                                                    return /* tuple */[
                                                                            subm,
                                                                            true
                                                                          ];
                                                                  }));
                                                    }));
                                      }));
                                return Curry._1(close, undefined);
                              }
                              
                            }),
                          children: I18N$BsConsole.show(undefined, "Add submodules")
                        }))));
}

var CloneSubmodules = {
  make: Repository$CloneSubmodules
};

function Repository$Show(Props) {
  var repo = Props.repo;
  var config = Props.config;
  var projectName = Props.projectName;
  var token = Props.token;
  var refetchRepositories = Props.refetchRepositories;
  var refetchRepoRules = Props.refetchRepoRules;
  var supportsAutoSubmodules = Props.supportsAutoSubmodules;
  var universeName = config.universe.name;
  var match = React.useState((function () {
          return 0;
        }));
  var setRefetchCommitRulesKey = match[1];
  var refetchCommitRules = function (param) {
    return Curry._1(setRefetchCommitRulesKey, (function (oldKey) {
                  return oldKey + 1 | 0;
                }));
  };
  var match$1 = React.useState((function () {
          
        }));
  var setCommitRules = match$1[1];
  var commitRules = match$1[0];
  var match$2 = React.useState((function () {
          
        }));
  var setAnchorEl = match$2[1];
  var anchorEl = match$2[0];
  var match$3 = React.useState((function () {
          return false;
        }));
  var setDisplayTestDialog = match$3[1];
  var match$4 = React.useState((function () {
          return false;
        }));
  var setDisplayEditRepoForm = match$4[1];
  var match$5 = React.useState((function () {
          return false;
        }));
  var setShowDeleteRepoDialog = match$5[1];
  var match$6 = React.useState((function () {
          return false;
        }));
  var setShowSubmodulesDialog = match$6[1];
  React.useEffect((function () {
          var id = repo.id;
          if (id !== undefined) {
            Task2$BsConsole.handle(token, CommitRule$BsConsole.fetchCommitRules(config, id, universeName, projectName), undefined, (function (rules) {
                    Belt_Result.map(rules, (function (rules) {
                            return Curry._1(setCommitRules, (function (param) {
                                          return rules;
                                        }));
                          }));
                    
                  }));
          }
          
        }), [match[0]]);
  var menuItemStyle = {
    root: Css.style(/* :: */[
          Css.paddingTop(Css.em(0.25)),
          /* :: */[
            Css.paddingBottom(Css.em(0.25)),
            /* [] */0
          ]
        ])
  };
  var status = repo.cloneStatus;
  var status$1 = repo.cloneStatus;
  return React.createElement(Col2$BsConsole.make, {
              children: null
            }, React.createElement(Row2$BsConsole.make, {
                  alignItems: /* center */98248149,
                  justifyContent: /* spaceBetween */516682146,
                  className: Css.style(/* :: */[
                        Css.width(Css.pct(100)),
                        /* [] */0
                      ]),
                  children: null
                }, React.createElement(BtTypography$BsConsole.make, {
                      variant: /* Heading5 */4,
                      children: I18N$BsConsole.showSkip(repo.name)
                    }), React.createElement(Row2$BsConsole.make, {
                      alignItems: /* baseline */287825029,
                      children: null
                    }, React.createElement(React.Fragment, undefined, status !== undefined ? (
                            status === "complete" ? React.createElement(Tooltip.default, {
                                    children: React.createElement("span", {
                                          className: Css.style(/* :: */[
                                                Css.color(Css.hex(Colors$BsConsole.greenLight)),
                                                /* [] */0
                                              ])
                                        }, React.createElement(Icon.default, {
                                              children: I18N$BsConsole.showSkip("check_circle")
                                            })),
                                    title: I18N$BsConsole.get(undefined, "Successfully cloned")
                                  }) : (
                                status === "in_progress" ? React.createElement(Tooltip.default, {
                                        children: React.createElement("span", {
                                              className: Css.style(/* :: */[
                                                    Css.color(Css.hex(Colors$BsConsole.greenLight)),
                                                    /* [] */0
                                                  ])
                                            }, React.createElement(Icon.default, {
                                                  children: I18N$BsConsole.showSkip("cloud_download")
                                                })),
                                        title: I18N$BsConsole.get(undefined, "Clone in progress")
                                      }) : (
                                    status === "error" ? React.createElement(Tooltip.default, {
                                            children: React.createElement("span", {
                                                  className: Css.style(/* :: */[
                                                        Css.color(Css.hex(Colors$BsConsole.redDark)),
                                                        /* [] */0
                                                      ])
                                                }, React.createElement(Icon.default, {
                                                      children: I18N$BsConsole.showSkip("close")
                                                    })),
                                            title: I18N$BsConsole.get(undefined, "Error while cloning: ") + Belt_Option.mapWithDefault(repo.cloneError, "", Util$BsConsole.identity)
                                          }) : null
                                  )
                              )
                          ) : null), React.createElement(Button.default, {
                          onClick: (function ($$event) {
                              var el = $$event.currentTarget;
                              if (!(el == null)) {
                                return Curry._1(setAnchorEl, (function (param) {
                                              return Caml_option.some(el);
                                            }));
                              }
                              
                            }),
                          children: React.createElement(Icon.default, {
                                children: I18N$BsConsole.showSkip("more_vert")
                              })
                        }), React.createElement(Menu.default, {
                          disableAutoFocusItem: true,
                          classes: {
                            paper: Css.style(/* :: */[
                                  Css.marginLeft(Css.rem(1.5)),
                                  /* [] */0
                                ])
                          },
                          anchorEl: anchorEl,
                          open: Belt_Option.isSome(anchorEl),
                          onClose: (function (param) {
                              return Curry._1(setAnchorEl, (function (param) {
                                            
                                          }));
                            }),
                          children: null
                        }, React.createElement(MenuItem.default, {
                              classes: menuItemStyle,
                              onClick: (function (param) {
                                  MetricsEvent$BsConsole.send(/* ActionWithAttrs */Block.__(1, [
                                          /* SourceCode_RepoTest */84,
                                          Json_encode.object_(/* :: */[
                                                /* tuple */[
                                                  "project",
                                                  projectName
                                                ],
                                                /* [] */0
                                              ])
                                        ]));
                                  Curry._1(setDisplayTestDialog, (function (param) {
                                          return true;
                                        }));
                                  return Curry._1(setAnchorEl, (function (param) {
                                                
                                              }));
                                }),
                              children: React.createElement(BtTypography$BsConsole.make, {
                                    variant: /* Caption */11,
                                    children: I18N$BsConsole.show(undefined, "Test")
                                  })
                            }), React.createElement(MenuItem.default, {
                              classes: menuItemStyle,
                              onClick: (function (param) {
                                  Curry._1(setAnchorEl, (function (param) {
                                          
                                        }));
                                  return Curry._1(setDisplayEditRepoForm, (function (param) {
                                                return true;
                                              }));
                                }),
                              children: React.createElement(BtTypography$BsConsole.make, {
                                    variant: /* Caption */11,
                                    children: I18N$BsConsole.show(undefined, "Edit")
                                  })
                            }), React.createElement(MenuItem.default, {
                              classes: menuItemStyle,
                              onClick: (function (param) {
                                  Curry._1(setShowDeleteRepoDialog, (function (param) {
                                          return true;
                                        }));
                                  return Curry._1(setAnchorEl, (function (param) {
                                                
                                              }));
                                }),
                              children: React.createElement(BtTypography$BsConsole.make, {
                                    variant: /* Caption */11,
                                    children: I18N$BsConsole.show(undefined, "Delete")
                                  })
                            }), React.createElement(MenuItem.default, {
                              classes: menuItemStyle,
                              onClick: (function (param) {
                                  Curry._1(setShowSubmodulesDialog, (function (param) {
                                          return true;
                                        }));
                                  return Curry._1(setAnchorEl, (function (param) {
                                                
                                              }));
                                }),
                              children: React.createElement(BtTypography$BsConsole.make, {
                                    variant: /* Caption */11,
                                    children: I18N$BsConsole.show(undefined, "Add submodules")
                                  })
                            })))), React.createElement(BtSettings$BsConsole.FancyDivider.make, {
                  className: Css.style(/* :: */[
                        Css.marginBottom(Css.rem(0.5)),
                        /* :: */[
                          Css.height(Css.px(4)),
                          /* [] */0
                        ]
                      ])
                }), React.createElement(Row2$BsConsole.make, {
                  alignItems: /* center */98248149,
                  justifyContent: /* flexStart */662439529,
                  className: Css.style(/* :: */[
                        Css.width(Css.pct(100)),
                        /* :: */[
                          Css.marginBottom(Css.rem(0.5)),
                          /* :: */[
                            Css.marginTop(Css.rem(0.5)),
                            /* [] */0
                          ]
                        ]
                      ]),
                  children: null
                }, React.createElement(BtTypography$BsConsole.make, {
                      variant: /* Body1 */8,
                      className: Css.style(/* :: */[
                            Css.color(Css.hex(Colors$BsConsole.blackA54)),
                            /* [] */0
                          ]),
                      children: I18N$BsConsole.show(undefined, "URL")
                    }), React.createElement(BtTypography$BsConsole.make, {
                      variant: /* Body1 */8,
                      className: Css.style(/* :: */[
                            Css.marginLeft(Css.rem(2)),
                            /* :: */[
                              Css.overflow(/* hidden */-862584982),
                              /* :: */[
                                Css.unsafe("overflowWrap", "break-word"),
                                /* [] */0
                              ]
                            ]
                          ]),
                      color: Colors$BsConsole.grey1,
                      children: I18N$BsConsole.showSkip(repo.url)
                    })), status$1 === "error" ? React.createElement(BtTypography$BsConsole.make, {
                    variant: /* Body1 */8,
                    className: Css.style(/* :: */[
                          Css.marginBottom(Css.rem(1)),
                          /* [] */0
                        ]),
                    color: Colors$BsConsole.errorDark,
                    children: I18N$BsConsole.showSkip(Belt_Option.mapWithDefault(repo.cloneError, "", Util$BsConsole.identity))
                  }) : null, React.createElement(Row2$BsConsole.make, {
                  alignItems: /* center */98248149,
                  justifyContent: /* flexStart */662439529,
                  className: Css.style(/* :: */[
                        Css.width(Css.pct(100)),
                        /* :: */[
                          Css.marginBottom(Css.rem(0.5)),
                          /* [] */0
                        ]
                      ]),
                  children: null
                }, React.createElement(BtTypography$BsConsole.make, {
                      variant: /* Body1 */8,
                      className: Css.style(/* :: */[
                            Css.color(Css.hex(Colors$BsConsole.blackA54)),
                            /* [] */0
                          ]),
                      children: I18N$BsConsole.show(undefined, "Revisions")
                    }), React.createElement("ul", undefined, commitRules !== undefined && Belt_List.length(commitRules) > 0 ? Belt_List.toArray(Belt_List.map(Belt_List.sort(commitRules, (function (r, r$prime) {
                                      return r.position - r$prime.position | 0;
                                    })), (function (r) {
                                  return React.createElement("li", undefined, React.createElement("code", undefined, I18N$BsConsole.showSkip(r.attributeTemplate + "\n")));
                                }))) : null, React.createElement("li", undefined, React.createElement("code", undefined, I18N$BsConsole.showSkip(repo.defaultCommit))))), React.createElement(Dialog.default, {
                  open: match$4[0],
                  classes: {
                    paper: Css.style(/* :: */[
                          Css.width(Css.pct(90)),
                          /* :: */[
                            Css.unsafe("maxWidth", "unset"),
                            /* [] */0
                          ]
                        ]),
                    container: Css.style(/* :: */[
                          Css.alignItems(/* flexStart */662439529),
                          /* [] */0
                        ]),
                    root: Css.style(/* :: */[
                          Css.userSelect(/* none */-922086728),
                          /* [] */0
                        ])
                  },
                  onClose: (function (param) {
                      return Curry._1(setDisplayEditRepoForm, (function (param) {
                                    return false;
                                  }));
                    }),
                  children: React.createElement(CardContent.default, {
                        children: React.createElement(Repository$Edit, {
                              config: config,
                              projectName: projectName,
                              token: token,
                              refetchRepositories: refetchRepositories,
                              refetchCommitRules: refetchCommitRules,
                              close: (function (param) {
                                  return Curry._1(setDisplayEditRepoForm, (function (param) {
                                                return false;
                                              }));
                                }),
                              repository: repo,
                              supportsAutoSubmodules: supportsAutoSubmodules,
                              key: "-1"
                            })
                      })
                }), React.createElement(Dialog.default, {
                  open: match$3[0],
                  classes: {
                    paper: Css.style(/* :: */[
                          Css.minWidth(Css.px(400)),
                          /* :: */[
                            Css.maxWidth(Css.px(800)),
                            /* [] */0
                          ]
                        ]),
                    container: Css.style(/* :: */[
                          Css.alignItems(/* center */98248149),
                          /* [] */0
                        ]),
                    root: Css.style(/* :: */[
                          Css.userSelect(/* none */-922086728),
                          /* [] */0
                        ])
                  },
                  onClose: (function (param) {
                      return Curry._1(setDisplayTestDialog, (function (param) {
                                    return false;
                                  }));
                    }),
                  children: React.createElement(CardContent.default, {
                        children: React.createElement(Repository$TestFetch, {
                              config: config,
                              id: repo.id,
                              projectName: projectName,
                              token: token,
                              close: (function (param) {
                                  return Curry._1(setDisplayTestDialog, (function (param) {
                                                return false;
                                              }));
                                })
                            })
                      })
                }), React.createElement(Dialog.default, {
                  open: match$5[0],
                  classes: {
                    paper: Css.style(/* :: */[
                          Css.minWidth(Css.px(400)),
                          /* :: */[
                            Css.maxWidth(Css.px(800)),
                            /* [] */0
                          ]
                        ]),
                    container: Css.style(/* :: */[
                          Css.alignItems(/* center */98248149),
                          /* [] */0
                        ]),
                    root: Css.style(/* :: */[
                          Css.userSelect(/* none */-922086728),
                          /* [] */0
                        ])
                  },
                  onClose: (function (_e) {
                      return Curry._1(setShowDeleteRepoDialog, (function (param) {
                                    return false;
                                  }));
                    }),
                  children: React.createElement(CardContent.default, {
                        children: null
                      }, React.createElement(Mui$BsConsole.IconButton.make, {
                            ariaLabel: "Close",
                            classes: {
                              root: Css.style(/* :: */[
                                    Css.height(Css.px(48)),
                                    /* :: */[
                                      Css.position(/* absolute */-1013592457),
                                      /* :: */[
                                        Css.color(Css.hex(Colors$BsConsole.blackA54)),
                                        /* :: */[
                                          Css.top(/* zero */-789508312),
                                          /* :: */[
                                            Css.right(/* zero */-789508312),
                                            /* [] */0
                                          ]
                                        ]
                                      ]
                                    ]
                                  ])
                            },
                            color: "primary",
                            onClick: (function (_event) {
                                return Curry._1(setShowDeleteRepoDialog, (function (param) {
                                              return false;
                                            }));
                              }),
                            children: React.createElement(Clear.default, { })
                          }), React.createElement(Row2$BsConsole.make, {
                            className: Css.style(/* :: */[
                                  Css.marginTop(Css.rem(2)),
                                  /* :: */[
                                    Css.marginBottom(Css.rem(1.5)),
                                    /* [] */0
                                  ]
                                ]),
                            children: React.createElement(BtTypography$BsConsole.make, {
                                  variant: /* Heading6 */5,
                                  children: I18N$BsConsole.showSkip("Are you sure you want to delete repository \"" + (repo.name + "\"?"))
                                })
                          }), React.createElement(Row2$BsConsole.make, {
                            justifyContent: /* flexEnd */924268066,
                            children: null
                          }, React.createElement(Button.default, {
                                color: "primary",
                                onClick: (function (_event) {
                                    return Curry._1(setShowDeleteRepoDialog, (function (param) {
                                                  return false;
                                                }));
                                  }),
                                children: I18N$BsConsole.show(undefined, "Cancel")
                              }), React.createElement(Button.default, {
                                variant: "raised",
                                color: "primary",
                                disabled: false,
                                onClick: (function (_event) {
                                    MetricsEvent$BsConsole.send(/* ActionWithAttrs */Block.__(1, [
                                            /* SourceCode_RepoDelete */85,
                                            Json_encode.object_(/* :: */[
                                                  /* tuple */[
                                                    "project",
                                                    projectName
                                                  ],
                                                  /* [] */0
                                                ])
                                          ]));
                                    return Task2$BsConsole.handle(token, deleteRepo(repo.id, config.universe.name, projectName, config, undefined), undefined, (function (param) {
                                                  Curry._1(refetchRepositories, undefined);
                                                  return Curry._1(refetchRepoRules, undefined);
                                                }));
                                  }),
                                children: I18N$BsConsole.show(undefined, "Yes")
                              })))
                }), React.createElement(Dialog.default, {
                  open: match$6[0],
                  classes: {
                    paper: Css.style(/* :: */[
                          Css.unsafe("maxWidth", "unset"),
                          /* [] */0
                        ]),
                    container: Css.style(/* :: */[
                          Css.alignItems(/* flexStart */662439529),
                          /* [] */0
                        ]),
                    root: Css.style(/* :: */[
                          Css.userSelect(/* none */-922086728),
                          /* [] */0
                        ])
                  },
                  onClose: (function (_e) {
                      return Curry._1(setShowSubmodulesDialog, (function (param) {
                                    return false;
                                  }));
                    }),
                  children: React.createElement(CardContent.default, {
                        children: React.createElement(Repository$CloneSubmodules, {
                              config: config,
                              id: repo.id,
                              defaultCommit: repo.defaultCommit,
                              projectName: projectName,
                              universeName: universeName,
                              token: token,
                              close: (function (param) {
                                  return Curry._1(setShowSubmodulesDialog, (function (param) {
                                                return false;
                                              }));
                                })
                            })
                      })
                }));
}

var Show = {
  make: Repository$Show
};

exports.AuthType = AuthType;
exports.revisionValid = revisionValid;
exports.RepoNameNotUnique = RepoNameNotUnique;
exports.fromJson = fromJson;
exports.toJson = toJson;
exports.getServerVersion = getServerVersion;
exports.fetchRepositories = fetchRepositories;
exports.saveRepository = saveRepository;
exports.updateRepository = updateRepository;
exports.RepoTestError = RepoTestError;
exports.testRepositoryFetching = testRepositoryFetching;
exports.deleteRepo = deleteRepo;
exports.Create = Create;
exports.TestFetch = TestFetch;
exports.Edit = Edit;
exports.SubmodulesTasks = SubmodulesTasks;
exports.CloneSubmodules = CloneSubmodules;
exports.Show = Show;
/* Css Not a pure module */
