// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var $$String = require("bs-platform/lib/js/string.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var ReactDom = require("react-dom");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Belt_SortArray = require("bs-platform/lib/js/belt_SortArray.js");
var Col2$BsConsole = require("./components/Col2.js");
var I18N$BsConsole = require("./I18N.js");
var Row2$BsConsole = require("./components/Row2.js");
var Util$BsConsole = require("./util.js");
var Image$BsConsole = require("./components/Image.js");
var Colors$BsConsole = require("./Colors.js");
var Issues$BsConsole = require("./bs-crdb-response/src/Issues.js");
var WfUtils$BsConsole = require("./workflow/WfUtils.js");
var WfRouter$BsConsole = require("./workflow/WfRouter.js");
var WfActions$BsConsole = require("./workflow/manual-actions/WfActions.js");
var Add = require("@material-ui/icons/Add");
var BtTypography$BsConsole = require("./BtTypography.js");
var Edit = require("@material-ui/icons/Edit");
var SnackPurveyor$BsConsole = require("./SnackPurveyor.js");
var Button = require("@material-ui/core/Button");
var Close = require("@material-ui/icons/Close");
var BtAutocomplete$BsConsole = require("./BtAutocomplete.js");
var Divider = require("@material-ui/core/Divider");
var Popover = require("@material-ui/core/Popover");
var Tooltip = require("@material-ui/core/Tooltip");
var MenuItem = require("@material-ui/core/MenuItem");
var MenuList = require("@material-ui/core/MenuList");
var TextField = require("@material-ui/core/TextField");
var InboxUnlinkedTicketJsx3$BsConsole = require("./inbox/InboxUnlinkedTicketJsx3.js");

var popover = Css.style(/* :: */[
      Css.zIndex(1000),
      /* [] */0
    ]);

var popoverContainer = Css.style(/* :: */[
      Css.padding(Css.px(16)),
      /* [] */0
    ]);

var popoverTitle = Css.style(/* :: */[
      Css.marginBottom(Css.rem(1)),
      /* [] */0
    ]);

var input = Css.style(/* :: */[
      Css.selector("input", /* :: */[
            Css.padding(Css.px(8)),
            /* [] */0
          ]),
      /* :: */[
        Css.selector("label", /* :: */[
              Css.transform(Css.translate(Css.px(14), Css.px(10))),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var tagRow = Css.style(/* :: */[
      Css.marginTop(Css.px(16)),
      /* :: */[
        Css.maxWidth(Css.px(195)),
        /* :: */[
          Css.width(Css.pct(100)),
          /* [] */0
        ]
      ]
    ]);

function tagContainer(tag) {
  return Css.style(/* :: */[
              Css.fontFamily("Inconsolata"),
              /* :: */[
                Css.backgroundColor(Css.hex(Colors$BsConsole.getTagBackgroundColor(tag))),
                /* [] */0
              ]
            ]);
}

var assigneeItem = Css.style(/* :: */[
      Css.backgroundColor(Css.hex(Colors$BsConsole.grey7)),
      /* [] */0
    ]);

var ticketItem = Css.style(/* :: */[
      Css.backgroundColor(Css.hex(Colors$BsConsole.grey7)),
      /* :: */[
        Css.selector(":hover .link", /* :: */[
              Css.textDecoration(/* underline */131142924),
              /* :: */[
                Css.color(Css.hex(Colors$BsConsole.accent)),
                /* [] */0
              ]
            ]),
        /* [] */0
      ]
    ]);

var ticketItemToBeDeleted = Css.style(/* :: */[
      Css.backgroundColor(Css.hex(Colors$BsConsole.redLight)),
      /* :: */[
        Css.textDecoration(/* lineThrough */412155569),
        /* :: */[
          Css.selector(".link", /* :: */[
                Css.color(Css.hex(Colors$BsConsole.redDark)),
                /* [] */0
              ]),
          /* :: */[
            Css.selector(":hover .link", /* :: */[
                  Css.textDecoration(/* underline */131142924),
                  /* [] */0
                ]),
            /* [] */0
          ]
        ]
      ]
    ]);

var itemContainer = Css.style(/* :: */[
      Css.fontSize(Css.px(13)),
      /* :: */[
        Css.selector(":not(:last-child)", /* :: */[
              Css.marginRight(Css.px(4)),
              /* [] */0
            ]),
        /* :: */[
          Css.marginBottom(Css.px(4)),
          /* :: */[
            Css.borderRadius(Css.px(2)),
            /* [] */0
          ]
        ]
      ]
    ]);

var item = Css.style(/* :: */[
      Css.padding(Css.px(4)),
      /* [] */0
    ]);

function tagListItem(tag) {
  return Css.merge(/* :: */[
              tagContainer(tag),
              /* :: */[
                Css.style(/* :: */[
                      Css.padding(Css.px(4)),
                      /* :: */[
                        Css.borderRadius(Css.px(2)),
                        /* [] */0
                      ]
                    ]),
                /* [] */0
              ]
            ]);
}

var iconButton = {
  root: Css.style(/* :: */[
        Css.fontSize(Css.rem(1)),
        /* :: */[
          Css.important(Css.padding(Css.px(4))),
          /* [] */0
        ]
      ]),
  label: Css.merge(/* :: */[
        Css.style(/* :: */[
              Css.unsafe("fontSize", "inherit"),
              /* [] */0
            ]),
        /* :: */[
          "triage-action",
          /* [] */0
        ]
      ])
};

var closeContainer = Css.merge(/* :: */[
      Css.style(/* :: */[
            Css.display(/* flex */-1010954439),
            /* :: */[
              Css.alignItems(/* center */98248149),
              /* :: */[
                Css.padding(Css.px(4)),
                /* :: */[
                  Css.cursor(/* pointer */-786317123),
                  /* :: */[
                    Css.alignSelf(/* stretch */-162316795),
                    /* :: */[
                      Css.borderBottomRightRadius(Css.px(4)),
                      /* :: */[
                        Css.borderTopRightRadius(Css.px(4)),
                        /* :: */[
                          Css.hover(/* :: */[
                                Css.backgroundColor(Css.hex(Colors$BsConsole.blackA125)),
                                /* [] */0
                              ]),
                          /* [] */0
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]),
      /* :: */[
        "extra-smaller-icon",
        /* :: */[
          "filter-delete",
          /* [] */0
        ]
      ]
    ]);

var emptyText = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.grey4)),
      /* [] */0
    ]);

var actionRow = Css.style(/* :: */[
      Css.width(Css.pct(100)),
      /* :: */[
        Css.marginTop(Css.px(25)),
        /* :: */[
          Css.selector("button:not(:first-of-type)", /* :: */[
                Css.marginLeft(Css.px(4)),
                /* [] */0
              ]),
          /* [] */0
        ]
      ]
    ]);

var menuList = Css.style(/* :: */[
      Css.padding2(Css.px(8), /* zero */-789508312),
      /* :: */[
        Css.focus(/* :: */[
              Css.unsafe("outline", "none"),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var menuItemContainer = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* [] */0
    ]);

var menuItemText = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.alignItems(/* center */98248149),
        /* :: */[
          Css.marginLeft(Css.px(5)),
          /* [] */0
        ]
      ]
    ]);

var menuItemBase = Css.style(/* :: */[
      Css.unsafe("height", "unset"),
      /* :: */[
        Css.fontSize(Css.px(14)),
        /* :: */[
          Css.lineHeight(/* `abs */[
                4845682,
                1.0
              ]),
          /* :: */[
            Css.hover(/* :: */[
                  Css.backgroundColor(Css.hex(Colors$BsConsole.accent)),
                  /* :: */[
                    Css.color(Css.hex(Colors$BsConsole.white)),
                    /* [] */0
                  ]
                ]),
            /* :: */[
              Css.focus(/* :: */[
                    Css.unsafe("outline", "none"),
                    /* :: */[
                      Css.backgroundColor(Css.hex(Colors$BsConsole.accent)),
                      /* :: */[
                        Css.color(Css.hex(Colors$BsConsole.white)),
                        /* [] */0
                      ]
                    ]
                  ]),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var menuItemLessPadding = Css.merge(/* :: */[
      menuItemBase,
      /* :: */[
        Css.style(/* :: */[
              Css.padding2(Css.px(8), Css.px(16)),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var menuItem = Css.merge(/* :: */[
      menuItemBase,
      /* :: */[
        Css.style(/* :: */[
              Css.padding2(Css.px(8), Css.px(40)),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var menuSectionHeader = Css.style(/* :: */[
      Css.padding2(Css.px(8), Css.px(16)),
      /* :: */[
        Css.fontSize(Css.px(14)),
        /* :: */[
          Css.fontWeight(/* `num */[
                5496390,
                500
              ]),
          /* [] */0
        ]
      ]
    ]);

var integrationHeader = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.padding2(Css.px(8), Css.px(16)),
        /* :: */[
          Css.fontSize(Css.px(11)),
          /* :: */[
            Css.lineHeight(Css.px(16)),
            /* :: */[
              Css.fontWeight(/* `num */[
                    5496390,
                    500
                  ]),
              /* :: */[
                Css.color(Css.hex(Colors$BsConsole.Debugger.grey1)),
                /* [] */0
              ]
            ]
          ]
        ]
      ]
    ]);

var icon = Css.style(/* :: */[
      Css.width(Css.px(16)),
      /* :: */[
        Css.height(Css.px(16)),
        /* :: */[
          Css.marginRight(Css.px(8)),
          /* [] */0
        ]
      ]
    ]);

var menuDivider = Css.style(/* :: */[
      Css.margin2(Css.px(5), Css.px(0)),
      /* [] */0
    ]);

var menuEditIcon = Css.style(/* :: */[
      Css.fontSize(Css.px(20)),
      /* [] */0
    ]);

var Styles = {
  popover: popover,
  popoverContainer: popoverContainer,
  popoverTitle: popoverTitle,
  input: input,
  tagRow: tagRow,
  tagContainer: tagContainer,
  assigneeItem: assigneeItem,
  ticketItem: ticketItem,
  ticketItemToBeDeleted: ticketItemToBeDeleted,
  itemContainer: itemContainer,
  item: item,
  tagListItem: tagListItem,
  iconButton: iconButton,
  closeContainer: closeContainer,
  emptyText: emptyText,
  actionRow: actionRow,
  menuList: menuList,
  menuItemContainer: menuItemContainer,
  menuItemText: menuItemText,
  menuItemBase: menuItemBase,
  menuItemLessPadding: menuItemLessPadding,
  menuItem: menuItem,
  menuSectionHeader: menuSectionHeader,
  integrationHeader: integrationHeader,
  icon: icon,
  menuDivider: menuDivider,
  menuEditIcon: menuEditIcon
};

function TriageActionPopover$ItemChip(Props) {
  var item$1 = Props.item;
  var itemToString = Props.itemToString;
  var onDelete = Props.onDelete;
  var deleteTooltipTitle = Props.deleteTooltipTitle;
  var classNameOpt = Props.className;
  var href = Props.href;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return React.createElement(Row2$BsConsole.make, {
              alignItems: /* center */98248149,
              className: Css.merge(/* :: */[
                    itemContainer,
                    /* :: */[
                      className,
                      /* [] */0
                    ]
                  ]),
              children: null
            }, href !== undefined ? React.createElement("a", {
                    className: Css.merge(/* :: */[
                          item,
                          /* :: */[
                            "link",
                            /* [] */0
                          ]
                        ]),
                    href: href,
                    target: "_blank"
                  }, I18N$BsConsole.showSkip(Curry._1(itemToString, item$1))) : React.createElement("div", {
                    className: Css.merge(/* :: */[
                          item,
                          /* :: */[
                            "item-string",
                            /* [] */0
                          ]
                        ])
                  }, I18N$BsConsole.showSkip(Curry._1(itemToString, item$1))), React.createElement(Tooltip.default, {
                  placement: "top",
                  children: React.createElement("div", {
                        className: closeContainer,
                        role: "button",
                        onClick: (function (param) {
                            return Curry._1(onDelete, item$1);
                          })
                      }, React.createElement(Close.default, {
                            color: "inherit",
                            fontSize: "inherit"
                          })),
                  title: deleteTooltipTitle
                }));
}

var ItemChip = {
  make: TriageActionPopover$ItemChip
};

function TriageActionPopover$Input(Props) {
  var placeholder = Props.placeholder;
  var value = Props.value;
  var onChange = Props.onChange;
  var onFocus = Props.onFocus;
  var label = Props.label;
  return React.createElement(TextField.default, {
              placeholder: placeholder,
              value: value,
              color: "primary",
              variant: "outlined",
              inputRef: (function (input) {
                  if (input == null) {
                    return ;
                  }
                  var domElement = ReactDom.findDOMNode(input);
                  setTimeout((function (param) {
                          return domElement.focus();
                        }), 250);
                  
                }),
              classes: {
                root: input
              },
              onChange: onChange,
              onFocus: onFocus,
              label: label
            });
}

var Input = {
  make: TriageActionPopover$Input
};

function TriageActionPopover$Tags(Props) {
  var anchorEl = Props.anchorEl;
  var allTags = Props.allTags;
  var tags = Props.tags;
  var onClose = Props.onClose;
  var handleAddRemoveTags = Props.handleAddRemoveTags;
  var isEmpty = Belt_List.length(tags) === 0;
  return React.createElement(Popover.default, {
              anchorOrigin: {
                horizontal: "left",
                vertical: "center"
              },
              anchorEl: anchorEl,
              open: true,
              className: popover,
              onClose: onClose,
              children: React.createElement(Col2$BsConsole.make, {
                    className: popoverContainer,
                    children: null
                  }, React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Heading6 */5,
                        className: popoverTitle,
                        children: I18N$BsConsole.show(undefined, "Edit group tags")
                      }), React.createElement(Row2$BsConsole.make, {
                        children: React.createElement(BtAutocomplete$BsConsole.make, {
                              toString: (function (tag) {
                                  return tag;
                                }),
                              freeSolo: true,
                              freeSoloLabel: I18N$BsConsole.get(undefined, "Add new tag"),
                              considerations: Belt_List.toArray(Belt_List.keep(allTags, (function (tag) {
                                          return !Belt_List.has(tags, tag, (function (a, b) {
                                                        return a === b;
                                                      }));
                                        }))),
                              onChange: (function (tag, param) {
                                  var tag$1 = tag.trim();
                                  var match = Belt_List.has(tags, tag$1, (function (a, b) {
                                          return a === b;
                                        }));
                                  if (tag$1 === "" || match) {
                                    return ;
                                  } else {
                                    return Curry._2(handleAddRemoveTags, /* :: */[
                                                tag$1,
                                                /* [] */0
                                              ], /* [] */0);
                                  }
                                }),
                              placeholder: I18N$BsConsole.get(undefined, "Tags"),
                              renderListOption: (function (tag, idx) {
                                  return React.createElement("div", {
                                              key: tag + ("__" + String(idx)),
                                              className: tagListItem(tag)
                                            }, I18N$BsConsole.showSkip(tag));
                                }),
                              renderInput: (function (param) {
                                  var onFocus = param.onFocus;
                                  var onChange = param.onChange;
                                  var onFocus$prime = Curry.__1(onFocus);
                                  var onChange$prime = Curry.__1(onChange);
                                  return React.createElement(TriageActionPopover$Input, {
                                              placeholder: param.placeholder,
                                              value: param.value,
                                              onChange: onChange$prime,
                                              onFocus: onFocus$prime,
                                              label: I18N$BsConsole.get(undefined, "Search tags to add")
                                            });
                                }),
                              clearOnChange: true
                            })
                      }), React.createElement(Row2$BsConsole.make, {
                        flexWrap: /* wrap */-822134326,
                        className: tagRow,
                        children: null
                      }, Belt_List.toArray(Belt_List.mapWithIndex(tags, (function (idx, tag) {
                                  return React.createElement(TriageActionPopover$ItemChip, {
                                              item: tag,
                                              itemToString: (function (tag) {
                                                  return tag;
                                                }),
                                              onDelete: (function (tag$prime) {
                                                  return Curry._2(handleAddRemoveTags, /* [] */0, /* :: */[
                                                              tag$prime,
                                                              /* [] */0
                                                            ]);
                                                }),
                                              deleteTooltipTitle: I18N$BsConsole.get(undefined, "Remove tag"),
                                              className: tagContainer(tag),
                                              key: tag + ("__" + String(idx))
                                            });
                                }))), isEmpty ? React.createElement(BtTypography$BsConsole.make, {
                              variant: /* Body2 */9,
                              className: emptyText,
                              children: I18N$BsConsole.show(undefined, "No tags")
                            }) : null), React.createElement(Row2$BsConsole.make, {
                        justifyContent: /* flexEnd */924268066,
                        className: actionRow,
                        children: React.createElement(Button.default, {
                              color: "primary",
                              onClick: (function (param) {
                                  return Curry._1(onClose, undefined);
                                }),
                              children: I18N$BsConsole.show(undefined, "Close")
                            })
                      }))
            });
}

var Tags = {
  make: TriageActionPopover$Tags
};

function TriageActionPopover$Assignees(Props) {
  var anchorEl = Props.anchorEl;
  var projectUsers = Props.projectUsers;
  var assignedProjectUser = Props.assignedProjectUser;
  var onClose = Props.onClose;
  var handleSetAssignee = Props.handleSetAssignee;
  var match = React.useState((function () {
          return assignedProjectUser;
        }));
  var setCurrentAssignee = match[1];
  var currentAssignee = match[0];
  return React.createElement(Popover.default, {
              anchorOrigin: {
                horizontal: "left",
                vertical: "center"
              },
              anchorEl: anchorEl,
              open: true,
              className: popover,
              onClose: onClose,
              disableEscapeKeyDown: true,
              disableBackdropClick: true,
              children: React.createElement(Col2$BsConsole.make, {
                    className: popoverContainer,
                    children: null
                  }, React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Heading6 */5,
                        className: popoverTitle,
                        children: I18N$BsConsole.show(undefined, "Edit group assignee")
                      }), React.createElement(Row2$BsConsole.make, {
                        children: React.createElement(BtAutocomplete$BsConsole.make, {
                              toString: (function (user) {
                                  return user.username;
                                }),
                              considerations: projectUsers,
                              onChange: (function (param, user) {
                                  return Curry._1(setCurrentAssignee, (function (param) {
                                                return user;
                                              }));
                                }),
                              placeholder: I18N$BsConsole.get(undefined, "Users"),
                              renderInput: (function (param) {
                                  var onFocus = param.onFocus;
                                  var onChange = param.onChange;
                                  var onFocus$prime = Curry.__1(onFocus);
                                  var onChange$prime = Curry.__1(onChange);
                                  return React.createElement(TriageActionPopover$Input, {
                                              placeholder: param.placeholder,
                                              value: param.value,
                                              onChange: onChange$prime,
                                              onFocus: onFocus$prime,
                                              label: I18N$BsConsole.get(undefined, "Search users")
                                            });
                                }),
                              clearOnChange: true
                            })
                      }), React.createElement(Row2$BsConsole.make, {
                        flexWrap: /* wrap */-822134326,
                        className: tagRow,
                        children: currentAssignee !== undefined ? React.createElement(TriageActionPopover$ItemChip, {
                                item: currentAssignee,
                                itemToString: (function (user) {
                                    return user.username;
                                  }),
                                onDelete: (function (param) {
                                    return Curry._1(setCurrentAssignee, (function (param) {
                                                  
                                                }));
                                  }),
                                deleteTooltipTitle: I18N$BsConsole.get(undefined, "Remove assignee"),
                                className: assigneeItem,
                                key: currentAssignee.username
                              }) : React.createElement(BtTypography$BsConsole.make, {
                                variant: /* Body2 */9,
                                className: emptyText,
                                children: I18N$BsConsole.show(undefined, "No assignee")
                              })
                      }), React.createElement(Row2$BsConsole.make, {
                        justifyContent: /* flexEnd */924268066,
                        className: actionRow,
                        children: null
                      }, React.createElement(Button.default, {
                            variant: "contained",
                            color: "primary",
                            disabled: currentAssignee !== undefined ? (
                                assignedProjectUser !== undefined ? currentAssignee.username === assignedProjectUser.username : false
                              ) : assignedProjectUser === undefined,
                            onClick: (function (param) {
                                Curry._1(handleSetAssignee, currentAssignee);
                                return Curry._1(onClose, undefined);
                              }),
                            children: I18N$BsConsole.show(undefined, "Apply")
                          }), React.createElement(Button.default, {
                            color: "primary",
                            onClick: (function (param) {
                                return Curry._1(onClose, undefined);
                              }),
                            children: I18N$BsConsole.show(undefined, "Cancel")
                          })))
            });
}

var Assignees = {
  make: TriageActionPopover$Assignees
};

function TriageActionPopover$EditTickets(Props) {
  var anchorEl = Props.anchorEl;
  var tickets = Props.tickets;
  var onClose = Props.onClose;
  var handleRemoveTickets = Props.handleRemoveTickets;
  var match = React.useState((function () {
          return /* [] */0;
        }));
  var setToBeDeleted = match[1];
  var toBeDeleted = match[0];
  var match$1 = React.useState((function () {
          return /* Default */0;
        }));
  var setMode = match$1[1];
  var ticketsToShow = Belt_Array.keep(Issues$BsConsole.Tickets.getLinkedAndUnlinked(tickets), (function (ticket) {
          return Belt_Option.isNone(Belt_List.getBy(toBeDeleted, (function (ticket$prime) {
                            return Issues$BsConsole.Ticket.eq(ticket, ticket$prime);
                          })));
        }));
  if (match$1[0]) {
    return React.createElement(Popover.default, {
                anchorOrigin: {
                  horizontal: "right",
                  vertical: "center"
                },
                anchorEl: anchorEl,
                open: true,
                className: popover,
                onClose: onClose,
                children: React.createElement(Col2$BsConsole.make, {
                      className: popoverContainer,
                      children: null
                    }, React.createElement(BtTypography$BsConsole.make, {
                          variant: /* Heading6 */5,
                          className: popoverTitle,
                          children: I18N$BsConsole.show(undefined, "Manage group tickets")
                        }), React.createElement(BtTypography$BsConsole.make, {
                          variant: /* Caption */11,
                          children: I18N$BsConsole.show(undefined, "Are you sure you would like to remove these tickets from the group?")
                        }), React.createElement(Row2$BsConsole.make, {
                          className: actionRow,
                          children: null
                        }, React.createElement(Button.default, {
                              variant: "contained",
                              color: "primary",
                              disabled: Belt_List.length(toBeDeleted) === 0,
                              classes: {
                                root: Css.style(/* :: */[
                                      Css.whiteSpace(/* nowrap */867913355),
                                      /* [] */0
                                    ])
                              },
                              onClick: (function (param) {
                                  Curry._1(handleRemoveTickets, toBeDeleted);
                                  return Curry._1(onClose, undefined);
                                }),
                              children: I18N$BsConsole.show(undefined, "Yes, remove")
                            }), React.createElement(Button.default, {
                              color: "primary",
                              onClick: (function (param) {
                                  return Curry._1(setMode, (function (param) {
                                                return /* Default */0;
                                              }));
                                }),
                              children: I18N$BsConsole.show(undefined, "Cancel")
                            }))),
                key: "edit-tickets__confirm"
              });
  } else {
    return React.createElement(Popover.default, {
                anchorOrigin: {
                  horizontal: "right",
                  vertical: "center"
                },
                anchorEl: anchorEl,
                open: true,
                className: popover,
                onClose: onClose,
                children: React.createElement(Col2$BsConsole.make, {
                      className: popoverContainer,
                      children: null
                    }, React.createElement(BtTypography$BsConsole.make, {
                          variant: /* Heading6 */5,
                          className: popoverTitle,
                          children: I18N$BsConsole.show(undefined, "Manage group tickets")
                        }), React.createElement(Row2$BsConsole.make, {
                          flexWrap: /* wrap */-822134326,
                          className: tagRow,
                          children: null
                        }, Belt_Array.mapWithIndex(ticketsToShow, (function (idx, ticket) {
                                var href = Issues$BsConsole.Ticket.getUrl(ticket);
                                var tmp = {
                                  item: ticket,
                                  itemToString: (function (ticket) {
                                      return Issues$BsConsole.Ticket.getShort(ticket);
                                    }),
                                  onDelete: (function (ticket) {
                                      return Curry._1(setToBeDeleted, (function (currentToBeDeleted) {
                                                    return /* :: */[
                                                            ticket,
                                                            currentToBeDeleted
                                                          ];
                                                  }));
                                    }),
                                  deleteTooltipTitle: I18N$BsConsole.get(undefined, "Remove ticket"),
                                  className: ticketItem,
                                  key: Issues$BsConsole.Ticket.getShort(ticket) + ("__" + String(idx))
                                };
                                if (href !== undefined) {
                                  tmp.href = Caml_option.valFromOption(href);
                                }
                                return React.createElement(TriageActionPopover$ItemChip, tmp);
                              })), Belt_List.toArray(Belt_List.mapWithIndex(Belt_List.keep(toBeDeleted, (function (ticket) {
                                        return Belt_Option.isSome(Belt_List.getBy(toBeDeleted, (function (ticket$prime) {
                                                          return Issues$BsConsole.Ticket.eq(ticket, ticket$prime);
                                                        })));
                                      })), (function (idx, ticket) {
                                    var href = Issues$BsConsole.Ticket.getUrl(ticket);
                                    var tmp = {
                                      item: ticket,
                                      itemToString: (function (ticket) {
                                          return Issues$BsConsole.Ticket.getShort(ticket);
                                        }),
                                      onDelete: (function (ticket) {
                                          return Curry._1(setToBeDeleted, (function (currentToBeDeleted) {
                                                        return Belt_List.keep(currentToBeDeleted, (function (ticket$prime) {
                                                                      return !Issues$BsConsole.Ticket.eq(ticket, ticket$prime);
                                                                    }));
                                                      }));
                                        }),
                                      deleteTooltipTitle: I18N$BsConsole.get(undefined, "keep ticket"),
                                      className: ticketItemToBeDeleted,
                                      key: Issues$BsConsole.Ticket.getShort(ticket) + ("__" + String(idx))
                                    };
                                    if (href !== undefined) {
                                      tmp.href = Caml_option.valFromOption(href);
                                    }
                                    return React.createElement(TriageActionPopover$ItemChip, tmp);
                                  }))), Belt_List.length(tickets) === 0 ? React.createElement(BtTypography$BsConsole.make, {
                                variant: /* Caption */11,
                                className: Css.style(/* :: */[
                                      Css.color(Css.hex(Colors$BsConsole.grey4)),
                                      /* [] */0
                                    ]),
                                children: I18N$BsConsole.show(undefined, "No tickets linked")
                              }) : null), React.createElement(Row2$BsConsole.make, {
                          className: actionRow,
                          children: null
                        }, React.createElement(Button.default, {
                              variant: "contained",
                              color: "primary",
                              disabled: Belt_List.length(toBeDeleted) === 0,
                              onClick: (function (param) {
                                  return Curry._1(setMode, (function (param) {
                                                return /* Confirm */1;
                                              }));
                                }),
                              children: I18N$BsConsole.show(undefined, "Apply")
                            }), React.createElement(Button.default, {
                              color: "primary",
                              onClick: (function (param) {
                                  return Curry._1(onClose, undefined);
                                }),
                              children: I18N$BsConsole.show(undefined, "Cancel")
                            }))),
                key: "edit-tickets__default"
              });
  }
}

var EditTickets = {
  make: TriageActionPopover$EditTickets
};

function handleWfAssociateTicket(workflow, config, token, fingerprint, projectName, fetchIssues, onClose) {
  return WfActions$BsConsole.createTicketWithTask(config.user.uid, fingerprint, token, workflow, projectName, (function (ticketParams) {
                if (ticketParams === undefined) {
                  return ;
                }
                var url = ticketParams.url;
                var $$short = ticketParams.short;
                SnackPurveyor$BsConsole.enqueue({
                      label: $$short,
                      onClick: (function (param) {
                          return Util$BsConsole.openUrl(url);
                        })
                    }, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Success creating external ticket."));
                Curry._1(onClose, undefined);
                return Curry._1(fetchIssues, [fingerprint]);
              }), config, undefined);
}

function TriageActionPopover$TicketMenuList(Props) {
  var anchorEl = Props.anchorEl;
  var handleChangeUrl = Props.handleChangeUrl;
  var workflows = Props.workflows;
  var tickets = Props.tickets;
  var projectName = Props.projectName;
  var handleSetEdit = Props.handleSetEdit;
  var handleAddLink = Props.handleAddLink;
  var config = Props.config;
  var fingerprint = Props.fingerprint;
  var fetchIssues = Props.fetchIssues;
  var token = Props.token;
  var onClose = Props.onClose;
  var ticketCreateWorkflows = WfUtils$BsConsole.filterBySupportedAction(workflows, "ticket:create");
  var hasWorkflows = ticketCreateWorkflows.length !== 0;
  var currentIntegrationType = {
    contents: ""
  };
  return React.createElement(Popover.default, {
              anchorOrigin: {
                horizontal: "left",
                vertical: "center"
              },
              anchorEl: anchorEl,
              open: true,
              className: popover,
              onClose: onClose,
              children: React.createElement(MenuList.default, {
                    autoFocus: false,
                    autoFocusItem: false,
                    classes: {
                      root: menuList
                    },
                    children: null
                  }, React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Body2 */9,
                        className: menuSectionHeader,
                        children: I18N$BsConsole.show(undefined, "Create a ticket")
                      }), Belt_Array.mapWithIndex(Belt_SortArray.stableSortBy(ticketCreateWorkflows, (function (a1, a2) {
                              return $$String.compare(a1.watcher.type_.toLowerCase(), a2.watcher.type_.toLowerCase());
                            })), (function (idx, workflow) {
                          var watcher = workflow.watcher;
                          return React.createElement("div", undefined, currentIntegrationType.contents !== watcher.type_ ? (currentIntegrationType.contents = watcher.type_, React.createElement(BtTypography$BsConsole.make, {
                                              variant: /* Body2 */9,
                                              className: integrationHeader,
                                              children: null
                                            }, React.createElement(Image$BsConsole.make, {
                                                  className: icon,
                                                  src: Belt_Option.getWithDefault(workflow.integration.description.icon, ""),
                                                  alt: watcher.type_ + " Icon"
                                                }), I18N$BsConsole.show(undefined, watcher.type_.replace("_legacy", "").toUpperCase()))) : null, React.createElement(MenuItem.default, {
                                          disableRipple: true,
                                          classes: {
                                            root: menuItem
                                          },
                                          onClick: (function (param) {
                                              return handleWfAssociateTicket(workflow, config, token, fingerprint, projectName, fetchIssues, onClose);
                                            }),
                                          children: React.createElement(BtTypography$BsConsole.make, {
                                                variant: /* Body2 */9,
                                                children: I18N$BsConsole.show(undefined, watcher.userFriendlyName)
                                              }),
                                          key: watcher.watcherName + ("__" + String(idx))
                                        }));
                        })), hasWorkflows ? React.createElement(BtTypography$BsConsole.make, {
                          variant: /* Body2 */9,
                          className: integrationHeader,
                          children: I18N$BsConsole.show(undefined, "OTHER")
                        }) : null, React.createElement(MenuItem.default, {
                        disableRipple: true,
                        classes: {
                          root: hasWorkflows ? menuItem : menuItemLessPadding
                        },
                        onClick: (function (param) {
                            return Curry._1(handleAddLink, undefined);
                          }),
                        children: React.createElement(BtTypography$BsConsole.make, {
                              variant: /* Body2 */9,
                              children: I18N$BsConsole.show(undefined, "Add link")
                            }),
                        key: "add_link"
                      }), React.createElement(Divider.default, {
                        className: menuDivider
                      }), tickets !== undefined && Belt_List.length(tickets) !== 0 ? React.createElement(React.Fragment, undefined, React.createElement(MenuItem.default, {
                              disableRipple: true,
                              classes: {
                                root: menuItemLessPadding
                              },
                              onClick: (function (param) {
                                  return Curry._1(handleSetEdit, tickets);
                                }),
                              children: React.createElement("div", {
                                    className: menuItemContainer
                                  }, React.createElement(Edit.default, {
                                        className: menuEditIcon
                                      }), React.createElement(BtTypography$BsConsole.make, {
                                        variant: /* Body2 */9,
                                        className: menuItemText,
                                        children: I18N$BsConsole.show(undefined, "Manage existing tickets")
                                      })),
                              key: "edit"
                            })) : null, hasWorkflows ? null : React.createElement(MenuItem.default, {
                          disableRipple: true,
                          classes: {
                            root: menuItemLessPadding
                          },
                          onClick: (function (param) {
                              return Curry._1(handleChangeUrl, /* ProjectSettingsIntegrations */Block.__(35, [
                                            projectName,
                                            WfRouter$BsConsole.root,
                                            undefined
                                          ]));
                            }),
                          children: React.createElement("div", {
                                className: menuItemContainer
                              }, React.createElement(Add.default, { }), React.createElement(BtTypography$BsConsole.make, {
                                    variant: /* Body2 */9,
                                    className: menuItemText,
                                    children: I18N$BsConsole.show(undefined, "Add a workflow integration")
                                  })),
                          key: "setup_workflow"
                        })),
              key: "menu-list__popover"
            });
}

var TicketMenuList = {
  handleWfAssociateTicket: handleWfAssociateTicket,
  make: TriageActionPopover$TicketMenuList
};

function TriageActionPopover$Tickets(Props) {
  var anchorEl = Props.anchorEl;
  var onClose = Props.onClose;
  var tickets = Props.tickets;
  var handleSetIssue = Props.handleSetIssue;
  var projectName = Props.projectName;
  var workflows = Props.workflows;
  var handleChangeUrl = Props.handleChangeUrl;
  var config = Props.config;
  var fingerprint = Props.fingerprint;
  var fetchIssues = Props.fetchIssues;
  var token = Props.token;
  var handleRemoveTickets = Props.handleRemoveTickets;
  var match = React.useState((function () {
          return /* Menu */0;
        }));
  var setMode = match[1];
  var mode = match[0];
  var match$1 = React.useState((function () {
          
        }));
  var setModal = match$1[1];
  return React.createElement(React.Fragment, undefined, mode ? React.createElement(TriageActionPopover$EditTickets, {
                    anchorEl: anchorEl,
                    tickets: mode[0],
                    onClose: onClose,
                    handleRemoveTickets: handleRemoveTickets
                  }) : React.createElement(TriageActionPopover$TicketMenuList, {
                    anchorEl: anchorEl,
                    handleChangeUrl: handleChangeUrl,
                    workflows: workflows,
                    tickets: tickets,
                    projectName: projectName,
                    handleSetEdit: (function (tickets$prime) {
                        return Curry._1(setMode, (function (param) {
                                      return /* Edit */[tickets$prime];
                                    }));
                      }),
                    handleAddLink: (function (param) {
                        return Curry._1(setModal, (function (param) {
                                      return /* AddLink */0;
                                    }));
                      }),
                    config: config,
                    fingerprint: fingerprint,
                    fetchIssues: fetchIssues,
                    token: token,
                    onClose: onClose
                  }), match$1[0] !== undefined ? React.createElement(InboxUnlinkedTicketJsx3$BsConsole.make, {
                    onSubmit: (function (ticket) {
                        Curry._2(handleSetIssue, /* :: */[
                              /* AddUnlinkedTicket */Block.__(5, [ticket]),
                              /* [] */0
                            ], undefined);
                        return Curry._1(onClose, undefined);
                      }),
                    onClose: onClose,
                    projectName: projectName,
                    workflows: workflows,
                    config: config,
                    token: token,
                    fetchIssues: fetchIssues,
                    selectedFingerprints: [fingerprint]
                  }) : null);
}

var Tickets = {
  make: TriageActionPopover$Tickets
};

exports.Styles = Styles;
exports.ItemChip = ItemChip;
exports.Input = Input;
exports.Tags = Tags;
exports.Assignees = Assignees;
exports.EditTickets = EditTickets;
exports.TicketMenuList = TicketMenuList;
exports.Tickets = Tickets;
/* popover Not a pure module */
