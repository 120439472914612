// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Slate = require("slate");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Slate$BsConsole = require("../bindings/Slate.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var Uncaught$BsConsole = require("../Uncaught.js");
var Backtrace$BsConsole = require("../bindings/Backtrace.js");
var SpecialCharacterAutocomplete$BsConsole = require("../SpecialCharacterAutocomplete.js");

function getMentionsCount(value) {
  return value.mentions.length;
}

function getMentions(value) {
  return Belt_Array.keep(Belt_Array.keepMap(value.split(SpecialCharacterAutocomplete$BsConsole.wordBreakRegex), (function (c) {
                    return c;
                  })), (function (word) {
                return word.startsWith("@");
              }));
}

function getMentionedUserIds(value, users) {
  var mentions = getMentions(value);
  return Belt_Array.map(Belt_Array.keep(users, (function (user) {
                    return Belt_Array.some(mentions, (function (mention) {
                                  return mention === "@" + user.username;
                                }));
                  })), (function (user) {
                return user.uid;
              }));
}

function getInvalidMentions(mentions, users) {
  return Belt_Array.keep(mentions, (function (mention) {
                return !Belt_Array.some(users, (function (user) {
                              return "@" + user.username === mention;
                            }));
              }));
}

var Mentions = {
  getMentionsCount: getMentionsCount,
  getMentions: getMentions,
  getMentionedUserIds: getMentionedUserIds,
  getInvalidMentions: getInvalidMentions
};

function fromJson(json) {
  return {
          comment: Json_decode.field("comment", Json_decode.string, json),
          mentions: Json_decode.field("mentions", (function (param) {
                  return Json_decode.array(Json_decode.$$float, param);
                }), json)
        };
}

function toJson(t) {
  return Json_encode.object_(/* :: */[
              /* tuple */[
                "comment",
                t.comment
              ],
              /* :: */[
                /* tuple */[
                  "mentions",
                  t.mentions
                ],
                /* [] */0
              ]
            ]);
}

function format(value, users) {
  var mentionedUserIds = getMentionedUserIds(value, users);
  return {
          comment: value,
          mentions: mentionedUserIds
        };
}

function stringify(value) {
  return JSON.stringify(toJson(value));
}

var Comments = {
  Mentions: Mentions,
  fromJson: fromJson,
  toJson: toJson,
  format: format,
  stringify: stringify
};

var initialValue = /* NewVersion */Block.__(1, [{
      comment: "",
      mentions: []
    }]);

function toStringResult(value) {
  if (typeof value === "number") {
    return /* Error */Block.__(1, ["Error: Failed to parse comment. This error has been reported to Backtrace."]);
  } else if (value.tag) {
    return /* Ok */Block.__(0, [value[0].comment]);
  } else {
    return Slate$BsConsole.toStringResult(value[0].toJSON());
  }
}

function decode(value) {
  try {
    var json = JSON.parse(value);
    if (Slate$BsConsole.isSlateFormat(json)) {
      return /* OldVersion */Block.__(0, [Slate.Value.fromJSON(json)]);
    } else {
      return /* NewVersion */Block.__(1, [fromJson(json)]);
    }
  }
  catch (raw_err){
    var err = Caml_js_exceptions.internalToOCamlException(raw_err);
    var error = Uncaught$BsConsole.castToJsExn(err);
    var __x = Backtrace$BsConsole.Attributes.addString("shouldSendAlert", "true", Backtrace$BsConsole.Attributes.addString("src_module", "CommentFormatter/parseString", Backtrace$BsConsole.Attributes.make(undefined)));
    Backtrace$BsConsole.Client.send(__x, /* `exn */[
          5049499,
          error
        ]);
    return /* Error */0;
  }
}

var initialResultValue = /* Ok */Block.__(0, [""]);

exports.Comments = Comments;
exports.initialValue = initialValue;
exports.initialResultValue = initialResultValue;
exports.toStringResult = toStringResult;
exports.decode = decode;
/* slate Not a pure module */
