// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Js_dict = require("bs-platform/lib/js/js_dict.js");
var Js_json = require("bs-platform/lib/js/js_json.js");
var Caml_obj = require("bs-platform/lib/js/caml_obj.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_format = require("bs-platform/lib/js/caml_format.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var Json_encode = require("@glennsl/bs-json/lib/js/src/Json_encode.bs.js");
var Belt_MapString = require("bs-platform/lib/js/belt_MapString.js");
var Caml_primitive = require("bs-platform/lib/js/caml_primitive.js");
var Col2$BsConsole = require("../components/Col2.js");
var I18N$BsConsole = require("../I18N.js");
var Link$BsConsole = require("../Link.js");
var Row2$BsConsole = require("../components/Row2.js");
var Task2$BsConsole = require("../Task2.js");
var BugGrid$BsConsole = require("./BugGrid.js");
var BugTabs$BsConsole = require("./BugTabs.js");
var BugTasks$BsConsole = require("./data/BugTasks.js");
var Services$BsConsole = require("../Services.js");
var Backtrace$BsConsole = require("../bindings/Backtrace.js");
var BugHeader$BsConsole = require("./BugHeader.js");
var TeamsUtils$BsConsole = require("../teams/TeamsUtils.js");
var BugRegisters$BsConsole = require("./BugRegisters.js");
var BugVariables$BsConsole = require("./BugVariables.js");
var MetricsEvent$BsConsole = require("../bs-metrics/MetricsEvent.js");
var Configuration$BsConsole = require("../configuration.js");
var RouterContext$BsConsole = require("../context/RouterContext.js");
var Button = require("@material-ui/core/Button");
var BugBttCallstack$BsConsole = require("./BugBttCallstack.js");
var BugFetchedSource$BsConsole = require("./BugFetchedSource.js");
var BugCrdbAttributes$BsConsole = require("./data/BugCrdbAttributes.js");
var BugBttThreadSelect$BsConsole = require("./BugBttThreadSelect.js");
var BugRegistersSimple$BsConsole = require("./BugRegistersSimple.js");
var BugVariableDetails$BsConsole = require("./BugVariableDetails.js");
var ProjectNameContext$BsConsole = require("../context/ProjectNameContext.js");
var UserFrontendSettings$BsConsole = require("../UserFrontendSettings.js");
var BugDefectVariablesToggle$BsConsole = require("./BugDefectVariablesToggle.js");

function getFaultingFrame(threads) {
  return Belt_Option.map(Caml_option.undefined_to_opt(threads.find((function (thread) {
                        return thread.faulted;
                      }))), (function (thread) {
                var frame = thread.user.find((function (frame) {
                        return Caml_obj.caml_equal(frame.faulted, true);
                      }));
                return /* tuple */[
                        thread,
                        frame === undefined ? undefined : Caml_option.some(frame)
                      ];
              }));
}

function getDefectsByFrame(defects, selectedFrame) {
  var selectedFrameFuncName = selectedFrame.symbol;
  var selectedFrameLibrary = selectedFrame.path;
  if (defects === undefined) {
    return ;
  }
  var resultingDefects = Belt_List.filter(defects, (function (defect) {
          var match = defect.functionName;
          var match$1 = defect.fileName;
          if (match !== undefined) {
            if (match$1 !== undefined) {
              if (selectedFrameLibrary !== undefined) {
                if (match === selectedFrameFuncName) {
                  return true;
                } else {
                  return match$1 === selectedFrameLibrary;
                }
              } else {
                return false;
              }
            } else {
              return match === selectedFrameFuncName;
            }
          } else if (match$1 !== undefined && selectedFrameLibrary !== undefined) {
            return match$1 === selectedFrameLibrary;
          } else {
            return false;
          }
        }));
  if (Belt_List.length(resultingDefects) > 0) {
    return resultingDefects;
  }
  
}

function getSelectedFrame(thread) {
  var frames = thread.user;
  var faultedFrame = Belt_Array.getBy(frames, (function (frame) {
          return Caml_obj.caml_equal(frame.faulted, true);
        }));
  return Belt_Option.getWithDefault(faultedFrame, Belt_Array.getExn(frames, 0));
}

function getSelectedVariable(frame) {
  return Belt_Option.flatMap(frame.variables, (function (__x) {
                return Belt_Array.get(__x, 0);
              }));
}

function reducer(error, state, action) {
  switch (action.tag | 0) {
    case /* SelectThread */0 :
        var thread = action[0];
        var selectedFrame = getSelectedFrame(thread);
        var selectedVariable = getSelectedVariable(selectedFrame);
        return {
                selectedThread: thread,
                selectedFrame: selectedFrame,
                selectedVariable: selectedVariable,
                selectedPane: state.selectedPane,
                expandedThreads: state.expandedThreads,
                defectVariablesView: state.defectVariablesView
              };
    case /* SelectFrame */1 :
        var selectedFrame$1 = action[0];
        return {
                selectedThread: state.selectedThread,
                selectedFrame: selectedFrame$1,
                selectedVariable: getSelectedVariable(selectedFrame$1),
                selectedPane: state.selectedPane,
                expandedThreads: state.expandedThreads,
                defectVariablesView: /* Variables */0
              };
    case /* SelectVariable */2 :
        return {
                selectedThread: state.selectedThread,
                selectedFrame: state.selectedFrame,
                selectedVariable: action[0],
                selectedPane: state.selectedPane,
                expandedThreads: state.expandedThreads,
                defectVariablesView: state.defectVariablesView
              };
    case /* JumpTo */3 :
        var variable = action[2];
        var thread$1 = action[0];
        var selectedFrame$2 = Belt_Option.getWithDefault(action[1], getSelectedFrame(thread$1));
        var selectedVariable$1 = variable !== undefined ? variable : getSelectedVariable(selectedFrame$2);
        return {
                selectedThread: thread$1,
                selectedFrame: selectedFrame$2,
                selectedVariable: selectedVariable$1,
                selectedPane: state.selectedPane,
                expandedThreads: state.expandedThreads,
                defectVariablesView: state.defectVariablesView
              };
    case /* SelectPane */4 :
        return {
                selectedThread: state.selectedThread,
                selectedFrame: state.selectedFrame,
                selectedVariable: state.selectedVariable,
                selectedPane: action[0],
                expandedThreads: state.expandedThreads,
                defectVariablesView: state.defectVariablesView
              };
    case /* KeyboardInput */5 :
        var keyboardInput = action[0];
        var match = state.selectedPane;
        switch (match) {
          case /* Threads */0 :
              var visibleThreads = Belt_Array.reduce(error.threadGroups, [], (function (acc, threadGroup) {
                      var firstThread = Belt_Array.getExn(threadGroup, 0);
                      var expanded = Belt_MapString.getWithDefault(state.expandedThreads, firstThread.id, false);
                      return Belt_Array.concat(acc, expanded ? threadGroup : [firstThread]);
                    }));
              var selectedThreadIndex = visibleThreads.findIndex((function (t) {
                      return Caml_obj.caml_equal(t, state.selectedThread);
                    }));
              switch (keyboardInput) {
                case /* Up */0 :
                    var selectedThread = Belt_Array.getExn(visibleThreads, Caml_primitive.caml_int_min(Caml_primitive.caml_int_max(selectedThreadIndex - 1 | 0, 0), visibleThreads.length - 1 | 0));
                    var selectedFrame$3 = getSelectedFrame(selectedThread);
                    var selectedVariable$2 = getSelectedVariable(selectedFrame$3);
                    return {
                            selectedThread: selectedThread,
                            selectedFrame: selectedFrame$3,
                            selectedVariable: selectedVariable$2,
                            selectedPane: state.selectedPane,
                            expandedThreads: state.expandedThreads,
                            defectVariablesView: state.defectVariablesView
                          };
                case /* Down */1 :
                    var selectedThread$1 = Belt_Array.getExn(visibleThreads, Caml_primitive.caml_int_min(Caml_primitive.caml_int_max(selectedThreadIndex + 1 | 0, 0), visibleThreads.length - 1 | 0));
                    var selectedFrame$4 = getSelectedFrame(selectedThread$1);
                    var selectedVariable$3 = getSelectedVariable(selectedFrame$4);
                    return {
                            selectedThread: selectedThread$1,
                            selectedFrame: selectedFrame$4,
                            selectedVariable: selectedVariable$3,
                            selectedPane: state.selectedPane,
                            expandedThreads: state.expandedThreads,
                            defectVariablesView: state.defectVariablesView
                          };
                case /* Left */2 :
                    var key = state.selectedThread.id;
                    return {
                            selectedThread: state.selectedThread,
                            selectedFrame: state.selectedFrame,
                            selectedVariable: state.selectedVariable,
                            selectedPane: state.selectedPane,
                            expandedThreads: Belt_MapString.set(state.expandedThreads, key, false),
                            defectVariablesView: state.defectVariablesView
                          };
                case /* Right */3 :
                    var key$1 = state.selectedThread.id;
                    return {
                            selectedThread: state.selectedThread,
                            selectedFrame: state.selectedFrame,
                            selectedVariable: state.selectedVariable,
                            selectedPane: state.selectedPane,
                            expandedThreads: Belt_MapString.set(state.expandedThreads, key$1, true),
                            defectVariablesView: state.defectVariablesView
                          };
                case /* Space */4 :
                    var key$2 = state.selectedThread.id;
                    var selectedThreadExpanded = Belt_MapString.getWithDefault(state.expandedThreads, key$2, false);
                    return {
                            selectedThread: state.selectedThread,
                            selectedFrame: state.selectedFrame,
                            selectedVariable: state.selectedVariable,
                            selectedPane: state.selectedPane,
                            expandedThreads: Belt_MapString.set(state.expandedThreads, key$2, !selectedThreadExpanded),
                            defectVariablesView: state.defectVariablesView
                          };
                
              }
          case /* Callstack */1 :
              var frames = state.selectedThread.user;
              var selectedFrameIndex = frames.findIndex((function (f) {
                      return Caml_obj.caml_equal(f, state.selectedFrame);
                    }));
              if (keyboardInput !== 1) {
                if (keyboardInput !== 0) {
                  return state;
                }
                var selectedFrame$5 = Belt_Array.getExn(state.selectedThread.user, Caml_primitive.caml_int_min(Caml_primitive.caml_int_max(selectedFrameIndex - 1 | 0, 0), frames.length - 1 | 0));
                var selectedVariable$4 = getSelectedVariable(selectedFrame$5);
                return {
                        selectedThread: state.selectedThread,
                        selectedFrame: selectedFrame$5,
                        selectedVariable: selectedVariable$4,
                        selectedPane: state.selectedPane,
                        expandedThreads: state.expandedThreads,
                        defectVariablesView: state.defectVariablesView
                      };
              }
              var selectedFrame$6 = Belt_Array.getExn(state.selectedThread.user, Caml_primitive.caml_int_min(Caml_primitive.caml_int_max(selectedFrameIndex + 1 | 0, 0), frames.length - 1 | 0));
              var selectedVariable$5 = getSelectedVariable(selectedFrame$6);
              return {
                      selectedThread: state.selectedThread,
                      selectedFrame: selectedFrame$6,
                      selectedVariable: selectedVariable$5,
                      selectedPane: state.selectedPane,
                      expandedThreads: state.expandedThreads,
                      defectVariablesView: state.defectVariablesView
                    };
          case /* Variables */2 :
              var variables = state.selectedFrame.variables;
              if (variables === undefined) {
                return state;
              }
              var selectedVariableIndex = variables.findIndex((function (v) {
                      return Caml_obj.caml_equal(v, state.selectedVariable);
                    }));
              if (keyboardInput !== 1) {
                if (keyboardInput !== 0) {
                  return state;
                } else {
                  return {
                          selectedThread: state.selectedThread,
                          selectedFrame: state.selectedFrame,
                          selectedVariable: Belt_Array.getExn(variables, Caml_primitive.caml_int_min(Caml_primitive.caml_int_max(selectedVariableIndex - 1 | 0, 0), variables.length - 1 | 0)),
                          selectedPane: state.selectedPane,
                          expandedThreads: state.expandedThreads,
                          defectVariablesView: state.defectVariablesView
                        };
                }
              } else {
                return {
                        selectedThread: state.selectedThread,
                        selectedFrame: state.selectedFrame,
                        selectedVariable: Belt_Array.getExn(variables, Caml_primitive.caml_int_min(Caml_primitive.caml_int_max(selectedVariableIndex + 1 | 0, 0), variables.length - 1 | 0)),
                        selectedPane: state.selectedPane,
                        expandedThreads: state.expandedThreads,
                        defectVariablesView: state.defectVariablesView
                      };
              }
          
        }
    case /* ToggleThread */6 :
        var threadId = action[0];
        var isSelected = Belt_MapString.getWithDefault(state.expandedThreads, threadId, false);
        return {
                selectedThread: state.selectedThread,
                selectedFrame: state.selectedFrame,
                selectedVariable: state.selectedVariable,
                selectedPane: state.selectedPane,
                expandedThreads: Belt_MapString.set(state.expandedThreads, threadId, !isSelected),
                defectVariablesView: state.defectVariablesView
              };
    case /* SetDefectVariablesView */7 :
        return {
                selectedThread: state.selectedThread,
                selectedFrame: state.selectedFrame,
                selectedVariable: state.selectedVariable,
                selectedPane: state.selectedPane,
                expandedThreads: state.expandedThreads,
                defectVariablesView: action[0]
              };
    
  }
}

function BugBtt(Props) {
  var config = Props.config;
  var token = Props.token;
  var crdbRow = Props.crdbRow;
  var onAddFilter = Props.onAddFilter;
  var initialSelection = Props.initialSelection;
  var aperture = Props.aperture;
  var error = Props.error;
  var w = Props.width;
  var h = Props.height;
  var hasRepositories = Props.hasRepositories;
  var handleChangeUrl = Props.handleChangeUrl;
  var projectName = React.useContext(ProjectNameContext$BsConsole.ctx);
  var universeName = config.universe.name;
  var project = Belt_Option.getExn(Configuration$BsConsole.getProject(projectName, config));
  var match = React.useContext(RouterContext$BsConsole.ctx);
  var handleReplaceUrl = match.handleReplaceUrl;
  var match$1 = React.useState((function () {
          return Belt_Option.getWithDefault(Belt_Option.flatMap(config.userSettings, (function (us) {
                            return us.debugViewToggles;
                          })), UserFrontendSettings$BsConsole.DebuggerToggles.$$default);
        }));
  var setToggles = match$1[1];
  var toggles = match$1[0];
  var toggleIsLocalVarsVisbile = function (param) {
    var newVal = !toggles.showVariables;
    Curry._1(setToggles, (function (param) {
            return {
                    condenseFunctions: toggles.condenseFunctions,
                    showVariables: newVal,
                    showBugTabsDataRaw: toggles.showBugTabsDataRaw
                  };
          }));
    return UserFrontendSettings$BsConsole.reduce(token, config.user.uid, /* :: */[
                /* DebuggerToggleShowVariables */Block.__(11, [newVal]),
                /* [] */0
              ], TeamsUtils$BsConsole.isGuest(config), (function (param) {
                  
                }), (function (err) {
                  var __x = Backtrace$BsConsole.Attributes.addString("src_module", "BugBtt.re/toggleIsLocalVarsVisible", Backtrace$BsConsole.Attributes.make(undefined));
                  return Backtrace$BsConsole.Client.send(__x, /* `string */[
                              288368849,
                              "Failed to store local variable toggle state: " + err
                            ]);
                }));
  };
  var toggleCondenseFunctions = function (param) {
    var newVal = !toggles.condenseFunctions;
    Curry._1(setToggles, (function (param) {
            return {
                    condenseFunctions: newVal,
                    showVariables: toggles.showVariables,
                    showBugTabsDataRaw: toggles.showBugTabsDataRaw
                  };
          }));
    return UserFrontendSettings$BsConsole.reduce(token, config.user.uid, /* :: */[
                /* DebuggerToggleCondenseFunctions */Block.__(10, [newVal]),
                /* [] */0
              ], TeamsUtils$BsConsole.isGuest(config), (function (param) {
                  
                }), (function (err) {
                  var __x = Backtrace$BsConsole.Attributes.addString("src_module", "BugBtt.re/toggleCondenseFunctions", Backtrace$BsConsole.Attributes.make(undefined));
                  return Backtrace$BsConsole.Client.send(__x, /* `string */[
                              288368849,
                              "Failed to store condense functions toggle state: " + err
                            ]);
                }));
  };
  var toggleShowBugTabsDataRaw = function (param) {
    var newVal = !toggles.showBugTabsDataRaw;
    Curry._1(setToggles, (function (param) {
            return {
                    condenseFunctions: toggles.condenseFunctions,
                    showVariables: toggles.showVariables,
                    showBugTabsDataRaw: newVal
                  };
          }));
    return UserFrontendSettings$BsConsole.reduce(token, config.user.uid, /* :: */[
                /* DebuggerToggleShowBugTabsDataRow */Block.__(12, [newVal]),
                /* [] */0
              ], TeamsUtils$BsConsole.isGuest(config), (function (param) {
                  
                }), (function (err) {
                  var __x = Backtrace$BsConsole.Attributes.addString("src_module", "BugBtt.re/toggleShowBugTabsDataRaw", Backtrace$BsConsole.Attributes.make(undefined));
                  return Backtrace$BsConsole.Client.send(__x, /* `string */[
                              288368849,
                              "Failed to store raw toggle state: " + err
                            ]);
                }));
  };
  var reducer$1 = React.useMemo((function () {
          return (function (param, param$1) {
              return reducer(error, param, param$1);
            });
        }), [error]);
  var thread = Belt_Option.getWithDefault(Caml_option.undefined_to_opt(error.threads.find((function (thread) {
                  return thread.faulted;
                }))), Belt_Array.getExn(error.threads, 0));
  var frame = getSelectedFrame(thread);
  var match$2 = Belt_Option.getWithDefault(Belt_Option.flatMap(initialSelection, (function (param) {
              var variableIndex = param.variableIndex;
              var frameIndex = param.frameIndex;
              try {
                var thread = Belt_Array.getExn(error.threads, param.threadIndex);
                var frame = frameIndex !== undefined ? Belt_Array.getExn(thread.user, frameIndex) : getSelectedFrame(thread);
                var variable = variableIndex !== undefined ? Belt_Option.map(frame.variables, (function (vars) {
                          return Belt_Array.getExn(vars, variableIndex);
                        })) : getSelectedVariable(frame);
                return /* tuple */[
                        thread,
                        frame,
                        variable
                      ];
              }
              catch (exn){
                return ;
              }
            })), /* tuple */[
        thread,
        frame,
        getSelectedVariable(frame)
      ]);
  var match$3 = React.useReducer(Curry.__2(reducer$1), {
        selectedThread: match$2[0],
        selectedFrame: match$2[1],
        selectedVariable: match$2[2],
        selectedPane: /* Callstack */1,
        expandedThreads: undefined,
        defectVariablesView: /* Variables */0
      });
  var dispatch = match$3[1];
  var match$4 = match$3[0];
  var defectVariablesView = match$4.defectVariablesView;
  var expandedThreads = match$4.expandedThreads;
  var selectedPane = match$4.selectedPane;
  var selectedVariable = match$4.selectedVariable;
  var selectedFrame = match$4.selectedFrame;
  var selectedThread = match$4.selectedThread;
  React.useEffect((function () {
          var threadIndex = error.threads.findIndex((function (t) {
                  return t === selectedThread;
                }));
          var frameIndex = selectedThread.user.findIndex((function (f) {
                  return f === selectedFrame;
                }));
          var match = selectedFrame.variables;
          var variableIndex = selectedVariable !== undefined && match !== undefined ? match.findIndex((function (v) {
                    return v === selectedVariable;
                  })) : undefined;
          Curry._1(handleReplaceUrl, /* ProjectDebugger2 */Block.__(19, [
                  projectName,
                  {
                    aperture: aperture,
                    state: {
                      errorId: error.errorId,
                      threadIndex: threadIndex,
                      frameIndex: frameIndex,
                      variableIndex: variableIndex
                    }
                  }
                ]));
          
        }), /* tuple */[
        selectedThread,
        selectedFrame,
        selectedVariable
      ]);
  var setSelectedThread = React.useCallback((function (thread) {
          return Curry._1(dispatch, /* SelectThread */Block.__(0, [thread]));
        }), ([]));
  var setSelectedFrame = React.useCallback((function (frame) {
          return Curry._1(dispatch, /* SelectFrame */Block.__(1, [frame]));
        }), ([]));
  var setSelectedVariable = React.useCallback((function (variable) {
          return Curry._1(dispatch, /* SelectVariable */Block.__(2, [variable]));
        }), ([]));
  var onToggleThread = React.useCallback((function (threadId) {
          return Curry._1(dispatch, /* ToggleThread */Block.__(6, [threadId]));
        }), ([]));
  var faultingFrame = getFaultingFrame(error.threads);
  var onSelectVariableId = function (variableId) {
    var $$location = {
      contents: undefined
    };
    Belt_Array.forEach(error.threads, (function (thread) {
            var frames = thread.user;
            return Belt_Array.forEach(frames, (function (frame) {
                          var variables = Belt_Option.getWithDefault(frame.variables, []);
                          return Belt_Array.forEach(variables, (function (variable) {
                                        if (variable.id === String(variableId)) {
                                          $$location.contents = /* tuple */[
                                            thread,
                                            frame,
                                            variable
                                          ];
                                          return ;
                                        }
                                        
                                      }));
                        }));
          }));
    var match = $$location.contents;
    if (match !== undefined) {
      return Curry._1(dispatch, /* JumpTo */Block.__(3, [
                    match[0],
                    match[1],
                    match[2]
                  ]));
    }
    
  };
  var attachmentsTask = React.useMemo((function () {
          return BugTasks$BsConsole.fetchAttachments(project.name, error.errorId);
        }), /* tuple */[
        project.name,
        error.errorId
      ]);
  var match$5 = Task2$BsConsole.useRefetchable(undefined, attachmentsTask, undefined);
  var refetchAttachments = match$5[1];
  var attachments = match$5[0];
  var services = config.services;
  var scaServiceEndpoint;
  if (services !== undefined) {
    var scaServiceConfig = Belt_List.getBy(services, (function (service) {
            return service.name === "sca";
          }));
    scaServiceEndpoint = scaServiceConfig !== undefined ? scaServiceConfig.endpoint : undefined;
  } else {
    scaServiceEndpoint = undefined;
  }
  var value = Js_dict.get(error.attributes, "build");
  var build = value !== undefined ? Js_json.decodeString(Caml_option.valFromOption(value)) : undefined;
  var defectsRequest;
  if (scaServiceEndpoint !== undefined) {
    var defectsTask = React.useMemo((function () {
            return BugTasks$BsConsole.fetchDefects(scaServiceEndpoint, universeName, projectName, build, undefined, undefined, error.fingerprint);
          }), /* tuple */[
          universeName,
          projectName,
          error.fingerprint,
          build
        ]);
    defectsRequest = Task2$BsConsole.use(undefined, defectsTask, undefined);
  } else {
    defectsRequest = /* NotAsked */0;
  }
  var fingerprintDefects;
  if (typeof defectsRequest === "number" || defectsRequest.tag) {
    fingerprintDefects = undefined;
  } else {
    var results = defectsRequest[0][0];
    fingerprintDefects = Belt_List.length(results) === 0 ? undefined : results;
  }
  var maybeErrorMessage = Belt_Option.flatMap(BugCrdbAttributes$BsConsole.getAttribute(crdbRow, "error.message"), (function (m) {
          return Json_decode.optional(Json_decode.string, m);
        }));
  var bugBttCallstackEl = function (width, height) {
    return React.createElement(BugBttCallstack$BsConsole.make, {
                callstack: selectedThread.user,
                kernelStack: selectedThread.kernel,
                width: width,
                height: height,
                selectedFrame: selectedFrame,
                setSelectedFrame: (function (frame, showVariableDetails) {
                    Curry._1(setSelectedFrame, frame);
                    if (showVariableDetails) {
                      return Curry._1(dispatch, /* SetDefectVariablesView */Block.__(7, [/* Variables */0]));
                    }
                    
                  }),
                paneFocused: selectedPane === /* Callstack */1,
                onClick: (function (param) {
                    return Curry._1(dispatch, /* SelectPane */Block.__(4, [/* Callstack */1]));
                  }),
                getDefectsByFrame: (function (param) {
                    return getDefectsByFrame(fingerprintDefects, param);
                  }),
                isLocalVarsVisible: toggles.showVariables,
                toggleIsLocalVarsVisbile: toggleIsLocalVarsVisbile,
                processFrames: toggles.condenseFunctions,
                toggleProcessFrames: toggleCondenseFunctions
              });
  };
  var bugBttThreadSelectEl = function (width, height) {
    return React.createElement(BugBttThreadSelect$BsConsole.make, {
                expandedThreads: expandedThreads,
                onToggleThread: onToggleThread,
                threads: error.threads,
                threadGroups: error.threadGroups,
                selectedThread: selectedThread,
                setSelectedThread: setSelectedThread,
                width: width,
                height: height,
                paneFocused: selectedPane === /* Threads */0,
                onClick: (function (param) {
                    return Curry._1(dispatch, /* SelectPane */Block.__(4, [/* Threads */0]));
                  })
              });
  };
  React.useEffect((function () {
          MetricsEvent$BsConsole.send(/* ActionWithAttrs */Block.__(1, [
                  /* Debugger_Load */107,
                  Json_encode.object_(Belt_List.keepMap(/* :: */[
                            /* tuple */[
                              "backtrace.agent",
                              error.backtraceAgent
                            ],
                            /* :: */[
                              /* tuple */[
                                "backtrace.version",
                                error.backtraceVersion
                              ],
                              /* :: */[
                                /* tuple */[
                                  "bugFormat",
                                  error.bugFormat
                                ],
                                /* :: */[
                                  /* tuple */[
                                    "debuggerView",
                                    "BugBTT"
                                  ],
                                  /* [] */0
                                ]
                              ]
                            ]
                          ], (function (param) {
                              var k = param[0];
                              return Belt_Option.map(param[1], (function (v) {
                                            return /* tuple */[
                                                    k,
                                                    v
                                                  ];
                                          }));
                            })))
                ]));
          
        }), [error.errorId]);
  return React.createElement(Row2$BsConsole.make, {
              alignItems: /* stretch */-162316795,
              className: Css.style(/* :: */[
                    Css.width(Css.pct(100)),
                    /* [] */0
                  ]),
              children: React.createElement(Col2$BsConsole.make, {
                    flexGrow: 1,
                    alignItems: /* stretch */-162316795,
                    className: Css.style(/* :: */[
                          Css.width(Css.pct(100)),
                          /* [] */0
                        ]),
                    children: null
                  }, React.createElement(BugHeader$BsConsole.make, {
                        config: config,
                        onAddFilter: onAddFilter,
                        errorId: error.errorId,
                        fingerprint: error.fingerprint,
                        classifiers: error.classifiers,
                        time: error.time,
                        recieved: BugCrdbAttributes$BsConsole.getAttribute(crdbRow, "timestamp.received"),
                        compressed: BugCrdbAttributes$BsConsole.getAttribute(crdbRow, "_compressed"),
                        variableAnnotations: error.variableAnnotations,
                        project: project,
                        onJumpToFaultingFrame: (function (param) {
                            if (faultingFrame !== undefined) {
                              return Curry._1(dispatch, /* JumpTo */Block.__(3, [
                                            faultingFrame[0],
                                            faultingFrame[1],
                                            undefined
                                          ]));
                            }
                            
                          }),
                        faulting: faultingFrame !== undefined ? (
                            faultingFrame[1] !== undefined ? /* Frame */1 : /* Thread */0
                          ) : undefined,
                        onSelectVariableId: onSelectVariableId,
                        attachments: attachments,
                        universeName: universeName,
                        onAttachmentUploaded: (function (param) {
                            return Curry._1(refetchAttachments, undefined);
                          }),
                        maybeErrorMessage: maybeErrorMessage,
                        projectName: projectName,
                        attributes: BugCrdbAttributes$BsConsole.mergeAttributes(error.attributes, crdbRow),
                        handleChangeUrl: handleChangeUrl,
                        token: token
                      }), React.createElement(BugGrid$BsConsole.make, {
                        threadCount: error.threads.length,
                        width: w,
                        height: (h - 42 | 0) + (
                          Belt_Option.isSome(maybeErrorMessage) ? 42 : 0
                        ) | 0,
                        layout: toggles.showVariables ? /* Btt */Block.__(0, [{
                                threadSelect: bugBttThreadSelectEl,
                                callstack: bugBttCallstackEl,
                                variables: (function (_width, h) {
                                    var variablesView = React.createElement(BugVariables$BsConsole.make, {
                                          annotationsDict: error.annotationsDict,
                                          tls: selectedThread.tls,
                                          variables: selectedFrame.variables,
                                          selectedVariable: selectedVariable,
                                          setSelectedVariable: setSelectedVariable,
                                          paneFocused: selectedPane === /* Variables */2,
                                          onClick: (function (prim) {
                                              
                                            }),
                                          isLocalVarsVisible: toggles.showVariables,
                                          toggleIsLocalVarsVisbile: toggleIsLocalVarsVisbile
                                        });
                                    var defectsForFrame = getDefectsByFrame(fingerprintDefects, selectedFrame);
                                    if (defectsForFrame !== undefined) {
                                      return React.createElement(BugDefectVariablesToggle$BsConsole.make, {
                                                  config: config,
                                                  setView: (function (view) {
                                                      return Curry._1(dispatch, /* SetDefectVariablesView */Block.__(7, [view]));
                                                    }),
                                                  variablesComponent: variablesView,
                                                  defects: defectsForFrame,
                                                  selection: defectVariablesView,
                                                  height: h - 32 | 0
                                                });
                                    } else {
                                      return variablesView;
                                    }
                                  }),
                                variableDetails: (function (param, param$1) {
                                    return React.createElement(BugVariableDetails$BsConsole.make, {
                                                variable: selectedVariable,
                                                variableAnnotations: Belt_Option.flatMap(selectedVariable, (function (selectedVariable) {
                                                        return Js_dict.get(error.annotationsDict, selectedVariable.id);
                                                      }))
                                              });
                                  }),
                                registers: (function (width) {
                                    return React.createElement(BugRegisters$BsConsole.make, {
                                                width: width,
                                                registers: selectedFrame.registers
                                              });
                                  }),
                                showVariableDetails: defectVariablesView === /* Variables */0
                              }]) : /* Generic111 */Block.__(1, [{
                                col1: bugBttThreadSelectEl,
                                col2: bugBttCallstackEl,
                                col3: (function (w, h) {
                                    return React.createElement(BugRegistersSimple$BsConsole.make, {
                                                width: w,
                                                height: h,
                                                registers: selectedFrame.registers
                                              });
                                  })
                              }]),
                        tabs: (function (width, height) {
                            var match = Services$BsConsole.getEndpoint(undefined)(config.services, "sourcecode");
                            var tmp;
                            if (hasRepositories) {
                              if (match !== undefined) {
                                var fileName = selectedFrame.path;
                                var directory = selectedFrame.directory;
                                var funcName = selectedFrame.symbol;
                                var objName = selectedFrame.basename;
                                var faultingLine = Belt_Option.map(selectedFrame.line, Caml_format.caml_int_of_string);
                                tmp = Caml_option.some(React.createElement(BugFetchedSource$BsConsole.make, {
                                          token: token,
                                          projectName: projectName,
                                          universeName: universeName,
                                          attributes: error.attributes,
                                          endpoint: match,
                                          directory: directory,
                                          fileName: fileName,
                                          funcName: funcName,
                                          objName: objName,
                                          faultingLine: faultingLine
                                        }));
                              } else {
                                tmp = undefined;
                              }
                            } else {
                              tmp = match !== undefined ? Caml_option.some(React.createElement(Col2$BsConsole.make, {
                                          alignItems: /* center */98248149,
                                          justifyContent: /* center */98248149,
                                          className: Css.style(/* :: */[
                                                Css.width(Css.pct(100)),
                                                /* :: */[
                                                  Css.height(Css.pct(100)),
                                                  /* :: */[
                                                    Css.padding2(Css.px(10), Css.px(10)),
                                                    /* :: */[
                                                      Css.fontSize(Css.rem(1.5)),
                                                      /* [] */0
                                                    ]
                                                  ]
                                                ]
                                              ]),
                                          children: null
                                        }, I18N$BsConsole.show(undefined, "Add source repositories to enable this feature."), React.createElement(Button.default, {
                                              variant: "contained",
                                              color: "primary",
                                              classes: {
                                                root: Css.style(/* :: */[
                                                      Css.unsafe("boxShadow", "none"),
                                                      /* :: */[
                                                        Css.marginTop(Css.rem(1)),
                                                        /* [] */0
                                                      ]
                                                    ])
                                              },
                                              onClick: (function (_event) {
                                                  
                                                }),
                                              children: React.createElement(Link$BsConsole.Jsx3.make, {
                                                    route: /* ProjectSettingsSourceCode */Block.__(47, [projectName]),
                                                    handleChangeUrl: handleChangeUrl,
                                                    children: I18N$BsConsole.show(undefined, "Add repositories")
                                                  })
                                            }))) : undefined;
                            }
                            var tmp$1 = {
                              token: token,
                              onAddFilter: onAddFilter,
                              attributes: BugCrdbAttributes$BsConsole.mergeAttributes(error.attributes, crdbRow),
                              globalAnnotations: error.globalAnnotations,
                              memoryMap: error.memoryMap,
                              width: width,
                              height: height,
                              projectName: projectName,
                              attachments: attachments,
                              errorId: error.errorId,
                              isRaw: toggles.showBugTabsDataRaw,
                              toggleIsRaw: toggleShowBugTabsDataRaw
                            };
                            if (error.system !== undefined) {
                              tmp$1.system = Caml_option.valFromOption(error.system);
                            }
                            if (error.globalVariables !== undefined) {
                              tmp$1.globalVariables = Caml_option.valFromOption(error.globalVariables);
                            }
                            var tmp$2 = Belt_Option.map(selectedVariable, (function (v) {
                                    return v.address;
                                  }));
                            if (tmp$2 !== undefined) {
                              tmp$1.selectedMemoryAddress = Caml_option.valFromOption(tmp$2);
                            }
                            if (tmp !== undefined) {
                              tmp$1.bugSourceEl = Caml_option.valFromOption(tmp);
                            }
                            return React.createElement(BugTabs$BsConsole.make, tmp$1);
                          })
                      }))
            });
}

var make = BugBtt;

exports.getFaultingFrame = getFaultingFrame;
exports.getDefectsByFrame = getDefectsByFrame;
exports.getSelectedFrame = getSelectedFrame;
exports.getSelectedVariable = getSelectedVariable;
exports.reducer = reducer;
exports.make = make;
/* Css Not a pure module */
