// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var I18N$BsConsole = require("../I18N.js");
var Colors$BsConsole = require("../Colors.js");
var FilterBackdrop$BsConsole = require("./FilterBackdrop.js");

function FilterContainer(Props) {
  var isFilterOpen = Props.isFilterOpen;
  var openFilter = Props.openFilter;
  var closeFilter = Props.closeFilter;
  Props.queryBuilderOpen;
  var filterContainerRef = Props.filterContainerRef;
  var onContainerFocusedStyle = Props.onContainerFocusedStyle;
  var filterBarClassName = Props.filterBarClassName;
  var autoFocus = Props.autoFocus;
  var children = Props.children;
  var filterBar = Css.style(/* :: */[
        Css.backgroundColor(Css.hex(Colors$BsConsole.white)),
        /* :: */[
          Css.borderRadius(Css.px(3)),
          /* :: */[
            Css.display(/* flex */-1010954439),
            /* :: */[
              Css.alignItems(/* center */98248149),
              /* :: */[
                Css.justifyContent(/* spaceBetween */516682146),
                /* :: */[
                  Css.flexWrap(/* nowrap */867913355),
                  /* :: */[
                    Css.height(Css.px(42)),
                    /* :: */[
                      Css.minHeight(Css.px(42)),
                      /* :: */[
                        Css.width(Css.pct(100)),
                        /* :: */[
                          Css.position(/* relative */903134412),
                          /* :: */[
                            Css.cursor(/* text */-856044371),
                            /* :: */[
                              Css.fontSize(Css.px(14)),
                              /* [] */0
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]
                ]
              ]
            ]
          ]
        ]
      ]);
  var handleOnClick = React.useCallback((function (_event) {
          if (isFilterOpen) {
            return Curry._1(autoFocus, filterContainerRef.current);
          } else {
            return Curry._1(openFilter, undefined);
          }
        }), /* tuple */[
        isFilterOpen,
        filterContainerRef
      ]);
  var handleKeyDownEscape = function ($$event) {
    var keyName = $$event.key;
    if (isFilterOpen && keyName === "Escape") {
      return Curry._1(closeFilter, undefined);
    }
    
  };
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  ref: (function (el) {
                      filterContainerRef.current = (el == null) ? undefined : Caml_option.some(el);
                      
                    }),
                  className: Css.merge(/* :: */[
                        "walkthrough--project_toolbar--filter_typeahead",
                        /* :: */[
                          "filter-input-bar",
                          /* :: */[
                            filterBar,
                            /* :: */[
                              onContainerFocusedStyle,
                              /* :: */[
                                filterBarClassName,
                                /* [] */0
                              ]
                            ]
                          ]
                        ]
                      ]),
                  title: isFilterOpen ? "" : I18N$BsConsole.get(undefined, "Add filter"),
                  onKeyDown: handleKeyDownEscape,
                  onClick: handleOnClick
                }, children), React.createElement(FilterBackdrop$BsConsole.make, {
                  isFilterOpen: isFilterOpen,
                  closeFilter: closeFilter
                }));
}

var make = FilterContainer;

exports.make = make;
/* Css Not a pure module */
