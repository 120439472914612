// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var I18N$BsConsole = require("./I18N.js");
var Link$BsConsole = require("./Link.js");
var Image$BsConsole = require("./components/Image.js");
var Route$BsConsole = require("./route.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");

function link(tenancy, screenWidth) {
  var match = screenWidth < 1280;
  return Css.merge(/* :: */[
              Css.style(/* :: */[
                    Css.cursor(/* pointer */-786317123),
                    /* :: */[
                      Css.margin2(Css.zero, Css.rem(1)),
                      /* :: */[
                        Css.display(/* inlineFlex */53323314),
                        /* :: */[
                          Css.alignItems(/* center */98248149),
                          /* :: */[
                            Css.maxWidth(Css.px(200)),
                            /* :: */[
                              Css.unsafe("transition", "max-width 0.5s ease-in-out"),
                              /* :: */[
                                Css.overflowX(/* hidden */-862584982),
                                /* [] */0
                              ]
                            ]
                          ]
                        ]
                      ]
                    ]
                  ]),
              /* :: */[
                tenancy || match ? Css.style(/* :: */[
                        Css.maxWidth(Css.px(43)),
                        /* [] */0
                      ]) : "",
                /* [] */0
              ]
            ]);
}

function getScreenWidth(param) {
  return window.innerWidth;
}

function AppBarLogo(Props) {
  var shell = Props.shell;
  var projectName = Props.projectName;
  var route = Props.route;
  var handleChangeUrl = Props.handleChangeUrl;
  var match = React.useState((function () {
          return window.innerWidth;
        }));
  var setScreenWidth = match[1];
  var handleResize = function (param) {
    var newScreenWidth = window.innerWidth;
    return Curry._1(setScreenWidth, (function (param) {
                  return newScreenWidth;
                }));
  };
  React.useEffect((function () {
          window.addEventListener("resize", handleResize);
          return (function (param) {
                    window.removeEventListener("resize", handleResize);
                    
                  });
        }), ([]));
  if (typeof shell === "number" || shell.tag) {
    return null;
  } else {
    return React.createElement(Link$BsConsole.Jsx3.make, {
                route: /* ProjectTriage */Block.__(11, [
                    projectName,
                    Route$BsConsole.getInboxParams(projectName, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined),
                    undefined
                  ]),
                handleChangeUrl: handleChangeUrl,
                className: link(shell[0].tenancy, match[0]),
                children: React.createElement(Image$BsConsole.make, {
                      src: "https://res.cloudinary.com/backtrace/image/upload/v1678128739/saucelabs_backtrace_logo_nav_1.svg",
                      alt: I18N$BsConsole.get(undefined, "Backtrace logo - click to link to default view"),
                      onClick: (function (_event) {
                          return Curry._1(handleChangeUrl, route);
                        })
                    })
              });
  }
}

var component = ReasonReact.statelessComponent("AppBarLogo");

function make(shell, projectName, route, handleChangeUrl) {
  var partial_arg = {
    shell: shell,
    projectName: projectName,
    route: route,
    handleChangeUrl: handleChangeUrl
  };
  return (function (param) {
      return ReasonReactCompat.wrapReactForReasonReact(AppBarLogo, partial_arg, param);
    });
}

var Jsx2 = {
  component: component,
  make: make
};

var make$1 = AppBarLogo;

exports.link = link;
exports.getScreenWidth = getScreenWidth;
exports.make = make$1;
exports.Jsx2 = Jsx2;
/* component Not a pure module */
