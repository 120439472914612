// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Crdb$BsConsole = require("../crdb.js");
var I18N$BsConsole = require("../I18N.js");
var Link$BsConsole = require("../Link.js");
var BugUtils$BsConsole = require("../debugger2/data/BugUtils.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var GetNextRoute$BsConsole = require("../GetNextRoute.js");
var BreadcrumbUtils$BsConsole = require("./BreadcrumbUtils.js");
var BreadcrumbStyles$BsConsole = require("./BreadcrumbStyles.js");
var BreadcrumbDivider$BsConsole = require("./BreadcrumbDivider.js");

function containsFingerprintEqualsFilter(ap) {
  return Belt_Array.some(Curry._1(Crdb$BsConsole.Filters.toArray, Curry._1(Crdb$BsConsole.Aperture.getFilters, ap)), (function (filter) {
                if (Curry._1(Crdb$BsConsole.Filter.getAttribute, filter) === "fingerprint") {
                  return Crdb$BsConsole.FilterOperation.toString(Curry._1(Crdb$BsConsole.Filter.getOperation, filter)) === "equal";
                } else {
                  return false;
                }
              }));
}

function Level2Breadcrumb$Crumb(Props) {
  var projectName = Props.projectName;
  var maybeFingerprint = Props.maybeFingerprint;
  var aperture = Props.aperture;
  var parentRoute = Props.parentRoute;
  var route = Props.route;
  var maybeCurrentView = Props.maybeCurrentView;
  var handleChangeUrl = Props.handleChangeUrl;
  var level1RouteType = parentRoute.routeName;
  var currentRouteType = BreadcrumbUtils$BsConsole.currentRouteToType(route, maybeCurrentView);
  var currentRouteName = BreadcrumbUtils$BsConsole.currentRouteToString(route, maybeCurrentView);
  switch (currentRouteType) {
    case /* Releases */0 :
        return React.createElement(React.Fragment, undefined, React.createElement(BreadcrumbDivider$BsConsole.make, { }), React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Body1 */8,
                        className: BreadcrumbStyles$BsConsole.crumb,
                        children: I18N$BsConsole.show(undefined, currentRouteName)
                      }));
    case /* TriageDetails */1 :
        if (maybeFingerprint === undefined) {
          return null;
        }
        var fingerprintShort = BugUtils$BsConsole.formatFingerprint(maybeFingerprint);
        return React.createElement(React.Fragment, undefined, React.createElement(BreadcrumbDivider$BsConsole.make, { }), React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Body1 */8,
                        className: BreadcrumbStyles$BsConsole.crumb,
                        children: Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                  /* String_literal */Block.__(11, [
                                      "Error Group Details (",
                                      /* String */Block.__(2, [
                                          /* No_padding */0,
                                          /* Char_literal */Block.__(12, [
                                              /* ")" */41,
                                              /* End_of_format */0
                                            ])
                                        ])
                                    ]),
                                  "Error Group Details (%s)"
                                ]), fingerprintShort)
                      }));
    case /* Debugger */2 :
        if (level1RouteType !== 0) {
          if (level1RouteType >= 3) {
            return null;
          } else {
            return React.createElement(React.Fragment, undefined, React.createElement(BreadcrumbDivider$BsConsole.make, { }), React.createElement(Link$BsConsole.Jsx3.make, {
                            route: GetNextRoute$BsConsole.instances(projectName, aperture, true),
                            handleChangeUrl: handleChangeUrl,
                            className: BreadcrumbStyles$BsConsole.link,
                            children: React.createElement(BtTypography$BsConsole.make, {
                                  variant: /* Body1 */8,
                                  className: BreadcrumbStyles$BsConsole.crumbWithLink,
                                  children: I18N$BsConsole.show(undefined, "Error Group Instances")
                                })
                          }));
          }
        }
        if (maybeFingerprint === undefined) {
          return null;
        }
        if (!containsFingerprintEqualsFilter(aperture)) {
          return null;
        }
        var fingerprintShort$1 = BugUtils$BsConsole.formatFingerprint(maybeFingerprint);
        return React.createElement(React.Fragment, undefined, React.createElement(BreadcrumbDivider$BsConsole.make, { }), React.createElement(Link$BsConsole.Jsx3.make, {
                        route: GetNextRoute$BsConsole.triageDetails(projectName, maybeFingerprint, aperture),
                        handleChangeUrl: handleChangeUrl,
                        className: BreadcrumbStyles$BsConsole.link,
                        children: React.createElement(BtTypography$BsConsole.make, {
                              variant: /* Body1 */8,
                              className: BreadcrumbStyles$BsConsole.crumbWithLink,
                              children: Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                        /* String_literal */Block.__(11, [
                                            "Error Group Details (",
                                            /* String */Block.__(2, [
                                                /* No_padding */0,
                                                /* Char_literal */Block.__(12, [
                                                    /* ")" */41,
                                                    /* End_of_format */0
                                                  ])
                                              ])
                                          ]),
                                        "Error Group Details (%s)"
                                      ]), fingerprintShort$1)
                            })
                      }));
    case /* Other */3 :
        return null;
    
  }
}

var Crumb = {
  make: Level2Breadcrumb$Crumb
};

exports.containsFingerprintEqualsFilter = containsFingerprintEqualsFilter;
exports.Crumb = Crumb;
/* react Not a pure module */
