// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var IFrame$BsConsole = require("./IFrame.js");
var IdentityVariant$BsConsole = require("./IdentityVariant.js");

var unityPaperRaw = Css.style(/* :: */[
      Css.unsafe("boxShadow", "0px 1px 3px 0px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 2px 1px -1px rgb(0 0 0 / 12%)"),
      /* :: */[
        Css.borderRadius(Css.px(4)),
        /* [] */0
      ]
    ]);

var elevation = IFrame$BsConsole.isIFrame && IdentityVariant$BsConsole.current >= 2 ? 1 : 2;

var mainViewMargins = IFrame$BsConsole.isIFrame ? (
    IdentityVariant$BsConsole.current >= 2 ? Css.style(/* :: */[
            Css.margin4(Css.rem(1), Css.px(1), Css.px(1), Css.px(1)),
            /* [] */0
          ]) : Css.style(/* :: */[
            Css.margin(Css.rem(1)),
            /* [] */0
          ])
  ) : Css.style(/* :: */[
        Css.margin(Css.rem(1)),
        /* [] */0
      ]);

var mainViewMarginsWithBreadcrumb;

var exit = 0;

if (IFrame$BsConsole.isIFrame && IdentityVariant$BsConsole.current >= 2) {
  mainViewMarginsWithBreadcrumb = Css.style(/* :: */[
        Css.margin4(Css.rem(1), Css.px(1), Css.px(1), Css.px(1)),
        /* [] */0
      ]);
} else {
  exit = 1;
}

if (exit === 1) {
  mainViewMarginsWithBreadcrumb = Css.style(/* :: */[
        Css.margin4(Css.rem(0.5), Css.rem(1), Css.rem(1), Css.rem(1)),
        /* [] */0
      ]);
}

var defaultElevation = 2;

var unityElevation = 1;

exports.defaultElevation = defaultElevation;
exports.unityElevation = unityElevation;
exports.unityPaperRaw = unityPaperRaw;
exports.elevation = elevation;
exports.mainViewMargins = mainViewMargins;
exports.mainViewMarginsWithBreadcrumb = mainViewMarginsWithBreadcrumb;
/* unityPaperRaw Not a pure module */
