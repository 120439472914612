// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var Col2$BsConsole = require("../components/Col2.js");
var I18N$BsConsole = require("../I18N.js");
var Row2$BsConsole = require("../components/Row2.js");
var Colors$BsConsole = require("../Colors.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var MuiIcons$BsConsole = require("../MuiIcons.js");
var Backtrace$BsConsole = require("../bindings/Backtrace.js");
var TeamsUtils$BsConsole = require("../teams/TeamsUtils.js");
var Menu = require("@material-ui/core/Menu");
var BtTypography$BsConsole = require("../BtTypography.js");
var MetricsEvent$BsConsole = require("../bs-metrics/MetricsEvent.js");
var Edit = require("@material-ui/icons/Edit");
var List = require("@material-ui/icons/List");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");
var Tooltip = require("@material-ui/core/Tooltip");
var Delete = require("@material-ui/icons/Delete");
var Update = require("@material-ui/icons/Update");
var MenuItem = require("@material-ui/core/MenuItem");
var SavedQueriesModel$BsConsole = require("./SavedQueriesModel.js");
var MoreHoriz = require("@material-ui/icons/MoreHoriz");
var UserFrontendSettings$BsConsole = require("../UserFrontendSettings.js");
var ProjectFrontendSettings$BsConsole = require("../ProjectFrontendSettings.js");

var listItem = Css.style(/* :: */[
      Css.border(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey675)),
      /* :: */[
        Css.borderRadius(Css.px(4)),
        /* :: */[
          Css.marginLeft(Css.px(16)),
          /* :: */[
            Css.marginRight(Css.px(16)),
            /* :: */[
              Css.marginBottom(Css.px(8)),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var chip = Css.style(/* :: */[
      Css.padding2(Css.px(2), Css.px(8)),
      /* :: */[
        Css.borderRadius(Css.px(20)),
        /* :: */[
          Css.fontSize(Css.px(14)),
          /* :: */[
            Css.fontWeight(/* `num */[
                  5496390,
                  400
                ]),
            /* :: */[
              Css.color(Css.hex(Colors$BsConsole.grey3)),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var modeChip = Css.merge(/* :: */[
      chip,
      /* :: */[
        Css.style(/* :: */[
              Css.backgroundColor(Css.hex(Colors$BsConsole.grey675)),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var projectDefaultChip = Css.merge(/* :: */[
      chip,
      /* :: */[
        Css.style(/* :: */[
              Css.backgroundColor(Css.hex(Colors$BsConsole.addAlpha(Colors$BsConsole.orange, 0.5))),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var myProjectDefaultChip = Css.merge(/* :: */[
      chip,
      /* :: */[
        Css.style(/* :: */[
              Css.backgroundColor(Css.hex(Colors$BsConsole.addAlpha(Colors$BsConsole.green, 0.3))),
              /* [] */0
            ]),
        /* [] */0
      ]
    ]);

var author = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.grey3)),
      /* [] */0
    ]);

var iconContainer = Css.merge(/* :: */[
      Css.style(/* :: */[
            Css.marginRight(Css.px(16)),
            /* [] */0
          ]),
      /* :: */[
        "icon-container",
        /* [] */0
      ]
    ]);

var menuItem = Css.style(/* :: */[
      Css.selector(".icon-container", /* :: */[
            Css.color(Css.hex(Colors$BsConsole.grey4)),
            /* [] */0
          ]),
      /* [] */0
    ]);

var updateMenuItem = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.red)),
      /* :: */[
        Css.borderTop(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey675)),
        /* [] */0
      ]
    ]);

var deleteMenuItem = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.red)),
      /* [] */0
    ]);

var pin = Css.style(/* :: */[
      Css.marginLeft(Css.rem(1)),
      /* :: */[
        Css.color(Css.hex(Colors$BsConsole.grey5)),
        /* [] */0
      ]
    ]);

var pinned = Css.style(/* :: */[
      Css.marginLeft(Css.rem(1)),
      /* :: */[
        Css.color(Css.hex(Colors$BsConsole.orange)),
        /* [] */0
      ]
    ]);

var projectBookmark = Css.style(/* :: */[
      Css.marginLeft(Css.rem(1)),
      /* :: */[
        Css.color(Css.hex(Colors$BsConsole.grey5)),
        /* [] */0
      ]
    ]);

var projectBookmarked = Css.style(/* :: */[
      Css.marginLeft(Css.rem(1)),
      /* :: */[
        Css.color(Css.hex(Colors$BsConsole.greenLight)),
        /* [] */0
      ]
    ]);

var itemName = Css.style(/* :: */[
      Css.whiteSpace(/* nowrap */867913355),
      /* :: */[
        Css.overflow(/* hidden */-862584982),
        /* :: */[
          Css.textOverflow(/* ellipsis */166888785),
          /* :: */[
            Css.maxWidth(Css.px(278)),
            /* [] */0
          ]
        ]
      ]
    ]);

var enabled = Css.style(/* :: */[
      Css.cursor(/* pointer */-786317123),
      /* [] */0
    ]);

var disabled = Css.style(/* :: */[
      Css.cursor(/* notAllowed */939907157),
      /* [] */0
    ]);

var Style = {
  listItem: listItem,
  chip: chip,
  modeChip: modeChip,
  projectDefaultChip: projectDefaultChip,
  myProjectDefaultChip: myProjectDefaultChip,
  author: author,
  iconContainer: iconContainer,
  menuItem: menuItem,
  updateMenuItem: updateMenuItem,
  deleteMenuItem: deleteMenuItem,
  pin: pin,
  pinned: pinned,
  projectBookmark: projectBookmark,
  projectBookmarked: projectBookmarked,
  itemName: itemName,
  enabled: enabled,
  disabled: disabled
};

function SavedQueryItem$MenuItem(Props) {
  var icon = Props.icon;
  var label = Props.label;
  var onClick = Props.onClick;
  var classNameOpt = Props.className;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  return React.createElement(MenuItem.default, {
              disableRipple: true,
              onClick: onClick,
              className: className,
              children: React.createElement(Row2$BsConsole.make, {
                    alignItems: /* center */98248149,
                    className: "row",
                    children: null
                  }, React.createElement(Col2$BsConsole.make, {
                        justifyContent: /* center */98248149,
                        className: iconContainer,
                        children: icon
                      }), React.createElement(BtTypography$BsConsole.make, {
                        variant: /* Body1 */8,
                        children: I18N$BsConsole.showSkip(label)
                      }))
            });
}

var MenuItem$1 = {
  make: SavedQueryItem$MenuItem
};

function SavedQueryItem(Props) {
  var savedQuery = Props.savedQuery;
  var isUserDefaultOpt = Props.isUserDefault;
  var isProjectDefaultOpt = Props.isProjectDefault;
  var isProjectBookmarkedOpt = Props.isProjectBookmarked;
  var isUserBookmarkedOpt = Props.isUserBookmarked;
  var classNameOpt = Props.className;
  var handleChangeUrl = Props.handleChangeUrl;
  var projectName = Props.projectName;
  var handleCloseDrawer = Props.handleCloseDrawer;
  var handleOnDetailsClicked = Props.handleOnDetailsClicked;
  var handleOnDeleteClicked = Props.handleOnDeleteClicked;
  var handleOnReplaceClicked = Props.handleOnReplaceClicked;
  Props.refetchQueries;
  var refetchProjectSettings = Props.refetchProjectSettings;
  var refetchUserSettings = Props.refetchUserSettings;
  var config = Props.config;
  var token = Props.token;
  var isUserDefault = isUserDefaultOpt !== undefined ? isUserDefaultOpt : false;
  var isProjectDefault = isProjectDefaultOpt !== undefined ? isProjectDefaultOpt : false;
  var isProjectBookmarked = isProjectBookmarkedOpt !== undefined ? isProjectBookmarkedOpt : false;
  var isUserBookmarked = isUserBookmarkedOpt !== undefined ? isUserBookmarkedOpt : false;
  var className = classNameOpt !== undefined ? classNameOpt : "";
  var match = React.useState((function () {
          
        }));
  var setAnchorEl = match[1];
  var anchorEl = match[0];
  var currentProject = React.useMemo((function () {
          return Belt_List.getBy(config.projects, (function (project) {
                        return project.name === projectName;
                      }));
        }), /* tuple */[
        config,
        projectName
      ]);
  var isAdmin = React.useMemo((function () {
          var match = config.user.role;
          var match$1 = config.user.superuser;
          if (match === "admin") {
            return true;
          } else {
            return match$1 === 1;
          }
        }));
  var handleAddProjectBookmark = function (_event) {
    if (currentProject !== undefined) {
      return ProjectFrontendSettings$BsConsole.reduce(token, currentProject.pid, /* :: */[
                  /* AddBookmarkedView */Block.__(1, [SavedQueriesModel$BsConsole.getName(savedQuery)]),
                  /* [] */0
                ], (function (param) {
                    MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* SavedQueries_AddProjectBookmark */15]));
                    return Curry._1(refetchProjectSettings, undefined);
                  }));
    }
    
  };
  var handleAddUserBookmark = function (_event) {
    return UserFrontendSettings$BsConsole.reduce(token, config.user.uid, /* :: */[
                /* AddBookmarkedView */Block.__(1, [
                    projectName,
                    SavedQueriesModel$BsConsole.getName(savedQuery)
                  ]),
                /* [] */0
              ], TeamsUtils$BsConsole.isGuest(config), (function (param) {
                  MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* SavedQueries_AddUserBookmark */17]));
                  return Curry._1(refetchUserSettings, undefined);
                }), (function (err) {
                  var __x = Backtrace$BsConsole.Attributes.addString("src_module", "SaveQueryItem/handleAddUserBookmark", Backtrace$BsConsole.Attributes.make(undefined));
                  Backtrace$BsConsole.Client.send(__x, /* `string */[
                        288368849,
                        "Failed to bookmark view: " + err
                      ]);
                  return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Failed to bookmark view"));
                }));
  };
  var handleRemoveProjectBookmark = function (_event) {
    if (currentProject !== undefined) {
      return ProjectFrontendSettings$BsConsole.reduce(token, currentProject.pid, /* :: */[
                  /* RemoveBookmarkedView */Block.__(2, [SavedQueriesModel$BsConsole.getName(savedQuery)]),
                  /* [] */0
                ], (function (param) {
                    MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* SavedQueries_RemoveProjectBookmark */16]));
                    return Curry._1(refetchProjectSettings, undefined);
                  }));
    }
    
  };
  var handleRemoveUserBookmark = function (_event) {
    return UserFrontendSettings$BsConsole.reduce(token, config.user.uid, /* :: */[
                /* RemoveBookmarkedView */Block.__(2, [
                    projectName,
                    SavedQueriesModel$BsConsole.getName(savedQuery)
                  ]),
                /* [] */0
              ], TeamsUtils$BsConsole.isGuest(config), (function (param) {
                  MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* SavedQueries_RemoveUserBookmark */18]));
                  return Curry._1(refetchUserSettings, undefined);
                }), (function (err) {
                  var __x = Backtrace$BsConsole.Attributes.addString("src_module", "SaveQueryItem/handleRemoveUserBookmark", Backtrace$BsConsole.Attributes.make(undefined));
                  Backtrace$BsConsole.Client.send(__x, /* `string */[
                        288368849,
                        "Failed to remove bookmark view: " + err
                      ]);
                  return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Failed to remove bookmark view"));
                }));
  };
  var tmp;
  if (anchorEl !== undefined) {
    var match$1 = SavedQueriesModel$BsConsole.getUsername(savedQuery);
    tmp = React.createElement(Menu.default, {
          transitionDuration: 0,
          anchorEl: Caml_option.valFromOption(anchorEl),
          anchorOrigin: {
            horizontal: "left",
            vertical: "bottom"
          },
          open: true,
          onClose: (function (param) {
              return Curry._1(setAnchorEl, (function (param) {
                            
                          }));
            }),
          children: null
        }, React.createElement(SavedQueryItem$MenuItem, {
              icon: React.createElement(List.default, {
                    color: "inherit",
                    fontSize: "inherit"
                  }),
              label: I18N$BsConsole.get(undefined, "Apply view"),
              onClick: (function (param) {
                  MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* SavedQueries_Apply */10]));
                  Curry._1(handleCloseDrawer, undefined);
                  return Curry._1(handleChangeUrl, SavedQueriesModel$BsConsole.getRouteForSavedQuery(savedQuery, projectName));
                }),
              className: menuItem
            }), React.createElement(SavedQueryItem$MenuItem, {
              icon: React.createElement(Edit.default, {
                    color: "inherit",
                    fontSize: "inherit"
                  }),
              label: I18N$BsConsole.get(undefined, "Details"),
              onClick: (function (param) {
                  return Curry._1(handleOnDetailsClicked, savedQuery);
                }),
              className: menuItem
            }), isUserBookmarked ? React.createElement(SavedQueryItem$MenuItem, {
                icon: React.createElement(MuiIcons$BsConsole.Pin.make, {
                      color: "inherit",
                      fontSize: "inherit"
                    }),
                label: I18N$BsConsole.get(undefined, "Remove your bookmark"),
                onClick: (function (_event) {
                    handleRemoveUserBookmark(_event);
                    return Curry._1(setAnchorEl, (function (param) {
                                  
                                }));
                  }),
                className: menuItem
              }) : React.createElement(SavedQueryItem$MenuItem, {
                icon: React.createElement(MuiIcons$BsConsole.Pin.make, {
                      color: "inherit",
                      fontSize: "inherit"
                    }),
                label: I18N$BsConsole.get(undefined, "Add bookmark"),
                onClick: (function (_event) {
                    handleAddUserBookmark(_event);
                    return Curry._1(setAnchorEl, (function (param) {
                                  
                                }));
                  }),
                className: menuItem
              }), isProjectBookmarked && isAdmin ? React.createElement(SavedQueryItem$MenuItem, {
                icon: React.createElement(MuiIcons$BsConsole.StarCircle.make, {
                      color: "inherit",
                      fontSize: "inherit"
                    }),
                label: I18N$BsConsole.get(undefined, "Remove bookmark for project"),
                onClick: (function (_event) {
                    handleRemoveProjectBookmark(_event);
                    return Curry._1(setAnchorEl, (function (param) {
                                  
                                }));
                  }),
                className: menuItem
              }) : (
            isAdmin ? React.createElement(SavedQueryItem$MenuItem, {
                    icon: React.createElement(MuiIcons$BsConsole.StarCircle.make, {
                          color: "inherit",
                          fontSize: "inherit"
                        }),
                    label: I18N$BsConsole.get(undefined, "Bookmark for project"),
                    onClick: (function (_event) {
                        handleAddProjectBookmark(_event);
                        return Curry._1(setAnchorEl, (function (param) {
                                      
                                    }));
                      }),
                    className: menuItem
                  }) : null
          ), isAdmin && match$1 !== "Backtrace" ? React.createElement(React.Fragment, undefined, React.createElement(SavedQueryItem$MenuItem, {
                    icon: React.createElement(Update.default, {
                          color: "inherit",
                          fontSize: "inherit"
                        }),
                    label: I18N$BsConsole.get(undefined, "Replace with current view"),
                    onClick: (function (param) {
                        return Curry._1(handleOnReplaceClicked, savedQuery);
                      }),
                    className: updateMenuItem
                  }), React.createElement(SavedQueryItem$MenuItem, {
                    icon: React.createElement(Delete.default, {
                          color: "inherit",
                          fontSize: "inherit"
                        }),
                    label: I18N$BsConsole.get(undefined, "Delete"),
                    onClick: (function (param) {
                        return Curry._1(handleOnDeleteClicked, savedQuery);
                      }),
                    className: deleteMenuItem
                  })) : null);
  } else {
    tmp = null;
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Row2$BsConsole.make, {
                  justifyContent: /* spaceBetween */516682146,
                  className: Css.merge(/* :: */[
                        listItem,
                        /* :: */[
                          className,
                          /* [] */0
                        ]
                      ]),
                  children: null
                }, React.createElement(Col2$BsConsole.make, {
                      className: Css.style(/* :: */[
                            Css.marginLeft(Css.px(24)),
                            /* :: */[
                              Css.marginTop(Css.px(16)),
                              /* :: */[
                                Css.marginBottom(Css.px(16)),
                                /* [] */0
                              ]
                            ]
                          ]),
                      children: null
                    }, React.createElement(Row2$BsConsole.make, {
                          flexWrap: /* nowrap */867913355,
                          className: Css.style(/* :: */[
                                Css.marginBottom(Css.px(8)),
                                /* [] */0
                              ]),
                          children: null
                        }, React.createElement(BtTypography$BsConsole.make, {
                              variant: /* Subtitle1 */6,
                              className: itemName,
                              title: I18N$BsConsole.get(undefined, SavedQueriesModel$BsConsole.getName(savedQuery)),
                              children: I18N$BsConsole.showSkip(SavedQueriesModel$BsConsole.getName(savedQuery))
                            }), isUserBookmarked ? React.createElement(Tooltip.default, {
                                children: React.createElement("div", {
                                      className: Css.merge(/* :: */[
                                            pinned,
                                            /* :: */[
                                              enabled,
                                              /* [] */0
                                            ]
                                          ]),
                                      role: "button",
                                      onClick: handleRemoveUserBookmark
                                    }, React.createElement(MuiIcons$BsConsole.Pinned.make, {
                                          color: "inherit",
                                          fontSize: "inherit"
                                        })),
                                title: I18N$BsConsole.get(undefined, "Remove your bookmark")
                              }) : React.createElement(Tooltip.default, {
                                children: React.createElement("div", {
                                      className: Css.merge(/* :: */[
                                            pin,
                                            /* :: */[
                                              enabled,
                                              /* [] */0
                                            ]
                                          ]),
                                      role: "button",
                                      onClick: handleAddUserBookmark
                                    }, React.createElement(MuiIcons$BsConsole.Pin.make, {
                                          color: "inherit",
                                          fontSize: "inherit"
                                        })),
                                title: I18N$BsConsole.get(undefined, "Bookmark view")
                              }), isProjectBookmarked ? React.createElement(Tooltip.default, {
                                children: React.createElement("div", {
                                      className: Css.merge(/* :: */[
                                            projectBookmarked,
                                            /* :: */[
                                              isAdmin ? enabled : disabled,
                                              /* [] */0
                                            ]
                                          ]),
                                      role: "button",
                                      onClick: handleRemoveProjectBookmark
                                    }, React.createElement(MuiIcons$BsConsole.StarCircle.make, {
                                          color: "inherit",
                                          fontSize: "inherit"
                                        })),
                                title: isAdmin ? I18N$BsConsole.get(undefined, "Remove bookmark from view for everyone in project") : I18N$BsConsole.get(undefined, "Action only available to admins")
                              }) : React.createElement(Tooltip.default, {
                                children: React.createElement("div", {
                                      className: Css.merge(/* :: */[
                                            projectBookmark,
                                            /* :: */[
                                              isAdmin ? enabled : disabled,
                                              /* [] */0
                                            ]
                                          ]),
                                      role: "button",
                                      onClick: handleAddProjectBookmark
                                    }, React.createElement(MuiIcons$BsConsole.StarCircle.make, {
                                          color: "inherit",
                                          fontSize: "inherit"
                                        })),
                                title: isAdmin ? I18N$BsConsole.get(undefined, "Bookmark view for everyone in project") : I18N$BsConsole.get(undefined, "Action only available to admins")
                              })), React.createElement(Row2$BsConsole.make, {
                          className: Css.style(/* :: */[
                                Css.marginBottom(Css.px(8)),
                                /* [] */0
                              ]),
                          children: null
                        }, React.createElement("div", {
                              className: Css.merge(/* :: */[
                                    modeChip,
                                    /* :: */[
                                      Css.style(/* :: */[
                                            Css.marginRight(Css.px(4)),
                                            /* [] */0
                                          ]),
                                      /* [] */0
                                    ]
                                  ])
                            }, I18N$BsConsole.show(undefined, SavedQueriesModel$BsConsole.modeToFriendlyString(SavedQueriesModel$BsConsole.getMode(savedQuery)))), isUserDefault ? React.createElement("div", {
                                className: Css.merge(/* :: */[
                                      myProjectDefaultChip,
                                      /* :: */[
                                        Css.style(/* :: */[
                                              Css.marginRight(Css.px(4)),
                                              /* [] */0
                                            ]),
                                        /* [] */0
                                      ]
                                    ])
                              }, I18N$BsConsole.show(undefined, "My project default")) : null, isProjectDefault ? React.createElement("div", {
                                className: Css.merge(/* :: */[
                                      projectDefaultChip,
                                      /* [] */0
                                    ])
                              }, I18N$BsConsole.show(undefined, "Project default")) : null), React.createElement(BtTypography$BsConsole.make, {
                          variant: /* Caption */11,
                          className: author,
                          children: Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                    /* String_literal */Block.__(11, [
                                        "By ",
                                        /* String */Block.__(2, [
                                            /* No_padding */0,
                                            /* End_of_format */0
                                          ])
                                      ]),
                                    "By %s"
                                  ]), SavedQueriesModel$BsConsole.getUsername(savedQuery))
                        })), React.createElement(Mui$BsConsole.IconButton.make, {
                      ariaLabel: "Saved view settings",
                      color: "primary",
                      className: Css.style(/* :: */[
                            Css.marginRight(Css.px(12)),
                            /* :: */[
                              Css.marginTop(Css.px(4)),
                              /* [] */0
                            ]
                          ]),
                      onClick: (function ($$event) {
                          var el = $$event.currentTarget;
                          return Curry._1(setAnchorEl, (function (param) {
                                        if (el == null) {
                                          return ;
                                        } else {
                                          return Caml_option.some(el);
                                        }
                                      }));
                        }),
                      children: React.createElement(MoreHoriz.default, {
                            color: "inherit",
                            fontSize: "inherit"
                          })
                    })), tmp);
}

function make(savedQuery, isUserDefault, isProjectDefault, className, isProjectBookmarked, isUserBookmarked, handleChangeUrl, projectName, handleCloseDrawer, handleOnDetailsClicked, handleOnDeleteClicked, handleOnReplaceClicked, refetchQueries, refetchProjectSettings, refetchUserSettings, config, token, _children) {
  var tmp = {
    savedQuery: savedQuery,
    handleChangeUrl: handleChangeUrl,
    projectName: projectName,
    handleCloseDrawer: handleCloseDrawer,
    handleOnDetailsClicked: handleOnDetailsClicked,
    handleOnDeleteClicked: handleOnDeleteClicked,
    handleOnReplaceClicked: handleOnReplaceClicked,
    refetchQueries: refetchQueries,
    refetchProjectSettings: refetchProjectSettings,
    refetchUserSettings: refetchUserSettings,
    config: config,
    token: token
  };
  if (isUserDefault !== undefined) {
    tmp.isUserDefault = Caml_option.valFromOption(isUserDefault);
  }
  if (isProjectDefault !== undefined) {
    tmp.isProjectDefault = Caml_option.valFromOption(isProjectDefault);
  }
  if (isProjectBookmarked !== undefined) {
    tmp.isProjectBookmarked = Caml_option.valFromOption(isProjectBookmarked);
  }
  if (isUserBookmarked !== undefined) {
    tmp.isUserBookmarked = Caml_option.valFromOption(isUserBookmarked);
  }
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  return ReasonReactCompat.wrapReactForReasonReact(SavedQueryItem, tmp, _children);
}

var Re = {
  make: make
};

var make$1 = SavedQueryItem;

exports.Style = Style;
exports.MenuItem = MenuItem$1;
exports.make = make$1;
exports.Re = Re;
/* listItem Not a pure module */
