// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Row2$BsConsole = require("../components/Row2.js");
var BreadcrumbUtils$BsConsole = require("./BreadcrumbUtils.js");
var BreadcrumbStyles$BsConsole = require("./BreadcrumbStyles.js");
var Level1Breadcrumb$BsConsole = require("./Level1Breadcrumb.js");
var Level2Breadcrumb$BsConsole = require("./Level2Breadcrumb.js");
var Level3Breadcrumb$BsConsole = require("./Level3Breadcrumb.js");

function Breadcrumbs(Props) {
  var projectName = Props.projectName;
  var route = Props.route;
  var aperture = Props.aperture;
  var maybeCurrentView = Props.maybeCurrentView;
  var maybeFingerprint = Props.maybeFingerprint;
  var maybeErrorId = Props.maybeErrorId;
  var handleChangeUrl = Props.handleChangeUrl;
  var maybeBreadcrumb = Level1Breadcrumb$BsConsole.get(undefined);
  var parentRoute;
  if (maybeBreadcrumb !== undefined) {
    var func = function (param, param$1, param$2) {
      return Level1Breadcrumb$BsConsole.typeToRoute(projectName, param, param$1, param$2);
    };
    var func$1 = Curry._1(func, aperture);
    parentRoute = Curry._1((function (param) {
            return Curry._2(func$1, param, maybeBreadcrumb);
          }), route);
  } else {
    parentRoute = BreadcrumbUtils$BsConsole.getDefaultParentRoute(route, projectName, aperture);
  }
  return React.createElement(Row2$BsConsole.make, {
              alignItems: /* center */98248149,
              justifyContent: /* flexStart */662439529,
              className: BreadcrumbStyles$BsConsole.container,
              children: null
            }, React.createElement(Level1Breadcrumb$BsConsole.Crumb.make, {
                  parentRoute: parentRoute,
                  handleChangeUrl: handleChangeUrl
                }), React.createElement(Level2Breadcrumb$BsConsole.Crumb.make, {
                  projectName: projectName,
                  maybeFingerprint: maybeFingerprint,
                  aperture: aperture,
                  parentRoute: parentRoute,
                  route: route,
                  maybeCurrentView: maybeCurrentView,
                  handleChangeUrl: handleChangeUrl
                }), React.createElement(Level3Breadcrumb$BsConsole.Crumb.make, {
                  maybeErrorId: maybeErrorId,
                  route: route,
                  maybeCurrentView: maybeCurrentView
                }));
}

var Level1;

var Level2;

var Level3;

var make = Breadcrumbs;

exports.Level1 = Level1;
exports.Level2 = Level2;
exports.Level3 = Level3;
exports.make = make;
/* react Not a pure module */
