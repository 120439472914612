// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Href$BsConsole = require("./Href.js");
var I18N$BsConsole = require("./I18N.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var Backtrace$BsConsole = require("./bindings/Backtrace.js");
var TeamsUtils$BsConsole = require("./teams/TeamsUtils.js");
var BtTypography$BsConsole = require("./BtTypography.js");
var MetricsEvent$BsConsole = require("./bs-metrics/MetricsEvent.js");
var Button = require("@material-ui/core/Button");
var Dialog = require("@material-ui/core/Dialog");
var Launch = require("@material-ui/icons/Launch");
var DialogTitle = require("@material-ui/core/DialogTitle");
var UserFrontendSettings$BsConsole = require("./UserFrontendSettings.js");
var DialogActions = require("@material-ui/core/DialogActions");
var DialogContent = require("@material-ui/core/DialogContent");

function getHasModalAlreadyBeenSeen(config) {
  return Belt_Option.getWithDefault(Belt_Option.map(Belt_Option.flatMap(config.userSettings, (function (userSettings) {
                        return userSettings.unityCDA;
                      })), (function (unityCDA) {
                    return unityCDA.hasSeenFirstTimeModal;
                  })), false);
}

function FirstTimeUnityModal(Props) {
  var config = Props.config;
  var refetchConfig = Props.refetchConfig;
  var token = Props.token;
  var match = React.useState((function () {
          return !getHasModalAlreadyBeenSeen(config);
        }));
  var setOpen = match[1];
  var open_ = match[0];
  React.useEffect((function () {
          if (open_) {
            MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* UnityCDAFirstTimeModalSeen */117]));
            UserFrontendSettings$BsConsole.updateOrCreate(token, config.user.uid, TeamsUtils$BsConsole.isGuest(config), (function (projectSettings) {
                    var currentSettings = Belt_Option.getWithDefault(projectSettings, UserFrontendSettings$BsConsole.empty);
                    return {
                            projectDefaultViews: currentSettings.projectDefaultViews,
                            bookmarkedViews: currentSettings.bookmarkedViews,
                            reopenLatestSelectedAttribute: currentSettings.reopenLatestSelectedAttribute,
                            reopenLatestSelectedType: currentSettings.reopenLatestSelectedType,
                            reopenLatestAttributeValues: currentSettings.reopenLatestAttributeValues,
                            reopenLatestSelectedTimePresent: currentSettings.reopenLatestSelectedTimePresent,
                            unityCDA: {
                              hasSeenFirstTimeModal: true
                            },
                            firstTimeExperience: currentSettings.firstTimeExperience,
                            highlightbarAttributes: currentSettings.highlightbarAttributes,
                            debugViewToggles: currentSettings.debugViewToggles,
                            languageCode: currentSettings.languageCode,
                            fingerprintDetailsAttrs: currentSettings.fingerprintDetailsAttrs
                          };
                  }), refetchConfig, (function (err) {
                    var __x = Backtrace$BsConsole.Attributes.addString("src_module", "FirstTimeUnityModal/useEffect1", Backtrace$BsConsole.Attributes.make(undefined));
                    return Backtrace$BsConsole.Client.send(__x, /* `string */[
                                288368849,
                                "Failed to create userFrontendSettings: " + err
                              ]);
                  }));
          }
          
        }), [open_]);
  return React.createElement(Dialog.default, {
              open: open_,
              classes: {
                paper: Css.style(/* :: */[
                      Css.position(/* absolute */-1013592457),
                      /* :: */[
                        Css.top(Css.px(200)),
                        /* [] */0
                      ]
                    ])
              },
              disableBackdropClick: true,
              hideBackdrop: true,
              children: null
            }, React.createElement(DialogTitle.default, {
                  children: I18N$BsConsole.show(undefined, "Welcome to Unity CDA powered by Backtrace")
                }), React.createElement(DialogContent.default, {
                  children: null
                }, React.createElement(BtTypography$BsConsole.make, {
                      variant: /* Body1 */8,
                      className: Css.style(/* :: */[
                            Css.marginBottom(Css.rem(1)),
                            /* [] */0
                          ]),
                      children: I18N$BsConsole.show(undefined, "We are fetching your last 90 days of data. Data will take some time to populate. Please check back periodically to see updates.")
                    }), React.createElement(BtTypography$BsConsole.make, {
                      variant: /* Body1 */8,
                      children: I18N$BsConsole.show(undefined, "for the best experience, download our Unity SDK and unlock the following benefits:")
                    }), React.createElement("ul", undefined, React.createElement("li", undefined, React.createElement(BtTypography$BsConsole.make, {
                              variant: /* Body1 */8,
                              children: I18N$BsConsole.show(undefined, "Improved callstacks")
                            })), React.createElement("li", undefined, React.createElement(BtTypography$BsConsole.make, {
                              variant: /* Body1 */8,
                              children: I18N$BsConsole.show(undefined, "Error-free statistics")
                            })), React.createElement("li", undefined, React.createElement(BtTypography$BsConsole.make, {
                              variant: /* Body1 */8,
                              children: I18N$BsConsole.show(undefined, "Breadcrumbs, screenshots, and attachments")
                            })), React.createElement("li", undefined, React.createElement(BtTypography$BsConsole.make, {
                              variant: /* Body1 */8,
                              children: I18N$BsConsole.show(undefined, "and more")
                            }))), React.createElement(BtTypography$BsConsole.make, {
                      variant: /* Body1 */8,
                      children: null
                    }, I18N$BsConsole.show(undefined, "Learn more about the SDK by reading the "), React.createElement("a", {
                          className: Css.style(/* :: */[
                                Css.display(/* inlineFlex */53323314),
                                /* :: */[
                                  Css.alignItems(/* center */98248149),
                                  /* [] */0
                                ]
                              ]),
                          href: Href$BsConsole.outsideLinksToJs(/* UnityCDASupport */-642242812),
                          rel: "noopener noreferrer",
                          target: "_blank",
                          onClick: (function (_event) {
                              return MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* UnityCDAFirstTimeModalDocumentationClick */116]));
                            })
                        }, I18N$BsConsole.show(undefined, "documentation"), React.createElement(Launch.default, {
                              color: "inherit",
                              fontSize: "inherit"
                            })))), React.createElement(DialogActions.default, {
                  className: Css.style(/* :: */[
                        Css.padding3(Css.zero, Css.px(24), Css.px(24)),
                        /* :: */[
                          Css.margin(/* zero */-789508312),
                          /* [] */0
                        ]
                      ]),
                  children: null
                }, React.createElement(Button.default, {
                      color: "primary",
                      onClick: (function (param) {
                          return Curry._1(setOpen, (function (param) {
                                        return false;
                                      }));
                        }),
                      children: I18N$BsConsole.show(undefined, "Take me to my project")
                    }), React.createElement("a", {
                      className: Css.style(/* :: */[
                            Css.textDecoration(/* none */-922086728),
                            /* [] */0
                          ]),
                      href: Href$BsConsole.outsideLinksToJs(/* UnityCDASupport */-642242812),
                      rel: "noopener noreferrer",
                      target: "_blank",
                      onClick: (function (_event) {
                          return MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* UnityCDAFirstTimeModalDocumentationClick */116]));
                        })
                    }, React.createElement(Button.default, {
                          variant: "contained",
                          color: "primary",
                          children: I18N$BsConsole.show(undefined, "Learn more")
                        }))));
}

function make(config, refetchConfig, token, _children) {
  return ReasonReactCompat.wrapReactForReasonReact(FirstTimeUnityModal, {
              config: config,
              refetchConfig: refetchConfig,
              token: token
            }, _children);
}

var Jsx2 = {
  make: make
};

var make$1 = FirstTimeUnityModal;

exports.getHasModalAlreadyBeenSeen = getHasModalAlreadyBeenSeen;
exports.make = make$1;
exports.Jsx2 = Jsx2;
/* Css Not a pure module */
