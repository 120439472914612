// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Colors$BsConsole = require("../Colors.js");
var MuiIcons$BsConsole = require("../MuiIcons.js");
var SauceColors$BsConsole = require("../SauceColors.js");
var FilterBarIcon$BsConsole = require("./FilterBarIcon.js");
var Button = require("@material-ui/core/Button");
var FilterList = require("@material-ui/icons/FilterList");

function NlqSwitch(Props) {
  var isNLQ = Props.isNLQ;
  var setNLQ = Props.setNLQ;
  var filterContainerRef = Props.filterContainerRef;
  var autoFocus = Props.autoFocus;
  var focusInput = function (param) {
    setTimeout((function (param) {
            return Curry._1(autoFocus, filterContainerRef.current);
          }), 50);
    
  };
  return React.createElement("div", {
              className: Css.style(/* :: */[
                    Css.backgroundColor(Css.hex(Colors$BsConsole.grey375)),
                    /* :: */[
                      Css.margin4(Css.px(2), Css.px(10), Css.px(2), Css.px(2)),
                      /* :: */[
                        Css.padding(Css.px(2)),
                        /* :: */[
                          Css.borderRadius(Css.px(3)),
                          /* :: */[
                            Css.whiteSpace(/* nowrap */867913355),
                            /* [] */0
                          ]
                        ]
                      ]
                    ]
                  ])
            }, React.createElement(Button.default, {
                  variant: "text",
                  classes: {
                    root: Css.style(/* :: */[
                          Css.backgroundColor(isNLQ ? /* transparent */582626130 : Css.hex(Colors$BsConsole.grey2)),
                          /* :: */[
                            Css.padding(Css.px(0)),
                            /* :: */[
                              Css.fontWeight(/* `num */[
                                    5496390,
                                    400
                                  ]),
                              /* [] */0
                            ]
                          ]
                        ]),
                    text: Css.style(/* :: */[
                          Css.paddingTop(Css.px(0)),
                          /* [] */0
                        ])
                  },
                  onClick: (function (param) {
                      Curry._1(setNLQ, false);
                      return focusInput(undefined);
                    }),
                  children: React.createElement(FilterBarIcon$BsConsole.make, {
                        icon: React.createElement(FilterList.default, {
                              className: Css.style(/* :: */[
                                    Css.color(isNLQ ? Css.hex(Colors$BsConsole.disabled) : Css.hex(SauceColors$BsConsole.green)),
                                    /* :: */[
                                      Css.marginRight(Css.px(2)),
                                      /* [] */0
                                    ]
                                  ])
                            }),
                        text: "Filter"
                      })
                }), React.createElement(Button.default, {
                  classes: {
                    root: Css.style(/* :: */[
                          Css.backgroundColor(isNLQ ? Css.hex(Colors$BsConsole.grey2) : /* transparent */582626130),
                          /* :: */[
                            Css.padding(Css.px(0)),
                            /* :: */[
                              Css.fontWeight(/* `num */[
                                    5496390,
                                    400
                                  ]),
                              /* [] */0
                            ]
                          ]
                        ]),
                    text: Css.style(/* :: */[
                          Css.paddingTop(Css.px(0)),
                          /* [] */0
                        ])
                  },
                  onClick: (function (param) {
                      Curry._1(setNLQ, true);
                      return focusInput(undefined);
                    }),
                  children: React.createElement(FilterBarIcon$BsConsole.make, {
                        icon: React.createElement(MuiIcons$BsConsole.AutoAwesome.make, {
                              className: Css.style(/* :: */[
                                    Css.color(isNLQ ? Css.hex(SauceColors$BsConsole.yellow) : Css.hex(Colors$BsConsole.disabled)),
                                    /* :: */[
                                      Css.marginRight(Css.px(2)),
                                      /* [] */0
                                    ]
                                  ])
                            }),
                        text: "Ask"
                      })
                }));
}

var make = NlqSwitch;

exports.make = make;
/* Css Not a pure module */
