// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var DateFns = require("bs-date-fns/lib/js/src/DateFns.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var Gate$BsConsole = require("./Gate.js");
var Bridge$BsConsole = require("./bridge.js");
var Tooltip$BsConsole = require("./charts/Tooltip.js");
var Version$BsConsole = require("./Version.js");
var Uncaught$BsConsole = require("./Uncaught.js");
var Backtrace$BsConsole = require("./bindings/Backtrace.js");
var MSClarity$BsConsole = require("./MSClarity.js");
var JssProvider$BsConsole = require("./JssProvider.js");
var SnackPurveyor$BsConsole = require("./SnackPurveyor.js");
var BannerPurveyor$BsConsole = require("./utilities/BannerPurveyor.js");
var BtThemeContext$BsConsole = require("./BtThemeContext.js");
var L10N_date_time$BsConsole = require("./L10N_date_time.js");
var SymbolicationID$BsConsole = require("./SymbolicationID.js");
var UnityDashboardRedirect$BsConsole = require("./UnityDashboardRedirect.js");
var IFramePostMessageEvents$BsConsole = require("./IFramePostMessageEvents.js");

((require('./static/app.css')));

Backtrace$BsConsole.Client.initialize(undefined, undefined);

Backtrace$BsConsole.Client.memorize("day.of.week", DateFns.format("dddd", L10N_date_time$BsConsole.now(undefined)));

Backtrace$BsConsole.Client.memorize("version", Version$BsConsole.version);

if (process.env.NODE_ENV === "production") {
  Backtrace$BsConsole.Client.memorize("symbolication_id", SymbolicationID$BsConsole.id);
}

console.log("react", React.version);

if (!Gate$BsConsole.metricsDisabled(undefined)) {
  MSClarity$BsConsole.initialize(undefined);
}

var component = ReasonReact.statelessComponent("App");

function make(_children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: (function (_self) {
              return UnityDashboardRedirect$BsConsole.handleRedirect(undefined);
            }),
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, JssProvider$BsConsole.make([ReasonReact.element(undefined, undefined, Curry._1(BtThemeContext$BsConsole.Provider.Jsx2.make, [ReasonReact.element(undefined, undefined, Curry._1(BtThemeContext$BsConsole.MuiProvider.Jsx2.make, [
                                                  React.createElement("link", {
                                                        href: "https://fonts.googleapis.com/css?family=Inconsolata|Inter|Roboto:300,400,500,600,700,800|Roboto+Mono",
                                                        rel: "stylesheet"
                                                      }),
                                                  ReasonReact.element(undefined, undefined, Mui$BsConsole.CssBaseline.make([])),
                                                  ReasonReact.element(undefined, undefined, Curry._3(Backtrace$BsConsole.ErrorBoundary.Jsx2.make, "main-boundary", (function (param) {
                                                              return ReasonReact.element(undefined, undefined, Uncaught$BsConsole.Jsx2.make([]));
                                                            }), [ReasonReact.element(undefined, undefined, Bridge$BsConsole.make([]))])),
                                                  React.createElement("div", {
                                                        ref: (function (ref) {
                                                            Tooltip$BsConsole.portalRef.contents = (ref == null) ? undefined : Caml_option.some(ref);
                                                            
                                                          }),
                                                        id: "tooltip-portal"
                                                      }),
                                                  ReasonReact.element(undefined, undefined, SnackPurveyor$BsConsole.make([])),
                                                  ReasonReact.element(undefined, undefined, BannerPurveyor$BsConsole.Jsx2.make([])),
                                                  ReasonReact.element(undefined, undefined, IFramePostMessageEvents$BsConsole.Jsx2.make([]))
                                                ]))]))]));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

exports.component = component;
exports.make = make;
/*  Not a pure module */
