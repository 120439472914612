// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Json = require("@glennsl/bs-json/lib/js/src/Json.bs.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var Int64 = require("bs-platform/lib/js/int64.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_int64 = require("bs-platform/lib/js/caml_int64.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Crdb$BsConsole = require("./crdb.js");
var I18N$BsConsole = require("./I18N.js");
var Task2$BsConsole = require("./Task2.js");
var BpgTask$BsConsole = require("./BpgTask.js");
var QueryPaginationTask$BsConsole = require("./QueryPaginationTask.js");
var ProjectFrontendSettings$BsConsole = require("./ProjectFrontendSettings.js");

function reducer(state, action) {
  switch (action.tag | 0) {
    case /* SetRetainedAperture */0 :
        return {
                timeAperture: state.timeAperture,
                timeApertureWithRange: state.timeApertureWithRange,
                frozenAperture: state.frozenAperture,
                retainedAperture: action[0]
              };
    case /* SetTimeApertures */1 :
        var timeApertureWithRange = action[2];
        var frozenAperture = Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, timeApertureWithRange, action[0]);
        return {
                timeAperture: action[1],
                timeApertureWithRange: timeApertureWithRange,
                frozenAperture: frozenAperture,
                retainedAperture: state.retainedAperture
              };
    case /* SetApertureWithSameRange */2 :
        var frozenAperture$1 = Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, state.timeApertureWithRange, action[0]);
        return {
                timeAperture: state.timeAperture,
                timeApertureWithRange: state.timeApertureWithRange,
                frozenAperture: frozenAperture$1,
                retainedAperture: state.retainedAperture
              };
    
  }
}

function getInitialState(aperture) {
  var timeAperture = Curry._1(Crdb$BsConsole.Aperture.getTimeAperture, aperture);
  var timeApertureWithRange = Curry._1(Crdb$BsConsole.TimeAperture.freeze, Curry._1(Crdb$BsConsole.Aperture.getTimeAperture, aperture));
  var frozenAperture = Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, timeApertureWithRange, aperture);
  return {
          timeAperture: timeAperture,
          timeApertureWithRange: timeApertureWithRange,
          frozenAperture: frozenAperture,
          retainedAperture: aperture
        };
}

function use(aperture) {
  var match = React.useReducer(reducer, aperture, getInitialState);
  var dispatch = match[1];
  var match$1 = match[0];
  var retainedAperture = match$1.retainedAperture;
  var timeAperture = match$1.timeAperture;
  React.useEffect((function () {
          if (!Curry._2(Crdb$BsConsole.Aperture.eq, aperture, retainedAperture)) {
            Curry._1(dispatch, /* SetRetainedAperture */Block.__(0, [aperture]));
          }
          
        }), [aperture]);
  React.useEffect((function () {
          var newTA = Curry._1(Crdb$BsConsole.Aperture.getTimeAperture, retainedAperture);
          if (Curry._2(Crdb$BsConsole.TimeAperture.eq, newTA, timeAperture)) {
            Curry._1(dispatch, /* SetApertureWithSameRange */Block.__(2, [retainedAperture]));
          } else {
            Curry._1(dispatch, /* SetTimeApertures */Block.__(1, [
                    retainedAperture,
                    newTA,
                    Curry._1(Crdb$BsConsole.TimeAperture.freeze, newTA)
                  ]));
          }
          
        }), [retainedAperture]);
  return match$1.frozenAperture;
}

var FrozenTimeAperture = {
  reducer: reducer,
  getInitialState: getInitialState,
  use: use
};

function getFoldTuples(fold) {
  return Belt_Array.map(Curry._1(Crdb$BsConsole.Fold.toArray, fold), (function (agg) {
                return /* tuple */[
                        Curry._1(Crdb$BsConsole.Aggregation.getAttribute, agg),
                        Crdb$BsConsole.Operation.toString(Curry._1(Crdb$BsConsole.Aggregation.getOperation, agg))
                      ];
              }));
}

function useSortRecover(sort, query, onRecover) {
  React.useLayoutEffect((function () {
          var sortAttr = Curry._1(Crdb$BsConsole.Sort.getAttribute, sort);
          var sortAgg = Curry._1(Crdb$BsConsole.Sort.getAggString, sort);
          var match = Curry._1(Crdb$BsConsole.Query.getFold, query);
          var match$1 = Curry._1(Crdb$BsConsole.Query.getSelect, query);
          if (match !== undefined) {
            var foldTuples = Belt_Array.concat(getFoldTuples(Caml_option.valFromOption(match)), [
                  /* tuple */[
                    "count",
                    "none"
                  ],
                  /* tuple */[
                    "group",
                    "none"
                  ]
                ]);
            var match$2 = Belt_Array.getBy(foldTuples, (function (param) {
                    if (param[0] === sortAttr) {
                      return param[1] === sortAgg;
                    } else {
                      return false;
                    }
                  }));
            if (match$2 !== undefined) {
              
            } else {
              Curry._1(onRecover, undefined);
            }
          } else if (match$1 !== undefined) {
            var match$3 = Belt_List.getBy(match$1, (function (attr) {
                    if (attr === sortAttr) {
                      return sortAgg === "select";
                    } else {
                      return false;
                    }
                  }));
            if (match$3 !== undefined) {
              
            } else {
              Curry._1(onRecover, undefined);
            }
          }
          
        }), [sort]);
  
}

function makeSimpleQuery(aperture) {
  var __x = Crdb$BsConsole.Fold.empty;
  var fold = Curry._2(Crdb$BsConsole.Fold.add, Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
            "_tx",
            /* Range */8
          ]), __x);
  return /* Aggregate */Block.__(0, [
            aperture,
            undefined,
            fold,
            undefined,
            undefined,
            undefined
          ]);
}

function addTx(aperture, tx) {
  var __x = Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture);
  var filters = Curry._2(Crdb$BsConsole.Filters.add, Curry._1(Crdb$BsConsole.Filter.create, /* tuple */[
            "_tx",
            /* AtMost */Block.__(9, [/* `String */[
                  -976970511,
                  tx
                ]])
          ]), __x);
  return Curry._2(Crdb$BsConsole.Aperture.setFilters, filters, aperture);
}

function getApertureForMultipleQueries(aperture, token, projectName) {
  var match = React.useState((function () {
          return aperture;
        }));
  var setRetainedAperture = match[1];
  var retainedAperture = match[0];
  var match$1 = React.useState((function () {
          
        }));
  var setApertureForQuery = match$1[1];
  var match$2 = React.useState((function () {
          return /* NotAsked */0;
        }));
  var setPaginationRemote = match$2[1];
  var paginationRemote = match$2[0];
  var frozenAperture = use(aperture);
  var query = makeSimpleQuery(frozenAperture);
  React.useEffect((function () {
          if (!Curry._2(Crdb$BsConsole.Aperture.eq, aperture, retainedAperture)) {
            Curry._1(setRetainedAperture, (function (param) {
                    return aperture;
                  }));
          }
          
        }), [aperture]);
  React.useEffect((function () {
          var hasTx = Belt_Option.isSome(Belt_Array.getBy(Curry._1(Crdb$BsConsole.Filters.toArray, Curry._1(Crdb$BsConsole.Aperture.getFilters, aperture)), (function (filter) {
                      if (Crdb$BsConsole.FilterOperation.toString(Curry._1(Crdb$BsConsole.Filter.getOperation, filter)) === "at-most") {
                        return Curry._1(Crdb$BsConsole.Filter.getAttribute, filter) === "_tx";
                      } else {
                        return false;
                      }
                    })));
          if (hasTx) {
            Curry._1(setApertureForQuery, (function (param) {
                    return Caml_option.some(aperture);
                  }));
          } else {
            var onSuccess = function (resp) {
              return Curry._1(setPaginationRemote, (function (param) {
                            return /* Success */Block.__(0, [resp]);
                          }));
            };
            var onFailure = function (errorMessage) {
              return Curry._1(setPaginationRemote, (function (param) {
                            return /* Failure */Block.__(1, [errorMessage]);
                          }));
            };
            var arg = QueryPaginationTask$BsConsole.CrdbAggregate.makeTask(query, projectName, undefined, 0, undefined, undefined);
            Curry._2((function (param) {
                      return (function (param$1, param$2) {
                          return Task2$BsConsole.handle(param, arg, param$1, param$2);
                        });
                    })(token), undefined, (function (param) {
                    return QueryPaginationTask$BsConsole.responseCb(onSuccess, onFailure, param);
                  }));
          }
          
        }), [retainedAperture]);
  React.useEffect((function () {
          if (typeof paginationRemote === "number") {
            paginationRemote === /* NotAsked */0;
          } else if (paginationRemote.tag) {
            Curry._1(setApertureForQuery, (function (param) {
                    return Caml_option.some(frozenAperture);
                  }));
          } else {
            var row = Belt_List.get(paginationRemote[0][1], 0);
            if (row !== undefined) {
              var txRange = Belt_List.getBy(row.aggregations, (function (shape) {
                      if (shape.tag === /* Range */8 && shape[0] === "_tx") {
                        return true;
                      } else {
                        return false;
                      }
                    }));
              var exit = 0;
              if (txRange !== undefined && txRange.tag === /* Range */8 && txRange[0] === "_tx") {
                var match = txRange[1];
                if (match !== undefined) {
                  var match$1 = match[1];
                  if (match$1 !== undefined) {
                    switch (match$1.tag | 0) {
                      case /* Raw */0 :
                          var match$2 = match$1[0];
                          if (typeof match$2 === "number") {
                            exit = 1;
                          } else {
                            var variant = match$2[0];
                            if (variant !== -976970511) {
                              if (variant !== 365180284) {
                                exit = 1;
                              } else {
                                var nextAperture = addTx(frozenAperture, Int64.to_string(Caml_int64.of_float(match$2[1])));
                                Curry._1(setApertureForQuery, (function (param) {
                                        return Caml_option.some(nextAperture);
                                      }));
                              }
                            } else {
                              var nextAperture$1 = addTx(frozenAperture, match$2[1]);
                              Curry._1(setApertureForQuery, (function (param) {
                                      return Caml_option.some(nextAperture$1);
                                    }));
                            }
                          }
                          break;
                      case /* ObjectID */17 :
                          var nextAperture$2 = addTx(frozenAperture, Int64.to_string(match$1[0]));
                          Curry._1(setApertureForQuery, (function (param) {
                                  return Caml_option.some(nextAperture$2);
                                }));
                          break;
                      default:
                        exit = 1;
                    }
                  } else {
                    exit = 1;
                  }
                } else {
                  exit = 1;
                }
              } else {
                exit = 1;
              }
              if (exit === 1) {
                Curry._1(setApertureForQuery, (function (param) {
                        return Caml_option.some(frozenAperture);
                      }));
              }
              
            } else {
              Curry._1(setApertureForQuery, (function (param) {
                      return Caml_option.some(frozenAperture);
                    }));
            }
          }
          
        }), [paginationRemote]);
  return match$1[0];
}

function getProjectFrontendSettings(token, config, projectName) {
  var match = React.useState((function () {
          return /* NotAsked */0;
        }));
  var setProjectFrontendSettings = match[1];
  var currentProject = Belt_List.getBy(config.projects, (function (project) {
          return project.name === projectName;
        }));
  var match$1 = BpgTask$BsConsole.use(token, BpgTask$BsConsole.ProjectFrontendSettings.fetchAll);
  var projectFrontendSettingsRemote = match$1[0];
  React.useEffect((function () {
          if (currentProject !== undefined) {
            if (typeof projectFrontendSettingsRemote === "number") {
              projectFrontendSettingsRemote === /* NotAsked */0;
            } else if (projectFrontendSettingsRemote.tag) {
              var msg = projectFrontendSettingsRemote[0];
              Curry._1(setProjectFrontendSettings, (function (param) {
                      return /* Failure */Block.__(1, [msg]);
                    }));
            } else {
              var projectFrontendSettings$prime = Belt_Option.flatMap(Belt_Option.flatMap(Belt_Array.getBy(projectFrontendSettingsRemote[0], (function (setting) {
                              return setting.pid === currentProject.pid;
                            })), (function (setting) {
                          return Json.parse(setting.json);
                        })), (function (settingJson) {
                      return ProjectFrontendSettings$BsConsole.ofJson(settingJson);
                    }));
              Curry._1(setProjectFrontendSettings, (function (param) {
                      return /* Success */Block.__(0, [projectFrontendSettings$prime]);
                    }));
            }
          } else {
            Curry._1(setProjectFrontendSettings, (function (param) {
                    return /* Failure */Block.__(1, [I18N$BsConsole.get(undefined, "Project not found")]);
                  }));
          }
          
        }), [projectFrontendSettingsRemote]);
  return /* tuple */[
          match[0],
          match$1[1]
        ];
}

function getRetainedAperture(aperture) {
  var match = React.useState((function () {
          return aperture;
        }));
  var setRetainedAperture = match[1];
  var retainedAperture = match[0];
  React.useEffect((function () {
          if (!Curry._2(Crdb$BsConsole.Aperture.eq, aperture, retainedAperture)) {
            Curry._1(setRetainedAperture, (function (param) {
                    return aperture;
                  }));
          }
          
        }), [aperture]);
  return retainedAperture;
}

exports.FrozenTimeAperture = FrozenTimeAperture;
exports.getFoldTuples = getFoldTuples;
exports.useSortRecover = useSortRecover;
exports.makeSimpleQuery = makeSimpleQuery;
exports.addTx = addTx;
exports.getApertureForMultipleQueries = getApertureForMultipleQueries;
exports.getProjectFrontendSettings = getProjectFrontendSettings;
exports.getRetainedAperture = getRetainedAperture;
/* react Not a pure module */
