// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var List = require("bs-platform/lib/js/list.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var $$String = require("bs-platform/lib/js/string.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("../bindings/Mui.js");
var Row$BsConsole = require("../utilities/Row.js");
var I18N$BsConsole = require("../I18N.js");
var Colors$BsConsole = require("../Colors.js");
var Column$BsConsole = require("../utilities/Column.js");
var Current$BsConsole = require("./Current.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var MuiIcons$BsConsole = require("../MuiIcons.js");
var Backtrace$BsConsole = require("../bindings/Backtrace.js");
var NewStyles$BsConsole = require("./NewStyles.js");
var TeamsUtils$BsConsole = require("../teams/TeamsUtils.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var CoronerdGate$BsConsole = require("../CoronerdGate.js");
var MetricsEvent$BsConsole = require("../bs-metrics/MetricsEvent.js");
var QueryDetails$BsConsole = require("./QueryDetails.js");
var Caml_builtin_exceptions = require("bs-platform/lib/js/caml_builtin_exceptions.js");
var SnackPurveyor$BsConsole = require("../SnackPurveyor.js");
var SavedQueriesModel$BsConsole = require("./SavedQueriesModel.js");
var UserFrontendSettings$BsConsole = require("../UserFrontendSettings.js");
var ProjectFrontendSettings$BsConsole = require("../ProjectFrontendSettings.js");

function SavedQueriesDetail(Props) {
  var name = Props.name;
  var savedView = Props.savedView;
  var pid = Props.pid;
  var savedDashboards = Props.savedDashboards;
  var navToLoad = Props.navToLoad;
  var navToNone = Props.navToNone;
  var activeQuery = Props.activeQuery;
  var mode = Props.mode;
  var navToReplace = Props.navToReplace;
  var navToDelete = Props.navToDelete;
  var onSelectSavedQuery = Props.onSelectSavedQuery;
  var queriesBpg = Props.queriesBpg;
  var token = Props.token;
  var config = Props.config;
  var projectDefault = Props.projectDefault;
  var refetchProjectSettings = Props.refetchProjectSettings;
  var userDefault = Props.userDefault;
  var refetchUserSettings = Props.refetchUserSettings;
  var isProjectBookmarked = Props.isProjectBookmarked;
  var isUserBookmarked = Props.isUserBookmarked;
  var projectDefault$1 = Belt_Option.mapWithDefault(Belt_Option.map(projectDefault, SavedQueriesModel$BsConsole.getName), false, (function (projectDefaultName) {
          return projectDefaultName === SavedQueriesModel$BsConsole.getName(savedView);
        }));
  var userDefault$1 = Belt_Option.mapWithDefault(Belt_Option.map(userDefault, SavedQueriesModel$BsConsole.getName), false, (function (userDefaultName) {
          return userDefaultName === SavedQueriesModel$BsConsole.getName(savedView);
        }));
  var dashboardQueries = List.find_all((function (q) {
            if (q.name.length > 13 && $$String.sub(q.name, 0, 12) === "%dashboard% ") {
              return q.project === pid;
            } else {
              return false;
            }
          }))(queriesBpg);
  var nonDashboardQueries = List.find_all((function (q) {
            if (q.name.length <= 13 || q.name.length > 13 && $$String.sub(q.name, 0, 12) !== "%dashboard% ") {
              return q.project === pid;
            } else {
              return false;
            }
          }))(queriesBpg);
  var savedQueryBpg;
  var exit = 0;
  var query$prime;
  try {
    query$prime = List.find((function (q) {
            return q.name === "%dashboard% " + SavedQueriesModel$BsConsole.getName(savedView);
          }), dashboardQueries);
    exit = 1;
  }
  catch (exn){
    if (exn === Caml_builtin_exceptions.not_found) {
      var exit$1 = 0;
      var query$prime$prime;
      try {
        query$prime$prime = List.find((function (q) {
                return q.name === SavedQueriesModel$BsConsole.getName(savedView);
              }), nonDashboardQueries);
        exit$1 = 2;
      }
      catch (exn$1){
        if (exn$1 === Caml_builtin_exceptions.not_found) {
          savedQueryBpg = undefined;
        } else {
          throw exn$1;
        }
      }
      if (exit$1 === 2) {
        savedQueryBpg = query$prime$prime;
      }
      
    } else {
      throw exn;
    }
  }
  if (exit === 1) {
    savedQueryBpg = query$prime;
  }
  var userIsPrivileged = TeamsUtils$BsConsole.isAdmin(config, name) || config.user.superuser === 1;
  var userIsSavedQueryOwner = Belt_Option.mapWithDefault(savedQueryBpg, false, (function (query) {
          return Belt_Option.getWithDefault(query.owner, -1) === config.user.uid;
        }));
  var match = userIsPrivileged || userIsSavedQueryOwner;
  var tmp;
  if (activeQuery !== undefined && mode !== undefined && savedQueryBpg !== undefined && match) {
    var _saved = Current$BsConsole.get(mode, activeQuery, savedDashboards);
    tmp = _saved ? null : ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                undefined,
                NewStyles$BsConsole.outlinedButton,
                (function (_e) {
                    return Curry._3(navToReplace, savedView, savedQueryBpg, activeQuery);
                  }),
                undefined,
                undefined,
                /* Flat */781662169,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                undefined,
                [I18N$BsConsole.show(undefined, "Replace")]
              ]));
  } else {
    tmp = null;
  }
  var match$1 = userIsPrivileged || userIsSavedQueryOwner;
  return ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, Css.style(/* :: */[
                      Css.height(Css.pct(100)),
                      /* [] */0
                    ]), [
                  ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, /* center */98248149, undefined, undefined, Css.style(/* :: */[
                                Css.marginTop(Css.rem(1)),
                                /* [] */0
                              ]), [
                            ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.IconButton.Jsx2.make, [
                                      Css.style(/* :: */[
                                            Css.height(Css.px(48)),
                                            /* :: */[
                                              Css.width(Css.px(48)),
                                              /* [] */0
                                            ]
                                          ]),
                                      undefined,
                                      (function (_e) {
                                          return Curry._1(navToLoad, undefined);
                                        }),
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      undefined,
                                      {
                                        root: Css.style(/* :: */[
                                              Css.padding(Css.px(6)),
                                              /* [] */0
                                            ])
                                      },
                                      undefined,
                                      "Back to list",
                                      [ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.ChevronLeft.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))]
                                    ])),
                            React.createElement("span", {
                                  className: Css.merge(/* :: */[
                                        BtTypography$BsConsole.subtitle1,
                                        /* :: */[
                                          Css.style(/* :: */[
                                                Css.color(Css.hex(Colors$BsConsole.grey4)),
                                                /* :: */[
                                                  Css.userSelect(Css.none),
                                                  /* [] */0
                                                ]
                                              ]),
                                          /* [] */0
                                        ]
                                      ])
                                }, I18N$BsConsole.show(undefined, "Back to list"))
                          ])),
                  ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.IconButton.Jsx2.make, [
                            Css.style(/* :: */[
                                  Css.height(Css.px(48)),
                                  /* :: */[
                                    Css.position(/* absolute */-1013592457),
                                    /* :: */[
                                      Css.top(/* `rem */[
                                            5691738,
                                            1
                                          ]),
                                      /* :: */[
                                        Css.right(/* `rem */[
                                              5691738,
                                              1
                                            ]),
                                        /* [] */0
                                      ]
                                    ]
                                  ]
                                ]),
                            undefined,
                            (function (_e) {
                                return Curry._1(navToNone, undefined);
                              }),
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            undefined,
                            "Close",
                            [ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Clear.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))]
                          ])),
                  React.createElement("div", {
                        className: Css.style(/* :: */[
                              Css.padding4(/* zero */-789508312, Css.rem(1), /* zero */-789508312, Css.rem(1)),
                              /* :: */[
                                Css.width(/* `percent */[
                                      -119887163,
                                      100
                                    ]),
                                /* [] */0
                              ]
                            ])
                      }, ReasonReact.element(undefined, undefined, Row$BsConsole.make(/* spaceBetween */516682146, /* flexStart */662439529, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, /* flexStart */662439529, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Column$BsConsole.make(undefined, undefined, undefined, undefined, undefined, [
                                                    ReasonReact.element(undefined, undefined, BtTypography$BsConsole.Re.make(/* Heading4 */3, undefined, Css.style(/* :: */[
                                                                  Css.margin(/* zero */-789508312),
                                                                  /* :: */[
                                                                    Css.marginRight(/* `rem */[
                                                                          5691738,
                                                                          3
                                                                        ]),
                                                                    /* [] */0
                                                                  ]
                                                                ]), undefined, undefined, undefined, [I18N$BsConsole.showSkip(SavedQueriesModel$BsConsole.getName(savedView))])),
                                                    ReasonReact.element(undefined, undefined, BtTypography$BsConsole.Re.make(/* Subtitle1 */6, undefined, Css.style(/* :: */[
                                                                  Css.margin(/* zero */-789508312),
                                                                  /* [] */0
                                                                ]), undefined, Colors$BsConsole.grey4, undefined, [React.createElement("span", undefined, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                                                            /* String_literal */Block.__(11, [
                                                                                " by ",
                                                                                /* String */Block.__(2, [
                                                                                    /* No_padding */0,
                                                                                    /* End_of_format */0
                                                                                  ])
                                                                              ]),
                                                                            " by %s"
                                                                          ]), SavedQueriesModel$BsConsole.getUsername(savedView)))]))
                                                  ]))]))])), ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, /* center */98248149, undefined, undefined, Css.style(/* :: */[
                                    Css.marginTop(Css.rem(1.25)),
                                    /* [] */0
                                  ]), [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.FormControlLabel.Jsx2.make, [
                                          undefined,
                                          Caml_option.some(React.createElement("span", {
                                                    className: Css.merge(/* :: */[
                                                          BtTypography$BsConsole.body2,
                                                          /* :: */[
                                                            Css.style(/* :: */[
                                                                  Css.color(userDefault$1 ? Css.hex(Colors$BsConsole.grey0) : Css.hex(Colors$BsConsole.grey4)),
                                                                  /* [] */0
                                                                ]),
                                                            /* [] */0
                                                          ]
                                                        ])
                                                  }, I18N$BsConsole.show(undefined, "Set as your default view"))),
                                          Caml_option.some(ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Checkbox.Jsx2.make, [
                                                        Css.style(/* :: */[
                                                              Css.selector("svg", /* :: */[
                                                                    Css.width(Css.rem(0.9)),
                                                                    /* :: */[
                                                                      Css.height(Css.rem(0.9)),
                                                                      /* [] */0
                                                                    ]
                                                                  ]),
                                                              /* [] */0
                                                            ]),
                                                        /* `Bool */[
                                                          737456202,
                                                          userDefault$1
                                                        ],
                                                        undefined,
                                                        /* Primary */-791844958,
                                                        undefined,
                                                        undefined,
                                                        {
                                                          root: Css.style(/* :: */[
                                                                Css.color(Css.hex(Colors$BsConsole.grey4)),
                                                                /* :: */[
                                                                  Css.padding2(Css.rem(0.25), /* zero */-789508312),
                                                                  /* :: */[
                                                                    Css.paddingRight(Css.rem(0.5)),
                                                                    /* [] */0
                                                                  ]
                                                                ]
                                                              ])
                                                        },
                                                        true,
                                                        (function (_evt, checked) {
                                                            return CoronerdGate$BsConsole.snack("1.44.0", undefined, (function (param) {
                                                                          if (checked) {
                                                                            SavedQueriesModel$BsConsole.makeUserDefault(token, config, name, savedView, config.user.uid, refetchUserSettings);
                                                                            return MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* SavedQueries_MakeUserDefault */14]));
                                                                          } else {
                                                                            return SavedQueriesModel$BsConsole.clearUserDefault(token, config, config.user.uid, name, refetchUserSettings);
                                                                          }
                                                                        }), undefined);
                                                          }),
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        []
                                                      ]))),
                                          undefined,
                                          Css.style(/* :: */[
                                                Css.margin(/* zero */-789508312),
                                                /* :: */[
                                                  Css.userSelect(Css.none),
                                                  /* [] */0
                                                ]
                                              ]),
                                          undefined,
                                          undefined,
                                          undefined,
                                          []
                                        ]))])), ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, /* center */98248149, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.FormControlLabel.Jsx2.make, [
                                          undefined,
                                          Caml_option.some(React.createElement("span", {
                                                    className: Css.merge(/* :: */[
                                                          BtTypography$BsConsole.body2,
                                                          /* :: */[
                                                            Css.style(/* :: */[
                                                                  Css.color(projectDefault$1 ? Css.hex(Colors$BsConsole.grey0) : Css.hex(Colors$BsConsole.grey4)),
                                                                  /* [] */0
                                                                ]),
                                                            /* [] */0
                                                          ]
                                                        ])
                                                  }, I18N$BsConsole.show(undefined, "Set as the project view"))),
                                          Caml_option.some(ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Checkbox.Jsx2.make, [
                                                        Css.style(/* :: */[
                                                              Css.selector("svg", /* :: */[
                                                                    Css.width(Css.rem(0.9)),
                                                                    /* :: */[
                                                                      Css.height(Css.rem(0.9)),
                                                                      /* [] */0
                                                                    ]
                                                                  ]),
                                                              /* [] */0
                                                            ]),
                                                        /* `Bool */[
                                                          737456202,
                                                          projectDefault$1
                                                        ],
                                                        undefined,
                                                        /* Primary */-791844958,
                                                        undefined,
                                                        undefined,
                                                        {
                                                          root: Css.style(/* :: */[
                                                                Css.color(Css.hex(Colors$BsConsole.grey4)),
                                                                /* :: */[
                                                                  Css.padding2(Css.rem(0.25), /* zero */-789508312),
                                                                  /* :: */[
                                                                    Css.paddingRight(Css.rem(0.5)),
                                                                    /* [] */0
                                                                  ]
                                                                ]
                                                              ])
                                                        },
                                                        true,
                                                        (function (_evt, checked) {
                                                            return CoronerdGate$BsConsole.snack("1.44.0", undefined, (function (param) {
                                                                          if (checked) {
                                                                            SavedQueriesModel$BsConsole.makeProjectDefault(token, savedView, pid, name, refetchProjectSettings);
                                                                            return MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* SavedQueries_MakeProjectDefault */13]));
                                                                          } else {
                                                                            return SavedQueriesModel$BsConsole.clearProjectDefault(token, pid, name, refetchProjectSettings);
                                                                          }
                                                                        }), undefined);
                                                          }),
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        []
                                                      ]))),
                                          undefined,
                                          Css.style(/* :: */[
                                                Css.margin(/* zero */-789508312),
                                                /* :: */[
                                                  Css.userSelect(Css.none),
                                                  /* [] */0
                                                ]
                                              ]),
                                          undefined,
                                          !userIsPrivileged,
                                          undefined,
                                          []
                                        ]))])), ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, /* center */98248149, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.FormControlLabel.Jsx2.make, [
                                          undefined,
                                          Caml_option.some(React.createElement("span", {
                                                    className: Css.merge(/* :: */[
                                                          BtTypography$BsConsole.body2,
                                                          /* :: */[
                                                            Css.style(/* :: */[
                                                                  Css.color(projectDefault$1 ? Css.hex(Colors$BsConsole.grey0) : Css.hex(Colors$BsConsole.grey4)),
                                                                  /* [] */0
                                                                ]),
                                                            /* [] */0
                                                          ]
                                                        ])
                                                  }, I18N$BsConsole.show(undefined, "Bookmark view"))),
                                          Caml_option.some(ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Checkbox.Jsx2.make, [
                                                        Css.style(/* :: */[
                                                              Css.selector("svg", /* :: */[
                                                                    Css.width(Css.rem(0.9)),
                                                                    /* :: */[
                                                                      Css.height(Css.rem(0.9)),
                                                                      /* [] */0
                                                                    ]
                                                                  ]),
                                                              /* [] */0
                                                            ]),
                                                        /* `Bool */[
                                                          737456202,
                                                          isUserBookmarked
                                                        ],
                                                        undefined,
                                                        /* Primary */-791844958,
                                                        undefined,
                                                        undefined,
                                                        {
                                                          root: Css.style(/* :: */[
                                                                Css.color(Css.hex(Colors$BsConsole.grey4)),
                                                                /* :: */[
                                                                  Css.padding2(Css.rem(0.25), /* zero */-789508312),
                                                                  /* :: */[
                                                                    Css.paddingRight(Css.rem(0.5)),
                                                                    /* [] */0
                                                                  ]
                                                                ]
                                                              ])
                                                        },
                                                        true,
                                                        (function (_evt, checked) {
                                                            return CoronerdGate$BsConsole.snack("1.44.0", undefined, (function (param) {
                                                                          if (checked) {
                                                                            return UserFrontendSettings$BsConsole.reduce(token, config.user.uid, /* :: */[
                                                                                        /* AddBookmarkedView */Block.__(1, [
                                                                                            name,
                                                                                            SavedQueriesModel$BsConsole.getName(savedView)
                                                                                          ]),
                                                                                        /* [] */0
                                                                                      ], TeamsUtils$BsConsole.isGuest(config), (function (param) {
                                                                                          MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* SavedQueries_AddUserBookmark */17]));
                                                                                          return Curry._1(refetchProjectSettings, undefined);
                                                                                        }), (function (err) {
                                                                                          var __x = Backtrace$BsConsole.Attributes.addString("src_module", "SaveQueryDetail/handleAddUserBookmark", Backtrace$BsConsole.Attributes.make(undefined));
                                                                                          Backtrace$BsConsole.Client.send(__x, /* `string */[
                                                                                                288368849,
                                                                                                "Failed to add bookmark view: " + err
                                                                                              ]);
                                                                                          return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Failed to add bookmark view"));
                                                                                        }));
                                                                          } else {
                                                                            return UserFrontendSettings$BsConsole.reduce(token, config.user.uid, /* :: */[
                                                                                        /* RemoveBookmarkedView */Block.__(2, [
                                                                                            name,
                                                                                            SavedQueriesModel$BsConsole.getName(savedView)
                                                                                          ]),
                                                                                        /* [] */0
                                                                                      ], TeamsUtils$BsConsole.isGuest(config), (function (param) {
                                                                                          MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* SavedQueries_RemoveUserBookmark */18]));
                                                                                          return Curry._1(refetchProjectSettings, undefined);
                                                                                        }), (function (err) {
                                                                                          var __x = Backtrace$BsConsole.Attributes.addString("src_module", "SaveQueryDetail/handleRemoveUserBookmark", Backtrace$BsConsole.Attributes.make(undefined));
                                                                                          Backtrace$BsConsole.Client.send(__x, /* `string */[
                                                                                                288368849,
                                                                                                "Failed to remove bookmark view: " + err
                                                                                              ]);
                                                                                          return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Failed to remove bookmark view"));
                                                                                        }));
                                                                          }
                                                                        }), undefined);
                                                          }),
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        []
                                                      ]))),
                                          undefined,
                                          Css.style(/* :: */[
                                                Css.margin(/* zero */-789508312),
                                                /* :: */[
                                                  Css.userSelect(Css.none),
                                                  /* [] */0
                                                ]
                                              ]),
                                          undefined,
                                          !userIsPrivileged,
                                          undefined,
                                          []
                                        ]))])), ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, /* center */98248149, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.FormControlLabel.Jsx2.make, [
                                          undefined,
                                          Caml_option.some(React.createElement("span", {
                                                    className: Css.merge(/* :: */[
                                                          BtTypography$BsConsole.body2,
                                                          /* :: */[
                                                            Css.style(/* :: */[
                                                                  Css.color(projectDefault$1 ? Css.hex(Colors$BsConsole.grey0) : Css.hex(Colors$BsConsole.grey4)),
                                                                  /* [] */0
                                                                ]),
                                                            /* [] */0
                                                          ]
                                                        ])
                                                  }, I18N$BsConsole.show(undefined, "Bookmark view for everyone in project"))),
                                          Caml_option.some(ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Checkbox.Jsx2.make, [
                                                        Css.style(/* :: */[
                                                              Css.selector("svg", /* :: */[
                                                                    Css.width(Css.rem(0.9)),
                                                                    /* :: */[
                                                                      Css.height(Css.rem(0.9)),
                                                                      /* [] */0
                                                                    ]
                                                                  ]),
                                                              /* [] */0
                                                            ]),
                                                        /* `Bool */[
                                                          737456202,
                                                          isProjectBookmarked
                                                        ],
                                                        undefined,
                                                        /* Primary */-791844958,
                                                        undefined,
                                                        undefined,
                                                        {
                                                          root: Css.style(/* :: */[
                                                                Css.color(Css.hex(Colors$BsConsole.grey4)),
                                                                /* :: */[
                                                                  Css.padding2(Css.rem(0.25), /* zero */-789508312),
                                                                  /* :: */[
                                                                    Css.paddingRight(Css.rem(0.5)),
                                                                    /* [] */0
                                                                  ]
                                                                ]
                                                              ])
                                                        },
                                                        true,
                                                        (function (_evt, checked) {
                                                            return CoronerdGate$BsConsole.snack("1.44.0", undefined, (function (param) {
                                                                          if (checked) {
                                                                            return ProjectFrontendSettings$BsConsole.reduce(token, pid, /* :: */[
                                                                                        /* AddBookmarkedView */Block.__(1, [SavedQueriesModel$BsConsole.getName(savedView)]),
                                                                                        /* [] */0
                                                                                      ], (function (param) {
                                                                                          MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* SavedQueries_AddProjectBookmark */15]));
                                                                                          return Curry._1(refetchProjectSettings, undefined);
                                                                                        }));
                                                                          } else {
                                                                            return ProjectFrontendSettings$BsConsole.reduce(token, pid, /* :: */[
                                                                                        /* RemoveBookmarkedView */Block.__(2, [SavedQueriesModel$BsConsole.getName(savedView)]),
                                                                                        /* [] */0
                                                                                      ], (function (param) {
                                                                                          MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* SavedQueries_RemoveProjectBookmark */16]));
                                                                                          return Curry._1(refetchProjectSettings, undefined);
                                                                                        }));
                                                                          }
                                                                        }), undefined);
                                                          }),
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        undefined,
                                                        []
                                                      ]))),
                                          undefined,
                                          Css.style(/* :: */[
                                                Css.margin(/* zero */-789508312),
                                                /* :: */[
                                                  Css.userSelect(Css.none),
                                                  /* [] */0
                                                ]
                                              ]),
                                          undefined,
                                          !userIsPrivileged,
                                          undefined,
                                          []
                                        ]))])), ReasonReact.element(undefined, undefined, Row$BsConsole.make(/* spaceBetween */516682146, undefined, undefined, undefined, Css.style(/* :: */[
                                    Css.width(/* `percent */[
                                          -119887163,
                                          100
                                        ]),
                                    /* :: */[
                                      Css.marginBottom(Css.rem(0.5)),
                                      /* :: */[
                                        Css.marginTop(Css.rem(1.5)),
                                        /* [] */0
                                      ]
                                    ]
                                  ]), [
                                ReasonReact.element(undefined, undefined, Row$BsConsole.make(/* flexStart */662439529, undefined, undefined, undefined, undefined, [
                                          ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                    undefined,
                                                    Css.merge(/* :: */[
                                                          NewStyles$BsConsole.containedButton,
                                                          /* :: */[
                                                            Css.style(/* :: */[
                                                                  Css.marginRight(/* `rem */[
                                                                        5691738,
                                                                        1
                                                                      ]),
                                                                  /* [] */0
                                                                ]),
                                                            /* [] */0
                                                          ]
                                                        ]),
                                                    (function (param) {
                                                        MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* SavedQueries_Apply */10]));
                                                        Curry._1(onSelectSavedQuery, savedView);
                                                        return Curry._1(navToNone, undefined);
                                                      }),
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    undefined,
                                                    [I18N$BsConsole.show(undefined, "Apply view")]
                                                  ])),
                                          tmp
                                        ])),
                                ReasonReact.element(undefined, undefined, Row$BsConsole.make(/* flexEnd */924268066, undefined, undefined, undefined, undefined, [match$1 && savedQueryBpg !== undefined ? ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                      undefined,
                                                      Css.merge(/* :: */[
                                                            NewStyles$BsConsole.textButton,
                                                            /* :: */[
                                                              Css.style(/* :: */[
                                                                    Css.marginRight(/* `rem */[
                                                                          5691738,
                                                                          0.75
                                                                        ]),
                                                                    /* [] */0
                                                                  ]),
                                                              /* [] */0
                                                            ]
                                                          ]),
                                                      (function (_e) {
                                                          return Curry._2(navToDelete, savedView, savedQueryBpg);
                                                        }),
                                                      undefined,
                                                      undefined,
                                                      /* Flat */781662169,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      undefined,
                                                      [I18N$BsConsole.show(undefined, "Delete view")]
                                                    ])) : null]))
                              ]))),
                  ReasonReact.element(undefined, undefined, QueryDetails$BsConsole.Re.make(savedView, []))
                ]));
}

function make(savedView, pid, savedDashboards, navToLoad, navToNone, activeQuery, mode, navToReplace, navToDelete, onSelectSavedQuery, queriesBpg, token, config, name, projectDefault, refetchProjectSettings, userDefault, refetchUserSettings, isProjectBookmarked, isUserBookmarked, _children) {
  return ReasonReactCompat.wrapReactForReasonReact(SavedQueriesDetail, {
              name: name,
              savedView: savedView,
              pid: pid,
              savedDashboards: savedDashboards,
              navToLoad: navToLoad,
              navToNone: navToNone,
              activeQuery: activeQuery,
              mode: mode,
              navToReplace: navToReplace,
              navToDelete: navToDelete,
              onSelectSavedQuery: onSelectSavedQuery,
              queriesBpg: queriesBpg,
              token: token,
              config: config,
              projectDefault: projectDefault,
              refetchProjectSettings: refetchProjectSettings,
              userDefault: userDefault,
              refetchUserSettings: refetchUserSettings,
              isProjectBookmarked: isProjectBookmarked,
              isUserBookmarked: isUserBookmarked
            }, _children);
}

var Re = {
  make: make
};

var make$1 = SavedQueriesDetail;

exports.make = make$1;
exports.Re = Re;
/* Css Not a pure module */
