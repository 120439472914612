// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Caml_int64 = require("bs-platform/lib/js/caml_int64.js");
var Pervasives = require("bs-platform/lib/js/pervasives.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Json_decode = require("@glennsl/bs-json/lib/js/src/Json_decode.bs.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Ui$BsConsole = require("./ui.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var Row$BsConsole = require("./utilities/Row.js");
var Crdb$BsConsole = require("./crdb.js");
var Href$BsConsole = require("./Href.js");
var I18N$BsConsole = require("./I18N.js");
var Task$BsConsole = require("./task.js");
var Text$BsConsole = require("./Text.js");
var Util$BsConsole = require("./util.js");
var Group$BsConsole = require("./bs-crdb-response/src/Group.js");
var Parse$BsConsole = require("./bs-crdb-response/src/Parse.js");
var Colors$BsConsole = require("./Colors.js");
var Column$BsConsole = require("./utilities/Column.js");
var Styles$BsConsole = require("./utilities/Styles.js");
var BtPaper$BsConsole = require("./BtPaper.js");
var Numeral$BsConsole = require("./Numeral.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var Caml_js_exceptions = require("bs-platform/lib/js/caml_js_exceptions.js");
var Fragment$BsConsole = require("./Fragment.js");
var MuiIcons$BsConsole = require("./MuiIcons.js");
var Flamegraph$BsConsole = require("./Flamegraph.js");
var WindowSize$BsConsole = require("./WindowSize.js");
var ViewLoading$BsConsole = require("./ViewLoading.js");
var BtTypography$BsConsole = require("./BtTypography.js");
var MetricsEvent$BsConsole = require("./bs-metrics/MetricsEvent.js");
var TaskCallback$BsConsole = require("./TaskCallback.js");
var FilterOffIcon$BsConsole = require("./FilterOffIcon.js");
var CallstackCopyButton$BsConsole = require("./CallstackCopyButton.js");
var ContainerWithDimensions$BsConsole = require("./ContainerWithDimensions.js");

var loadingContainer = Css.style(/* :: */[
      Css.padding(Css.px(100)),
      /* [] */0
    ]);

var totalCount = Css.style(/* :: */[
      Css.backgroundColor(Css.hex(Colors$BsConsole.grey7)),
      /* :: */[
        Css.color(Css.hex(Colors$BsConsole.grey2)),
        /* :: */[
          Css.padding2(Css.px(2), Css.px(7)),
          /* [] */0
        ]
      ]
    ]);

var caption = Css.style(/* :: */[
      Css.unsafe("color", "currentColor !important"),
      /* [] */0
    ]);

var flamegraphToolbarStyle = Css.style(/* :: */[
      Css.flexWrap(/* nowrap */867913355),
      /* :: */[
        Css.justifyContent(/* spaceBetween */516682146),
        /* :: */[
          Css.zIndex(1000),
          /* :: */[
            Css.minHeight(Css.px(50)),
            /* :: */[
              Css.borderBottom(Css.px(2), /* solid */12956715, Css.hex(Colors$BsConsole.grey675)),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var flamegraphFrame = Css.style(/* :: */[
      Css.unsafe("color", "currentColor !important"),
      /* [] */0
    ]);

var frameFont = Css.style(/* :: */[
      Css.unsafe("fontFamily", "Inconsolata, monospace"),
      /* :: */[
        Css.whiteSpace(/* nowrap */867913355),
        /* :: */[
          Css.overflow(/* hidden */-862584982),
          /* :: */[
            Css.unsafe("textOverflow", "ellipsis"),
            /* :: */[
              Css.unsafe("lineHeight", "1 !important"),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var actionsContainer = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.justifyContent(/* center */98248149),
        /* :: */[
          Css.alignItems(/* center */98248149),
          /* [] */0
        ]
      ]
    ]);

var actionButton = Css.style(/* :: */[
      Css.unsafe("color", "currentColor !important"),
      /* [] */0
    ]);

var actionButtonText = Css.style(/* :: */[
      Css.marginLeft(Css.px(7)),
      /* [] */0
    ]);

var disabledButton = Css.style(/* :: */[
      Css.unsafe("opacity", "0.3 !important"),
      /* [] */0
    ]);

var settingsDialog = Css.style(/* :: */[
      Css.unsafe("maxWidth", "250px !important"),
      /* :: */[
        Css.padding(Css.px(10)),
        /* [] */0
      ]
    ]);

var weighted = Css.style(/* :: */[
      Css.unsafe("color", "currentColor !important"),
      /* [] */0
    ]);

var activePeriod = Css.style(/* :: */[
      Css.unsafe("color", "#232323 !important"),
      /* [] */0
    ]);

var closeButton = Css.style(/* :: */[
      Css.unsafe("position", "absolute !important"),
      /* :: */[
        Css.top(Css.px(8)),
        /* :: */[
          Css.right(Css.px(8)),
          /* [] */0
        ]
      ]
    ]);

var link = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.accent)),
      /* [] */0
    ]);

var paper = Css.merge(/* :: */[
      BtPaper$BsConsole.mainViewMargins,
      /* [] */0
    ]);

var defaultFailure = I18N$BsConsole.get(undefined, "There was an error");

function parse(json, factor) {
  try {
    var message = Json_decode.field("message", Json_decode.string, Json_decode.field("error", Util$BsConsole.identity, json));
    return /* Failure */Block.__(1, [message]);
  }
  catch (exn){
    var columns;
    try {
      columns = Parse$BsConsole.parseColumns(json);
    }
    catch (exn$1){
      columns = undefined;
    }
    if (columns === undefined) {
      return /* Failure */Block.__(1, [defaultFailure]);
    }
    var rows;
    try {
      rows = Parse$BsConsole.decodeExn(/* tuple */[
            Curry._1(Crdb$BsConsole.Factor.toCrdbString, factor),
            undefined
          ], columns, json);
    }
    catch (raw_er){
      var er = Caml_js_exceptions.internalToOCamlException(raw_er);
      console.log(I18N$BsConsole.get(undefined, "Flamegraph distribution parse error"), er);
      rows = undefined;
    }
    var metadata = Parse$BsConsole.Aggregate.metadata(json);
    if (rows === undefined) {
      return /* Failure */Block.__(1, [defaultFailure]);
    }
    if (Belt_List.length(rows) <= 0) {
      return /* Success */Block.__(0, [/* tuple */[
                  {
                    keys: 0,
                    tail: undefined,
                    vals: /* [] */0
                  },
                  metadata
                ]]);
    }
    var group = Belt_List.get(rows, 0);
    if (group === undefined) {
      return /* Failure */Block.__(1, [defaultFailure]);
    }
    var getter = function (param, param$1) {
      return Group$BsConsole.getAggregation(columns, group, param, param$1);
    };
    var maybeDistribution = Group$BsConsole.unwrapDistribution(getter("callstack", /* Distribution */1));
    return /* Success */Block.__(0, [/* tuple */[
                maybeDistribution,
                metadata
              ]]);
  }
}

function toTask(query, token, name) {
  return Task$BsConsole.makeTask(/* CRDB */Block.__(2, [
                token,
                name,
                Crdb$BsConsole.QueryManager.toCrdb(query)
              ]));
}

function getTimes(param) {
  return param[1].times;
}

var component = ReasonReact.reducerComponentWithRetainedProps("ProjectFlamegraphView-BsConsole");

function make(handleTask, query, token, name, children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: (function (self) {
              return Curry._1(self.send, /* Request */0);
            }),
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (self) {
              return Curry._1(children, self.state.remote);
            }),
          initialState: (function (param) {
              return {
                      remote: /* NotAsked */0
                    };
            }),
          retainedProps: component.retainedProps,
          reducer: (function (action, state) {
              if (typeof action === "number") {
                return /* UpdateWithSideEffects */Block.__(2, [
                          {
                            remote: /* Loading */1
                          },
                          (function (self) {
                              return Curry._2(handleTask, (function (json) {
                                            var success = parse(TaskCallback$BsConsole.getJson(json), /* Custom */["None"]);
                                            if (success.tag) {
                                              return Curry._1(self.send, /* Failure */Block.__(1, [success[0]]));
                                            } else {
                                              return Curry._1(self.send, /* Success */Block.__(0, [success[0]]));
                                            }
                                          }), toTask(query, token, name));
                            })
                        ]);
              }
              if (action.tag) {
                return /* Update */Block.__(0, [{
                            remote: /* Failure */Block.__(1, [action[0]])
                          }]);
              }
              var response = action[0];
              MetricsEvent$BsConsole.send(/* Query */Block.__(4, [
                      "flamegraph",
                      query,
                      getTimes(response)
                    ]));
              return /* Update */Block.__(0, [{
                          remote: /* Success */Block.__(0, [response])
                        }]);
            }),
          jsElementWrapped: component.jsElementWrapped
        };
}

var FetchCallStackDistribution = {
  defaultFailure: defaultFailure,
  parse: parse,
  toTask: toTask,
  getTimes: getTimes,
  component: component,
  make: make
};

var component$1 = ReasonReact.statelessComponent("ProjectFlamegraphView-BsConsole");

function make$1(handleChangeUrl, remote, weighted, reversed, frame, setFrame, handleAddFilters, callstack, toggleWeighted, toggleReversed, focusNode, _children) {
  return {
          debugName: component$1.debugName,
          reactClassInternal: component$1.reactClassInternal,
          handedOffState: component$1.handedOffState,
          willReceiveProps: component$1.willReceiveProps,
          didMount: component$1.didMount,
          didUpdate: component$1.didUpdate,
          willUnmount: component$1.willUnmount,
          willUpdate: component$1.willUpdate,
          shouldUpdate: component$1.shouldUpdate,
          render: (function (_self) {
              var validData;
              if (typeof remote === "number" || remote.tag) {
                validData = false;
              } else {
                var data = remote[0][0];
                if (data !== undefined) {
                  var data$1 = Flamegraph$BsConsole.Data.ofDistribution(data, weighted, reversed);
                  validData = data$1.value > 0;
                } else {
                  validData = false;
                }
              }
              return ReasonReact.element(undefined, undefined, Curry._4(Mui$BsConsole.Toolbar.Jsx2.make, undefined, flamegraphToolbarStyle, true, [React.createElement(React.Fragment, undefined, React.createElement("div", {
                                        style: {
                                          display: "flex",
                                          margin: "0 7px",
                                          overflow: "hidden",
                                          alignContent: "baseline",
                                          flex: "5",
                                          flexWrap: "nowrap"
                                        }
                                      }, validData ? React.createElement(React.Fragment, undefined, React.createElement("div", {
                                                  style: {
                                                    lineHeight: "1",
                                                    marginRight: "7px",
                                                    whiteSpace: "nowrap"
                                                  }
                                                }, I18N$BsConsole.show(undefined, "selected frame:")), ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, React.createElement("span", {
                                                          className: Styles$BsConsole.$$Text.tooltipTitle
                                                        }, React.createElement("span", {
                                                              className: Styles$BsConsole.$$Text.tooltipTitle
                                                            }, I18N$BsConsole.show(undefined, frame))), undefined, undefined, undefined, undefined, undefined, [React.createElement("div", {
                                                            className: frameFont
                                                          }, I18N$BsConsole.show(undefined, frame))]))) : null), React.createElement("div", {
                                        className: actionsContainer + " smaller-icon"
                                      }, ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, React.createElement("span", {
                                                    className: Styles$BsConsole.$$Text.tooltipTitle
                                                  }, I18N$BsConsole.show(undefined, "filter to frame")), undefined, /* Top */4202101, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                          undefined,
                                                          actionButton,
                                                          (function (_e) {
                                                              MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* FlameGraph_FilterToFrame */26]));
                                                              Curry._1(setFrame, "root");
                                                              return Curry._1(handleChangeUrl, Curry._1(handleAddFilters, /* :: */[
                                                                              /* tuple */[
                                                                                "callstack",
                                                                                /* Contains */Block.__(2, [/* `String */[
                                                                                      -976970511,
                                                                                      frame
                                                                                    ]])
                                                                              ],
                                                                              /* [] */0
                                                                            ]));
                                                            }),
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          frame === "root",
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          {
                                                            disabled: disabledButton
                                                          },
                                                          [
                                                            ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.FilterList.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [])),
                                                            React.createElement("span", {
                                                                  className: actionButtonText
                                                                }, I18N$BsConsole.show(undefined, "Filter to frame"))
                                                          ]
                                                        ]))])), ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, React.createElement("span", {
                                                    className: Styles$BsConsole.$$Text.tooltipTitle
                                                  }, I18N$BsConsole.show(undefined, "filter against frame")), undefined, /* Top */4202101, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                          undefined,
                                                          actionButton,
                                                          (function (_e) {
                                                              MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* FlameGraph_FilterAgainstFrame */27]));
                                                              Curry._1(setFrame, "root");
                                                              return Curry._1(handleChangeUrl, Curry._1(handleAddFilters, /* :: */[
                                                                              /* tuple */[
                                                                                "callstack",
                                                                                /* NotContains */Block.__(3, [/* `String */[
                                                                                      -976970511,
                                                                                      frame
                                                                                    ]])
                                                                              ],
                                                                              /* [] */0
                                                                            ]));
                                                            }),
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          frame === "root",
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          {
                                                            disabled: disabledButton
                                                          },
                                                          [
                                                            React.createElement("div", {
                                                                  className: Css.style(/* :: */[
                                                                        Css.selector("svg", /* :: */[
                                                                              Css.height(Css.px(15)),
                                                                              /* [] */0
                                                                            ]),
                                                                        /* [] */0
                                                                      ])
                                                                }, ReasonReact.element(undefined, undefined, FilterOffIcon$BsConsole.make("currentColor", undefined, []))),
                                                            React.createElement("span", {
                                                                  className: actionButtonText
                                                                }, I18N$BsConsole.show(undefined, "Filter against frame"))
                                                          ]
                                                        ]))])), ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                undefined,
                                                actionButton,
                                                (function (_event) {
                                                    return MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* FlameGraph_CopyCallstack */28]));
                                                  }),
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                frame === "root",
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                undefined,
                                                {
                                                  disabled: disabledButton
                                                },
                                                [ReasonReact.element(undefined, undefined, CallstackCopyButton$BsConsole.make(/* Frames */Block.__(0, [callstack]), ReasonReact.element(undefined, undefined, Row$BsConsole.make(undefined, /* center */98248149, undefined, undefined, undefined, [
                                                                    ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Assignment.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [])),
                                                                    React.createElement("div", {
                                                                          className: actionButtonText,
                                                                          style: {
                                                                            lineHeight: "24px"
                                                                          }
                                                                        }, I18N$BsConsole.show(undefined, "Copy callstack"))
                                                                  ])), []))]
                                              ])), ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, React.createElement("span", {
                                                    className: Styles$BsConsole.$$Text.tooltipTitle
                                                  }, I18N$BsConsole.show(undefined, "reset zoom")), undefined, /* Top */4202101, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                          undefined,
                                                          actionButton,
                                                          (function (_e) {
                                                              var flamegraphContainer = document.querySelector(".flamegraph div");
                                                              if (!(flamegraphContainer == null)) {
                                                                flamegraphContainer.scrollTop = 0.0;
                                                                setTimeout((function (param) {
                                                                        var el$prime = flamegraphContainer.querySelector("g");
                                                                        if (el$prime == null) {
                                                                          return ;
                                                                        }
                                                                        var nodes = Array.prototype.slice.call(el$prime.querySelectorAll("rect"));
                                                                        var rect = Belt_Array.get(nodes, nodes.length - 1 | 0);
                                                                        if (rect === undefined) {
                                                                          return ;
                                                                        }
                                                                        var click = new UIEvent("click", {
                                                                              bubbles: true
                                                                            });
                                                                        Caml_option.valFromOption(rect).dispatchEvent(click);
                                                                        
                                                                      }), 200);
                                                                return ;
                                                              }
                                                              
                                                            }),
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          frame === "root",
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          undefined,
                                                          {
                                                            disabled: disabledButton
                                                          },
                                                          [
                                                            ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.ZoomOut.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [])),
                                                            React.createElement("span", {
                                                                  className: actionButtonText
                                                                }, I18N$BsConsole.show(undefined, "reset zoom"))
                                                          ]
                                                        ]))])), ReasonReact.element(undefined, undefined, Ui$BsConsole.Popover.make((function (refHander, onFocus, onClick) {
                                                  return React.createElement("div", {
                                                              ref: refHander,
                                                              onFocus: onFocus,
                                                              onClick: (function (e) {
                                                                  e.stopPropagation();
                                                                  
                                                                })
                                                            }, ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, React.createElement("span", {
                                                                          className: Styles$BsConsole.$$Text.tooltipTitle
                                                                        }, I18N$BsConsole.show(undefined, "Advanced settings")), undefined, /* Top */4202101, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.IconButton.Jsx2.make, [
                                                                                actionButton,
                                                                                undefined,
                                                                                onClick,
                                                                                undefined,
                                                                                undefined,
                                                                                undefined,
                                                                                undefined,
                                                                                undefined,
                                                                                undefined,
                                                                                undefined,
                                                                                "Advanced settings",
                                                                                [ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Settings.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))]
                                                                              ]))])));
                                                }), undefined, undefined, undefined, undefined, undefined, undefined, (function (onClose) {
                                                  var supportLink = Href$BsConsole.Support.make(undefined, /* GuideFlamegraphs */19);
                                                  return React.createElement("div", {
                                                              style: {
                                                                maxWidth: "300px",
                                                                position: "relative"
                                                              }
                                                            }, ReasonReact.element(undefined, undefined, Curry._4(Mui$BsConsole.CardHeader.Jsx2.make, undefined, Caml_option.some(I18N$BsConsole.show(undefined, "Settings")), undefined, [])), ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.IconButton.Jsx2.make, [
                                                                      closeButton,
                                                                      undefined,
                                                                      (function (param) {
                                                                          return Curry._1(onClose, undefined);
                                                                        }),
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      undefined,
                                                                      "Close",
                                                                      [ReasonReact.element(undefined, undefined, Curry._6(MuiIcons$BsConsole.Close.Jsx2.make, undefined, undefined, undefined, undefined, undefined, []))]
                                                                    ])), ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.CardContent.Jsx2.make, undefined, [
                                                                      ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Space_Between */959915471, undefined, undefined, /* Center */980392437, [
                                                                                ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Body1 */-904051921, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "weighted")])),
                                                                                ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, React.createElement("span", {
                                                                                              className: Styles$BsConsole.$$Text.tooltipTitle
                                                                                            }, Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                                                                                      /* String_literal */Block.__(11, [
                                                                                                          "view ",
                                                                                                          /* String */Block.__(2, [
                                                                                                              /* No_padding */0,
                                                                                                              /* String_literal */Block.__(11, [
                                                                                                                  " frames",
                                                                                                                  /* End_of_format */0
                                                                                                                ])
                                                                                                            ])
                                                                                                        ]),
                                                                                                      "view %s frames"
                                                                                                    ]), weighted ? I18N$BsConsole.get(undefined, "unweighted") : I18N$BsConsole.get(undefined, "weighted"))), undefined, /* Top */4202101, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Switch.Jsx2.make, !validData, /* Secondary */67972948, /* `Bool */[
                                                                                                    737456202,
                                                                                                    weighted
                                                                                                  ], (function (_event, param) {
                                                                                                      Curry._1(toggleWeighted, undefined);
                                                                                                      return Curry._1(focusNode, undefined);
                                                                                                    }), undefined, undefined, undefined, []))]))
                                                                              ])),
                                                                      ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Caption */-191243578, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "The width of frames in the graph are a function of the filtered errors containing the function")])),
                                                                      ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Space_Between */959915471, undefined, undefined, /* Center */980392437, [
                                                                                ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Body1 */-904051921, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "reverse frames")])),
                                                                                ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Tooltip.Jsx2.make, undefined, React.createElement("span", {
                                                                                              className: Styles$BsConsole.$$Text.tooltipTitle
                                                                                            }, I18N$BsConsole.show(undefined, "view reversed frames")), undefined, /* Top */4202101, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._8(Mui$BsConsole.Switch.Jsx2.make, !validData, /* Secondary */67972948, /* `Bool */[
                                                                                                    737456202,
                                                                                                    reversed
                                                                                                  ], (function (_event, param) {
                                                                                                      return Curry._1(toggleReversed, undefined);
                                                                                                    }), undefined, undefined, undefined, []))]))
                                                                              ])),
                                                                      ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Caption */-191243578, undefined, undefined, undefined, undefined, [I18N$BsConsole.show(undefined, "Frames are shown with a common root frame and then outer-most frames at the top and inner-most at the bottom of the flamegraph tips")])),
                                                                      React.createElement("br", undefined),
                                                                      supportLink !== undefined ? ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Body1 */-904051921, undefined, undefined, undefined, undefined, [
                                                                                  I18N$BsConsole.show(undefined, "Read more about flamegraphs "),
                                                                                  React.createElement("a", {
                                                                                        className: "link " + link,
                                                                                        href: supportLink,
                                                                                        rel: "noreferrer",
                                                                                        target: "_blank"
                                                                                      }, I18N$BsConsole.show(undefined, "here"))
                                                                                ])) : null
                                                                    ])));
                                                }), undefined, []))))]));
            }),
          initialState: component$1.initialState,
          retainedProps: component$1.retainedProps,
          reducer: component$1.reducer,
          jsElementWrapped: component$1.jsElementWrapped
        };
}

var FlameGraphToolbar = {
  component: component$1,
  make: make$1
};

function setRef(theRef, param) {
  param.state.chartRef.contents = (theRef == null) ? undefined : Caml_option.some(theRef);
  
}

var component$2 = ReasonReact.reducerComponent("ProjectFlamegraphView-BsConsole");

function make$2(config, aperture, handleTask, weighted, reversed, projectName, token, handleChangeUrl, handleAddFilters, route, _children) {
  return {
          debugName: component$2.debugName,
          reactClassInternal: component$2.reactClassInternal,
          handedOffState: component$2.handedOffState,
          willReceiveProps: component$2.willReceiveProps,
          didMount: component$2.didMount,
          didUpdate: component$2.didUpdate,
          willUnmount: component$2.willUnmount,
          willUpdate: component$2.willUpdate,
          shouldUpdate: component$2.shouldUpdate,
          render: (function (self) {
              var query_002 = Curry._2(Crdb$BsConsole.Fold.add, Curry._1(Crdb$BsConsole.Aggregation.create, /* tuple */[
                        "callstack",
                        /* Distribution */Block.__(1, [100])
                      ]), Crdb$BsConsole.Fold.empty);
              var query = /* Aggregate */Block.__(0, [
                  aperture,
                  undefined,
                  query_002,
                  undefined,
                  undefined,
                  undefined
                ]);
              return ReasonReact.element(JSON.stringify(Curry._1(Crdb$BsConsole.Query.toJson, query)) + "-fetch", undefined, make(handleTask, Curry._1(Crdb$BsConsole.Query.freeze, query), token, projectName, (function (remote) {
                                return ReasonReact.element(undefined, undefined, WindowSize$BsConsole.make((function (param, windowHeight) {
                                                  return ReasonReact.element(undefined, undefined, ContainerWithDimensions$BsConsole.make(undefined, (function (width, param) {
                                                                    var tmp;
                                                                    if (typeof remote === "number") {
                                                                      tmp = remote === /* NotAsked */0 ? null : ReasonReact.element(undefined, undefined, ViewLoading$BsConsole.make(I18N$BsConsole.get(undefined, "Fetching flame graph data"), []));
                                                                    } else if (remote.tag) {
                                                                      tmp = ReasonReact.element(undefined, undefined, Column$BsConsole.make(/* spaceAround */-485895757, /* center */98248149, undefined, undefined, Css.style(/* :: */[
                                                                                    Css.width(Css.pct(100)),
                                                                                    /* :: */[
                                                                                      Css.minHeight(Css.px(400)),
                                                                                      /* :: */[
                                                                                        Css.paddingBottom(Css.px(8)),
                                                                                        /* :: */[
                                                                                          Css.fontSize(Css.px(20)),
                                                                                          /* [] */0
                                                                                        ]
                                                                                      ]
                                                                                    ]
                                                                                  ]), [I18N$BsConsole.showSkip(remote[0])]));
                                                                    } else {
                                                                      var match = remote[0];
                                                                      var meta = match[1];
                                                                      var data = match[0];
                                                                      if (data !== undefined) {
                                                                        var data$1 = Flamegraph$BsConsole.Data.ofDistribution(data, self.state.weighted, self.state.reversed);
                                                                        if (data$1.value > 0) {
                                                                          var tail = data.tail;
                                                                          var tmp$1;
                                                                          if (tail !== undefined) {
                                                                            var totalShown = Belt_List.reduce(data.vals, 0, (function (acc, curr) {
                                                                                    return acc + curr[1] | 0;
                                                                                  }));
                                                                            var total = totalShown + tail | 0;
                                                                            var totalPct = Numeral$BsConsole.format("0.00%", Pervasives.string_of_float(totalShown / total));
                                                                            tmp$1 = React.createElement("div", {
                                                                                  className: totalCount
                                                                                }, ReasonReact.element(undefined, undefined, BtTypography$BsConsole.Re.make(/* Caption */11, undefined, caption, undefined, undefined, undefined, [Curry._1(I18N$BsConsole.showf(undefined, /* Format */[
                                                                                                    /* String_literal */Block.__(11, [
                                                                                                        "Showing distribution of top 100 callstacks - (",
                                                                                                        /* String */Block.__(2, [
                                                                                                            /* No_padding */0,
                                                                                                            /* String_literal */Block.__(11, [
                                                                                                                " of total)",
                                                                                                                /* End_of_format */0
                                                                                                              ])
                                                                                                          ])
                                                                                                      ]),
                                                                                                    "Showing distribution of top 100 callstacks - (%s of total)"
                                                                                                  ]), totalPct)])));
                                                                          } else {
                                                                            tmp$1 = null;
                                                                          }
                                                                          tmp = React.createElement(React.Fragment, undefined, tmp$1, React.createElement("div", {
                                                                                    className: "flamegraph"
                                                                                  }, ReasonReact.element(JSON.stringify(Curry._1(Crdb$BsConsole.Query.toJson, query)) + ("__" + (projectName + "-flamegraph")), Curry._1(self.handle, setRef), Flamegraph$BsConsole.make(data$1, windowHeight - 279 | 0, width - 15 | 0, (function (node, uid) {
                                                                                              MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* FlameGraph_FrameClick */29]));
                                                                                              Curry._1(self.send, /* SetFrame */Block.__(0, [node.name]));
                                                                                              Curry._1(self.send, /* SetCallstack */Block.__(4, [Flamegraph$BsConsole.Data.findNodePath(data$1, node)]));
                                                                                              return Curry._1(self.send, /* SetUid */Block.__(1, [uid]));
                                                                                            }), []))));
                                                                        } else {
                                                                          tmp = ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(Css.style(/* :: */[
                                                                                        Css.height(Css.vh(50)),
                                                                                        /* [] */0
                                                                                      ]), undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Center */980392437, undefined, undefined, /* Center */980392437, [ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, /* V4 */3, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [Caml_int64.gt(meta.tx, Caml_int64.one) ? ReasonReact.element(undefined, undefined, Fragment$BsConsole.make([
                                                                                                          ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Display1 */-11760689, undefined, undefined, undefined, undefined, [Text$BsConsole.get(/* Explore_Empty_Title */6)])),
                                                                                                          ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, undefined, [Text$BsConsole.get(/* Explore_Empty_Description */7)]))
                                                                                                        ])) : ReasonReact.element(undefined, undefined, Fragment$BsConsole.make([
                                                                                                          ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Display1 */-11760689, undefined, undefined, undefined, undefined, [Text$BsConsole.get(/* Welcome */8)])),
                                                                                                          ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, undefined, [Text$BsConsole.get(/* No_Crashes_Uploaded */9)])),
                                                                                                          ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Center */980392437, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                                                                              undefined,
                                                                                                                              undefined,
                                                                                                                              (function (_event) {
                                                                                                                                  return Curry._1(handleChangeUrl, /* ProjectSettingsIntegratedDocs */Block.__(28, [
                                                                                                                                                projectName,
                                                                                                                                                {
                                                                                                                                                  doc: undefined
                                                                                                                                                }
                                                                                                                                              ]));
                                                                                                                                }),
                                                                                                                              undefined,
                                                                                                                              undefined,
                                                                                                                              /* Outlined */-28821822,
                                                                                                                              undefined,
                                                                                                                              undefined,
                                                                                                                              undefined,
                                                                                                                              undefined,
                                                                                                                              undefined,
                                                                                                                              undefined,
                                                                                                                              undefined,
                                                                                                                              undefined,
                                                                                                                              undefined,
                                                                                                                              undefined,
                                                                                                                              [I18N$BsConsole.show(undefined, "Get started")]
                                                                                                                            ]))]))
                                                                                                        ]))]))]));
                                                                        }
                                                                      } else {
                                                                        tmp = ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(Css.style(/* :: */[
                                                                                      Css.height(Css.vh(50)),
                                                                                      /* [] */0
                                                                                    ]), undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Center */980392437, undefined, undefined, /* Center */980392437, [ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, true, undefined, undefined, /* V4 */3, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [Caml_int64.gt(meta.tx, Caml_int64.one) ? ReasonReact.element(undefined, undefined, Fragment$BsConsole.make([
                                                                                                        ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Display1 */-11760689, undefined, undefined, undefined, undefined, [Text$BsConsole.get(/* Explore_Empty_Title */6)])),
                                                                                                        ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, undefined, [Text$BsConsole.get(/* Explore_Empty_Description */7)]))
                                                                                                      ])) : ReasonReact.element(undefined, undefined, Fragment$BsConsole.make([
                                                                                                        ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Display1 */-11760689, undefined, undefined, undefined, undefined, [Text$BsConsole.get(/* Welcome */8)])),
                                                                                                        ReasonReact.element(undefined, undefined, Curry._7(Mui$BsConsole.Typography.Jsx2.make, undefined, /* Subheading */148169314, undefined, undefined, undefined, undefined, [Text$BsConsole.get(/* No_Crashes_Uploaded */9)])),
                                                                                                        ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, /* Center */980392437, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Button.Jsx2.make, [
                                                                                                                            undefined,
                                                                                                                            undefined,
                                                                                                                            (function (_event) {
                                                                                                                                return Curry._1(handleChangeUrl, /* ProjectSettingsIntegratedDocs */Block.__(28, [
                                                                                                                                              projectName,
                                                                                                                                              {
                                                                                                                                                doc: undefined
                                                                                                                                              }
                                                                                                                                            ]));
                                                                                                                              }),
                                                                                                                            undefined,
                                                                                                                            undefined,
                                                                                                                            /* Outlined */-28821822,
                                                                                                                            undefined,
                                                                                                                            undefined,
                                                                                                                            undefined,
                                                                                                                            undefined,
                                                                                                                            undefined,
                                                                                                                            undefined,
                                                                                                                            undefined,
                                                                                                                            undefined,
                                                                                                                            undefined,
                                                                                                                            undefined,
                                                                                                                            [I18N$BsConsole.show(undefined, "Get started")]
                                                                                                                          ]))]))
                                                                                                      ]))]))]));
                                                                      }
                                                                    }
                                                                    return React.createElement(React.Fragment, undefined, React.createElement("div", undefined, ReasonReact.element(undefined, undefined, make$1(handleChangeUrl, remote, self.state.weighted, self.state.reversed, self.state.frame, (function (frame) {
                                                                                              return Curry._1(self.send, /* SetFrame */Block.__(0, [frame]));
                                                                                            }), handleAddFilters, self.state.callstack, (function (param) {
                                                                                              return Curry._1(self.send, /* ToggleWeighted */0);
                                                                                            }), (function (param) {
                                                                                              return Curry._1(self.send, /* ToggleReversed */1);
                                                                                            }), (function (param) {
                                                                                              var match = self.state.chartRef.contents;
                                                                                              var match$1 = self.state.uid;
                                                                                              if (match === undefined) {
                                                                                                return ;
                                                                                              }
                                                                                              if (match$1 === undefined) {
                                                                                                return ;
                                                                                              }
                                                                                              var r = Caml_option.valFromOption(match);
                                                                                              setTimeout((function (param) {
                                                                                                      return r.focusNode(match$1);
                                                                                                    }), 300);
                                                                                              
                                                                                            }), []))), tmp);
                                                                  })));
                                                })));
                              })));
            }),
          initialState: (function (param) {
              return {
                      callstack: [],
                      frame: "root",
                      weighted: weighted,
                      reversed: reversed,
                      chartRef: {
                        contents: undefined
                      },
                      uid: undefined
                    };
            }),
          retainedProps: component$2.retainedProps,
          reducer: (function (action, state) {
              if (typeof action === "number") {
                if (action === /* ToggleWeighted */0) {
                  return /* UpdateWithSideEffects */Block.__(2, [
                            {
                              callstack: state.callstack,
                              frame: state.frame,
                              weighted: !state.weighted,
                              reversed: state.reversed,
                              chartRef: state.chartRef,
                              uid: state.uid
                            },
                            (function (_self) {
                                return Curry._1(handleChangeUrl, /* ProjectFlamegraph */Block.__(20, [
                                              projectName,
                                              {
                                                aperture: aperture,
                                                weighted: !state.weighted,
                                                reversed: state.reversed
                                              }
                                            ]));
                              })
                          ]);
                } else {
                  return /* UpdateWithSideEffects */Block.__(2, [
                            {
                              callstack: state.callstack,
                              frame: state.frame,
                              weighted: state.weighted,
                              reversed: !state.reversed,
                              chartRef: state.chartRef,
                              uid: state.uid
                            },
                            (function (_self) {
                                return Curry._1(handleChangeUrl, /* ProjectFlamegraph */Block.__(20, [
                                              projectName,
                                              {
                                                aperture: aperture,
                                                weighted: state.weighted,
                                                reversed: !state.reversed
                                              }
                                            ]));
                              })
                          ]);
                }
              }
              switch (action.tag | 0) {
                case /* SetFrame */0 :
                    return /* Update */Block.__(0, [{
                                callstack: state.callstack,
                                frame: action[0],
                                weighted: state.weighted,
                                reversed: state.reversed,
                                chartRef: state.chartRef,
                                uid: state.uid
                              }]);
                case /* SetUid */1 :
                    return /* Update */Block.__(0, [{
                                callstack: state.callstack,
                                frame: state.frame,
                                weighted: state.weighted,
                                reversed: state.reversed,
                                chartRef: state.chartRef,
                                uid: action[0]
                              }]);
                case /* SetTimeAperture */2 :
                    var ta = action[0];
                    return /* SideEffects */Block.__(1, [(function (_self) {
                                  return Curry._1(handleChangeUrl, /* ProjectFlamegraph */Block.__(20, [
                                                projectName,
                                                {
                                                  aperture: Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, ta, aperture),
                                                  weighted: state.weighted,
                                                  reversed: state.reversed
                                                }
                                              ]));
                                })]);
                case /* SetFilters */3 :
                    var filterMap = action[0];
                    return /* SideEffects */Block.__(1, [(function (_self) {
                                  return Curry._1(handleChangeUrl, /* ProjectFlamegraph */Block.__(20, [
                                                projectName,
                                                {
                                                  aperture: Curry._2(Crdb$BsConsole.Aperture.setFilters, filterMap, aperture),
                                                  weighted: state.weighted,
                                                  reversed: state.reversed
                                                }
                                              ]));
                                })]);
                case /* SetCallstack */4 :
                    return /* Update */Block.__(0, [{
                                callstack: action[0],
                                frame: state.frame,
                                weighted: state.weighted,
                                reversed: state.reversed,
                                chartRef: state.chartRef,
                                uid: state.uid
                              }]);
                
              }
            }),
          jsElementWrapped: component$2.jsElementWrapped
        };
}

var make$3 = ReasonReactCompat.wrapReasonReactForReact(component$2, (function (r) {
        return make$2(r.config, r.aperture, r.handleTask, r.weighted, r.reversed, r.projectName, r.token, r.handleChangeUrl, r.handleAddFilters, r.route, []);
      }));

var Jsx3 = {
  make: make$3
};

var RR;

exports.RR = RR;
exports.loadingContainer = loadingContainer;
exports.totalCount = totalCount;
exports.caption = caption;
exports.flamegraphToolbarStyle = flamegraphToolbarStyle;
exports.flamegraphFrame = flamegraphFrame;
exports.frameFont = frameFont;
exports.actionsContainer = actionsContainer;
exports.actionButton = actionButton;
exports.actionButtonText = actionButtonText;
exports.disabledButton = disabledButton;
exports.settingsDialog = settingsDialog;
exports.weighted = weighted;
exports.activePeriod = activePeriod;
exports.closeButton = closeButton;
exports.link = link;
exports.paper = paper;
exports.FetchCallStackDistribution = FetchCallStackDistribution;
exports.FlameGraphToolbar = FlameGraphToolbar;
exports.setRef = setRef;
exports.component = component$2;
exports.make = make$2;
exports.Jsx3 = Jsx3;
/* loadingContainer Not a pure module */
