// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var I18N$BsConsole = require("../I18N.js");
var BtTypography$BsConsole = require("../BtTypography.js");
var BreadcrumbUtils$BsConsole = require("./BreadcrumbUtils.js");
var BreadcrumbStyles$BsConsole = require("./BreadcrumbStyles.js");
var BreadcrumbDivider$BsConsole = require("./BreadcrumbDivider.js");

function Level3Breadcrumb$Crumb(Props) {
  var maybeErrorId = Props.maybeErrorId;
  var route = Props.route;
  var maybeCurrentView = Props.maybeCurrentView;
  var level3RouteName = BreadcrumbUtils$BsConsole.currentRouteToString(route, maybeCurrentView);
  var match = BreadcrumbUtils$BsConsole.currentRouteToType(route, maybeCurrentView);
  if (match !== 2 || maybeErrorId === undefined) {
    return null;
  } else {
    return React.createElement(React.Fragment, undefined, React.createElement(BreadcrumbDivider$BsConsole.make, { }), React.createElement(BtTypography$BsConsole.make, {
                    variant: /* Body1 */8,
                    className: BreadcrumbStyles$BsConsole.crumb,
                    children: Curry._2(I18N$BsConsole.showf(undefined, /* Format */[
                              /* String */Block.__(2, [
                                  /* No_padding */0,
                                  /* String_literal */Block.__(11, [
                                      " (",
                                      /* String */Block.__(2, [
                                          /* No_padding */0,
                                          /* Char_literal */Block.__(12, [
                                              /* ")" */41,
                                              /* End_of_format */0
                                            ])
                                        ])
                                    ])
                                ]),
                              "%s (%s)"
                            ]), level3RouteName, maybeErrorId)
                  }));
  }
}

var Crumb = {
  make: Level3Breadcrumb$Crumb
};

exports.Crumb = Crumb;
/* react Not a pure module */
