// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("bs-platform/lib/js/curry.js");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var ReasonReact = require("reason-react/lib/js/src/ReasonReact.js");
var Mui$BsConsole = require("./bindings/Mui.js");
var I18N$BsConsole = require("./I18N.js");
var I18N_config$BsConsole = require("./I18N_config.js");

var component = ReasonReact.statelessComponent("Settings_i18n-BsConsole__ChooseLanguageDialog");

function setOrClearLanguage(maybeLanguageCodeVariant, token, config, cb) {
  if (maybeLanguageCodeVariant !== undefined) {
    I18N_config$BsConsole.SetUserLanguage.set(maybeLanguageCodeVariant, token, config, cb);
  } else {
    I18N_config$BsConsole.ClearUserLanguage.clear(token, config, cb);
  }
  return I18N_config$BsConsole.GetUserLanguage.get(config);
}

function emptyStringAsNone(string) {
  if (string.length === 0) {
    return ;
  } else {
    return string;
  }
}

function handleChange(languageCode, token, config) {
  I18N$BsConsole.$$File.load(setOrClearLanguage(Belt_Option.flatMap(languageCode, I18N$BsConsole.LanguageCode.fromISO639), token, config, (function (param) {
              window.location.reload(true);
              
            })));
  
}

function getLanguageList(param) {
  var first_001 = I18N$BsConsole.show(undefined, "Use browser language setting");
  var first = /* tuple */[
    "",
    first_001
  ];
  return /* :: */[
          first,
          I18N$BsConsole.LanguageCode.map((function (l) {
                  return /* tuple */[
                          I18N$BsConsole.LanguageCode.toISO639(l),
                          I18N$BsConsole.showSkip(I18N$BsConsole.LanguageCode.toString(l))
                        ];
                }))
        ];
}

function make(token, config, onClose, _children) {
  return {
          debugName: component.debugName,
          reactClassInternal: component.reactClassInternal,
          handedOffState: component.handedOffState,
          willReceiveProps: component.willReceiveProps,
          didMount: component.didMount,
          didUpdate: component.didUpdate,
          willUnmount: component.willUnmount,
          willUpdate: component.willUpdate,
          shouldUpdate: component.shouldUpdate,
          render: (function (_self) {
              return ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Dialog.Jsx2.make, [
                              undefined,
                              true,
                              onClose,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              undefined,
                              [ReasonReact.element(undefined, undefined, Mui$BsConsole.Grid.make(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, [
                                          ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogTitle.Jsx2.make, undefined, [I18N$BsConsole.show(undefined, "Language Selection")])),
                                          ReasonReact.element(undefined, undefined, Curry._3(Mui$BsConsole.DialogContent.Jsx2.make, undefined, undefined, [
                                                    ReasonReact.element(undefined, undefined, Curry._2(Mui$BsConsole.DialogContentText.Jsx2.make, undefined, [I18N$BsConsole.show(undefined, "Choose your preferred language")])),
                                                    ReasonReact.element(undefined, undefined, Curry._6(Mui$BsConsole.FormControl.Jsx2.make, undefined, undefined, undefined, undefined, undefined, [ReasonReact.element(undefined, undefined, Curry._5(Mui$BsConsole.RadioGroup.Jsx2.make, undefined, I18N_config$BsConsole.GetUserLanguage.getStringNoDefault(config), (function (_event, value) {
                                                                          return handleChange(emptyStringAsNone(value), token, config);
                                                                        }), undefined, [Belt_List.map(getLanguageList(undefined), (function (param) {
                                                                                var value = param[0];
                                                                                return ReasonReact.element(value, undefined, Curry.app(Mui$BsConsole.FormControlLabel.Jsx2.make, [
                                                                                                value,
                                                                                                Caml_option.some(param[1]),
                                                                                                Caml_option.some(ReasonReact.element(undefined, undefined, Curry.app(Mui$BsConsole.Radio.Jsx2.make, [
                                                                                                              undefined,
                                                                                                              undefined,
                                                                                                              undefined,
                                                                                                              undefined,
                                                                                                              undefined,
                                                                                                              undefined,
                                                                                                              undefined,
                                                                                                              undefined,
                                                                                                              []
                                                                                                            ]))),
                                                                                                undefined,
                                                                                                undefined,
                                                                                                undefined,
                                                                                                undefined,
                                                                                                undefined,
                                                                                                []
                                                                                              ]));
                                                                              }))]))]))
                                                  ]))
                                        ]))]
                            ]));
            }),
          initialState: component.initialState,
          retainedProps: component.retainedProps,
          reducer: component.reducer,
          jsElementWrapped: component.jsElementWrapped
        };
}

var ChooseLanguageDialog = {
  component: component,
  setOrClearLanguage: setOrClearLanguage,
  emptyStringAsNone: emptyStringAsNone,
  handleChange: handleChange,
  getLanguageList: getLanguageList,
  make: make
};

exports.ChooseLanguageDialog = ChooseLanguageDialog;
/* component Not a pure module */
