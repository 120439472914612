// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var React = require("react");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var RR$BsConsole = require("./RR.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var Add = require("@material-ui/icons/Add");
var Chat = require("@material-ui/icons/Chat");
var Edit = require("@material-ui/icons/Edit");
var Flag = require("@material-ui/icons/Flag");
var Help = require("@material-ui/icons/Help");
var Http = require("@material-ui/icons/Http");
var Info = require("@material-ui/icons/Info");
var Lens = require("@material-ui/icons/Lens");
var Link = require("@material-ui/icons/Link");
var List = require("@material-ui/icons/List");
var Lock = require("@material-ui/icons/Lock");
var Save = require("@material-ui/icons/Save");
var Sync = require("@material-ui/icons/Sync");
var Tune = require("@material-ui/icons/Tune");
var Build = require("@material-ui/icons/Build");
var Check = require("@material-ui/icons/Check");
var Clear = require("@material-ui/icons/Clear");
var Close = require("@material-ui/icons/Close");
var $$Error = require("@material-ui/icons/Error");
var Https = require("@material-ui/icons/Https");
var Label = require("@material-ui/icons/Label");
var SvgIcon = require("@material-ui/core/SvgIcon");
var Cancel = require("@material-ui/icons/Cancel");
var Delete = require("@material-ui/icons/Delete");
var GetApp = require("@material-ui/icons/GetApp");
var Launch = require("@material-ui/icons/Launch");
var Person = require("@material-ui/icons/Person");
var Repeat = require("@material-ui/icons/Repeat");
var Search = require("@material-ui/icons/Search");
var Update = require("@material-ui/icons/Update");
var VpnKey = require("@material-ui/icons/VpnKey");
var Devices = require("@material-ui/icons/Devices");
var Publish = require("@material-ui/icons/Publish");
var Receipt = require("@material-ui/icons/Receipt");
var $$Storage = require("@material-ui/icons/Storage");
var Warning = require("@material-ui/icons/Warning");
var ZoomOut = require("@material-ui/icons/ZoomOut");
var MoreVert = require("@material-ui/icons/MoreVert");
var Settings = require("@material-ui/icons/Settings");
var AddCircle = require("@material-ui/icons/AddCircle");
var BugReport = require("@material-ui/icons/BugReport");
var CallSplit = require("@material-ui/icons/CallSplit");
var MergeType = require("@material-ui/icons/MergeType");
var MoreHoriz = require("@material-ui/icons/MoreHoriz");
var OpenInNew = require("@material-ui/icons/OpenInNew");
var PersonAdd = require("@material-ui/icons/PersonAdd");
var AccessTime = require("@material-ui/icons/AccessTime");
var Assignment = require("@material-ui/icons/Assignment");
var AttachFile = require("@material-ui/icons/AttachFile");
var DragHandle = require("@material-ui/icons/DragHandle");
var ExpandLess = require("@material-ui/icons/ExpandLess");
var ExpandMore = require("@material-ui/icons/ExpandMore");
var FilterList = require("@material-ui/icons/FilterList");
var LocalOffer = require("@material-ui/icons/LocalOffer");
var Visibility = require("@material-ui/icons/Visibility");
var ArrowDropUp = require("@material-ui/icons/ArrowDropUp");
var ArrowUpward = require("@material-ui/icons/ArrowUpward");
var CheckCircle = require("@material-ui/icons/CheckCircle");
var ChevronLeft = require("@material-ui/icons/ChevronLeft");
var CloudUpload = require("@material-ui/icons/CloudUpload");
var Description = require("@material-ui/icons/Description");
var Fingerprint = require("@material-ui/icons/Fingerprint");
var HelpOutline = require("@material-ui/icons/HelpOutline");
var ViewCompact = require("@material-ui/icons/ViewCompact");
var ArrowForward = require("@material-ui/icons/ArrowForward");
var ChevronRight = require("@material-ui/icons/ChevronRight");
var ErrorOutline = require("@material-ui/icons/ErrorOutline");
var PriorityHigh = require("@material-ui/icons/PriorityHigh");
var AccountCircle = require("@material-ui/icons/AccountCircle");
var ArrowDownward = require("@material-ui/icons/ArrowDownward");
var ArrowDropDown = require("@material-ui/icons/ArrowDropDown");
var CompareArrows = require("@material-ui/icons/CompareArrows");
var DeleteForever = require("@material-ui/icons/DeleteForever");
var Notifications = require("@material-ui/icons/Notifications");
var VisibilityOff = require("@material-ui/icons/VisibilityOff");
var KeyboardArrowUp = require("@material-ui/icons/KeyboardArrowUp");
var AddCircleOutline = require("@material-ui/icons/AddCircleOutline");
var FiberSmartRecord = require("@material-ui/icons/FiberSmartRecord");
var CenterFocusStrong = require("@material-ui/icons/CenterFocusStrong");
var KeyboardArrowDown = require("@material-ui/icons/KeyboardArrowDown");
var KeyboardArrowLeft = require("@material-ui/icons/KeyboardArrowLeft");
var KeyboardBackspace = require("@material-ui/icons/KeyboardBackspace");
var KeyboardArrowRight = require("@material-ui/icons/KeyboardArrowRight");
var RadioButtonUnchecked = require("@material-ui/icons/RadioButtonUnchecked");

var component = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(ErrorOutline.default, tmp, children);
}

var Jsx2 = {
  component: component,
  make: make
};

var ErrorOutline$1 = {
  Jsx2: Jsx2
};

var component$1 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$1(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(Flag.default, tmp, children);
}

var Jsx2$1 = {
  component: component$1,
  make: make$1
};

var Flag$1 = {
  Jsx2: Jsx2$1
};

function MuiIcons$FlagOutline(Props) {
  var className = Props.className;
  var color = Props.color;
  var fontSize = Props.fontSize;
  var shapeRendering = Props.shapeRendering;
  var tmp = {
    children: null
  };
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  return React.createElement(SvgIcon.default, tmp, React.createElement("path", {
                  d: "M0 0h24v24H0z",
                  fill: "none"
                }), React.createElement("path", {
                  d: "M14 6l-1-2H5v17h2v-7h5l1 2h7V6h-6zm4 8h-4l-1-2H7V6h5l1 2h5v6z"
                }));
}

var FlagOutline = {
  make: MuiIcons$FlagOutline
};

var component$2 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$2(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(Warning.default, tmp, children);
}

var Jsx2$2 = {
  component: component$2,
  make: make$2
};

var Warning$1 = {
  Jsx2: Jsx2$2
};

var component$3 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$3(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact($$Storage.default, tmp, children);
}

var Jsx2$3 = {
  component: component$3,
  make: make$3
};

var $$Storage$1 = {
  Jsx2: Jsx2$3
};

var component$4 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$4(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(Visibility.default, tmp, children);
}

var Jsx2$4 = {
  component: component$4,
  make: make$4
};

var Visibility$1 = {
  Jsx2: Jsx2$4
};

var component$5 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$5(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(BugReport.default, tmp, children);
}

var Jsx2$5 = {
  component: component$5,
  make: make$5
};

var BugReport$1 = {
  Jsx2: Jsx2$5
};

function MuiIcons$AutoAwesome(Props) {
  var className = Props.className;
  var color = Props.color;
  var fontSize = Props.fontSize;
  var shapeRendering = Props.shapeRendering;
  var tmp = {
    viewBox: "0 0 24 24",
    children: null
  };
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  return React.createElement(SvgIcon.default, tmp, React.createElement("path", {
                  d: "M19,9 L20.25,6.25 L23,5 L20.25,3.75 L19,1 L17.75,3.75 L15,5 L17.75,6.25 Z"
                }), React.createElement("path", {
                  d: "M11.5,9.5L9,4L6.5,9.5L1,12l5.5,2.5L9,20l2.5-5.5L17,12L11.5,9.5z"
                }));
}

var AutoAwesome = {
  make: MuiIcons$AutoAwesome
};

var component$6 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$6(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(ArrowDropDown.default, tmp, children);
}

var Jsx2$6 = {
  component: component$6,
  make: make$6
};

var ArrowDropDown$1 = {
  Jsx2: Jsx2$6
};

var component$7 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$7(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(ArrowDropUp.default, tmp, children);
}

var Jsx2$7 = {
  component: component$7,
  make: make$7
};

var ArrowDropUp$1 = {
  Jsx2: Jsx2$7
};

var component$8 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$8(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(Update.default, tmp, children);
}

var Jsx2$8 = {
  component: component$8,
  make: make$8
};

var Update$1 = {
  Jsx2: Jsx2$8
};

var Inbox = { };

var component$9 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$9(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(AccountCircle.default, tmp, children);
}

var Jsx2$9 = {
  component: component$9,
  make: make$9
};

var AccountCircle$1 = {
  Jsx2: Jsx2$9
};

var component$10 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$10(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(Receipt.default, tmp, children);
}

var Jsx2$10 = {
  component: component$10,
  make: make$10
};

var Receipt$1 = {
  Jsx2: Jsx2$10
};

var component$11 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$11(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(LocalOffer.default, tmp, children);
}

var Jsx2$11 = {
  component: component$11,
  make: make$11
};

var LocalOffer$1 = {
  Jsx2: Jsx2$11
};

var component$12 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$12(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(CenterFocusStrong.default, tmp, children);
}

var Jsx2$12 = {
  component: component$12,
  make: make$12
};

var CenterFocusStrong$1 = {
  Jsx2: Jsx2$12
};

var component$13 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$13(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(AttachFile.default, tmp, children);
}

var Jsx2$13 = {
  component: component$13,
  make: make$13
};

var AttachFile$1 = {
  Jsx2: Jsx2$13
};

var component$14 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$14(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(GetApp.default, tmp, children);
}

var Jsx2$14 = {
  component: component$14,
  make: make$14
};

var GetApp$1 = {
  Jsx2: Jsx2$14
};

var component$15 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$15(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(Chat.default, tmp, children);
}

var Jsx2$15 = {
  component: component$15,
  make: make$15
};

var Chat$1 = {
  Jsx2: Jsx2$15
};

var component$16 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$16(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(KeyboardArrowLeft.default, tmp, children);
}

var Jsx2$16 = {
  component: component$16,
  make: make$16
};

var KeyboardArrowLeft$1 = {
  Jsx2: Jsx2$16
};

var component$17 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$17(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(KeyboardArrowRight.default, tmp, children);
}

var Jsx2$17 = {
  component: component$17,
  make: make$17
};

var KeyboardArrowRight$1 = {
  Jsx2: Jsx2$17
};

var component$18 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$18(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(ArrowForward.default, tmp, children);
}

var Jsx2$18 = {
  component: component$18,
  make: make$18
};

var ArrowRight = {
  Jsx2: Jsx2$18
};

var component$19 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$19(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(ArrowDownward.default, tmp, children);
}

var Jsx2$19 = {
  component: component$19,
  make: make$19
};

var ArrowDownward$1 = {
  Jsx2: Jsx2$19
};

var component$20 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$20(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(ArrowUpward.default, tmp, children);
}

var Jsx2$20 = {
  component: component$20,
  make: make$20
};

var ArrowUpward$1 = {
  Jsx2: Jsx2$20
};

var component$21 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$21(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(KeyboardArrowDown.default, tmp, children);
}

var Jsx2$21 = {
  component: component$21,
  make: make$21
};

var KeyboardArrowDown$1 = {
  Jsx2: Jsx2$21
};

var component$22 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$22(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(KeyboardArrowUp.default, tmp, children);
}

var Jsx2$22 = {
  component: component$22,
  make: make$22
};

var KeyboardArrowUp$1 = {
  Jsx2: Jsx2$22
};

var component$23 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$23(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(Sync.default, tmp, children);
}

var Jsx2$23 = {
  component: component$23,
  make: make$23
};

var Sync$1 = {
  Jsx2: Jsx2$23
};

var component$24 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$24(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(Link.default, tmp, children);
}

var Jsx2$24 = {
  component: component$24,
  make: make$24
};

var Link$1 = {
  Jsx2: Jsx2$24
};

var component$25 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$25(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(MoreVert.default, tmp, children);
}

var Jsx2$25 = {
  component: component$25,
  make: make$25
};

var MoreVert$1 = {
  Jsx2: Jsx2$25
};

var component$26 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$26(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(MoreHoriz.default, tmp, children);
}

var Jsx2$26 = {
  component: component$26,
  make: make$26
};

var MoreHoriz$1 = {
  Jsx2: Jsx2$26
};

var component$27 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$27(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(Label.default, tmp, children);
}

var Jsx2$27 = {
  component: component$27,
  make: make$27
};

var Label$1 = {
  Jsx2: Jsx2$27
};

var component$28 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$28(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(PersonAdd.default, tmp, children);
}

var Jsx2$28 = {
  component: component$28,
  make: make$28
};

var PersonAdd$1 = {
  Jsx2: Jsx2$28
};

var component$29 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$29(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(Person.default, tmp, children);
}

var Jsx2$29 = {
  component: component$29,
  make: make$29
};

var Person$1 = {
  Jsx2: Jsx2$29
};

var component$30 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$30(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(ArrowForward.default, tmp, children);
}

var Jsx2$30 = {
  component: component$30,
  make: make$30
};

var ArrowForward$1 = {
  Jsx2: Jsx2$30
};

var component$31 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$31(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(Help.default, tmp, children);
}

var Jsx2$31 = {
  component: component$31,
  make: make$31
};

var Help$1 = {
  Jsx2: Jsx2$31
};

var component$32 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$32(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(HelpOutline.default, tmp, children);
}

var Jsx2$32 = {
  component: component$32,
  make: make$32
};

var HelpOutline$1 = {
  Jsx2: Jsx2$32
};

var component$33 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$33(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(Close.default, tmp, children);
}

var Jsx2$33 = {
  component: component$33,
  make: make$33
};

var Close$1 = {
  Jsx2: Jsx2$33
};

var component$34 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$34(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(Cancel.default, tmp, children);
}

var Jsx2$34 = {
  component: component$34,
  make: make$34
};

var Cancel$1 = {
  Jsx2: Jsx2$34
};

var component$35 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$35(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(FilterList.default, tmp, children);
}

var Jsx2$35 = {
  component: component$35,
  make: make$35
};

var FilterList$1 = {
  Jsx2: Jsx2$35
};

var component$36 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$36(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(Assignment.default, tmp, children);
}

var Jsx2$36 = {
  component: component$36,
  make: make$36
};

var Assignment$1 = {
  Jsx2: Jsx2$36
};

var component$37 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$37(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(Edit.default, tmp, children);
}

var Jsx2$37 = {
  component: component$37,
  make: make$37
};

var Edit$1 = {
  Jsx2: Jsx2$37
};

var component$38 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$38(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(Delete.default, tmp, children);
}

var Jsx2$38 = {
  component: component$38,
  make: make$38
};

var Delete$1 = {
  Jsx2: Jsx2$38
};

var component$39 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$39(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(DeleteForever.default, tmp, children);
}

var Jsx2$39 = {
  component: component$39,
  make: make$39
};

var DeleteForever$1 = {
  Jsx2: Jsx2$39
};

var component$40 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$40(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(GetApp.default, tmp, children);
}

var Jsx2$40 = {
  component: component$40,
  make: make$40
};

var Download = {
  Jsx2: Jsx2$40
};

var component$41 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$41(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(AttachFile.default, tmp, children);
}

var Jsx2$41 = {
  component: component$41,
  make: make$41
};

var Paperclip = {
  Jsx2: Jsx2$41
};

var component$42 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$42(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(CompareArrows.default, tmp, children);
}

var Jsx2$42 = {
  component: component$42,
  make: make$42
};

var CompareArrows$1 = {
  Jsx2: Jsx2$42
};

var component$43 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$43(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(AddCircle.default, tmp, children);
}

var Jsx2$43 = {
  component: component$43,
  make: make$43
};

var AddCircle$1 = {
  Jsx2: Jsx2$43
};

var component$44 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$44(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(AddCircleOutline.default, tmp, children);
}

var Jsx2$44 = {
  component: component$44,
  make: make$44
};

var AddCircleOutline$1 = {
  Jsx2: Jsx2$44
};

var component$45 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$45(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(Add.default, tmp, children);
}

var Jsx2$45 = {
  component: component$45,
  make: make$45
};

var Add$1 = {
  Jsx2: Jsx2$45
};

var component$46 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$46(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(Info.default, tmp, children);
}

var Jsx2$46 = {
  component: component$46,
  make: make$46
};

var Info$1 = {
  Jsx2: Jsx2$46
};

var component$47 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$47(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(Clear.default, tmp, children);
}

var Jsx2$47 = {
  component: component$47,
  make: make$47
};

var Clear$1 = {
  Jsx2: Jsx2$47
};

var component$48 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$48(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(Tune.default, tmp, children);
}

var Jsx2$48 = {
  component: component$48,
  make: make$48
};

var Tune$1 = {
  Jsx2: Jsx2$48
};

var component$49 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$49(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(OpenInNew.default, tmp, children);
}

var Jsx2$49 = {
  component: component$49,
  make: make$49
};

var OpenInNew$1 = {
  Jsx2: Jsx2$49
};

var component$50 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$50(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(ExpandMore.default, tmp, children);
}

var Jsx2$50 = {
  component: component$50,
  make: make$50
};

var ExpandMore$1 = {
  Jsx2: Jsx2$50
};

var component$51 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$51(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(Publish.default, tmp, children);
}

var Jsx2$51 = {
  component: component$51,
  make: make$51
};

var Publish$1 = {
  Jsx2: Jsx2$51
};

var component$52 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$52(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(CheckCircle.default, tmp, children);
}

var Jsx2$52 = {
  component: component$52,
  make: make$52
};

var CheckCircle$1 = {
  Jsx2: Jsx2$52
};

var component$53 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$53(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(Build.default, tmp, children);
}

var Jsx2$53 = {
  component: component$53,
  make: make$53
};

var Build$1 = {
  Jsx2: Jsx2$53
};

var component$54 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$54(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(Description.default, tmp, children);
}

var Jsx2$54 = {
  component: component$54,
  make: make$54
};

var Description$1 = {
  Jsx2: Jsx2$54
};

var component$55 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$55(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(Lens.default, tmp, children);
}

var Jsx2$55 = {
  component: component$55,
  make: make$55
};

var Lens$1 = {
  Jsx2: Jsx2$55
};

var component$56 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$56(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(Launch.default, tmp, children);
}

var Jsx2$56 = {
  component: component$56,
  make: make$56
};

var Launch$1 = {
  Jsx2: Jsx2$56
};

var component$57 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$57(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(Lock.default, tmp, children);
}

var Jsx2$57 = {
  component: component$57,
  make: make$57
};

var Lock$1 = {
  Jsx2: Jsx2$57
};

var component$58 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$58(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(Search.default, tmp, children);
}

var Jsx2$58 = {
  component: component$58,
  make: make$58
};

var Search$1 = {
  Jsx2: Jsx2$58
};

var component$59 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$59(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(Notifications.default, tmp, children);
}

var Jsx2$59 = {
  component: component$59,
  make: make$59
};

var Notifications$1 = {
  Jsx2: Jsx2$59
};

var component$60 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$60(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(ChevronLeft.default, tmp, children);
}

var Jsx2$60 = {
  component: component$60,
  make: make$60
};

var ChevronLeft$1 = {
  Jsx2: Jsx2$60
};

var component$61 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$61(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(ChevronRight.default, tmp, children);
}

var Jsx2$61 = {
  component: component$61,
  make: make$61
};

var ChevronRight$1 = {
  Jsx2: Jsx2$61
};

var component$62 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$62(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(RadioButtonUnchecked.default, tmp, children);
}

var Jsx2$62 = {
  component: component$62,
  make: make$62
};

var RadioButtonUnchecked$1 = {
  Jsx2: Jsx2$62
};

var component$63 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$63(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(ZoomOut.default, tmp, children);
}

var Jsx2$63 = {
  component: component$63,
  make: make$63
};

var ZoomOut$1 = {
  Jsx2: Jsx2$63
};

var component$64 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$64(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(Settings.default, tmp, children);
}

var Jsx2$64 = {
  component: component$64,
  make: make$64
};

var Settings$1 = {
  Jsx2: Jsx2$64
};

var component$65 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$65(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(DragHandle.default, tmp, children);
}

var Jsx2$65 = {
  component: component$65,
  make: make$65
};

var DragHandle$1 = {
  Jsx2: Jsx2$65
};

var component$66 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$66(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(ExpandLess.default, tmp, children);
}

var Jsx2$66 = {
  component: component$66,
  make: make$66
};

var ExpandLess$1 = {
  Jsx2: Jsx2$66
};

var component$67 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$67(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(VpnKey.default, tmp, children);
}

var Jsx2$67 = {
  component: component$67,
  make: make$67
};

var VpnKey$1 = {
  Jsx2: Jsx2$67
};

var component$68 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$68(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(KeyboardBackspace.default, tmp, children);
}

var Jsx2$68 = {
  component: component$68,
  make: make$68
};

var KeyboardBackspace$1 = {
  Jsx2: Jsx2$68
};

var component$69 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$69(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(PriorityHigh.default, tmp, children);
}

var Jsx2$69 = {
  component: component$69,
  make: make$69
};

var PriorityHigh$1 = {
  Jsx2: Jsx2$69
};

var component$70 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$70(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(Http.default, tmp, children);
}

var Jsx2$70 = {
  component: component$70,
  make: make$70
};

var Http$1 = {
  Jsx2: Jsx2$70
};

var component$71 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$71(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(Https.default, tmp, children);
}

var Jsx2$71 = {
  component: component$71,
  make: make$71
};

var Https$1 = {
  Jsx2: Jsx2$71
};

var component$72 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$72(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(Check.default, tmp, children);
}

var Jsx2$72 = {
  component: component$72,
  make: make$72
};

var Check$1 = {
  Jsx2: Jsx2$72
};

var component$73 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$73(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(CallSplit.default, tmp, children);
}

var Jsx2$73 = {
  component: component$73,
  make: make$73
};

var CallSplit$1 = {
  Jsx2: Jsx2$73
};

var component$74 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$74(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(FiberSmartRecord.default, tmp, children);
}

var Jsx2$74 = {
  component: component$74,
  make: make$74
};

var FiberSmartRecord$1 = {
  Jsx2: Jsx2$74
};

var component$75 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$75(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact($$Error.default, tmp, children);
}

var Jsx2$75 = {
  component: component$75,
  make: make$75
};

var $$Error$1 = {
  Jsx2: Jsx2$75
};

var component$76 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$76(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(VisibilityOff.default, tmp, children);
}

var Jsx2$76 = {
  component: component$76,
  make: make$76
};

var VisibilityOff$1 = {
  Jsx2: Jsx2$76
};

var component$77 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$77(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(ViewCompact.default, tmp, children);
}

var Jsx2$77 = {
  component: component$77,
  make: make$77
};

var ViewCompact$1 = {
  Jsx2: Jsx2$77
};

var component$78 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$78(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(AccessTime.default, tmp, children);
}

var Jsx2$78 = {
  component: component$78,
  make: make$78
};

var AccessTime$1 = {
  Jsx2: Jsx2$78
};

var component$79 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$79(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(CloudUpload.default, tmp, children);
}

var Jsx2$79 = {
  component: component$79,
  make: make$79
};

var CloudUpload$1 = {
  Jsx2: Jsx2$79
};

var component$80 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$80(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(MergeType.default, tmp, children);
}

var Jsx2$80 = {
  component: component$80,
  make: make$80
};

var MergeType$1 = {
  Jsx2: Jsx2$80
};

var component$81 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$81(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(Save.default, tmp, children);
}

var Jsx2$81 = {
  component: component$81,
  make: make$81
};

var Save$1 = {
  Jsx2: Jsx2$81
};

var component$82 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$82(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(List.default, tmp, children);
}

var Jsx2$82 = {
  component: component$82,
  make: make$82
};

var List$1 = {
  Jsx2: Jsx2$82
};

function MuiIcons$Code(Props) {
  var className = Props.className;
  var color = Props.color;
  var fontSize = Props.fontSize;
  var shapeRendering = Props.shapeRendering;
  var tmp = {
    viewBox: "0 0 16 14",
    children: null
  };
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  return React.createElement(SvgIcon.default, tmp, React.createElement("path", {
                  d: "M9.66667 0.333312C9.94158 0.40204 10.1087 0.680614 10.04 0.955524L6.91755 13.4453C6.84882 13.7202 6.57025 13.8874 6.29534 13.8186C6.02042 13.7499 5.85328 13.4713 5.92201 13.1964L9.04445 0.706639C9.11318 0.431729 9.39176 0.264584 9.66667 0.333312Z",
                  fill: "#6D7584"
                }), React.createElement("path", {
                  d: "M12.1311 2.8488C12.3524 2.67178 12.6753 2.70765 12.8523 2.92893L15.8576 6.68556C16.0475 6.92292 16.0475 7.26019 15.8576 7.49755L12.8523 11.2542C12.6753 11.4755 12.3524 11.5113 12.1311 11.3343C11.9098 11.1573 11.8739 10.8344 12.051 10.6131L14.8682 7.09156L12.051 3.56998C11.8739 3.3487 11.9098 3.02582 12.1311 2.8488Z",
                  fill: "#6D7584"
                }), React.createElement("path", {
                  d: "M3.94903 3.56998C4.12605 3.3487 4.09018 3.02582 3.8689 2.8488C3.64762 2.67178 3.32474 2.70765 3.14772 2.92893L0.142416 6.68556C-0.0474725 6.92292 -0.0474716 7.26019 0.142416 7.49755L3.14772 11.2542C3.32474 11.4755 3.64762 11.5113 3.8689 11.3343C4.09018 11.1573 4.12605 10.8344 3.94903 10.6131L1.13177 7.09156L3.94903 3.56998Z",
                  fill: "#6D7584"
                }));
}

var Code = {
  make: MuiIcons$Code
};

function MuiIcons$Cube(Props) {
  var className = Props.className;
  var color = Props.color;
  var fontSize = Props.fontSize;
  var shapeRendering = Props.shapeRendering;
  var tmp = {
    viewBox: "0 0 16 16",
    children: React.createElement("path", {
          clipRule: "evenodd",
          d: "M0.84022 3.90527C0.733133 3.96368 0.666504 4.07592 0.666504 4.1979V11.8021C0.666504 11.9241 0.733133 12.0364 0.84022 12.0948L7.84022 15.913C7.93971 15.9672 8.05996 15.9672 8.15945 15.913L15.1595 12.0948C15.2665 12.0364 15.3332 11.9241 15.3332 11.8021V4.1979C15.3332 4.07592 15.2665 3.96368 15.1595 3.90527L8.15945 0.0870882C8.05996 0.0328196 7.93971 0.0328196 7.84022 0.0870882L0.84022 3.90527ZM7.99984 1.13911L14.1335 4.48474L7.99989 7.83034L1.86624 4.48471L7.99984 1.13911ZM1.6665 11.4064V5.51486L7.59245 8.74719V14.6387L1.6665 11.4064ZM8.59245 14.5377L14.3332 11.4064V5.51492L8.59245 8.64622V14.5377Z",
          fill: "#6D7584",
          fillRule: "evenodd"
        })
  };
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  return React.createElement(SvgIcon.default, tmp);
}

var Cube = {
  make: MuiIcons$Cube
};

function MuiIcons$OctagonalError(Props) {
  var className = Props.className;
  var color = Props.color;
  var fontSize = Props.fontSize;
  var shapeRendering = Props.shapeRendering;
  var tmp = {
    viewBox: "0 0 16 16",
    children: null
  };
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  return React.createElement(SvgIcon.default, tmp, React.createElement("path", {
                  d: "M4.67569 4.64558C4.87048 4.44985 5.18631 4.44985 5.3811 4.64558L7.99983 7.27691L10.6186 4.64558C10.8134 4.44985 11.1292 4.44985 11.324 4.64558C11.5188 4.84131 11.5188 5.15865 11.324 5.35438L8.70524 7.98572L11.3524 10.6456C11.5472 10.8413 11.5472 11.1587 11.3524 11.3544C11.1576 11.5501 10.8417 11.5501 10.647 11.3544L7.99983 8.69452L5.35271 11.3544C5.15791 11.5501 4.84209 11.5501 4.6473 11.3544C4.45251 11.1587 4.45251 10.8413 4.6473 10.6456L7.29442 7.98572L4.67569 5.35438C4.4809 5.15865 4.4809 4.84131 4.67569 4.64558Z",
                  fill: "#6D7584"
                }), React.createElement("path", {
                  clipRule: "evenodd",
                  d: "M11.5537 15.7642C11.4021 15.9158 11.2 16 10.9979 16H5.01895C4.8 16 4.61474 15.9158 4.46316 15.7642L0.235789 11.5368C0.0842105 11.4021 0 11.2 0 10.9979V5.01895C0 4.8 0.0842105 4.61474 0.235789 4.46316L4.46316 0.235789C4.59789 0.0842105 4.8 0 5.00211 0H10.9811C11.2 0 11.3853 0.0842105 11.5368 0.235789L15.7642 4.46316C15.9158 4.59789 16 4.8 16 5.00211V10.9811C16 11.2 15.9158 11.3853 15.7642 11.5368L11.5537 15.7642ZM15 5.11316V10.8871L10.9035 15H5.11316L1 10.8868V5.11316L5.11316 1H10.8868L15 5.11316Z",
                  fill: "#6D7584",
                  fillRule: "evenodd"
                }));
}

var OctagonalError = {
  make: MuiIcons$OctagonalError
};

function MuiIcons$Platform(Props) {
  var className = Props.className;
  var color = Props.color;
  var fontSize = Props.fontSize;
  var shapeRendering = Props.shapeRendering;
  var tmp = {
    viewBox: "0 0 16 12",
    children: null
  };
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  return React.createElement(SvgIcon.default, tmp, React.createElement("path", {
                  d: "M2.5 2.5C2.77614 2.5 3 2.27614 3 2C3 1.72386 2.77614 1.5 2.5 1.5C2.22386 1.5 2 1.72386 2 2C2 2.27614 2.22386 2.5 2.5 2.5Z",
                  fill: "#6D7584"
                }), React.createElement("path", {
                  d: "M4 2.5C4.27614 2.5 4.5 2.27614 4.5 2C4.5 1.72386 4.27614 1.5 4 1.5C3.72386 1.5 3.5 1.72386 3.5 2C3.5 2.27614 3.72386 2.5 4 2.5Z",
                  fill: "#6D7584"
                }), React.createElement("path", {
                  d: "M6 2C6 2.27614 5.77614 2.5 5.5 2.5C5.22386 2.5 5 2.27614 5 2C5 1.72386 5.22386 1.5 5.5 1.5C5.77614 1.5 6 1.72386 6 2Z",
                  fill: "#6D7584"
                }), React.createElement("path", {
                  d: "M6.69682 10.1538C6.43733 10.0594 6.30354 9.77245 6.39798 9.51296L7.99408 5.12772C8.08852 4.86824 8.37545 4.73444 8.63493 4.82889C8.89442 4.92333 9.02822 5.21026 8.93377 5.46974L7.33768 9.85498C7.24323 10.1145 6.95631 10.2483 6.69682 10.1538Z",
                  fill: "#6D7584"
                }), React.createElement("path", {
                  d: "M10.6869 8.87841L12.0202 7.54508C12.2155 7.34981 12.2155 7.03323 12.0202 6.83797L10.6869 5.50463C10.4916 5.30937 10.175 5.30937 9.97978 5.50463C9.78452 5.6999 9.78452 6.01648 9.97978 6.21174L10.9596 7.19152L9.97978 8.1713C9.78452 8.36656 9.78452 8.68315 9.97978 8.87841C10.175 9.07367 10.4916 9.07367 10.6869 8.87841Z",
                  fill: "#6D7584"
                }), React.createElement("path", {
                  d: "M3.97978 6.83797C3.78452 7.03323 3.78452 7.34981 3.97978 7.54508L5.31311 8.87841C5.50838 9.07367 5.82496 9.07367 6.02022 8.87841C6.21548 8.68315 6.21548 8.36656 6.02022 8.1713L5.04044 7.19152L6.02022 6.21174C6.21548 6.01648 6.21548 5.6999 6.02022 5.50463C5.82496 5.30937 5.50838 5.30937 5.31311 5.50463L3.97978 6.83797Z",
                  fill: "#6D7584"
                }), React.createElement("path", {
                  clipRule: "evenodd",
                  d: "M0 1.33333C0 0.596954 0.596954 0 1.33333 0H14.6667C15.403 0 16 0.596954 16 1.33333V10.6667C16 11.403 15.403 12 14.6667 12H1.33333C0.596953 12 0 11.403 0 10.6667V1.33333ZM1.33333 1H14.6667C14.8508 1 15 1.14924 15 1.33333V3H1V1.33333C1 1.14924 1.14924 1 1.33333 1ZM1 4V10.6667C1 10.8508 1.14924 11 1.33333 11H14.6667C14.8508 11 15 10.8508 15 10.6667V4H1Z",
                  fill: "#6D7584",
                  fillRule: "evenodd"
                }));
}

var Platform = {
  make: MuiIcons$Platform
};

function MuiIcons$Status(Props) {
  var className = Props.className;
  var color = Props.color;
  var fontSize = Props.fontSize;
  var shapeRendering = Props.shapeRendering;
  var tmp = {
    viewBox: "0 0 16 16",
    children: null
  };
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  return React.createElement(SvgIcon.default, tmp, React.createElement("path", {
                  d: "M7.70762 3.66488C7.89072 3.45817 7.87158 3.14216 7.66488 2.95906C7.45817 2.77596 7.14216 2.79509 6.95906 3.0018L4.25032 6.05976L3.04126 4.69228C2.85835 4.4854 2.54236 4.46597 2.33548 4.64888C2.12861 4.83179 2.10918 5.14778 2.29209 5.35466L4.24962 7.56868L7.70762 3.66488Z",
                  fill: "#6D7584"
                }), React.createElement("path", {
                  clipRule: "evenodd",
                  d: "M10 1.33333V6H14.6667C15.403 6 16 6.59695 16 7.33333V14.6667C16 15.403 15.403 16 14.6667 16H7.33333C6.59695 16 6 15.403 6 14.6667V10H1.33333C0.596954 10 0 9.40305 0 8.66667V1.33333C0 0.596954 0.596954 0 1.33333 0H8.66667C9.40305 0 10 0.596954 10 1.33333ZM9 8.66667V1.33333C9 1.14924 8.85076 1 8.66667 1H1.33333C1.14924 1 1 1.14924 1 1.33333V8.66667C1 8.85076 1.14924 9 1.33333 9H8.66667C8.85076 9 9 8.85076 9 8.66667ZM10 8.66667V7H14.6667C14.8508 7 15 7.14924 15 7.33333V14.6667C15 14.8508 14.8508 15 14.6667 15H7.33333C7.14924 15 7 14.8508 7 14.6667V10H8.66667C8.85142 10 9.02741 9.96242 9.18738 9.89449L10.293 11.0001L8.97986 12.3132C8.78459 12.5085 8.78459 12.825 8.97986 13.0203C9.17512 13.2156 9.4917 13.2156 9.68696 13.0203L11.0001 11.7072L12.3132 13.0203C12.5084 13.2156 12.825 13.2156 13.0203 13.0203C13.2156 12.825 13.2156 12.5084 13.0203 12.3132L11.7072 11.0001L13.0203 9.68696C13.2156 9.4917 13.2156 9.17512 13.0203 8.97986C12.825 8.7846 12.5084 8.7846 12.3132 8.97986L11.0001 10.293L9.89449 9.18738C9.96242 9.0274 10 8.85143 10 8.66667Z",
                  fill: "#6D7584",
                  fillRule: "evenodd"
                }));
}

var Status = {
  make: MuiIcons$Status
};

function MuiIcons$Assignee(Props) {
  var className = Props.className;
  var color = Props.color;
  var fontSize = Props.fontSize;
  var shapeRendering = Props.shapeRendering;
  var tmp = {
    viewBox: "0 0 16 16",
    children: React.createElement("path", {
          clipRule: "evenodd",
          d: "M9.27563 7.79229C10.8593 7.2598 12 5.76311 12 4C12 1.79086 10.2091 0 8 0C5.79086 0 4 1.79086 4 4C4 5.76313 5.14073 7.25983 6.72441 7.7923C3.56563 8.42192 1.10276 11.3488 0.718855 15.0012C0.661123 15.5505 1.11244 16 1.66473 16H14.3341C14.8864 16 15.339 15.5505 15.2813 15.0012C14.8974 11.3487 12.4345 8.42187 9.27563 7.79229ZM11 4C11 5.65685 9.65685 7 8 7C6.34315 7 5 5.65685 5 4C5 2.34315 6.34315 1 8 1C9.65685 1 11 2.34315 11 4ZM8.00006 8.66667C11.107 8.66667 13.8421 11.3215 14.275 15H1.72516C2.15803 11.3215 4.89312 8.66667 8.00006 8.66667Z",
          fill: "#6D7584",
          fillRule: "evenodd"
        })
  };
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  return React.createElement(SvgIcon.default, tmp);
}

var Assignee = {
  make: MuiIcons$Assignee
};

function MuiIcons$Tag(Props) {
  var className = Props.className;
  var color = Props.color;
  var fontSize = Props.fontSize;
  var shapeRendering = Props.shapeRendering;
  var tmp = {
    viewBox: "0 0 16 16",
    children: null
  };
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  return React.createElement(SvgIcon.default, tmp, React.createElement("path", {
                  d: "M11.3332 6C12.0695 6 12.6665 5.40305 12.6665 4.66667C12.6665 3.93029 12.0695 3.33333 11.3332 3.33333C10.5968 3.33333 9.99984 3.93029 9.99984 4.66667C9.99984 5.40305 10.5968 6 11.3332 6Z",
                  fill: "#6D7584"
                }), React.createElement("path", {
                  clipRule: "evenodd",
                  d: "M8.01388 0.390524L0.390524 8.01388C-0.130175 8.53458 -0.130175 9.3788 0.390524 9.8995L6.04738 15.5563C6.56808 16.077 7.4123 16.077 7.933 15.5563L15.5563 7.933C15.8064 7.68295 15.9469 7.34381 15.9469 6.99019V1.33333C15.9469 0.596954 15.3499 0 14.6135 0H8.95669C8.60306 0 8.26393 0.140476 8.01388 0.390524ZM1.09763 8.72098L8.72098 1.09763C8.7835 1.03512 8.86828 1 8.95669 1L14.6135 1C14.7976 1 14.9469 1.14924 14.9469 1.33333V6.99019C14.9469 7.07859 14.9118 7.16338 14.8492 7.22589L7.22589 14.8492C7.09572 14.9794 6.88466 14.9794 6.75449 14.8492L1.09763 9.19239C0.967456 9.06221 0.967456 8.85116 1.09763 8.72098Z",
                  fill: "#6D7584",
                  fillRule: "evenodd"
                }));
}

var Tag = {
  make: MuiIcons$Tag
};

function MuiIcons$OutlinedList(Props) {
  var className = Props.className;
  var color = Props.color;
  var fontSize = Props.fontSize;
  var shapeRendering = Props.shapeRendering;
  var tmp = {
    viewBox: "0 0 16 16",
    children: null
  };
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  return React.createElement(SvgIcon.default, tmp, React.createElement("path", {
                  clipRule: "evenodd",
                  d: "M3.33333 0H0.666667C0.298477 0 0 0.298477 0 0.666667V3.33333C0 3.70152 0.298477 4 0.666667 4H3.33333C3.70152 4 4 3.70152 4 3.33333V0.666667C4 0.298477 3.70152 0 3.33333 0ZM1 3V1H3V3H1Z",
                  fill: "#6D7584",
                  fillRule: "evenodd"
                }), React.createElement("path", {
                  clipRule: "evenodd",
                  d: "M3.33333 6H0.666667C0.298477 6 0 6.29848 0 6.66667V9.33333C0 9.70152 0.298477 10 0.666667 10H3.33333C3.70152 10 4 9.70152 4 9.33333V6.66667C4 6.29848 3.70152 6 3.33333 6ZM1 9V7H3V9H1Z",
                  fill: "#6D7584",
                  fillRule: "evenodd"
                }), React.createElement("path", {
                  clipRule: "evenodd",
                  d: "M0.666667 12H3.33333C3.70152 12 4 12.2985 4 12.6667V15.3333C4 15.7015 3.70152 16 3.33333 16H0.666667C0.298477 16 0 15.7015 0 15.3333V12.6667C0 12.2985 0.298477 12 0.666667 12ZM1 13V15H3V13H1Z",
                  fill: "#6D7584",
                  fillRule: "evenodd"
                }), React.createElement("path", {
                  clipRule: "evenodd",
                  d: "M15.3333 0H6.66667C6.29848 0 6 0.298477 6 0.666667V3.33333C6 3.70152 6.29848 4 6.66667 4H15.3333C15.7015 4 16 3.70152 16 3.33333V0.666667C16 0.298477 15.7015 0 15.3333 0ZM7 3V1H15V3H7Z",
                  fill: "#6D7584",
                  fillRule: "evenodd"
                }), React.createElement("path", {
                  clipRule: "evenodd",
                  d: "M6.66667 6H15.3333C15.7015 6 16 6.29848 16 6.66667V9.33333C16 9.70152 15.7015 10 15.3333 10H6.66667C6.29848 10 6 9.70152 6 9.33333V6.66667C6 6.29848 6.29848 6 6.66667 6ZM7 7V9H15V7H7Z",
                  fill: "#6D7584",
                  fillRule: "evenodd"
                }), React.createElement("path", {
                  clipRule: "evenodd",
                  d: "M15.3333 12H6.66667C6.29848 12 6 12.2985 6 12.6667V15.3333C6 15.7015 6.29848 16 6.66667 16H15.3333C15.7015 16 16 15.7015 16 15.3333V12.6667C16 12.2985 15.7015 12 15.3333 12ZM7 15V13H15V15H7Z",
                  fill: "#6D7584",
                  fillRule: "evenodd"
                }));
}

var OutlinedList = {
  make: MuiIcons$OutlinedList
};

function MuiIcons$OutlineBug(Props) {
  var className = Props.className;
  var color = Props.color;
  var fontSize = Props.fontSize;
  var shapeRendering = Props.shapeRendering;
  var tmp = {
    viewBox: "0 0 16 16",
    children: null
  };
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  return React.createElement(SvgIcon.default, tmp, React.createElement("path", {
                  d: "M5.58213 0.0860405C5.71297 -0.0446813 5.94567 -0.0241361 6.10188 0.131929L6.91817 0.947464C7.25608 0.824957 7.62032 0.758209 8.00001 0.758209C8.40888 0.758209 8.79984 0.835612 9.15929 0.976695L10.0048 0.131929C10.161 -0.0241361 10.3937 -0.0446813 10.5246 0.0860405C10.6554 0.216762 10.6349 0.44925 10.4787 0.605315L9.77981 1.30351C10.5494 1.82437 11.0827 2.67346 11.1829 3.65226C11.2018 3.83711 11.0508 3.98821 10.8667 3.98821H5.13335C4.94925 3.98821 4.79819 3.83711 4.81712 3.65226C4.92012 2.64643 5.48037 1.77759 6.28448 1.26113L5.62806 0.605315C5.47185 0.44925 5.45128 0.216762 5.58213 0.0860405Z",
                  fill: "#6D7584"
                }), React.createElement("path", {
                  clipRule: "evenodd",
                  d: "M2.65455 5.70374H3.82999C2.8335 6.73962 2.18487 8.18299 2.05671 9.63225C2.00742 9.61584 1.95474 9.60696 1.9 9.60696H0.5C0.223858 9.60696 0 9.83292 0 10.1117C0 10.3904 0.223858 10.6164 0.5 10.6164H1.9C1.95218 10.6164 2.0025 10.6083 2.04979 10.5933C2.12731 11.6188 2.47568 12.5679 3.02417 13.3754H2.65455C2.51368 13.3754 2.37934 13.4354 2.28458 13.5406L0.830037 15.1557C0.644284 15.3619 0.659342 15.6811 0.863671 15.8686C1.068 16.0561 1.38422 16.0409 1.56998 15.8347L2.87574 14.3848H3.89191C4.97159 15.3871 6.43285 16 8.03335 16C9.63384 16 11.0951 15.3871 12.1748 14.3848H13.1643L14.47 15.8347C14.6558 16.0409 14.972 16.0561 15.1763 15.8686C15.3807 15.6811 15.3957 15.3619 15.21 15.1557L13.7554 13.5406C13.6607 13.4354 13.5263 13.3754 13.3855 13.3754H13.0425C13.5882 12.5721 13.9358 11.6287 14.0157 10.6092C14.0431 10.6139 14.0713 10.6164 14.1 10.6164H15.5C15.7762 10.6164 16 10.3904 16 10.1117C16 9.83292 15.7762 9.60696 15.5 9.60696H14.1C14.0687 9.60696 14.0382 9.60986 14.0085 9.6154C13.8768 8.17181 13.2293 6.7356 12.2367 5.70374H13.3455C13.4863 5.70374 13.6207 5.64375 13.7154 5.53853L15.17 3.92348C15.3557 3.71723 15.3407 3.39803 15.1364 3.21053C14.932 3.02303 14.6158 3.03823 14.43 3.24448L13.1243 4.69433H10C9.99409 4.69433 9.98819 4.69443 9.98232 4.69464L6.01766 4.69464C6.0118 4.69443 6.00591 4.69433 6 4.69433H2.87574L1.56998 3.24448C1.38422 3.03823 1.068 3.02303 0.863669 3.21053C0.65934 3.39803 0.644282 3.71723 0.830036 3.92348L2.28458 5.53853C2.37934 5.64375 2.51368 5.70374 2.65455 5.70374ZM5.45602 5.70405C5.42416 5.70405 5.40435 5.71194 5.39428 5.71814C3.99473 6.5797 3.03335 8.40133 3.03335 10.1566C3.03335 12.6034 4.92406 14.6511 7.40084 14.9524C7.40029 14.9427 7.4 14.9328 7.4 14.923L7.4 5.70405L5.45602 5.70405ZM8.4 5.70405V14.923C8.4 14.9416 8.399 14.96 8.39706 14.9781C11.0045 14.7975 13.0333 12.6912 13.0333 10.1566C13.0333 8.40133 12.072 6.5797 10.6724 5.71815C10.6623 5.71194 10.6425 5.70405 10.6107 5.70405L8.4 5.70405Z",
                  fill: "#6D7584",
                  fillRule: "evenodd"
                }));
}

var OutlineBug = {
  make: MuiIcons$OutlineBug
};

function MuiIcons$Pinned(Props) {
  var className = Props.className;
  var color = Props.color;
  var fontSize = Props.fontSize;
  var shapeRendering = Props.shapeRendering;
  var tmp = {
    viewBox: "0 0 12 14",
    children: React.createElement("path", {
          d: "M9.53629 0.333349L2.56178 0.333344C2.30988 0.334346 2.10455 0.570988 2.10355 0.822889L2.10341 0.852442C2.10143 1.28004 2.26616 2.9265 2.56716 3.2275C2.76109 3.42143 2.99688 3.5578 3.25426 3.63046L2.70654 7.92308C2.17756 7.95514 1.68355 8.17718 1.30433 8.55641C0.895678 8.96506 0.669223 10.7519 0.666705 11.3272L0.666509 11.3709C0.665314 11.6229 0.860039 11.6679 1.11201 11.6667H4.74386L5.0665 13.6667H6.93317L7.33317 11.6667H10.8661C11.1249 11.6656 11.3318 11.6086 11.3329 11.2667L11.3331 11.2794C11.3358 10.7042 11.1141 8.91925 10.7092 8.51432C10.3334 8.13848 9.84126 7.92093 9.31262 7.89355L8.82577 3.60558C9.08381 3.53061 9.32084 3.39211 9.51651 3.19644C9.82029 2.89265 9.98863 1.24483 9.99043 0.817163L9.99056 0.787613C9.99162 0.535519 9.78826 0.332154 9.53629 0.333349Z",
          fill: "currentColor"
        })
  };
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  return React.createElement(SvgIcon.default, tmp);
}

var component$83 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$83(className, color, fontSize, shapeRendering, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  return ReasonReactCompat.wrapReactForReasonReact(MuiIcons$Pinned, tmp, children);
}

var Jsx2$83 = {
  component: component$83,
  make: make$83
};

var Pinned = {
  make: MuiIcons$Pinned,
  Jsx2: Jsx2$83
};

function MuiIcons$Pin(Props) {
  var className = Props.className;
  var color = Props.color;
  var fontSize = Props.fontSize;
  var shapeRendering = Props.shapeRendering;
  var tmp = {
    viewBox: "0 0 16 16",
    children: React.createElement("path", {
          d: "M15.8648 5.103L10.8766 0.134652C10.6965 -0.0448839 10.4041 -0.0448839 10.2241 0.134652L10.2029 0.155703C9.89706 0.460262 8.84205 1.7563 8.84205 2.18707C8.84205 2.4646 8.91284 2.73092 9.04437 2.96708L5.59799 5.64682C5.19853 5.29124 4.68869 5.09663 4.14867 5.09663C3.56674 5.09663 2.13323 6.21322 1.72187 6.62303L1.69058 6.6542C1.51033 6.83364 1.51033 7.12469 1.69058 7.30413L4.26603 9.86939L0.0356097 15.7153C-0.0831991 15.8795 0.119911 16.0836 0.283273 15.9642L6.10962 11.7057L8.66667 14.2526C8.75675 14.3424 8.87489 14.3873 8.99293 14.3873C9.11098 14.3873 9.22921 14.3424 9.3192 14.2526L9.35048 14.2215C9.76194 13.8117 10.875 12.3759 10.875 11.7964C10.875 11.2585 10.6795 10.7507 10.3226 10.3528L13.0291 6.93608C13.2662 7.06708 13.5335 7.13759 13.8122 7.13759C14.2448 7.13759 15.5379 6.07891 15.8437 5.77417L15.8648 5.75312C16.0451 5.57349 16.0451 5.28245 15.8648 5.103Z",
          fill: "currentColor"
        })
  };
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  return React.createElement(SvgIcon.default, tmp);
}

var component$84 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$84(className, color, fontSize, shapeRendering, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  return ReasonReactCompat.wrapReactForReasonReact(MuiIcons$Pin, tmp, children);
}

var Jsx2$84 = {
  component: component$84,
  make: make$84
};

var Pin = {
  make: MuiIcons$Pin,
  Jsx2: Jsx2$84
};

function MuiIcons$StarCircle(Props) {
  var className = Props.className;
  var color = Props.color;
  var fontSize = Props.fontSize;
  var shapeRendering = Props.shapeRendering;
  var tmp = {
    viewBox: "0 0 16 16",
    children: React.createElement("path", {
          clipRule: "evenodd",
          d: "M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM10.937 12.0292C10.97 12.1701 10.8172 12.2813 10.6934 12.2065L8.06591 10.6203C8.02354 10.5947 7.97047 10.5947 7.9281 10.6203L5.30066 12.2065C5.17678 12.2813 5.02398 12.1701 5.05701 12.0292L5.75874 9.0361C5.77009 8.98772 5.75358 8.93705 5.71593 8.90462L3.39038 6.90232C3.28022 6.80747 3.33937 6.6268 3.48429 6.61546L6.53917 6.37647C6.58903 6.37257 6.63251 6.34109 6.65179 6.29495L7.84736 3.43378C7.90315 3.30026 8.09213 3.29973 8.14867 3.43293L9.34234 6.24467C9.3616 6.29003 9.4043 6.32107 9.45339 6.32539L12.5173 6.59505C12.6611 6.6077 12.7191 6.78682 12.6101 6.88138L10.2776 8.90462C10.2402 8.93706 10.2239 8.98757 10.2352 9.03577L10.937 12.0292Z",
          fill: "#currentColor",
          fillRule: "evenodd"
        })
  };
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  return React.createElement(SvgIcon.default, tmp);
}

var component$85 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$85(className, color, fontSize, shapeRendering, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  return ReasonReactCompat.wrapReactForReasonReact(MuiIcons$StarCircle, tmp, children);
}

var Jsx2$85 = {
  component: component$85,
  make: make$85
};

var StarCircle = {
  make: MuiIcons$StarCircle,
  Jsx2: Jsx2$85
};

var component$86 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$86(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(Repeat.default, tmp, children);
}

var Jsx2$86 = {
  component: component$86,
  make: make$86
};

var Repeat$1 = {
  Jsx2: Jsx2$86
};

var component$87 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$87(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(Fingerprint.default, tmp, children);
}

var Jsx2$87 = {
  component: component$87,
  make: make$87
};

var Fingerprint$1 = {
  Jsx2: Jsx2$87
};

function MuiIcons$CapabilityStatus(Props) {
  var className = Props.className;
  var color = Props.color;
  var fontSize = Props.fontSize;
  var shapeRendering = Props.shapeRendering;
  var tmp = {
    viewBox: "0 0 14 14",
    children: null
  };
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  return React.createElement(SvgIcon.default, tmp, React.createElement("path", {
                  d: "M6.85333 3.30347C7.06695 3.06231 7.04462 2.69364 6.80347 2.48002C6.56231 2.2664 6.19364 2.28872 5.98002 2.52988L3.71913 5.08224L2.77035 4.00915C2.55696 3.76779 2.18831 3.74512 1.94695 3.95852C1.7056 4.17192 1.68293 4.54057 1.89633 4.78192L3.71832 6.84264L6.85333 3.30347Z",
                  fill: "#6D7584"
                }), React.createElement("path", {
                  clipRule: "evenodd",
                  d: "M8.75 1.16667V5.25H12.8333C13.4777 5.25 14 5.77233 14 6.41667V12.8333C14 13.4777 13.4777 14 12.8333 14H6.41667C5.77233 14 5.25 13.4777 5.25 12.8333V8.75H1.16667C0.522335 8.75 0 8.22766 0 7.58333V1.16667C0 0.522334 0.522335 0 1.16667 0H7.58333C8.22766 0 8.75 0.522334 8.75 1.16667ZM7.58333 7.58333V1.16667H1.16667L1.16667 7.58333H7.58333ZM8.71327 7.8749C8.71238 7.87835 8.71148 7.88179 8.71056 7.88523L8.71327 7.8749ZM8.71056 7.88523C8.6033 8.28676 8.28719 8.60299 7.88572 8.71043L8.80002 9.62473L7.46252 10.9622C7.23471 11.19 7.23471 11.5594 7.46252 11.7872C7.69033 12.015 8.05967 12.015 8.28748 11.7872L9.62498 10.4497L10.9626 11.7873C11.1904 12.0151 11.5597 12.0151 11.7875 11.7873C12.0153 11.5595 12.0153 11.1901 11.7875 10.9623L10.4499 9.62473L11.7875 8.28719C12.0153 8.05939 12.0153 7.69004 11.7875 7.46224C11.5597 7.23443 11.1903 7.23443 10.9625 7.46224L9.62498 8.79977L8.71056 7.88523Z",
                  fill: "#6D7584",
                  fillRule: "evenodd"
                }));
}

var component$88 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$88(className, color, fontSize, shapeRendering, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  return ReasonReactCompat.wrapReactForReasonReact(MuiIcons$CapabilityStatus, tmp, children);
}

var Jsx2$88 = {
  component: component$88,
  make: make$88
};

var CapabilityStatus = {
  make: MuiIcons$CapabilityStatus,
  Jsx2: Jsx2$88
};

function MuiIcons$ChevronDown(Props) {
  var className = Props.className;
  var color = Props.color;
  var fontSize = Props.fontSize;
  var shapeRendering = Props.shapeRendering;
  var tmp = {
    viewBox: "0 0 16 16",
    children: React.createElement("path", {
          clipRule: "evenodd",
          d: "M13.7567 4.90446C14.0811 5.22109 14.0811 5.73445 13.7567 6.05108L8.58742 11.0962C8.263 11.4128 7.73701 11.4128 7.41258 11.0962L2.24332 6.05108C1.91889 5.73445 1.91889 5.22109 2.24332 4.90446C2.56774 4.58783 3.09373 4.58783 3.41815 4.90446L8 9.37626L12.5819 4.90446C12.9063 4.58783 13.4323 4.58783 13.7567 4.90446Z",
          fill: "#6D7584",
          fillRule: "evenodd"
        })
  };
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  return React.createElement(SvgIcon.default, tmp);
}

var component$89 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$89(className, color, fontSize, shapeRendering, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  return ReasonReactCompat.wrapReactForReasonReact(MuiIcons$ChevronDown, tmp, children);
}

var Jsx2$89 = {
  component: component$89,
  make: make$89
};

var ChevronDown = {
  make: MuiIcons$ChevronDown,
  Jsx2: Jsx2$89
};

function MuiIcons$User(Props) {
  var className = Props.className;
  var color = Props.color;
  var fontSize = Props.fontSize;
  var shapeRendering = Props.shapeRendering;
  var tmp = {
    viewBox: "0 0 16 16",
    children: React.createElement("path", {
          d: "M8.11602 6.81825C9.50175 6.35234 10.4999 5.04273 10.4999 3.49998C10.4999 1.567 8.9329 0 6.99992 0C5.06693 0 3.49993 1.567 3.49993 3.49998C3.49993 5.04273 4.49809 6.35235 5.88383 6.81825C3.11985 7.36913 0.964794 9.93015 0.628876 13.1261C0.578361 13.6067 0.973267 14 1.45652 14H12.5422C13.0255 14 13.4215 13.6067 13.371 13.1261C13.0351 9.93014 10.88 7.36912 8.11602 6.81825Z",
          fill: "#6D7584"
        })
  };
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  return React.createElement(SvgIcon.default, tmp);
}

var component$90 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$90(className, color, fontSize, shapeRendering, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  return ReasonReactCompat.wrapReactForReasonReact(MuiIcons$User, tmp, children);
}

var Jsx2$90 = {
  component: component$90,
  make: make$90
};

var User = {
  make: MuiIcons$User,
  Jsx2: Jsx2$90
};

function MuiIcons$JiraLogo(Props) {
  var className = Props.className;
  var color = Props.color;
  var fontSize = Props.fontSize;
  var shapeRendering = Props.shapeRendering;
  var tmp = {
    viewBox: "0 0 16 16",
    children: null
  };
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  return React.createElement(SvgIcon.default, tmp, React.createElement("path", {
                  d: "M13.4225 0H6.67917C6.67917 1.68 8.03834 3.03917 9.71834 3.03917H10.9608V4.24083C10.9608 5.92083 12.32 7.28 14 7.28V0.5775C14 0.2625 13.7375 0 13.4225 0Z",
                  fill: "#6D7584"
                }), React.createElement("path", {
                  d: "M10.08 3.36001H3.34249C3.34249 5.04001 4.70165 6.39917 6.38165 6.39917H7.62416V7.60084C7.62416 9.28084 8.98332 10.64 10.6633 10.64V3.93751C10.6575 3.62251 10.4008 3.36001 10.08 3.36001Z",
                  fill: "#6D7584"
                }), React.createElement("path", {
                  d: "M0 6.72001H6.7375C7.05833 6.72001 7.32083 6.98251 7.32083 7.29751V14C5.64083 14 4.28167 12.6408 4.28167 10.9608V9.75918H3.03917C1.35917 9.75918 0 8.40001 0 6.72001Z",
                  fill: "#6D7584"
                }));
}

var component$91 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$91(className, color, fontSize, shapeRendering, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  return ReasonReactCompat.wrapReactForReasonReact(MuiIcons$JiraLogo, tmp, children);
}

var Jsx2$91 = {
  component: component$91,
  make: make$91
};

var JiraLogo = {
  make: MuiIcons$JiraLogo,
  Jsx2: Jsx2$91
};

function MuiIcons$Tags(Props) {
  var className = Props.className;
  var color = Props.color;
  var fontSize = Props.fontSize;
  var shapeRendering = Props.shapeRendering;
  var tmp = {
    viewBox: "0 0 16 16",
    children: React.createElement("path", {
          clipRule: "evenodd",
          d: "M0.341709 7.01214L7.01214 0.341709C7.23094 0.122916 7.52768 1.35222e-07 7.8371 2.45856e-07L12.7868 0C13.4312 3.93371e-07 13.9535 0.522335 13.9535 1.16667V6.11642C13.9535 6.42583 13.8306 6.72258 13.6118 6.94137L6.94137 13.6118C6.48576 14.0674 5.74707 14.0674 5.29146 13.6118L0.341709 8.66206C-0.113903 8.20645 -0.113903 7.46775 0.341709 7.01214ZM9.91667 5.24998C10.561 5.24998 11.0833 4.72765 11.0833 4.08332C11.0833 3.43898 10.561 2.91665 9.91667 2.91665C9.27233 2.91665 8.75 3.43898 8.75 4.08332C8.75 4.72765 9.27233 5.24998 9.91667 5.24998Z",
          fill: "#6D7584",
          fillRule: "evenodd"
        })
  };
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  return React.createElement(SvgIcon.default, tmp);
}

var component$92 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$92(className, color, fontSize, shapeRendering, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  return ReasonReactCompat.wrapReactForReasonReact(MuiIcons$Tags, tmp, children);
}

var Jsx2$92 = {
  component: component$92,
  make: make$92
};

var Tags = {
  make: MuiIcons$Tags,
  Jsx2: Jsx2$92
};

var component$93 = RR$BsConsole.statelessComponent("MuiIcons-BsConsole");

function make$93(className, color, fontSize, shapeRendering, viewBox, children) {
  var tmp = { };
  if (className !== undefined) {
    tmp.className = Caml_option.valFromOption(className);
  }
  if (color !== undefined) {
    tmp.color = Caml_option.valFromOption(color);
  }
  if (fontSize !== undefined) {
    tmp.fontSize = Caml_option.valFromOption(fontSize);
  }
  if (shapeRendering !== undefined) {
    tmp.shapeRendering = Caml_option.valFromOption(shapeRendering);
  }
  if (viewBox !== undefined) {
    tmp.viewBox = Caml_option.valFromOption(viewBox);
  }
  return ReasonReactCompat.wrapReactForReasonReact(Devices.default, tmp, children);
}

var Jsx2$93 = {
  component: component$93,
  make: make$93
};

var Devices$1 = {
  Jsx2: Jsx2$93
};

exports.ErrorOutline = ErrorOutline$1;
exports.Flag = Flag$1;
exports.FlagOutline = FlagOutline;
exports.Warning = Warning$1;
exports.$$Storage = $$Storage$1;
exports.Visibility = Visibility$1;
exports.BugReport = BugReport$1;
exports.AutoAwesome = AutoAwesome;
exports.ArrowDropDown = ArrowDropDown$1;
exports.ArrowDropUp = ArrowDropUp$1;
exports.Update = Update$1;
exports.Inbox = Inbox;
exports.AccountCircle = AccountCircle$1;
exports.Receipt = Receipt$1;
exports.LocalOffer = LocalOffer$1;
exports.CenterFocusStrong = CenterFocusStrong$1;
exports.AttachFile = AttachFile$1;
exports.GetApp = GetApp$1;
exports.Chat = Chat$1;
exports.KeyboardArrowLeft = KeyboardArrowLeft$1;
exports.KeyboardArrowRight = KeyboardArrowRight$1;
exports.ArrowRight = ArrowRight;
exports.ArrowDownward = ArrowDownward$1;
exports.ArrowUpward = ArrowUpward$1;
exports.KeyboardArrowDown = KeyboardArrowDown$1;
exports.KeyboardArrowUp = KeyboardArrowUp$1;
exports.Sync = Sync$1;
exports.Link = Link$1;
exports.MoreVert = MoreVert$1;
exports.MoreHoriz = MoreHoriz$1;
exports.Label = Label$1;
exports.PersonAdd = PersonAdd$1;
exports.Person = Person$1;
exports.ArrowForward = ArrowForward$1;
exports.Help = Help$1;
exports.HelpOutline = HelpOutline$1;
exports.Close = Close$1;
exports.Cancel = Cancel$1;
exports.FilterList = FilterList$1;
exports.Assignment = Assignment$1;
exports.Edit = Edit$1;
exports.Delete = Delete$1;
exports.DeleteForever = DeleteForever$1;
exports.Download = Download;
exports.Paperclip = Paperclip;
exports.CompareArrows = CompareArrows$1;
exports.AddCircle = AddCircle$1;
exports.AddCircleOutline = AddCircleOutline$1;
exports.Add = Add$1;
exports.Info = Info$1;
exports.Clear = Clear$1;
exports.Tune = Tune$1;
exports.OpenInNew = OpenInNew$1;
exports.ExpandMore = ExpandMore$1;
exports.Publish = Publish$1;
exports.CheckCircle = CheckCircle$1;
exports.Build = Build$1;
exports.Description = Description$1;
exports.Lens = Lens$1;
exports.Launch = Launch$1;
exports.Lock = Lock$1;
exports.Search = Search$1;
exports.Notifications = Notifications$1;
exports.ChevronLeft = ChevronLeft$1;
exports.ChevronRight = ChevronRight$1;
exports.RadioButtonUnchecked = RadioButtonUnchecked$1;
exports.ZoomOut = ZoomOut$1;
exports.Settings = Settings$1;
exports.DragHandle = DragHandle$1;
exports.ExpandLess = ExpandLess$1;
exports.VpnKey = VpnKey$1;
exports.KeyboardBackspace = KeyboardBackspace$1;
exports.PriorityHigh = PriorityHigh$1;
exports.Http = Http$1;
exports.Https = Https$1;
exports.Check = Check$1;
exports.CallSplit = CallSplit$1;
exports.FiberSmartRecord = FiberSmartRecord$1;
exports.$$Error = $$Error$1;
exports.VisibilityOff = VisibilityOff$1;
exports.ViewCompact = ViewCompact$1;
exports.AccessTime = AccessTime$1;
exports.CloudUpload = CloudUpload$1;
exports.MergeType = MergeType$1;
exports.Save = Save$1;
exports.List = List$1;
exports.Code = Code;
exports.Cube = Cube;
exports.OctagonalError = OctagonalError;
exports.Platform = Platform;
exports.Status = Status;
exports.Assignee = Assignee;
exports.Tag = Tag;
exports.OutlinedList = OutlinedList;
exports.OutlineBug = OutlineBug;
exports.Pinned = Pinned;
exports.Pin = Pin;
exports.StarCircle = StarCircle;
exports.Repeat = Repeat$1;
exports.Fingerprint = Fingerprint$1;
exports.CapabilityStatus = CapabilityStatus;
exports.ChevronDown = ChevronDown;
exports.User = User;
exports.JiraLogo = JiraLogo;
exports.Tags = Tags;
exports.Devices = Devices$1;
/* component Not a pure module */
