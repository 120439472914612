// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_List = require("bs-platform/lib/js/belt_List.js");
var ElementRe = require("bs-webapi/lib/js/src/dom/nodes/ElementRe.js");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Fuzz$BsConsole = require("./bindings/Fuzz.js");
var I18N$BsConsole = require("./I18N.js");
var BtTypography$BsConsole = require("./BtTypography.js");
var Paper = require("@material-ui/core/Paper");
var Popper = require("@material-ui/core/Popper");
var BtAutocomplete$BsConsole = require("./BtAutocomplete.js");

var wordBreakRegex = /(\s+)/;

function getSelectedWordAndWordPosition(internalValue, cursorPosition) {
  var wordArray = Belt_Array.keepMap(internalValue.split(wordBreakRegex), (function (c) {
          return c;
        }));
  var arrayOfWordLengths = Belt_Array.map(wordArray, (function (word) {
          return word.length;
        }));
  var arrayOfWordPositions = Belt_Array.mapWithIndex(arrayOfWordLengths, (function (index, length) {
          var start = Belt_Array.reduceWithIndex(arrayOfWordLengths, 0, (function (acc, currLength, idx) {
                  if (idx < index) {
                    return acc + currLength | 0;
                  } else {
                    return acc;
                  }
                }));
          var end_ = start + length | 0;
          return /* tuple */[
                  start,
                  end_
                ];
        }));
  var wordPositionIndex = cursorPosition === 0 ? 0 : Belt_Array.reduceWithIndex(arrayOfWordPositions, -1, (function (acc, curr, index) {
            if (cursorPosition > curr[0] && cursorPosition <= curr[1]) {
              return index;
            } else {
              return acc;
            }
          }));
  var selectedWord = Belt_Option.getWithDefault(Belt_Array.get(wordArray, wordPositionIndex), "");
  return /* tuple */[
          selectedWord,
          wordPositionIndex
        ];
}

function SpecialCharacterAutocomplete(Props) {
  var toString = Props.toString;
  var considerations = Props.considerations;
  var onDeleteOpt = Props.onDelete;
  var onChange = Props.onChange;
  var defaultValueOpt = Props.defaultValue;
  var loadingOpt = Props.loading;
  var placeholderOpt = Props.placeholder;
  var autoFocusOpt = Props.autoFocus;
  var inputAriaLabelOpt = Props.inputAriaLabel;
  var filterOptions = Props.filterOptions;
  var renderListOption = Props.renderListOption;
  var renderInput = Props.renderInput;
  var listClassNameOpt = Props.listClassName;
  var rootClassNameOpt = Props.rootClassName;
  var onBlur = Props.onBlur;
  var triggeringCharacter = Props.triggeringCharacter;
  var onDelete = onDeleteOpt !== undefined ? onDeleteOpt : (function (param) {
        
      });
  var defaultValue = defaultValueOpt !== undefined ? defaultValueOpt : "";
  var loading = loadingOpt !== undefined ? loadingOpt : false;
  var placeholder = placeholderOpt !== undefined ? placeholderOpt : "";
  var autoFocus = autoFocusOpt !== undefined ? autoFocusOpt : false;
  var inputAriaLabel = inputAriaLabelOpt !== undefined ? inputAriaLabelOpt : "autocompete-input";
  var listClassName = listClassNameOpt !== undefined ? listClassNameOpt : "";
  var rootClassName = rootClassNameOpt !== undefined ? rootClassNameOpt : "";
  var match = React.useState((function () {
          
        }));
  var setAnchorEl = match[1];
  var anchorEl = match[0];
  var match$1 = React.useState((function () {
          
        }));
  var setListboxRef = match$1[1];
  var listboxRef = match$1[0];
  var match$2 = React.useState((function () {
          return defaultValue;
        }));
  var setInternalValue = match$2[1];
  var internalValue = match$2[0];
  var match$3 = React.useState((function () {
          return "";
        }));
  var setAutoCompleteValue = match$3[1];
  var autoCompleteValue = match$3[0];
  var match$4 = React.useState((function () {
          return considerations;
        }));
  var setOptions = match$4[1];
  var options = match$4[0];
  var match$5 = React.useState((function () {
          return false;
        }));
  var setOpen_ = match$5[1];
  var open_ = match$5[0];
  var match$6 = React.useState((function () {
          
        }));
  var setBlurCb = match$6[1];
  var blurCb = match$6[0];
  var match$7 = React.useState((function () {
          return 0;
        }));
  var setPopperKey = match$7[1];
  var popperKey = match$7[0];
  var match$8 = React.useState((function () {
          return 0;
        }));
  var setCursorPosition = match$8[1];
  var cursorPosition = match$8[0];
  var highlightedIndexRef = React.useRef(-1);
  var handleOpen = function (param) {
    if (open_) {
      return ;
    } else {
      return Curry._1(setOpen_, (function (param) {
                    return true;
                  }));
    }
  };
  var handleClose = function (_event) {
    if (open_) {
      return Curry._1(setOpen_, (function (param) {
                    return false;
                  }));
    }
    
  };
  var handleFocus = function (param) {
    if (autoCompleteValue !== "") {
      return handleOpen(undefined);
    } else {
      return handleClose(undefined);
    }
  };
  var handleAddValue = function (value) {
    var trimmedValue = internalValue.trim();
    if (value === triggeringCharacter) {
      if (!trimmedValue.endsWith(triggeringCharacter)) {
        var newValue = trimmedValue === "" ? triggeringCharacter : trimmedValue + (" " + triggeringCharacter);
        Curry._1(setInternalValue, (function (param) {
                return newValue;
              }));
        Curry._1(onChange, newValue);
      }
      return handleOpen(undefined);
    }
    var arrayOfEachWord = Belt_Array.keepMap(internalValue.split(wordBreakRegex), (function (c) {
            return c;
          }));
    var match = getSelectedWordAndWordPosition(internalValue, cursorPosition);
    var wordPosition = match[1];
    if (value.trim() === "") {
      var newValue$1 = value + " ";
      Curry._1(setInternalValue, (function (param) {
              return newValue$1;
            }));
      Curry._1(onChange, newValue$1);
    } else if (match[0].startsWith(triggeringCharacter)) {
      var newValue$2 = Belt_Array.mapWithIndex(arrayOfEachWord, (function (index, word) {
                  if (index === wordPosition) {
                    return value;
                  } else {
                    return word;
                  }
                })).join("").trim();
      var newValue$3 = newValue$2 + " ";
      Curry._1(setInternalValue, (function (param) {
              return newValue$3;
            }));
      Curry._1(onChange, newValue$3);
    } else {
      var newValue$4 = trimmedValue + (" " + (value + " "));
      Curry._1(setInternalValue, (function (param) {
              return newValue$4;
            }));
      Curry._1(onChange, newValue$4);
    }
    return handleClose(undefined);
  };
  var handleInputChange = function ($$event, inputCursorPosition) {
    Curry._1(setCursorPosition, (function (param) {
            return inputCursorPosition;
          }));
    var newValue = $$event.target.value;
    var match = getSelectedWordAndWordPosition(newValue, inputCursorPosition);
    var selectedWord = match[0];
    if (selectedWord.startsWith(triggeringCharacter)) {
      Curry._1(setAutoCompleteValue, (function (param) {
              return selectedWord;
            }));
      handleOpen(undefined);
    } else {
      Curry._1(setAutoCompleteValue, (function (param) {
              return "";
            }));
      handleClose(undefined);
    }
    if (newValue !== internalValue) {
      Curry._1(setInternalValue, (function (param) {
              return newValue;
            }));
      return Curry._1(onChange, newValue);
    }
    
  };
  var changeHighlightedIndex = function (setIndex) {
    if (!open_) {
      return ;
    }
    var tmp;
    if (typeof setIndex === "number") {
      tmp = setIndex !== 0 ? -1 : 0;
    } else {
      var newIndex = highlightedIndexRef.current + setIndex[0] | 0;
      tmp = newIndex >= options.length ? options.length - 1 | 0 : (
          newIndex <= -1 ? -1 : newIndex
        );
    }
    highlightedIndexRef.current = tmp;
    var listboxNode;
    if (listboxRef !== undefined) {
      var p = Caml_option.valFromOption(listboxRef).parentElement;
      listboxNode = (p == null) ? undefined : Caml_option.nullable_to_opt(p.querySelector("[role='listbox']"));
    } else {
      listboxNode = undefined;
    }
    if (listboxNode === undefined) {
      return ;
    }
    var node = Caml_option.valFromOption(listboxNode);
    var prev = node.querySelector("[data-focus='true']");
    if (!(prev == null)) {
      prev.removeAttribute("data-focus");
    }
    var option = node.querySelector("[data-option-index=\"" + (String(tmp) + "\"]"));
    if (option == null) {
      return ;
    }
    option.setAttribute("data-focus", "true");
    if (node.scrollHeight <= node.clientHeight) {
      return ;
    }
    var scrollBottom = (node.scrollTop | 0) + node.clientHeight | 0;
    var o$prime = ElementRe.asHtmlElement(option);
    var match;
    if (o$prime !== undefined) {
      var o$prime$1 = Caml_option.valFromOption(o$prime);
      match = /* tuple */[
        o$prime$1.offsetTop,
        o$prime$1.offsetHeight
      ];
    } else {
      match = /* tuple */[
        0,
        0
      ];
    }
    var optionOffsetTop = match[0];
    var optionBottom = optionOffsetTop + match[1] | 0;
    if (optionBottom <= scrollBottom) {
      if (optionOffsetTop < (node.scrollTop | 0)) {
        node.scrollTop = optionOffsetTop;
        return ;
      } else {
        return ;
      }
    }
    var newScrollTop = optionBottom - node.clientHeight | 0;
    node.scrollTop = newScrollTop;
    
  };
  var handleKeyDown = function ($$event) {
    var keyName = $$event.key;
    var selected = highlightedIndexRef.current;
    Belt_List.forEach(/* [] */0, (function (param) {
            if (keyName !== param[0]) {
              return ;
            }
            var handler = param[1];
            $$event.preventDefault();
            if (selected === -1) {
              return Curry._2(handler, autoCompleteValue, undefined);
            }
            if (!(selected >= 0 && selected < options.length)) {
              return ;
            }
            var option = Belt_Array.get(options, selected);
            if (option === undefined) {
              return ;
            }
            var option$1 = Caml_option.valFromOption(option);
            return Curry._2(handler, Curry._1(toString, option$1), Caml_option.some(option$1));
          }));
    switch (keyName) {
      case "ArrowDown" :
          if (open_) {
            $$event.preventDefault();
            return changeHighlightedIndex(/* Diff */[1]);
          } else {
            return ;
          }
      case "ArrowUp" :
          if (open_) {
            $$event.preventDefault();
            return changeHighlightedIndex(/* Diff */[-1]);
          } else {
            return ;
          }
      case "Backspace" :
          if (autoCompleteValue === "") {
            Curry._1(onDelete, undefined);
            return handleClose($$event);
          } else {
            return ;
          }
      case "Enter" :
          if (!open_) {
            return ;
          }
          $$event.preventDefault();
          if (!(selected >= 0 && selected < options.length)) {
            return ;
          }
          var option = Belt_Array.get(options, selected);
          if (option !== undefined) {
            return handleAddValue(Curry._1(toString, Caml_option.valFromOption(option)));
          } else {
            return ;
          }
      case "Escape" :
          handleClose($$event);
          if (onBlur !== undefined) {
            return Curry._1(onBlur, undefined);
          } else {
            return ;
          }
      case "Tab" :
          if (open_) {
            $$event.preventDefault();
            if (!(selected >= 0 && selected < options.length)) {
              return ;
            }
            var option$1 = Belt_Array.get(options, selected);
            if (option$1 !== undefined) {
              return handleAddValue(Curry._1(toString, Caml_option.valFromOption(option$1)));
            } else {
              return ;
            }
          }
          handleClose($$event);
          if (onBlur !== undefined) {
            return Curry._1(onBlur, undefined);
          } else {
            return ;
          }
      default:
        return ;
    }
  };
  React.useEffect((function () {
          var nextCb;
          if (anchorEl !== undefined && listboxRef !== undefined) {
            var list = Caml_option.valFromOption(listboxRef);
            var el = Caml_option.valFromOption(anchorEl);
            nextCb = (function ($$event) {
                var eventEl = $$event.target;
                try {
                  if (el.contains(eventEl) || list.contains(eventEl)) {
                    return ;
                  } else {
                    handleClose($$event);
                    if (onBlur !== undefined) {
                      return Curry._1(onBlur, undefined);
                    } else {
                      return ;
                    }
                  }
                }
                catch (exn){
                  return ;
                }
              });
          } else {
            nextCb = undefined;
          }
          Curry._1(setBlurCb, (function (oldCb) {
                  if (oldCb !== undefined) {
                    window.removeEventListener("mousedown", oldCb);
                  }
                  return nextCb;
                }));
          
        }), /* tuple */[
        listboxRef,
        anchorEl
      ]);
  React.useEffect((function () {
          if (blurCb !== undefined) {
            window.addEventListener("mousedown", blurCb);
          }
          return (function (param) {
                    if (blurCb !== undefined) {
                      window.removeEventListener("mousedown", blurCb);
                      return ;
                    }
                    
                  });
        }), [blurCb]);
  React.useEffect((function () {
          var nextConsiderations = autoCompleteValue.trim() !== "" ? (
              filterOptions !== undefined ? Curry._2(filterOptions, considerations, autoCompleteValue) : Fuzz$BsConsole.fuzz(autoCompleteValue, considerations, toString)
            ) : considerations;
          Curry._1(setOptions, (function (param) {
                  return nextConsiderations;
                }));
          
        }), /* tuple */[
        autoCompleteValue,
        considerations
      ]);
  React.useLayoutEffect((function () {
          if (listboxRef !== undefined) {
            var node = Caml_option.valFromOption(listboxRef);
            var el = node.querySelector("[data-focus='true']");
            if (!(el == null)) {
              el.removeAttribute("data-focus");
            }
            node.scrollTop = 0.0;
          }
          var _option = Belt_Array.get(options, 0);
          if (_option !== undefined && (autoCompleteValue.trim() !== "" || options.length === 1)) {
            changeHighlightedIndex(/* Start */0);
          } else {
            changeHighlightedIndex(/* Reset */1);
          }
          
        }), /* tuple */[
        popperKey,
        listboxRef
      ]);
  React.useLayoutEffect((function () {
          Curry._1(setPopperKey, (function (k) {
                  return k + 1 | 0;
                }));
          
        }), [options]);
  return React.createElement(React.Fragment, undefined, React.createElement("div", {
                  ref: (function (el) {
                      return Curry._1(setAnchorEl, (function (param) {
                                    if (el == null) {
                                      return ;
                                    } else {
                                      return Caml_option.some(el);
                                    }
                                  }));
                    }),
                  onKeyDown: handleKeyDown
                }, Curry._1(renderInput, {
                      internalValue: internalValue,
                      autoFocus: autoFocus,
                      placeholder: placeholder,
                      ariaLabel: inputAriaLabel,
                      onChange: handleInputChange,
                      onFocus: handleFocus,
                      resetInternalValues: (function (param) {
                          Curry._1(setInternalValue, (function (param) {
                                  return "";
                                }));
                          return Curry._1(setAutoCompleteValue, (function (param) {
                                        return "";
                                      }));
                        }),
                      handleAddValue: handleAddValue
                    })), React.createElement(Popper.default, {
                  anchorEl: anchorEl,
                  open: open_,
                  placement: "bottom-start",
                  children: React.createElement(Paper.default, {
                        classes: {
                          root: Css.merge(/* :: */[
                                BtAutocomplete$BsConsole.Styles.paper,
                                /* :: */[
                                  rootClassName,
                                  /* [] */0
                                ]
                              ])
                        },
                        children: null
                      }, loading && considerations.length === 0 ? React.createElement("div", {
                              className: BtAutocomplete$BsConsole.Styles.loading
                            }, I18N$BsConsole.show(undefined, "Loading...")) : null, options.length === 0 && !loading ? React.createElement("div", {
                              className: BtAutocomplete$BsConsole.Styles.loading
                            }, I18N$BsConsole.show(undefined, "No options")) : null, options.length !== 0 ? React.createElement("ul", {
                              ref: (function (el) {
                                  return Curry._1(setListboxRef, (function (param) {
                                                if (el == null) {
                                                  return ;
                                                } else {
                                                  return Caml_option.some(el);
                                                }
                                              }));
                                }),
                              className: Css.merge(/* :: */[
                                    BtAutocomplete$BsConsole.Styles.listBox,
                                    /* :: */[
                                      listClassName,
                                      /* [] */0
                                    ]
                                  ]),
                              role: "listbox"
                            }, Belt_Array.mapWithIndex(options, (function (idx, option) {
                                    return React.createElement(BtAutocomplete$BsConsole.ListItem.make, {
                                                index: idx,
                                                className: BtAutocomplete$BsConsole.Styles.option,
                                                onClick: (function (param) {
                                                    return handleAddValue(Curry._1(toString, option));
                                                  }),
                                                children: renderListOption !== undefined ? Curry._2(renderListOption, option, idx) : React.createElement(BtTypography$BsConsole.make, {
                                                        variant: /* Body1 */8,
                                                        className: Css.style(/* :: */[
                                                              Css.margin(Css.zero),
                                                              /* [] */0
                                                            ]),
                                                        children: I18N$BsConsole.showSkip(Curry._1(toString, option))
                                                      }),
                                                key: Curry._1(toString, option) + ("__" + String(idx))
                                              });
                                  }))) : null),
                  key: String(popperKey)
                }));
}

var make = SpecialCharacterAutocomplete;

exports.wordBreakRegex = wordBreakRegex;
exports.getSelectedWordAndWordPosition = getSelectedWordAndWordPosition;
exports.make = make;
/* Css Not a pure module */
