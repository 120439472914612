// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Belt_Array = require("bs-platform/lib/js/belt_Array.js");
var Belt_Option = require("bs-platform/lib/js/belt_Option.js");
var Caml_option = require("bs-platform/lib/js/caml_option.js");
var Mui$BsConsole = require("../../bindings/Mui.js");
var I18N$BsConsole = require("../../I18N.js");
var Task2$BsConsole = require("../../Task2.js");
var Colors$BsConsole = require("../../Colors.js");
var ApiAlerts$BsConsole = require("./ApiAlerts.js");
var Add = require("@material-ui/icons/Add");
var AlertsRouter$BsConsole = require("./AlertsRouter.js");
var BtTypography$BsConsole = require("../../BtTypography.js");
var MetricsEvent$BsConsole = require("../../bs-metrics/MetricsEvent.js");
var Table = require("@material-ui/core/Table");
var Edit = require("@material-ui/icons/Edit");
var SnackPurveyor$BsConsole = require("../../SnackPurveyor.js");
var Button = require("@material-ui/core/Button");
var Dialog = require("@material-ui/core/Dialog");
var $$Error = require("@material-ui/icons/Error");
var WarningMessage$BsConsole = require("../../WarningMessage.js");
var Delete = require("@material-ui/icons/Delete");
var TableRow = require("@material-ui/core/TableRow");
var TableBody = require("@material-ui/core/TableBody");
var TableCell = require("@material-ui/core/TableCell");
var TableHead = require("@material-ui/core/TableHead");
var DialogTitle = require("@material-ui/core/DialogTitle");
var CheckCircle = require("@material-ui/icons/CheckCircle");
var DialogActions = require("@material-ui/core/DialogActions");
var DialogContent = require("@material-ui/core/DialogContent");
var DialogContentText = require("@material-ui/core/DialogContentText");

var pageMarginBottom = Css.marginBottom(Css.rem(1));

var featureDescription = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.grey4)),
      /* :: */[
        pageMarginBottom,
        /* [] */0
      ]
    ]);

var marginRight = Css.style(/* :: */[
      Css.marginRight(Css.px(5)),
      /* [] */0
    ]);

var flexRow = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* row */5693978),
        /* [] */0
      ]
    ]);

var flexColumn = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.flexDirection(/* column */-963948842),
        /* [] */0
      ]
    ]);

var buttonContainer = Css.style(/* :: */[
      Css.display(/* flex */-1010954439),
      /* :: */[
        Css.justifyContent(/* flexEnd */924268066),
        /* :: */[
          Css.marginTop(Css.px(20)),
          /* [] */0
        ]
      ]
    ]);

var table = Css.style(/* :: */[
      Css.marginTop(Css.px(20)),
      /* [] */0
    ]);

var tableHeader = Css.style(/* :: */[
      Css.unsafe("boxShadow", "0px 1px 4px 0px " + Colors$BsConsole.octothorpe(Colors$BsConsole.blackA20)),
      /* [] */0
    ]);

var tableHeaderCell = Css.style(/* :: */[
      Css.fontSize(Css.px(13)),
      /* :: */[
        Css.color(Css.hex(Colors$BsConsole.grey2)),
        /* [] */0
      ]
    ]);

var titleContainer = Css.style(/* :: */[
      Css.position(/* relative */903134412),
      /* :: */[
        Css.marginBottom(Css.rem(1)),
        /* :: */[
          Css.paddingRight(Css.px(28)),
          /* [] */0
        ]
      ]
    ]);

var statusIcon = Css.style(/* :: */[
      Css.fontSize(Css.rem(1.2)),
      /* [] */0
    ]);

var statusIconContainer = Css.style(/* :: */[
      Css.display(/* inlineFlex */53323314),
      /* :: */[
        Css.paddingRight(Css.px(5)),
        /* :: */[
          Css.paddingLeft(Css.px(2)),
          /* :: */[
            Css.alignItems(/* center */98248149),
            /* :: */[
              Css.borderRadius(Css.rem(0.5)),
              /* [] */0
            ]
          ]
        ]
      ]
    ]);

var green = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.npGreen)),
      /* :: */[
        Css.backgroundColor(Css.hex(Colors$BsConsole.greenExtraLight)),
        /* [] */0
      ]
    ]);

var orange = Css.style(/* :: */[
      Css.color(Css.hex(Colors$BsConsole.orange)),
      /* :: */[
        Css.backgroundColor(Css.hex(Colors$BsConsole.orangeBackground)),
        /* [] */0
      ]
    ]);

var Styles = {
  pageMarginBottom: pageMarginBottom,
  featureDescription: featureDescription,
  marginRight: marginRight,
  flexRow: flexRow,
  flexColumn: flexColumn,
  buttonContainer: buttonContainer,
  table: table,
  tableHeader: tableHeader,
  tableHeaderCell: tableHeaderCell,
  titleContainer: titleContainer,
  statusIcon: statusIcon,
  statusIconContainer: statusIconContainer,
  green: green,
  orange: orange
};

function AlertsTable$CreateAlertButton(Props) {
  var handleRouteChange = Props.handleRouteChange;
  return React.createElement("div", {
              className: buttonContainer
            }, React.createElement(Button.default, {
                  variant: "contained",
                  color: "primary",
                  onClick: (function (param) {
                      return Curry._1(handleRouteChange, /* CreateAlert */1);
                    }),
                  children: null
                }, React.createElement(Add.default, {
                      className: marginRight
                    }), React.createElement("span", {
                      className: marginRight
                    }, I18N$BsConsole.show(undefined, "Create alert"))));
}

var CreateAlertButton = {
  make: AlertsTable$CreateAlertButton
};

function AlertsTable$AlertsTable(Props) {
  var alerts = Props.alerts;
  var handleChangeUrl = Props.handleChangeUrl;
  var config = Props.config;
  var projectName = Props.projectName;
  var token = Props.token;
  var handleRefresh = Props.handleRefresh;
  var match = React.useState((function () {
          
        }));
  var setErrorMessage = match[1];
  var errorMessage = match[0];
  var match$1 = React.useState((function () {
          
        }));
  var setShowDeleteConfirmDialog = match$1[1];
  var showDeleteConfirmDialog = match$1[0];
  return React.createElement(React.Fragment, undefined, Belt_Option.isSome(errorMessage) ? React.createElement(WarningMessage$BsConsole.make, {
                    errorMessage: I18N$BsConsole.show(undefined, Belt_Option.getWithDefault(errorMessage, "Something went wrong. Please try again."))
                  }) : null, React.createElement(Dialog.default, {
                  open: Belt_Option.isSome(showDeleteConfirmDialog),
                  onClose: (function (param) {
                      return Curry._1(setShowDeleteConfirmDialog, (function (param) {
                                    
                                  }));
                    }),
                  children: null
                }, React.createElement(DialogTitle.default, {
                      children: I18N$BsConsole.show(undefined, "Are you sure?")
                    }), React.createElement(DialogContent.default, {
                      children: React.createElement(DialogContentText.default, {
                            children: Curry._1(I18N$BsConsole.showSkipf(/* Format */[
                                      /* String_literal */Block.__(11, [
                                          "Are you sure you want to delete the alert ",
                                          /* String */Block.__(2, [
                                              /* No_padding */0,
                                              /* Char_literal */Block.__(12, [
                                                  /* "?" */63,
                                                  /* End_of_format */0
                                                ])
                                            ])
                                        ]),
                                      "Are you sure you want to delete the alert %s?"
                                    ]), showDeleteConfirmDialog !== undefined ? "\"" + (showDeleteConfirmDialog.name + "\"") : "")
                          })
                    }), React.createElement(DialogActions.default, {
                      children: null
                    }, React.createElement(Button.default, {
                          color: "primary",
                          onClick: (function (param) {
                              return Curry._1(setShowDeleteConfirmDialog, (function (param) {
                                            
                                          }));
                            }),
                          children: I18N$BsConsole.show(undefined, "No")
                        }), React.createElement(Button.default, {
                          color: "primary",
                          onClick: (function (param) {
                              if (showDeleteConfirmDialog !== undefined) {
                                var id = showDeleteConfirmDialog.id;
                                var onSuccess = function (param) {
                                  MetricsEvent$BsConsole.send(/* Action */Block.__(0, [/* IssueBasedAlert_Deleted */128]));
                                  return Curry._1(handleRefresh, undefined);
                                };
                                var onFailure = function (err) {
                                  return Curry._1(setErrorMessage, (function (param) {
                                                return err;
                                              }));
                                };
                                var maybeTask = ApiAlerts$BsConsole.DeleteAlert.deleteAlertTask(config, projectName, String(id));
                                if (maybeTask !== undefined) {
                                  return Task2$BsConsole.handle(token, Caml_option.valFromOption(maybeTask), undefined, (function (param) {
                                                return ApiAlerts$BsConsole.responseCb(onSuccess, onFailure, param);
                                              }));
                                } else {
                                  return SnackPurveyor$BsConsole.enqueue(undefined, undefined, undefined, undefined, I18N$BsConsole.show(undefined, "Service unavailable"));
                                }
                              }
                              
                            }),
                          children: I18N$BsConsole.show(undefined, "Yes")
                        }))), React.createElement(Table.default, {
                  className: table,
                  children: null
                }, React.createElement(TableHead.default, {
                      className: tableHeader,
                      children: React.createElement(TableRow.default, {
                            children: null
                          }, React.createElement(TableCell.default, {
                                className: tableHeaderCell,
                                children: I18N$BsConsole.show(undefined, "Name")
                              }), React.createElement(TableCell.default, {
                                className: tableHeaderCell,
                                children: I18N$BsConsole.show(undefined, "Integrations")
                              }), React.createElement(TableCell.default, {
                                className: tableHeaderCell,
                                children: I18N$BsConsole.show(undefined, "Status")
                              }), React.createElement(TableCell.default, {
                                className: Css.merge(/* :: */[
                                      tableHeaderCell,
                                      /* :: */[
                                        Css.style(/* :: */[
                                              Css.textAlign(/* center */98248149),
                                              /* [] */0
                                            ]),
                                        /* [] */0
                                      ]
                                    ]),
                                children: I18N$BsConsole.show(undefined, "Actions")
                              }))
                    }), React.createElement(TableBody.default, {
                      children: Belt_Array.map(alerts, (function (alert) {
                              return React.createElement(TableRow.default, {
                                          children: null
                                        }, React.createElement(TableCell.default, {
                                              children: I18N$BsConsole.showSkip(alert.name)
                                            }), React.createElement(TableCell.default, {
                                              children: I18N$BsConsole.showSkip(Belt_Array.map(alert.integrations, (function (integration) {
                                                            return integration.name;
                                                          })).join(",  "))
                                            }), React.createElement(TableCell.default, {
                                              children: alert.state === "enabled" ? React.createElement("div", {
                                                      className: Css.merge(/* :: */[
                                                            statusIconContainer,
                                                            /* :: */[
                                                              green,
                                                              /* [] */0
                                                            ]
                                                          ])
                                                    }, React.createElement(CheckCircle.default, {
                                                          className: Css.merge(/* :: */[
                                                                statusIcon,
                                                                /* :: */[
                                                                  marginRight,
                                                                  /* [] */0
                                                                ]
                                                              ])
                                                        }), I18N$BsConsole.show(undefined, "Active")) : React.createElement("div", {
                                                      className: Css.merge(/* :: */[
                                                            statusIconContainer,
                                                            /* :: */[
                                                              orange,
                                                              /* [] */0
                                                            ]
                                                          ])
                                                    }, React.createElement($$Error.default, {
                                                          className: Css.merge(/* :: */[
                                                                statusIcon,
                                                                /* :: */[
                                                                  marginRight,
                                                                  /* [] */0
                                                                ]
                                                              ])
                                                        }), I18N$BsConsole.show(undefined, "Inactive"))
                                            }), React.createElement(TableCell.default, {
                                              children: React.createElement("div", {
                                                    className: Css.merge(/* :: */[
                                                          flexRow,
                                                          /* :: */[
                                                            Css.style(/* :: */[
                                                                  Css.height(Css.pct(100)),
                                                                  /* :: */[
                                                                    Css.justifyContent(/* center */98248149),
                                                                    /* [] */0
                                                                  ]
                                                                ]),
                                                            /* [] */0
                                                          ]
                                                        ])
                                                  }, React.createElement(Mui$BsConsole.IconButtonWithTooltipJsx3.make, {
                                                        ariaLabel: "edit " + alert.name,
                                                        className: Css.style(/* :: */[
                                                              Css.color(Css.hex(Colors$BsConsole.grey4)),
                                                              /* [] */0
                                                            ]),
                                                        onClick: (function (param) {
                                                            return Curry._1(handleChangeUrl, /* ProjectSettingsAlerts */Block.__(27, [
                                                                          projectName,
                                                                          {
                                                                            tab: AlertsRouter$BsConsole.typeToString(/* IssueBasedAlerts */1),
                                                                            id: String(alert.id)
                                                                          }
                                                                        ]));
                                                          }),
                                                        placement_tooltip: "bottom",
                                                        title_tooltip: "Edit alert",
                                                        children: React.createElement(Edit.default, { })
                                                      }), React.createElement(Mui$BsConsole.IconButtonWithTooltipJsx3.make, {
                                                        ariaLabel: "delete " + alert.name,
                                                        className: Css.style(/* :: */[
                                                              Css.color(Css.hex(Colors$BsConsole.grey4)),
                                                              /* [] */0
                                                            ]),
                                                        onClick: (function (param) {
                                                            return Curry._1(setShowDeleteConfirmDialog, (function (param) {
                                                                          return alert;
                                                                        }));
                                                          }),
                                                        placement_tooltip: "bottom",
                                                        title_tooltip: "Delete alert",
                                                        children: React.createElement(Delete.default, { })
                                                      }))
                                            }));
                            }))
                    })));
}

var AlertsTable = {
  make: AlertsTable$AlertsTable
};

function AlertsTable$1(Props) {
  var alerts = Props.alerts;
  var config = Props.config;
  var projectName = Props.projectName;
  var token = Props.token;
  var handleRefresh = Props.handleRefresh;
  var handleChangeUrl = Props.handleChangeUrl;
  var handleRouteChange = Props.handleRouteChange;
  return React.createElement("div", {
              className: flexColumn
            }, React.createElement(BtTypography$BsConsole.make, {
                  variant: /* Body2 */9,
                  className: featureDescription,
                  children: I18N$BsConsole.show(undefined, "Configure alerts to automatically generate notifications and tickets to your 3rd party integrations.")
                }), React.createElement(AlertsTable$CreateAlertButton, {
                  handleRouteChange: handleRouteChange
                }), React.createElement(AlertsTable$AlertsTable, {
                  alerts: alerts,
                  handleChangeUrl: handleChangeUrl,
                  config: config,
                  projectName: projectName,
                  token: token,
                  handleRefresh: handleRefresh
                }));
}

var make = AlertsTable$1;

exports.Styles = Styles;
exports.CreateAlertButton = CreateAlertButton;
exports.AlertsTable = AlertsTable;
exports.make = make;
/* pageMarginBottom Not a pure module */
