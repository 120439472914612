// Generated by BUCKLESCRIPT, PLEASE EDIT WITH CARE
'use strict';

var Css = require("bs-css/lib/js/src/Css.js");
var Block = require("bs-platform/lib/js/block.js");
var Curry = require("bs-platform/lib/js/curry.js");
var React = require("react");
var Crdb$BsConsole = require("./crdb.js");
var Row2$BsConsole = require("./components/Row2.js");
var Colors$BsConsole = require("./Colors.js");
var BtPaper$BsConsole = require("./BtPaper.js");
var ReasonReactCompat = require("reason-react/lib/js/src/ReasonReactCompat.js");
var MuiIcons$BsConsole = require("./MuiIcons.js");
var DescribeTask$BsConsole = require("./DescribeTask.js");
var Paper = require("@material-ui/core/Paper");
var BacktraceHooks$BsConsole = require("./BacktraceHooks.js");
var ExploreModeSelect$BsConsole = require("./ExploreModeSelect.js");
var SavedQueriesModel$BsConsole = require("./saved-queries/SavedQueriesModel.js");
var ProjectFlamegraphView$BsConsole = require("./ProjectFlamegraphView.js");
var SavedQueriesContainer$BsConsole = require("./saved-queries/SavedQueriesContainer.js");
var ErrorsOverTimeBinChart$BsConsole = require("./ErrorsOverTimeBinChart.js");
var DistributionValueSelect$BsConsole = require("./DistributionValueSelect.js");
var SavedQueryBookmarkSelect$BsConsole = require("./saved-queries/SavedQueryBookmarkSelect.js");

function ProjectFlamegraph(Props) {
  var config = Props.config;
  var aperture = Props.aperture;
  var handleTask = Props.handleTask;
  var weighted = Props.weighted;
  var reversed = Props.reversed;
  var projectName = Props.projectName;
  var token = Props.token;
  var handleChangeUrl = Props.handleChangeUrl;
  var handleAddFilters = Props.handleAddFilters;
  var route = Props.route;
  var apertureForQuery = BacktraceHooks$BsConsole.FrozenTimeAperture.use(aperture);
  var match = DescribeTask$BsConsole.use(token, projectName, undefined, undefined);
  var describeRemote = match[0];
  var match$1 = DescribeTask$BsConsole.use(token, projectName, "metrics", undefined);
  var metricsDescribeRemote = match$1[0];
  var metricsAttributes = React.useMemo((function () {
          if (typeof metricsDescribeRemote === "number" || metricsDescribeRemote.tag) {
            return /* [] */0;
          } else {
            return metricsDescribeRemote[0];
          }
        }), [metricsDescribeRemote]);
  var handleSetAperture = function (aperture$prime) {
    return Curry._1(handleChangeUrl, /* ProjectFlamegraph */Block.__(20, [
                  projectName,
                  {
                    aperture: aperture$prime,
                    weighted: weighted,
                    reversed: reversed
                  }
                ]));
  };
  var tmp;
  if (typeof describeRemote === "number" || describeRemote.tag) {
    tmp = null;
  } else {
    var attributes = describeRemote[0];
    tmp = React.createElement(React.Fragment, undefined, DistributionValueSelect$BsConsole.hasAttribute(attributes, "error.type") ? React.createElement(DistributionValueSelect$BsConsole.make, {
                attribute: "error.type",
                label: "Error type",
                aperture: aperture,
                token: token,
                projectName: projectName,
                handleSetAperture: handleSetAperture,
                className: Css.style(/* :: */[
                      Css.marginRight(Css.px(8)),
                      /* [] */0
                    ]),
                icon: React.createElement(MuiIcons$BsConsole.OctagonalError.make, {
                      color: "inherit",
                      fontSize: "inherit"
                    }),
                view: "list"
              }) : null, DistributionValueSelect$BsConsole.hasAttribute(attributes, "uname.sysname") ? React.createElement(DistributionValueSelect$BsConsole.make, {
                attribute: "uname.sysname",
                label: "Platform",
                aperture: aperture,
                token: token,
                projectName: projectName,
                handleSetAperture: handleSetAperture,
                icon: React.createElement(MuiIcons$BsConsole.Platform.make, {
                      color: "inherit",
                      fontSize: "inherit"
                    }),
                view: "list"
              }) : null);
  }
  return React.createElement(React.Fragment, undefined, React.createElement(Paper.default, {
                  className: Css.merge(/* :: */[
                        BtPaper$BsConsole.mainViewMargins,
                        /* :: */[
                          Css.style(/* :: */[
                                Css.marginBottom(/* zero */-789508312),
                                /* :: */[
                                  Css.overflow(/* hidden */-862584982),
                                  /* [] */0
                                ]
                              ]),
                          /* [] */0
                        ]
                      ]),
                  elevation: BtPaper$BsConsole.elevation,
                  children: React.createElement(Row2$BsConsole.make, {
                        alignItems: /* center */98248149,
                        justifyContent: /* spaceBetween */516682146,
                        className: Css.style(/* :: */[
                              Css.width(Css.pct(100)),
                              /* :: */[
                                Css.backgroundColor(Css.hex(Colors$BsConsole.grey8)),
                                /* :: */[
                                  Css.padding2(Css.px(4), Css.px(16)),
                                  /* [] */0
                                ]
                              ]
                            ]),
                        children: React.createElement(SavedQueriesContainer$BsConsole.make, {
                              name: projectName,
                              token: token,
                              handleTask: handleTask,
                              handleChangeUrl: handleChangeUrl,
                              config: config,
                              query: undefined,
                              mode: undefined,
                              normBy: undefined,
                              renderTrigger: (function (param) {
                                  var handleOpen = param.handleOpen;
                                  return React.createElement(React.Fragment, undefined, React.createElement(SavedQueryBookmarkSelect$BsConsole.make, {
                                                  projectBookmarkedViews: param.projectBookmarkedViews,
                                                  userBookmarkedViews: param.userBookmarkedViews,
                                                  projectDefault: param.projectDefault,
                                                  userDefault: param.userDefault,
                                                  queries: param.queries,
                                                  handleOpen: (function (param) {
                                                      return Curry._2(handleOpen, undefined, undefined);
                                                    }),
                                                  handleChangeUrl: handleChangeUrl,
                                                  currentMode: SavedQueriesModel$BsConsole.modeOfStringExn("instance"),
                                                  projectName: projectName
                                                }));
                                })
                            })
                      })
                }), React.createElement(Paper.default, {
                  className: Css.merge(/* :: */[
                        BtPaper$BsConsole.mainViewMargins,
                        /* :: */[
                          "explore-table-paper",
                          /* [] */0
                        ]
                      ]),
                  children: null
                }, React.createElement(Row2$BsConsole.make, {
                      justifyContent: /* spaceBetween */516682146,
                      className: Css.style(/* :: */[
                            Css.padding4(Css.px(16), Css.px(16), /* zero */-789508312, Css.px(16)),
                            /* [] */0
                          ]),
                      children: null
                    }, React.createElement(Row2$BsConsole.make, {
                          children: tmp
                        }), React.createElement(ExploreModeSelect$BsConsole.Jsx3.make, {
                          projectName: projectName,
                          aperture: aperture,
                          currentView: /* FlameGraph */35181051,
                          handleChangeUrl: handleChangeUrl
                        })), React.createElement(ErrorsOverTimeBinChart$BsConsole.make, {
                      token: token,
                      projectName: projectName,
                      aperture: apertureForQuery,
                      onRangeChange: (function (range) {
                          return Curry._1(handleChangeUrl, /* ProjectFlamegraph */Block.__(20, [
                                        projectName,
                                        {
                                          aperture: Curry._2(Crdb$BsConsole.Aperture.setTimeAperture, /* Custom */Block.__(1, [range]), aperture),
                                          weighted: weighted,
                                          reversed: reversed
                                        }
                                      ]));
                        }),
                      className: Css.style(/* :: */[
                            Css.borderBottom(Css.px(1), /* solid */12956715, Css.hex(Colors$BsConsole.grey675)),
                            /* [] */0
                          ]),
                      metricsAttributes: metricsAttributes
                    }), React.createElement(ProjectFlamegraphView$BsConsole.Jsx3.make, {
                      config: config,
                      aperture: aperture,
                      handleTask: handleTask,
                      weighted: weighted,
                      reversed: reversed,
                      projectName: projectName,
                      token: token,
                      handleChangeUrl: handleChangeUrl,
                      handleAddFilters: handleAddFilters,
                      route: route
                    })));
}

function make(config, aperture, handleTask, weighted, reversed, projectName, token, handleChangeUrl, handleAddFilters, route, _children) {
  return ReasonReactCompat.wrapReactForReasonReact(ProjectFlamegraph, {
              config: config,
              aperture: aperture,
              handleTask: handleTask,
              weighted: weighted,
              reversed: reversed,
              projectName: projectName,
              token: token,
              handleChangeUrl: handleChangeUrl,
              handleAddFilters: handleAddFilters,
              route: route
            }, _children);
}

var Re = {
  make: make
};

var make$1 = ProjectFlamegraph;

exports.make = make$1;
exports.Re = Re;
/* Css Not a pure module */
